@font-face {
    font-family: "flaticon";
    src: url("../../../assets/DashboardAssets/assets/vendor/fonts/flaticon.ttf") format("truetype"), url("../../../assets/DashboardAssets/assets/vendor/fonts/flaticon.ttf") format("woff"), url("../../../assets/DashboardAssets/assets/vendor/fonts/flaticon.ttf") format("woff2"), url("../../../assets/DashboardAssets/assets/vendor/fonts/flaticon.ttf") format("embedded-opentype"), url("../../../assets/DashboardAssets/assets/vendor/fonts/flaticon.ttf") format("svg");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-file:before {
    content: "\f101";
}

.flaticon-sign-out:before {
    content: "\f102";
}

.flaticon-send:before {
    content: "\f103";
}

.flaticon-love:before {
    content: "\f104";
}

.flaticon-location:before {
    content: "\f105";
}

.flaticon-quote:before {
    content: "\f106";
}

.flaticon-search:before {
    content: "\f107";
}

.flaticon-volunteer:before {
    content: "\f108";
}

.flaticon-bag:before {
    content: "\f109";
}

.flaticon-notification:before {
    content: "\f10a";
}

.flaticon-bell:before {
    content: "\f10b";
}

.flaticon-search-1:before {
    content: "\f10c";
}

.flaticon-speech-bubble:before {
    content: "\f10d";
}

.flaticon-verified:before {
    content: "\f10e";
}

.flaticon-money-box:before {
    content: "\f10f";
}

.flaticon-email:before {
    content: "\f110";
}

.flaticon-facebook:before {
    content: "\f111";
}

.flaticon-home:before {
    content: "\f112";
}

.flaticon-check:before {
    content: "\f113";
}

.flaticon-edit:before {
    content: "\f114";
}

.flaticon-quote-1:before {
    content: "\f115";
}

.flaticon-email-1:before {
    content: "\f116";
}

.flaticon-application:before {
    content: "\f117";
}

.flaticon-up-arrow:before {
    content: "\f118";
}

.flaticon-pin:before {
    content: "\f119";
}

.flaticon-phone-call:before {
    content: "\f11a";
}

.flaticon-mail:before {
    content: "\f11b";
}

.flaticon-linkedin:before {
    content: "\f11c";
}

.flaticon-instagram:before {
    content: "\f11d";
}

.flaticon-twitter:before {
    content: "\f11e";
}

.flaticon-facebook-1:before {
    content: "\f11f";
}

.flaticon-check-mark:before {
    content: "\f120";
}

.flaticon-heart:before {
    content: "\f121";
}

.flaticon-campaign:before {
    content: "\f122";
}

.flaticon-quotes:before {
    content: "\f123";
}

.flaticon-calendar:before {
    content: "\f124";
}

.flaticon-coin:before {
    content: "\f125";
}

.flaticon-user:before {
    content: "\f126";
}

.flaticon-next:before {
    content: "\f127";
}

.flaticon-back:before {
    content: "\f128";
}

.flaticon-plus:before {
    content: "\f129";
}

.flaticon-phone-call-1:before {
    content: "\f12a";
}

.flaticon-email-2:before {
    content: "\f12b";
}

.flaticon-pin-1:before {
    content: "\f12c";
}

.flaticon-call:before {
    content: "\f12d";
}

.flaticon-tick:before {
    content: "\f12e";
}

.flaticon-secure-shield:before {
    content: "\f12f";
}

.flaticon-telephone:before {
    content: "\f130";
}

.flaticon-right-quote:before {
    content: "\f131";
}

.flaticon-search-2:before {
    content: "\f132";
}

.flaticon-right-arrow:before {
    content: "\f133";
}

.flaticon-left-arrow:before {
    content: "\f134";
}

.flaticon-email-3:before {
    content: "\f135";
}

.flaticon-right-arrow-1:before {
    content: "\f136";
}

.flaticon-share:before {
    content: "\f137";
}

.flaticon-insurance:before {
    content: "\f138";
}

.flaticon-health-care:before {
    content: "\f139";
}

.flaticon-right-quote-sign:before {
    content: "\f13a";
}

.flaticon-minus-sign:before {
    content: "\f13b";
}

.flaticon-quotation-mark:before {
    content: "\f13c";
}

.flaticon-play-button-arrowhead:before {
    content: "\f13d";
}

.flaticon-play-button:before {
    content: "\f13e";
}

.flaticon-badge:before {
    content: "\f13f";
}

.flaticon-exit:before {
    content: "\f140";
}

.flaticon-shopping-cart:before {
    content: "\f141";
}

.flaticon-quote-2:before {
    content: "\f142";
}

.flaticon-quote-3:before {
    content: "\f143";
}

.flaticon-user-1:before {
    content: "\f144";
}

.flaticon-heart-1:before {
    content: "\f145";
}

.flaticon-user-2:before {
    content: "\f146";
}

.flaticon-check-1:before {
    content: "\f147";
}

.flaticon-clock:before {
    content: "\f148";
}

.flaticon-straight-quotes:before {
    content: "\f149";
}

.flaticon-time:before {
    content: "\f14a";
}

.flaticon-support:before {
    content: "\f14b";
}

.flaticon-send-1:before {
    content: "\f14c";
}

.flaticon-play:before {
    content: "\f14d";
}

.flaticon-loupe:before {
    content: "\f14e";
}

.flaticon-checked:before {
    content: "\f14f";
}

.flaticon-invisible:before {
    content: "\f150";
}

.flaticon-done:before {
    content: "\f151";
}

.flaticon-down-arrow:before {
    content: "\f152";
}