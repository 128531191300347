.offset_details h1 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
.offset_details_flight {
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  padding-bottom: 10px;
}
.offset_details_header {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  padding-left: 15px;
  padding-top: 60px;
  line-height: 1.2;
}
.offset_details_head p {
  margin: 0;
  font-size: 22px;
}
.offset_down_heade_back {
  padding: 22px 12px;
  background-image: linear-gradient(
      rgb(201 201 201 / 50%),
      rgba(201 201 201 / 50%)
    ),
    url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/fk4eqwc85uqy5e13gfna6q7tu9tx/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22greenfield.jpg%22%3B%20filename%2A%3DUTF-8%27%27greenfield.jpg&response-content-type=image%2Fjpeg");
  background-size: cover !important;
}
.offset_down_heade_back_padding_top {
  padding-top: 70px;
  margin-top: 0;
}
.offset_down_heade {
  justify-content: center;
  display: flex;
}
.offset_down_heade_ns {
  top: 45%;
}
.offset_down_heade h1 {
  color: #000000;
  text-align: initial;
  font-size: 27px;
  line-height: 35px;
  font-weight: 600;
}
.offset_down_heade p {
  color: #000000;
  text-align: initial;
}
.Tool_user {
  background-color: #fff;
}
.Tool_user_section {
  display: flex;
}
.tool_user_content_number {
  margin-left: 24px;
}
.tool_user_contents {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 9px;
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .tool_user_contents {
    text-align: justify;
  }
  .ct_act_now_padd_left .our-approach-main-sec-head_act{
    left: 0px;
    padding-left: 10px;
  }
  .ct_font_22_dash .our-approach-main-sec-head.offset-emmision-new-h1
  _header.our-approach-main-sec-head-mobile span {
    font-size: 22px;
}
.about-hate-climate-chng-text-second.Tool_user_section .ct_grid_2 {
  display: grid !important;
  grid-template-columns: auto auto;
  align-items: self-start;
}
}
.Tool_user_number {
  background: -webkit-linear-gradient(#88ae47, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px !important;
  font-weight: bolder !important;
  padding: 0;
}
.down_Li {
  margin: 0px 0px 18px 36px;
  list-style-image: url("../../assets/offsetImg/emission_ul.svg") !important;
}

.down_Li::marker {
  margin-top: 8px;
}

.offset-indv-car-img-sec {
  max-height: 455px;
  min-height: 455px;
}

.offset-indv-flight-img-sec {
  max-height: 385px;
  min-height: 385px;
}

.emissionsonflight-text2 {
  padding: 12px 36px;
  padding-top: 0px;
}

.emissionsonflight-text2 p {
  font-weight: 410;
  font-size: 15px;
  text-align: justify;
}

.emissionsonflight-text2 p a {
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 800;
}
.a_tag {
  text-decoration: inline !important;
  margin: 0 6px 0 0;
  font-weight: 400;
}
.flight_upper_headding {
  position: absolute;
}
.flight_down_headding {
  position: absolute;
  height: 103px;
  right: 0px;
  bottom: 0%  !important;
}
.seprate_text {
  text-align: justify;
  font-weight: 400;
  font-size: 15px;
}

.seprate_text span {
  position: relative;
  top: -5px;
  left: 8px;
}

.offset-emmision-new-p p {
  font-size: 23px !important;
  font-weight: 400;
}

.reducing-tips {
  font-size: 25px;
  padding-left: 5px;
}

.offset-emmision-new-h1 h1 {
  font-size: 26px !important;
}

.offset-emmision-new-h1 h1 span {
  font-size: 26px !important;
  font-weight: 600 !important;
}
.subscription_btn {
  display: flex;
  justify-content: center;
}
.flight_footprint_content {
  margin-bottom: 15px;
}
/* mobile responsive */
@media screen and (min-width: 360px) and (max-width: 576px) {
  .offset_down_heade h1 {
    font-size: 22px;
  }
  .flight-upper-tips-sec {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-left: -20px;
  }
  .ct_formate_margin_padding p.our-approach-sec-desc.text-align-lifestyle-ns.ct_accordian_tab {
    margin-left: 25px;
    margin-right: 0px;
}
.ct_formate_margin_padding p.Tool_user_number {
  margin-bottom: 0px !important;
} 
.ct_font_22_dash{
  padding-bottom: 50px;
}
.ct_font_22_dash .flight_down_headding{
  bottom: auto !important;
}
}
.flight-upper-tips-sec-text-web-ns {
  padding-left: 10%;
}
@media screen and (max-width:767px){
  .ct_padding_btm_50{
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 577px) {
  .flight-upper-tips-sec {
    display: flex;
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: -20px;
    column-gap: 10%;
  }
}
