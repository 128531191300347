.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 6px 11px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .flight-card-block .ant-select-single .ant-select-selector .ant-select-selection-item {
    width: 200px !important;
  }

  .buss-flight-header {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.offset-onetime-order-summery .ant-input {
  height: 50px !important;
  border-radius: 10px !important;
}

.not_edit {
  cursor: not-allowed;
}

.gift-code-input .ant-input {
  height: 40px !important;
}

.gift-code-input .ant-input-affix-wrapper {
  border-radius: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c6c6c6 !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: #c6c6c6 !important;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #121212;
  font-size: 15px;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  color: #b0b0b0;
  font-size: 15px;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 80px;
}

.admin_carbon_offset {
  width: 100%;
  z-index: 9;
}

.admin_carbon_offset .ant-tabs-nav-wrap {
  justify-content: center !important;
}

.subscription-disable-slider {
  position: relative;
}

.subscription-disable-slider .rec-arrow-right:disabled,
.subscription-disable-slider .rec-arrow-left:disabled {
  cursor: not-allowed;
  display: none;
}

.subscription-disable-slider .rec-arrow-right {
  position: absolute;
  top: 0px;
  right: -2px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-left {
  position: absolute;
  top: 0px;
  right: 52px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-right:hover,
.subscription-disable-slider .rec-arrow-right:hover:enabled,
.subscription-disable-slider .rec-arrow-right:focus:enabled {
  border: 0 !important;
  box-shadow: none;
  background: #ececec !important;
  background-color: #ececec !important;
  color: #706e6e !important;
}

.carbon-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 28px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.carbon-business-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 0px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.active-bg-grey-onetime-card {
  background: #f6ffea !important;
  border: 0.5px solid #88ae47 !important;
}

.custom-pad-offset-onetime-card {
  padding: 12px 8px 5px 15px !important;
}

.custom-pad-offset-onetime-card img {
  height: 24px !important;
  width: 20px !important;
}

.carbon-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-business-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-offset-onetime-img-card img {
  width: 24px;
}

.carbon-business-offset-onetime-img-card img {
  width: 24px;
}

.carbon-offset-onetime-life-card-text-head p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.carbon-offset-onetime-life-card-text-head img {
  width: 22px;
  height: 22px;
}

.carbon-offset-onetime-life-card-img img {
  width: 100%;
  border-radius: 22px;
  cursor: pointer;
  height: 270px;
}

.life-card-img-select {
  border: 4px solid #77a038;
  border-radius: 24px;
  /* height: 270px; */
}

.carbon-offset-onetime-life-sec {
  padding: 15px 10px 10px 10px;
}

.carbon-offset-onetime-life-sec-household {
  padding-top: 50px !important;
}

@media screen and (max-width: 767px) {
  .carbon-offset-onetime-life-business {
    padding-top: 10px !important;
  }

  .carbon-offset-onetime-life-business-emp {
    padding-top: 50px !important;
  }
}

.carbon-offset-bg-img {
  margin-top: -40px;
}

.carbon-offset-onetime-life-sec-card {
  margin-top: 15px;
  position: relative;
}

.carbon-offset-onetime-life-sec-card-dropdown {
  padding-bottom: 50px;
  position: relative;
  padding-left: 10%;
  width: 90%;
}

.carbon-offset-flight-sec .ant-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-weight: 600 !important;
  font-size: 15px;
  color: #797b7a;
}

.carbon-offset-onetime-life-card-text {
  background: #ffffff;
  border-radius: 18px;
  width: 96%;
  bottom: 3px;
  margin: 0px 8px;
  position: absolute;
  box-shadow: 0 0 40px #c4c4c4;
}

.indv-offset-order-summay .ant-card {
  background-color: #f3f3f3;
  border-radius: 12px;
}

.indv-offset-order-summay .ant-card .ant-card-head {
  border-bottom: 2px solid #e0e0e0;
}

.indv-offset-order-summay .ant-card .ant-card-head .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  padding: 20px 12px;
}

.indv-offset-order-summay .ant-card .ant-card-body {
  padding: 0px;
}

.offset-onetime-order-summery .select-onetime-card {
  font-size: 14px;
  font-weight: 600;
  color: #9f9f9f;
  padding: 10px 0px;
  text-transform: uppercase;
}

.offset-onetime-order-summery p {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
  margin-bottom: 0px;
}

.offset-onetime-order-summery .ord-total-imp {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
}

.renew-message {
  font-weight: bold !important;
  color: #121212 !important;
  margin: 0px 12px;
}

.renew-message-ns {
  margin: 0px 40px !important;
  text-align: center !important;
}

.indv-offset-order-total-1 {
  background-color: #dee9c7;
}

.indv-offset-order-total-1 p {
  margin-bottom: 0;
}

.indv-offset-order-total-1 span {
  color: #88ac4c;
  font-weight: 600;
  font-size: 18px;
}

.indv-offset-order-total-1 span b {
  color: #121212;
  font-size: 16px;
  padding-left: 4px;
}

.indv-offset-order-total-2 p {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span b {
  color: #121212;
}

.indv-offset-order-btn-buy {
  width: 100% !important;
  background-color: #dce5d4 !important;
  color: #9eb37f !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-buy-gift {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

button:disabled.indv-offset-order-btn-buy-gift {
  opacity: 0.5;
}

.indv-offset-order-btn-cart {
  width: 300px !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: auto;
}

.indv-offset-order-btn-cart-subscribe {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: auto;
}

@media (max-width: 768px) {
  .indv-offset-order-btn-cart-subscribe {
    width: 300px !important;
    background-color: #88ae47 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    height: 48px !important;
    border: none !important;
    cursor: pointer !important;
  }
}

.disable-add-to-cart {
  opacity: 0.5;
}

.indv-offset-order-summay-desc p {
  color: #121212;
  font-weight: bold;
}

.indv-offset-order-summay-desc ul li {
  font-size: 13px;
  margin-left: 17px;
  font-weight: 500;
  color: #88ae47;
  list-style-type: disc;
}

.indv-offset-order-summay-num {
  font-size: 13px;
  color: #121212;
  font-weight: bold;
  padding: 0 5px;
}

.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav {
  flex-direction: row;
}

.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav .nav-item:not(:last-child) {
  margin-right: 69px;
}

.hidentext {
  display: none;
}

.login-indv-form .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.remove-overflow-x {
  overflow-x: hidden !important;
  margin-right: 0px !important;
}

@media screen and (max-width: 767px) {
  .indv-planttree-section {
    margin-right: -8px !important;
  }

  .indv-offset-order-btn {
    padding-top: 137px;
  }
}

.indv-offset-tab-link a {
  color: #aaaaaa !important;
}

.indv-offset-tab-link a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.idv-offset-top-menu ul {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 18px 0;
}

.idv-offset-top-menu ul li {
  display: block;
}

.indv-scrol-sec-subs {
  padding: 0 10px;
}

.indv-scrol-sec-subs p {
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.indv-scrol-sec-subs img {
  width: 24px;
}

.indv-scrol-sec-subs-forward,
.indv-scrol-sec-subs-back {
  background: #eeeeee;
  margin: 0 2px;
  padding: 4px 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.offset-onetime-car-sec {
  background-color: #fafaf8 !important;
  border: 1px solid #88ae47;
  border-radius: 12px;
}

.offset-onetime-car-sec p {
  margin-bottom: 0px;
  color: #797b7a;
}

.offset-onetime-car-sec-head {
  position: relative;
}

.offset-onetime-car-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.other-transport-tab {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin: 0;
}

.other-transport-tab li {
  display: block;
}

.other-transport-tab li {
  padding: 15px 30px;
  position: relative;
  font-weight: 600;
  color: #acacac;
  cursor: pointer;
}

@media (max-width: 360px) {
  .other-transport-tab-ns li {
    padding: 15px 18px !important;
  }
}

@media (min-width: 361px) and (max-width: 390px) {
  .other-transport-tab-ns li {
    padding: 15px 18px !important;
  }
}

@media (min-width: 391px) and (max-width: 450px) {
  .other-transport-tab-ns li {
    padding: 15px 25px !important;
  }
}

.other-transport-tab li.active {
  border-bottom: 1px solid #88ac4c;
  color: #000000;
}

.offset-onetime-car-bottom-sec {
  border-top: 1px solid #f0f0f0 !important;
}

.offset-onetime-car-bottom-sec .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.offset-onetime-car-bottom-sec input {
  border-radius: 10px !important;
}

.offset-onetime-car-bottom-sec-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
}

.offset-onetime-car-bottom-sec2-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
  height: 100%;
}

.offset-onetime-car-bottom-sec-or {
  position: absolute;
  z-index: 99;
  bottom: 124px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.offset-onetime-car-bottom-sec-or p {
  background: #ffffff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
}

.offset-car-vehicle-detail {
  border-bottom: solid 1px #ececea;
}

.offset-car-vehicle-detail-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-car-vehicle-detail-btn button {
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  font-weight: 500;
  width: 100%;
}

/* ---------------Offset Flight Tab css ----------------------- */
.carbon-offset-flight-sec {
  padding: 0px 20px 10px 20px;
  margin-top: -35px;
}

.carbon-offset-flight-sec-ns {
  font-weight: 600 !important;
  font-size: 15px;
  color: #797b7a;
}

.carbon-offset-flight-sec .ant-card {
  border-radius: 14px;
}

.carbon-offset-flight-sec .ant-card-bordered {
  border: 2px solid #88ae47;
  background: #fafaf8;
}

.offset-flight-radio .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
  border-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper {
  margin-right: 30px;
}

.offset-flight-radio .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner::after {
  background-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #63959b !important;
}

.flight-card-content {
  padding-top: 20px;
}

.flight-card-content .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 13px 18px;
  height: 50px;
}

.flight-card-content .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.flight-card-content .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.FlightRightLeft {
  width: 30px;
  height: 30px;
}

.pad-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flight-card-content .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flight-card-content .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  margin: 0px 30px;
  cursor: pointer;
  width: 30px;
}

.offset-order-summery-label .flight-pass-data p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 95px;
}

.flight-card-content .flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-card-content .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carbon-offset-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 55px !important;
  float: right;
}

.flight-card-content .ant-btn:hover,
.ant-btn:focus {
  border-color: transparent !important;
}

/* ------------------------------------------------- */
.greentxt {
  color: #88ae47 !important;
}

.offset-mtp {
  margin-top: -15px !important;
}

.offset-order-summery-label {
  padding-top: 10px;
}

.offset-order-summery-label .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

@media (max-width: 470px) {
  .text-align-lifestyle-ns {
    padding-top: 74px !important;
  }
}

.offset-order-summery-label .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.offset-order-summery-label .mr-lft {
  margin-left: 30px;
}

.offset-order-summery-label .mr-rght {
  margin-right: 30px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card img {
  width: 20px;
}

.offset-order-summery-label .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.offset-onetime-household-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-onetime-household-sec-text p {
  color: #9f9f9f;
}

.offset-house-btn-sec {
  border-top: solid 1px #ececea;
}

.other-transport-add-stage-btn button {
  background: none !important;
  color: #88ae47 !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
}

.other-transport-add-stage-btn button :hover {
  background: none;
  color: #88ae47;
}

.btn-img-span {
  width: 25px;
  padding-bottom: 2px;
}

.other-transport-remove-btn {
  padding-top: 8px;
  cursor: pointer;
}

.other-transport-remove-btn img {
  border: solid 1px #ececea;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
}

.padding-t-b {
  padding: 20px 0;
}

.offset-flight-card {
  padding: 20px 0;
}

.offset-flight-card-padding {
  margin-left: 65px;
}

.offset-flight-card .ant-row .ant-col label {
  font-size: 18px;
  font-weight: 800;
}

.offset-flight-card .ant-row .ant-col span {
  font-size: 14px;
  color: #acacac;
  font-weight: 500;
  display: block;
  /* word-break: break-word; */
}

.car-from-to-heading-ns {
  font-size: 18px !important;
  font-weight: 800 !important;
  font-family: Poppins, sans-serif !important;
}

.car-from-to-value-ns {
  font-size: 14px !important;
  color: #acacac !important;
  font-weight: 500 !important;
}

.car-from-to-container-ns {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.ct_block_sec {
  display: block;
}

@media (min-width: 769px) and (max-width: 960px) {
  .car-from-to-container-ns {
    flex-wrap: wrap;
  }
}

.offset-flight-card .flight-place-way img {
  background: #fff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
  position: relative;
  top: -34px;
  left: 90px;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .offset-flight-card .flight-place-way img {
    left: 27px !important;
  }
}

.off-ord-flight-data-card .ant-row .ant-col span {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 0;
}

.plant-tree-gift-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 !important;
}

.txt-algn-cntr {
  align-items: center;
}

@media (max-width: 768px) {
  .txt-algn-cntr {
    justify-content: space-between;
  }
}

.txt-algn-baseline-ns {
  align-items: unset !important;
}

.off-ord-flight-data-card .ant-row .ant-col .text-end {
  text-align: end;
}

.off-ord-flight-data-card .ant-row .text-end label {
  font-size: 14px;
  padding: 15px 0;
}

.offset-flight-card hr {
  margin-top: 25px;
}

.offset-flight-card-ns hr {
  margin-top: 14px;
}

.offset-flight-card .car-place-line {
  border-top: 1px dashed #acacac;
}

.car-place-line {
  border-top: 1px dashed #acacac;
}

.dsp-grid {
  display: grid !important;
}

.pb-15 {
  padding-bottom: 15px;
}

.select-validation {
  color: red;
}

.plant-tree-ord {
  /* padding-left: 20px; */
}

.plant-tree-ord ul li {
  list-style: none;
}

.txt-no-input {
  font-size: 16px;
  font-weight: 600;
  color: #78777c;
}

.Life_style_NoData {
  display: flex;
  justify-content: center;
}

.ant-popover-inner-content {
  width: 430px;
}

.have_gift-code {
  margin-bottom: 5px;
}

.gift-code-input {
  padding-top: 20px;
}

.gift_card_total {
  padding: 0 20px;
}

.second_cart_projects {
  background-color: #fff !important;
  margin-top: 20px !important;
}

.second_cart_projects .card_project_content {
  padding: 20px 25px 0px 20px;
}

.card_project_content img {
  width: 115px;
}

.card_project_content p {
  color: #acacac;
  padding-top: 15px;
  font-size: 16px;
}

.gift_apply_remove_btn {
  border-radius: 10px !important;
  height: 35px !important;
}

.text_capital {
  text-transform: capitalize;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: normal !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 15px !important;
}

.ant-row.remove-overflow-x.py-3.px-2.ct_alignment_center {
  align-items: flex-start;
}

.offset-top-bg img {
  width: 100%;
}

.validate_error {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 400;
}

.offset-cards-row {
  position: relative;
  bottom: 48px;
}

.off-set-car-img-sec-img img {
  width: 100%;
  max-height: 455px;
  min-height: 455px;
}

.off-set-flight-img-sec-img img {
  width: 100%;
  max-height: 385px;
  min-height: 385px;
}

.off-set-car-img-sec .head p {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.off-set-car-img-sec .text p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.off-set-car-img-sec .text2 p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.font-text16 {
  font-size: 16px;
}

.car-related-summary {
  justify-content: space-between;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .offset-flight-image {
    display: flex !important;
    height: 30px;
    margin-top: 10px;
  }

  .offset-flight-card hr {
    margin-top: 25px;
    width: 300px;
  }

  .offset-flight-card-ns hr {
    margin-top: 14px;
    width: 300px;
  }

  .offset-flight-card .flight-place-way img {
    left: 133px !important;
  }

  .car-from-to-text>label {
    display: flex;
    justify-content: center;
  }

  .indi-offset-flight-radio {
    display: flex !important;
    justify-content: space-evenly !important;
  }

  .indi-offset-flight-radio .ant-radio-wrapper {
    margin-right: 0px !important;
  }
}

.offset-lifestyle-text-para {
  /* margin-top: -15px; */
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .subs-offset-cards>.ant-row {
    display: block;
  }

  .ct_position_8 {
    position: relative;
    top: 8px;
  }
}

@media screen and (min-width: 577px) {
  .offset-lifestyle-text-para {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .order-summary-add-cart-btn-ns {
    display: flex !important;
    justify-content: center !important;
  }

  .order-summary-add-cart-btn .ant-row {
    margin-left: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .carbon-offset-flight-sec {
    padding: 0px 20px 10px 20px;
    margin-top: 0px;
  }
}

/* --------------Mobile Responsive Start css by Shlok */
@media screen and (min-width: 360px) and (max-width: 670px) {
  .one-time-offset-sec .ant-row {
    flex-flow: row wrap;
    /* display: block; */
  }

  .one-time-offset-sec .ant-col-lg-21 {
    max-width: 92% !important;
  }

  .one-time-offset-second .ant-col-lg-21 {
    max-width: 90% !important;
  }

  .ant-card-head {
    margin-top: 20px;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }

  .offset-car-vehicle-detail-btn button span {
    display: flex;
    justify-content: center;
  }

  .business-detail-head {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .off-set-car-img-sec .head p {
    font-size: 18px !important;
  }

  .off-set-car-img-sec .text p {
    font-size: 13px !important;
  }

  .off-set-car-img-sec .text2 p {
    font-size: 13px !important;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1399px) {
  .offset-flight-card .flight-place-way img {
    left: 55px;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }
}

/* mobile an tablet responsive */
@media screen and (min-width: 360px) and (max-width: 768px) {
  .cart-card-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .one-time-offset-sec .ant-col {
    flex-flow: row wrap;
    justify-content: space-between;
    /* margin: 5px 0px; */
    margin: 0px;
  }

  .one-time-offset-sec .ant-col .ant-btn {
    flex-flow: row wrap;
    justify-content: center;
    /* margin: 5px 0px; */
    margin: 0px;
  }
}

/* Mobile Responsive */
@media screen and (min-width: 360px) and (max-width: 576px) {
  .other-transport-header {
    display: flex;
    flex-direction: column;
  }

  .other-transport-tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .offset-onetime-car-bottom-sec-or {
    /* position: relative; */
    margin-bottom: -33px;
  }

  .carbon-offset-employee-btn button {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .offset-onetime-car-bottom-sec-or {
    position: relative;
    margin-bottom: -33px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .other-transport-tab {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

@media screen and (min-width: 360px) and (max-width: 369px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 7px 15px -33px 131px;
  }
}

@media screen and (min-width: 671px) and (max-width: 682px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 283px 0px -37px -62px;
  }
}

@media screen and (min-width: 683px) and (max-width: 768px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 154px -40px 23px 1px;
  }
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .carbon-offset-btn {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    padding: 0px 40px !important;
  }

  .order-summary-header {
    text-align: center;
  }

  .ns-order-summary-header {
    font-size: 22px;
  }

  .indv-offset-order-summay .ant-card {
    background-color: #fafafa !important;
    box-shadow: 0 0 10px #ecececcc;
    border: 1px solid #f0f0f0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .carbon-offset-btn {
    padding: 0px 20px !important;
  }
}

@media (min-width: 769px) and (max-width: 2400px) {
  .indv-offset-order-btn-cart {
    width: 100% !important;
  }
}

.dropdown-border-ns {
  padding: 0 0 50px 0;
  margin-left: auto;
  margin-right: auto;
}

.select-border-ns {
  width: 100%;
  border: 1px solid #88ae47;
  border-radius: 10px;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.ct_login_width_bg {
  /* background-image: url(login_bg_1.jpg); */
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow-y: auto;
}

.ct_login_width_bg:before {
  content: "";
  position: absolute;
  top: 0px;
  background-color: #000;
  height: 100vh;
  width: 100%;
  opacity: 0.6;
  z-index: -1;
}

h2.ct_center_position {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  margin-bottom: 0px !important;
}

.ct_login_bg12 {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.ct_login_tab {
  flex-direction: column;
}

.ct_login_bg12 .tab-content {
  flex: 1;
  padding: 15px;
}

.ct_login_tab a.nav-link.active {
  background: #86af48 !important;
  border-radius: 0px;
}

.ct_login_tab a.nav-link {
  text-align: center;
  padding: 29px 40px;
  color: #333;
}

.ct_login_tab a.nav-link i {
  font-size: 24px;
}

ul.nav.nav-pills.ct_login_tab {
  border-right: 1px solid #d8d8d8;
}

.ct_login_bg12 .tab-content .tab-pane {
  padding: 0px;
}

.ct_login_form_cnt12 h5 {
  text-align: center;
}

.ct_login_tab a.nav-link.active p {
  color: #fff !important;
}

.ct_login_form_cnt12 .ant-form-item {
  margin-bottom: 14px;
  padding-inline: 13px;
}

.ct_input_fixed_icon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 16px;
  fill: #a2a2a2;
}

.ct_login_form_cnt12 .ct_input {
  text-indent: 30px;
  background: #eee !important;
  color: #333 !important;
  font-weight: 400 !important;
}

.ct_login_form_cnt12 .ct_input:focus {
  box-shadow: unset !important;
  border-color: #86af48 !important;
}

.ct_login_form_cnt12 .ct_input::placeholder {
  color: #a2a2a2 !important;
}

.ct_login_btn {
  border: 1px solid #86af48;
  background-color: #86af48;
  outline: none;
  width: 100%;
  padding: 8px 10px;
  color: #fff;
  margin-top: 30px;
  font-weight: 500;
  transition: 0.4s ease all;
  z-index: 1;
  position: relative;
}

.ct_login_btn:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 0.4s ease all;
  transform: scaleX(0);
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.ct_login_btn:hover:before {
  transform: scaleX(1);
}

.ct_login_btn:hover {
  color: #86af48;
}

.ct_forgot_password {
  color: #a2a2a2;
  font-weight: 500;
  text-decoration: none;
}

.ct_login_tab a.nav-link.active img {
  filter: invert(1);
}

.ct_login_tab a.nav-link img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.ct_login_form_cnt12 h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-weight: 400;
  background-color: rgb(144 195 69 / 17%);
  padding: 30px;
  border-radius: 10px;
}

.ct_center_position svg {
  width: 47px;
  display: block;
  margin: auto;
  margin-bottom: 15px;
  fill: #82aa45;
}

@media screen and (max-width: 991px) {
  .ct_login_bg12 {
    flex-direction: column;
  }

  .ct_login_tab a.nav-link {
    border-bottom: 1px solid #d8d8d8;
  }
}