

$clr_white: #fff;
$clr_offwhite: rgba(255,255,255,0.8);
$clr_black: #000;
$clr_emperor: #666666;
$clr_selago: #F4F7FE;
$clr_yellow: #FFC422;
$clr_gray: #888888;
$clr_albastor: #f9f9f9;
$clr_athens: #F0F4FF;
$clr_sand: #F5F5F5;
$clr_red:#FF3838;
$clr_lochi:#338F7A;
$clr_jaffa:#F59946;
$clr_cod:#181818;

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/
@mixin transition($time) {
    -webkit-transition: $time;
    transition: $time;
}

@mixin transform($tr_amt) {
    -webkit-transform: $tr_amt;
    -moz-transform: $tr_amt;
    transform: $tr_amt;
}

@mixin transform-origin($trl_amt) {
    transform-origin: $trl_amt;
    -webkit-transform-origin: $trl_amt;
    -moz-transform-origin: $trl_amt;
    -ms-transform-origin: $trl_amt;
    -o-transform-origin: $trl_amt;
}

@mixin transform-style($trm_amt) {
    transform-style: $trm_amt;
    -webkit-transform-style: $trm_amt;
    -moz-transform-style: $trm_amt;
    -ms-transform-style: $trm_amt;
    -o-transform-style: $trm_amt;
}

%flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

}

%inline_flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

}

%flex_column_center {
    @extend %flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

%flex_column_between {
    @extend %flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

%flex_column_end {
    @extend %flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

%align_center {
    @extend %flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

%align_start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%align_end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
}
%flex_justify_between {
    @extend %flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

%flex_justify_end {
    @extend %flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

%flex_justify_start {
    @extend %flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

%flex_start {
    @extend %flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

%flex_start {
    @extend %flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%flex_between {
    @extend %flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;

}

%flex_end {
    @extend %flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

@media only screen and (max-width:575px) {
    .xs-center {
        text-align: center;
    }
    .xs-none {
        display: none;
    }
    .smt-15{
        margin-top: 15px!important;
    }
    .content-wrapper{
        margin-top:215px;
    }
    .breadcrumb-wrap {
        .breadcrumb-title{
            .breadcrumb-menu{
                li{
                    font-size: 14px;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
        .breadcrumb-img{
            img{
                display: none;
            }
        }
    }
    //Counter CSS
    .counter-card-wrap{
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 25px 20px;
                text-align: center;
                .counter-icon{
                    width: 100%;
                    i{
                        font-size: 48px;
                        margin: 0 auto;
                    }
                }
                .counter-text{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 10px;
                    h2{
                        font-size: 22px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    //Team Details CSS
    .team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-title {
        font-size: 14px;
    }
    .team-desc  .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
        padding: 1px 6px;
    }
    //Team Details CSS
    .progess-bar-item {
        .single-skill{
            p{
                font-size: 14px;
            }
            .circle-chart {
                width: 120px;
                height: 120px;
            }
        }
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid{
        max-width: 540px;
        padding:0 15px;
    }
    //Counter CSS
    .counter-card-wrap{
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 40px 25px;
                .counter-icon{
                    width: 65px;
                    i{
                        font-size: 62px;
                    }
                }
                .counter-text{
                    width: calc(100% - 85px);
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    p,input,textarea {
        font-size: 14px;
    }
    .btn{
        font-size: 14px;
    }
    .sm-none {
        display: none !important;
    }
    .sm-center {
        text-align: center !important;
    }

    .mb-50 {
        margin-bottom: 30px !important;
    }

    .pt-100 {
        padding-top: 50px
    }
    .pb-50 {
        padding-bottom: 0px
    }

    .pb-60 {
        padding-bottom: 15px;
    }

    .pb-100 {
        padding-bottom: 50px;
    }

    .mt-100 {
        margin-top: 50px!important;
    }
    
    .mb-100 {
        margin-bottom: 50px!important;
    }
    .mtb-100 {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pt-70 {
        padding-top: 20px;
    }

    .pb-70 {
        padding-bottom: 20px;
    }

    .pb-75 {
        padding-bottom: 25px;
    }

    .pb-50 {
        padding-bottom: 0px
    }

    .pt-75 {
        padding-top: 25px
    }

    .mb-40 {
        margin-bottom: 25px !important;
    }
    .section-title.mb-50,
    .content-title.mb-50{
        margin-bottom: 30px!important;
    }
    .section-title.mb-40{
        margin-bottom: 20px!important;
    }
    .link,.btn{
        font-size: 14px;
    }
    .btn{
        padding:12px 25px 12px;
    }
    .back-to-top {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 32px;
        right: 10px;

        i {
            font-size: 18px;
        }
    }
    .section-title,
    .content-title{
        &.style2,
        &.style3{
            span {
                font-size: 14px;
            }
        }
        &.style1{
            span {
                font-size: 14px;
            }
        }
        h2{
            font-size: 24px;
            line-height: 1.3;
        }
        &.style3{
            span{
                padding-bottom: 6px;
                &.bl-text{
                    right: 0px;
                    font-size: 40px;
                }
            }
        }
        &.style4{
            span{
                &.bl-text{
                    right: 0px;
                    font-size: 40px;
                }
            }
        }
    }
    .content-wrapper{
        margin-top: 242px;
    }
    .content-feature-list {
        li {
            margin: 0 0 12px;
            font-size: 14px;
            i {
                top: 3px;
            }
        }
    }
    .breadcrumb-wrap {
        padding: 60px 0;
    }
    .breadcrumb-title {
        h2{
            font-size: 24px;
            margin-bottom: 10px;
        }
        .breadcrumb-menu {
            li {
                font-size: 14px;
                &:after{
                    font-size: 15px;
                }
                a{
                    font-size: 14px;
                }
            }
        }
    }
    .owl-carousel {
        .owl-nav {
            button{
                &.owl-prev,
                &.owl-next{
                    width: 40px;
                    height: 40px;
                    i{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    //Card Title CSS
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3{
        font-size:20px;
    }
    //Hero CSS
    .hero-wrap{
        margin-top: 242px;
        .hero-content{
            margin-bottom: 30px;
            span{
                margin-bottom: 8px;
            }
            h1{
                margin-bottom: 15px;
                font-weight: 700;
            }
            p{
                margin-bottom: 25px;
            }
        }
        &.style1{
            .hero-content{
                padding: 70px 0 0px;
                h1{
                    font-size: 28px;
                }
            }
            .hero-shape-six{
                max-width: 45px;
            }
            .hero-shape-three{
                max-width: 80px;
            }
            .hero-shape-four{
                max-width: 70px;
            }
            .hero-skull-shape{
                max-width: 70px;
                right: 55%;
            }
            .hero-img-wrap{
                img{
                    max-width: 280px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 60px 0 220px;
                &:after {
                    background: rgba(0, 0, 0, 0.8);
                }
            }
            .hero-content{
                h1{
                    font-size: 28px;
                }
            }
        }
        &.style3{
            padding: 60px 0 55px;
            .hero-content{
                margin-bottom: 0;
                h1{
                    font-size: 28px;
                    color: $clr_white;
                }
                p{
                    color: $clr_offwhite;
                }
            }
            .hero-shape-one {
                width: 100%;
            }
            .hero-shape-three {
                max-width: 100px;
            }
            .hero-shape-two {
                max-width: 120px;
            }
            .hero-shape-four {
                opacity: 0.3;
            }
            .hero-img-wrap{
                margin-left: 30px;
                 .hero-img-shape {
                    left: -50px;
                    max-width: 80px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 195px;
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 40px;
                        height: 40px;
                        i{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: auto;
            bottom: 160px;
            right: auto;
            left: 50%;
            width: 100%;
            text-align: center;
            @include transform(translate(-50%,0));
            button{
                display: inline-block;
                width: 45px;
                height: 45px;
                margin: 0 10px 0 0;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
    //Promo CSS
    .promo-wrap{
        &.style2 {
            margin-top: -120px;
            .promo-card {
                width: 100%;
                padding: 30px 20px;
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
    //About CSS
    .about-wrap{
        .about-content{
            .content-title{
                margin-bottom: 20px;
            }
            .content-feature-list{
                margin-bottom: 25px;
            }
        }
        &.style1{
            .about-shape-one{
                max-width: 200px;
            }
            .about-shape-two{
                max-width: 60px;
            }
            .about-shape-three{
                max-width: 150px;
            }
            .about-img-wrap{
                height: 350px;
                .about-quote{
                    bottom: 20px;
                    width: calc(100% - 40px);
                    padding: 20px;
                    i{
                        top: 25px;
                        left: 20px;
                        font-size: 30px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        padding-left: 45px;
                    }
                }
            }
        }
        &.style2{
            .about-shape-one {
                bottom: 10px;
                right: 20px;
                max-width: 100px;
            }
        }
    }
    //Project Card CSS
    .project-card{
        &.style1{
            .project-info{
                padding: 20px;
                h3{
                    margin: 0 0 12px;
                }
                p{
                    margin: 0 0 15px;
                }
                .project-shape{
                    max-width: 120px;
                }
            }
        }
        &.style3{
            .project-info{
                padding: 18px 20px;
                h3{
                    margin: 0 0 12px;
                }
                .progressbar-wrap {
                    margin-bottom: 15px;
                }
            }
        }
        &.style4{
            .project-img{
                width: 100%;
            }
            .project-info{
                width: 100%;
                margin: 0;
                padding: 20px;
                h3{
                    margin-bottom: 12px;
                }
                .progressbar-wrap {
                    margin-bottom: 10px;
                }
            }
        }
    }
    //Team Card CSS
    .team-card{
        &.style1{
            .team-info{
                padding: 20px;
            }
        }
        &.style2{
            .team-info-wrap{
                padding: 20px;
            }
        }
    }
    //CTA Section CSS
    .cta-wrap{
        &.style1{
            .cta-btn{
                text-align: center;
                .btn{
                    padding: 11px 15px;
                    margin-right: 0;
                    display: block;
                    width: 100%;
                    &:nth-child(2){
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .cta-wrap.style1::after,
    .cta-wrap.style2::after {
        z-index: -1;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0.95)), color-stop(100%, rgba(255, 255, 255, 0.3)));
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0.3) 100%);
    }
     //Event Card CSS
     .event-card{
        &.style1,
        &.style2,
        &.style3{
            .event-img {
                width: 100%;
                border-radius: 5px 5px 0 0;
                img{
                    border-radius: 5px 5px 0 0;
                }
            }
            .event-info {
                padding: 18px 20px;
                width: 100%;
                margin-left: 0;
            }
        }
        &.style4,
        &.style5{
            .event-info{
                padding: 18px 20px;
            }
        }
    }
    .newsletter-form{
        &.style1{
            padding: 22px 20px 25px;
            h4{
                font-size: 20px;
            }
            p{
                margin: 0 0 15px;
            }
            .form-group{
                margin-bottom: 15px;
                input{
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }
    .event-search-form{
        padding: 20px 20px 0;
        .form-group{
            select,input{
                height: 52px;
                font-size: 14px;
            }
            button{
                margin-top: 0;
            }
        }
    }
    //Counter CSS
    .promo-video{
        &.style1{
            height: 350px;
        }
        &.style2{
            .play-now {
                margin-top: 20px;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 50px 0px 75px 0px;
            .counter-card{
                padding: 30px 20px;
                max-height: 125px;
                &:nth-child(2),
                &:nth-child(4){
                    top: 50px;
                }
                .counter-text{
                    h2{
                        font-size: 26px;
                    }
                }
                &.style1{
                    border-radius: 40px 40px 0 40px;
                }
                &.style2{
                    border-radius: 40px 40px 40px 0;
                }
                &.style3{
                    border-radius: 40px 0 40px 40px;
                }
                &.style4{
                    border-radius: 0 40px 40px 40px;
                }
            }
        }
    }
    //Donate Box
    .donate-box {
        padding-left: 25px;
        padding-right: 25px;
        .donate-amt {
            li{
                margin-right: 12px;
                margin-bottom: 12px;
                button {
                    font-size: 14px;
                    padding: 10px 18px;
                }
            }
        }
    }
    //Blog CSS
    .blog-card{
        .blog-info{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .blog-author{
                .blog-author-info{
                    span{
                        font-size: 14px;
                    }
                }
            }
            .blog-metainfo{
                li{
                    a{
                        font-size: 14px;
                        i{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &.style1{
            .blog-info{
                padding: 20px;
                .blog-author{
                    margin: 0 0 18px;
                }
                h3{
                    margin-bottom: 12px;
                }
                p{
                    margin-bottom: 12px;
                }
            }
            .link{
                padding: 13px 15px;
            }
        }
        &.style2{
            .blog-info{
                padding: 20px;
                .blog-author{
                    margin: 0 0 18px;
                }
                h3{
                    margin-bottom: 12px;
                }
                p{
                    margin-bottom: 12px;
                }
            }
            .link{
                padding: 13px 15px;
            }
        }
        &.style3{
            padding: 20px;
            .blog-info{
                padding-top: 20px;
            }
        }
    }
    //Slider btn css
    .project-slider-one{
        &.owl-carousel{
            .owl-nav{
                justify-content: center;
                margin: 15px 0 20px;
                button{
                    &.owl-prev,
                    &.owl-next{
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        width: 40px;
                        height: 40px;
                        @include transform(translateY(0));
                        i{
                            font-size: 22px;
                        }
                    }
                    &.owl-prev{
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    //Event & Project Details CSS
    .event-info-item-wrap{
        .event-info-item {
            padding-bottom: 15px;
            margin-bottom: 15px;
            p,span {
                font-size: 14px;
            }
        }
    }
    .project-info-item-wrap {
        .project-info-item {
            margin-bottom: 20px;
        }
    }
    .project-desc {
        .wp-block-quote {
            padding: 20px;
            p{
                font-size: 14px;
            }
        }
    }
    .register-widget {
        .form-group {
            input,
            select{
                height: 52px;
                font-size: 14px;
            }
        }
    }
    //Accordion CSS
    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-size: 16px;
                padding: 15px 40px 15px 20px;
                span {
                    min-width: 30px;
                    padding: 20px 10px;
                    i{
                        font-size: 20px;
                        right: 15px;
                    }
                }
            }    
        }
        .accordion-body {
            padding: 20px 20px 20px;
        }
    }
    //Donation CSS
    .donation-form{
        padding: 20px;
        h2{
            font-size: 20px!important;
        }
        h4{
            margin: 20px 0 20px;
        }
        .radionbtn-wrap{
            .radionbtn{
                label{
                    font-size: 14px;
                }
            }
        }
        .form-group{
            margin-bottom: 15px;
            input,select{
                height: 52px;
                padding: 12px 16px;
                font-size: 14px;
            }
        }
        .form-check{
            margin-bottom: 15px;
        }
    }
    .checkbox {
        label {
            &:before {
                top: 1.5px;
            }
        }
    }
    .checkbox input:checked + label:after {
        content: "";
        top: 4px;
    }
    //Contact CSS
    .contact-item-wrap{
        padding: 20px 20px;
        .contact-item {
            margin-bottom: 20px;
            h3{
                font-size: 20px;
                margin: 0 0 8px;
            }
            p,a{
                font-size: 14px;
            }
        }
    }
    #contactForm {
        padding: 20px 20px 15px;
        .form-group {
            margin: 0 0 15px;
            input {
                height: 52px;
            }
            textarea {
                height: 120px;
            }
        }
    }
    .checkbox{
        label {
            font-size: 14px;
        }
    }
    //Blog Details CSS
    article {
        margin-bottom: 18px;
        padding-bottom: 14px;
    }
    .event-desc .single-project-img,
    .event-desc .single-event-img,
    .project-desc .single-project-img,
    .project-desc .single-event-img {
        margin: 5px 0 20px;
    }
    article,
    .project-desc,
    .service-desc,
    .event-desc,
    .team-desc,
    .terms-wrap{
        h1{
            font-size: 26px;
        }
        h2{
            font-size: 24px;
        }
        h3{
            font-size: 22px;
        }
        h4{
            font-size: 20px;
        }
        h5{
            font-size: 18px;
        }
        h6{
            font-size: 16px;
        }
        h1,h2,h3,h4,h5,h6{
            margin-bottom: 15px;
        }
        ol{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ul,ol{
            li{
                font-size: 14px;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                }    
            }
        .content-feature-list{
            margin: 15px 0 15px;
        }
    }
    .post-author .post-author-info h4 {
        font-size: 20px;
    }
    .wp-block-quote {
        padding: 25px;
        margin: 20px 0 20px;
    }
    .post-metainfo {
        margin-bottom: 15px;
        li {
            margin-bottom: 0;
        }
    }
    .post-img {
        margin: 0 0 25px;
    }
    .author-info-wrap {
        .author-info {
            h6 {
                font-size: 15px;
            }    
        }
    }
    .post-tag {
        margin-top: 15px;
            ul {
            li{
                a {
                    font-size: 13px;
                }
            }
        }
    }
    .post-author {
        padding: 20px;
        .post-author-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
        .post-author-info {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
            h4 {
                font-size: 14px;
               a{
                font-size: 20px;
               }
            }
        }
    }
    .comment-box-title {
        margin-bottom: 20px;
    }
    .comment-box-wrap {
        padding: 20px;
    }
    .comment-item-wrap {
        .comment-item {
            padding-bottom: 20px;
            margin-bottom: 20px;
            &.reply{
                margin-left: 25px;
            }
            .comment-author-img {
                width: 65px;
                height: 65px;
            }
            .comment-author-wrap {
                width: 100%;
                margin-left: 0;
                margin-top: 22px;
                .comment-author-name{
                    margin: 0 0 11px;
                    h5{
                        font-size: 18px;
                        display: block;
                    }
                }
                .comment-text{
                    margin-bottom: 8px;
                }
            }
        }
    }
    
    #cmt-form {
        .comment-form{
            .form-group{
                margin-bottom: 15px;
                label{
                    font-size: 14px;
                }
                input{
                    height: 54px;
                }
            }
           .form-check{
               margin-bottom: 15px;
           }
        }
    }
    .sidebar {
        .sidebar-widget {
            padding:20px;
            h4 {
                font-size: 20px;
                margin: 0 0 20px;
                padding-bottom: 15px;
            }
        }
     }
    .category-box{
         ul{
             li{
                 a{
                     font-size: 14px;
                     padding:8px 10px 8px 0;
                     i{
                         top: 18px;
                     }
                 }
             }
         }
    }
    .tag-list {
        ul {
            li {
                margin: 9px 6px 0px 0px;
            }    
        }
    }
    .pp-post-item{
        .pp-post-info {
            h6 {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 10px;
            }
            .pp-post-date {
                font-size: 13px;
                i{
                    font-size: 14px;
                }
            }
        }
    }
    .page-nav {
        margin-top: 15px;
        li{
            a{
                width: 36px;
                height: 36px;
                font-size: 14px;
                line-height: 34px;
                i{
                    font-size: 13px;
                    top: 2px;
                }
            }
        }
    }
    //Account Section CSS
    .login-form-wrap {
        padding: 30px 20px 20px;
       .login-header {
           h3 {
               font-size: 20px;
           }
       }
       .login-body{
           .form-group{
               margin-bottom: 10px;
               label{
                   font-size: 14px;
                   margin-bottom: 5px;
               }
                input {
                height: 50px;
                }
           }
           .checkbox{
               label{
                   font-size: 13px;
               }
           }
           .btn{
               padding: 12px;
           }
           .link{
               font-size: 13px;
           }
       }
    }
    //Error Page
    .error-content {
        img{
            max-width:280px;
        }
        p {
            margin: 0 auto 20px;
            max-width: 300px;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container-fluid{
        max-width: 720px;
        padding-left:15px;
        padding-right:15px;
    }
    .pt-100 {
        padding-top: 70px
    }

    .pb-100 {
        padding-bottom: 70px;
    }

    .mt-100 {
        margin-top: 70px!important;
    }

    .mb-100 {
        margin-bottom: 70px!important;
    }
    .mtb-100 {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }
    .pb-50 {
        padding-bottom: 20px
    }

    .pb-60 {
        padding-bottom: 35px;
    }

    .mt-100 {
        margin-top: 70px !important;
    }

    .smb-25 {
        margin-bottom: 25px;
    }

    .smb-70 {
        margin-bottom: 70px !important;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pt-70 {
        padding-top: 40px
    }

    .pb-70 {
        padding-bottom: 40px
    }

    .pb-75 {
        padding-bottom: 45px
    }

    .pb-50 {
        padding-bottom: 20px
    }

    .pt-75 {
        padding-top: 45px
    }
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 32px;
        }
    }
    .content-wrapper{
        margin-top:128px;
    }
    .breadcrumb-wrap {
        padding: 70px 0;
    }
    //Card Title CSS
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3{
        font-size:20px;
    } 
    //Hero CSS
    .hero-wrap{
        margin-top: 130px;
        &.style1{
            .hero-content{
                padding: 60px 0 30px;
                h1{
                    font-size: 44px;
                }
            }
            .hero-img-wrap{
                img{
                    max-width: 650px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 70px 0 200px;
            }
            .hero-content{
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style3{
            padding: 80px 0;
            .hero-content{
                h1{
                    font-size: 30px;
                }
            }
            .hero-shape-two {
                max-width: 120px;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 145px;
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: 50%;
            right: 30px!important;
        }
    }
    //Promo Card CSS
    .promo-wrap.style2 .promo-card {
        padding: 28px 10px;
    }
    //Donate Box
    .donate-box {
        .donate-amt {
            li{
                button {
                    font-size: 16px;
                    padding: 13px 20px;
                }
            }
        }
    }
    //Event Card CSS
    .event-card{
        &.style1{
            .event-img {
                width: 315px;
            }
            .event-info {
                width: calc(100% - 335px);
            }
        }
    }
    //Counter CSS
    .promo-video{
        &.style1{
            height: 450px;
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 70px 0px 95px 0px;
        }
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 40px 65px;
                .counter-icon{
                    width: 75px;
                    i{
                        font-size: 72px;
                    }
                }
                .counter-text{
                    width: calc(100% - 95px);
                    h2{
                        font-size: 32px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .page-wrapper{
        overflow-x: hidden!important;
    }
    .md-none {
        display: none !important;
    }
    .mt-60 {
        margin-top: 30px !important;
    }
    .md-center {
        text-align: center !important;
    }
    //Contact CSS
    #contactForm{
        margin-bottom: 25px;
    }
    //About CSS
    .about-wrap{
        .about-img-wrap{
            margin-bottom: 25px;
        }
    }
    //Counter CSS
    .counter-wrap{
        &.style1 {
            margin-top: 25px;
        }
    }
    .counter-card-wrap{
        &.style3{
            margin-top: 30px;
        }
    }
    //Slider Btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
             .owl-nav{
                 justify-content: center;
                 margin: 30px 0 0;
                 button{
                     &.owl-prev,
                     &.owl-next{
                         position: relative;
                         left: auto;
                         right: auto;
                         top: auto;
                         width: 40px;
                         height: 40px;
                         @include transform(translateY(0));
                         i{
                             font-size: 17px;
                         }
                     }
                     &.owl-prev{
                         margin-right: 15px;
                     }
                 }
             }
             .owl-dots{
                display: none;
             }
         }
     }
     //Promo Css
     .promo-video{
        &.style3{
            .play-now{
                margin: 25px auto 0;
            }
        }
     }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 34px;
        }
    }
    .breadcrumb-wrap {
        padding: 90px 0;
    }
    .content-wrapper{
        margin-top: 138px;
    }
    //Hero CSS
    .hero-wrap{
        &.style1{
            .hero-content{
                padding: 90px 0;
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 100px 0 200px;
            }
            .hero-content{
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style3{
            padding: 80px 0;
           
            .hero-content{
                h1{
                    font-size: 40px;
                }
            }
            .hero-shape-two {
                max-width: 150px;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 145px;
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: 50%;
            right: 30px!important;
        }
    }
    //Team Details CSS
    .progess-bar-item {
        .single-skill{
            p{
                font-size: 14px;
            }
            .circle-chart {
                width: 120px;
                height: 120px;
            }
        }
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 960px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 300px 0px 125px 30px;
        }
        &.style2,
        &.style3{
            .counter-card{
                .counter-icon{
                    width: 55px;
                    i{
                        font-size: 52px;
                    }
                }
                .counter-text{
                    width: calc(100% - 75px);
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
    //Slider btn css
    .team-slider-one,
    .team-slider-two,
    .blog-slider-one,
    .project-slider-one,
    .project-slider-three{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        background-color: $clr_white;
                        &:hover{
                            background-color: $clr_lochi;
                        }
                    }
                    &.owl-prev{
                        left: -25px!important;
                    }
                    &.owl-next{
                        right: -25px!important;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1199px) {
    .sidebar {
        margin: 50px 0 0;
        &.style2{
            margin: 0 0 25px;
        }
    }
    .lg-none{
        display: none!important;
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                justify-content: center;
                position: relative;
                button{
                    &.owl-prev,
                    &.owl-next{
                       position: relative;
                       top: auto;
                       left: auto;
                       right: auto;
                    }
                    &.owl-prev{
                       margin-right: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:1200px) {
    .xl-none {
        display: none !important;
    }
    .content-title{
        p{
            padding-right: 25px;
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .section-img.style2 {
        right: 10px;
        max-width: 60px;
    }
    .breadcrumb-wrap{
        .section-img {
            max-width: 140px;
        }
    }
    //Hero CSS
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 40px;
                        height: 40px;
                        i{
                            font-size: 18px;
                        }
                    }
                    &.owl-prev{
                       left: 0px!important;
                    }
                    &.owl-next{
                        right: 0px!important;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 0px!important;
        button{
            width: 40px!important;
            height: 40px!important;
        }
    }
    //Slider Btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        background-color: $clr_white;
                        &:hover{
                            background-color: $clr_lochi;
                        }
                    }
                    &.owl-prev{
                        left: -35px!important;
                    }
                    &.owl-next{
                        right: -35px!important;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    //hero CSS
    .hero-wrap{
        &.style1,
        &.style2,
        &.style3{
            .hero-content{
                h1{
                    font-size: 60px;
                    line-height: 1.2;
                }
                p{
                    padding-right: 30px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 5px;
                    }
                    &.owl-next{
                        right: 5px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 5px;
    }
    //Counter CSS
    .counter-card-wrap{
        &.style1 {
            padding: 300px 50px 125px 30px;
        }
    }
    //Slider btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 42px;
                        height: 42px;
                    }
                    &.owl-prev{
                        left: -48px;
                    }
                    &.owl-next{
                        right: -48px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 40px;
        }
    }
    //Hero CSS
    .hero-wrap{
        &.style1{
            .hero-content{
                padding: 110px 0;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 25px;
                    }
                    &.owl-next{
                        right: 25px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 25px;
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 1140px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 300px 30px 125px 30px;
        }
    }
     //Slider btn css
     .project-slider-one,
     .project-slider-three,
     .blog-slider-one,
     .team-slider-one,
     .team-slider-two{
         &.owl-carousel{
             .owl-nav{
                 button{
                     &.owl-prev,
                     &.owl-next{
                         width: 42px;
                         height: 42px;
                     }
                     &.owl-prev{
                         left: -48px;
                     }
                     &.owl-next{
                         right: -48px;
                     }
                 }
             }
         }
     }
     //Popular Post Widget
     .pp-post-item {
        .pp-post-info {
            h6 {
                font-size: 15px;
                margin-bottom: 8px;
            }
        }
    }
    
}
@media only screen and (min-width: 1400px) {
    .breadcrumb-wrap {
        padding: 125px 0;
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 1320px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 48px;
        }
    }
    .breadcrumb-wrap{
       .container{
            max-width: 1520px;
            margin: 0 auto;
       }
    }
    //hero CSS
    .hero-wrap{
        .container{
            max-width: 1520px;
        }
        &.style1,
        &.style2,
        &.style3{
            .hero-content{
                h1{
                    font-size: 70px;
                    line-height: 1.2;
                }
                p{
                    padding-right: 30px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 10px;
                    }
                    &.owl-next{
                        right: 10px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 10px;
    }
    
}
@media only screen and (min-width:1920px) {
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 60px;
                    }
                    &.owl-next{
                        right: 60px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 60px;
    }
}