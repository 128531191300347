@import url(https://fonts.googleapis.com/css2?family=Comforter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,700,900);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;500;600;700&family=Spline+Sans:wght@300;400;500;600;700&display=swap);
@font-face {
    font-family: 'Roboto-Black';
    src: url(/static/media/Roboto-Black.f3fa86ed.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url(/static/media/Roboto-BlackItalic.9f20f498.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(/static/media/Roboto-Bold.e31fcf18.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url(/static/media/Roboto-BoldItalic.b03c5eb4.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url(/static/media/Roboto-Italic.549ec357.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(/static/media/Roboto-Light.46e48ce0.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url(/static/media/Roboto-LightItalic.4356190d.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(/static/media/Roboto-Medium.894a2ede.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url(/static/media/Roboto-MediumItalic.95d76465.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(/static/media/Roboto-Regular.df7b648c.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url(/static/media/Roboto-Thin.94998475.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url(/static/media/Roboto-ThinItalic.8f066370.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}




.idv-login-modal .ant-modal-header {
  border-bottom: 0px !important;
  padding: 16px 24px 0px !important;
}

.idv-login-modal .ant-modal-header .ant-modal-title {
  font-weight: 600;
  font-size: 15px;
}
.idv-login-modal .ant-modal-content {
  border-radius: 12px;
  padding: 0;
}

.login-indv-form-up-txt p {
  margin-bottom: 0px;
}
.business-sign-in-ns {
  color: #88ae47 !important;
  padding-left: 5px;
  background: none;
  outline: none;
  border: none;
}
.idv-login-modal
  .ant-modal-content
  .ant-modal-body
  .ant-row
  .ant-col
  .idv-login-modal-content
  .title {
  font-size: 18px;
  font-weight: 600;
  margin-top: -10px;
  margin-bottom: 12px;
}
.idv-login-modal .ant-modal-content .ant-modal-close-x .anticon {
  color: #000000;
}
.login-indv-form-up-txt p a {
  color: #88ae47 !important;
  padding-left: 5px;
}
.indivibusinessModal {
  height: 100%;
  margin: 0px !important;
  padding-top: 45px;
}
.indivibusinessModal img {
  height: 100% !important;
}
.ant-statistic {
  text-align: center;
}
.login-indv-form input,
.login-indv-form .ant-input-password {
  border-radius: 8px;
}
.login-indv-form .inv-create-pass {
  padding: 40px 0;
}
.indv-login-form-btn-sec {
  justify-content: space-between;
  align-items: center;
}
.indv-login-form-btn-sec .ant-row .ind-web-span a {
  color: #88ae47;
  font-weight: 500;
}
.indv-login-form-btn-sec .ant-row .ind-web-span-lft a {
  color: #88ae47;
  font-weight: 500;
  padding-left: 8px;
}
.ind-web-span-lft {
  padding-left: 22px;
}
.carbon-idv-signin-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 48px !important;
}

.carbon-idv-signin-social-sec hr {
  width: 36%;
}

.carbon-idv-signin-social-sec span {
  font-size: 12px;
}

.idv-login-g-btn button {
  background-color: #ffffff;
  color: #eb3e37;
  border: none;
  width: 100%;
  box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.86);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  font-weight: 600;
}

.idv-login-f-btn button {
  background-color: #ffffff;
  border: none;
  color: #39507e;
  width: 100%;
  box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.86);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  font-weight: 600;
}

.indv-auth-img img {
  width: 100%;
  border-radius: 10px;
  height: 485px;
}
.idv-login img {
  width: 100%;
  border-radius: 10px;
  height: 475px;
}
.idv-login-page img {
  width: 100%;
  border-radius: 10px;
  height: 445px;
}
.indv-auth-create-pass img {
  width: 100%;
  border-radius: 10px;
  height: 430px;
}
.otp_expired_msg {
  color: red;
  display: flex;
  justify-content: center;
}
.indi_bus_Modal {
  top: 65px !important;
}
.indi_bus_Modal .login-indv-form-up-txt {
  margin-bottom: 1rem;
}
.indi_bus_Modal .idv-login-modal-content .indi_bus_form p {
  margin-bottom: 10px;
}
.indi_bus_Modal .idv-login-modal-content .indi_bus_form .ant-form-item {
  margin-bottom: 12px;
}
@media screen and (max-width: 600px) {
  .fix-display {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
  }
  .carbon-idv-signin-social-sec span {
    font-size: 12px;
    white-space: nowrap;
    padding: 0px 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }
  .idv-login-g-btn button {
    width: 150px;
  }
  .idv-login-f-btn button {
    width: 150px;
  }
  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }
  .idv-login-g-btn .g_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-f-btn .f_btn {
    width: 150px;
    padding: 0 30px;
  }
  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
  .idv-login img {
    height: auto;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .idv-login-g-btn .g_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-f-btn .f_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }

}

.carbon-offset-onetime-life-card-img img{
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width:768px){
  .one-time-offset-sec .ant-row {
    flex-flow: row wrap;
    display: block !important;
    width: 100% !important;
}
  .ct_img_full_w_res{
    display: block  !important;
  
  }
  .indv-offset-order-summay {
    margin-top: 30px;
}

}
@media screen and (max-width:767px){
  .ct_social_icon_g.mt-3 {
    justify-content: center;
}
.ct_img_full_width{
  width: 100%;
}
.ct_img_full_width img{
  -o-object-fit: cover;
     object-fit: cover;
}
.ct_img_full_w_res > .ant-col {
  width: 100%!important;
}
}
@media screen and (max-width:576px){
  .ct_social_icon_g {
  flex-flow: row;
}
.ct_social_icon_g .ant-col{
  width: auto;
}
}

@media screen and (max-width:375px){
  .ct_social_icon_g {
  flex-flow: row;
}
.ct_social_icon_g .ant-col{
  width: 100% !important;
  margin-bottom: 10px;
  padding-right: 12px  !important;
}
 .ct_social_icon_g button{
   justify-content: center;
   grid-gap: 10px;
   gap: 10px;
   width: 100% !important;
 }
}

.idv-modal-upper-sec-back button {
  background: #ededed !important;
  border: none;
  border-radius: 15px;
  padding: 4px 12px;
}

.carbon-idv-forgot-btn {
  width: 100%;
}

.indv-pass-success-modal {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.indv-pass-success-modal-check .fas {
  background: #f2f2f2 !important;
  padding: 28px !important;
  border: solid 3px #88ae47 !important;
  border-radius: 56px !important;
  font-size: 40px;
  color: #88ae47;
}

.indv-pass-success-modal-btn button {
  background-color: #88ae47 !important;
  border: none !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 48px !important;
}

.forgot-pass-idv-otp-enter-sec {
  text-align: center;
  padding: 60px 0;
}

.forgot-pass-idv-otp-enter-sec input {
  width: 60px !important;
  height: 55px !important;
  margin-right: 8px !important;
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .signup-otp-section input {
    width: 42px !important;
    height: 42px !important;
  }
  .indv-form-submit-sec{
    width: 295px !important;
  }
  .signup-otp-section{
    margin-left: 10px;
  }
}
@media screen and (min-width: 360px) and (max-width: 992px) {
  .indi-login-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .signup-otp-section input{
    width: 53px !important;
    height: 53px !important;
  }
}

.ind-web-resend span {
  color: #88ae47 !important;
  font-weight: 500;
  cursor: pointer;
}
.idv-otp-form {
  bottom: -23px;
  position: absolute;
  width: 410px;
}
.create-new-pass-padding {
  padding: 70px 0;
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .forgot-pass-idv-otp-enter-sec input {
    width: 50px !important;
    height: 45px !important;
    margin-right: 8px !important;
  }
  .idv-otp-form {
    width: 360px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .forgot-pass-idv-otp-enter-sec {
    padding: 50px 0;
  }
  .idv-otp-form {
    width: 340px;
  }
}

.auth-bg {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.term-cond-btn-foot-signup {
  background: #88ae47 !important;
  color: #fff !important;
  width: 125px;
  border-radius: 6px !important;
}
.idv-signup-modal-term-condition{
  top: 10px !important;
}
.idv-signup-modal-term-condition .ant-modal-content {
  border-radius: 10px;
}
.privacy_height{
  max-height: 185px;
  overflow-y: auto;
}
.privacy_height_adjust::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.privacy_height_adjust{
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}
.inlinePera p:last-child{
  display: inline;
 }
.f-w6 {
  font-weight: 600;
  padding: 0 5px;
}
.term-cond-signup-head p {
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 10px;
}
.padding-data-ui{
  padding: 108px 0;
}
.carbon_footer_bar {
  background-color: #252525 !important;
  min-height: 200px;
  border-bottom: solid 1px #252525;
}

.carbon_footer_bar .container {
  padding-top: 40px;
}

nav {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #000000;
  position: relative;
  z-index: 99;
  min-height: 72px;
}

.fas {
  cursor: pointer;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: tomato;
}

.logo font {
  color: #fff;
}

.footer-nav-sec ul {
  padding: 0;
}

.footer-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-list li a {
  text-decoration: none;
  color: #ffff;
  font-weight: 500;
}
.footer_hr {
  border-top: 1px solid #7d7c81;
  margin-top: 40px;
}
.footer_ul_icons {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.footer_icons {
  display: flex;
  padding-top: 15px;
  padding-bottom: 20px;
  justify-content: space-between;
}
.footer_icons .logo img {
  width: 110px;
}
.footer_icons h4 {
  color: #828186;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.footer_ul_icons {
  padding-left: 0;
}

.footer_ul_icons li {
  padding: 5px 10px;
  margin-left: 5px;
}
.footer_ul_icons li img {
  width: 25px;
  cursor: pointer;
}
.footer_ul_icons li .fb_icon {
  width: 12px;
}

.footer_icons_text {
  display: flex;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .footer-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .footer-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .footer-list li {
    margin-bottom: 20px;
  }

  .footer-list li:last-child {
    margin-bottom: 0px;
  }

  .footer_icons {
    display: flex;
    flex-direction: column;
  }

  .footer_icons .logo {
    display: flex;
    justify-content: center;
  }

  .footer_icons_text {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }
}

@media screen and (max-width: 769px) {
  .footer_ul_icons {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
}

.carbon-navbar .logo img {
  width: 155px;
}
.project_footer {
  color: #fff;
}

.carbon-navbar {
  background-color: #f4f4f4 !important;
  min-height: 72px;
  border-bottom: solid 1px #d0d0d0;
}

@media screen and (max-width: 576px) {
  .carbon-navbar {
    height: 76px;
  }
}

.carbon-navbar-our-approach {
  background: #f4f4f4 !important;
  min-height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
}
.carbon-flight-emission-ns {
  padding-top: 7px;
}
@media screen and (max-width: 576px) {
  .carbon-navbar-our-approach {
    position: relative;
    background-color: #f4f4f4 !important;
  }
  .our-approach-main-sec {
    height: 266px;
    position: relative;
  }
  .carbon-navbar {
    height: 76px;
  }
  .our-approach-main-sec-head {
    position: absolute;
    left: 0;
    right: 0;
    top: 9%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .our-approach-main-sec-head_act {
    position: absolute;
    left: -22px;
    right: 0;
    top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .our-approach-main-sec-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .our-approach-main-sec-text-ns {
    font-weight: 700;
    font-size: 21PX;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 84% !important;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .carbon-flight-emission-ns {
    padding-right: 35px;
    padding-left: 35px;
    padding-top: 7px;
  }
  .flight-footprint-ns {
    font-weight: bold !important;
    line-height: 28px !important;
    color: #404040 !important;
    font-size: 21px !important;
  }
  .carbon-app-reduce-emissions {
    padding: 24px 0px !important;
  }
}
/* .carbon-nav {
  flex-grow: unset !important;
}

.carbon-nav .nav-link {
  color: #000000 !important;
} */

nav {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #000000;
  position: relative;
  z-index: 99;
  min-height: 72px;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: tomato;
}

.logo font {
  color: #fff;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  background-color: #01aacd;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 2px;
} */

.menu-list li a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  padding: 5px 10px;
  margin-left: 20px;
}

/* .menu-list li a:hover,
.menu-list li a.active {
  background-color: #fff;
  border-radius: 2px;
  color: #000;
  transition: all 0.5s ease-in-out;
} */

@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: fixed;
    background: #f4f4f4;
    padding: 20px;
    right: 0;
    top: 64px;
    transition: all 0.2s ease-in-out;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    /* width: 100px; */
    display: block;
    margin: auto;
  }

  .menu-list.close {
    right: -100%;
    transition: all 0.5s ease-in-out;
  }
}

.carbon-navbar .logo img {
  width: 155px;
  cursor: pointer;
}
.carbon-navbar-our-approach .logo img {
  width: 155px;
  cursor: pointer;
}

.header-eko-dropdown {
  padding: 6px 10px !important;
}
.ant-dropdown-open .dropdown_img {
  display: none;
}
.updown_img {
  position: absolute !important;
  display: none !important;
  top: 3px;
  right: 0px;
}
.dropdown_img {
  position: absolute !important;
  /* display: none; */
  top: 3px;
  right: 0px;
}
.drop_down_hover:hover .updown_img {
  position: absolute;
  /* top: 27px; */
  display: block !important;
}
.drop_down_hover:hover .dropdown_img {
  position: absolute;
  /* top: 27px; */
  display: none !important;
}

.hover_div {
  position: absolute !important;
  width: 195px;
  padding: 16px 0px;
  top: 25px;
  left: -6px;
  background: white;
  display: none !important ;
}
.drop_down_hover:hover .hover_div {
  display: block !important;
}
.hover_div a {
  padding: 0 !important;
  margin-left: 0 !important;
}

.hover_div hr {
  margin: 1rem 0 !important;
  color: #838383 !important;
}

.hover-div-dropdown-a {
  display: flex;
  justify-content: flex-start !important;
  margin-left: 1.5rem;
}
.menu-list-items {
  font-size: 14px !important;
}

/* **************.Logo Container css******************** */

.indv-logo-head img {
  width: 116.7px !important;
  cursor: pointer !important;
}
.ant-table-pagination.ant-pagination {
  display: none;
}
.class-pag {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.class-pag-order {
  padding-right: 18px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}
.ns-page-desktop {
  padding: 15px 0px 15px 0px;
}
.order_date {
  padding-left: 15px;
  width: 100%;
  height: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  /* align-items: center; */
  color: #90a0b7;
}
.plantTreeClose {
  color: #ffff;
  font-size: 20px;
  cursor: pointer;
  float: right;
}
.selected {
  font-weight: bolder;
  margin: 0px 12px !important;
  padding: 0px 8px !important;
  border: 1px solid var(--color-individual) !important;
}
.next .disabled .previous .disabled {
  color: black;
}
/* .previous {
  color: var(--color-individual) !important;
} */
.break {
  border: none !important;
}
/* **************.NavLeftContainer css******************** */

.NavLeftContainer {
  display: flex;
}

.NavFaq {
  cursor: pointer;
  color: var(--color-dark-grey);
  padding: 0px 14px 0px 0px;
  border-right: 2px solid var(--color-gray);
}

.LogiNavFaqnBtn {
  cursor: pointer;
  color: var(--color-dark-grey);
}
.CartImage {
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 50%;
  padding: 0px 10px 0px 14px;
}
.CartImage_cart {
  cursor: pointer;
  width: 50px;
  height: 25px;
  padding: 0px 10px 0px 14px;
}
.icon {
  width: 200px;
  height: 200px;
}
.NavLeftContainer-avtar {
  display: flex;
  align-items: center;
}

.NavLeftContainer-sec1 {
  display: flex;
  align-items: center;
}

.NavLeftContainer-sec1 p,
.NavLeftContainer-avtar p {
  margin-bottom: 0 !important;
}

.NavLeftContainer-avtar .indv-dash-nav-dropdown {
  color: #808080 !important;
  align-items: center;
  display: flex;
}
.NavLeftContainer-avtar .ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  padding-left: 4px;
}
.indv-dash-nav-dropdown-text {
  padding: 16px 56px 16px 14px !important;
  border-radius: 12px !important;
}

.indv-dash-nav-dropdown-text .ant-dropdown-menu-item:hover {
  background-color: unset !important;
  color: #88ae47 !important;
}
.item_cart_main {
  position: relative;
}
.item_cart {
  position: absolute;
  top: -7px;
  right: -3px;
  color: #fff;
  font-weight: 700;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indv-member-since {
  margin-right: 0rem;
}

.indv-member-since p {
  font-size: 15px;
  font-weight: 500;
  color: #88ae47;
}
.indv-member-since p span {
  color: rgb(124, 129, 136);
  font-weight: 400;
  padding-left: 8px;
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .indv-member-since {
    margin-right: 0rem;
  }

  .indv-member-since p span {
    display: inline-block;
  }
  .ns-font {
    font-size: 9px;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .indv-member-since {
    margin-right: 2rem;
  }

  .indv-member-since p span {
    display: inline-block;
  }
}
@media screen and (max-width: 576px) {
  .nav-container-texts p {
    font-size: 11px !important;
  }
  .indv-member-since {
    margin-left: 7px !important;
  }
  .indv-member-since p {
    font-size: 13px;
  }
  .indv-logo-head img {
    width: 75px !important;
  }
  .NavLeftContainer-avtar {
    width: 38%;
  }
  .NavLeftContainer-sec1 {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
  }
  .indv-dash-nav-dropdown {
    font-size: 12px;
  }
  .indv-member-since p span {
    padding-left: 1px;
  }
}
@media (min-width: 768px) {
  .indv-member-since-ns {
    margin-right: 1rem !important;
  }
}

@media screen and (max-width:370px){
  .indv-member-since p {
  
    margin-right: 15px;
}
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 6px 11px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .flight-card-block .ant-select-single .ant-select-selector .ant-select-selection-item {
    width: 200px !important;
  }

  .buss-flight-header {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
}

.offset-onetime-order-summery .ant-input {
  height: 50px !important;
  border-radius: 10px !important;
}

.not_edit {
  cursor: not-allowed;
}

.gift-code-input .ant-input {
  height: 40px !important;
}

.gift-code-input .ant-input-affix-wrapper {
  border-radius: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c6c6c6 !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: #c6c6c6 !important;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #121212;
  font-size: 15px;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  color: #b0b0b0;
  font-size: 15px;
}

.admin_carbon_offset .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 80px;
}

.admin_carbon_offset {
  width: 100%;
  z-index: 9;
}

.admin_carbon_offset .ant-tabs-nav-wrap {
  justify-content: center !important;
}

.subscription-disable-slider {
  position: relative;
}

.subscription-disable-slider .rec-arrow-right:disabled,
.subscription-disable-slider .rec-arrow-left:disabled {
  cursor: not-allowed;
  display: none;
}

.subscription-disable-slider .rec-arrow-right {
  position: absolute;
  top: 0px;
  right: -2px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-left {
  position: absolute;
  top: 0px;
  right: 52px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-right:hover,
.subscription-disable-slider .rec-arrow-right:hover:enabled,
.subscription-disable-slider .rec-arrow-right:focus:enabled {
  border: 0 !important;
  box-shadow: none;
  background: #ececec !important;
  background-color: #ececec !important;
  color: #706e6e !important;
}

.carbon-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 28px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.carbon-business-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 0px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.active-bg-grey-onetime-card {
  background: #f6ffea !important;
  border: 0.5px solid #88ae47 !important;
}

.custom-pad-offset-onetime-card {
  padding: 12px 8px 5px 15px !important;
}

.custom-pad-offset-onetime-card img {
  height: 24px !important;
  width: 20px !important;
}

.carbon-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-business-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-offset-onetime-img-card img {
  width: 24px;
}

.carbon-business-offset-onetime-img-card img {
  width: 24px;
}

.carbon-offset-onetime-life-card-text-head p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.carbon-offset-onetime-life-card-text-head img {
  width: 22px;
  height: 22px;
}

.carbon-offset-onetime-life-card-img img {
  width: 100%;
  border-radius: 22px;
  cursor: pointer;
  height: 270px;
}

.life-card-img-select {
  border: 4px solid #77a038;
  border-radius: 24px;
  /* height: 270px; */
}

.carbon-offset-onetime-life-sec {
  padding: 15px 10px 10px 10px;
}

.carbon-offset-onetime-life-sec-household {
  padding-top: 50px !important;
}

@media screen and (max-width: 767px) {
  .carbon-offset-onetime-life-business {
    padding-top: 10px !important;
  }

  .carbon-offset-onetime-life-business-emp {
    padding-top: 50px !important;
  }
}

.carbon-offset-bg-img {
  margin-top: -40px;
}

.carbon-offset-onetime-life-sec-card {
  margin-top: 15px;
  position: relative;
}

.carbon-offset-onetime-life-sec-card-dropdown {
  padding-bottom: 50px;
  position: relative;
  padding-left: 10%;
  width: 90%;
}

.carbon-offset-flight-sec .ant-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-weight: 600 !important;
  font-size: 15px;
  color: #797b7a;
}

.carbon-offset-onetime-life-card-text {
  background: #ffffff;
  border-radius: 18px;
  width: 96%;
  bottom: 3px;
  margin: 0px 8px;
  position: absolute;
  box-shadow: 0 0 40px #c4c4c4;
}

.indv-offset-order-summay .ant-card {
  background-color: #f3f3f3;
  border-radius: 12px;
}

.indv-offset-order-summay .ant-card .ant-card-head {
  border-bottom: 2px solid #e0e0e0;
}

.indv-offset-order-summay .ant-card .ant-card-head .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  padding: 20px 12px;
}

.indv-offset-order-summay .ant-card .ant-card-body {
  padding: 0px;
}

.offset-onetime-order-summery .select-onetime-card {
  font-size: 14px;
  font-weight: 600;
  color: #9f9f9f;
  padding: 10px 0px;
  text-transform: uppercase;
}

.offset-onetime-order-summery p {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
  margin-bottom: 0px;
}

.offset-onetime-order-summery .ord-total-imp {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
}

.renew-message {
  font-weight: bold !important;
  color: #121212 !important;
  margin: 0px 12px;
}

.renew-message-ns {
  margin: 0px 40px !important;
  text-align: center !important;
}

.indv-offset-order-total-1 {
  background-color: #dee9c7;
}

.indv-offset-order-total-1 p {
  margin-bottom: 0;
}

.indv-offset-order-total-1 span {
  color: #88ac4c;
  font-weight: 600;
  font-size: 18px;
}

.indv-offset-order-total-1 span b {
  color: #121212;
  font-size: 16px;
  padding-left: 4px;
}

.indv-offset-order-total-2 p {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span b {
  color: #121212;
}

.indv-offset-order-btn-buy {
  width: 100% !important;
  background-color: #dce5d4 !important;
  color: #9eb37f !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-buy-gift {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

button:disabled.indv-offset-order-btn-buy-gift {
  opacity: 0.5;
}

.indv-offset-order-btn-cart {
  width: 300px !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: auto;
}

.indv-offset-order-btn-cart-subscribe {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
  margin-left: auto;
}

@media (max-width: 768px) {
  .indv-offset-order-btn-cart-subscribe {
    width: 300px !important;
    background-color: #88ae47 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    height: 48px !important;
    border: none !important;
    cursor: pointer !important;
  }
}

.disable-add-to-cart {
  opacity: 0.5;
}

.indv-offset-order-summay-desc p {
  color: #121212;
  font-weight: bold;
}

.indv-offset-order-summay-desc ul li {
  font-size: 13px;
  margin-left: 17px;
  font-weight: 500;
  color: #88ae47;
  list-style-type: disc;
}

.indv-offset-order-summay-num {
  font-size: 13px;
  color: #121212;
  font-weight: bold;
  padding: 0 5px;
}

.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav {
  flex-direction: row;
}

.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav .nav-item:not(:last-child) {
  margin-right: 69px;
}

.hidentext {
  display: none;
}

.login-indv-form .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.remove-overflow-x {
  overflow-x: hidden !important;
  margin-right: 0px !important;
}

@media screen and (max-width: 767px) {
  .indv-planttree-section {
    margin-right: -8px !important;
  }

  .indv-offset-order-btn {
    padding-top: 137px;
  }
}

.indv-offset-tab-link a {
  color: #aaaaaa !important;
}

.indv-offset-tab-link a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.idv-offset-top-menu ul {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 18px 0;
}

.idv-offset-top-menu ul li {
  display: block;
}

.indv-scrol-sec-subs {
  padding: 0 10px;
}

.indv-scrol-sec-subs p {
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.indv-scrol-sec-subs img {
  width: 24px;
}

.indv-scrol-sec-subs-forward,
.indv-scrol-sec-subs-back {
  background: #eeeeee;
  margin: 0 2px;
  padding: 4px 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.offset-onetime-car-sec {
  background-color: #fafaf8 !important;
  border: 1px solid #88ae47;
  border-radius: 12px;
}

.offset-onetime-car-sec p {
  margin-bottom: 0px;
  color: #797b7a;
}

.offset-onetime-car-sec-head {
  position: relative;
}

.offset-onetime-car-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.other-transport-tab {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin: 0;
}

.other-transport-tab li {
  display: block;
}

.other-transport-tab li {
  padding: 15px 30px;
  position: relative;
  font-weight: 600;
  color: #acacac;
  cursor: pointer;
}

@media (max-width: 360px) {
  .other-transport-tab-ns li {
    padding: 15px 18px !important;
  }
}

@media (min-width: 361px) and (max-width: 390px) {
  .other-transport-tab-ns li {
    padding: 15px 18px !important;
  }
}

@media (min-width: 391px) and (max-width: 450px) {
  .other-transport-tab-ns li {
    padding: 15px 25px !important;
  }
}

.other-transport-tab li.active {
  border-bottom: 1px solid #88ac4c;
  color: #000000;
}

.offset-onetime-car-bottom-sec {
  border-top: 1px solid #f0f0f0 !important;
}

.offset-onetime-car-bottom-sec .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.offset-onetime-car-bottom-sec input {
  border-radius: 10px !important;
}

.offset-onetime-car-bottom-sec-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
}

.offset-onetime-car-bottom-sec2-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
  height: 100%;
}

.offset-onetime-car-bottom-sec-or {
  position: absolute;
  z-index: 99;
  bottom: 124px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.offset-onetime-car-bottom-sec-or p {
  background: #ffffff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
}

.offset-car-vehicle-detail {
  border-bottom: solid 1px #ececea;
}

.offset-car-vehicle-detail-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-car-vehicle-detail-btn button {
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  font-weight: 500;
  width: 100%;
}

/* ---------------Offset Flight Tab css ----------------------- */
.carbon-offset-flight-sec {
  padding: 0px 20px 10px 20px;
  margin-top: -35px;
}

.carbon-offset-flight-sec-ns {
  font-weight: 600 !important;
  font-size: 15px;
  color: #797b7a;
}

.carbon-offset-flight-sec .ant-card {
  border-radius: 14px;
}

.carbon-offset-flight-sec .ant-card-bordered {
  border: 2px solid #88ae47;
  background: #fafaf8;
}

.offset-flight-radio .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
  border-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper {
  margin-right: 30px;
}

.offset-flight-radio .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner::after {
  background-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #63959b !important;
}

.flight-card-content {
  padding-top: 20px;
}

.flight-card-content .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 13px 18px;
  height: 50px;
}

.flight-card-content .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.flight-card-content .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.FlightRightLeft {
  width: 30px;
  height: 30px;
}

.pad-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flight-card-content .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flight-card-content .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  margin: 0px 30px;
  cursor: pointer;
  width: 30px;
}

.offset-order-summery-label .flight-pass-data p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 95px;
}

.flight-card-content .flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-card-content .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carbon-offset-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 55px !important;
  float: right;
}

.flight-card-content .ant-btn:hover,
.ant-btn:focus {
  border-color: transparent !important;
}

/* ------------------------------------------------- */
.greentxt {
  color: #88ae47 !important;
}

.offset-mtp {
  margin-top: -15px !important;
}

.offset-order-summery-label {
  padding-top: 10px;
}

.offset-order-summery-label .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

@media (max-width: 470px) {
  .text-align-lifestyle-ns {
    padding-top: 74px !important;
  }
}

.offset-order-summery-label .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.offset-order-summery-label .mr-lft {
  margin-left: 30px;
}

.offset-order-summery-label .mr-rght {
  margin-right: 30px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card img {
  width: 20px;
}

.offset-order-summery-label .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.offset-onetime-household-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-onetime-household-sec-text p {
  color: #9f9f9f;
}

.offset-house-btn-sec {
  border-top: solid 1px #ececea;
}

.other-transport-add-stage-btn button {
  background: none !important;
  color: #88ae47 !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
}

.other-transport-add-stage-btn button :hover {
  background: none;
  color: #88ae47;
}

.btn-img-span {
  width: 25px;
  padding-bottom: 2px;
}

.other-transport-remove-btn {
  padding-top: 8px;
  cursor: pointer;
}

.other-transport-remove-btn img {
  border: solid 1px #ececea;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
}

.padding-t-b {
  padding: 20px 0;
}

.offset-flight-card {
  padding: 20px 0;
}

.offset-flight-card-padding {
  margin-left: 65px;
}

.offset-flight-card .ant-row .ant-col label {
  font-size: 18px;
  font-weight: 800;
}

.offset-flight-card .ant-row .ant-col span {
  font-size: 14px;
  color: #acacac;
  font-weight: 500;
  display: block;
  /* word-break: break-word; */
}

.car-from-to-heading-ns {
  font-size: 18px !important;
  font-weight: 800 !important;
  font-family: Poppins, sans-serif !important;
}

.car-from-to-value-ns {
  font-size: 14px !important;
  color: #acacac !important;
  font-weight: 500 !important;
}

.car-from-to-container-ns {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
}

.ct_block_sec {
  display: block;
}

@media (min-width: 769px) and (max-width: 960px) {
  .car-from-to-container-ns {
    flex-wrap: wrap;
  }
}

.offset-flight-card .flight-place-way img {
  background: #fff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
  position: relative;
  top: -34px;
  left: 90px;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .offset-flight-card .flight-place-way img {
    left: 27px !important;
  }
}

.off-ord-flight-data-card .ant-row .ant-col span {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 0;
}

.plant-tree-gift-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 !important;
}

.txt-algn-cntr {
  align-items: center;
}

@media (max-width: 768px) {
  .txt-algn-cntr {
    justify-content: space-between;
  }
}

.txt-algn-baseline-ns {
  align-items: unset !important;
}

.off-ord-flight-data-card .ant-row .ant-col .text-end {
  text-align: end;
}

.off-ord-flight-data-card .ant-row .text-end label {
  font-size: 14px;
  padding: 15px 0;
}

.offset-flight-card hr {
  margin-top: 25px;
}

.offset-flight-card-ns hr {
  margin-top: 14px;
}

.offset-flight-card .car-place-line {
  border-top: 1px dashed #acacac;
}

.car-place-line {
  border-top: 1px dashed #acacac;
}

.dsp-grid {
  display: grid !important;
}

.pb-15 {
  padding-bottom: 15px;
}

.select-validation {
  color: red;
}

.plant-tree-ord {
  /* padding-left: 20px; */
}

.plant-tree-ord ul li {
  list-style: none;
}

.txt-no-input {
  font-size: 16px;
  font-weight: 600;
  color: #78777c;
}

.Life_style_NoData {
  display: flex;
  justify-content: center;
}

.ant-popover-inner-content {
  width: 430px;
}

.have_gift-code {
  margin-bottom: 5px;
}

.gift-code-input {
  padding-top: 20px;
}

.gift_card_total {
  padding: 0 20px;
}

.second_cart_projects {
  background-color: #fff !important;
  margin-top: 20px !important;
}

.second_cart_projects .card_project_content {
  padding: 20px 25px 0px 20px;
}

.card_project_content img {
  width: 115px;
}

.card_project_content p {
  color: #acacac;
  padding-top: 15px;
  font-size: 16px;
}

.gift_apply_remove_btn {
  border-radius: 10px !important;
  height: 35px !important;
}

.text_capital {
  text-transform: capitalize;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: normal !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 15px !important;
}

.ant-row.remove-overflow-x.py-3.px-2.ct_alignment_center {
  align-items: flex-start;
}

.offset-top-bg img {
  width: 100%;
}

.validate_error {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 400;
}

.offset-cards-row {
  position: relative;
  bottom: 48px;
}

.off-set-car-img-sec-img img {
  width: 100%;
  max-height: 455px;
  min-height: 455px;
}

.off-set-flight-img-sec-img img {
  width: 100%;
  max-height: 385px;
  min-height: 385px;
}

.off-set-car-img-sec .head p {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.off-set-car-img-sec .text p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.off-set-car-img-sec .text2 p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.font-text16 {
  font-size: 16px;
}

.car-related-summary {
  justify-content: space-between;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .offset-flight-image {
    display: flex !important;
    height: 30px;
    margin-top: 10px;
  }

  .offset-flight-card hr {
    margin-top: 25px;
    width: 300px;
  }

  .offset-flight-card-ns hr {
    margin-top: 14px;
    width: 300px;
  }

  .offset-flight-card .flight-place-way img {
    left: 133px !important;
  }

  .car-from-to-text>label {
    display: flex;
    justify-content: center;
  }

  .indi-offset-flight-radio {
    display: flex !important;
    justify-content: space-evenly !important;
  }

  .indi-offset-flight-radio .ant-radio-wrapper {
    margin-right: 0px !important;
  }
}

.offset-lifestyle-text-para {
  /* margin-top: -15px; */
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .subs-offset-cards>.ant-row {
    display: block;
  }

  .ct_position_8 {
    position: relative;
    top: 8px;
  }
}

@media screen and (min-width: 577px) {
  .offset-lifestyle-text-para {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .order-summary-add-cart-btn-ns {
    display: flex !important;
    justify-content: center !important;
  }

  .order-summary-add-cart-btn .ant-row {
    margin-left: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .carbon-offset-flight-sec {
    padding: 0px 20px 10px 20px;
    margin-top: 0px;
  }
}

/* --------------Mobile Responsive Start css by Shlok */
@media screen and (min-width: 360px) and (max-width: 670px) {
  .one-time-offset-sec .ant-row {
    flex-flow: row wrap;
    /* display: block; */
  }

  .one-time-offset-sec .ant-col-lg-21 {
    max-width: 92% !important;
  }

  .one-time-offset-second .ant-col-lg-21 {
    max-width: 90% !important;
  }

  .ant-card-head {
    margin-top: 20px;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }

  .offset-car-vehicle-detail-btn button span {
    display: flex;
    justify-content: center;
  }

  .business-detail-head {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .off-set-car-img-sec .head p {
    font-size: 18px !important;
  }

  .off-set-car-img-sec .text p {
    font-size: 13px !important;
  }

  .off-set-car-img-sec .text2 p {
    font-size: 13px !important;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1399px) {
  .offset-flight-card .flight-place-way img {
    left: 55px;
  }

  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }

  .carbon-offset-flight-sec {
    padding: 0;
  }

  .carbon-offset-onetime-life-sec {
    padding: 0;
  }

  .offset_down_heade_back {
    margin-top: 15px;
  }
}

/* mobile an tablet responsive */
@media screen and (min-width: 360px) and (max-width: 768px) {
  .cart-card-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .one-time-offset-sec .ant-col {
    flex-flow: row wrap;
    justify-content: space-between;
    /* margin: 5px 0px; */
    margin: 0px;
  }

  .one-time-offset-sec .ant-col .ant-btn {
    flex-flow: row wrap;
    justify-content: center;
    /* margin: 5px 0px; */
    margin: 0px;
  }
}

/* Mobile Responsive */
@media screen and (min-width: 360px) and (max-width: 576px) {
  .other-transport-header {
    display: flex;
    flex-direction: column;
  }

  .other-transport-tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .offset-onetime-car-bottom-sec-or {
    /* position: relative; */
    margin-bottom: -33px;
  }

  .carbon-offset-employee-btn button {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .offset-onetime-car-bottom-sec-or {
    position: relative;
    margin-bottom: -33px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .other-transport-tab {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

@media screen and (min-width: 360px) and (max-width: 369px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 7px 15px -33px 131px;
  }
}

@media screen and (min-width: 671px) and (max-width: 682px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 283px 0px -37px -62px;
  }
}

@media screen and (min-width: 683px) and (max-width: 768px) {
  .offset-onetime-car-bottom-sec-or {
    position: static;
    margin: 154px -40px 23px 1px;
  }
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .carbon-offset-btn {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    padding: 0px 40px !important;
  }

  .order-summary-header {
    text-align: center;
  }

  .ns-order-summary-header {
    font-size: 22px;
  }

  .indv-offset-order-summay .ant-card {
    background-color: #fafafa !important;
    box-shadow: 0 0 10px #ecececcc;
    border: 1px solid #f0f0f0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .carbon-offset-btn {
    padding: 0px 20px !important;
  }
}

@media (min-width: 769px) and (max-width: 2400px) {
  .indv-offset-order-btn-cart {
    width: 100% !important;
  }
}

.dropdown-border-ns {
  padding: 0 0 50px 0;
  margin-left: auto;
  margin-right: auto;
}

.select-border-ns {
  width: 100%;
  border: 1px solid #88ae47;
  border-radius: 10px;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.ct_login_width_bg {
  /* background-image: url(login_bg_1.jpg); */
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow-y: auto;
}

.ct_login_width_bg:before {
  content: "";
  position: absolute;
  top: 0px;
  background-color: #000;
  height: 100vh;
  width: 100%;
  opacity: 0.6;
  z-index: -1;
}

h2.ct_center_position {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  margin-bottom: 0px !important;
}

.ct_login_bg12 {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.ct_login_tab {
  flex-direction: column;
}

.ct_login_bg12 .tab-content {
  flex: 1 1;
  padding: 15px;
}

.ct_login_tab a.nav-link.active {
  background: #86af48 !important;
  border-radius: 0px;
}

.ct_login_tab a.nav-link {
  text-align: center;
  padding: 29px 40px;
  color: #333;
}

.ct_login_tab a.nav-link i {
  font-size: 24px;
}

ul.nav.nav-pills.ct_login_tab {
  border-right: 1px solid #d8d8d8;
}

.ct_login_bg12 .tab-content .tab-pane {
  padding: 0px;
}

.ct_login_form_cnt12 h5 {
  text-align: center;
}

.ct_login_tab a.nav-link.active p {
  color: #fff !important;
}

.ct_login_form_cnt12 .ant-form-item {
  margin-bottom: 14px;
  padding-inline: 13px;
}

.ct_input_fixed_icon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 16px;
  fill: #a2a2a2;
}

.ct_login_form_cnt12 .ct_input {
  text-indent: 30px;
  background: #eee !important;
  color: #333 !important;
  font-weight: 400 !important;
}

.ct_login_form_cnt12 .ct_input:focus {
  box-shadow: unset !important;
  border-color: #86af48 !important;
}

.ct_login_form_cnt12 .ct_input::-moz-placeholder {
  color: #a2a2a2 !important;
}

.ct_login_form_cnt12 .ct_input:-ms-input-placeholder {
  color: #a2a2a2 !important;
}

.ct_login_form_cnt12 .ct_input::placeholder {
  color: #a2a2a2 !important;
}

.ct_login_btn {
  border: 1px solid #86af48;
  background-color: #86af48;
  outline: none;
  width: 100%;
  padding: 8px 10px;
  color: #fff;
  margin-top: 30px;
  font-weight: 500;
  transition: 0.4s ease all;
  z-index: 1;
  position: relative;
}

.ct_login_btn:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 0.4s ease all;
  transform: scaleX(0);
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.ct_login_btn:hover:before {
  transform: scaleX(1);
}

.ct_login_btn:hover {
  color: #86af48;
}

.ct_forgot_password {
  color: #a2a2a2;
  font-weight: 500;
  text-decoration: none;
}

.ct_login_tab a.nav-link.active img {
  filter: invert(1);
}

.ct_login_tab a.nav-link img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.ct_login_form_cnt12 h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-weight: 400;
  background-color: rgb(144 195 69 / 17%);
  padding: 30px;
  border-radius: 10px;
}

.ct_center_position svg {
  width: 47px;
  display: block;
  margin: auto;
  margin-bottom: 15px;
  fill: #82aa45;
}

@media screen and (max-width: 991px) {
  .ct_login_bg12 {
    flex-direction: column;
  }

  .ct_login_tab a.nav-link {
    border-bottom: 1px solid #d8d8d8;
  }
}
.carbon-app-Banner {
  background-color: #f4f4f4 !important;
  background: url(/static/media/carbon_bgimg.940adbaa.png) no-repeat right 40px top;
}


.color-black {
  color: #000000 !important;
  margin: 8px 0 0 0 !important;
}

.font-wg-500 {
  font-weight: 500 !important;
  padding-bottom: 20px;
}

.carbon-app-bann-row {
  padding: 0px;
}

.carbon-app-bann-row h1 {
  font-weight: 400;
}

.carbon-app-bann-row h1 span {
  font-weight: 700;
  margin: 12px 0 !important;
}

.carbon-app-bann-row p {
  color: #969696;
  margin: 26px 0;
  margin: 26px 0 0 0;
}

.carbon-app-bann-btn-sec {
  padding: 50px 0;
}

.carbon-app-bann-green-btn a {
  color: #000000 !important;
}

.carbon-app-bann-green-btn button {
  background-color: #88ae47 !important;
  border: none;
  padding: 22px 26px;
  border-radius: 16px;
  width: 205px;
  font-weight: 600;
}

.carbon-app-bann-blue-btn button {
  background-color: #c2e7ed;
  color: #000000;
  border: none;
  padding: 22px 26px;
  border-radius: 16px;
  width: 205px;
  font-weight: 600;
}

.carbon-app-reduce-emissions {
  padding: 56px 0;
}

.carbon-app-reduce-emissions-head {
  text-align: center;
}
.partners-head {
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.carbon-app-reduce-emissions h3 span {
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}
.carbon-app-reduce-emissions-tabs-new .ant-tabs-nav-wrap {
  justify-content: center;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-nav-wrap {
  justify-content: center;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-nav {
  padding-bottom: 30px;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-nav-list {
  background-color: #f3f3f3 !important;
  padding: 0px 12px;
  border-radius: 8px;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active {
  background: #fff;
  padding: 0px 20px;
  margin: 6px 0 6px 15px !important;
  border-radius: 6px;
  width: 100px;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
  font-weight: 500;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab:hover {
  color: #000000;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active .ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #000000 !important;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-ink-bar {
  display: none;
}

.carbon-action-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/5rxwo1ps1jnsdart95lniikzuvzy/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22home_sec_change_climate.jpg%22%3B%20filename%2A%3DUTF-8%27%27home_sec_change_climate.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px;
  border-radius: 8px;
}

.carbon-action-img-overlay-sec {
  background-color: #ebeaeaf2;
  padding: 32px;
  border-radius: 8px;
}

.carbon-climate-inner-part-sec button {
  background-color: #88ae47;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 12px 32px;
}
.carbon-climate-inner-part-sec button a {
  color: #fff;
}
.why-climate-change-sec {
  background: #f5f5f5 !important;
}
.why-climate-change-sec-in {
  text-align: center;
}
@media (max-width: 768px) {
  .why-climate-change-sec-in-padding-ns {
    align-items: baseline;
    padding-left: 20px !important;
  }
  .why_climate_center_ns {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    justify-content: center !important;
  }
  .why-climate-change-sec-in-padding-ns p {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 20px !important;
  }
}

.why-climate-change-sec-in img {
  width: 150px;
  height: 150px;
}

.why-climate-change-sec-in .head {
  font-size: 18px;
  font-weight: 700;
}

.why-climate-change-sec-in .text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #a0a0a0;
}

.why-climate-change-sec-head p {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
}
.why_climate_center {
  justify-content: center;
}
.why-climate-change-sec-head p span {
  font-weight: 700;
}

.carbon-app-sec-faq {
  background-color: #f4f4f4;
}

.carbon-app-sec-faq .head {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
}

.carbon-app-sec-faq .ant-collapse {
  border: none;
  background: none;
}

.carbon-app-sec-faq .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
}

.carbon-home-started-sec {
  background: url(/static/media/started_bgImg.9c7a9657.png) no-repeat -30px;
  background-size: 100%;
}
.carbon-home-started-sec .upp {
  padding: 0px 125px;
}

.carbon-home-started-sec .head {
  font-weight: 800;
  text-align: center;
  font-size: 24px;
}

.carbon-home-started-sec .btn .carbon-app-get-btn-sec {
  justify-content: center;
}

.carbon-home-started-sec .img img {
  width: 100%;
}
.text p {
  font-weight: 500;
  color: #8c8c8c;
}

.carbon-banner-img img {
  width: 100%;
}
/* .carbon_emission {
  padding: 20px 0px 0px 0px;
} */

.carbon_emission .label {
  font-size: 20px;
  font-weight: 600;
}
.carbon_emission .description {
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}

.hr_col {
  border-left: 1px solid #f3f3f3;
}

.carbon_emission_img {
  background-color: #fff;
  border-radius: 15px;
  max-height: 145px;
  padding: 25px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 40px #dfe5bf;
  width: 145px;
}

.padding-b50 {
  padding-bottom: 50px;
}
.justify_space_evenly {
  justify-content: space-evenly !important;
}
.carbon-project-categories {
  padding: 56px 0;
  background-color: #f7f7f7;
}
.carbon-project-categories-head {
  text-align: center;
  margin-bottom: 45px;
}

.carbon-project-categories h3 {
  font-weight: 800;
}
.carbon-project-categories h3 span {
  font-weight: 400;
}
.carbon_pro_cat_img {
  border-radius: 15px;
}
.pro_cat_img_center {
  display: flex;
  justify-content: center;
}
.pro-cat-img {
  border-radius: 22px;
  padding: 7px 14px;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px 0px 12px;
  top: 15px;
  position: absolute;
  /* right: 300px; */
  background: rgba(0, 0, 0, 0.4);
  color: #f1f1f1;
}
.read_artical_center {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
}
.read_artical .container .read_more {
  display: flex;
  justify-content: center;
  color: #88ae47;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.read_artical_center .ArticleImg1 {
  max-width: 700px;
}
.article_profile_img .ArticleImg2 {
  max-width: 400px;
}
.right_move {
  margin-right: 285px !important;
}
.read_artical_center .article_content {
  background-color: #f4f4f4;
  margin: 170px 0px 75px;
  padding: 40px 40px;
  border-radius: 12px 12px 0 12px;
  margin-left: -100px;
}
.article_profile_img .article_content {
  width: 100%;
}
.read_artical_center .article_content h4 {
  font-size: 32px;
  font-weight: 700;
}
.article_profile_img .article_content h4 {
  font-size: 16px;
  font-weight: 700;
}
.read_artical_center .left_view .artical_profile {
  width: 35px;
}
.article_info .left_view img {
  width: 40px;
}
.article_info .left_view span {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
}
.article_info .right_view p {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
}
.article_info {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.read_artical_center .article_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 700px);
  height: 75px;
}
.article_profile_img .article_btn {
  width: calc(100% - 400px);
}
.read_artical_center .article_btn .ant-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  border-color: #88ae47 !important;
  background: #88ae47 !important;
  display: block;
  width: 100%;
  height: 100%;
}

.carbon-pro-cat-card {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  background-color: #fff;
  height: 60px;
  padding: 18px 30px;
  top: -25px;
  position: inherit;
}
.carbon-pro-cat-card h4 {
  font-size: 18px;
  font-weight: 700;
}
.ant-col-lg-21 {
  max-width: 72.5% !important;
}

.my-subscription-card-frequency-value {
  font-size: 11px;
}
.my-subscription-card-cancel {
  color: red !important;
}
.my-subscription-card-renew-disabled {
  color: grey !important;
}
.my-subscription-card-renew {
  color: green !important;
}

.ns-name-change {
  width: 251px;
}

@media screen and (max-width: 998px) {
  .carbon-app-bann-green-btn button,
  .carbon-app-bann-blue-btn button {
    width: 100%; /*165px; */
  }
}
/* @media screen and (max-width:991px) {
  .carbon_emission {
    text-align: center;
}
.carbon_emission_img {
  margin-bottom: 15px;
}
} */
.ct_object_cover{
  -o-object-fit: contain;
     object-fit: contain;
}

.ct_border_left_div .ct_border_div:nth-child(1) {
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}
.ct_border_left_div .ct_border_div:nth-child(2) {
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
.ct_border_right_div .ct_border_div:nth-child(1) {
  border-left: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}
.ct_border_right_div .ct_border_div:nth-child(2) {
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
}

@media screen and (max-width: 767px) {
  .padding-b50 {
    padding-bottom: 30px;
  }
  .ant-col {
    padding-right: 0px !important;
  }
  .why-climate-change-sec-in {
    display: flex;
  }
  .why-climate-change-sec-in .text {
    text-align: left;
    padding: 14px 8px;
    width: 200px !important;
  }
  .why_climate_center {
    justify-content: left;
  }
  .carbon-app-bann-btn-sec {
    padding: 18px 0;
  }
  .climate-action-not-difficult-para-number {
    font-size: 22px !important;
  }
  .transparency-at-our-code-sec-block2 {
    padding: 10px !important;
    height: 100px !important;
  }
  .dashboard_img_sec {
    display: flex;
  }
  .transparency-at-our-code-sec-block p,
  .transparency-at-our-code-sec-block2 p,
  .transparency-at-our-code-sec-block3 p,
  .transparency-at-our-code-sec-block4 p {
    font-size: 16px !important;
    padding-left: 16px;
  }
  img.carsousal-items-logo-image2-image3.ct_object_cover ,img.carsousal-items-logo-image1.ct_object_cover{
    height: 80px !important;
  }
  
  .transparency-at-our-code-sec {
    padding: 0px !important;
  }
  .carbon-app-bann-blue-btn,
  .carbon-app-bann-green-btn {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #88ae47 !important;
  }
  .climate-action-btnsec-btn1 {
    width: 192px !important;
  }
  .climate-action-btnsec-btn2 {
    width: 192px !important;
    height: 48px !important;
  }
  .carbon_emission .description {
    padding-right: 30px !important;
  }
  .climate-offseting-sec-leftblock-text,
  .climate-offseting-sec-rightblock-text {
    width: 340px !important;
    text-align: left !important;
  }
  .climate-project-img img,
  .climate-project-img-left img {
    width: 96% !important;
  }
  .ct_offset_project_main p.climate-offseting-sec-rightblock-text ,.ct_offset_project_main p.climate-offseting-sec-leftblock-text  {
    width: 100% !important;
}
.ct_not_difficult_box .ant-row.ct_row_col {
  display: flex !important;
  grid-template-columns: unset!important;;
}
.ct_row_col .ct_col_full {
  /* max-width: 100% !important; */
  height: auto  !important;
}
.ct_why_climate .why-climate-change-sec-in {
  align-items: center;
  margin-bottom: 30px;
}
.ct_card_flex_div .ant-row.justify-content-evenly .ant-col {
  width: auto !important;
}
}
@media screen and (max-width: 769px) {
  .padding-b50 {
    padding-bottom: 30px;
  }
  .home_section {
    justify-content: center;
    margin-right: 0px !important;
  }
  .mt-5 {
    margin-top: 0 !important;
  }
  .home_section .ant-col {
    margin-bottom: 15px;
  }
  .carbon-app-reduce-emissions-head h3 {
    font-size: 15px;
    margin-bottom: 24px;
  }

  .carbon-home-started-sec .upp {
    padding: 0;
  }
  .carbon-home-started-sec .container .w-100 .ant-row .ant-col {
    margin-bottom: 15px;
  }
  .pt-5 {
    padding-top: 2rem !important;
  }
  .read_artical {
    display: block;
    width: 100%;
  }
  .read_artical_center {
    display: block;
    margin-bottom: -210px;
  }
  .read_artical_center .article_content {
    position: relative;
    bottom: 250px;
    margin-left: 0;
  }
  .read_artical_center .article_btn {
    width: 100%;
    bottom: 180px;
  }
  .read_artical_center .article_btn .ant-btn {
    border-radius: 0 0 14px 14px;
  }
  .read_artical_center .article_content h4 {
    font-size: 18px;
  }
  .article_info .left_view span {
    font-size: 11px;
  }
  .article_profile_img .ArticleImg2 {
    max-width: 100%;
  }
  .read_artical_center .ArticleImg1 {
    height: 470px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .read_artical {
    display: block;
    width: 100%;
  }
  .why-climate-change-sec-head p {
    font-size: 20px;
    margin-top: 10px;
    padding-top: 32px;
  }
  .read_artical_center {
    display: block;
    margin-bottom: -210px;
  }
  .py-5 {
    padding-top: 0 !important;
  }
  .read_artical_center .article_content {
    position: relative;
    bottom: 250px;
    margin-left: 0;
    padding: 25px 40px;
  }
  .read_artical_center .article_btn {
    width: 100%;
    bottom: 180px;
  }
  .read_artical_center .article_btn .ant-btn {
    border-radius: 0 0 14px 14px;
  }
  .read_artical_center .article_content h4 {
    font-size: 18px;
  }
  .article_info .left_view span {
    font-size: 11px;
  }
  .article_profile_img .ArticleImg2 {
    max-width: 100%;
  }
  .read_artical_center .ArticleImg1 {
    height: 470px;
    width: 100%;
  }
  .carbon-app-reduce-emissions-tabs-new .ant-tabs-nav-wrap {
    justify-content: center;
  }
}

.indv-dash-custom-tbl .ant-table-thead > tr > th {
  text-align: center;
  background: none;
  color: #c3c3c3;
  font-weight: 500;
}
.name_change {
  text-align: left !important;
}
.Image_change {
  width: 40px;
  padding: 16px 0px 16px 16px !important;
  text-align: left !important;
}
.same_change {
  width: 212px;
}
.profile-subscription-action {
  color: rgb(255, 0, 0);
}
.profile-subscription-billing {
  color: rgb(0, 0, 255);
}
.indv-dash-custom-tbl
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.indv-dash-custom-tbl .ant-table-tbody > tr > td {
  text-align: center;
  background-color: #fafafa !important;
}

.term-condition-phead-txt {
  font-weight: 600;
  font-size: 15px;
}

.term-condition-para-txt {
  font-size: 14px;
  color: #83889e;
}

.term-para-link {
  color: #0000ee !important;
}

.why-climate-change-sec-text p {
  font-weight: 500;
  font-size: 16px;
}

.why-climate-change-sec-text p span {
  color: #a0a0a0;
}

.climate-action-btnsec p {
  font-weight: 400;
  font-size: 12px;
  color: #a0a0a0;
}

.climate-action-btnsec-btn2 {
  background: none;
  width: 215px;
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  border: #86af48 solid 2px;
  border-radius: 3px;
}

.climate-action-btnsec-btn2 a {
  color: #202020 !important;
}

.climate-action-btnsec-btn1 {
  width: 215px;
  height: 48px;
  border: none;
  background: #86af48 !important;
  border-radius: 3px;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 16px;
}

.climate-action-btnsec-btn1 a {
  color: #ffffff !important;
  font-weight: 500;
}

.climate-action-not-difficult {
  padding: 50px 0;
}

.climate-action-not-difficult-head p {
  text-align: center;
  font-size: 30px;
  line-height: 48px;
  font-weight: 700;
}

.climate-action-not-difficult-head p span {
  font-weight: 500;
  font-size: 24px;
}

.climate-action-not-difficult-para-number {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin-bottom: 0;
}

.climate-action-not-difficult-para-head {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0;
}

.climate-action-not-difficult-para-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #a0a0a0;
}

.transparency-at-our-code-sec {
  padding: 50px 0;
  background: #fff;
}

.transparency-at-our-code-sec-head p {
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  margin-bottom: 15px !important;
}

.transparency-at-our-code-sec-head p span {
  font-weight: 700;
  padding: 10px;
}

.transparency-at-our-code-sec-text p {
  text-align: center;
}

.transparency-at-our-code-sec-block,
.transparency-at-our-code-sec-block4 {
  background: #ffffff;
  padding: 50px 60px;
  height: 200px;
  width: 100%;
  position: relative;
}

.transparency-at-our-code-sec-block2,
.transparency-at-our-code-sec-block4 {
  margin-top: 1px;
}

.transparency-at-our-code-sec-block2,
.transparency-at-our-code-sec-block3 {
  background: #ffffff;
  padding: 50px 60px;
  height: 200px;
  width: 100%;
  position: relative;
}

.transparency-at-our-code-sec-block p,
.transparency-at-our-code-sec-block2 p,
.transparency-at-our-code-sec-block3 p,
.transparency-at-our-code-sec-block4 p {
  font-weight: 500;
  font-size: 20px;
  color: #202020;
  margin-bottom: 0;
}

.climate-offsetting-projects-head p {
  text-align: center;
  font-size: 32px;
  line-height: 48px;
}

.climate-offsetting-projects-head p span {
  font-weight: 700;
}

.climate-offsetting-projects-text p {
  text-align: left;
  font-size: 18px;
  color: #000000;
}
.climate-offseting-sec-leftblock-head {
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  margin-bottom: 14px;
}

.climate-offseting-sec-leftblock-text {
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  margin-top: 12px;
  text-align: justify;
}

.climate-offseting-sec-rightblock-head {
  font-weight: 600;
  font-size: 29px;
  color: #000000;
  margin-bottom: 1px;
}
.climate-offseting-sec-rightblock-text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
  text-align: justify;
}
.our-approach-carbon-offsetting-works {
  background: #f5f5f5;
  padding: 80px 0;
}

.our-approach-carbon-offsetting-works .head p {
  font-size: 32px;
  line-height: 48px;
}

.our-approach-carbon-offsetting-works .head p span {
  font-weight: 700;
}

.our-approach-carbon-offsetting-works .para {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.our-approach-carbon-offsetting-works .para2 {
  font-weight: 500;
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 5px;
}

.carbon-offsetting-work-block {
  padding: 22px;
  display: flex;
  grid-gap: 18px;
  gap: 18px;
  background-color: #ffffff;
}

.carbon-offsetting-work-block-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #202020;
}

.carbon-offsetting-work-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #a0a0a0;
}

.carbon-offsetting-work-block-text ul {
  padding-left: 0px;
}

.carbon-offsetting-work-block-text ul li {
  list-style: none;
}

.margin-top32 {
  margin-top: 32px;
}

.carbon-offseting-work-vector3 img {
  position: absolute;
  left: -22px;
}

.carbon-offsetting-work-down-vector img {
  position: absolute;
  top: -15px;
}

.carbon-offsetting-work-down-para p {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.text-uppercase {
  text-transform: uppercase;
}

.tackle-climate-change-part {
  background-color: #ffffff;
  margin: 180px 0px;
  padding: 32px;
  border-radius: 8px;
  width: 50rem;
  /* min-height: 292px; */
}
.tackle-climate-change-part .details_para {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.our-approach-main-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/aj0741yrb5rko20ylrwp2aa1dja2/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22approach-banner.jpg%22%3B%20filename%2A%3DUTF-8%27%27approach-banner.jpg&response-content-type=image%2Fjpeg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 192px 0px 156px 0;
}

.our-approach-main-sec-head h1 {
  text-align: left;
  font-weight: 500;
  font-size: 48px;
  line-height: 1.2;
}
.our-approach-main-sec-head-ns h1 {
  text-align: center !important;
}
.our-approach-main-sec-head h1 span {
  font-weight: 700;
  padding-left: 8px;
  font-size: 25px;
}
our-approach-main-sec-head_act h1 {
  text-align: left;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
}
our-approach-main-sec-head_act h1 span {
  font-weight: 700;
  padding-left: 8px;
  font-size: 25px;
}
/* .ct_not_difficult_box .ant-col.border-css-custom {
  height: 200px;
} */
.ct_not_difficult_box .ant-row.ct_row_col {
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.ct_row_col .ct_col_full {
  max-width: 100%;
  height: 100%;
}
@media screen and (min-width: 360px) and (max-width: 670px) {
  .why-climate-change-sec-in img {
    width: 110px !important;
    height: 110px !important;
  }

  .our-approach-main-sec-head-mobile {
    text-align: center !important;
  }
}
.our-approach-main-sec-text p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
}
.our-approach-main-sec-text-fliht p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
  padding-top: 10px;
}

.our-approach-sec-desc {
  text-align: center;
  font-weight: 700;
  line-height: 28px;
  color: #404040;
  font-size: 21px;
  padding-top: 38px;
}
@media (max-width: 362px) {
  .our-approach-sec-desc-padding-ns {
    padding-top: 70px !important;
  }
}
@media (max-width: 367px) {
  .our-approach-sec-desc-flight-padding-ns {
    padding-top: 67px !important;
  }
}

.offset-mobile-ns {
  padding-left: 5px;
  color: rgb(136, 174, 71);
}
.offset-mobile-tips-ns {
  padding-left: 5px;
  color: rgb(136, 174, 71);
  font-size: 25px;
}
.climate-project-img {
  padding: 25px 0px 25px 0px;
}

.climate-project-img img {
  width: 100%;
  border-radius: 8px;
}

.climate-project-img-left {
  padding: 25px 18px 25px 0px;
}

.climate-project-img-left img {
  width: 100%;
  border-radius: 8px;
}

.climate-project-img-left2 {
  padding: 25px 0px 25px 0px;
}

.climate-project-img-left2 img {
  width: 100%;
  height: 385px !important;
  border-radius: 8px 0px 0px 8px;
}

.climate-project-img-left3 {
  padding: 25px 0px 25px 0px;
}

.climate-project-img-left3 img {
  width: 100%;
  border-radius: 0px 8px 8px 0px;
  height: 385px !important;
}

.carbon-setting-imgs {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
}
.ant-row {
 
  align-items: center;
}
@media screen and (max-width: 576px) {
  .carbon-setting-imgs {
    display: flex;
    align-items: center;
  }
  .carbon-app-reduce-emissions-head {
    margin-bottom: 14px;
  }
  .carbon-app-reduce-emissions-head > h3 {
    font-size: 16px;
    line-height: 24px;
    display: block;
    font-weight: 500;
  }
  .carbon-app-reduce-emissions {
    padding: 24px 0px 37px 0px !important;
  }
  .carbon_approach_emission {
    padding-top: 0px !important;
    margin-bottom: 18px !important;
  }
  .ct_pad_30 {
    padding: 30px;
  }
  .padding-b50 {
    padding-bottom: 0px;
  }
  .carbon_approach_emission .description {
    margin-bottom: 0px;
  }
  .carbon_approach_emission .description {
    margin-bottom: 0px !important;
  }
  .carbon-approach-emission-blocks img {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    height: 187px;
  }
  .carbon_approach_emission .label {
    margin-bottom: 0px !important;
  }
  .our-approach-carbon-offsetting-works {
    padding: 27px 0 38px;
  }
  .our-approach-carbon-offsetting-works .head p {
    text-align: start;
    margin-bottom: 13px;
    text-transform: uppercase;
    font-size: 20px !important;
  }
  .our-approach-carbon-offsetting-works .para {
    font-weight: 400;
    color: #565656;
  }
  .our-approach-carbon-offsetting-works .para2 {
    color: #565656;
    font-weight: 400;
    margin-top: 25px;
  }
  .carbon-offsetting-work-block {
    padding: 11px 16px 10px 14px;
    display: flex;
    grid-gap: 10%;
    grid-gap: 7%;
    grid-gap: 7%;
    gap: 7%;
    border-radius: 8px;
  }
  .carbon-offsetting-work-block .bgImg img {
    width: 40px;
    padding: 8px;
  }
  .carbon-offsetting-work-block-head {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    line-height: 181%;
    margin-bottom: 0px;
    color: #1b1b1b;
  }
  .our-approach-devider {
    display: none;
  }
  .carbon-offsetting-work-block-text {
    font-size: 14px;
    line-height: inherit;
    margin-top: 11px;
  }
  .carbon-offsetting-work-block-arrow {
    display: none;
  }
  .sideArrowDown {
    margin: 17px 0px 20px;
  }
  .sideArrowDown img {
    margin: 17px 0px 20px;
    width: 40px !important;
    transform: rotate(90deg);
  }
  .transparency-at-our-code-sec {
    padding: 38px 0 54px;
  }
  .transparency-at-our-code-sec-head p {
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .approach-text-center {
    margin-bottom: 0px;
  }
  .ct_why_climate_mob p.climate-mobile-even-text {
    padding-top: 0px;
}
.climate-action-not-difficult.ct_not_difficult_box .ant-col.ant-col-md-3.ant-col-lg-3 {
  max-width: 60px !important;
  width: 60px  !important;
}
.ct_not_difficult_box p.climate-action-not-difficult-para-number {
  font-size: 16px !important;
}
}
@media screen and (min-width: 577px) {
  .carbon_approach_emission .description {
    margin-top: 39px;
  }
  .carbon-offsetting-work-arrow {
    display: none;
  }
  .sideArrowDown {
    margin: 64px 0;
  }
  .sideArrowDown-block {
    margin: 92px 0;
  }
  .approach-text-center {
    margin-bottom: 24px;
  }
  .approach-text-center p {
    text-transform: uppercase;
    font-size: 20px;
  }
  .our-approach-carbon-offsetting-works .head p {
    display: flex;
    flex-direction: column;
  }
  
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .sideArrow,
  .sideArrowDown {
    display: none;
  }
}
.for-indv-text-home-btn button {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  height: 68px;
}

.for-buss-text-home-btn button {
  color: #202020;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  height: 68px;
}

.carbon_approach_emission {
  padding: 10px 0px 0px 0px;
}

.carbon_approach_emission .label {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.carbon_approach_emission .description {
  color: #a0a0a0;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.carbon-action-sec {
  margin-bottom: 106px;
}

.carbon-action-sec-head {
  font-size: 24px !important;
  text-align: center;
  margin: 48px 0;
}

.carbon-action-sec-head span {
  font-weight: 700;
  font-size: 28px;
}
.color-green {
  color: #88ae47 !important;
}
.color-green-no {
  color: #88ae47 !important;
  text-decoration: underline;
}
.climate_no {
  margin-top: 12px;
}
.climate_no p {
  font-size: 16px;
  cursor: pointer;
}

.carbon-action-sec-text1 p {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}

.carbon-action-sec-text2 p {
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 48px; */
}

.carbon-action-sec-text3 p {
  font-weight: 400;
  font-size: 15px;
}

.carbon-action-sec-text4 p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 45px;
}

.dashboard_img_sec {
  display: flex;
  height: 50px;
}

.transparency-at-our-code-sec-block p,
.transparency-at-our-code-sec-block2 p,
.transparency-at-our-code-sec-block3 p,
.transparency-at-our-code-sec-block4 p {
  padding-left: 24px;
}

.class_hove1 {
  cursor: pointer;
  background: black;
}
.class_hove1_image {
  display: none !important;
}
.class_hove1 :hover {
  background: white;
}
.class_hove1:hover .class_hove1_image {
  display: block !important;
}
.our-approach-main-sec-head .climate_change {
  font-weight: 700;
  padding-left: 8px;
  font-size: 48px;
}
.sideArrowDown-blue {
  margin: 24px 0;
}
.sideArrowDown-green img {
  transform: rotate(90deg);
  width: 125px;
}
.sideArrow-green-image img {
  transform: rotate(180deg);
}
.carbon-offsetting-work-block .bgImg {
  max-width: 40px;
  width: 100%;
  height: 40px;
  margin-top: 11px;
}
.indv-privacy-sec-ns p {
  color: rgba(0, 0, 0, 0.85);
}
.indv-privacy-sec-ns li {
  color: rgba(0, 0, 0, 0.85);
  text-align: justify;
}
.term-condition-para-txt-ns span {
  font-weight: 600 !important;
}
@media only screen and (max-width: 1399px) {
  .carbon_emission .label {
    font-size: 19px;
  }
}
@media screen and (min-width: 360px) and (max-width: 670px) {
  .carbon-app-bann-row h1 {
    text-align: center;
    padding-top: 10px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .indv-dash-order-table table {
    font-size: 12px;
  }
  .indv-dash-order-table table tbody > tr > td > img {
    height: 80px;
    width: 39px;
  }
  .indv-dash-order-table-img {
    padding: 10px 10px 10px 10px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 992px) {
  .indv-dash-order-table table > thead > tr > th {
    padding: 13px 6px;
  }
  .indv-dash-order-table table tbody > tr > td {
    padding: 13px 6px;
  }
}
@media screen and (min-width: 360px) and (max-width: 1200px) {
  .indv-dash-order-history-main {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .indv-dash-order-table-main {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .indv-dash-order-table table > thead > tr > th {
    padding: 9px 9px;
  }
  .indv-dash-order-table table tbody > tr > td {
    padding: 9px 9px;
  }
  .indv-dash-order-table table {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .transparency-at-our-code-sec-block,
  .transparency-at-our-code-sec-block4 {
    height: 200px !important;
  }
  .transparency-at-our-code-sec-block2,
  .transparency-at-our-code-sec-block3 {
    height: 200px !important;
  }
  .carbon-action-sec-text1 p {
    margin-bottom: 46px;
  }
}
@media screen and (max-width: 768px) {
  .our-approach-main-sec-head .climate_change {
    font-weight: 700;
    padding-left: 8px;
    font-size: 24px;
  }
  .our-approach-main-sec-head h1 {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
  }
  .tackle-climate-change-part {
    width: auto;
  }
  .border-css-custom {
    border: none !important;
  }
  .text-align-container-climate-ns {
    text-align: center !important;
  }
  @media (max-width: 768px) {
    .climate-mobile-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      gap: 20px;
      padding-left: 5px;
      height: 250px;
    }
    .climate-mobile-container p {
      font-weight: 500;
      font-size: 15px;
      font-family: "Poppins", sans-serif;
      color: gray;
    }
    .climate-mobile-even-text {
      padding-top: 20px;
      color: #88ae47 !important;
      height: 30px;
    }
    .climate-mobile-not-even-text {
      color: gray;
    }

    .text-width-ns {
      width: 150px !important;
    }
    .text-align-climate-ns {
      text-align: justify !important;
      padding-left: 18px;
      padding-right: 20px;
    }
  }
  .climate-action-image {
    margin-right: 20px;
  }
  .transparency-at-our-code-sec-head p {
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 15px !important;
  }
  .climate-offsetting-projects-head p {
    text-align: center;
    font-size: 26px;
    line-height: 40px;
  }
  .climate-offsetting-projects-sec {
    margin-top: 16px;
  }
  .climate-offseting-sec-leftblock-head {
    font-weight: 600;
    font-size: 26px;
    color: #000000;
    margin-bottom: 14px;
  }
  .climate-project-img-left {
    padding: 25px 0px 25px 0px;
  }
  .climate-offseting-sec-rightblock-head {
    font-weight: 600;
    font-size: 23px;
    color: #000000;
    margin-bottom: 1px;
  }
  .climate-offseting-sec-leftblock-head {
    font-weight: 600;
    font-size: 23px;
    color: #000000;
    margin-bottom: 14px;
  }
  .climate-action-not-difficult-head p {
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
  }
  .our-approach-carbon-offsetting-works .head p {
    font-size: 24px;
    line-height: 36px;
  }
  .our-approach-main-sec {
    padding: 115px 0px 90px 0;
  }
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .my-subscription-wrapper {
    margin: 16px;
    padding: 16px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px rgb(171 168 168 / 51%);
  }
  .my-subscription-card {
    display: flex;
    justify-content: space-between;
  }
  .my-subscription-card-name {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .my-subscription-card-subtext {
    padding-right: 3px;
    height: 22px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #90a0b7;
  }
  .my-subscription-card-total_offset {
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    flex-direction: column;
    padding: 1px 10px;
    height: 24px;
    background: #9dbd4f;
    border-radius: 19px;
    margin: 5px 0px;
  }
  .my-subscription-card-date {
    display: flex;
  }
  .my-subscription-card-amount_invested {
    font-size: 12px;
    line-height: 14px;
  }
  .my-subscription-card-table-data {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
  .my-subscription-action {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
  h4.my-subscription-card-project_type {
    font-size: 14px;
    margin: 0;
  }
  .my-subscription-image {
    width: 25px;
    height: 25px;
  }
  p.my-subscription-title {
    padding-bottom: 8px;
  }
  .my-subscription-card-header {
    font-size: 12px;
    font-weight: 500;
  }
  span.my-subscription-card-frequency-value {
    font-size: 11px;
  }

  .ct_not_difficult_box .ant-col {
    margin-bottom: 10px;
}

}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .indv-dash-order-table table {
    font-size: 8px !important;
  }
  .indv-dash-order-table table p {
    font-size: 8px !important;
  }
  .indv-dash-order-table table > thead > tr > th {
    padding: 5px 1px;
  }
  .indv-dash-order-table table tbody > tr > td {
    padding: 5px 1px;
  }
  .indv-dash-order-table table tbody > tr > td > img {
    height: 42px;
    width: 22px;
  }
  .indv-dash-order-table-img {
    padding: 5px !important;
  }
  .inv-order-dash-pagin {
    font-size: 9px;
  }
  .ns-page {
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .inv-order-dash-pagin .page-item:not(:first-child) .page-link {
    margin-left: -8px;
  }
  .indv-order-history-texts {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .indv-order-history-texts-heading {
    font-size: 13px !important;
  }
  .indv-order-history-texts-firstblock {
    font-size: 14px !important;
  }
  .indv-order-history-texts-secondblock {
    font-size: 11px !important;
  }
  .indv-order-history-block {
    display: flex;
    flex-direction: column;
  }
  .tackle-climate-change-part {
    margin: 120px 0 140px 0;
  }
  .carbon-offsetting-work-block .bgImg {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }
  .carbon-approach-emission-sec {
    padding-right: 16px !important;
  }
  .carbon-app-reduce-emissions-main > div {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .carbon-app-reduce-emissions-head > h3 {
    text-align: center;
    margin-bottom: 0px;
  }
  .carbon_approach_emission .description {
    font-size: 14px;
  }
  .transparency-at-our-code-sec-approach {
    padding: 38px 0 39px !important;
  }
  .approach-img-sec p {
    padding-left: 0px;
  }
  .approach-us-end-sec-block2 > div {
    padding: 0px 7px !important;
  }
  .transparency-at-our-code-sec-head-main p {
    text-align: start;
  }
}
@media screen and (min-width: 577px) {
  .approach-img-sec p {
    padding-left: 0px;
  }

 
}


@media screen and (max-width:414px) {
  .ant-row.ct_para_cnt {
    height: 0px;
    overflow: hidden;
    transition: 0.4s linear;
}
.ct_accordian_tab {
  text-align: left;
  padding-inline: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 0px !important;
  margin-top: 74px;
  padding-block: 9px !important;
  margin-inline: 25px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow:0px 0px 25px rgba(0,0,0,0.09);
  font-size: 16px;
  border: 1px solid #3333333b;
  color: #88ae47;
  margin-top: 90px;
}
.ct_margin_top_25{
  margin-top:25px !important;
}
.ct_margin_right1_0{
  margin-right: 0px;
}
.carbon-offset-onetime-life-sec-card-dropdown{
  width:100% !important;
  padding-bottom: 24px !important;
}

.ct_accordian_tab button {
  border: 0px;
  background-color: #88ae47;
  color: #ffff !important;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.ct_accordion_item .Tool_user_number {
  margin-left: 13px;
}
.ct_tool_user_contents p{
  text-align: left;
}


.ct_accordion_item {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  padding: 10px 0px;
  border: 1px solid #88ae47;
  margin-inline: 0px !important;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom:45px;
  margin-inline: 13px !important;
}
.ct_accordion_item > .ant-col {
  padding-left: 10px !important;
}
.ct_order_box {
  margin-top: 25px !important;
}
.ct_order_box .ant-card-head {
  margin-top: 0px !important;
}
.ct_order_box .ns-order-summary-header {
  font-size: 20px;
  font-weight: 500;
}

.ct_card_flex_div .ant-col {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom:15px
}
}


.ct_inner_gift_card_main {
  display: block !important;
}
.ct_inner_gift_card_main .ct_bottom_cnt{
  display: block;
}

.ct_inner_gift_card_main .ct_bottom_cnt .ct_bottom_item:first-child{
width: 100%;
}
.ct_inner_gift_card_main .ct_bottom_cnt {
  display: block;
  margin-top: 0px !important;
}
.ct_inner_gift_card_main .ct_head_logo img {
  width: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}
.ct_inner_gift_card_main .ct_head_logo {
  text-align: center;
  margin-bottom: 15px;
}
.ct_align_unset{
  align-items: unset  !important;
}
.ct_inner_gift_card_main .ct_head_flex{
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  margin-bottom: 20px;
}
.ct_inner_gift_card_main .ct_gift_short_head h5{
  text-align: center;
}
.ct_inner_gift_card_main .ct_gift_bgimg12 img {
  height: 120px;
}
.ct_inner_gift_card_main .ct_gift_title {
  text-align: center;
}
.ct_inner_gift_card_main {
  border-radius: 10px;
  margin-bottom: 24px  !important;
  height: 100%;
}
.ct_inner_gift_card_main .ct_gift_bgimg12 {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  align-self: end;
}
.ct_inner_gift_card_main .ct_gift_left{
  padding-bottom: 0px !important;
}
@media screen and (max-width:991px){
  #order-summary-mobile{
    margin-top: 24px!important;
  }
}
@media screen and (max-width:575px) {
  .ct_inner_gift_card_main .ct_head_flex{
    padding-left: 15px;
  }
  .ct_inner_gift_card_main .ct_gift_bgimg12 {
    display: block;
  }
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px;
  height: 50px;
}
/*================  Leftcontainer =============== */
.Individual_leftScreen {
  display: flex;
  flex-direction: column;
  width: 200px;
  /* height: 573px; */
  height: 908px;
  border-radius: 10px;
  overflow: scroll;
  line-height: 40px;
}

.tooltipForChart {
  background: white;
  padding: 10px;
}
.leftScreen {
  width: 200px;
  height: 100vh;
  border-radius: 10px;
  background-color: var(--color-light-gray) !important;
}
.climate_project_head {
  padding: 30px 0 20px 20px !important;
}
.climate_project_head h2 {
  font-size: 30px;
}
.climate_project_home {
  flex-flow: nowrap !important;
}
.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 16px;
  padding: 0px 16px;
  line-height: 3.5;
}
.menuItem:hover {
  background-color: var(--color-light-gray-hover);
  border-radius: 5px;
}
.MenuImg {
  width: 34px;
  height: 24px;
  padding-right: 10px;
}
.menulist {
  padding: 0;
  margin-top: 10px;
}
.set_arrow {
  position: relative;
}
.sidearrow {
  width: 10px;
  position: absolute;
  left: 0;
  top: 14px;
  /* visibility: hidden; */
}
.hamburger_toggle {
  cursor: pointer;
  display: none;
}
.menulist:active .sidearrow {
  visibility: visible;
}

@media screen and (max-width: 820px) {
  .leftScreen {
    width: 100%;
  }
  .menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 16px;
    padding: 0px 16px;
    justify-content: center;
    line-height: 10;
  }
  .menulist {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .menuItem:hover {
    background-color: var(--color-light-gray-hover);
    border-radius: 5px;
  }
  .hamburger_toggle {
    /* width: 100px;
    height: 100px; */
    display: block;
    position: fixed;
    top: 8px;
    right: 0;
    right: 16px;
    font-size: 30px;
    z-index: 9999;
  }

  .leftScreen {
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(100%);
  }

  .leftScreenSlideIn {
    border-radius: 10px;
    line-height: 38px;
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0%);
    z-index: 99;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 16px;
  padding: 0px 16px;
}

.menuItem:hover {
  background-color: var(--color-light-gray-hover);
  border-radius: 5px;
}
ul {
  padding: 0;
}
.medileScreen {
  display: flex;
}
.ant-row.ct_grid_2.ct_block_sec {
  display: block !important;
}
.ant-row.ct_grid_2.ct_block_sec p {
  margin-bottom: 0px;
  margin-top: 0px;
}

@media screen and (max-width: 820px) {
  .Individual_leftScreen {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .leftScreen {
    width: 100%;
  }
  .menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 40px 16px;
    padding: 0px 16px;
    justify-content: center;
    line-height: 2;
  }
  .menulist {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .menuItem:hover {
    background-color: var(--color-light-gray-hover);
    border-radius: 5px;
  }
  .hamburger_toggle {
    /* width: 100px;
    height: 100px; */
    display: block;
    position: fixed;
    top: 4px;
    right: 0;
    right: 16px;
    font-size: 30px;
    z-index: 9999;
  }

  .leftScreen {
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(100%);
  }

  .leftScreenSlideIn {
    border-radius: 10px;
    line-height: 38px;
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0%);
    z-index: 99;
  }

  .T_M_Screen_btn {
    justify-content: center !important;
    flex-wrap: wrap;
  }
}
/* *******************Individual_Main_Middle_Container*************** */
.Individual_Main_Middle_Container {
  display: flex;
  width: 100%;
  margin-top: 68px;
}

@media screen and (max-width: 420px) {
  .Individual_leftScreen {
    width: 100%;
  }
}

/*================== middleContainer ================*/

/* .Individual_MiddleContainer {
   display: flex;; 
  margin-top: 80px;
} */

.Individual_MiddleScreenMain {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.Individual_middleScreen1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.Individual_first_block {
  /* width: 50%; */
  height: 212px;
  background-color: var(--color-white);
  /* margin: 9px 10px; */
  border-radius: 22px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}
.business_first_block {
  height: 200px;
  background-color: var(--color-white);
  border-radius: 22px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.first_block1 {
  display: flex;
  width: 100%;
  height: 140px;
  background-color: var(--color-individual);
  border-radius: 22px;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.business_first_block .first_block1 {
  display: flex;
  width: 100%;
  height: 135px;
  background-color: var(--color-individual);
  border-radius: 22px;
}

.first_block_img {
  display: flex;
  align-items: center;
}
.first_block_current_year_container {
  padding-bottom: 30px;
}
.first_block_current_year {
  padding-left: 5px;
  padding-right: 30px;
  color: #ffff;
  font-size: 15px;
}
.project_distribution_current_year {
  padding-left: 5px;
  padding-right: 30px;
  font-size: 15px;
}
.ns-project-distributiondradio-wrapper {
  display: inline;
  padding-left: 15px;
}
.first_block_since_member {
  padding-left: 5px;
  color: #ffff;
  font-size: 15px;
}
.project_distribution_since_member {
  padding-left: 5px;
  font-size: 15px;
}

.first_blockT {
  color: var(--color-white);
  font-size: 19px;
  font-weight: 600;
}

.firstblockN {
  color: var(--color-white);
  font-size: 22px;
  margin-right: 8px;
}

.blockImg {
  width: 32px;
  height: 32px;
  margin: 4px 8px 0px 0px;
}

.first_block_right {
  display: flex;
  width: 70%;
  height: 140px;
  justify-content: flex-end;
}

.first_b_inner {
  display: flex;
  /* width: 50%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 22px;
}
.first_b_inner span {
  color: #f0fde2;
  font-size: 16px;
  font-weight: 600;
}
.first_block_rightP {
  color: var(--color-individual-white);

  background: none;
  border: none;
}

.first_btn {
  background: #f4f8ea;
  padding: 10px 20px;
  border-radius: 10px;
  border-color: var(--color-individual-white);
  color: var(--color-individual);
  border: 0;
  font-size: 19px;
}

.first_blocklastP_main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-size: 17px;
}

.Individual_second_block {
  justify-content: space-between;
}

.Individual_first_square {
  /* width: 50%; */
  height: 212px;
  background-color: var(--color-individual-firstsquare);
  border-radius: 22px;
}
.business_first_square {
  height: 200px;
  background-color: var(--color-individual-firstsquare);
  border-radius: 22px;
}

.firstsquare_box {
  padding: 25px 30px;
}

.firstsquareN {
  color: var(--color-black);
  font-size: 26px;

  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
}
.business_first_square .firstsquareN {
  color: var(--color-black);
  font-size: 40px;

  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
}
.color-color-individual {
  color: var(--color-individual);
}
.firstsquareT {
  color: var(--color-individual);
  font-size: 17px;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
.firstsquareT1 {
  margin-left: 10px !important;
}
.firstsquareT2 {
  margin-left: 20px !important;
}
.business_first_square .firstsquareT {
  color: var(--color-individual);
  font-size: 17px;

  padding-top: 20px;
}

.Individual_second_square {
  margin-left: 10px;
  background-color: var(--color-individual-secondsquare);
}

.secondsquare_box {
  padding: 25px 30px;
}

.secondsquareN {
  color: var(--color-black);
}

.secondsquareT {
  color: var(--color-individual-secondsquareT);
}

.head-off-plant-text {
  display: flex;
  color: #acacac;
  font-weight: 600;
}
.head-off-plant-text a {
  padding: 0 5px;
  color: #88ae47;
  font-weight: 600;
}
.head-off-plant-text a:hover {
  color: #88ae47;
}
/* .downawar_set {
  width: 100%;
  position: absolute;
  bottom: 0;
} */
.Individual_S_M_Screen {
  /* background: url("../assets/map.png"); */
  width: 100%;
  background-size: cover;
  height: 627px;
  border-radius: 22px;
  margin-top: 22px;
  /* margin: 10px 10px; */
}
.map_screen_main {
  position: relative;
}
.map_screen_main_inner {
  position: absolute;
  top: 0;
}
.map_screen_main .gmnoprint {
  display: none;
}
@media screen and (max-width: 560px) {
  .map_marker {
    width: 300px !important;
    height: 160px !important;
    right: 0% !important;
  }
}

.map_marker {
  width: 350px;
  height: 150px;
  background-color: black;
  position: absolute;
  top: 34%;
  right: 28%;
  border-radius: 15px;
  padding: 12px;
}
.map_marker_plant {
  width: 350px;
  height: 160px;
  background-color: black;
  position: absolute;
  top: 34%;
  right: 28%;
  border-radius: 15px;
  padding: 12px;
}
@media (max-width: 560px) {
  .map_marker_plant {
    width: 350px;
    height: 160px;
    background-color: black;
    position: absolute;
    top: 34%;
    right: 4%;
    border-radius: 15px;
    padding: 12px;
  }
}
@media (max-width: 410px) {
  .map_marker_plant {
    width: 304px;
    height: 160px;
    background-color: black;
    position: absolute;
    top: 34%;
    right: 0%;
    border-radius: 15px;
    padding: 12px;
  }
}

.weight-map-symb {
  color: #cfcfcf !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.marker_text {
  color: var(--color-white);
  font-size: 18px;

  margin: 0px 5px 0 0;
}
.Marker_see_project {
  color: var(--color-individual);
}
.markerinner {
  display: flex;
  align-items: center;
}
.marker_img {
  width: 55px;
  height: 55px;
  border-radius: 15px;
}
.marker_plant_tree {
  height: 230px;
}
.Marker_project_name {
  color: var(--bs-white);

  margin: 0px 0px 0 10px;
}
.s_m_box {
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 19px;
  margin: 4%;
  width: 95%;
  /* padding: 15px 0; */
}
.s_m_box_modal {
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 19px;
  margin: 4%;
}

.s_m_windmil_image {
  width: 46%;
  height: 139px;
}

.s_m_boxP {
  padding: 10px 40px;
  text-align: center;
  font-size: 15px;
  color: var(--color-individual-gray);
}

/******************* S_M_Inner_Box**********************/
.s_m_inner_box1 {
  padding: 12px 10px 0 15px;
}
.hrline {
  margin-top: 0;
  margin-bottom: 0;
}
.s_m_inner_H {
  font-size: 17px;
}
.s_m_inner_H_order {
  padding-left: 20px;
  padding-top: 15px;
}
.s_m_inner_btn_main {
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.s_m_inner_btn_main_order {
  display: flex;
  width: 85%;
  justify-content: space-between;
  padding-left: 20px;
}

.s_m_inner_btn {
  padding-bottom: 12px;
  background-color: var(--color-white);
  border: none;

  color: #c3c3c3;
}
.climate_project_active_line {
  padding-bottom: 12px;
  background-color: var(--color-white);
  border: none;

  border-bottom: 2px solid var(--color-individual);
}

.s_m_inner_box_hr_line {
  width: 100%;
}

.s_m_inner_box2 {
  margin: 13px;
  /* width: 91%; */
  border: 1px solid var(--color-individual-gray);
  border-radius: 10px;
  cursor: pointer;
}
.s_m_inner_box2_order {
  margin: 13px;
  border: 1px solid var(--color-individual-gray);
  border-radius: 10px;
  cursor: pointer;
}
.active_project {
  margin: 13px;
  /* width: 91%; */
  height: 148px;
  border: 2px solid var(--color-individual);
  border-radius: 10px;
  cursor: pointer;
}
.Over_flow_css {
  overflow-x: unset;
  overflow-y: scroll;
  height: 485px;
  width: 320px;
}
.Over_flow_css::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.Over_flow_css::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.Over_flow_css::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.s_m_box2_inner_part {
  display: flex;
  padding: 10px;
  width: 100%;
}
.s_m_box2_inner_Image {
  width: 30%;
  height: 66px;
  /* / border: 1px solid var(--color-individual-gray); / */
}
.s_m_box2_inner_button {
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 23px;
  display: flex;
  flex-direction: column;
  padding: 1px 10px;
  width: 86px;
  height: 24px;
  background: #9dbd4f;
  border-radius: 19px;
}
.s_m_box2_inner_order {
  width: 100%;
  padding-top: 4px;
  padding-left: 10px;
  /* / border: 1px solid var(--color-individual-gray); / */
}
.s_m_inner_Image_main {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.s_m_inner_Image_main_order {
  padding-top: 4px;
  width: 22px;
  height: 25px;
}
.s_m_box2_inner_location {
  padding-left: 10px;
}
.s_m_box2_inner_carbon_order {
  display: flex;
  justify-content: center;
  width: 95%;
  height: 39px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 8px 2px 8px 10px;
  margin-left: 7px;
}
.image-text-map {
  width: 70%;
}
.s_m_box2_inner_carbon_off {
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 95%;
  height: 50px;
  /* / border: 1px solid var(--color-individual-gray); / */
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-individual-Inner-box);
  border-radius: 6px;
  padding: 8px 2px 8px 10px;
}
.s_m_box2_inner_carbon_off1 {
  margin-right: 10px;
}

/******************* S_M_Inner_Box_End**********************/

.Individual_T_M_Screen {
  width: 100%;
  margin: 10px;
}
.Individual_T_M_Screen_climate {
  margin: 10px;
}
.middle_caur {
  width: 355px;
}

.ct_left_div .sc-gsnTZi {
  width: 100% !important;
  left: 0px !important;
}

.hHJTFD {
  margin: 0 !important;
}
.T_M_Header_Center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 413px; */
  height: 500px;
  /* z-index: 9999; */
}
.recharts_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.Pie_chart_div {
  height: 275px !important;
}
.Pie_chart_div img {
  width: 30% !important;
}

.T_M_ScreenP {
  font-size: 22px;
  font-weight: 500;
  padding: 0px 10px;
  margin: 28px 0 0 3px;
}
.empty_card_lbl_P {
  font-size: 24px;
  font-family: Roboto-Bold;
  padding: 0px 10px;
  margin: 0px 0 0 3px;
}

.T_M_box {
  width: 100%;
  margin: 0px;
  margin-bottom: 15px;
}
/******************** T_m_InnerBox************************/

.T_M_boxMain1 {
  display: flex;
  width: 100%;
}
.guIbWC {
  /* width: 100% !important; */
}
.T_M_box_inner {
  width: 100%;
  height: 387px;
  background-color: var(--color-white);
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(171 168 168 / 51%);
}
.T_M_box_inner_order {
  width: 100%;
  height: 105%;
  background-color: var(--color-white);
  border-radius: 20px;
  /* margin: 10px; */
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(171 168 168 / 51%);
}
.T_M_box_inner_climate {
  width: 400px;
  height: 387px;
  background-color: var(--color-white);
  border-radius: 20px;
  /* margin: 10px; */
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(171 168 168 / 51%);
}
@media (max-width: 768px) {
  .T_M_box_inner_climate {
    width: 300px;
    height: 387px;
    background-color: var(--color-white);
    border-radius: 20px;
    /* margin: 10px; */
    position: relative;
    box-shadow: 0px 0px 13px 0px rgb(171 168 168 / 51%);
  }
}

.T_m_wind_inner {
  width: 100%;
  height: 285px;
  border-radius: 20px;
}

.T_M_box_inner1 {
  width: 98%;
  /* height: 56%; */
  position: absolute;
  background-color: var(--color-white);
  bottom: 5px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 10px;
  float: left;
  left: 4px;
}

.climate_pro_box_inner1 {
  width: 98%;
  position: absolute;
  background-color: var(--color-white);
  bottom: 5px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 10px;
  float: left;
  left: 4px;
  cursor: pointer;
}
.climat_pro_Inner_btn {
  display: none;
}
.climate_pro_box_inner1:hover .climat_pro_Inner_btn {
  display: block;
}
.T_M_box_inner2 {
  display: flex;
  flex-direction: column;
}

.T_M_inner2_head {
  font-size: 8px;
  margin: 0px 14px;
}

.T_M_inner2_head1 {
  padding-left: 5px;
  font-size: 14px;
}
.T_M_inner2_head1_climate {
  font-size: 12px;
  width: 70%;
}

.T_M_inner2_image {
  display: flex;
}

.T_m_location_image {
  width: 14px !important;
  height: 18px;
}

.T_m_innerP {
  font-size: 11px;
  padding: 0px 12px;

  color: var(--color-individual-gray1);
}

.project_decrip {
  font-size: 13px;
  padding: 0px 12px;

  color: var(--color-individual-gray1);
}

.T_M_Inner_box_Main {
  display: flex;
  justify-content: space-between;
}
.ct_hide_hidden {
  height: 100%;
}
.ct_hide_hidden {
  height: 100% !important;
  overflow: unset !important;
  padding: 10px;
}

.T_M_Inner_box {
  overflow: auto;
  width: 47%;
  height: 91px;
  background-color: var(--color-individual-firstsquare);
  margin-top: 8px;
  border-radius: 10px;
  display: block;
  align-items: center;
  justify-content: center;
}

/* .project_slider_back {
  top: 32px !important;
  left: 91% !important;
} */

.back-myproject {
  top: 32px !important;
  left: 91% !important;
}

.project_slider_forward {
  top: 32px !important;
  right: 0% !important;
}

.T_M_Main_box1 {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
}

.T_M_Inner_boxP {
  color: var(--color-black);
}
.T_M_Inner_Order {
  width: 44px;
  height: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #4900ff;
}
.T_M_Inner_boxP1 {
  /* color: #9AB9BC; */
  font-size: 25px;
  font-weight: 600;
}

.T_M_Inner_box1 {
  background-color: var(--color-individual-secondsquare);
}

.T_M_InnerN {
  color: var(--color-individual);
  font-size: 25px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
.T_M_InnerNOverFlow {
  display: block;
  color: var(--color-individual);
  font-size: 25px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
.projectDistributionRadio {
  display: flex;
  padding-left: 19px;
  align-items: center;
  padding-top: 20px;
}
.ns-project-distribution-radio {
  display: flex;
  align-items: center;
  justify-content: center;
}
.T_M_InnerN1 {
  /* color: #97A2A3; */
  color: var(--color-individual-amount1);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.T_M_Main_H {
  font-size: 14px;
  margin-bottom: 0;
}

.T_M_Main_H1 {
  padding-right: 4px;
}

.T_M_Inner_btn {
  width: 100%;
  height: 56px;
  border-radius: 7px;
  margin-top: 10px;
  background-color: var(--color-white);
  font-size: 17px;
  color: #333333;
  font-weight: 500;
}

.climat_pro_Inner_btn {
  width: 100%;
  height: 50px;
  border-radius: 7px;
  margin-top: 10px;
  background: #86af47;
  color: #fff;
  border-color: transparent;
  font-size: 17px;
}

/********************* innerTM_End****************/

.T_M_windmil_image {
  width: 160px;
  height: 140px;
  /* padding: 0px 185px */
}

.T_M_Screen_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.T_M_Screen_btn1 {
  background-color: var(--color-individual-gray);
  padding: 3px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  height: 40px;
  margin-right: 20px;
}

.T_M_Screen_btn_Main {
  border: none;
  font-size: 16px;
  border-radius: 6px;
  background: none;
}

.T_M_Screen_btn_Main_link {
  border: none;
  font-size: 16px;
  border-radius: 6px;
  background-color: var(--bs-white);
}

.Individual_F_M_Screen {
  width: 100%;
  margin: 10px 0px;
}

.F_M_box {
  border-radius: 10px;
  /* height: 165px; */
  overflow: auto;
  background-color: var(--color-white);
  display: flex;
  border: none;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  width: 95%;
  margin: 0 10px 0 10px;
}

.F_M_box_Main1 {
  /* width: 55%; */
  height: 150px;
  border-radius: 10px;
  margin: 6px 31px;
  background-size: cover;
  padding: 10px 0;
}

.F_M_box_Img {
  width: 185px;
  height: 135px;
  border-radius: 8px;
}
.F_M_box_Main2 {
  padding-top: 10px;
}

.F_M_box_heading {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.55);
  margin: 12px 0px 0 0;
}

.F_M_box_P {
  color: #969595;
  padding: 0px 36px 10px 0px;
  text-align: justify;
  font-size: 16px;
}

.F_M_box_S {
  cursor: pointer;
  color: var(--color-individual);
}
.Carousel_Main {
  position: relative;
}
.disbleBtnDynamic .rec-arrow-left {
  position: absolute;
  top: 28px;
  right: 17px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-right {
  position: absolute;
  top: 28px;
  right: -9px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-right:hover:enabled,
.disbleBtnDynamic .rec-arrow-right:focus:enabled,
.disbleBtnDynamic .rec-arrow-right:hover {
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec !important;
  color: #706e6e !important;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-left:hover:enabled,
.disbleBtnDynamic .rec-arrow-left:focus:enabled,
.disbleBtnDynamic .rec-arrow-left:hover {
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec !important;
  color: #706e6e !important;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-left:disabled,
.disbleBtnDynamic .rec-arrow-right:disabled {
  cursor: not-allowed;
  display: none;
}
.yt_slider_back {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  border: none;
  color: #706e6e;
}
.yt_slider_forward {
  position: absolute;
  bottom: 0;
  right: 91%;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  border: none;
  color: #706e6e;
}
.border_empty_state {
  border: 1px solid;
  border-radius: 19px;
  margin: 12px;
}
@media screen and (max-width: 420px) {
  .F_M_box_P {
    font-size: 12px !important;
    padding: 0px 15px 10px 0px;
    word-spacing: 0px;
  }
}
@media screen and (max-width: 820px) {
  .Individual_middleScreen1 {
    flex-wrap: wrap;
  }
  .Individual_first_block {
    width: 100%;
  }
  .Individual_second_block {
    width: 100%;
    margin-top: 15px;
  }
  /* .s_m_box {
    width: 100%;
  } */

  .F_M_box_P {
    height: 117px;
  }
  .F_M_box_heading {
    font-size: 15px;
  }
  .T_M_windmil_image {
    width: 38%;
    height: 127px;
    /* padding: 0px 131px; */
  }
  .s_m_windmil_image {
    width: 46%;
    height: 105px;
  }
  .yt_slider_back {
    left: 47%;
  }
  .yt_slider_forward {
    right: 47%;
  }
}

@media screen and (max-width: 420px) {
  .yt_slider_back {
    left: 43%;
  }
  .yt_slider_forward {
    right: 43%;
  }

  .F_M_box_Img {
    width: 120px;
    height: 120px;
  }
  .F_M_box_Main1 {
    margin: 42px 12px;
  }
  .F_M_box {
    height: 225px;
  }
}

/*==================== IndiviDualMIddleScreenEmptyCart ===============*/

.E_M_box {
  height: 809px;
}

.E_M_ScreenP {
  text-align: center;
  padding: 0px;
}

.E_M_boxP {
  padding: 0px;
}

.E_Cart_Arrow {
  font-size: 44px;
  color: var(--color-dark-grey);
}

.E_M_S_Head {
  display: flex;
}

.E_M_ScreenP {
  font-size: 31px;
  padding-top: 4px;
  padding-left: 10px;
}

.E_M_Image {
  height: 172px;
}

.carousel-climate-project .rec-pagination {
  display: none !important;
}
.screen-loader-center {
  display: flex;
  justify-content: center;
  padding: 150px;
}
.screen-loader {
  width: 150px;
}
.carbonFootPrintPageBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fa-angle-left {
  cursor: pointer;
}
.Pie_chart {
  padding: 0px 15px;
  height: 100%;
  width: 100%;
}
.Pie_chart text {
  font-size: 10px !important;
}
.Pie_chart #reactgooglegraph-2 > div > div {
  margin: auto !important;
}
.Pie_chart_rechart {
  padding: 0px 15px;
  height: 70%;
  width: 100%;
}
.Pie_chart_rechart text {
  font-size: 10px !important;
}
.Pie_chart_rechart #reactgooglegraph-2 > div > div {
  margin: auto !important;
}
.indi_donut_chart p {
  position: absolute;
  top: 12.5rem;
  z-index: 1;
  right: 23.5rem;
  font-size: 16px;
  font-weight: 600;
}
.carsousal-items-logo-image1 {
  height: 200px;
  width: 100%;
}
.carsousal-items-logo-image2-image3 {
  height: 80px;
  width: 100%;
  margin-top: 0px;
}
.carsousal-items-logo-image3 {
  margin-top: 0px !important;
  height: 80px !important;
  width: 100% !important;
}
.ant-modal.idv-signup-reset-pass-modal {
  max-width: 800px;
  width: 100% !important;
}
.OrderHistoryOneTime_table_header_SIDEB.order-history-text {
  align-items: self-start;
}
.OrderHistoryOneTime_table_header_SIDEA {
  width: 20% !important;
}
.OrderHistoryOneTime_table_header_SIDEB {
  width: 70% !important;
}

p.order_historyinfo.order-history-text-info {
  text-align: left;
}
@media screen and (min-width: 360px) and (max-width: 767px) {
  .Individual_first_square_ns {
    height: 161px !important;
  }
  .carsousal-items-logo-image1 {
    height: 110px;
  }
  .carsousal-items-logo-image2-image3 {
    margin-top: 0px !important;
    height: 200px !important;
    width: 100% !important;
  }
  .carsousal-items-logo-image3 {
    margin-top: 0px !important;
    height: 80px !important;
    width: 90% !important;
    margin-left: 5% !important;
  }
  .head-off-plant-text {
    font-size: 13px;
  }
  .first_block_img .blockImg {
    width: 27px;
    height: 27px;
    margin: 5px 8px 0px 25px;
  }
  .blockImg {
    width: 27px;
    height: 27px;
    margin: 5px 8px 0px 0px;
  }
  .firstsquareT {
    font-size: 14px;
  }
  .head-off-plant-text {
    display: unset;
  }
  .first_blocklastP_main {
    padding: 0px 12px;
    font-size: 12px;
  }
  .first_btn {
    font-size: 12px;
    padding: 10px 10px;
  }
  .first_block_rightP {
    font-size: 12px;
    padding: 10px 0px;
  }

  .Bussiness_Impact_Offset {
    width: 100%;
  }
  .Bussiness_project_distribution {
    width: 100%;
  }

  .F_M_box_heading {
    font-size: 12px;
  }
  .F_M_box_P {
    font-size: 12px;
  }
  .business_first_square .firstsquareT {
    padding-top: 8px;
  }
  .Individual_MiddleScreenMain {
    padding: 15px;
  }
  .carbon_heading {
    font-size: 20px;
  }
  .climate_project_home {
    flex-flow: row wrap !important;
  }
  .Individual_first_square {
    height: 185px;
  }
  .ns- project-distributiondradio-wrapper {
    display: block;
  }
  .ns-Bussiness_project_distribution_h {
    width: 44%;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .firstsquareT {
    padding-top: 0;
  }
  .T_M_Header_Center {
    height: 400px;
  }
  .indi_donut_chart p {
    right: 20rem;
  }
  .Individual_first_square {
    height: 185px;
  }
}
.p_c_box_Section {
  display: contents;
  font-weight: 600;
  color: #3f161a;
  cursor: pointer;
  padding-left: 4px;
}
/* --------------Mobile Responsive End */
.for_success {
  position: relative;
}
.usd_success {
  font-weight: 600 !important;
  color: #88ae47 !important;
}
.usd_success_tonnes {
  font-size: 12px;
}
.success_position {
  position: absolute;
  right: 0;
  top: 5px;
}
.dashboard-header-text {
  padding-left: 7px;
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .dashboard-header-text {
    display: flex;
    color: #acacac;
    font-weight: 600;
    font-size: 13px;
  }
  .ant-row.indv-offset-order-total-1.ct_flex_important,
  .indv-offset-order-total-2.ct_flex_important {
    display: flex !important;
  }
  .indi-dashboard-header-text {
    font-size: 11px !important;
  }
  .T_M_InnerN {
    font-size: 19px;
  }
  .first_blockT {
    font-size: 14px !important;
  }
  .T_M_InnerN1 {
    font-size: 16px;
  }
  .T_M_Main_H {
    font-size: 12px !important;
  }
  .indi_donut_chart p {
    font-size: 14px;
  }
  .climate-project-inner-section {
    width: 287px;
  }
  .climate-inner-box {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .Indv-project-middle-carousel {
    width: 300px !important;
  }
  .T_M_Screen_btn_Main {
    font-size: 12px !important;
  }
  .T_M_Screen_btn_Main_link {
    font-size: 12px;
    height: 100%;
  }
  .T_M_InnerNOverFlow {
    font-size: 20px;
  }
  .business_first_square .firstsquareN {
    padding-top: 10px;
    font-size: 19px;
  }
  .business_first_square .firstsquareT {
    padding-left: 0px;
    font-size: 16px;
  }
  .Individual_first_square .firstsquareN {
    padding-top: 10px;
    font-size: 19px;
  }
  .Individual_first_square .firstsquareT {
    padding-left: 0px;
    font-size: 16px;
  }
  .F_M_box_Main1 {
    margin: 13px 12px;
  }
  .F_M_box_heading {
    font-size: 14px;
    padding-right: 13px;
  }
  .F_M_box_P {
    padding: 10px 19px 10px 0px;
  }
  .F_M_box {
    padding: 5px;
  }
  .bussiness_graph_height {
    padding: 8px !important;
    vertical-align: left !important ;
  }
  .Individual-footprint-box-image {
    width: 103px;
    height: 103px;
  }
  .Individual-footprint-box {
    height: -moz-fit-content;
    height: fit-content;
  }
  .Individual-footprint-box-card {
    margin: 13px 12px 0px 10px;
  }
  .Individual-footprint-box-para {
    padding: 10px 10px 0px 0px;
    text-align: start;
  }
  .s_m_box2_inner_order.subscripttion-image-bussiness {
    width: 10%;
  }
  .s_m_box2_inner_order.s_m_box2_inner_order-mobile {
    padding-top: 0px;
  }
  .s_m_box2_inner_part.s_m_box2_inner_part-mobile {
    padding-left: 15px;
    padding-top: 15px;
  }
  .s_m_box2_inner_order.s_m_box2_inner_order-mobile-bussiness {
    width: 9%;
  }
  .T_M_inner2_image-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .s_m_box2_inner_button {
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 23px;
    display: flex;
    flex-direction: column;
    padding: 1px 10px;
    width: 107px;
    height: 24px;
    background: #9dbd4f;
    border-radius: 19px;
    margin-left: 10px;
  }
  .s_m_box2_inner_order.indv-onetime-order-history-image {
    width: 10%;
  }
  .T_M_inner2_image.indv-onetime-order-history-project-name {
    width: 100%;
    justify-content: space-between;
  }
  .ct_mt_40 {
    margin-top: 40px;
  }
  .ct_margin_0_1_0 {
    margin-inline: 0px !important;
  }
  .ct_margin_0_1_0 .ant-col {
    padding-inline: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  .map-marker-section {
    width: 260px !important;
    right: 10% !important;
    font-size: 12px;
  }
  .marker_text {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 992px) {
  .dashboard-header-text {
    display: flex;
    color: #acacac;
    font-weight: 600;
    font-size: 13px;
  }
  .first-year-container {
    display: flex;
  }
  .impact-container-block {
    margin-right: -25px;
  }
  .first-year-container input[type="radio"] {
    margin-bottom: 25px;
  }
  .impact-block-button {
    font-size: 16px;
    width: 73px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first_block_current_year {
    padding-right: 7px;
  }
  .ns-project-distributiondradio-wrapper {
    padding-left: 0px;
  }
  .ns-project-distribution-radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .OrderHistoryOneTime_table_header_SIDEB {
    width: auto !important;
    grid-gap: 10px;
    gap: 10px;
  }
  .OrderHistoryOneTime_table_header_SIDEB .order-history-text-header {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 577px) {
  .T_M_InnerN1 {
    font-size: 24px;
  }
  .carsousal-items-logo-image2-image3-ns {
    margin-top: 0px !important;
  }
}

#components-layout-demo-responsive .logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.indv-layout .ant-layout-header {
  padding: 0px 24px !important;
  box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 7%);
  z-index: 1;
}

.indv-logo-head {
  height: 64px !important;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-layout-sub-header-background {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.site-layout-sub-header-background2 {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
}

.site-layout-background {
  background: #fff !important;
}

.indv-dash-sidebar {
  background: #f8f6f7 !important;
}

.indv-dash-sidebar .ant-menu.ant-menu-dark {
  background: #f8f6f7 !important;
}

/* .indv-dash-sidebar .ant-menu-title-content {
  color: #89898b !important;
} */
.ant-menu-title-content a{
  font-size: 12px !important;
}
.indv-dash-sidebar .anticon {
  color: #89898b !important;
}

.indv-dash-sidebar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #eeeced !important;
}

.indv-dash-sidebar .ant-menu-dark .ant-menu-item > span > a {
  color: #89898b !important;
}

.indv-dash-side-img img {
  width: 24px;
  margin-right: 10px;
}
.image_side_nav{
  position: absolute;
  left: 0;
  width: 7px;
  top: 13px;
  display: none;
}
.ant-tooltip-content{
  display: none;
}

.indv-dash-sidebar .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* width: 85%;
  margin-left: 25px; */
  /* background-color: #eeeced !important; */
  /* border-radius: 6px;
  padding-left: 12px !important; */
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  /* width: 100%;
  padding-left: 35px !important; */
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f8f6f7 !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #f8f6f7 !important;
}

.ant-menu-submenu-selected {
  color: #000000 !important;
}

.ant-menu-item-selected a {
  color: #000000 !important;
}

.ant-menu-inline .ant-menu-item {
  width: -moz-max-content !important;
  width: max-content !important;
} 

.ant-layout-sider-children {
  background-color: #ffffff;
}

.indv-dash-custom-tbl .ant-table-thead>tr>th {
  width: 200px;
}

.ant-layout-sider-zero-width-trigger{
  background-color:#ccc !important;
}

.indv-dash-sidebar .anticon {
  color: #333 !important;
}

@media screen and (max-width: 576px) {
  .indv-dash-sidebar {
    z-index: 100;
    height: 100%;
    position: absolute !important;
  }
  .indv-layout .ant-layout-header {
    padding: 0px 11px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px){
  .indv-dash-sidebar {
    z-index: 100;
    height: 100%;
  }
}

.indv-faq-head {
  display: flex;
  align-items: center;
}

.indv-faq-head p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}

.indv-faq-head button {
  border: none;
  border-radius: 36px;
  height: 34px;
  width: 34px;
  margin-right: 25px;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}

.indv-faq-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-faq-sec .ant-collapse-content {
  border-top: unset !important;
}
.indv-faq-sec .collapse_arrow{
  width: 22px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  font-weight: bolder;
}
.ul_li_list li{
  list-style: unset ;
}
.ul_li_list-ns > p{
  margin-left: 0px !important;
}
.faqs-collapse-ns .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
}
.indv-add-cart-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}
.nomodal-success-page {
  display: none;
}
.img_section_custome{ 
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0,0,0,.15);
  margin: 15px;
  border-radius: 10px; 
  padding: 2px;
}
.ct_cart_img1 img{
max-width: 50px !important;
height: 50px !important;
width: 50px !important;
}
.close-success-page {
  width: 33px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;
  color: black;
  padding-left: 10px;
  float: right;
  padding-top: 5px;
}

.ct_align_start{
  align-items: flex-start !important;
}
.ct_Comforter_font {
  font-family: 'Comforter', cursive !important;
 
}
.ct_img_252_w img {
    height: 110px !important;
}

.ct_payment_gift_middle .ct_middle_item {
  width: 100% !important;
  height: 100%;
  align-items: center;
}
.ct_payment_gift_middle {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.ct_payment_gift_middle .ct_middle_item {
  margin-bottom: 0px;
}
.ct_font_14{
  font-size: 14px;
}
.ct_font_35{
  font-size: 35px;
}
.ct_payment_gift_middle .ct_middle_item h5{
  font-size: 12px;
}

.ct_bottom_cnt.ct_payment_gift_btm {
  margin-top: 20px;
}
.ct_payment_gift_btm .ct_bottom_item img{
  width: 100px;
}
.ct_h_126{
  width: 100%;
}
.ct_h_126 img{
  height: 126px!important;
}


@media screen and (max-width: 576px) {
  .modal-content-success-page {
    width: 338px !important;
  }
  .modal-text-success-page {
    padding: 0px 14px !important;
  }
  .ct_big_gift_middle .ct_middle_item {
    width: 100%;
  }
  .ct_middle_cnt.ct_big_gift_middle{
    margin-top: 30px;
  }
  .ct_flex_between{
    display: flex;
    justify-content: space-between;
  }
  .ct_Comforter_font.ct_font_75 {
    font-size: 100px;
  }
  .ct_gift_left_12{
    margin-inline:auto;
  }
  .ct_cart_img1 img{
    max-width: 35px !important;
    height: 35px !important;
    width: 35px !important;
    }
}
.ct_gift_height img{
  width: 100%;
  /* aspect-ratio: 1/1; */
}
.modal-success-page {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content-success-page {
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #888;
  width: 450px;
  height: 10%;
  color: red;
}
.d-flex-pagination {
  display: flex !important;
  flex-wrap: wrap;
}
.justify-content-pagination {
  justify-content: space-between;
}
.ct_middle_item {
  grid-gap: 10px;
  gap: 10px;
}
.ct_height_157 {
  height: 245px !important;
width: 100%!important;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 768px) {
  .justify-content-pagination {
    justify-content: center;
  }
  .errorToastContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94% !important;
    height: 75px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 20px;
    position: fixed;
    z-index: 2;
    margin: auto;
    right: 10px;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px;
  }
}
@media (min-width: 391px) and (max-width: 768px) {
  .apply-now-margin-padding-ns {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .align-apply-button-ns {
    display: flex;
    justify-content: center;
  }
}
.errorToastContainer {
  height: 80px;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 20px;
  position: fixed;
  z-index: 2;
  margin: auto;
  right: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.errToastIconLeft {
  height: 40px;
  width: 40px;
}
.toastText {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  overflow-wrap: break-word;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.closeToast {
  display: none;
}
.projectDistribution-length-default-ns {
  position: absolute !important;
  top: 43% !important;
}
.projectDistribution-length-one-ns {
  left: 34% !important;
}
.projectDistribution-length-two-ns {
  left: 33% !important;
}
.projectDistribution-length-three-ns {
  left: 32% !important;
}
.projectDistribution-length-four-ns {
  left: 31% !important;
}
.projectDistribution-length-five-ns {
  left: 30.5% !important;
}
.projectDistribution-length-six-ns {
  left: 29.5% !important;
}
.projectDistribution-length-seven-ns {
  left: 29% !important;
}
.ct_payment_gift_middle .ct_middle_item h5,.ct_middle_item h5 strong {
  font-family: 'Noto Serif SC', serif;
}
@media screen and (max-width:1024px) and (min-width:768px){
  .ct_gift_card_main.ct_payment_gift_main{
    grid-template-columns: 72% 26%;
  }
}
@media (max-width: 768px) {
  .projectDistribution-length-one-ns {
    left: 32% !important;
  }
  .projectDistribution-length-two-ns {
    left: 31% !important;
  }
  .projectDistribution-length-three-ns {
    left: 30% !important;
  }
  .projectDistribution-length-four-ns {
    left: 29% !important;
  }
  .projectDistribution-length-five-ns {
    left: 28.5% !important;
  }
  .projectDistribution-length-six-ns {
    left: 27.5% !important;
  }
  .projectDistribution-length-seven-ns {
    left: 27% !important;
  }
}

.toastClose {
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: block;
}
.closeIconConatiner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
.toast-flex {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
}
.modal-text-success-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
}
.indv-add-cart-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-add-cart-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-add-cart-sec .ant-collapse-content {
  border-top: unset !important;
}
.indiv_add_cart_table .product-label-tbl {
  display: flex;
}
.indiv_add_cart_table .product-label-tbl p {
  font-weight: 800;
  padding-left: 5px;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-tbl-ns p {
  font-weight: 400 !important;
}
.indiv_add_cart_table .product-label-imgLbl-tbl {
  display: flex;
  align-items: center;
}
.indiv_add_cart_table .product-label-imgLbl-tbl p {
  font-weight: 800;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-imgLbl-tbl img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.indiv_add_cart_table .ant-collapse-header {
  color: #2a2a2a;
  font-size: 18px;
  font-weight: 900;
}
.indiv_add_cart_table .ant-table-thead > tr > th {
  position: relative;
  color: #ababab;
  font-weight: 600;
  background: transparent;
  border-bottom: none;
}
.indiv_add_cart_table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.indv-cart-btn {
  width: 250px;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  margin-top: 15px;
}
.cart-border-top {
  border-top: 1px solid #ababab;
}
.indiv_add_cart_table .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.indiv_add_cart_table .cart-border-top {
  padding: 16px;
}
.tble-circle-img {
  cursor: pointer;
}
.cart-radio-button {
  display: flex;
  flex-direction: column;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner {
  border-color: #629e9f;
  width: 20px;
  height: 20px;
  border-width: 3px;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner::after {
  background-color: #629e9f;
}
.payment-method-input {
  display: flex;
  flex-direction: column;
}
.payment-method-input .ant-form-item {
  margin-bottom: 0;
}
.payment-method-input .ant-input {
  border-radius: 8px;
  height: 50px;
}
.payment-method-input .ant-input-lg {
  padding: 6.5px 15px;
}
.cart-border {
  border-top: 1px solid #ababab;
  margin: 15px 0;
}
.debit-credit-card {
  padding: 20px 25px;
}
.AddNewCard-margin-ns {
  margin: 25px !important;
}
.card-padding-payment-ns {
  padding: 20px;
}
@media (max-width: 768px) {
  .debit-credit-card-padding-ns {
    padding: 20px 1px !important;
  }
  .AddNewCard-margin-ns {
    margin: 5px !important;
    margin-top: 15px !important;
  }
  .card-padding-payment-ns {
    padding: 5px;
  }
}
.debit-credit-card .ant-card {
  border-radius: 8px !important;
}
.debit-credit-card .ant-card .ant-card-body {
  padding: 0;
}
.payment-method-input .ant-radio-wrapper {
  align-items: center;
}
.saved-card-details {
  display: grid;
  padding-left: 10px;
}
.saved-card-details-img img {
  width: 35px;
}
.saved-card-details p {
  margin-bottom: 0;
}

.custom-card-detail-enter .sc-bczRLJ {
  width: 100% !important;
}

.custom-card-detail-enter .sc-gsnTZi {
  height: 50px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
}
.custom-card-detail-enter #cardNumber {
  width: 75% !important;
}
/* .ct_project_dtl h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
} */
.ct_project_dtl h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.ct_project_card {
  padding: 15px;
}
.ct_project_card img {
  border-radius: 10px;
  max-width: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
.ct_project_dtl p{
  margin-bottom: 0px;
}
.ct_project_dtl strong {
  font-weight: 600;
}
.ct_project_card {
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
  margin: 15px;
  border-radius: 10px;
}
@media (max-width: 365px) {
  .custom-card-detail-enter #cardNumber {
    width: 100% !important;
  }
}
.padding-r {
  padding-right: 15rem;
}
.trashCard img {
  width: 20px;
  cursor: pointer;
}

.pay_btn {
  background: var(--color-individual) !important;
  color: #fff !important;
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .indi-add-cart-block .product-label-imgLbl-tbl img {
    width: 35px;
    height: 35px;
    border-radius: 6px;
  }
  .indi-add-cart-block .product-label-imgLbl-tbl p {
    font-weight: 600;
    font-size: 9px;
    padding-top: 10px;
  }
  .indi-add-cart-block .product-label-tbl {
    font-size: 8px;
  }
  .indi-add-cart-block .ant-table-tbody > tr > td {
    padding: 4px !important;
    font-size: 9px !important;
  }
  .indi-add-cart-block thead {
    font-size: 9px !important;
    font-weight: 800 !important;
  }
  .indi-add-cart-block .ant-table-thead > tr > th {
    padding: 5px !important;
  }
  .ant-col {
    width: 100%;
    }
.gift-code-input .ant-col.ant-col-md-14.ant-col-lg-14 {
    margin-bottom: 30px;
}
.ant-row.txt-algn-cntr.cart-card-price.ct_flex_row ,.cart-card-price{
  flex-flow: row !important;
}
.ct_payment_gift_middle {
 
  grid-template-columns: repeat(1,1fr);
}
}
@media screen and (min-width: 577px) and (max-width: 992px) {
  .indi-add-cart-block .ant-table-tbody > tr > td {
    padding: 10px !important;
  }
}

.indv-privacy-sec-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.indv-privacy-sec-text p {
  margin-bottom: 0;
  text-align: justify;
}
.indv-privacy-policy-sec li {
  list-style-type: disc;
  margin-left: 18px;
  text-align: justify;
}
@media screen and (max-width: 576px) {
  .indv-privacy-policy-sec {
    margin-top: 0px !important;
  }  
}
.reset-pass-succ-modal{
    min-height: 350px !important;
}

.indv-profile-bg{
    background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.div_class{
    background-color: transparent;
}
.ant-btn-default.csncel_subcription{
    background-color: #fff !important;
    color: var(--bs-dark) !important;
}
.indv-climate-card {
  padding: 12px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.43);
  border-radius: 14px;
}

.indv-climate-card-earn {
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.43);
  border-radius: 14px;
  margin-top: 24px;
}

.indv-climate-card-img img {
  width: 100%;
  border-radius: 12px;
  height: 310px;
}

.indv-climate-card-img {
  position: relative;
}

.indv-climate-card-img-overlay {
  position: absolute;
  top: 72%;
  opacity: 0;
}

.indv-climate-card-img:hover .indv-climate-card-img-overlay {
  opacity: 1;
  width: 100%;
}

.indv-climate-card p {
  margin-bottom: 0 !important;
}

.indv-climate-card-head {
  color: #a7a7a7 !important;
  font-weight: 600;
  font-size: 14px;
}

.indv-climate-card-text {
  font-weight: 600;
  font-size: 15px;
}

.indv-climate-card-date-txt {
  font-size: 12px;
  color: #a7a7a7 !important;
}

.indv-climate-card-date {
  font-weight: 600;
  font-size: 15px;
}

.indv-climate-card-earn-head {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.indv-climate-card-earn-text {
  color: #a7a7a7 !important;
  font-size: 14px;
  margin-bottom: 0;
}

.indv-climate-card-earn-img img {
  width: 80%;
}

.indv-climate-card-img-overlay-download-btn {
  background-color: #6f7a51;
  border-radius: 50%;
  padding: 12px;
  width: 48px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.indv-climate-card-img-overlay-download-btn span i {
  color: #ffffff;
}

.indv-climate-card-img-overlay-share-btn {
  background-color: #9d9d9d;
  width: 100%;
  padding: 10px;
  border-radius: 18px;
}

.indv-climate-card-img-overlay-share-btn p,
.indv-climate-card-img-overlay-share-btn span {
  color: #ffffff;
  cursor: pointer;
}

.indv-payment-success-sec p {
  margin-bottom: 0;
  font-weight: 500;
}
.indv-payment-success-done-sec-up {
  padding-top: 50px;
}
.indv-payment-success-done-sec-up-img img {
  width: 25%;
}
.modal-text {
  padding-top: 5px;
  text-align: center;
  padding-bottom: 15px;
}
.climate-certificate-button-disable {
  opacity: 0.5;
}
.climate-certificate-close {
  position: absolute;
  top: 12px;
  right: 13px;
  border: none;
  background: none;
  outline: none;
  font-size: 20px;
}
@media (max-width: 572px) {
  .climate-certificate-close {
    right: 0px !important;
    left: 190px;
    top: 8px;
  }
}
.nomodal {
  display: none;
}
.close {
  font-size: 20px;
  cursor: pointer;
  color: black;
  padding-left: 10px;
}
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #888;
  width: 250px;
  color: red;
  padding: 0;
}

.payment-success-card-select {
  border: 3px solid #88ae47;
  border-radius: 24px;
  height: 390px;
}

.indv-payment-success-sec-head {
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-success-sec-text1,
.indv-payment-success-sec-text2 {
  font-size: 13px;
}

.indv-payment-success-done-sec-down-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-foot {
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.26);
  background-color: #fff;
}

.carbon-offset-payment-success-foot-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 45px !important;
}
.indv-payment-success-alert {
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
}

.indv-payment-success-alert p {
  margin-bottom: 0;
}

.indv-payment-success-alert-head {
  font-size: 14px;
  font-weight: 600;
}

.indv-payment-success-alert-text {
  font-size: 13px;
}

.indv-payment-success-alert-img {
  display: flex;
  justify-content: center;
}

.indv-payment-success-alert-img img {
  width: 205px;
}

.payment-success-foot .ant-affix {
  z-index: 9999 !important;
}

@media screen and (max-width: 576px) {
  .indv-payment-success-alert {
    min-width: 225px;
  }
  .indv-payment-success-block {
    margin-left: 19% !important;
  }
}
.notification-toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 20px;
  z-index: 100;
}
@media (max-width: 768px) {
  .notification-toast-container {
    width: 94%;
  }
}
.notification-toast-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
.notification-toast-close-icon {
  width: 24px;
  height: 24px;
}
.notification-toast-error-icon {
  width: 40px;
  height: 40px;
  background: #d14c44;
  border-radius: 9999px;
}
.notification-toast-button {
  background: transparent !important;
  outline: none;
  border: none;
}
.notification-toast-flex {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}
.notification-toast-image-flex {
  display: flex;
  justify-content: space-between;
}
.payment-success-foot-ns {
  justify-content: end !important;
}
.indv-payment-success-sec-text-ns {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 5px !important;
}

.landing-header {
  padding: 40px 140px;
  background: #f4f4f4;
}
.landing-header h2 {
  text-transform: uppercase;
}
.page-content {
  padding: 40px 250px;
  font-size: 18px;
  text-align: justify;
}
.read-pagination {
  text-align: center;
}
.faq-page-content {
  padding: 40px 130px;
  font-size: 18px;
}
.faqs-collapse .ant-collapse-item .ant-collapse-header {
  color: #88ae47 !important;
}
.ct_text_p_child_left p {
  text-align: start;
}
.indivi_about_us_content {
  padding: 75px 0 0 0;
}
.indivi_about_us .text_bold {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: system-ui;
}
.indivi_about_us .text_bold .normal {
  font-weight: normal;
}
.indivi_about_us img {
  width: 100%;
}
.about_mission_vision_bgc {
  background-color: #fafafa;
  /* padding: 60px 130px; */
}
.indivi_about_us_data {
  height: 110px;
  overflow: auto;
}
.mission_Vision_content {
  height: 210px;
  overflow: auto;
}
.indivi_about_us_data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.indivi_about_us_data::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.indivi_about_us_data::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.mission_Vision_content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mission_Vision_content::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.mission_Vision_content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.about-us-our-miss-img img {
  height: 250px;
}

.about-us-our-vis-img img {
  height: 285px;
}

.indivi_about_us_mission_vision .mission_vision_normal {
  font-weight: normal;
  font-size: 32px;
  text-transform: uppercase;
}
.indivi_about_us_mission_vision .mission_vision_bold {
  font-weight: 900;
  font-family: system-ui;
}

.text_bold {
  font-weight: 900;
  font-family: system-ui;
}

.page-content-redeem {
  padding: 40px 250px;
  font-size: 18px;
}
.page-content-redeem img {
  width: 100%;
}
.redeemgift_content {
  padding-top: 50px;
}
.redeemgift_content label {
  font-weight: 900;
  padding-bottom: 25px;
}
.redeemgift_content-ns label {
  padding-bottom: 5px !important;
}
.redeemgift_content h3 {
  font-size: 30px;
  color: #88ae47;
  font-weight: 600;
}
.redeemgift_content p {
  color: #9e9e9e;
  text-align: justify;
}
.rd-pd-left {
  padding-left: 50px;
}
.rd-pading-tb {
  padding: 25px 0;
}
.page-content-contact-us {
  padding: 45px 140px;
}
.contact-card-box {
  border: 2px solid #f0eeef;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.contact-card-box img {
  width: 20px;
}
.contact-card-box label {
  padding-left: 10px;
  font-size: 16px;
  line-height: 1;
  padding-left: 15px;
  display: contents;
}
.contact-card-box label p {
  font-size: 15px;
  font-weight: 600;
}
.contact-card-box a {
  color: black;
}
.contact-card-box .mail-point {
  cursor: pointer;
}
.contact-card-box a:hover {
  color: black;
}
.contact-form {
  margin-top: 40px !important;
}
.contact-form .ant-row p {
  font-weight: 500;
  color: #6d6c71;
}
.contact-form .ant-input {
  height: 50px;
  border-radius: 10px;
}
.just-cenrte {
  justify-content: center;
}
.form_textarea {
  min-height: 160px;
  max-height: 160px;
}

.about-us-mission-sec {
  margin-left: 105px;
}

.about-us-vission-sec {
  margin-left: 75px;
}
.about-us-vission-sec p {
  width: 480px;
}
.about-us-mission-sec p {
  width: 480px;
}

.about-mis-vis-border {
  border-right: 2px solid #ededed;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
  .contact-card-box label p {
    font-size: 14px;
  }
}
.about-us-sec-climate-crisis {
  padding: 75px 0 85px 0;
}

.about-us-sec-climate-crisis .head h1 {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.about-us-sec-climate-crisis .head h1 span {
  font-weight: 700;
}

.about-us-sec-climate-crisis .text p {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: #000;
}

.about-hate-climate-chng-sec {
  padding: 75px 0;
  background-color: #f5f5f5;
}

.about-hate-climate-chng-sec .head p {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-left: -5px;
}

.about-hate-climate-chng-sec .head-green {
  color: #5b851f;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.about-hate-climate-chng-sec .text {
  font-weight: 400;
  color: #333;
  font-size: 14px;
  margin: 0;
}
.about-hate-climate-chng-sec-flight {
  padding: 75px 0;
  background-color: #f5f5f5;
  margin-bottom: 0px !important;
}

.about-hate-climate-chng-sec-flight .head p {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-left: -5px;
}

.about-hate-climate-chng-sec-flight .head-green {
  color: #5b851f;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.about-hate-climate-chng-sec-flight .text {
  font-weight: 400;
  color: #333;
  font-size: 14px;
  margin: 0;
}
.about-hate-climate-chng-sec_emp {
  padding: 75px 0;
  background-color: #f5f5f5;
  margin-bottom: 0px;
}

.about-hate-climate-chng-sec_emp .head p {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-left: -5px;
}

.about-hate-climate-chng-sec_emp .head-green {
  color: #5b851f;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.about-hate-climate-chng-sec_emp .text {
  font-weight: 400;
  color: #333;
  font-size: 14px;
  margin: 0;
}

.eko-positive-sec {
  padding: 80px 0;
}

.eko-positive-sec-img-bg {
  background: url(/static/media/Mountain_windmill_Bg.478995e5.svg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 260px;
  position: relative;
}

.eko-positive-sec p {
  position: absolute;
  font-weight: 400;
  font-size: 44px;
  color: #575757;
  text-align: center;
  top: 24%;
  left: 9%;
}

.eko-positive-sec p span {
  color: #5b851f !important;
  font-weight: 600;
}

.about-us-mission-vision-sec {
  padding: 50px 0;
  background-color: #f5f5f5;
}

.about-our-mission-sec {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  margin: auto;
  grid-gap: 15px;
  gap: 15px;
}
.about-our-mission-sec .head p {
  color: #5b851f;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 0;
}
.about-our-mission-sec .border-sec {
  padding: 10px 25px;
}

.about-our-mission-sec .border-sec hr {
  border: solid 1px #000 !important;
  height: 100% !important;
  margin: 0 !important;
}

.about-our-mission-sec .text {
  display: flex;
  align-items: center;
}

.about-our-mission-sec .text p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  margin: 0;
}

.about-our-vision-sec {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: auto;
  grid-gap: 20px;
  gap: 20px;
}

.about-our-vision-sec .head p {
  color: #5b851f;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 0;
  text-align: left;
}

.about-our-vision-sec .border-sec {
  padding: 10px 25px;
}

.about-our-vision-sec .border-sec hr {
  border: solid 1px #000 !important;
  height: 100% !important;
  margin: 0 !important;
}

.about-our-vision-sec .text {
  display: flex;
  align-items: center;
}

.about-our-vision-sec .text p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-align: right;
  margin: 0;
}

.about-us-meet-team-sec {
  padding: 75px 0 0 0;
}

.about-us-meet-team-sec .head p {
  text-align: center;
  color: #5b851f !important;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 40px;
}

.about-us-meet-team-sec .img-sec {
  background-color: #ebebeb;
  height: 100%;
}

.about-us-meet-team-sec .img-sec img {
  padding: 25px;
  width: 100%;
  height: 460px;
}

.about-us-meet-team-sec .text-sec {
  background-color: #fafafa;
  height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-us-meet-team-sec .text-sec .head {
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}

.about-us-meet-team-sec .text-sec .text1 {
  font-size: 16px;
  color: #000;
  line-height: 32px;
  text-align: justify;
}

.about-us-meet-team-sec .text-sec .text2 {
  font-size: 16px;
  color: #5b851f;
  /* font-weight: 600; */
  font-style: italic;
  text-align: justify;
}

.about-us-meet-team-sec .text-sec .team_p-t {
  font-size: 16px;
  color: #5b851f;
  /* font-weight: 600; */
  text-align: justify;
  font-style: italic;
  padding-top: 40px;
}

.about-us-meet-team-sec-box {
  box-shadow: 0px 3px 8px 1px rgba(186, 186, 186, 1);
}

.about-us-sec-climate-crisis-img img {
  width: 100%;
}

.about-hate-climate-chng-sec-img img {
  width: 100%;
}

/* .about-hate-climate-chng-text-sec{
  display: flex;
} */

.about-bottom-img-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/cing3o7a0t6w0tt0abcnentc25yo/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22PlasticBag%20Man_AboutUs.jpg%22%3B%20filename%2A%3DUTF-8%27%27PlasticBag%2520Man_AboutUs.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 245px;
}

.about-bottom-img-sec2 {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/vn9pkryil5v095hb1s3xl1l3av59/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22OceanTurtle_AboutUs.jpg%22%3B%20filename%2A%3DUTF-8%27%27OceanTurtle_AboutUs.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 245px;
}

.about-us-abstract-sec {
  margin: 75px 0;
}

.about-bottom-img-sec p,
.about-bottom-img-sec2 p {
  margin-bottom: 0;
  color: #ffffff;
}

.about-bottom-img-sec-overlay,
.about-bottom-img-sec2-overlay {
  background-color: #00000061;
  padding-left: 50px;
  min-height: 400px;
  padding-top: 10rem;
}

.about-bottom-img-sec-btn button {
  background-color: #ffffff;
  color: #87ac44;
  border: none;
  height: 36px;
  padding: 0px 25px;
  font-weight: 500;
}

.about-bottom-img-sec-overlay .head,
.about-bottom-img-sec2 .head {
  font-weight: 600;
  font-size: 30px;
}
.about-bottom-img-sec-overlay .text,
.about-bottom-img-sec2 .text {
  font-weight: 400;
  font-size: 14px;
  padding: 12px 0;
}
.about-bottom-img-sec-social {
  display: flex;
  align-items: center;
}
.about-bottom-img-sec-social-linked img {
  width: 27px;
  margin-left: 12px;
}
@media only screen and (max-width: 1400px) {
  .about-our-mission-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0;
  }
  .about-our-vision-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: right;
    margin: 0;
  }
  .about-our-vision-sec .border-sec {
    padding: 10px 25px;
    /* margin-left: 55px; */
  }
  .about-our-mission-sec .border-sec {
    padding: 10px 25px;
    /* margin-right: 55px; */
  }
  .eko-positive-sec p {
    color: #575757;
  }
  .about-us-meet-team-sec .text-sec .text1 {
    font-size: 16px;
  }
  .about-us-meet-team-sec .text-sec .team_p-t {
    font-size: 16px;
    margin-bottom: 0;
  }
  .about-us-meet-team-sec .text-sec .text2 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .about-hate-climate-chng-sec .text {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .about-us-sec-climate-crisis .head h1 {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
  .about-hate-climate-chng-sec .head p {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  .about-hate-climate-chng-sec .head-green {
    color: #5b851f;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .eko-positive-sec-img-bg {
    min-height: 92px;
  }
  .eko-positive-sec p {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    color: #575757;
    text-align: center;
    top: 6%;
    left: 0%;
  }
  .border-sec {
    display: none;
  }
  .about-our-mission-sec {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
  }
  .about-our-mission-sec .head p {
    color: #5b851f;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0;
  }
  .about-our-vision-sec {
    display: flex;
    width: 100%;
    margin: auto;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column-reverse;
  }
  .about-our-vision-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: left;
    margin: 0;
  }
  .about-our-vision-sec .head p {
    color: #5b851f;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0;
    text-align: left;
  }

  .about-full-width {
    min-width: 100%;
    margin-bottom: 20px;
  }
  .head-spacing {
    margin-top: 20px;
  }
  .about-hate-climate-chng-sec-img img {
    width: 100%;
    margin-bottom: 20px;
  }
  .about-bottom-img-sec-overlay,
  .about-bottom-img-sec2-overlay {
    padding-left: 30px;
    padding-top: 5rem;
    min-height: 335px;
  }
  .about-us-sec-climate-crisis {
    padding: 45px 0 45px 0;
  }
  .about-hate-climate-chng-sec {
    padding: 50px 0;
  }
  .eko-positive-sec {
    padding: 50px 0;
  }
  .about-us-meet-team-sec {
    padding: 50px 0 0 0;
  }
  .page-content {
    padding: 40px 35px;
  }
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .about-hate-climate-chng-text-second-desc {
    margin-top: 103px !important;
  }
  .about-hate-climate-chng-text-second-flight {
    margin-bottom: 0px !important;
  }

  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
  .contact-card-box label p {
    font-size: 14px;
  }
  .faq-page-content {
    padding: 25px 25px;
  }
  .landing-header {
    padding: 30px 60px;
    text-align: center;
  }
  .page-content-redeem {
    padding: 10px 25px;
    font-size: 16px;
  }
  .rd-pd-left {
    padding-left: 10px;
  }
  .redeemgift_content {
    padding-top: 10px;
  }
  .redeemgift_content h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 576px) {
  .about-us-sec-climate-crisis .head h1 {
    margin-bottom: 16px;
    text-align: start !important;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
  .about-us-sec-climate-crisis-img img {
    height: 129px;
    border-radius: 8px;
  }
  .about-us-sec-climate-crisis-img {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .about-us-sec-climate-crisis .text {
    margin-top: 0px !important;
  }
  .about-us-sec-climate-crisis .text p {
    text-align: start !important;
    color: #808080 !important;
    margin-bottom: 32px;
    font-size: 14px !important;
    line-height: 21px;
  }
  .about-us-climate-change-sec {
    display: block !important;
  }
  .about-hate-climate-chng-sec-img img {
    height: 129px;
    position: relative;
    top: 142px;
    border-radius: 8px;
  }
  .about-us-sec-climate-crisis {
    padding: 24px 0 0 0 !important;
  }
  .about-hate-climate-chng-sec {
    margin-bottom: 20px !important;
    padding: 0px !important;
    background-color: inherit !important;
  }
  .about-hate-climate-chng-text-sec {
    margin-top: -157px;
  }
  .about-hate-climate-chng-sec .head p {
    text-align: start !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    margin-bottom: 8px !important;
    line-height: 150%;
  }
  .about-hate-climate-chng-sec .head-green {
    color: #000000 !important;
    font-weight: 700 Im !important;
    margin-bottom: 16px !important;
    text-transform: uppercase;
  }
  .about-hate-climate-chng-sec .head-green-text {
    margin-bottom: 5px;
  }
  .about-hate-climate-chng-sec .text {
    color: #808080 !important;
    line-height: 21px;
    font-weight: 400 !important;
    margin-top: 157px !important;
  }
  .about-hate-climate-chng-second .pText {
    color: #808080 !important;
    line-height: 21px;
    font-weight: 400 !important;
  }
  .eko-positive-sec-img-bg {
    background-size: contain !important;
  }
  .eko-positive-sec {
    padding: 50px 0;
    border: 1px solid #dfdfdf;
    margin: 32px 20px;
    border-radius: 8px;
    padding: 33px 0 24px !important;
  }
  .eko-positive-sec p {
    top: 0% !important;
  }
  .about-us-mission-vision-sec {
    background-color: inherit !important;
    padding: 0px !important;
  }
  .about-our-mission-sec {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 12px 16px 15px;
    grid-gap: 9px !important;
    gap: 9px !important;
  }
  .about-our-vision-sec {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 12px 16px 15px;
    grid-gap: 9px !important;
    gap: 9px !important;
    margin-top: 16px !important;
  }
  .about-our-mission-sec .head p,
  .about-our-vision-sec .head p {
    color: #9dbd4f !important;
  }
  .about-our-vision-sec .text p {
    color: #808080 !important;
    font-size: 16px !important;
  }
  .about-our-mission-sec .text p {
    color: #808080 !important;
    font-size: 16px !important;
  }
  .head-spacing {
    margin-top: 0px !important;
  }
  .about-us-sec-header-text {
    display: flex;
    flex-direction: column;
  }
  .about-us-sec-climate-crisis .head h1 span {
    line-height: 150%;
    color: #000000;
    margin-bottom: 8px;
  }
  .image-heder-sec-active {
    border: #88ae47;
    border-radius: 15px;
  }
  .image-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 7px;
  }
  .image-header-sec img {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 200px !important;
    width: 160px;
  }
  .image-header-sec {
    position: relative;
    width: 138px;
    height: 135px;
    overflow: hidden;
    border-radius: 20px;
  }
  .image-header-sec-active {
    position: relative;
    width: 138px;
    height: 135px;
    overflow: hidden;
    border-radius: 20px;
    background-image: contain;
    border: 3px solid #88ae47;
  }
  .image-header-sec-active img {
    position: relative;
    width: 156px;
    height: 200px !important;
    bottom: 33px;
    right: 0px;
  }
  p.image-header-sec-head {
    color: #88ae47 !important;
  }
  .about-us-meet-team-sec-box {
    box-shadow: none !important;
  }
  .about-us-meet-team-sec .text-sec {
    background-color: inherit !important;
    position: relative;
    padding: 0;
  }
  .about-us-meet-team-sec .text-sec .team_p-t,
  .about-us-meet-team-sec .text-sec .text2 {
    color: #88ae47;
    padding-top: 0;
    font-style: inherit;
  }

  .about-us-meet-team-sec .text-sec .text-sec-text1 {
    display: grid;
    grid-gap: 20px;
    line-height: 27px;
    margin-top: 322px !important;
  }
  .about-us-meet-team-sec .text-sec .text-sec-text2 {
    display: grid;
    grid-gap: 20px;
    line-height: 27px;
    margin-top: 347px !important;
  }
  .about-us-sec-text {
    background-color: #d9d9d9;
    padding: 13px 18px;
    border-radius: 8px;
    position: absolute !important;
  }
  .about-us-sec-polygon1 {
    position: absolute;
    top: -18px;
    left: 32px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #d9d9d9;
  }
  .about-us-sec-polygon2 {
    position: absolute;
    top: -18px;
    right: 74px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #d9d9d9;
  }
  .about-us-meet-team-sec .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .about-us-meet-team-sec {
    padding: 32px 0 0 0;
  }
  .about-us-meet-team-sec .head p {
    color: #000000 !important;
    font-weight: 700;
    font-size: 23px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 22px;
  }
  .about-us-abstract-sec {
    margin: 21px 0 !important;
  }
  .about-bottom-img-sec-overlay .head,
  .about-bottom-img-sec2 .head {
    font-size: 16px;
  }
  .about-bottom-img-sec-overlay .text,
  .about-bottom-img-sec2 .text {
    font-size: 12px;
  }
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
  .contact-card-box label p {
    font-size: 14px;
  }
  .faq-page-content {
    padding: 25px 25px;
  }
  .landing-header {
    padding: 30px 60px;
    text-align: center;
  }
  .page-content-redeem {
    padding: 10px 25px;
    font-size: 16px;
  }
  .rd-pd-left {
    padding-left: 10px;
  }
  .redeemgift_content {
    padding-top: 10px;
  }
  .redeemgift_content h3 {
    font-size: 21px;
  }
  .rd-pading-tb {
    padding: 25px 0 0 0;
  }
  .redeemgift_content {
    padding-top: 0px;
  }
  .redeemgift_content label {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 14px;
  }
  .landing-header {
    padding: 17px 45px;
  }
  .page-content-redeem-sec {
    padding-right: 8px !important;
  }
  .redeemgift-content-block {
    display: flex;
    grid-gap: 17px;
    gap: 17px;
  }
  .redeemgift-content-block h3 {
    width: 70%;
  }
  .about-us-climate-main-sec {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
@media screen and (min-width: 577px) {
  .about-us-sec-climate-crisis .text p,
  .about-hate-climate-chng-sec .text,
  .about-hate-climate-chng-sec .head p,
  .about-our-mission-sec .head p,
  .about-our-vision-sec .head p {
    display: flex;
    flex-direction: column;
  }
  .redeemgift-content-section {
    display: flex;
    flex-direction: row;
  }
  .redeemgift_content h3 {
    display: flex;
    flex-direction: column;
  }
  .redeemgift_content label {
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 25px;
  }
  .redeemgift-content-block {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
  }
  .redeemgift_content-step2 label {
    width: 30%;
  }
  .redeemgift_content-step2-ns label {
    width: 100% !important;
  }
  .redeemgift-content-block h3 {
    margin-top: 3px;
  }
  .redeemgift-content-block-ns {
    flex-direction: column !important;
    grid-gap: 2px !important;
    gap: 2px !important;
  }
}

.individual-tour-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.indv-tour-begin-sec {
  z-index: 99;
}

.tour-modal-close-btn {
  padding: 0 !important;
  background: none !important;
  width: unset !important;
}

.individual-tour-container .individual-tour-bg-image {
  width: 100%;
  /* height: 100vh; */
}

.text-center {
  text-align: center;
}

.individual-tour-sub {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
}

.individual-tour-modal {
  max-width: 100%;
  padding: 20px 50px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #ffff;
  width: 425px;
  /* height: 225px; */
}

.individual-tour-arrow:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.individual-tour1 {
  top: 163px;
  left: 74%;
}

.individual-tour1:before {
  left: -15px;
  bottom: 45%;
  transform: rotate(270deg);
}

.individual-tour2 {
  top: 170px;
  left: 60%;
}

.individual-tour2:before,
.individual-tour3:before,
.individual-tour4:before,
.individual-tour7:before {
  right: 50%;
  bottom: -10px;
  transform: rotate(180deg);
}

.individual-tour8:before {
  right: -3%;
  bottom: 95px;
  transform: rotate(90deg);
}

.individual-tour3 {
  top: 180px;
  left: 33%;
}

.individual-tour4 {
  left: 60%;
  top: 48%;
}

.individual-tour5 {
  top: 54%;
  left: 40%;
}

.individual-tour5:before {
  left: 50%;
  top: -8px;
  transform: rotate(0deg);
}

.individual-tour6:before,
.individual-tour9:before,
.individual-tourend:before {
  content: unset;
}

.individual-tour7 {
  top: 33%;
  left: 40%;
}

.individual-tour8 {
  top: 105%;
  right: 42%;
  left: unset;
}

.individual-tour10 {
  top: 24%;
  left: unset;
  right: -10%;
}

.individual-tour10:before {
  left: 59%;
  top: -8px;
  transform: rotate(0deg);
}

.individual-tour-modal-header {
  position: relative;
  font-size: 18px;
  font-family: "Roboto-Bold";
}

.individual-tour-modal-cross {
  position: absolute;
  top: 0;
  right: -10px;
  background: none;
  border: none;
  box-shadow: none;
}

.individual-tour-modal-body {
  font-weight: 600;
  color: #ababab;
  font-size: 15px;
  padding: 15px 0;
}

.individual-tour-green-btn,
.individual-tour-light-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.tour-width-btn {
  width: 100% !important;
  padding: 10px;
}

.individual-tour-green-btn {
  background-color: #89aa51;
  width: 100px;
}

.individual-tour-light-btn {
  color: rgb(14, 14, 14);

  width: 100px;
}

.individual-tour-modal-footer {
  margin-top: 10px;
}

.infotext {
  color: #ababab;
  font-size: 16px;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .individual-tour-modal {
    width: 370px;
    padding: 10px 30px;
  }
  .individual-tour1 {
    top: 120px;
    left: 80%;
  }
  .individual-tour2 {
    top: 98px;
    left: 60%;
  }
  .individual-tour3 {
    top: 100px;
  }
  .individual-tour4 {
    top: 28%;
  }
  .individual-tour5 {
    top: 40%;
  }
  .individual-tour7 {
    top: 18%;
  }
  .individual-tour8 {
    top: 73%;
    right: 40%;
  }
  .individual-tour10:before {
    left: 80%;
  }
  .individual-tour10 {
    top: 19%;
    right: -9%;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .individual-tour1 {
    left: 76%;
  }
  .individual-tour2 {
    top: 130px;
  }
  .individual-tour3 {
    top: 150px;
    left: 35%;
  }
  .individual-tour4 {
    top: 47%;
  }
  .individual-tour5 {
    top: 56%;
    left: 39%;
  }
  .individual-tour7 {
    top: 32%;
  }
  .individual-tour8 {
    top: 112%;
  }
  .individual-tour10:before {
    left: 80%;
  }
  .individual-tour10 {
    top: 25%;
    right: -6%;
  }
}

.business-tour-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.indv-tour-begin-sec {
  z-index: 99;
}

.tour-modal-close-btn {
  padding: 0 !important;
  background: none !important;
  width: unset !important;
}

.business-tour-container .business-tour-bg-image {
  width: 100%;
}

.text-center {
  text-align: center;
}

.business-tour-sub {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
}

.business-tour-modal {
  max-width: 100%;
  padding: 20px 40px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #ffff;
  width: 380px;
}

.business-tour-arrow:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.business-tour1 {
  top: 250px;
  left: 15%;
}

.business-tour1:before {
  left: 60px;
  bottom: 100%;
  transform: rotate(360deg);
}

.business-tour2 {
  top: 170px;
  left: 74%;
}

.business-tour2:before {
  right: 99%;
  bottom: 95px;
  transform: rotate(270deg);
}

.business-tour8:before {
  right: -3%;
  bottom: 95px;
  transform: rotate(90deg);
}

.business-tour3 {
  top: 170px;
  left: 74%;
}
.business-tour3:before {
  right: 50%;
  bottom: 202px;
  transform: rotate(0deg);
}

.business-tour4 {
  left: 32%;
  top: 22%;
}
.business-tour4:before {
  right: 50%;
  bottom: -10px;
  transform: rotate(180deg);
}
.business-tour5 {
  top: 37%;
  left: 58%;
}

.business-tour5:before {
  left: 50%;
  top: 100%;
  transform: rotate(180deg);
}
.business-tour6 {
  top: 55%;
  left: 45%;
}

.business-tour6:before {
  left: 50%;
  top: -4%;
  transform: rotate(0deg);
}

.business-tour9:before,
.business-tourend:before {
  content: unset;
}

.business-tour7 {
  top: 70%;
  left: 53%;
}
.business-tour7:before {
  z-index: 0;
}

.business-tour8 {
  top: 105%;
  right: 45%;
  left: unset;
}

.business-tour10 {
  top: 24%;
  left: unset;
  right: -7%;
}

.business-tour10:before {
  left: 59%;
  top: -8px;
  transform: rotate(0deg);
}

.business-tour-modal-header {
  position: relative;
  font-size: 18px;
  font-family: "Roboto-Bold";
}

.business-tour-modal-cross {
  position: absolute;
  top: 0;
  right: -10px;
  background: none;
  border: none;
  box-shadow: none;
}

.business-tour-modal-body {
  font-weight: 600;
  color: #ababab;
  font-size: 15px;
  padding: 15px 0;
}

.business-tour-green-btn,
.business-tour-light-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.tour-width-btn {
  width: 100% !important;
  padding: 10px;
}

.business-tour-green-btn {
  background-color: #89aa51;
  width: 100px;
}

.business-tour-light-btn {
  color: rgb(14, 14, 14);

  width: 100px;
}

.business-tour-modal-footer {
  margin-top: 10px;
}

.infotext {
  color: #ababab;
  font-size: 16px;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .business-tour1 {
    left: 17%;
  }
  .business-tour2 {
    top: 162px;
  }
  .business-tour3 {
    top: 112%;
    left: 63%;
  }
  .business-tour4 {
    top: 20%;
    left: 33%;
  }
  .business-tour5 {
    top: 37%;
    left: 39%;
  }
  .business-tour6 {
    top: 57%;
    left: 45%;
  }
  .business-tour7 {
    top: 72%;
  }
  .business-tour8 {
    top: 105%;
    left: 29%;
  }
  .business-tour10 {
    top: 25%;
    right: -4%;
  }
  .business-tour10:before {
    left: 80%;
  }
}

.buss-offset-card {
  max-width: unset !important;
  min-width: unset !important;
}
.ct_color_change.ct_padd_top_0 {
  padding-top: 0px !important;
}
.ct_color_change.ct_padd_inline_0 {
  padding-inline: 0px !important;
}
.business-offset-flight-top-right {
  margin-bottom: 0;
}
.business-offset-flight-top-right li {
  display: block;
}
.business-offset-flight-top-right li a {
  color: #aaaaaa;
}

.business-offset-flight-top-right li:last-child {
  margin-left: 36px;
}

.flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-img-card {
  background-color: #ffffff;
}

.flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.bussiness-offset-flight-bydis-removebtn {
  margin-top: 56px;
}
.bussiness-offset-flight-35-removebtn {
  margin-top: 35px;
}

.busoness-offset-flight-bydis-label-marginbottm {
  margin-bottom: 21px !important;
}
.margin-top30 {
  margin-top: 30px !important;
}
.margin-top15 {
  margin-top: 15px !important;
}
.margin-btm15 {
  margin-bottom: 15px !important;
}
.offset-input-custm .ant-input-lg {
  height: 50px !important;
}
.webGreenColor {
  color: #88ae47;
  padding-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
}
.m-top-1 {
  margin-top: 1rem;
}

.business-offset-flight-tab a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.buss-summary-form-sec {
  border-top: 2px solid #e8e8e8;
}

.buss-sum-head-txt p {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}

.buss-sum-head-txt2 p {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0 !important;
  /* word-wrap: break-word; */
}

.buss-sum-head-txt-p p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000000;
}

.bus-offset-flight-sum-img-line {
  border: solid #dee2e6 1px;
  position: relative;
  width: 100%;
}

.bus-offset-flight-sum-img img {
  width: 36px;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 36px;
}

@media (max-width: 768px) {
  .car-one-time-offset-btn-center {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .subscription-emp-btn-center {
    display: flex;
    justify-content: center;
  }
  .emp-one-time-desc-center .ant-row {
    display: flex;
    justify-content: center !important;
  }

  .emp-one-time-desc-center .ant-col-lg-21 {
    max-width: 92.5% !important;
  }
  .flight-text-center .ant-row {
    display: flex;
    justify-content: center !important;
  }
  .one-time-car-center .ant-row {
    display: flex;
    justify-content: center !important;
  }
  .flight-text-center .ant-col-lg-21 {
    max-width: 92.5% !important;
  }
  .one-time-car-center .ant-col-lg-21 {
    max-width: 92.5% !important;
  }
  .one-time-event-center .ant-row {
    display: flex;
    justify-content: center !important;
  }
  .one-time-event-center .ant-col-lg-21 {
    max-width: 92.5% !important;
  }
}
.disabed-click {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.buss-plant-sum-btm-txt-head p {
  color: #aaaaaa;
  font-weight: 500;
}

.buss-plant-sum-btm-txt-para ul li {
  color: #aaaaaa;
  font-weight: 500;
  list-style: none;
}
.add_new_card {
  color: #88ae47 !important;
}
.disp_content {
  display: contents;
}
.padding-top-Left {
  padding: 1rem 1.5rem;
}
.text_cap_lock {
  text-transform: uppercase;
}
.text_capital {
  text-transform: capitalize;
}
.carbon-offset-bg-img {
  margin-top: -40px;
}
.carbon-offset-bg-img-other-transport {
  margin-top: 0px;
}
.offset_tab_m-t {
  margin-top: 20px;
}
.text_error {
  color: red !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin-bottom: -10px !important;
}
.business-order-summary-or {
  display: flex;
  justify-content: center;
  align-items: center;
}
.business-order-summary-or-txt {
  margin-top: 37px;
}
.event-related-summary {
  justify-content: space-between;
  margin-left: 0px !important;
}
.business-offset-cards {
  margin-top: -40px !important;
  margin-bottom: 50px !important;
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .business-offset-flight-top-right {
    font-size: 12px;
  }
}
/* mobile and tablet responsive */
@media screen and (min-width: 360px) and (max-width: 768px) {
  .business-cart-price {
    justify-content: space-between;
  }
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .business-order-summary-or-txt {
    margin-top: 0px !important;
  }
  .offset-header-cards {
    display: block !important;
    flex-direction: column;
  }
  .inactive-bg-onetime-card p {
    padding-bottom: 7px !important;
  }
  .active-bg-grey-onetime-card {
    margin-bottom: 7px !important;
  }

  .input-units-mobile-ns {
    width: 65%;
  }

  .co2e-mobile-ns {
    margin-left: auto !important;
    margin-right: 24px !important;
  }

  .co2e-flight-distance-mobile-ns {
    width: 33%;
    margin-left: auto !important;
    margin-right: 0 !important;
    margin-bottom: 32px !important;
  }

  .flight-time-input-mobile-ns {
    width: 80%;
  }

  .flight-distance-input-mobile-ns {
    width: 80%;
  }

  .ant-form-item-control-input {
    width: 100%;
  }

  .return-flight-mobile-ns {
    width: 40%;
  }

  .power-src-mobile-ns {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .co2e-desktop-ns {
    padding-left: 2vw !important;
  }
}

.offset_details h1 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
.offset_details_flight {
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  padding-bottom: 10px;
}
.offset_details_header {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  padding-left: 15px;
  padding-top: 60px;
  line-height: 1.2;
}
.offset_details_head p {
  margin: 0;
  font-size: 22px;
}
.offset_down_heade_back {
  padding: 22px 12px;
  background-image: linear-gradient(
      rgb(201 201 201 / 50%),
      rgba(201 201 201 / 50%)
    ),
    url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/fk4eqwc85uqy5e13gfna6q7tu9tx/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22greenfield.jpg%22%3B%20filename%2A%3DUTF-8%27%27greenfield.jpg&response-content-type=image%2Fjpeg");
  background-size: cover !important;
}
.offset_down_heade_back_padding_top {
  padding-top: 70px;
  margin-top: 0;
}
.offset_down_heade {
  justify-content: center;
  display: flex;
}
.offset_down_heade_ns {
  top: 45%;
}
.offset_down_heade h1 {
  color: #000000;
  text-align: initial;
  font-size: 27px;
  line-height: 35px;
  font-weight: 600;
}
.offset_down_heade p {
  color: #000000;
  text-align: initial;
}
.Tool_user {
  background-color: #fff;
}
.Tool_user_section {
  display: flex;
}
.tool_user_content_number {
  margin-left: 24px;
}
.tool_user_contents {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 9px;
}

@media screen and (min-width: 360px) and (max-width: 576px) {
  .tool_user_contents {
    text-align: justify;
  }
  .ct_act_now_padd_left .our-approach-main-sec-head_act{
    left: 0px;
    padding-left: 10px;
  }
  .ct_font_22_dash .our-approach-main-sec-head.offset-emmision-new-h1
  _header.our-approach-main-sec-head-mobile span {
    font-size: 22px;
}
.about-hate-climate-chng-text-second.Tool_user_section .ct_grid_2 {
  display: grid !important;
  grid-template-columns: auto auto;
  align-items: self-start;
}
}
.Tool_user_number {
  background: -webkit-linear-gradient(#88ae47, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px !important;
  font-weight: bolder !important;
  padding: 0;
}
.down_Li {
  margin: 0px 0px 18px 36px;
  list-style-image: url(/static/media/emission_ul.1f0b9e4b.svg) !important;
}

.down_Li::marker {
  margin-top: 8px;
}

.offset-indv-car-img-sec {
  max-height: 455px;
  min-height: 455px;
}

.offset-indv-flight-img-sec {
  max-height: 385px;
  min-height: 385px;
}

.emissionsonflight-text2 {
  padding: 12px 36px;
  padding-top: 0px;
}

.emissionsonflight-text2 p {
  font-weight: 410;
  font-size: 15px;
  text-align: justify;
}

.emissionsonflight-text2 p a {
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 800;
}
.a_tag {
  -webkit-text-decoration: inline !important;
          text-decoration: inline !important;
  margin: 0 6px 0 0;
  font-weight: 400;
}
.flight_upper_headding {
  position: absolute;
}
.flight_down_headding {
  position: absolute;
  height: 103px;
  right: 0px;
  bottom: 0%  !important;
}
.seprate_text {
  text-align: justify;
  font-weight: 400;
  font-size: 15px;
}

.seprate_text span {
  position: relative;
  top: -5px;
  left: 8px;
}

.offset-emmision-new-p p {
  font-size: 23px !important;
  font-weight: 400;
}

.reducing-tips {
  font-size: 25px;
  padding-left: 5px;
}

.offset-emmision-new-h1 h1 {
  font-size: 26px !important;
}

.offset-emmision-new-h1 h1 span {
  font-size: 26px !important;
  font-weight: 600 !important;
}
.subscription_btn {
  display: flex;
  justify-content: center;
}
.flight_footprint_content {
  margin-bottom: 15px;
}
/* mobile responsive */
@media screen and (min-width: 360px) and (max-width: 576px) {
  .offset_down_heade h1 {
    font-size: 22px;
  }
  .flight-upper-tips-sec {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-left: -20px;
  }
  .ct_formate_margin_padding p.our-approach-sec-desc.text-align-lifestyle-ns.ct_accordian_tab {
    margin-left: 25px;
    margin-right: 0px;
}
.ct_formate_margin_padding p.Tool_user_number {
  margin-bottom: 0px !important;
} 
.ct_font_22_dash{
  padding-bottom: 50px;
}
.ct_font_22_dash .flight_down_headding{
  bottom: auto !important;
}
}
.flight-upper-tips-sec-text-web-ns {
  padding-left: 10%;
}
@media screen and (max-width:767px){
  .ct_padding_btm_50{
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 577px) {
  .flight-upper-tips-sec {
    display: flex;
    margin-top: 35px;
    margin-bottom: 35px;
    margin-left: -20px;
    grid-column-gap: 10%;
    -moz-column-gap: 10%;
         column-gap: 10%;
  }
}

.Indivdual_Project_MiddleContainer {
  display: flex;
  flex-direction: column;
  padding: 29px;
}

.Individual_Project_MidScreen_Main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.Individual_Project_first_block {
  height: 212px;
  background-color: var(--color-white);
  /* margin: 9px 10px; */
  border-radius: 22px;
}

.first_Project_block1 {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 212px;
  /* background-color: var(--color-individual); */
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  border-radius: 28px;
}
.first_Project_block1_Main {
  margin: 37px 17px;
}

.first_Project_block_head {
  font-size: 22px;
  font-family: "Roboto-Bold";
}

.first_Project_block_P {
  letter-spacing: 1px;
  color: #969696;
  word-spacing: 2px;
}

.individual_first_project_square {
  background-color: var(--color-individual-secondsquare);
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.indv_project_squareT {
  color: var(--color-individual-secondsquareT);
  font-size: 16px !important;
}

.individual_first_projectP {
  display: flex;
}

.first_block_projectT {
  color: var(--color-individual-project-black);
}

.T_M_Project_box {
  padding: 0px 0px;
  margin: 0px;
  border: none;
}

.T_M_Project_box_inner {
  width: 36%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.Carousel_Project_Main {
  position: relative;
  height: 480px;
  /* margin-top: 52px; */
}

.order-history-myproject-height {
  height: auto !important;
}
.back-myproject {
  top: 32px !important;
  left: 91% !important;
}

.individual_project_r_energy {
  margin-top: 90px;
}

.Renawable_slider_back {
  top: 24% !important;
}

.Renawable_slider_forward {
  top: 24% !important;
}
.Renewable_energyP {
  font-size: 13px;
  margin: 29px 0px;
  font-family: "Roboto-Bold";
  color: var(--color-individual-i-b-amount);
}

.renewable_energy_inner1 {
  height: 43%;
}

.renewable_energy_image {
  height: 81%;
}

.renewable_energy_btn {
  background-color: var(--color-individual);
  border: none;
  color: var(--color-white);
}

.forestry_slider_back {
  top: 39% !important;
}

.forestry_slider_forward {
  top: 39% !important;
}

.individual_project_forestry {
  margin-top: 150px;
}

.energy_efficiency_slider_back {
  top: 52% !important;
}
.energy_efficiency_slider_forward {
  top: 52% !important;
}

.individual_project_Energy_Efficiency {
  margin-top: 210px;
}

.IndividualInvestIn_Climate {
  margin-top: 30px;
  width: 100%;
}

.IndividualInvestIn_Climate_block1 {
  height: 220px;
}

.IndividualInvestIn_Climate_block1_Main {
  margin: 37px 17px;
  width: 50%;
}

.IndividualInvestIn_ClimateP {
  width: 100%;
  font-size: 15px;
}

.InvestClimateS a {
  color: var(--color-individual);
  font-family: "Roboto-Bold";
  padding: 0 4px;
}
.InvestClimateS a:hover {
  color: var(--color-individual);
}
.invest_climate_project_img {
  height: 100%;
  border-radius: 52px;
}
.invest_climate_project_img_main {
  width: 100%;
  height: 100%;
}
.climate_images {
  width: 100%;
  height: 100%;
}
.carousel-climate-project .rec-arrow:disabled {
  cursor: not-allowed;
  display: none;
}
.carsousal-items {
  justify-content: center;
  align-items: center;
  display: flex;
}
.carousel-climate-project_width {
  /* width: 92% !important; */
}
.carousel-climate-project .rec-arrow {
  background: none !important;
  border: 0;
  box-shadow: none !important;
  font-size: 17px;
  color: #333 !important;
}
.image_height_div {
  height: 185px;
}
.climate_project {
  display: flex;
  align-items: center;
}
.climate_project .Climate17Goals {
  width: 60rem;
  margin: 0;
  margin-right: auto;
  margin-left: auto;
}
.yt_slider_back_climate {
  position: unset !important;
  width: 65px;
  height: 45px;
  background: none;
}
.yt_slider_forward_climate {
  position: unset !important;
  width: 65px;
  height: 45px;
  background: none;
}
.project_crsl_slider_back_climate {
  position: unset !important;
  width: 50px;
  height: 35px;
  background: none;
}
.project_crsl_slider_forward_climate {
  position: unset !important;
  width: 50px;
  height: 35px;
  background: none;
}
.climate_upper_test {
  font-size: 18px;
  line-height: 30px;
  margin: 30px 0px;
}
.climate_upper_test span {
  color: #88ae47 !important;
}
.caurausal_main {
  width: 100%;
  background: #fafafa;
  padding-bottom: 28px;
}
.standard_p {
  letter-spacing: 1.2px;
  padding: 25px 0px 20px 0px;
  font-size: 18px;
}
.standard_h4 {
  margin-bottom: 32px;
  font-size: 29px;
}
.climate_images_icon {
  width: 95%;
  padding: 12px;
}
.climate_images_2 {
  height: 100%;
  width: 100%;
}
.guIbWC {
  height: 100%;
}
.rec-item-wrapper {
  height: 100%;
  /* width: auto !important; */
}
@media screen and (min-width: 577px) {

  .Carousel_Main_NS .rec-item-wrapper {
    height: 100%;
    width: auto !important;
  }
}
.slideImage {
  max-height: 310px;
  width: auto;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  width: 250px !important;
}

/* Mobile responsive */
@media screen and (min-width: 360px) and (max-width: 576px) {
  .first_Project_block_head {
    font-size: 15px;
  }
  .IndividualInvestIn_Climate_block1_Main {
    margin: 20px 15px;
    width: 100%;
  }
  .first_Project_block_P {
    font-size: 12px;
  }
  .first_Project_block1_Main {
    margin: 15px 10px;
  }
  .investin-climate-sec {
    width: auto;
    flex-direction: column;
    padding-right: 5px;
  }
  .investin-climate-sec-main {
    width: -moz-fit-content;
    width: fit-content;
    margin: 15px 10px 0px 15px;
    height: 50%;
  }
  .investin-climate-sec-img-main {
    height: 43%;
  }
  .investin-climate-sec-img {
    border-radius: 20px;
  }
  .climate-project-standards-text {
    font-size: 25px;
  }
}
@media screen and (min-width: 360px) {
  .climate_project .Climate17Goals {
    width: 100%;
  }
}
/* Tablet responsive */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .first_Project_block_head {
    font-size: 16px;
  }
  .IndividualInvestIn_Climate_block1_Main {
    margin: 6px 7px;
    width: 50%;
  }
}

.Bussiness_project_distribution {
  /* width: 50%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  border-radius: 12px;
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.one_more {
  display: block;
}
.dashboard-popup-container-ns {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 20px;
  position: absolute;
  padding: 10px 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 90%;
  top: 70%;
  /* left: 50%; */
  z-index: 1;
}

.ct_offset_minus_top.offset-header-cards{
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -30px !important;
    margin-bottom: 40px !important;
    text-align: center;
}
@media (max-width: 1200px) {
  .dashboard-popup-container-ns {
    width: 95%;
    top: 70%;
    left: 2%;
  }
}
.dashboard-popup-sub-container-ns {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.dashboard-popup-success-icon-ns {
  width: 30px;
  height: 30px;
  background: #209628;
  border-radius: 9999px;
}
.dashboard-popup-text-right-ns {
  padding-right: 5px;
}
.dashboard-popup-link-ns {
  color: #07bc0c;
}
.dashboard-popup-text-left-ns {
  padding-left: 5px;
}
.dashboard-popup-text-ns {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
@media (max-width: 768px) {
  .dashboard-popup-text-ns {
    font-size: 11px;
  }
}

.Bussiness_project_distribution_h .g2-tooltip-value {
  float: left;
}
.T_M_Screen_Container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  position: relative;
}
.orderHistoryFlex-ns {
  display: flex;
  align-items: baseline;
  grid-gap: 36px;
  gap: 36px;
}
.dropDownBorder {
  width: 100px;
  height: 35.13px;
  border: 0.88px solid #6e8e16;
  border-radius: 4.43px;
  cursor: pointer;
}
.selectedDropdowniconContainer-ns {
  display: flex;
  align-items: center;
  padding-top: 3px;
  justify-content: space-around;
}
.dropDownContainerForGraph {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
}
.dropDownIcon {
  transform: rotate(-180deg);
}
.dropDownBorderForGraph {
  width: 100px;
  height: 30.13px;
  border: 0.88px solid #6e8e16;
  border-radius: 4.43px;
  cursor: pointer;
}
.dropDownSelected {
  font-size: 16px;
  line-height: 17px;
  padding-left: 2px;
  display: block;
}
.dropDownOptions {
  height: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding-bottom: 10px;
  position: absolute;
  top: 40px;
  z-index: 100;
  font-size: 16px;
  line-height: 28px;
  color: black;
  cursor: pointer;
  width: 100px;
  background: #ffffff;
  border: 1.48px solid #a3a3a3;
  border-radius: 7.44px;
}

.first_block1_business {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.first_b_inner_business {
  margin-top: 25px;
}
.first_b_inner_business span {
  color: #f0fde2;
  font-size: 16px;
  font-weight: 600;
}
.first_block_right_business {
  display: flex;
  height: 140px;
  justify-content: flex-end;
}
.dropDownOptionsForGraph {
  height: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding-bottom: 10px;
  position: absolute;
  top: 61px;
  z-index: 100;
  font-size: 16px;
  line-height: 28px;
  color: black;
  cursor: pointer;
  width: 100px;
  background: #ffffff;
  border: 1.48px solid #a3a3a3;
  border-radius: 7.44px;
}
.dropdownItem {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  padding-left: 15px;
}
@media (max-width: 768px) {
  .dropdownItem {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .dropDownSelected {
    font-size: 14px;
  }
}

.dropdownItem:last-child {
  border-bottom: none;
  padding-bottom: 10px;
}
.Bussiness_Impact_Offset {
  /* width: 49%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-left: 10px; */
  border-radius: 12px;
  margin-top: 22px;
}

.Bussiness_project_distribution_h {
  font-family: "Roboto-Bold";
  font-weight: bold;
  padding-left: 24px;
  padding-top: 18px;
  font-size: 20px;
}
.Bussiness_project_distribution_h_ns {
  padding-left: 0px !important;
}

.Bussiness_project_distribution_inner_part1 {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.no-color {
  color: unset !important;
}

.Bussiness_project_distribution_inner_imagePart {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main {
  width: 43%;
  height: 40px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main_SubImage {
  width: 37%;
}

.Bussiness_project_distribution_inner_part_image_sideArrow {
  width: 82%;
  height: 21px;
}
.Bussiness_project_distribution_inner_part {
  height: 101px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-top: 14px; */
  border-radius: 12px;
}
.Bussiness_project_distribution_inner_imagePart_P {
  width: 160px;
  padding-left: 10px;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.bussiness_graph_height {
  height: 275px !important;
  padding: 22px;
  vertical-align: left !important ;
}
.login-text-span {
  color: var(--color-individual);
  font-family: Roboto-Bold;
  padding-right: 4px;
}
.Business_Header_Center_empty {
  height: 300px;
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
.ant-layout-siderP{
   min-width: 200px;
}
@media screen and (min-width: 360px) and (max-width: 576px) {
  .business-inner-text {
    margin-left: 16px;
  }
  .first_block_img {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .impact-graph-dropdown {
    width: 21%;
    line-height: 13px;
    font-size: 11px;
  }
  .grpah-dropdown-selected {
    font-size: 11px;
  }
  .impact-graph-dropdown > p {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 992px) {
  .year-container-block {
    display: flex;
  }
  .year-container-block input[type="radio"] {
    margin-bottom: 23px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .impact-graph-dropdown {
    width: 21%;
    line-height: 13px;
    font-size: 11px;
  }
  .grpah-dropdown-selected {
    font-size: 11px;
  }
}

.biz-pd-graph-header-ns {
  display: flex;
  flex-direction: row;
}
/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/

/*
==============================================
slideDown
==============================================
*/

.slideDown {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(8%);
    }
    65% {
        transform: translateY(-4%);
    }
    80% {
        transform: translateY(4%);
    }
    95% {
        transform: translateY(-2%);
    }
    100% {
        transform: translateY(0%);
    }
}

/*
==============================================
slideUp
==============================================
*/

.slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-8%);
    }
    65% {
        transform: translateY(4%);
    }
    80% {
        transform: translateY(-4%);
    }
    95% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0%);
    }
}

/*
==============================================
slideLeft
==============================================
*/

.slideLeft {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible;
}

@keyframes slideLeft {
    0% {
        transform: translateX(150%);
    }
    50% {
        transform: translateX(-8%);
    }
    65% {
        transform: translateX(4%);
    }
    80% {
        transform: translateX(-4%);
    }
    95% {
        transform: translateX(2%);
    }
    100% {
        transform: translateX(0%);
    }
}

/*
==============================================
slideRight
==============================================
*/

.slideRight {
    animation-name: slideRight;
    -webkit-animation-name: slideRight;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible;
}

@keyframes slideRight {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(8%);
    }
    65% {
        transform: translateX(-4%);
    }
    80% {
        transform: translateX(4%);
    }
    95% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0%);
    }
}

/*
==============================================
slideExpandUp
==============================================
*/

.slideExpandUp {
    animation-name: slideExpandUp;
    -webkit-animation-name: slideExpandUp;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease -out;
    visibility: visible;
}

@keyframes slideExpandUp {
    0% {
        transform: translateY(100%) scaleX(0.5);
    }
    30% {
        transform: translateY(-8%) scaleX(0.5);
    }
    40% {
        transform: translateY(2%) scaleX(0.5);
    }
    50% {
        transform: translateY(0%) scaleX(1.1);
    }
    60% {
        transform: translateY(0%) scaleX(0.9);
    }
    70% {
        transform: translateY(0%) scaleX(1.05);
    }
    80% {
        transform: translateY(0%) scaleX(0.95);
    }
    90% {
        transform: translateY(0%) scaleX(1.02);
    }
    100% {
        transform: translateY(0%) scaleX(1);
    }
}

/*
==============================================
expandUp
==============================================
*/

.expandUp {
    animation-name: expandUp;
    -webkit-animation-name: expandUp;
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible;
}

@keyframes expandUp {
    0% {
        transform: translateY(100%) scale(0.6) scaleY(0.5);
    }
    60% {
        transform: translateY(-7%) scaleY(1.12);
    }
    75% {
        transform: translateY(3%);
    }
    100% {
        transform: translateY(0%) scale(1) scaleY(1);
    }
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible;
}

@keyframes fadeIn {
    0% {
        transform: scale(0);
        opacity: 0.0;
    }
    60% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/*
==============================================
expandOpen
==============================================
*/

.expandOpen {
    animation-name: expandOpen;
    -webkit-animation-name: expandOpen;
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    visibility: visible;
}

@keyframes expandOpen {
    0% {
        transform: scale(1.8);
    }
    50% {
        transform: scale(0.95);
    }
    80% {
        transform: scale(1.05);
    }
    90% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}

/*
==============================================
bigEntrance
==============================================
*/

.bigEntrance {
    animation-name: bigEntrance;
    -webkit-animation-name: bigEntrance;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    visibility: visible;
}

@keyframes bigEntrance {
    0% {
        transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
        opacity: 0.2;
    }
    30% {
        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
        opacity: 1;
    }
    45% {
        transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    60% {
        transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    75% {
        transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    90% {
        transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
}

/*
==============================================
hatch
==============================================
*/

.hatch {
    animation-name: hatch;
    -webkit-animation-name: hatch;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    visibility: visible;
}

@keyframes hatch {
    0% {
        transform: rotate(0deg) scaleY(0.6);
    }
    20% {
        transform: rotate(-2deg) scaleY(1.05);
    }
    35% {
        transform: rotate(2deg) scaleY(1);
    }
    50% {
        transform: rotate(-2deg);
    }
    65% {
        transform: rotate(1deg);
    }
    80% {
        transform: rotate(-1deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/*
==============================================
bounce
==============================================
*/

.bounce {
    animation-name: bounce;
    -webkit-animation-name: bounce;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
}

@keyframes bounce {
    0% {
        transform: translateY(0%) scaleY(0.6);
    }
    60% {
        transform: translateY(-100%) scaleY(1.1);
    }
    70% {
        transform: translateY(0%) scaleY(0.95) scaleX(1.05);
    }
    80% {
        transform: translateY(0%) scaleY(1.05) scaleX(1);
    }
    90% {
        transform: translateY(0%) scaleY(0.95) scaleX(1);
    }
    100% {
        transform: translateY(0%) scaleY(1) scaleX(1);
    }
}

/*
==============================================
pulse
==============================================
*/

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.95);
        opacity: 0.7;
    }
}

/*
==============================================
floating
==============================================
*/

.floating {
    animation-name: floating;
    -webkit-animation-name: floating;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(8%);
    }
    100% {
        transform: translateY(0%);
    }
}

/*
==============================================
tossing
==============================================
*/

.tossing {
    animation-name: tossing;
    -webkit-animation-name: tossing;
    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(4deg);
    }
    100% {
        transform: rotate(-4deg);
    }
}

/*
==============================================
pullUp
==============================================
*/

.pullUp {
    animation-name: pullUp;
    -webkit-animation-name: pullUp;
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
}

@keyframes pullUp {
    0% {
        transform: scaleY(0.1);
    }
    40% {
        transform: scaleY(1.02);
    }
    60% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(1);
    }
}

/*
==============================================
pullDown
==============================================
*/

.pullDown {
    animation-name: pullDown;
    -webkit-animation-name: pullDown;
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
    0% {
        transform: scaleY(0.1);
    }
    40% {
        transform: scaleY(1.02);
    }
    60% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(1);
    }
}

/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft {
    animation-name: stretchLeft;
    -webkit-animation-name: stretchLeft;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
}

@keyframes stretchLeft {
    0% {
        transform: scaleX(0.3);
    }
    40% {
        transform: scaleX(1.02);
    }
    60% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(1);
    }
}

/*
==============================================
stretchRight
==============================================
*/

.stretchRight {
    animation-name: stretchRight;
    -webkit-animation-name: stretchRight;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
}

@keyframes stretchRight {
    0% {
        transform: scaleX(0.3);
    }
    40% {
        transform: scaleX(1.02);
    }
    60% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(1);
    }
}

.animated {
    animation-fill-mode: both;
    animation-duration: 1s;
}

.animated.hinge {
    animation-duration: 1s;
}

/*
@-moz-keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}

@-o-keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}

@keyframes fadeIn {
0% {opacity: 0;}
100% {opacity: 1;}
}

.fadeIn {
-webkit-animation-name: fadeIn;
-moz-animation-name: fadeIn;
-o-animation-name: fadeIn;
animation-name: fadeIn;
}
*/

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUpBig {
    animation-name: fadeInUpBig;
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDownBig {
    animation-name: fadeInDownBig;
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translateX(-2000px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeftBig {
    animation-name: fadeInLeftBig;
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRightBig {
    animation-name: fadeInRightBig;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.fadeOutUp {
    animation-name: fadeOutUp;
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

.fadeOutDown {
    animation-name: fadeOutDown;
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.fadeOutLeft {
    animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}

.fadeOutRight {
    animation-name: fadeOutRight;
}

@keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-2000px);
    }
}

.fadeOutUpBig {
    animation-name: fadeOutUpBig;
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(2000px);
    }
}

.fadeOutDownBig {
    animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-2000px);
    }
}

.fadeOutLeftBig {
    animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(2000px);
    }
}

.fadeOutRightBig {
    animation-name: fadeOutRightBig;
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }
    100% {
        transform: translateY(0);
    }
}

.slideInDown {
    animation-name: slideInDown;
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slideInRight {
    animation-name: slideInRight;
}

@keyframes slideOutUp {
    0% {
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-2000px);
    }
}

.slideOutUp {
    animation-name: slideOutUp;
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-2000px);
    }
}

.slideOutLeft {
    animation-name: slideOutLeft;
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(2000px);
    }
}

.slideOutRight {
    animation-name: slideOutRight;
}

@keyframes moveFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

.moveFromLeft {
    animation: moveFromLeft 400ms ease;
}

@keyframes moveFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

.moveFromRight {
    animation: moveFromRight 400ms ease;
}

@keyframes moveFromBottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

.moveFromBottom {
    animation: moveFromBottom 400ms ease;
}

@keyframes scaleDisappear {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    60% {
        opacity: 0;
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.scaleDisappear {
    animation: scaleDisappear 350ms ease-in-out;
}

@keyframes scaleAppear {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.scaleAppear {
    animation: scaleAppear 750ms ease;
}

/*
Animation example, for spinners
*/

.animate-spin {
    animation: spin 2s infinite linear;
    display: inline-block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #212529;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: #fff;
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {
    .h1,
    h1 {
        font-size: 2.5rem
    }
}

.h2,
h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {
    .h2,
    h2 {
        font-size: 2rem
    }
}

.h3,
h3 {
    font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {
    .h3,
    h3 {
        font-size: 1.75rem
    }
}

.h4,
h4 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {
    .h4,
    h4 {
        font-size: 1.5rem
    }
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-bs-original-title],
abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: .875em
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,
sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #0d6efd;
    text-decoration: underline
}

a:hover {
    color: #0a58ca
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 60%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #6c757d
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    /* padding-right: var(--bs-gutter-x, .75rem); */
    /* padding-left: var(--bs-gutter-x, .75rem); */
    padding-left: 12px;
    padding-right: 12px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

@media (min-width:576px) {
    .col-sm {
        flex: 1 0
    }
    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.33333333%
    }
    .offset-sm-2 {
        margin-left: 16.66666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.33333333%
    }
    .offset-sm-5 {
        margin-left: 41.66666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.33333333%
    }
    .offset-sm-8 {
        margin-left: 66.66666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.33333333%
    }
    .offset-sm-11 {
        margin-left: 91.66666667%
    }
    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }
    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }
    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }
    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }
    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }
    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:768px) {
    .col-md {
        flex: 1 0
    }
    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.33333333%
    }
    .offset-md-2 {
        margin-left: 16.66666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.33333333%
    }
    .offset-md-5 {
        margin-left: 41.66666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.33333333%
    }
    .offset-md-8 {
        margin-left: 66.66666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.33333333%
    }
    .offset-md-11 {
        margin-left: 91.66666667%
    }
    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }
    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }
    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }
    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }
    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }
    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0
    }
    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.33333333%
    }
    .offset-lg-2 {
        margin-left: 16.66666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.33333333%
    }
    .offset-lg-5 {
        margin-left: 41.66666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.33333333%
    }
    .offset-lg-8 {
        margin-left: 66.66666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.33333333%
    }
    .offset-lg-11 {
        margin-left: 91.66666667%
    }
    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }
    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }
    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }
    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }
    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }
    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0
    }
    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-xl-0 {
        margin-left: 0
    }
    .offset-xl-1 {
        margin-left: 8.33333333%
    }
    .offset-xl-2 {
        margin-left: 16.66666667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.33333333%
    }
    .offset-xl-5 {
        margin-left: 41.66666667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.33333333%
    }
    .offset-xl-8 {
        margin-left: 66.66666667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.33333333%
    }
    .offset-xl-11 {
        margin-left: 91.66666667%
    }
    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }
    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }
    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }
    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }
    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }
    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        flex: 1 0
    }
    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-xxl-0 {
        margin-left: 0
    }
    .offset-xxl-1 {
        margin-left: 8.33333333%
    }
    .offset-xxl-2 {
        margin-left: 16.66666667%
    }
    .offset-xxl-3 {
        margin-left: 25%
    }
    .offset-xxl-4 {
        margin-left: 33.33333333%
    }
    .offset-xxl-5 {
        margin-left: 41.66666667%
    }
    .offset-xxl-6 {
        margin-left: 50%
    }
    .offset-xxl-7 {
        margin-left: 58.33333333%
    }
    .offset-xxl-8 {
        margin-left: 66.66666667%
    }
    .offset-xxl-9 {
        margin-left: 75%
    }
    .offset-xxl-10 {
        margin-left: 83.33333333%
    }
    .offset-xxl-11 {
        margin-left: 91.66666667%
    }
    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }
    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }
    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }
    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }
    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }
    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:first-child) {
    border-top: 2px solid currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6
}

.table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce
}

.table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7
}

.table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4
}

.table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #6c757d
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control {
    padding: 1rem .75rem
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #198754
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, .9);
    border-radius: .25rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #198754
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #198754
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #198754
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .25rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #dc3545
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #dc3545
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #dc3545
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212529
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-success {
    color: #198754;
    border-color: #198754
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #198754;
    background-color: transparent
}

.btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #0dcaf0;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-dark {
    color: #212529;
    border-color: #212529
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline
}

.btn-link:hover {
    color: #0a58ca
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropend,
.dropstart,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }
    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }
    .dropdown-menu-sm-end {
        --bs-position: end
    }
    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }
    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }
    .dropdown-menu-md-end {
        --bs-position: end
    }
    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }
    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }
    .dropdown-menu-lg-end {
        --bs-position: end
    }
    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }
    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }
    .dropdown-menu-xl-end {
        --bs-position: end
    }
    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }
    .dropdown-menu-xxl-end {
        --bs-position: end
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #212529
}

.dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-header {
    color: #adb5bd
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: #0a58ca
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: 75vh;
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-sm .navbar-toggler {
        display: none
    }
    .navbar-expand-sm .offcanvas-header {
        display: none
    }
    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }
    .navbar-expand-sm .offcanvas-bottom,
    .navbar-expand-sm .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }
    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-md .navbar-toggler {
        display: none
    }
    .navbar-expand-md .offcanvas-header {
        display: none
    }
    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }
    .navbar-expand-md .offcanvas-bottom,
    .navbar-expand-md .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }
    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-lg .navbar-toggler {
        display: none
    }
    .navbar-expand-lg .offcanvas-header {
        display: none
    }
    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }
    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }
    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-xl .navbar-toggler {
        display: none
    }
    .navbar-expand-xl .offcanvas-header {
        display: none
    }
    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }
    .navbar-expand-xl .offcanvas-bottom,
    .navbar-expand-xl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }
    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }
    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }
    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
    .navbar-expand-xxl .offcanvas-header {
        display: none
    }
    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }
    .navbar-expand-xxl .offcanvas-bottom,
    .navbar-expand-xxl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }
    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0
}

.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }
    .card-group>.card {
        flex: 1 0;
        margin-bottom: 0
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }
    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }
    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-body {
    padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: "/";
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe
}

.alert-primary .alert-link {
    color: #06357a
}

.alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8
}

.alert-secondary .alert-link {
    color: #34383c
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc
}

.alert-success .alert-link {
    color: #0c4128
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb
}

.alert-info .alert-link {
    color: #04414d
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #523e02
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #4f5050
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #101214
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }
    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }
    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }
    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }
    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }
    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }
    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }
    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }
    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
}

.list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #41464b;
    background-color: #cbccce
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
}

.list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #0f5132;
    background-color: #bcd0c7
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
}

.list-group-item-info {
    color: #055160;
    background-color: #cff4fc
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #055160;
    background-color: #badce3
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160
}

.list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #664d03;
    background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
}

.list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
}

.list-group-item-light {
    color: #636464;
    background-color: #fefefe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #636464;
    background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    opacity: 1
}

.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }
    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }
    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }
    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }
    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }
    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }
    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }
    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }
    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,
    .spinner-grow {
        animation-duration: 1.5s
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem
}

.offcanvas-header .btn-close {
    padding: .5rem .5rem;
    margin-top: -.5rem;
    margin-right: -.5rem;
    margin-bottom: -.5rem
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(-100%)
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(100%)
}

.offcanvas.show {
    transform: none
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #0d6efd
}

.link-primary:focus,
.link-primary:hover {
    color: #0a58ca
}

.link-secondary {
    color: #6c757d
}

.link-secondary:focus,
.link-secondary:hover {
    color: #565e64
}

.link-success {
    color: #198754
}

.link-success:focus,
.link-success:hover {
    color: #146c43
}

.link-info {
    color: #0dcaf0
}

.link-info:focus,
.link-info:hover {
    color: #3dd5f3
}

.link-warning {
    color: #ffc107
}

.link-warning:focus,
.link-warning:hover {
    color: #ffcd39
}

.link-danger {
    color: #dc3545
}

.link-danger:focus,
.link-danger:hover {
    color: #b02a37
}

.link-light {
    color: #f8f9fa
}

.link-light:focus,
.link-light:hover {
    color: #f9fafb
}

.link-dark {
    color: #212529
}

.link-dark:focus,
.link-dark:hover {
    color: #1a1e21
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #dee2e6 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #dee2e6 !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #dee2e6 !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #0d6efd !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #198754 !important
}

.border-info {
    border-color: #0dcaf0 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #212529 !important
}

.border-white {
    border-color: #fff !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
}

.gap-1 {
    grid-gap: .25rem !important;
    gap: .25rem !important
}

.gap-2 {
    grid-gap: .5rem !important;
    gap: .5rem !important
}

.gap-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important
}

.gap-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important
}

.gap-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.font-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(13, 110, 253, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(108, 117, 125, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(25, 135, 84, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(13, 202, 240, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(255, 193, 7, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(220, 53, 69, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(248, 249, 250, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(33, 37, 41, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(33, 37, 41, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(13, 110, 253, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(108, 117, 125, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(25, 135, 84, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(13, 202, 240, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(255, 193, 7, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(220, 53, 69, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(33, 37, 41, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important;
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
        user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
        user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
        user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .2rem !important
}

.rounded-2 {
    border-radius: .25rem !important
}

.rounded-3 {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-end {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-start {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }
    .float-sm-end {
        float: right !important
    }
    .float-sm-none {
        float: none !important
    }
    .d-sm-inline {
        display: inline !important
    }
    .d-sm-inline-block {
        display: inline-block !important
    }
    .d-sm-block {
        display: block !important
    }
    .d-sm-grid {
        display: grid !important
    }
    .d-sm-table {
        display: table !important
    }
    .d-sm-table-row {
        display: table-row !important
    }
    .d-sm-table-cell {
        display: table-cell !important
    }
    .d-sm-flex {
        display: flex !important
    }
    .d-sm-inline-flex {
        display: inline-flex !important
    }
    .d-sm-none {
        display: none !important
    }
    .flex-sm-fill {
        flex: 1 1 auto !important
    }
    .flex-sm-row {
        flex-direction: row !important
    }
    .flex-sm-column {
        flex-direction: column !important
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .gap-sm-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }
    .gap-sm-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }
    .gap-sm-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }
    .gap-sm-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .gap-sm-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }
    .gap-sm-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }
    .justify-content-sm-start {
        justify-content: flex-start !important
    }
    .justify-content-sm-end {
        justify-content: flex-end !important
    }
    .justify-content-sm-center {
        justify-content: center !important
    }
    .justify-content-sm-between {
        justify-content: space-between !important
    }
    .justify-content-sm-around {
        justify-content: space-around !important
    }
    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }
    .align-items-sm-start {
        align-items: flex-start !important
    }
    .align-items-sm-end {
        align-items: flex-end !important
    }
    .align-items-sm-center {
        align-items: center !important
    }
    .align-items-sm-baseline {
        align-items: baseline !important
    }
    .align-items-sm-stretch {
        align-items: stretch !important
    }
    .align-content-sm-start {
        align-content: flex-start !important
    }
    .align-content-sm-end {
        align-content: flex-end !important
    }
    .align-content-sm-center {
        align-content: center !important
    }
    .align-content-sm-between {
        align-content: space-between !important
    }
    .align-content-sm-around {
        align-content: space-around !important
    }
    .align-content-sm-stretch {
        align-content: stretch !important
    }
    .align-self-sm-auto {
        align-self: auto !important
    }
    .align-self-sm-start {
        align-self: flex-start !important
    }
    .align-self-sm-end {
        align-self: flex-end !important
    }
    .align-self-sm-center {
        align-self: center !important
    }
    .align-self-sm-baseline {
        align-self: baseline !important
    }
    .align-self-sm-stretch {
        align-self: stretch !important
    }
    .order-sm-first {
        order: -1 !important
    }
    .order-sm-0 {
        order: 0 !important
    }
    .order-sm-1 {
        order: 1 !important
    }
    .order-sm-2 {
        order: 2 !important
    }
    .order-sm-3 {
        order: 3 !important
    }
    .order-sm-4 {
        order: 4 !important
    }
    .order-sm-5 {
        order: 5 !important
    }
    .order-sm-last {
        order: 6 !important
    }
    .m-sm-0 {
        margin: 0 !important
    }
    .m-sm-1 {
        margin: .25rem !important
    }
    .m-sm-2 {
        margin: .5rem !important
    }
    .m-sm-3 {
        margin: 1rem !important
    }
    .m-sm-4 {
        margin: 1.5rem !important
    }
    .m-sm-5 {
        margin: 3rem !important
    }
    .m-sm-auto {
        margin: auto !important
    }
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-sm-0 {
        margin-top: 0 !important
    }
    .mt-sm-1 {
        margin-top: .25rem !important
    }
    .mt-sm-2 {
        margin-top: .5rem !important
    }
    .mt-sm-3 {
        margin-top: 1rem !important
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important
    }
    .mt-sm-5 {
        margin-top: 3rem !important
    }
    .mt-sm-auto {
        margin-top: auto !important
    }
    .me-sm-0 {
        margin-right: 0 !important
    }
    .me-sm-1 {
        margin-right: .25rem !important
    }
    .me-sm-2 {
        margin-right: .5rem !important
    }
    .me-sm-3 {
        margin-right: 1rem !important
    }
    .me-sm-4 {
        margin-right: 1.5rem !important
    }
    .me-sm-5 {
        margin-right: 3rem !important
    }
    .me-sm-auto {
        margin-right: auto !important
    }
    .mb-sm-0 {
        margin-bottom: 0 !important
    }
    .mb-sm-1 {
        margin-bottom: .25rem !important
    }
    .mb-sm-2 {
        margin-bottom: .5rem !important
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-sm-5 {
        margin-bottom: 3rem !important
    }
    .mb-sm-auto {
        margin-bottom: auto !important
    }
    .ms-sm-0 {
        margin-left: 0 !important
    }
    .ms-sm-1 {
        margin-left: .25rem !important
    }
    .ms-sm-2 {
        margin-left: .5rem !important
    }
    .ms-sm-3 {
        margin-left: 1rem !important
    }
    .ms-sm-4 {
        margin-left: 1.5rem !important
    }
    .ms-sm-5 {
        margin-left: 3rem !important
    }
    .ms-sm-auto {
        margin-left: auto !important
    }
    .p-sm-0 {
        padding: 0 !important
    }
    .p-sm-1 {
        padding: .25rem !important
    }
    .p-sm-2 {
        padding: .5rem !important
    }
    .p-sm-3 {
        padding: 1rem !important
    }
    .p-sm-4 {
        padding: 1.5rem !important
    }
    .p-sm-5 {
        padding: 3rem !important
    }
    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-sm-0 {
        padding-top: 0 !important
    }
    .pt-sm-1 {
        padding-top: .25rem !important
    }
    .pt-sm-2 {
        padding-top: .5rem !important
    }
    .pt-sm-3 {
        padding-top: 1rem !important
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important
    }
    .pt-sm-5 {
        padding-top: 3rem !important
    }
    .pe-sm-0 {
        padding-right: 0 !important
    }
    .pe-sm-1 {
        padding-right: .25rem !important
    }
    .pe-sm-2 {
        padding-right: .5rem !important
    }
    .pe-sm-3 {
        padding-right: 1rem !important
    }
    .pe-sm-4 {
        padding-right: 1.5rem !important
    }
    .pe-sm-5 {
        padding-right: 3rem !important
    }
    .pb-sm-0 {
        padding-bottom: 0 !important
    }
    .pb-sm-1 {
        padding-bottom: .25rem !important
    }
    .pb-sm-2 {
        padding-bottom: .5rem !important
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-sm-5 {
        padding-bottom: 3rem !important
    }
    .ps-sm-0 {
        padding-left: 0 !important
    }
    .ps-sm-1 {
        padding-left: .25rem !important
    }
    .ps-sm-2 {
        padding-left: .5rem !important
    }
    .ps-sm-3 {
        padding-left: 1rem !important
    }
    .ps-sm-4 {
        padding-left: 1.5rem !important
    }
    .ps-sm-5 {
        padding-left: 3rem !important
    }
    .text-sm-start {
        text-align: left !important
    }
    .text-sm-end {
        text-align: right !important
    }
    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }
    .float-md-end {
        float: right !important
    }
    .float-md-none {
        float: none !important
    }
    .d-md-inline {
        display: inline !important
    }
    .d-md-inline-block {
        display: inline-block !important
    }
    .d-md-block {
        display: block !important
    }
    .d-md-grid {
        display: grid !important
    }
    .d-md-table {
        display: table !important
    }
    .d-md-table-row {
        display: table-row !important
    }
    .d-md-table-cell {
        display: table-cell !important
    }
    .d-md-flex {
        display: flex !important
    }
    .d-md-inline-flex {
        display: inline-flex !important
    }
    .d-md-none {
        display: none !important
    }
    .flex-md-fill {
        flex: 1 1 auto !important
    }
    .flex-md-row {
        flex-direction: row !important
    }
    .flex-md-column {
        flex-direction: column !important
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-md-wrap {
        flex-wrap: wrap !important
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .gap-md-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }
    .gap-md-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }
    .gap-md-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }
    .gap-md-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .gap-md-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }
    .gap-md-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }
    .justify-content-md-start {
        justify-content: flex-start !important
    }
    .justify-content-md-end {
        justify-content: flex-end !important
    }
    .justify-content-md-center {
        justify-content: center !important
    }
    .justify-content-md-between {
        justify-content: space-between !important
    }
    .justify-content-md-around {
        justify-content: space-around !important
    }
    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }
    .align-items-md-start {
        align-items: flex-start !important
    }
    .align-items-md-end {
        align-items: flex-end !important
    }
    .align-items-md-center {
        align-items: center !important
    }
    .align-items-md-baseline {
        align-items: baseline !important
    }
    .align-items-md-stretch {
        align-items: stretch !important
    }
    .align-content-md-start {
        align-content: flex-start !important
    }
    .align-content-md-end {
        align-content: flex-end !important
    }
    .align-content-md-center {
        align-content: center !important
    }
    .align-content-md-between {
        align-content: space-between !important
    }
    .align-content-md-around {
        align-content: space-around !important
    }
    .align-content-md-stretch {
        align-content: stretch !important
    }
    .align-self-md-auto {
        align-self: auto !important
    }
    .align-self-md-start {
        align-self: flex-start !important
    }
    .align-self-md-end {
        align-self: flex-end !important
    }
    .align-self-md-center {
        align-self: center !important
    }
    .align-self-md-baseline {
        align-self: baseline !important
    }
    .align-self-md-stretch {
        align-self: stretch !important
    }
    .order-md-first {
        order: -1 !important
    }
    .order-md-0 {
        order: 0 !important
    }
    .order-md-1 {
        order: 1 !important
    }
    .order-md-2 {
        order: 2 !important
    }
    .order-md-3 {
        order: 3 !important
    }
    .order-md-4 {
        order: 4 !important
    }
    .order-md-5 {
        order: 5 !important
    }
    .order-md-last {
        order: 6 !important
    }
    .m-md-0 {
        margin: 0 !important
    }
    .m-md-1 {
        margin: .25rem !important
    }
    .m-md-2 {
        margin: .5rem !important
    }
    .m-md-3 {
        margin: 1rem !important
    }
    .m-md-4 {
        margin: 1.5rem !important
    }
    .m-md-5 {
        margin: 3rem !important
    }
    .m-md-auto {
        margin: auto !important
    }
    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-md-0 {
        margin-top: 0 !important
    }
    .mt-md-1 {
        margin-top: .25rem !important
    }
    .mt-md-2 {
        margin-top: .5rem !important
    }
    .mt-md-3 {
        margin-top: 1rem !important
    }
    .mt-md-4 {
        margin-top: 1.5rem !important
    }
    .mt-md-5 {
        margin-top: 3rem !important
    }
    .mt-md-auto {
        margin-top: auto !important
    }
    .me-md-0 {
        margin-right: 0 !important
    }
    .me-md-1 {
        margin-right: .25rem !important
    }
    .me-md-2 {
        margin-right: .5rem !important
    }
    .me-md-3 {
        margin-right: 1rem !important
    }
    .me-md-4 {
        margin-right: 1.5rem !important
    }
    .me-md-5 {
        margin-right: 3rem !important
    }
    .me-md-auto {
        margin-right: auto !important
    }
    .mb-md-0 {
        margin-bottom: 0 !important
    }
    .mb-md-1 {
        margin-bottom: .25rem !important
    }
    .mb-md-2 {
        margin-bottom: .5rem !important
    }
    .mb-md-3 {
        margin-bottom: 1rem !important
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-md-5 {
        margin-bottom: 3rem !important
    }
    .mb-md-auto {
        margin-bottom: auto !important
    }
    .ms-md-0 {
        margin-left: 0 !important
    }
    .ms-md-1 {
        margin-left: .25rem !important
    }
    .ms-md-2 {
        margin-left: .5rem !important
    }
    .ms-md-3 {
        margin-left: 1rem !important
    }
    .ms-md-4 {
        margin-left: 1.5rem !important
    }
    .ms-md-5 {
        margin-left: 3rem !important
    }
    .ms-md-auto {
        margin-left: auto !important
    }
    .p-md-0 {
        padding: 0 !important
    }
    .p-md-1 {
        padding: .25rem !important
    }
    .p-md-2 {
        padding: .5rem !important
    }
    .p-md-3 {
        padding: 1rem !important
    }
    .p-md-4 {
        padding: 1.5rem !important
    }
    .p-md-5 {
        padding: 3rem !important
    }
    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-md-0 {
        padding-top: 0 !important
    }
    .pt-md-1 {
        padding-top: .25rem !important
    }
    .pt-md-2 {
        padding-top: .5rem !important
    }
    .pt-md-3 {
        padding-top: 1rem !important
    }
    .pt-md-4 {
        padding-top: 1.5rem !important
    }
    .pt-md-5 {
        padding-top: 3rem !important
    }
    .pe-md-0 {
        padding-right: 0 !important
    }
    .pe-md-1 {
        padding-right: .25rem !important
    }
    .pe-md-2 {
        padding-right: .5rem !important
    }
    .pe-md-3 {
        padding-right: 1rem !important
    }
    .pe-md-4 {
        padding-right: 1.5rem !important
    }
    .pe-md-5 {
        padding-right: 3rem !important
    }
    .pb-md-0 {
        padding-bottom: 0 !important
    }
    .pb-md-1 {
        padding-bottom: .25rem !important
    }
    .pb-md-2 {
        padding-bottom: .5rem !important
    }
    .pb-md-3 {
        padding-bottom: 1rem !important
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-md-5 {
        padding-bottom: 3rem !important
    }
    .ps-md-0 {
        padding-left: 0 !important
    }
    .ps-md-1 {
        padding-left: .25rem !important
    }
    .ps-md-2 {
        padding-left: .5rem !important
    }
    .ps-md-3 {
        padding-left: 1rem !important
    }
    .ps-md-4 {
        padding-left: 1.5rem !important
    }
    .ps-md-5 {
        padding-left: 3rem !important
    }
    .text-md-start {
        text-align: left !important
    }
    .text-md-end {
        text-align: right !important
    }
    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }
    .float-lg-end {
        float: right !important
    }
    .float-lg-none {
        float: none !important
    }
    .d-lg-inline {
        display: inline !important
    }
    .d-lg-inline-block {
        display: inline-block !important
    }
    .d-lg-block {
        display: block !important
    }
    .d-lg-grid {
        display: grid !important
    }
    .d-lg-table {
        display: table !important
    }
    .d-lg-table-row {
        display: table-row !important
    }
    .d-lg-table-cell {
        display: table-cell !important
    }
    .d-lg-flex {
        display: flex !important
    }
    .d-lg-inline-flex {
        display: inline-flex !important
    }
    .d-lg-none {
        display: none !important
    }
    .flex-lg-fill {
        flex: 1 1 auto !important
    }
    .flex-lg-row {
        flex-direction: row !important
    }
    .flex-lg-column {
        flex-direction: column !important
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .gap-lg-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }
    .gap-lg-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }
    .gap-lg-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }
    .gap-lg-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .gap-lg-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }
    .gap-lg-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }
    .justify-content-lg-start {
        justify-content: flex-start !important
    }
    .justify-content-lg-end {
        justify-content: flex-end !important
    }
    .justify-content-lg-center {
        justify-content: center !important
    }
    .justify-content-lg-between {
        justify-content: space-between !important
    }
    .justify-content-lg-around {
        justify-content: space-around !important
    }
    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }
    .align-items-lg-start {
        align-items: flex-start !important
    }
    .align-items-lg-end {
        align-items: flex-end !important
    }
    .align-items-lg-center {
        align-items: center !important
    }
    .align-items-lg-baseline {
        align-items: baseline !important
    }
    .align-items-lg-stretch {
        align-items: stretch !important
    }
    .align-content-lg-start {
        align-content: flex-start !important
    }
    .align-content-lg-end {
        align-content: flex-end !important
    }
    .align-content-lg-center {
        align-content: center !important
    }
    .align-content-lg-between {
        align-content: space-between !important
    }
    .align-content-lg-around {
        align-content: space-around !important
    }
    .align-content-lg-stretch {
        align-content: stretch !important
    }
    .align-self-lg-auto {
        align-self: auto !important
    }
    .align-self-lg-start {
        align-self: flex-start !important
    }
    .align-self-lg-end {
        align-self: flex-end !important
    }
    .align-self-lg-center {
        align-self: center !important
    }
    .align-self-lg-baseline {
        align-self: baseline !important
    }
    .align-self-lg-stretch {
        align-self: stretch !important
    }
    .order-lg-first {
        order: -1 !important
    }
    .order-lg-0 {
        order: 0 !important
    }
    .order-lg-1 {
        order: 1 !important
    }
    .order-lg-2 {
        order: 2 !important
    }
    .order-lg-3 {
        order: 3 !important
    }
    .order-lg-4 {
        order: 4 !important
    }
    .order-lg-5 {
        order: 5 !important
    }
    .order-lg-last {
        order: 6 !important
    }
    .m-lg-0 {
        margin: 0 !important
    }
    .m-lg-1 {
        margin: .25rem !important
    }
    .m-lg-2 {
        margin: .5rem !important
    }
    .m-lg-3 {
        margin: 1rem !important
    }
    .m-lg-4 {
        margin: 1.5rem !important
    }
    .m-lg-5 {
        margin: 3rem !important
    }
    .m-lg-auto {
        margin: auto !important
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-lg-0 {
        margin-top: 0 !important
    }
    .mt-lg-1 {
        margin-top: .25rem !important
    }
    .mt-lg-2 {
        margin-top: .5rem !important
    }
    .mt-lg-3 {
        margin-top: 1rem !important
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important
    }
    .mt-lg-5 {
        margin-top: 3rem !important
    }
    .mt-lg-auto {
        margin-top: auto !important
    }
    .me-lg-0 {
        margin-right: 0 !important
    }
    .me-lg-1 {
        margin-right: .25rem !important
    }
    .me-lg-2 {
        margin-right: .5rem !important
    }
    .me-lg-3 {
        margin-right: 1rem !important
    }
    .me-lg-4 {
        margin-right: 1.5rem !important
    }
    .me-lg-5 {
        margin-right: 3rem !important
    }
    .me-lg-auto {
        margin-right: auto !important
    }
    .mb-lg-0 {
        margin-bottom: 0 !important
    }
    .mb-lg-1 {
        margin-bottom: .25rem !important
    }
    .mb-lg-2 {
        margin-bottom: .5rem !important
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important
    }
    .mb-lg-auto {
        margin-bottom: auto !important
    }
    .ms-lg-0 {
        margin-left: 0 !important
    }
    .ms-lg-1 {
        margin-left: .25rem !important
    }
    .ms-lg-2 {
        margin-left: .5rem !important
    }
    .ms-lg-3 {
        margin-left: 1rem !important
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important
    }
    .ms-lg-5 {
        margin-left: 3rem !important
    }
    .ms-lg-auto {
        margin-left: auto !important
    }
    .p-lg-0 {
        padding: 0 !important
    }
    .p-lg-1 {
        padding: .25rem !important
    }
    .p-lg-2 {
        padding: .5rem !important
    }
    .p-lg-3 {
        padding: 1rem !important
    }
    .p-lg-4 {
        padding: 1.5rem !important
    }
    .p-lg-5 {
        padding: 3rem !important
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-lg-0 {
        padding-top: 0 !important
    }
    .pt-lg-1 {
        padding-top: .25rem !important
    }
    .pt-lg-2 {
        padding-top: .5rem !important
    }
    .pt-lg-3 {
        padding-top: 1rem !important
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important
    }
    .pt-lg-5 {
        padding-top: 3rem !important
    }
    .pe-lg-0 {
        padding-right: 0 !important
    }
    .pe-lg-1 {
        padding-right: .25rem !important
    }
    .pe-lg-2 {
        padding-right: .5rem !important
    }
    .pe-lg-3 {
        padding-right: 1rem !important
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important
    }
    .pe-lg-5 {
        padding-right: 3rem !important
    }
    .pb-lg-0 {
        padding-bottom: 0 !important
    }
    .pb-lg-1 {
        padding-bottom: .25rem !important
    }
    .pb-lg-2 {
        padding-bottom: .5rem !important
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important
    }
    .ps-lg-0 {
        padding-left: 0 !important
    }
    .ps-lg-1 {
        padding-left: .25rem !important
    }
    .ps-lg-2 {
        padding-left: .5rem !important
    }
    .ps-lg-3 {
        padding-left: 1rem !important
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important
    }
    .ps-lg-5 {
        padding-left: 3rem !important
    }
    .text-lg-start {
        text-align: left !important
    }
    .text-lg-end {
        text-align: right !important
    }
    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }
    .float-xl-end {
        float: right !important
    }
    .float-xl-none {
        float: none !important
    }
    .d-xl-inline {
        display: inline !important
    }
    .d-xl-inline-block {
        display: inline-block !important
    }
    .d-xl-block {
        display: block !important
    }
    .d-xl-grid {
        display: grid !important
    }
    .d-xl-table {
        display: table !important
    }
    .d-xl-table-row {
        display: table-row !important
    }
    .d-xl-table-cell {
        display: table-cell !important
    }
    .d-xl-flex {
        display: flex !important
    }
    .d-xl-inline-flex {
        display: inline-flex !important
    }
    .d-xl-none {
        display: none !important
    }
    .flex-xl-fill {
        flex: 1 1 auto !important
    }
    .flex-xl-row {
        flex-direction: row !important
    }
    .flex-xl-column {
        flex-direction: column !important
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .gap-xl-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }
    .gap-xl-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }
    .gap-xl-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }
    .gap-xl-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .gap-xl-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }
    .gap-xl-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }
    .justify-content-xl-start {
        justify-content: flex-start !important
    }
    .justify-content-xl-end {
        justify-content: flex-end !important
    }
    .justify-content-xl-center {
        justify-content: center !important
    }
    .justify-content-xl-between {
        justify-content: space-between !important
    }
    .justify-content-xl-around {
        justify-content: space-around !important
    }
    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }
    .align-items-xl-start {
        align-items: flex-start !important
    }
    .align-items-xl-end {
        align-items: flex-end !important
    }
    .align-items-xl-center {
        align-items: center !important
    }
    .align-items-xl-baseline {
        align-items: baseline !important
    }
    .align-items-xl-stretch {
        align-items: stretch !important
    }
    .align-content-xl-start {
        align-content: flex-start !important
    }
    .align-content-xl-end {
        align-content: flex-end !important
    }
    .align-content-xl-center {
        align-content: center !important
    }
    .align-content-xl-between {
        align-content: space-between !important
    }
    .align-content-xl-around {
        align-content: space-around !important
    }
    .align-content-xl-stretch {
        align-content: stretch !important
    }
    .align-self-xl-auto {
        align-self: auto !important
    }
    .align-self-xl-start {
        align-self: flex-start !important
    }
    .align-self-xl-end {
        align-self: flex-end !important
    }
    .align-self-xl-center {
        align-self: center !important
    }
    .align-self-xl-baseline {
        align-self: baseline !important
    }
    .align-self-xl-stretch {
        align-self: stretch !important
    }
    .order-xl-first {
        order: -1 !important
    }
    .order-xl-0 {
        order: 0 !important
    }
    .order-xl-1 {
        order: 1 !important
    }
    .order-xl-2 {
        order: 2 !important
    }
    .order-xl-3 {
        order: 3 !important
    }
    .order-xl-4 {
        order: 4 !important
    }
    .order-xl-5 {
        order: 5 !important
    }
    .order-xl-last {
        order: 6 !important
    }
    .m-xl-0 {
        margin: 0 !important
    }
    .m-xl-1 {
        margin: .25rem !important
    }
    .m-xl-2 {
        margin: .5rem !important
    }
    .m-xl-3 {
        margin: 1rem !important
    }
    .m-xl-4 {
        margin: 1.5rem !important
    }
    .m-xl-5 {
        margin: 3rem !important
    }
    .m-xl-auto {
        margin: auto !important
    }
    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-xl-0 {
        margin-top: 0 !important
    }
    .mt-xl-1 {
        margin-top: .25rem !important
    }
    .mt-xl-2 {
        margin-top: .5rem !important
    }
    .mt-xl-3 {
        margin-top: 1rem !important
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important
    }
    .mt-xl-5 {
        margin-top: 3rem !important
    }
    .mt-xl-auto {
        margin-top: auto !important
    }
    .me-xl-0 {
        margin-right: 0 !important
    }
    .me-xl-1 {
        margin-right: .25rem !important
    }
    .me-xl-2 {
        margin-right: .5rem !important
    }
    .me-xl-3 {
        margin-right: 1rem !important
    }
    .me-xl-4 {
        margin-right: 1.5rem !important
    }
    .me-xl-5 {
        margin-right: 3rem !important
    }
    .me-xl-auto {
        margin-right: auto !important
    }
    .mb-xl-0 {
        margin-bottom: 0 !important
    }
    .mb-xl-1 {
        margin-bottom: .25rem !important
    }
    .mb-xl-2 {
        margin-bottom: .5rem !important
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-xl-5 {
        margin-bottom: 3rem !important
    }
    .mb-xl-auto {
        margin-bottom: auto !important
    }
    .ms-xl-0 {
        margin-left: 0 !important
    }
    .ms-xl-1 {
        margin-left: .25rem !important
    }
    .ms-xl-2 {
        margin-left: .5rem !important
    }
    .ms-xl-3 {
        margin-left: 1rem !important
    }
    .ms-xl-4 {
        margin-left: 1.5rem !important
    }
    .ms-xl-5 {
        margin-left: 3rem !important
    }
    .ms-xl-auto {
        margin-left: auto !important
    }
    .p-xl-0 {
        padding: 0 !important
    }
    .p-xl-1 {
        padding: .25rem !important
    }
    .p-xl-2 {
        padding: .5rem !important
    }
    .p-xl-3 {
        padding: 1rem !important
    }
    .p-xl-4 {
        padding: 1.5rem !important
    }
    .p-xl-5 {
        padding: 3rem !important
    }
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-xl-0 {
        padding-top: 0 !important
    }
    .pt-xl-1 {
        padding-top: .25rem !important
    }
    .pt-xl-2 {
        padding-top: .5rem !important
    }
    .pt-xl-3 {
        padding-top: 1rem !important
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important
    }
    .pt-xl-5 {
        padding-top: 3rem !important
    }
    .pe-xl-0 {
        padding-right: 0 !important
    }
    .pe-xl-1 {
        padding-right: .25rem !important
    }
    .pe-xl-2 {
        padding-right: .5rem !important
    }
    .pe-xl-3 {
        padding-right: 1rem !important
    }
    .pe-xl-4 {
        padding-right: 1.5rem !important
    }
    .pe-xl-5 {
        padding-right: 3rem !important
    }
    .pb-xl-0 {
        padding-bottom: 0 !important
    }
    .pb-xl-1 {
        padding-bottom: .25rem !important
    }
    .pb-xl-2 {
        padding-bottom: .5rem !important
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-xl-5 {
        padding-bottom: 3rem !important
    }
    .ps-xl-0 {
        padding-left: 0 !important
    }
    .ps-xl-1 {
        padding-left: .25rem !important
    }
    .ps-xl-2 {
        padding-left: .5rem !important
    }
    .ps-xl-3 {
        padding-left: 1rem !important
    }
    .ps-xl-4 {
        padding-left: 1.5rem !important
    }
    .ps-xl-5 {
        padding-left: 3rem !important
    }
    .text-xl-start {
        text-align: left !important
    }
    .text-xl-end {
        text-align: right !important
    }
    .text-xl-center {
        text-align: center !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }
    .float-xxl-end {
        float: right !important
    }
    .float-xxl-none {
        float: none !important
    }
    .d-xxl-inline {
        display: inline !important
    }
    .d-xxl-inline-block {
        display: inline-block !important
    }
    .d-xxl-block {
        display: block !important
    }
    .d-xxl-grid {
        display: grid !important
    }
    .d-xxl-table {
        display: table !important
    }
    .d-xxl-table-row {
        display: table-row !important
    }
    .d-xxl-table-cell {
        display: table-cell !important
    }
    .d-xxl-flex {
        display: flex !important
    }
    .d-xxl-inline-flex {
        display: inline-flex !important
    }
    .d-xxl-none {
        display: none !important
    }
    .flex-xxl-fill {
        flex: 1 1 auto !important
    }
    .flex-xxl-row {
        flex-direction: row !important
    }
    .flex-xxl-column {
        flex-direction: column !important
    }
    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }
    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }
    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }
    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }
    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }
    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }
    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }
    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }
    .gap-xxl-0 {
        grid-gap: 0 !important;
        gap: 0 !important
    }
    .gap-xxl-1 {
        grid-gap: .25rem !important;
        gap: .25rem !important
    }
    .gap-xxl-2 {
        grid-gap: .5rem !important;
        gap: .5rem !important
    }
    .gap-xxl-3 {
        grid-gap: 1rem !important;
        gap: 1rem !important
    }
    .gap-xxl-4 {
        grid-gap: 1.5rem !important;
        gap: 1.5rem !important
    }
    .gap-xxl-5 {
        grid-gap: 3rem !important;
        gap: 3rem !important
    }
    .justify-content-xxl-start {
        justify-content: flex-start !important
    }
    .justify-content-xxl-end {
        justify-content: flex-end !important
    }
    .justify-content-xxl-center {
        justify-content: center !important
    }
    .justify-content-xxl-between {
        justify-content: space-between !important
    }
    .justify-content-xxl-around {
        justify-content: space-around !important
    }
    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }
    .align-items-xxl-start {
        align-items: flex-start !important
    }
    .align-items-xxl-end {
        align-items: flex-end !important
    }
    .align-items-xxl-center {
        align-items: center !important
    }
    .align-items-xxl-baseline {
        align-items: baseline !important
    }
    .align-items-xxl-stretch {
        align-items: stretch !important
    }
    .align-content-xxl-start {
        align-content: flex-start !important
    }
    .align-content-xxl-end {
        align-content: flex-end !important
    }
    .align-content-xxl-center {
        align-content: center !important
    }
    .align-content-xxl-between {
        align-content: space-between !important
    }
    .align-content-xxl-around {
        align-content: space-around !important
    }
    .align-content-xxl-stretch {
        align-content: stretch !important
    }
    .align-self-xxl-auto {
        align-self: auto !important
    }
    .align-self-xxl-start {
        align-self: flex-start !important
    }
    .align-self-xxl-end {
        align-self: flex-end !important
    }
    .align-self-xxl-center {
        align-self: center !important
    }
    .align-self-xxl-baseline {
        align-self: baseline !important
    }
    .align-self-xxl-stretch {
        align-self: stretch !important
    }
    .order-xxl-first {
        order: -1 !important
    }
    .order-xxl-0 {
        order: 0 !important
    }
    .order-xxl-1 {
        order: 1 !important
    }
    .order-xxl-2 {
        order: 2 !important
    }
    .order-xxl-3 {
        order: 3 !important
    }
    .order-xxl-4 {
        order: 4 !important
    }
    .order-xxl-5 {
        order: 5 !important
    }
    .order-xxl-last {
        order: 6 !important
    }
    .m-xxl-0 {
        margin: 0 !important
    }
    .m-xxl-1 {
        margin: .25rem !important
    }
    .m-xxl-2 {
        margin: .5rem !important
    }
    .m-xxl-3 {
        margin: 1rem !important
    }
    .m-xxl-4 {
        margin: 1.5rem !important
    }
    .m-xxl-5 {
        margin: 3rem !important
    }
    .m-xxl-auto {
        margin: auto !important
    }
    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-xxl-0 {
        margin-top: 0 !important
    }
    .mt-xxl-1 {
        margin-top: .25rem !important
    }
    .mt-xxl-2 {
        margin-top: .5rem !important
    }
    .mt-xxl-3 {
        margin-top: 1rem !important
    }
    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }
    .mt-xxl-5 {
        margin-top: 3rem !important
    }
    .mt-xxl-auto {
        margin-top: auto !important
    }
    .me-xxl-0 {
        margin-right: 0 !important
    }
    .me-xxl-1 {
        margin-right: .25rem !important
    }
    .me-xxl-2 {
        margin-right: .5rem !important
    }
    .me-xxl-3 {
        margin-right: 1rem !important
    }
    .me-xxl-4 {
        margin-right: 1.5rem !important
    }
    .me-xxl-5 {
        margin-right: 3rem !important
    }
    .me-xxl-auto {
        margin-right: auto !important
    }
    .mb-xxl-0 {
        margin-bottom: 0 !important
    }
    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }
    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }
    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }
    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }
    .mb-xxl-auto {
        margin-bottom: auto !important
    }
    .ms-xxl-0 {
        margin-left: 0 !important
    }
    .ms-xxl-1 {
        margin-left: .25rem !important
    }
    .ms-xxl-2 {
        margin-left: .5rem !important
    }
    .ms-xxl-3 {
        margin-left: 1rem !important
    }
    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }
    .ms-xxl-5 {
        margin-left: 3rem !important
    }
    .ms-xxl-auto {
        margin-left: auto !important
    }
    .p-xxl-0 {
        padding: 0 !important
    }
    .p-xxl-1 {
        padding: .25rem !important
    }
    .p-xxl-2 {
        padding: .5rem !important
    }
    .p-xxl-3 {
        padding: 1rem !important
    }
    .p-xxl-4 {
        padding: 1.5rem !important
    }
    .p-xxl-5 {
        padding: 3rem !important
    }
    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-xxl-0 {
        padding-top: 0 !important
    }
    .pt-xxl-1 {
        padding-top: .25rem !important
    }
    .pt-xxl-2 {
        padding-top: .5rem !important
    }
    .pt-xxl-3 {
        padding-top: 1rem !important
    }
    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }
    .pt-xxl-5 {
        padding-top: 3rem !important
    }
    .pe-xxl-0 {
        padding-right: 0 !important
    }
    .pe-xxl-1 {
        padding-right: .25rem !important
    }
    .pe-xxl-2 {
        padding-right: .5rem !important
    }
    .pe-xxl-3 {
        padding-right: 1rem !important
    }
    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }
    .pe-xxl-5 {
        padding-right: 3rem !important
    }
    .pb-xxl-0 {
        padding-bottom: 0 !important
    }
    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }
    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }
    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }
    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }
    .ps-xxl-0 {
        padding-left: 0 !important
    }
    .ps-xxl-1 {
        padding-left: .25rem !important
    }
    .ps-xxl-2 {
        padding-left: .5rem !important
    }
    .ps-xxl-3 {
        padding-left: 1rem !important
    }
    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }
    .ps-xxl-5 {
        padding-left: 3rem !important
    }
    .text-xxl-start {
        text-align: left !important
    }
    .text-xxl-end {
        text-align: right !important
    }
    .text-xxl-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }
    .fs-2 {
        font-size: 2rem !important
    }
    .fs-3 {
        font-size: 1.75rem !important
    }
    .fs-4 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }
    .d-print-inline-block {
        display: inline-block !important
    }
    .d-print-block {
        display: block !important
    }
    .d-print-grid {
        display: grid !important
    }
    .d-print-table {
        display: table !important
    }
    .d-print-table-row {
        display: table-row !important
    }
    .d-print-table-cell {
        display: table-cell !important
    }
    .d-print-flex {
        display: flex !important
    }
    .d-print-inline-flex {
        display: inline-flex !important
    }
    .d-print-none {
        display: none !important
    }
}


.ct_project_main_div  .fade {
    opacity: 0;
    transition: opacity .15s linear
}

.ct_project_main_div  .fade.in {
    opacity: 1
}

.ct_project_main_div  .collapse {
    display: none
}

.ct_project_main_div  .collapse.in {
    display: block
}



.ct_project_main_div  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility
}

.ct_project_main_div  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}


@charset "UTF-8";



.vertical-tabs .tab-content.no-border {
    padding-top: 0;
}

.vertical-tabs .nav>li>a {
    background-color: #333333;
    color: #ffffff;
    padding: 18px 60px 18px 40px;
    margin-bottom: 10px;
    position: relative;
}

.vertical-tabs .nav>li>a i {
    position: relative;
    top: 1px;
    padding-right: 12px;
}

.vertical-tabs .nav>li>a:after {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'rt-icons-2';
    content: "\e7c5";
    position: absolute;
    font-size: 12px;
    line-height: 58px;
    text-align: center;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    letter-spacing: 0;
}

.vertical-tabs .nav>li.active a,
.vertical-tabs .nav>li>a:hover {
    background-color: #4baf47;
}

@media (min-width: 768px) {
    .vertical-tabs .nav>li.active {
        position: relative;
        z-index: 2;
    }
}

.owl-carousel.projects-carousel {
    z-index: 6;
}

@media (min-width: 992px) {
    .owl-carousel.projects-carousel {
        width: 130%;
    }
}

.projects-content {
    padding: 0;
    border: none;
    background-color: transparent;
}

.projects-content .item-media img {
    margin: 0 -16.6666666666%;
    max-width: 150%;
}

@media (max-width: 991px) {
    .projects-content {
        margin-top: 40px;
    }
}

@media (min-width: 768px) {
    .owl-carousel.hover-scale .owl-stage-outer {
        overflow: visible;
    }
    .owl-carousel.hover-scale .vertical-item {
        transition: all 0.2s ease-in;
    }
    .owl-carousel.hover-scale .owl-item:hover .vertical-item,
    .owl-carousel.hover-scale .owl-item.center .vertical-item {
        transform: scale(1.1);
        z-index: 4;
        overflow: hidden;
    }
}


.vertical-item.full-padding {
    padding: 30px 30px 30px 30px;
}

.vertical-item.content-padding .item-content {
    padding: 25px 30px;
}

.vertical-item.content-with-meta .item-content {
    padding: 25px 30px 10px;
}

.vertical-item.content-absolute {
    position: relative;
}

.vertical-item.content-absolute .item-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px;
}

@media (max-width: 400px) {
    .vertical-item.content-absolute .item-content {
        padding: 30px 20px;
    }
}

.vertical-item.content-absolute.vertical-center .item-content {
    bottom: 0;
    line-height: 1.1;
}

.vertical-item.content-absolute.bottom-content .item-content {
    top: auto;
    bottom: 0;
    padding: 0 30px 22px;
}

@media (max-width: 400px) {
    .vertical-item.content-absolute.bottom-content .item-content {
        padding: 0 20px 12px;
    }
}

@media (max-width: 400px) {
    .vertical-item .item-content {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

/* item media links */

.item-media {
    position: relative;
    overflow: hidden;
}

.media-links {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.media-links div {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 4;
}

.media-links:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    filter: alpha(opacity=0);
    opacity: 0;
    transition: all 0.2s linear;
}

[class*="item"]:hover .media-links:before {
    opacity: 0.75;
}

.media-links.p-link:before {
    background-color: #4baf47;
}

.media-links.p-link .links-wrap {
    line-height: 1;
    text-align: center;
    color: #333333;
    bottom: 50%;
    margin-bottom: -15px;
    z-index: 2;
}

.media-links.p-link .links-wrap i {
    position: relative;
    left: -100px;
    font-size: 30px;
    opacity: 0;
    transition: all 0.2s ease-in-out 0s;
    color: #fff;
}

[class*="item"]:hover .media-links.p-link:before {
    opacity: 0.8;
}

[class*="item"]:hover .media-links.p-link .links-wrap i {
    left: 0;
    opacity: 1;
}

.media-links a.abs-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 3;
}

[class*="item"]:hover .media-links a.abs-link {
    opacity: 1;
}

.links-wrap {
    line-height: 0;
    font-size: 0;
    text-align: right;
}

.links-wrap a {
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 60px;
    right: -60px;
    display: inline-block;
    z-index: 5;
    opacity: 0;
    transition: all 0.6s ease-in-out 0s;
    background-color: #4baf47;
}

.links-wrap a:before,
.links-wrap a:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #333333;
}

.links-wrap a:before {
    width: 2px;
    height: 30px;
}

.links-wrap a:after {
    width: 30px;
    height: 2px;
}

.links-wrap a:hover:before,
.links-wrap a:hover:after {
    opacity: 0.6;
}

.links-wrap a.p-link:before {
    content: "\f10c";
}

.media-links.type2 .links-wrap {
    text-align: center;
    top: auto;
    bottom: 50%;
    margin-bottom: -18px;
}

.media-links.type2 .links-wrap a {
    position: relative;
    top: -10px;
    right: 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    background-color: transparent;
    transition: all 0.2s ease-in-out 0s;
}

.media-links.type2 .links-wrap a:after {
    display: none;
}

.media-links.type2 .links-wrap a:before {
    width: auto;
    height: auto;
    position: static;
    font-family: Flaticon;
    font-size: 20px;
    background-color: transparent;
}

.media-links.type2 .links-wrap a.p-link:before {
    content: "\f10a";
}

.media-links.type2 .links-wrap a.p-link {
    transition-delay: 0.2s;
}

.media-links.type2 .links-wrap a.p-like {
    transition-delay: 0.1s;
}

.media-links.type2 .links-wrap a.p-view:before {
    content: "\f117";
}

.media-links.type2 .links-wrap a.p-like:before {
    content: "\f10c";
}

[class*="item"]:hover .media-links.type2 .links-wrap a {
    top: 0;
}

.media-links.type2 .links-wrap a:hover {
    color: #4baf47;
}

.media-links.type2 .links-wrap a:hover:before {
    opacity: 1;
}

.bottom-content .links-wrap {
    top: 0;
    bottom: auto;
}

.bottom-content .links-wrap a {
    height: 70px;
    font-size: 26px;
    line-height: 70px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
}

.bottom-content .links-wrap a:before {
    content: "\f114";
    position: static;
    font-family: Flaticon;
    font-size: 26px;
    background-color: transparent;
    transform: none;
}

.bottom-content .links-wrap a:after {
    display: none;
}

.bottom-content .links-wrap a.p-link:before {
    content: "\f110";
}

.bottom-content .links-wrap a:hover:before,
.bottom-content .links-wrap a:hover:after {
    opacity: 1;
}

[class*="item"]:hover .links-wrap a {
    right: 0;
    opacity: 1;
}

[class*="item"]:hover .media-links div {
    opacity: 1;
}

/* item icons */

.item-icons {
    display: table;
    width: 100%;
    border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
    display: block;
    font-size: 16px;
}

.item-icons>div {
    padding: 22px 10px 18px;
    display: table-cell;
}

.item-icons>div+div {
    border-left: 1px solid rgba(128, 128, 128, 0.1);
}

.read-more {
    font-size: 16px;
    font-weight: 700;
}

.read-more i {
    padding-right: 2px;
    font-size: 400;
    transition: padding 0.2s ease-in-out 0s;
}

.read-more:hover i {
    padding: 0 5px;
}

.bottom-border {
    position: relative;
}

.bottom-border:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}



.block-header .highlight {
    position: relative;
}

.block-header .highlight:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -11px;
    height: 3px;
    z-index: 2;
    background-color: #4baf47;
}



.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
   
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    display: none;
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.owl-carousel .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

/* .owl-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 6px;
    border: 1px solid #a4a4a4;
    display: block;
    backface-visibility: visible;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 50%;
} */

/* .owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
    background-color: #a4a4a4;
} */

/* .owl-carousel .owl-nav {
    margin-top: 37px;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
} */

.owl-carousel .owl-nav>div {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
}

.owl-carousel .owl-nav>div+div {
    margin-left: 65px;
}

.owl-carousel .owl-nav>div:hover {
    color: #4baf47;
}

.owl-carousel .owl-nav>div:first-child:before,
.owl-carousel .owl-nav>div:last-child:after {
    font: normal normal normal 14px/1 FontAwesome;
    color: #4baf47;
    line-height: 16px;
    width: 16px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.2s ease-in-out 0s;
}

.owl-carousel .owl-nav>div:first-child:before {
    content: "\f104";
    padding-right: 10px;
}

.owl-carousel .owl-nav>div:last-child:after {
    content: "\f105";
    padding-left: 10px;
}

/* No Js */





/*
 *  Owl Carousel - Auto Height Plugin
 */

.owl-height {
    transition: height 500ms ease-in-out;
}

/*
 *  Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
}




/* owl theme */

.owl-theme .owl-nav>div {
    text-align: center;
}

.owl-theme .owl-nav .disabled {
    cursor: default;
    pointer-events: none;
}

.owl-theme .owl-dots {
    margin-top: 40px;
}

.owl-center .owl-nav {
    top: 0;
    bottom: 0;
    position: static;
    margin-top: 0;
    opacity: 1;
}

.owl-center .owl-nav>div {
    right: -15px;
    width: 50px;
    top: 0;
    bottom: 0;
    margin: 0;
    height: auto;
    background-color: rgba(51, 51, 51, 0.6);
}

.owl-center .owl-nav>div:first-child {
    left: -15px;
    right: auto;
}

.owl-center .owl-nav>div:first-child:after {
    left: 13px;
}

.owl-center .owl-nav>div:after {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    color: #ffffff;
    font-size: 20px;
    left: 17px;
}



.switch-theme-mode {
    position: fixed !important;
    top: 200px  !important;
    right: 15px  !important;
    z-index: 99  !important;
}


.switch {
    position: relative;
    display: inline-block;
    width: 35px !important;
    height: 35px !important;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
}

.slider.round {
    border-radius: 50%;
}

.slider.round:before {
    border-radius: 50%;
}

.slider:before {
    position: absolute;
    content: "";
    height: 35px;
    width: 35px;
    left: 0px;
    bottom: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: 0.4s;
    box-shadow: 0 0px 15px #2020203d;
    background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABIElEQVR4Aa3B4UpaAQAG0HNDa7vq9gaLTeYTRW2vMn0IfZXBaIPBdf+KfgiFsNEtYa7n+EaIhFqJ0DleyrkzO4ko7CCisHSgtKbt1qmWpYjCQs9ftTUtc/FL20KlstA1F19t+GguKqtem4mx0iM+mPltT9MnIyMnmvZN/NT2hNIr701FRFw5tEXTVFz7oq8Wlxqe9Vlc67jXUYtjawoTEVFhJPqW+mKoMBERYyhciIgKIzGwNBBDhQsRMbbhRNQ67r1xI448q+lK1PoGbsREwxaHLkVETLyz78AT2r6Zamg4NjR0pIE/ZroeUapErWnVD/FPz4bvYq5r4dyZhZZK3HlrxZ7anZ6liMJC6dSttjWljgcRhQeFLSIKOxgbexn/AToGdbC84vBBAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: #565858; */
    background-size: 17px;
}

input:checked+.slider {
    background-color: transparent;
}

input:focus+.slider {
    box-shadow: 0 0 1px transparent;
}

input:checked+.slider:before {
    transform: translateX(0);
    background: #000 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABHklEQVR42mNgQANdXg1cDMSCKZXnPvz/P3saUYobONYd/w8G3f7EKBcDmQ0Cn/4Ro5zpwJP/UHDrBxEaFq/7Dwc3vxNU3ir++i9Cw+u/BDUs2fgfBbQaENBw9gOqhvmL8HtY5Deq+v83vjcw4dHQ6fofA8ybi0fDhHRMDR/+dfvi1NAT9B8LePobp5Y2tf9YwYd/c+fg8MuDX/9xgBvfFyxpN4S7JWT2dHBK3nj+P17w9u/tH7d+fIEkTJBDewL+/icOnH0PtWz3PeI0TC6DpSa9p38IK99yGcnjfVFfCDnnA1rW7Q1/8hu38v2PG8Qwk7nO7jvYXPb678KVOFNXt//Gs/fg8fLr/5l3i9e1ihPMIc2q3f4TUrvsG4Qw5QC3Mwc3/L09xAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
}

.theme-light .navbar-brand .logo-dark {
    display: none !important;
}

.theme-light .navbar-brand .logo-light {
    display: block !important;
}

.theme-dark .dpt-100 {
    padding-top: 100px;
}

.theme-dark .navbar-brand .logo-light {
    display: none !important;
}

.theme-dark .navbar-brand .logo-dark {
    display: block !important;
}

.theme-dark .td-cod,
.theme-dark .footer-wrap,
.theme-dark .btn.style3,
.theme-dark .blog-card.style1,
.theme-dark .blog-card.style2,
.theme-dark .blog-card.style3,
.theme-dark .newsletter-form.style1,
.theme-dark .promo-wrap.style2,
.theme-dark .project-card.style3,
.theme-dark .project-card.style4,
.theme-dark .event-card.style2,
.theme-dark .event-card.style5,
.theme-dark .team-card.style2 .team-info-wrap,
.theme-dark .event-card.style3,
.theme-dark .counter-card-wrap.style1 .counter-card,
.theme-dark .team-card .team-info,
.theme-dark .bg-albastor {
    background-color: #191919 !important;
}

.theme-dark body,
.theme-dark .bg-white,
.theme-dark .search-area,.theme-dark {
    background-color: #000 !important;
}

.theme-dark .td-aztech,
.theme-dark .bg-athens,
.theme-dark .bg-sand,
.theme-dark .hero-wrap.style1,
.theme-dark .counter-wrap.style1,
.theme-dark .hero-wrap.style3,
.theme-dark .about-wrap.style3 .about-img-wrap::after,
.theme-dark .project-card.style1 .project-info,
.theme-dark .wp-block-quote {
    background-color: #091315 !important;
}

.theme-dark .breadcrumb-wrap::after {
    background-color: rgba(9, 19, 21, 0.75) !important;
}

.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6,
.theme-dark h3 a,
.theme-dark a,
.theme-dark .event-search-form .form-group label,
.theme-dark .select-lang .navbar-language .dropdown-toggle::before,
.theme-dark .btn:hover {
    color: #fff !important;
}

.theme-dark p,
.theme-dark span,
.theme-dark .checkbox label,
.theme-dark .content-feature-list li,
.theme-dark .login-form .login-body .form-group label,
.theme-dark .blog-card .blog-info .blog-metainfo li,
.theme-dark article ol li,
.theme-dark .terms-wrap ol li,
.theme-dark article p strong,
.theme-dark .terms-wrap p strong,
.theme-dark .content-feature-list li,
.theme-dark article ol li,
.theme-dark .project-desc ol li,
.theme-dark .event-desc ol li,
.theme-dark .team-desc ol li,
.theme-dark .terms-wrap ol li,
.theme-dark .project-card.style3 .project-info .fund-collection .fund-raised,
.theme-dark .project-card.style3 .project-info .fund-collection .fund-goal,
.theme-dark .project-card.style4 .project-info .fund-collection .fund-raised,
.theme-dark .project-card.style4 .project-info .fund-collection .fund-goal,
.theme-dark article ol li,
.theme-dark .service-desc ol li,
.theme-dark .terms-wrap ol li,
.theme-dark .post-metainfo li {
    color: rgba(255, 255, 255, 0.85) !important;
}

.theme-dark h3:hover a,
.theme-dark a:hover,
.theme-dark .counter-card-wrap.style1 .counter-card.style2 .counter-text h2,
.theme-dark .counter-card-wrap.style1 .counter-card.style2 .counter-text h2 span,
.theme-dark .counter-card-wrap.style1 .counter-card.style3 .counter-text h2,
.theme-dark .counter-card-wrap.style1 .counter-card.style3 .counter-text h2 span,
.theme-dark .copyright-text a {
    color: #4baf47 !important;
}

.theme-dark .counter-card-wrap.style1 .counter-card.style1 .counter-text h2,
.theme-dark .counter-card-wrap.style1 .counter-card.style1 .counter-text h2 span,
.theme-dark .counter-card-wrap.style1 .counter-card.style4 .counter-text h2 span,
.theme-dark .counter-card-wrap.style1 .counter-card.style4 .counter-text h2 {
    color: #F59946 !important;
}

.theme-dark .team-wrap .team-shape-one,
.theme-dark .blog-wrap .container .blog-section-shape,
.theme-dark .promo-wrap.style2 .promo-shape-two,
.theme-dark .event-wrap .event-sape-three,
.theme-dark .promo-wrap.style2 .promo-shape-one {
    opacity: 0.09;
}

.theme-dark .hero-wrap.style3 .hero-shape-three,
.theme-dark .hero-wrap.style3 .hero-shape-four,
.theme-dark .about-wrap.style1 .about-shape-one,
.theme-dark .team-card.style1 .team-info .team-shape {
    opacity: 0.17;
}

.theme-dark .hero-wrap.style3 .hero-shape-two {
    filter: brightness(0) invert(1);
    opacity: 0.3;
}

.theme-dark .cta-wrap.style1::after,
.theme-dark .cta-wrap.style2::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 45%, rgba(0, 0, 0, 0.3) 94%);
}

.theme-dark article,
.theme-dark .owl-carousel .owl-nav button.owl-prev,
.theme-dark .owl-carousel .owl-nav button.owl-next {
    border-color: rgba(255, 255, 255, 0.35);
}

.theme-dark .owl-carousel .owl-dots button.owl-dot span {
    background: rgba(255, 255, 255, 0.3);
}

.theme-dark .owl-carousel .owl-dots button.owl-dot.active span {
    background-color: #4baf47;
}

.theme-dark .tag-list li a {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff !important;
}

.theme-dark .tag-list li a:hover {
    background: #4baf47 !important;
    color: #fff;
    border-color: transparent;
}

.theme-dark .event-search-form {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.theme-dark .header-wrap .header-top {
    background-color: #000;
}

.theme-dark .header-wrap .header-top .header-top-right .social-profile li a {
    background-color: rgba(255, 255, 255, 0.15);
}

.theme-dark .header-wrap .header-top .header-top-right .social-profile li a:hover {
    background-color: #4baf47;
}

.theme-dark .header-wrap .header-bottom {
    background-color: #191919;
}

.theme-dark .header-wrap .header-bottom .searchbox input {
    color: #fff;
}

.theme-dark .header-wrap .header-bottom .searchbox button i {
    color: rgba(255, 255, 255, 0.8);
}

.theme-dark .header-wrap .header-bottom .searchbtn i {
    color: #fff;
}

.theme-dark .breadcrumb-wrap:after {
    background-color: rgba(0, 0, 0, 0.9);
}

.theme-dark .header-wrap .navbar .other-options::before {
    background-color: rgba(255, 255, 255, 0.18);
}

.theme-dark .accordion-item {
    background: transparent;
}

.theme-dark .accordion-item .accordion-header .accordion-button {
    background-color: #191919;
    color: #fff;
}

.theme-dark .accordion-item .accordion-body {
    border-color: rgba(255, 255, 255, 0.18);
}

.theme-dark .search-area button i {
    color: #fff;
}

.theme-dark .sidebar-widget {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.theme-dark .sidebar-widget.style2 {
    border: none;
}

.theme-dark .header-wrap .navbar .navbar-nav .nav-item a:hover,
.theme-dark .header-wrap .navbar .navbar-nav .nav-item a:focus,
.theme-dark .header-wrap .navbar .navbar-nav .nav-item a.active {
    color: #4baf47 !important;
}

.theme-dark .event-card,
.theme-dark .blog-card .blog-info,
.theme-dark .promo-wrap.style2 .promo-card,
.theme-dark .event-info-item-wrap .event-info-item,
.theme-dark .comment-item {
    border-color: rgba(255, 255, 255, 0.2);
}

.theme-dark .post-author,
.theme-dark .contact-item-wrap,
.theme-dark .contact-form {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.theme-dark .social-profile.style3 a {
    background-color: rgba(255, 255, 255, 0.15);
}

.theme-dark .social-profile.style3 a i {
    color: #fff;
}

.theme-dark .social-profile.style3 a:hover {
    background-color: #4baf47;
}


/* .theme-dark select {
    background-image: url(../img/down-arrow-white.png);
} */

.theme-dark .donation-form {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.theme-dark input,
.theme-dark textarea,
.theme-dark .event-search-form .form-group select,
.theme-dark .event-search-form .form-group input,
.theme-dark #cmt-form .comment-form .form-group input,
.theme-dark #cmt-form .comment-form .form-group textarea,
.theme-dark #contactForm .form-group input,
.theme-dark #contactForm .form-group textarea,
.theme-dark .donation-form .form-group input,
.theme-dark .donation-form .form-group textarea,
.theme-dark .donation-form .form-group select,
.theme-dark .register-widget .form-group input,
.theme-dark .register-widget .form-group select,
.theme-dark .register-widget .form-group textarea,
.theme-dark .project-search-form .form-group select,
.theme-dark .project-search-form .form-group input,
.theme-dark .login-form-wrap .login-body .form-group input,
.theme-dark .newsletter-form .form-group input {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.theme-dark .team-card.style3 {
    background-color: transparent;
}

.theme-dark .team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(2n) .team-card.style3:hover,
.theme-dark .team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(2n+1) .team-card.style3:hover {
    background-color: #191919;
}

.theme-dark .page-nav li a {
    border: 1px solid rgba(255, 255, 255, 0.28);
    background-color: transparent;
}

.theme-dark .page-nav li a:hover {
    background-color: #4baf47;
    color: #fff !important;
}

.theme-dark .login-form-wrap .login-form .form-group input,
.theme-dark .contact-widget .form-group input,
.theme-dark .contact-widget .form-group textarea,
.theme-dark .search-box .form-group input {
    background-color: #000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.theme-dark .login-form-wrap .login-form .form-group input:-ms-input-placeholder,
.theme-dark .contact-widget .form-group input:-ms-input-placeholder,
.theme-dark .contact-widget .form-group textarea:-ms-input-placeholder,
.theme-dark .search-box .form-group input:-ms-input-placeholder {
    color: #fff;
}

.theme-dark .login-form-wrap .login-form .form-group input::-moz-placeholder, .theme-dark .contact-widget .form-group input::-moz-placeholder, .theme-dark .contact-widget .form-group textarea::-moz-placeholder, .theme-dark .search-box .form-group input::-moz-placeholder {
    color: #fff;
}

.theme-dark .login-form-wrap .login-form .form-group input:-ms-input-placeholder, .theme-dark .contact-widget .form-group input:-ms-input-placeholder, .theme-dark .contact-widget .form-group textarea:-ms-input-placeholder, .theme-dark .search-box .form-group input:-ms-input-placeholder {
    color: #fff;
}

.theme-dark .login-form-wrap .login-form .form-group input::placeholder,
.theme-dark .contact-widget .form-group input::placeholder,
.theme-dark .contact-widget .form-group textarea::placeholder,
.theme-dark .search-box .form-group input::placeholder {
    color: #fff;
}

.theme-dark .login-form-wrap {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
}

.theme-dark .ct_white_bg {
    background-color: #333;
}
.theme-dark blockquote{
    color: #fff;
}
.theme-dark .ct_bottom_card_info{
    background-color: #333;
}

.theme-dark .ct_dashboard_right_cnt {
    background-color: #333;
}
.theme-dark .ct_dashboard_right_cnt .ct_dashboard_cnt h4,.theme-dark .ct_dashboard_right_cnt .ct_dashboard_cnt p {
    color: #fff !important;
}
.theme-dark .ct_small_form_news input {
    background-color: #333;
}
.theme-dark .ct_leave_reply_main {
    background-color: transparent;
}

.theme-dark .ct_nwsletr_bg1:before {
 
    opacity: 0.2;

}
.theme-dark .ct_nwsletr_bg1 {
   
    background: #000;
}
.theme-dark .ct_globel_partner_bg{
    background: #333;
}
.theme-dark  .ct_globel_logo_img {
    background-color: #fff;
   
}
.theme-dark .ct_scroll_fixed_cnt .ct_round_num p {
    color: #fff;
    background-color: #333;
}
.theme-dark .ct_dash_esg_cnt h2,.theme-dark .ct_dash_esg_cnt  p ,.theme-dark .ct_srvc_point h4{
    color: #333 !important;
}
.theme-dark .ct_dash_esg_cnt span.ct_clr_text  {
    color: #86af48!important;
}
.theme-dark .ct_dark_mode_head h2, .theme-dark .ct_dark_mode_head p {
    color: #fff !important;
}
.theme-dark  .ct_dark_srvc_point li h4{
    color: #fff !important;
}
.theme-dark .ct_how_works_bg p,.theme-dark .ct_how_works_bg h2{
    color: #333 !important;
}
.theme-dark .ct_how_works_bg h4,.theme-dark .ct_how_works_bg p{
    color: #fff !important;
}
html.theme-dark .ct_green_background {
    background: #333;
}
.theme-dark .ct_svg_head,.theme-dark .ct_how_works_bg{
    background-color: #222;
}
.theme-dark .ct_svg_head:after{
    display: none;
}
.theme-dark .ct_how_works_bg h2{
    color: #fff !important;
}
.theme-dark .ct_bg_grey_1 {
    background-color: #181818;
    color: #fff !important;
    padding: 15px;
}
.theme-dark .ct_box_light_bg p {
    color: #fff !important;
}
.theme-dark .ct_table tr th,.theme-dark .ct_table tr td{
    color: #fff;
}
.theme-dark .ct_bank_bg{
    background-color: #181818;
}

.theme-dark .ct_how_work_box .ct_how_work_icon {
    background-color: #13190a;
}
.theme-dark .ct_how_work_box{
    background-color: #333;
}

.theme-dark .ct_carbon_neutral_nwsltr_cnt h2 {
    color: #000 !important;
}
.theme-dark .ct_light_yellow_bg {
    background-color: #000;
}
.theme-dark .ct_faq_q_box{
    background-color: #333;
}
.theme-dark .ct_carbon_neutral_nwsltr {
    background-color: #333;
}
.theme-dark .ct_carbon_neutral_nwsltr_cnt h2{
color: #fff !important;
}
.theme-dark .ct_eko_points_bg:after{
    display: none;
}
.theme-dark .ct_eko_spark_bg_title{
margin-top: 0px;
}

.theme-dark .ct_eko_point_work_box {
    background-color: rgb(51 51 51 / 32%);
    border-color: #333;
}
.theme-dark  .ct_eko_point_work_cnt::before {
  
    background-color: #333
}

.theme-dark  .ct_eko_point_work_cnt{
    background-color: #333;
}
.theme-dark h4.T_M_inner2_head1_climate,.theme-dark .T_m_innerP {
    color: #333 !important;
}
@media only screen and (max-width: 991px) {
    .theme-dark .main-menu-wrap,
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu {
        background-color: #091315;
    }
    .theme-dark .menu-close i,
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item .menu-expand i {
        color: #fff !important;
    }
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item a.active,
    .theme-dark .main-menu li a.active {
        color: #4baf47 !important;
    }
    .theme-dark .main-menu li a.active,
    .theme-dark .main-menu li a {
        border-color: rgba(255, 255, 255, 0.2);
    }
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li,
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item a {
        border-color: rgba(255, 255, 255, 0.2);
    }
}

@media only screen and (min-width: 992px) {
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu,
    .theme-dark .main-menu-wrap #menu ul li.has-children .sub-menu {
        background-color: #091315;
    }
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item a:hover,
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item a:focus,
    .theme-dark .header-wrap .navbar .navbar-nav .nav-item a.active {
        color: #4baf47 !important;
    }
    .theme-dark .header-wrap.style2 .navbar .navbar-nav .nav-item .dropdown-menu li {
        border-color: rgba(255, 255, 255, 0.15);
    }
}

@media screen and (max-width:767px) {
    .switch-theme-mode {
        top: 130px;
    }
}



$clr_white: #fff;
$clr_offwhite: rgba(255,255,255,0.85);
$clr_black: #000;
$clr_aztech: #091315;
$clr_cod: #191919;
$clr_grey: #ABA8B0;
$clr_mine_shaft: #3B3B3B;
$clr_mirage:#231F3E;
$clr_sand: #F5F5F5;
$clr_red:#FF3838;
$clr_lochi:#338F7A;
$clr_jaffa:#F59946;

.switch-theme-mode {
    position: fixed !important;
    top: 200px  !important;
    right: 15px  !important;
    z-index: 99  !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
      }
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    &.round {
        border-radius: 50%;
        &:before{
            border-radius: 50%;
        }
    }
    &:before {
        position: absolute;
        content: "";
        height: 35px;
        width: 35px;
        left: 0px;
        bottom: 4px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: 0.4s;
        box-shadow: 0 0px 15px #2020203d;
        background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABIElEQVR4Aa3B4UpaAQAG0HNDa7vq9gaLTeYTRW2vMn0IfZXBaIPBdf+KfgiFsNEtYa7n+EaIhFqJ0DleyrkzO4ko7CCisHSgtKbt1qmWpYjCQs9ftTUtc/FL20KlstA1F19t+GguKqtem4mx0iM+mPltT9MnIyMnmvZN/NT2hNIr701FRFw5tEXTVFz7oq8Wlxqe9Vlc67jXUYtjawoTEVFhJPqW+mKoMBERYyhciIgKIzGwNBBDhQsRMbbhRNQ67r1xI448q+lK1PoGbsREwxaHLkVETLyz78AT2r6Zamg4NjR0pIE/ZroeUapErWnVD/FPz4bvYq5r4dyZhZZK3HlrxZ7anZ6liMJC6dSttjWljgcRhQeFLSIKOxgbexn/AToGdbC84vBBAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        background-position: center;
        background-color: $clr_aztech;
        background-size: 17px;
    }
}
input:checked + .slider {
  background-color: transparent;
}
input:focus + .slider {
  box-shadow: 0 0 1px  transparent;
}
input:checked + .slider:before {
  transform: translateX(0);
  background: #000 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABHklEQVR42mNgQANdXg1cDMSCKZXnPvz/P3saUYobONYd/w8G3f7EKBcDmQ0Cn/4Ro5zpwJP/UHDrBxEaFq/7Dwc3vxNU3ir++i9Cw+u/BDUs2fgfBbQaENBw9gOqhvmL8HtY5Deq+v83vjcw4dHQ6fofA8ybi0fDhHRMDR/+dfvi1NAT9B8LePobp5Y2tf9YwYd/c+fg8MuDX/9xgBvfFyxpN4S7JWT2dHBK3nj+P17w9u/tH7d+fIEkTJBDewL+/icOnH0PtWz3PeI0TC6DpSa9p38IK99yGcnjfVFfCDnnA1rW7Q1/8hu38v2PG8Qwk7nO7jvYXPb678KVOFNXt//Gs/fg8fLr/5l3i9e1ihPMIc2q3f4TUrvsG4Qw5QC3Mwc3/L09xAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;

}

.theme-light {
    .navbar-brand{
        .logo-dark{
            display: none!important;
        }
        .logo-light{
            display: block!important;
        }
    }
}
.theme-dark {
    .dpt-100{
        padding-top: 100px;
    }
    .navbar-brand{
        .logo-light{
            display: none!important;
        }
        .logo-dark{
            display: block!important;
        }
    }
    .td-cod,
    .footer-wrap,
    .btn.style3,
    .blog-card.style1,
    .blog-card.style2,
    .blog-card.style3,
    .newsletter-form.style1,
    .promo-wrap.style2,
    .project-card.style3,
    .project-card.style4,
    .event-card.style2,
    .event-card.style5,
    .team-card.style2 .team-info-wrap,
    .event-card.style3,
    .counter-card-wrap.style1 .counter-card,
    .team-card .team-info,
    .bg-albastor {
        background-color: $clr_cod!important;
    }
    body,
    .bg-white,
    .search-area{
        background-color: $clr_black!important;
    }
    
    .td-aztech,
    .bg-athens,
    .bg-sand,
    .hero-wrap.style1,
    .counter-wrap.style1,
    .hero-wrap.style3,
    .about-wrap.style3 .about-img-wrap::after,
    .project-card.style1 .project-info,
    .wp-block-quote{
        background-color: $clr_aztech!important;
    }
    .breadcrumb-wrap::after{
        background-color: rgba(9, 19, 21,0.75)!important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h3 a,
    a,
    .event-search-form .form-group label,
    .select-lang .navbar-language .dropdown-toggle::before,
    .btn:hover{
        color: $clr_white!important;
    }
    p,span,
    .checkbox label,
    .content-feature-list li,
    .login-form .login-body .form-group label,
    .blog-card .blog-info .blog-metainfo li,
    article ol li,
    .terms-wrap ol li,
    article p strong, 
    .terms-wrap p strong,
    .content-feature-list li,
    article ol li,
    .project-desc ol li,
     .event-desc ol li,
    .team-desc ol li,
    .terms-wrap ol li,
    .project-card.style3 .project-info .fund-collection .fund-raised,
    .project-card.style3 .project-info .fund-collection .fund-goal,
    .project-card.style4 .project-info .fund-collection .fund-raised,
    .project-card.style4 .project-info .fund-collection .fund-goal,
    article ol li, .service-desc ol li, .terms-wrap ol li,
    .post-metainfo li{
        color: $clr_offwhite!important;
    }
    h3:hover a,
    a:hover,
    .counter-card-wrap.style1 .counter-card.style2 .counter-text h2,
    .counter-card-wrap.style1 .counter-card.style2 .counter-text h2 span,
    .counter-card-wrap.style1 .counter-card.style3 .counter-text h2,
    .counter-card-wrap.style1 .counter-card.style3 .counter-text h2 span,
    .copyright-text a{
        color: $clr_lochi!important;
    }
    .counter-card-wrap.style1 .counter-card.style1 .counter-text h2,
    .counter-card-wrap.style1 .counter-card.style1 .counter-text h2 span,
    .counter-card-wrap.style1 .counter-card.style4 .counter-text h2 span,
    .counter-card-wrap.style1 .counter-card.style4 .counter-text h2 {
        color: $clr_jaffa!important;
    }
    .team-wrap .team-shape-one,
    .blog-wrap .container .blog-section-shape,
    .promo-wrap.style2 .promo-shape-two,
    .event-wrap .event-sape-three,
    .promo-wrap.style2 .promo-shape-one{
        opacity: 0.09;
    }
    .hero-wrap.style3 .hero-shape-three,
    .hero-wrap.style3 .hero-shape-four,
    .about-wrap.style1 .about-shape-one,
    .team-card.style1 .team-info .team-shape {
        opacity: 0.17;
    }
    .hero-wrap.style3 .hero-shape-two {
        filter: brightness(0) invert(1);
        opacity: 0.3;
    }
    .cta-wrap.style1::after, .cta-wrap.style2::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 45%, rgba(0, 0, 0, 0.3) 94%);
    }
    article,
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next{
        border-color: rgba(255,255,255,0.35);
    }
    .owl-carousel .owl-dots button.owl-dot span{
        background: rgba(255, 255, 255, 0.3);
    }
    .owl-carousel .owl-dots button.owl-dot.active span{
        background-color: $clr_lochi;
    }
    .tag-list li a{
        background: transparent;
        border-color: rgba(255, 255, 255, 0.2);
        color: $clr_white!important;
    }
    .tag-list li a:hover{
        background: $clr_lochi!important;
        color: $clr_white;
        border-color: transparent;
    }
    .event-search-form {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.25);
    }
    .header-wrap{
        .header-top{
            background-color: $clr_black;
            .header-top-right{
                .social-profile{
                    li{
                        a{
                            background-color: rgba(255, 255, 255, 0.15);
                            &:hover{
                                background-color: $clr_lochi;
                            }
                        }
                    }
                }
            }
        }
        .header-bottom{
            background-color: $clr_cod;
            .searchbox{
                input{
                    color: $clr_white;
                }
                button{
                    i{
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            .searchbtn{
                i{
                    color: $clr_white;
                }
            }
        }
    }
    .breadcrumb-wrap:after{
        background-color: rgba(0, 0, 0, 0.9);
    }
    .header-wrap .navbar .other-options::before {
        background-color: rgba(255, 255, 255, 0.18);
    }
    .accordion-item {
        background: transparent;
        .accordion-header {
            .accordion-button{
                background-color: $clr_cod;
                color: $clr_white;
            }
        }
        .accordion-body{
            border-color: rgba(255, 255, 255, 0.18);
        }
    }
    .search-area{
        button{
            i{
                color: $clr_white;
            }
        }
    }
    .sidebar-widget{
        background-color: transparent;
        box-shadow: none;
        border: 1px solid rgba(255, 255, 255, 0.2);
        &.style2{
            border: none;
        }
    }
    .header-wrap .navbar .navbar-nav .nav-item a:hover,
    .header-wrap .navbar .navbar-nav .nav-item a:focus,
    .header-wrap .navbar .navbar-nav .nav-item a.active {
        color: $clr_lochi!important;
    }
    .event-card,
    .blog-card .blog-info,
    .promo-wrap.style2 .promo-card,
    .event-info-item-wrap .event-info-item,
    .comment-item{
        border-color: rgba(255, 255, 255, 0.2);
    }
    .post-author,
    .contact-item-wrap,
    .contact-form{
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
    }
    .social-profile{
        &.style3{
            a{
                background-color: rgba(255, 255, 255, 0.15);
                i{
                    color: $clr_white;
                }
                &:hover{
                    background-color: $clr_lochi;
                }
            }
        }
    }
    .donation-form{
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
    }
    input,textarea,
    .event-search-form .form-group select,
    .event-search-form .form-group input,
    #cmt-form .comment-form .form-group input,
    #cmt-form .comment-form .form-group textarea,
    #contactForm .form-group input,
    #contactForm .form-group textarea,
    .donation-form .form-group input,
    .donation-form .form-group textarea,
    .donation-form .form-group select,
    .register-widget .form-group input,
    .register-widget .form-group select,
    .register-widget .form-group textarea,
    .project-search-form .form-group select,
    .project-search-form .form-group input,
    .login-form-wrap .login-body .form-group input,
    .newsletter-form .form-group input{
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: $clr_white;
    }
    .team-card.style3{
        background-color: transparent;
    }
    .team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(2n) .team-card.style3:hover,
    .team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(2n+1) .team-card.style3:hover{
        background-color: $clr_cod;
    }
    .page-nav li a{
        border: 1px solid rgba(255, 255, 255, 0.28);
        background-color: transparent;
        &:hover{
            background-color: $clr_lochi;
            color: $clr_white!important;
        }
    }
    .login-form-wrap .login-form .form-group input,
    .contact-widget .form-group input,
    .contact-widget .form-group textarea,
    .search-box .form-group input{
        background-color: $clr_black;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: $clr_white;
        &::-moz-placeholder{
            color: $clr_white;
        }
        &:-ms-input-placeholder{
            color: $clr_white;
        }
        &::placeholder{
            color: $clr_white;
        }
    }
    .login-form-wrap{
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: transparent;
    }
}
@media only screen and (max-width:991px) {
    .theme-dark{
        .main-menu-wrap,
        .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu{
            background-color: $clr_aztech;
        }
        .menu-close i,
        .header-wrap .navbar .navbar-nav .nav-item .menu-expand i{
            color: $clr_white!important;
        }
        .header-wrap .navbar .navbar-nav .nav-item a.active,
        .main-menu li a.active{
            color: $clr_lochi!important;
        }
        .main-menu li a.active,
        .main-menu li a{
            border-color: rgba(255, 255, 255, 0.2);
        }
        .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li,
        .header-wrap .navbar .navbar-nav .nav-item a {
            border-color:rgba(255, 255, 255, 0.2);
        }
    }
    
}
@media only screen and (min-width:992px) {
    .theme-dark{
        .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu,
        .main-menu-wrap #menu ul li.has-children .sub-menu{
            background-color: $clr_aztech;
        }
        .header-wrap .navbar .navbar-nav .nav-item a:hover,
        .header-wrap .navbar .navbar-nav .nav-item a:focus,
        .header-wrap .navbar .navbar-nav .nav-item a.active{
              color: $clr_lochi!important;
         }
         .header-wrap.style2 .navbar .navbar-nav .nav-item .dropdown-menu li{
            border-color:rgba(255, 255, 255, 0.15);
        }
    }
}
body.compensate-for-scrollbar {
    overflow: hidden
}

.fancybox-active {
    height: auto
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: 0;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992
}

.fancybox-container * {
    box-sizing: border-box
}

.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto
}

.fancybox-bg {
    background: #1e1e1e;
    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71)
}

.fancybox-is-open .fancybox-bg {
    opacity: .9;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1)
}

.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997
}

.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
    opacity: 1;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.fancybox-toolbar {
    right: 0;
    top: 0
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    transform: translateZ(0);
    z-index: 99994
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform, opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
    display: block
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0
}

.fancybox-slide--image::before {
    display: none
}

.fancybox-slide--html {
    padding: 6px
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle
}

.fancybox-slide--image .fancybox-content {
    animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    -webkit-backface-visibility: hidden;
    background: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    transform-origin: top left;
    transition-property: transform, opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: zoom-out
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: zoom-in
}

.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
    cursor: grab
}

.fancybox-is-grabbing .fancybox-content {
    cursor: grabbing
}

.fancybox-container [data-selectable=true] {
    cursor: text
}

.fancybox-image,
.fancybox-spaceball {
    background: 0 0;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.fancybox-spaceball {
    z-index: 1
}

.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--video .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%
}

.fancybox-slide--video .fancybox-content {
    background: #000
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff
}

.fancybox-iframe,
.fancybox-video {
    background: 0 0;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%
}

.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0
}

.fancybox-button {
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
    color: #ccc
}

.fancybox-button:hover {
    color: #fff
}

.fancybox-button:focus {
    outline: 0
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: 0
}

.fancybox-button div {
    height: 100%
}

.fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0
}

.fancybox-button--fsenter svg:nth-child(2),
.fancybox-button--play svg:nth-child(2) {
    display: none
}

.fancybox-button--fsexit svg:nth-child(1),
.fancybox-button--pause svg:nth-child(1) {
    display: none
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998
}

.fancybox-close-small {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: .8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401
}

.fancybox-close-small:hover {
    color: #fff;
    opacity: 1
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none
}

.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px
}

.fancybox-navigation .fancybox-button div {
    padding: 7px
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 31px 26px 31px 6px
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
    right: env(safe-area-inset-right)
}

.fancybox-caption {
    background: linear-gradient(to top, rgba(0, 0, 0, .85) 0, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .15) 65%, rgba(0, 0, 0, .075) 75.5%, rgba(0, 0, 0, .037) 82.85%, rgba(0, 0, 0, .019) 88%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 75px 44px 25px 44px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996
}

@supports (padding:max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left))
    }
}

.fancybox-caption--separate {
    margin-top: -50px
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline
}

.fancybox-loading {
    animation: fancybox-rotate 1s linear infinite;
    background: 0 0;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999
}

@keyframes fancybox-rotate {
    100% {
        transform: rotate(360deg)
    }
}

.fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, .25, 1)
}

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    transform: translate3d(100%, 0, 0)
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    transform: translate3d(0, 0, 0)
}

.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
    opacity: 0;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5)
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(.5, .5, .5)
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1)
}

.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    transform: rotate(-360deg)
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    transform: rotate(360deg)
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    transform: rotate(0)
}

.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0)
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0)
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0)
}

.fancybox-fx-tube.fancybox-slide--previous {
    transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg)
}

.fancybox-fx-tube.fancybox-slide--next {
    transform: translate3d(100%, 0, 0) scale(.1) skew(10deg)
}

.fancybox-fx-tube.fancybox-slide--current {
    transform: translate3d(0, 0, 0) scale(1)
}

@media all and (max-height:576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px
    }
    .fancybox-slide--image {
        padding: 6px 0
    }
    .fancybox-close-small {
        right: -6px
    }
    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px
    }
    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px
    }
    @supports (padding:max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right))
        }
    }
}

.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0
}

.fancybox-share p {
    margin: 0;
    padding: 0
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap
}

.fancybox-share__button:link,
.fancybox-share__button:visited {
    color: #fff
}

.fancybox-share__button:hover {
    text-decoration: none
}

.fancybox-share__button--fb {
    background: #3b5998
}

.fancybox-share__button--fb:hover {
    background: #344e86
}

.fancybox-share__button--pt {
    background: #bd081d
}

.fancybox-share__button--pt:hover {
    background: #aa0719
}

.fancybox-share__button--tw {
    background: #1da1f2
}

.fancybox-share__button--tw:hover {
    background: #0d95e8
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px
}

.fancybox-share__button svg path {
    fill: #fff
}

.fancybox-share__input {
    background: 0 0;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%
}

.fancybox-thumbs {
    background: #ddd;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    width: 212px;
    z-index: 99995
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 7px
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, .1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px
}

.fancybox-thumbs__list a::before {
    border: 6px solid #ff5268;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
    z-index: 99991
}

.fancybox-thumbs__list a:focus::before {
    opacity: .5
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
    opacity: 1
}

@media all and (max-width:576px) {
    .fancybox-thumbs {
        width: 110px
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 110px
    }
    .fancybox-thumbs__list a {
        max-width: calc(100% - 10px)
    }
}
@font-face {
    font-family: "flaticon";
    src: url(/static/media/flaticon.3f54283b.ttf) format("truetype"), url(/static/media/flaticon.3f54283b.ttf) format("woff"), url(/static/media/flaticon.3f54283b.ttf) format("woff2"), url(/static/media/flaticon.3f54283b.ttf) format("embedded-opentype"), url(/static/media/flaticon.3f54283b.ttf) format("svg");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-file:before {
    content: "\f101";
}

.flaticon-sign-out:before {
    content: "\f102";
}

.flaticon-send:before {
    content: "\f103";
}

.flaticon-love:before {
    content: "\f104";
}

.flaticon-location:before {
    content: "\f105";
}

.flaticon-quote:before {
    content: "\f106";
}

.flaticon-search:before {
    content: "\f107";
}

.flaticon-volunteer:before {
    content: "\f108";
}

.flaticon-bag:before {
    content: "\f109";
}

.flaticon-notification:before {
    content: "\f10a";
}

.flaticon-bell:before {
    content: "\f10b";
}

.flaticon-search-1:before {
    content: "\f10c";
}

.flaticon-speech-bubble:before {
    content: "\f10d";
}

.flaticon-verified:before {
    content: "\f10e";
}

.flaticon-money-box:before {
    content: "\f10f";
}

.flaticon-email:before {
    content: "\f110";
}

.flaticon-facebook:before {
    content: "\f111";
}

.flaticon-home:before {
    content: "\f112";
}

.flaticon-check:before {
    content: "\f113";
}

.flaticon-edit:before {
    content: "\f114";
}

.flaticon-quote-1:before {
    content: "\f115";
}

.flaticon-email-1:before {
    content: "\f116";
}

.flaticon-application:before {
    content: "\f117";
}

.flaticon-up-arrow:before {
    content: "\f118";
}

.flaticon-pin:before {
    content: "\f119";
}

.flaticon-phone-call:before {
    content: "\f11a";
}

.flaticon-mail:before {
    content: "\f11b";
}

.flaticon-linkedin:before {
    content: "\f11c";
}

.flaticon-instagram:before {
    content: "\f11d";
}

.flaticon-twitter:before {
    content: "\f11e";
}

.flaticon-facebook-1:before {
    content: "\f11f";
}

.flaticon-check-mark:before {
    content: "\f120";
}

.flaticon-heart:before {
    content: "\f121";
}

.flaticon-campaign:before {
    content: "\f122";
}

.flaticon-quotes:before {
    content: "\f123";
}

.flaticon-calendar:before {
    content: "\f124";
}

.flaticon-coin:before {
    content: "\f125";
}

.flaticon-user:before {
    content: "\f126";
}

.flaticon-next:before {
    content: "\f127";
}

.flaticon-back:before {
    content: "\f128";
}

.flaticon-plus:before {
    content: "\f129";
}

.flaticon-phone-call-1:before {
    content: "\f12a";
}

.flaticon-email-2:before {
    content: "\f12b";
}

.flaticon-pin-1:before {
    content: "\f12c";
}

.flaticon-call:before {
    content: "\f12d";
}

.flaticon-tick:before {
    content: "\f12e";
}

.flaticon-secure-shield:before {
    content: "\f12f";
}

.flaticon-telephone:before {
    content: "\f130";
}

.flaticon-right-quote:before {
    content: "\f131";
}

.flaticon-search-2:before {
    content: "\f132";
}

.flaticon-right-arrow:before {
    content: "\f133";
}

.flaticon-left-arrow:before {
    content: "\f134";
}

.flaticon-email-3:before {
    content: "\f135";
}

.flaticon-right-arrow-1:before {
    content: "\f136";
}

.flaticon-share:before {
    content: "\f137";
}

.flaticon-insurance:before {
    content: "\f138";
}

.flaticon-health-care:before {
    content: "\f139";
}

.flaticon-right-quote-sign:before {
    content: "\f13a";
}

.flaticon-minus-sign:before {
    content: "\f13b";
}

.flaticon-quotation-mark:before {
    content: "\f13c";
}

.flaticon-play-button-arrowhead:before {
    content: "\f13d";
}

.flaticon-play-button:before {
    content: "\f13e";
}

.flaticon-badge:before {
    content: "\f13f";
}

.flaticon-exit:before {
    content: "\f140";
}

.flaticon-shopping-cart:before {
    content: "\f141";
}

.flaticon-quote-2:before {
    content: "\f142";
}

.flaticon-quote-3:before {
    content: "\f143";
}

.flaticon-user-1:before {
    content: "\f144";
}

.flaticon-heart-1:before {
    content: "\f145";
}

.flaticon-user-2:before {
    content: "\f146";
}

.flaticon-check-1:before {
    content: "\f147";
}

.flaticon-clock:before {
    content: "\f148";
}

.flaticon-straight-quotes:before {
    content: "\f149";
}

.flaticon-time:before {
    content: "\f14a";
}

.flaticon-support:before {
    content: "\f14b";
}

.flaticon-send-1:before {
    content: "\f14c";
}

.flaticon-play:before {
    content: "\f14d";
}

.flaticon-loupe:before {
    content: "\f14e";
}

.flaticon-checked:before {
    content: "\f14f";
}

.flaticon-invisible:before {
    content: "\f150";
}

.flaticon-done:before {
    content: "\f151";
}

.flaticon-down-arrow:before {
    content: "\f152";
}

$clr_white: #fff;
$clr_offwhite: rgba(255,255,255,0.8);
$clr_black: #000;
$clr_emperor: #666666;
$clr_selago: #F4F7FE;
$clr_yellow: #FFC422;
$clr_gray: #888888;
$clr_albastor: #f9f9f9;
$clr_athens: #F0F4FF;
$clr_sand: #F5F5F5;
$clr_red:#FF3838;
$clr_lochi:#2490EB;
$clr_lochi:#338F7A;
$clr_jaffa:#F59946;
$clr_cod:#181818;

@mixin transform($tr_amt) {
    transform: $tr_amt;
}

@mixin transform-origin($trl_amt) {
    transform-origin: $trl_amt;
    -webkit-transform-origin: $trl_amt;
    -moz-transform-origin: $trl_amt;
    -ms-transform-origin: $trl_amt;
    -o-transform-origin: $trl_amt;
}

@mixin transform-style($trm_amt) {
    transform-style: $trm_amt;
    -webkit-transform-style: $trm_amt;
    -moz-transform-style: $trm_amt;
    -ms-transform-style: $trm_amt;
    -o-transform-style: $trm_amt;
}
@mixin transition($time) {
    transition: $time;
}

@mixin transition($time) {
    transition: $time;
}
%flex {
    display: flex;
    flex-wrap: wrap;

}
%align_center {
    @extend %flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

.footer-wrap{
    position: relative;
    background-color: $clr_lochi;
    .footer-top{
        background-color: $clr_cod;
        position: relative;
        z-index: 1;
        .footer-shape-one,
        .footer-shape-two,
        .footer-shape-three,
        .footer-shape-four{
            position: absolute;
            z-index: -1;
        }
        .footer-shape-one{
            bottom: 0;
            left: 15px;
        }
        .footer-shape-two{
            top: 20px;
            right: 45px;
        }
        .footer-shape-three{
            top: 120px;
            right: 0;
        }
        .footer-shape-four{
            top: 60px;
            right: 0;
        }
    }
    .footer-bottom{
        background-color: $clr_black;
    }
    .footer-widget{
        margin-bottom: 25px;
        .footer-widget-title{
            position: relative;
            font-size: 22px;
            font-weight: 600;
            color: $clr_white;
            margin-bottom: 36px;
            padding-bottom: 20px;
            &:before{
                position: absolute;
                bottom: 0px;
                left: 0;
                content: "";
                width: 100px;
                height: 2px;
                background-color: rgba(255,255,255,0.15);  
            }
            &:after{
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                width: 35px;
                height: 2px;
                background-color: $clr_jaffa;    
            }
        }
        .footer-logo{
            display: block;
        }
        .comp-desc{
            color: $clr_offwhite;
            margin:25px 0 30px 0;
            padding-bottom: 25px;
            position: relative;
            &:after{
                position: absolute;
                bottom: 0;
                left:0;
                content: "";
                width: 100%;
                height: 2px;
                background-color: rgba(255,255,255,0.15);
            }
            &:before{
                position: absolute;
                bottom: 0;
                left:0;
                content: "";
                width: 80px;
                height: 2px;
                background-color: $clr_jaffa;
            }
            
        }
        .newsletter-form{
            position: relative;
            input{
                width: 100%;
                height: 60px;
                border-radius: 10px 0 10px 0;
                padding: 13px 105px 12px 18px;
                color: $clr_white;
                border: none;
                background-color: rgba(255,255,255,0.15);
            }
            button{
                position: absolute;
                top: 0;
                right: 0;
                background-color: $clr_lochi;
                color: $clr_white;
                border: none;
                height: 100%;
                border-radius: 0 0 10px 0;
                padding: 12px 20px;
            }
        }
        
        .footer-menu{
            li{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    color: $clr_offwhite;
                    position: relative;
                    padding-left: 25px;
                    &:after{
                        position: absolute;
                        top: 8px;
                        left: 0;
                        content: "";
                        width: 16px;
                        height: 1px;
                        background-color: $clr_jaffa;
                        @include transition(0.4s);
                    }
                    &:hover{
                        color: $clr_jaffa;
                        padding-left: 28px;
                    }
                }
            }
        }
        .contact-info{
            li{
                position: relative;
                padding-left: 25px;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                h6{
                    color: $clr_white;
                    font-weight: 500;
                    margin: 0 0 5px;
                }
                i{
                    position: absolute;
                    top: 3px;
                    left: 0;
                    font-size: 16px;
                    line-height: 1;
                    color: $clr_jaffa;
                }
                p,a{
                    color: $clr_offwhite;
                    margin-bottom: 0;
                    @include transition(0.3s);
                }
                &:hover{
                    p,a{
                        color: $clr_jaffa;
                    }
                }
            }
        }
    }
}
.footer-bottom{
    padding: 18px 10px;
    .copyright-text{
        margin: 0;
        color: $clr_white;
        i{
            position: relative;
            top: 2px;
            margin-right: 2px;
        }
        span{
            color: $clr_lochi;
        }
        a{
            color: $clr_lochi;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .social-profile{
        text-align: right;
        li{
            a{
                background-color: $clr_lochi;
                color: $clr_white;
                font-size: 18px;
                i{
                    color: $clr_black;
                }
                &:hover{
                    background-color: $clr_jaffa;
                    @include transform(translateY(-2px));
                    i{
                        color: $clr_white;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:575px){
    .footer-wrap{
        .footer-bottom{
            .copyright-text{
                margin-bottom: 15px;
            }
            .copyright-text,
            .social-profile{
                text-align: center;
            }
        }
    }
}
@media only screen and (max-width:767px){
    .footer-wrap {
        .footer-top{
            .footer-shape-one{
                left: auto;
                right: 0px;
            }
            .footer-shape-two{
                right: 25px;
                top: 10px;
            }
            .footer-shape-four{
                right: 0px;
                top: 20px;
                max-width: 75px;
            }
        }
        .footer-widget {
            .footer-widget-title {
                font-size: 20px;
                padding-bottom: 5px;
                margin-bottom: 15px;
            }
            .comp-desc{
                font-size: 14px;
                margin: 15px 0 22px;
                padding-bottom: 15px;
            }
            .contact-info{
                li{
                    margin-bottom: 10px;
                }
                p,a{
                    font-size: 14px;
                }
            }
            .footer-menu{
                li{
                    margin-bottom: 8px;
                    a{
                        font-size: 14px;
                        i{
                            font-size: 14px;
                            top: -3px;
                        }
                    }
                }
            }
        }
        .footer-bottom{
            .social-profile{
                li{
                    a{
                        width: 30px;
                        height: 30px;
                        i{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and  (max-width:991px){
    .footer-wrap {
        .footer-top {
            .footer-shape-one {
                max-width: 140px;
            }
            .footer-shape-two {
                max-width: 70px;
            }
            .footer-shape-three {
                max-width: 70px;
            }
        }
        .footer-widget {
            .footer-widget-title {
                margin-bottom: 22px;
                padding-bottom: 15px;
            }
            .newsletter-form{
                input,button{
                    font-size: 14px;
                }
                input{
                    height: 55px;
                    padding:12px 130px 12px 15px;
                }
                button{
                    padding: 12px 16px;
                }
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width:991px){
    .footer-wrap {
        .footer-widget {
            .footer-widget-title {
                font-size: 20px;
            }
        }
        
    }
}

@media only screen  and (min-width:992px) and (max-width:1199px){
    .footer-wrap{
        .footer-top {
            .footer-shape-four{
                top: 40px;
            }
            .footer-widget {
                .newsletter-form{
                    input,button{
                        font-size: 14px;
                    }
                    input{
                        height: 55px;
                        padding:12px 130px 12px 15px;
                    }
                    button{
                        padding: 12px 16px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:1200px) and (max-width:1399px){
    .footer-menu li a,
    .comp-desc,
    .footer-wrap .footer-widget .contact-info li p,
    .footer-wrap .footer-widget .contact-info li a{
        font-size: 15px;
    }
    .footer-wrap .footer-widget .footer-widget-title {
        font-size: 20px;
    }
}

$clr_white: #fff;
$clr_offwhite: rgba(255,255,255,0.8);
$clr_black: #000;
$clr_emperor: #666666;
$clr_selago: #F4F7FE;
$clr_yellow: #FFC422;
$clr_gray: #888888;
$clr_albastor: #f9f9f9;
$clr_athens: #F0F4FF;
$clr_sand: #F5F5F5;
$clr_red:#FF3838;
$clr_lochi:#2490EB;
$clr_lochi:#338F7A;
$clr_jaffa:#F59946;


@mixin transition($time) {
    transition: $time;
}
@mixin transform($tr_amt) {
    transform: $tr_amt;
}
%flex {
    display: flex;
    flex-wrap: wrap;
}
%flex_column_center {
    @extend %flex;
    flex-direction: column;
    justify-content: center;
}
%align_center {
    @extend %flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

%flex_justify_end {
    @extend %flex;
    justify-content: flex-end;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}
@keyframes toBottomFromTop10 {
    49% {
        transform:translateY(-10%)
    }
    50% {
        opacity:0;
        transform:translateY(10%)
    }
    51% {
        opacity:1
    }
}
.fadeInDown {
    animation-name: fadeInDown
}
.header-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    @include transition(0.4s);
    &.sticky {
        position: fixed;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        @include transition(0.4s);
        background: $clr_white;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        width: 100%;
        top: -54px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1); 
    }
    .header-top {
        padding: 12px 0 12px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        .header-top-left {
            @extend %flex;
            @extend %align_center;
            .contact-info{
                @extend %align_center;
                li{
                    margin-right: 20px;
                    padding-right: 20px;
                    position: relative;
                    @extend %align_center;
                    border-right: 1px solid rgba(0, 0, 0, 0.2);
                    &:last-child{
                        margin-right: 0;
                        padding-right: 0;
                        border-right: none;
                    }
                    i{
                        font-size: 16px;
                        line-height: 0.8;
                        color: $clr_lochi;
                        @include transition(0.4s);
                    }
                    a,p{
                        display: inline-block;
                        margin-left: 8px;
                        margin-bottom: 0;
                        font-size: 15px;
                        color: $clr_emperor;
                        @include transition(0.4s);
                        &:hover{
                            color: $clr_lochi;
                        }
                    }
                }
            }
        }
        .header-top-right {
            @extend %align_center;
            @extend %flex_justify_end;
            .social-profile{
                text-align: right;
                margin-left: 15px;
                position: relative;
                padding-left: 15px;
                border-left: 1px solid rgba(0, 0, 0, 0.19);
                &:before{
                    position: absolute;
                    top: 50%;
                    left: -2px;
                    content: "";
                    width: 1px;
                    height: 25px;
                    background-color: rgba(255,255,255,0.35);
                    @include transform(translateY(-50%));
                }
                li{
                    a{
                        width: 30px;
                        height: 30px;
                        background-color: rgba(0, 0, 0, 0.2);
                        i{
                            font-size: 14px;
                            color: $clr-white;
                        }
                        &:hover{
                            background-color: $clr_lochi;
                        }
                    }
                }
            }
        }
    }
    .header-bottom {
        background-color: $clr_white;
        @include transition(0.4s);
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
        .container{
            position: relative;
        }
    }
}
.searchbox{
    position: relative;
    input{
        width: 100%;
        height: 54px;
        background-color: rgba(0, 0, 0, .06);
        padding: 12px 30px 12px 18px;
        border: none;
        border-radius: 15px 0 15px 0;
    }
    button{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
        padding: 5px 20px 5px 5px;
        height: 100%;
        i{
            color: $clr_black;
            position: relative;
            top: 4px;
        }
    }
}
.searchbtn{
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    top: 2px;
   
    i{
        font-size: 20px;
        line-height: 1;
    }
}
.search-area{
    position: absolute;
    top: 85px;
    right: 12px;
    background-color: $clr_white;
    padding: 15px 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 320px;
    visibility: hidden;
    opacity: 0;
    border-radius: 0 0 5px 5px;
    @include transition(0.4s);
    &.open{
        visibility: visible;
        opacity: 1;
        top: 78px;
    }
    input{
        width: 100%;
        height: 58px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 15px 15px;
        background-color: transparent;
        color: $clr_black;
    }
    button{
        position: absolute;
        top: 35px;
        right: 22px;
        border: none;
        background-color: transparent;
        i{
            font-size: 20px;
            opacity: 0.5;
            line-height: 0.8;
        }
    }
}
.select-lang {
    padding-left: 18px;
    position: relative;
    i {
        position: absolute;
        top: 4px;
        left: 0;
        font-size: 15px;
        line-height: 1;
        color: $clr_lochi;
    }
    .navbar-language {
        .dropdown-toggle {
            background-color: transparent;
            border: none;
            font-size: 15px;
            padding-right: 16px;
            color: $clr_emperor;
            @extend %flex;
            @extend %align_center;
            @include transition(0.3s);
            &:after {
                display: none;
            }
            &:before {
                position: absolute;
                top: 0px;
                right: -2px;
                content: "\EA4E";
                font-family: "remixicon";
                font-weight: 400;
                border: 0;
                margin-left: 8px;
                font-size: 16px;
            }
        }
        .dropdown-menu {
            margin: 0;
            padding: 5px 0;
            box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
            left: 0;
            right: auto;
            border: 0;
            border-radius: 0;
            transform: translateY(75px) !important;
            display: block;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s linear;
            a {
                padding: 5px 10px;
                transition: all 0.3s linear;
                font-size: 15px;

                img {
                    width: 20px;
                    margin-right: 10px;
                }
            }
            &.show {
                transform: translateY(40px) !important;
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .header-wrap .header-top .header-top-left .contact-item p,
    .header-wrap .header-top .header-top-left .contact-item a,
    .header-wrap .header-top .header-top-right .select-lang .navbar-language .dropdown-toggle{
        font-size: 14px!important;
    }
    .header-wrap{
        &.sticky{
            top: -171px;
        }
        .navbar-brand {
            img {
                max-width: 120px;
            }
        }
        .header-top{
            padding: 15px 0;
            background-color: rgba(51, 143, 122, 0.09);
            border: none;
            .header-top-left{
                margin-bottom: 10px;
                .contact-info{
                    width: 100%;
                    li{
                        text-align: center;
                        margin-bottom: 10px;
                        justify-content: center;
                        width: 100%;
                        border: none;
                        span{
                            top: 0px;
                            width: 25px;
                            height: 25px;
                            i{
                                font-size: 14px;
                            }
                        }
                        a,p{
                            font-size: 14px;
                        }
                    }
                }
            }
            .header-top-right{
                justify-content: center;
                .select-lang{
                    i{
                        top: 4px;
                    }
                }
                .social-profile{
                    li{
                        a{
                            width: 34px;
                            height: 34px;
                        }
                    }
                }
            }
        }
    }
    .search-area{
        width: 100%;
        right: 0;
        &.open{
            top: 76px;
        }
        input{
            height: 52px;
            font-size: 14px;
            padding: 10px 15px;
        }
        button{
            top: 32px;
            i{
                font-size: 18px;
            }
        }
    }
}
@media only screen and (min-width:992px) and (max-width: 1199px) {
    .header-wrap{
        &.sticky {
            top: -55px;
        }
        .header-top{
            .header-top-left{
                .contact-info{
                    li{
                        i{
                            font-size: 15px;
                        }
                        a,p{
                            font-size: 14px;
                        }
                    }
                }
            }
            .header-top-right{
                .select-lang{
                    span{
                        font-size: 14px;
                    }
                }
            }
        }
        .header-bottom{
            .other-options{
                .btn{
                    font-size: 15px;
                    padding: 12px 20px;
                }
            }
        }
        .navbar {
            .navbar-nav {
                .nav-item {
                    margin: 0 9px!important;
                    a {
                        font-size: 16px!important;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px)  and (max-width: 991px) {
    .header-wrap{
        &.sticky {
            top: -50px;
        }
        .header-top{
            padding: 12px 0;
            .header-top-left{
                .contact-info{
                    li{
                        margin-right: 8px;
                        padding-right: 8px;
                        border-right: none;
                        i{
                            font-size: 12px;
                        }
                        a,p{
                            font-size: 13px;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .header-top-right {
                .select-lang{
                    .dropdown-toggle{
                        span{
                            font-size: 13px;
                        }
                    }
                }
                .social-profile{
                    margin-left: 10px;
                    padding-left: 10px;
                    li{
                        margin: 0 3px;
                        a{
                            width: 25px;
                            height: 25px;
                            i{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .header-top-left,
            .header-top-right{
                justify-content: center;
            }
        }
        .navbar-brand{
            img{
                max-width: 140px;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .header-wrap .header-top .header-top-left .contact-item {
        width: 100%;
        margin: 0 0 15px;
    }
    .header-wrap .header-top .header-top-left .contact-item p,
    .header-wrap .header-top .header-top-left .contact-item a {
        color: $clr_black;
        font-size: 14px;
        font-weight: 400;
    }
    .header-wrap {
        .header-bottom{
            .container{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                .search-area{
                    &.open {
                        top: 76px;
                    }
                }
            }
            .navbar{
                .navbar-collapse{
                    -webkit-box-align: start;
                    -ms-flex-align: start;
                    -ms-grid-row-align: flex-start;
                    align-items: flex-start;
                }
                .navbar-nav{
                    width: 100%;
                    flex-direction: column;
                    .nav-item{
                        position: relative;
                        .menu-expand {
                            position: absolute;
                            right: 0;
                            top: 14px;
                            cursor: pointer;
                            i {
                                color: $clr_black;
                                font-size: 18px;
                                line-height: 1;
                                position: relative;
                                top: 7px;
                            }
                        }
                        a{
                            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                            padding-top:15px;
                            padding-bottom:15px;
                            i{
                                display: none;
                            }
                            &.active{
                                color: $clr_lochi;
                            }
                            &.btn{
                                display: inline-block;
                                padding: 10px 30px 10px;
                                color: $clr_white;
                                margin-top: 15px;
                                font-weight: 500;
                                font-size: 14px;
                                i{
                                    display: inline-block;
                                    top: 6px;
                                }
                            }
                        }
                        .dropdown-menu{
                            width: 100%;
                            background-color: #EDF5F3;
                            border: none;
                            padding:0 ;
                            li{
                                a{
                                    font-size: 14px;
                                    padding-left: 10px;
                                    margin-left: 10px;
                                    &.active{
                                        color: $clr_lochi;
                                    }
                                }
                            }
                            .nav-item{
                                .dropdown-menu{
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .user-login{
                span {
                    position: relative;
                    top: -3px;
                        i {
                        font-size: 20px;
                        }
                }
            }
        }
    }
    .mobile-bar-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        position: relative;
        top: 4px;
    }
    .menu-close {
        position: absolute;
        top: 13px;
        right: 16px;

        i {
            color: $clr_black;
            font-size: 25px;
        }
    }
    .mobile-menu {
        text-align: right;
        margin-left: 20px;
        a {
            width: 38px;
            height: 38px;
            border-radius: 62% 38% 46% 54% / 60% 63% 37% 40%;
            text-align: center;
            background-color: $clr_lochi;
            display: flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            i {
                font-size: 22px;
                color: $clr_white;
                margin: 0 auto;
            }
        }
    }
    .mobile-menu {
        display: inline-block;
        position: relative;
        top: -5px;
    }
    .mobile-top-bar {
        display: inline-block;
        margin-right: 6px;
        cursor: pointer;
        position: relative;
        top: 0px;
        span {
            display: block;
            width: 20px;
            height: 2px;
            background-color: $clr_black;
            border-radius: 20px;
            margin: 5.9px 0;
            &:nth-child(1) {
                margin-top: 0;
            }
            &:nth-child(2) {
                width: 12px;
                margin-left: 8px;
            }
        }
    }
    .main-menu-wrap {
        width: 100%;
        position: fixed;
        background: #EDF5F3;
        z-index: 999;
        top: 0;
        height: 100vh;
        transition: 0.5s;
        left: -100%;
        padding: 55px 20px 30px;
        overflow-y: auto;
        @include transition(0.4s);

        &.open {
            left: 0;
        }
    }
    .main-menu {
        & > li {
            &.has-children {
                &.menu-open {
                    & > span.menu-expand {
                        transform: rotate(180deg);

                        i {
                            color: $clr_black;
                        }
                    }
                }
            }
            & > ul {
                & > li {
                    &.has-children {
                        &.menu-open {
                            & > span.menu-expand {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
        li {
            position: relative;
            &:last-child {
                margin: 0;
            }
            & > span {
                &.menu-expand {
                    position: absolute;
                    right: 0;
                    top: 13px;
                    cursor: pointer;
                    i {
                        color: $clr_black;
                        font-size: 18px;
                    }
                    &:hover {
                        color: $clr_black;
                    }
                }
            }

            a {
                &.active {
                    color: $clr_lochi;
                }
            }

            & > a {
                font-size: 14px;
                font-weight: 400;
                color: $clr_black;
                text-transform: capitalize;
                display: block;
                padding: 12px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);

                &:hover {
                    color: $clr_black;
                }
            }

            & > ul.sub-menu {
                padding-left: 20px;
            }
        }
    }
    .header-wrap .header-bottom .main-menu-wrap #menu > ul > li > a::after {
        display: none;
    }
    .main-menu li a.active {
        font-weight: 600;
        color: $clr_black;
        border-color: rgba(0, 0, 0, 0.3);
    }
}

@media only screen and (min-width: 992px) {
    .menu-expand i {
        display: none;
    }
    .header-wrap{
        .navbar{
            padding: 0;
            .navbar-brand{
                padding: 0 20px 0 0;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;
            }
            .navbar-nav{
                margin-left: auto;
                .nav-item{
                    position: relative;
                    margin: 0 10px;
                    &.first-child{
                        margin-left: 0;
                    }
                    a{
                        color:$clr_black;
                        font-weight: 500;
                        padding: 30px 0;
                        @include transition(0.4s);
                        position: relative;
                        &:hover,
                        &:focus,
                        &.active{
                            color: $clr_lochi;
                        }
                        i {
                            font-size: 16px;
                            line-height: 1;
                            position: relative;
                            top: 4px;
                            display: inline-block;
                            margin-left: -3px;
                            @include transition(0.4s);
                        }
                        &:hover{
                            i{
                                @include transform(rotate(180deg));
                            }
                        }
                    }
                    .dropdown-menu {
                        display: block!important;
                        border: none;
                        top: 83px;
                        left: -23px;
                        z-index: 99;
                        opacity: 0;
                        width: 250px;
                        display: block;
                        border-radius: 0;
                        padding: 12px 0;
                        position: absolute;
                        visibility: hidden;
                        box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
                        @include transition(0.3s);
                        @include transform(rotateX(-75deg));
                        @include transition(0.5s);
                        transform-style: preserve-3d;
                        transform-origin: 0% 0%;
                        li {
                            margin: 0;
                            a{
                                padding: 10px 20px;
                                position: relative;
                                display: block;
                                font-weight: 500;
                                @include transition(0.3s);
                                &:before{
                                    position: absolute;
                                    top: 50%;
                                    left: 0px;
                                    content: "";
                                    width: 0;
                                    height: 2px;
                                    background-color: $clr_lochi;
                                    visibility: hidden;
                                    opacity: 0;
                                    @include transform(translateY(-50%));
                                    @include transition(0.4s);
                                }
                                &.active,
                                &:hover{
                                    color: $clr_lochi;
                                    &:before{
                                        width: 20px;
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }
                                &.active,
                                &:hover{
                                    padding-left: 30px;
                                }
                                &:hover,
                                &:focus,
                                &.active{
                                    width: 100%;
                                    right: auto;
                                    left: 0;
                                }
                                 i {
                                    margin: 0;
                                    position: absolute;
                                    top: 55%;
                                    font-size: 16px;
                                    line-height: 1;
                                    @include  transform(translateY(-50%)); 
                                    right: 15px;
                                }
                            }
                            .dropdown-menu{
                                top: 0;
                                opacity: 0;
                                left: -250px;
                                right: auto;
                                visibility: hidden;
                            }
                            &:hover{
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    &:hover{
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            @include transform(rotatex(0deg));
                        }
                    }
                }
            }
            .other-options{
                display: flex;
                align-items:center;
                position: relative;
                .option-item{
                    margin: 0 0 0 20px;
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .navbar-brand{
        img{
            max-width: 140px;
        }
    }
}
@media only screen and (min-width: 1400px) {
    .header-wrap{
        .navbar {
            .navbar-nav {
                .nav-item {
                    margin: 0 15px;
                    a{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    .header-wrap {
        .container {
            max-width: 1520px;
        }
    }
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    position: relative
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    position: relative
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    visibility: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    transform: translateZ(0)
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    transition: transform 2s
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    transform: translateY(-100%)
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    transform: translateY(-100%)
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    transition: transform 2s;
    transform: translateY(0)
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    line-height: 1
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
    text-align: center
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */

 .owl-theme .owl-dots,
 .owl-theme .owl-nav {
     text-align: center;
     -webkit-tap-highlight-color: transparent
 }
 
 .owl-theme .owl-nav {
     margin-top: 10px
 }
 
 .owl-theme .owl-nav [class*=owl-] {
     color: #FFF;
     font-size: 14px;
     margin: 5px;
     padding: 4px 7px;
     background: #D6D6D6;
     display: inline-block;
     cursor: pointer;
     border-radius: 3px
 }
 
 .owl-theme .owl-nav [class*=owl-]:hover {
     background: #869791;
     color: #FFF;
     text-decoration: none
 }
 
 .owl-theme .owl-nav .disabled {
     opacity: .5;
     cursor: default
 }
 
 .owl-theme .owl-nav.disabled+.owl-dots {
     margin-top: 10px
 }
 
 .owl-theme .owl-dots .owl-dot {
     display: inline-block;
     zoom: 1
 }
 
 .owl-theme .owl-dots .owl-dot span {
     width: 10px;
     height: 10px;
     margin: 5px 7px;
     background: #D6D6D6;
     display: block;
     -webkit-backface-visibility: visible;
     transition: opacity .2s ease;
     border-radius: 30px
 }
 
 .owl-theme .owl-dots .owl-dot.active span,
 .owl-theme .owl-dots .owl-dot:hover span {
     background: #869791
 }
/**
 * Owl Carousel v2.3.owl.video.play.png
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */

 .owl-carousel,
 .owl-carousel .owl-item {
     -webkit-tap-highlight-color: transparent;
     position: relative
 }
 
 .owl-carousel {
     display: none;
     width: 100%;
     z-index: 1
 }
 
 .owl-carousel .owl-stage {
     position: relative;
     touch-action: manipulation;
     -moz-backface-visibility: hidden
 }
 
 .owl-carousel .owl-stage:after {
     content: ".";
     display: block;
     clear: both;
     visibility: hidden;
     line-height: 0;
     height: 0
 }
 
 .owl-carousel .owl-stage-outer {
     position: relative;
     overflow: hidden;
     -webkit-transform: translate3d(0, 0, 0)
 }
 
 .owl-carousel .owl-item,
 .owl-carousel .owl-wrapper {
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
     -ms-transform: translate3d(0, 0, 0)
 }
 
 .owl-carousel .owl-item {
     min-height: 1px;
     float: left;
     -webkit-backface-visibility: hidden;
     -webkit-touch-callout: none
 }
 
 .owl-carousel .owl-item img {
     display: block;
     width: 100%
 }
 
 .owl-carousel .owl-dots.disabled,
 .owl-carousel .owl-nav.disabled {
     display: none
 }
 
 .no-js .owl-carousel,
 .owl-carousel.owl-loaded {
     display: block
 }
 
 .owl-carousel .owl-dot,
 .owl-carousel .owl-nav .owl-next,
 .owl-carousel .owl-nav .owl-prev {
     cursor: pointer;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none
 }
 
 .owl-carousel .owl-nav button.owl-next,
 .owl-carousel .owl-nav button.owl-prev,
 .owl-carousel button.owl-dot {
     background: 0 0;
     color: inherit;
     border: none;
     padding: 0 !important;
     font: inherit
 }
 
 .owl-carousel.owl-loading {
     opacity: 0;
     display: block
 }
 
 .owl-carousel.owl-hidden {
     opacity: 0
 }
 
 .owl-carousel.owl-refresh .owl-item {
     visibility: hidden
 }
 
 .owl-carousel.owl-drag .owl-item {
     touch-action: pan-y;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none
 }
 
 .owl-carousel.owl-grab {
     cursor: move;
     cursor: grab
 }
 
 .owl-carousel.owl-rtl {
     direction: rtl
 }
 
 .owl-carousel.owl-rtl .owl-item {
     float: right
 }
 
 .owl-carousel .animated {
     animation-duration: 1s;
     animation-fill-mode: both
 }
 
 .owl-carousel .owl-animated-in {
     z-index: 0
 }
 
 .owl-carousel .owl-animated-out {
     z-index: 1
 }
 
 .owl-carousel .fadeOut {
     animation-name: fadeOut
 }
 
 @keyframes fadeOut {
     0% {
         opacity: 1
     }
     100% {
         opacity: 0
     }
 }
 
 .owl-height {
     transition: height .5s ease-in-out
 }
 
 .owl-carousel .owl-item .owl-lazy {
     opacity: 0;
     transition: opacity .4s ease
 }
 
 .owl-carousel .owl-item .owl-lazy:not([src]),
 .owl-carousel .owl-item .owl-lazy[src^=""] {
     max-height: 0
 }
 
 .owl-carousel .owl-item img.owl-lazy {
     transform-style: preserve-3d
 }
 
 .owl-carousel .owl-video-wrapper {
     position: relative;
     height: 100%;
     background: #000
 }
 
 .owl-carousel .owl-video-play-icon {
     position: absolute;
     height: 80px;
     width: 80px;
     left: 50%;
     top: 50%;
     margin-left: -40px;
     margin-top: -40px;
     /* background: url(owl.video.play.png) no-repeat; */
     cursor: pointer;
     z-index: 1;
     -webkit-backface-visibility: hidden;
     transition: transform .1s ease
 }
 
 .owl-carousel .owl-video-play-icon:hover {
     transform: scale(1.3, 1.3)
 }
 
 .owl-carousel .owl-video-playing .owl-video-play-icon,
 .owl-carousel .owl-video-playing .owl-video-tn {
     display: none
 }
 
 .owl-carousel .owl-video-tn {
     opacity: 0;
     height: 100%;
     background-position: center center;
     background-repeat: no-repeat;
     background-size: contain;
     transition: opacity .4s ease
 }
 
 .owl-carousel .owl-video-frame {
     position: relative;
     z-index: 1;
     height: 100%;
     width: 100%
 }
@font-face {
    font-family: remixicon;
    src: url(/static/media/remixicon.9915fef9.woff2);
    src: url(/static/media/remixicon.9915fef9.woff2) format('embedded-opentype'), url(/static/media/remixicon.9915fef9.woff2) format("woff2"), url(/static/media/remixicon.9915fef9.woff2) format("woff"), url(/static/media/remixicon.9915fef9.woff2) format('truetype'), url(/static/media/remixicon.9915fef9.woff2) format('svg');
    font-display: swap
}

[class*=" ri-"],
[class^=ri-] {
    font-family: remixicon !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.ri-lg {
    font-size: 1.3333em;
    line-height: .75em;
    vertical-align: -.0667em
}

.ri-xl {
    font-size: 1.5em;
    line-height: .6666em;
    vertical-align: -.075em
}

.ri-xxs {
    font-size: .5em
}

.ri-xs {
    font-size: .75em
}

.ri-sm {
    font-size: .875em
}

.ri-1x {
    font-size: 1em
}

.ri-2x {
    font-size: 2em
}

.ri-3x {
    font-size: 3em
}

.ri-4x {
    font-size: 4em
}

.ri-5x {
    font-size: 5em
}

.ri-6x {
    font-size: 6em
}

.ri-7x {
    font-size: 7em
}

.ri-8x {
    font-size: 8em
}

.ri-9x {
    font-size: 9em
}

.ri-10x {
    font-size: 10em
}

.ri-fw {
    text-align: center;
    width: 1.25em
}

.ri-24-hours-fill:before {
    content: "\ea01"
}

.ri-24-hours-line:before {
    content: "\ea02"
}

.ri-4k-fill:before {
    content: "\ea03"
}

.ri-4k-line:before {
    content: "\ea04"
}

.ri-a-b:before {
    content: "\ea05"
}

.ri-account-box-fill:before {
    content: "\ea06"
}

.ri-account-box-line:before {
    content: "\ea07"
}

.ri-account-circle-fill:before {
    content: "\ea08"
}

.ri-account-circle-line:before {
    content: "\ea09"
}

.ri-account-pin-box-fill:before {
    content: "\ea0a"
}

.ri-account-pin-box-line:before {
    content: "\ea0b"
}

.ri-account-pin-circle-fill:before {
    content: "\ea0c"
}

.ri-account-pin-circle-line:before {
    content: "\ea0d"
}

.ri-add-box-fill:before {
    content: "\ea0e"
}

.ri-add-box-line:before {
    content: "\ea0f"
}

.ri-add-circle-fill:before {
    content: "\ea10"
}

.ri-add-circle-line:before {
    content: "\ea11"
}

.ri-add-fill:before {
    content: "\ea12"
}

.ri-add-line:before {
    content: "\ea13"
}

.ri-admin-fill:before {
    content: "\ea14"
}

.ri-admin-line:before {
    content: "\ea15"
}

.ri-advertisement-fill:before {
    content: "\ea16"
}

.ri-advertisement-line:before {
    content: "\ea17"
}

.ri-airplay-fill:before {
    content: "\ea18"
}

.ri-airplay-line:before {
    content: "\ea19"
}

.ri-alarm-fill:before {
    content: "\ea1a"
}

.ri-alarm-line:before {
    content: "\ea1b"
}

.ri-alarm-warning-fill:before {
    content: "\ea1c"
}

.ri-alarm-warning-line:before {
    content: "\ea1d"
}

.ri-album-fill:before {
    content: "\ea1e"
}

.ri-album-line:before {
    content: "\ea1f"
}

.ri-alert-fill:before {
    content: "\ea20"
}

.ri-alert-line:before {
    content: "\ea21"
}

.ri-aliens-fill:before {
    content: "\ea22"
}

.ri-aliens-line:before {
    content: "\ea23"
}

.ri-align-bottom:before {
    content: "\ea24"
}

.ri-align-center:before {
    content: "\ea25"
}

.ri-align-justify:before {
    content: "\ea26"
}

.ri-align-left:before {
    content: "\ea27"
}

.ri-align-right:before {
    content: "\ea28"
}

.ri-align-top:before {
    content: "\ea29"
}

.ri-align-vertically:before {
    content: "\ea2a"
}

.ri-alipay-fill:before {
    content: "\ea2b"
}

.ri-alipay-line:before {
    content: "\ea2c"
}

.ri-amazon-fill:before {
    content: "\ea2d"
}

.ri-amazon-line:before {
    content: "\ea2e"
}

.ri-anchor-fill:before {
    content: "\ea2f"
}

.ri-anchor-line:before {
    content: "\ea30"
}

.ri-ancient-gate-fill:before {
    content: "\ea31"
}

.ri-ancient-gate-line:before {
    content: "\ea32"
}

.ri-ancient-pavilion-fill:before {
    content: "\ea33"
}

.ri-ancient-pavilion-line:before {
    content: "\ea34"
}

.ri-android-fill:before {
    content: "\ea35"
}

.ri-android-line:before {
    content: "\ea36"
}

.ri-angularjs-fill:before {
    content: "\ea37"
}

.ri-angularjs-line:before {
    content: "\ea38"
}

.ri-anticlockwise-2-fill:before {
    content: "\ea39"
}

.ri-anticlockwise-2-line:before {
    content: "\ea3a"
}

.ri-anticlockwise-fill:before {
    content: "\ea3b"
}

.ri-anticlockwise-line:before {
    content: "\ea3c"
}

.ri-app-store-fill:before {
    content: "\ea3d"
}

.ri-app-store-line:before {
    content: "\ea3e"
}

.ri-apple-fill:before {
    content: "\ea3f"
}

.ri-apple-line:before {
    content: "\ea40"
}

.ri-apps-2-fill:before {
    content: "\ea41"
}

.ri-apps-2-line:before {
    content: "\ea42"
}

.ri-apps-fill:before {
    content: "\ea43"
}

.ri-apps-line:before {
    content: "\ea44"
}

.ri-archive-drawer-fill:before {
    content: "\ea45"
}

.ri-archive-drawer-line:before {
    content: "\ea46"
}

.ri-archive-fill:before {
    content: "\ea47"
}

.ri-archive-line:before {
    content: "\ea48"
}

.ri-arrow-down-circle-fill:before {
    content: "\ea49"
}

.ri-arrow-down-circle-line:before {
    content: "\ea4a"
}

.ri-arrow-down-fill:before {
    content: "\ea4b"
}

.ri-arrow-down-line:before {
    content: "\ea4c"
}

.ri-arrow-down-s-fill:before {
    content: "\ea4d"
}

.ri-arrow-down-s-line:before {
    content: "\ea4e"
}

.ri-arrow-drop-down-fill:before {
    content: "\ea4f"
}

.ri-arrow-drop-down-line:before {
    content: "\ea50"
}

.ri-arrow-drop-left-fill:before {
    content: "\ea51"
}

.ri-arrow-drop-left-line:before {
    content: "\ea52"
}

.ri-arrow-drop-right-fill:before {
    content: "\ea53"
}

.ri-arrow-drop-right-line:before {
    content: "\ea54"
}

.ri-arrow-drop-up-fill:before {
    content: "\ea55"
}

.ri-arrow-drop-up-line:before {
    content: "\ea56"
}

.ri-arrow-go-back-fill:before {
    content: "\ea57"
}

.ri-arrow-go-back-line:before {
    content: "\ea58"
}

.ri-arrow-go-forward-fill:before {
    content: "\ea59"
}

.ri-arrow-go-forward-line:before {
    content: "\ea5a"
}

.ri-arrow-left-circle-fill:before {
    content: "\ea5b"
}

.ri-arrow-left-circle-line:before {
    content: "\ea5c"
}

.ri-arrow-left-down-fill:before {
    content: "\ea5d"
}

.ri-arrow-left-down-line:before {
    content: "\ea5e"
}

.ri-arrow-left-fill:before {
    content: "\ea5f"
}

.ri-arrow-left-line:before {
    content: "\ea60"
}

.ri-arrow-left-right-fill:before {
    content: "\ea61"
}

.ri-arrow-left-right-line:before {
    content: "\ea62"
}

.ri-arrow-left-s-fill:before {
    content: "\ea63"
}

.ri-arrow-left-s-line:before {
    content: "\ea64"
}

.ri-arrow-left-up-fill:before {
    content: "\ea65"
}

.ri-arrow-left-up-line:before {
    content: "\ea66"
}

.ri-arrow-right-circle-fill:before {
    content: "\ea67"
}

.ri-arrow-right-circle-line:before {
    content: "\ea68"
}

.ri-arrow-right-down-fill:before {
    content: "\ea69"
}

.ri-arrow-right-down-line:before {
    content: "\ea6a"
}

.ri-arrow-right-fill:before {
    content: "\ea6b"
}

.ri-arrow-right-line:before {
    content: "\ea6c"
}

.ri-arrow-right-s-fill:before {
    content: "\ea6d"
}

.ri-arrow-right-s-line:before {
    content: "\ea6e"
}

.ri-arrow-right-up-fill:before {
    content: "\ea6f"
}

.ri-arrow-right-up-line:before {
    content: "\ea70"
}

.ri-arrow-up-circle-fill:before {
    content: "\ea71"
}

.ri-arrow-up-circle-line:before {
    content: "\ea72"
}

.ri-arrow-up-down-fill:before {
    content: "\ea73"
}

.ri-arrow-up-down-line:before {
    content: "\ea74"
}

.ri-arrow-up-fill:before {
    content: "\ea75"
}

.ri-arrow-up-line:before {
    content: "\ea76"
}

.ri-arrow-up-s-fill:before {
    content: "\ea77"
}

.ri-arrow-up-s-line:before {
    content: "\ea78"
}

.ri-artboard-2-fill:before {
    content: "\ea79"
}

.ri-artboard-2-line:before {
    content: "\ea7a"
}

.ri-artboard-fill:before {
    content: "\ea7b"
}

.ri-artboard-line:before {
    content: "\ea7c"
}

.ri-article-fill:before {
    content: "\ea7d"
}

.ri-article-line:before {
    content: "\ea7e"
}

.ri-aspect-ratio-fill:before {
    content: "\ea7f"
}

.ri-aspect-ratio-line:before {
    content: "\ea80"
}

.ri-asterisk:before {
    content: "\ea81"
}

.ri-at-fill:before {
    content: "\ea82"
}

.ri-at-line:before {
    content: "\ea83"
}

.ri-attachment-2:before {
    content: "\ea84"
}

.ri-attachment-fill:before {
    content: "\ea85"
}

.ri-attachment-line:before {
    content: "\ea86"
}

.ri-auction-fill:before {
    content: "\ea87"
}

.ri-auction-line:before {
    content: "\ea88"
}

.ri-award-fill:before {
    content: "\ea89"
}

.ri-award-line:before {
    content: "\ea8a"
}

.ri-baidu-fill:before {
    content: "\ea8b"
}

.ri-baidu-line:before {
    content: "\ea8c"
}

.ri-ball-pen-fill:before {
    content: "\ea8d"
}

.ri-ball-pen-line:before {
    content: "\ea8e"
}

.ri-bank-card-2-fill:before {
    content: "\ea8f"
}

.ri-bank-card-2-line:before {
    content: "\ea90"
}

.ri-bank-card-fill:before {
    content: "\ea91"
}

.ri-bank-card-line:before {
    content: "\ea92"
}

.ri-bank-fill:before {
    content: "\ea93"
}

.ri-bank-line:before {
    content: "\ea94"
}

.ri-bar-chart-2-fill:before {
    content: "\ea95"
}

.ri-bar-chart-2-line:before {
    content: "\ea96"
}

.ri-bar-chart-box-fill:before {
    content: "\ea97"
}

.ri-bar-chart-box-line:before {
    content: "\ea98"
}

.ri-bar-chart-fill:before {
    content: "\ea99"
}

.ri-bar-chart-grouped-fill:before {
    content: "\ea9a"
}

.ri-bar-chart-grouped-line:before {
    content: "\ea9b"
}

.ri-bar-chart-horizontal-fill:before {
    content: "\ea9c"
}

.ri-bar-chart-horizontal-line:before {
    content: "\ea9d"
}

.ri-bar-chart-line:before {
    content: "\ea9e"
}

.ri-barcode-box-fill:before {
    content: "\ea9f"
}

.ri-barcode-box-line:before {
    content: "\eaa0"
}

.ri-barcode-fill:before {
    content: "\eaa1"
}

.ri-barcode-line:before {
    content: "\eaa2"
}

.ri-barricade-fill:before {
    content: "\eaa3"
}

.ri-barricade-line:before {
    content: "\eaa4"
}

.ri-base-station-fill:before {
    content: "\eaa5"
}

.ri-base-station-line:before {
    content: "\eaa6"
}

.ri-basketball-fill:before {
    content: "\eaa7"
}

.ri-basketball-line:before {
    content: "\eaa8"
}

.ri-battery-2-charge-fill:before {
    content: "\eaa9"
}

.ri-battery-2-charge-line:before {
    content: "\eaaa"
}

.ri-battery-2-fill:before {
    content: "\eaab"
}

.ri-battery-2-line:before {
    content: "\eaac"
}

.ri-battery-charge-fill:before {
    content: "\eaad"
}

.ri-battery-charge-line:before {
    content: "\eaae"
}

.ri-battery-fill:before {
    content: "\eaaf"
}

.ri-battery-line:before {
    content: "\eab0"
}

.ri-battery-low-fill:before {
    content: "\eab1"
}

.ri-battery-low-line:before {
    content: "\eab2"
}

.ri-battery-saver-fill:before {
    content: "\eab3"
}

.ri-battery-saver-line:before {
    content: "\eab4"
}

.ri-battery-share-fill:before {
    content: "\eab5"
}

.ri-battery-share-line:before {
    content: "\eab6"
}

.ri-bear-smile-fill:before {
    content: "\eab7"
}

.ri-bear-smile-line:before {
    content: "\eab8"
}

.ri-behance-fill:before {
    content: "\eab9"
}

.ri-behance-line:before {
    content: "\eaba"
}

.ri-bell-fill:before {
    content: "\eabb"
}

.ri-bell-line:before {
    content: "\eabc"
}

.ri-bike-fill:before {
    content: "\eabd"
}

.ri-bike-line:before {
    content: "\eabe"
}

.ri-bilibili-fill:before {
    content: "\eabf"
}

.ri-bilibili-line:before {
    content: "\eac0"
}

.ri-bill-fill:before {
    content: "\eac1"
}

.ri-bill-line:before {
    content: "\eac2"
}

.ri-billiards-fill:before {
    content: "\eac3"
}

.ri-billiards-line:before {
    content: "\eac4"
}

.ri-bit-coin-fill:before {
    content: "\eac5"
}

.ri-bit-coin-line:before {
    content: "\eac6"
}

.ri-blaze-fill:before {
    content: "\eac7"
}

.ri-blaze-line:before {
    content: "\eac8"
}

.ri-bluetooth-connect-fill:before {
    content: "\eac9"
}

.ri-bluetooth-connect-line:before {
    content: "\eaca"
}

.ri-bluetooth-fill:before {
    content: "\eacb"
}

.ri-bluetooth-line:before {
    content: "\eacc"
}

.ri-blur-off-fill:before {
    content: "\eacd"
}

.ri-blur-off-line:before {
    content: "\eace"
}

.ri-body-scan-fill:before {
    content: "\eacf"
}

.ri-body-scan-line:before {
    content: "\ead0"
}

.ri-bold:before {
    content: "\ead1"
}

.ri-book-2-fill:before {
    content: "\ead2"
}

.ri-book-2-line:before {
    content: "\ead3"
}

.ri-book-3-fill:before {
    content: "\ead4"
}

.ri-book-3-line:before {
    content: "\ead5"
}

.ri-book-fill:before {
    content: "\ead6"
}

.ri-book-line:before {
    content: "\ead7"
}

.ri-book-mark-fill:before {
    content: "\ead8"
}

.ri-book-mark-line:before {
    content: "\ead9"
}

.ri-book-open-fill:before {
    content: "\eada"
}

.ri-book-open-line:before {
    content: "\eadb"
}

.ri-book-read-fill:before {
    content: "\eadc"
}

.ri-book-read-line:before {
    content: "\eadd"
}

.ri-booklet-fill:before {
    content: "\eade"
}

.ri-booklet-line:before {
    content: "\eadf"
}

.ri-bookmark-2-fill:before {
    content: "\eae0"
}

.ri-bookmark-2-line:before {
    content: "\eae1"
}

.ri-bookmark-3-fill:before {
    content: "\eae2"
}

.ri-bookmark-3-line:before {
    content: "\eae3"
}

.ri-bookmark-fill:before {
    content: "\eae4"
}

.ri-bookmark-line:before {
    content: "\eae5"
}

.ri-boxing-fill:before {
    content: "\eae6"
}

.ri-boxing-line:before {
    content: "\eae7"
}

.ri-braces-fill:before {
    content: "\eae8"
}

.ri-braces-line:before {
    content: "\eae9"
}

.ri-brackets-fill:before {
    content: "\eaea"
}

.ri-brackets-line:before {
    content: "\eaeb"
}

.ri-briefcase-2-fill:before {
    content: "\eaec"
}

.ri-briefcase-2-line:before {
    content: "\eaed"
}

.ri-briefcase-3-fill:before {
    content: "\eaee"
}

.ri-briefcase-3-line:before {
    content: "\eaef"
}

.ri-briefcase-4-fill:before {
    content: "\eaf0"
}

.ri-briefcase-4-line:before {
    content: "\eaf1"
}

.ri-briefcase-5-fill:before {
    content: "\eaf2"
}

.ri-briefcase-5-line:before {
    content: "\eaf3"
}

.ri-briefcase-fill:before {
    content: "\eaf4"
}

.ri-briefcase-line:before {
    content: "\eaf5"
}

.ri-bring-forward:before {
    content: "\eaf6"
}

.ri-bring-to-front:before {
    content: "\eaf7"
}

.ri-broadcast-fill:before {
    content: "\eaf8"
}

.ri-broadcast-line:before {
    content: "\eaf9"
}

.ri-brush-2-fill:before {
    content: "\eafa"
}

.ri-brush-2-line:before {
    content: "\eafb"
}

.ri-brush-3-fill:before {
    content: "\eafc"
}

.ri-brush-3-line:before {
    content: "\eafd"
}

.ri-brush-4-fill:before {
    content: "\eafe"
}

.ri-brush-4-line:before {
    content: "\eaff"
}

.ri-brush-fill:before {
    content: "\eb00"
}

.ri-brush-line:before {
    content: "\eb01"
}

.ri-bubble-chart-fill:before {
    content: "\eb02"
}

.ri-bubble-chart-line:before {
    content: "\eb03"
}

.ri-bug-2-fill:before {
    content: "\eb04"
}

.ri-bug-2-line:before {
    content: "\eb05"
}

.ri-bug-fill:before {
    content: "\eb06"
}

.ri-bug-line:before {
    content: "\eb07"
}

.ri-building-2-fill:before {
    content: "\eb08"
}

.ri-building-2-line:before {
    content: "\eb09"
}

.ri-building-3-fill:before {
    content: "\eb0a"
}

.ri-building-3-line:before {
    content: "\eb0b"
}

.ri-building-4-fill:before {
    content: "\eb0c"
}

.ri-building-4-line:before {
    content: "\eb0d"
}

.ri-building-fill:before {
    content: "\eb0e"
}

.ri-building-line:before {
    content: "\eb0f"
}

.ri-bus-2-fill:before {
    content: "\eb10"
}

.ri-bus-2-line:before {
    content: "\eb11"
}

.ri-bus-fill:before {
    content: "\eb12"
}

.ri-bus-line:before {
    content: "\eb13"
}

.ri-bus-wifi-fill:before {
    content: "\eb14"
}

.ri-bus-wifi-line:before {
    content: "\eb15"
}

.ri-cactus-fill:before {
    content: "\eb16"
}

.ri-cactus-line:before {
    content: "\eb17"
}

.ri-cake-2-fill:before {
    content: "\eb18"
}

.ri-cake-2-line:before {
    content: "\eb19"
}

.ri-cake-3-fill:before {
    content: "\eb1a"
}

.ri-cake-3-line:before {
    content: "\eb1b"
}

.ri-cake-fill:before {
    content: "\eb1c"
}

.ri-cake-line:before {
    content: "\eb1d"
}

.ri-calculator-fill:before {
    content: "\eb1e"
}

.ri-calculator-line:before {
    content: "\eb1f"
}

.ri-calendar-2-fill:before {
    content: "\eb20"
}

.ri-calendar-2-line:before {
    content: "\eb21"
}

.ri-calendar-check-fill:before {
    content: "\eb22"
}

.ri-calendar-check-line:before {
    content: "\eb23"
}

.ri-calendar-event-fill:before {
    content: "\eb24"
}

.ri-calendar-event-line:before {
    content: "\eb25"
}

.ri-calendar-fill:before {
    content: "\eb26"
}

.ri-calendar-line:before {
    content: "\eb27"
}

.ri-calendar-todo-fill:before {
    content: "\eb28"
}

.ri-calendar-todo-line:before {
    content: "\eb29"
}

.ri-camera-2-fill:before {
    content: "\eb2a"
}

.ri-camera-2-line:before {
    content: "\eb2b"
}

.ri-camera-3-fill:before {
    content: "\eb2c"
}

.ri-camera-3-line:before {
    content: "\eb2d"
}

.ri-camera-fill:before {
    content: "\eb2e"
}

.ri-camera-lens-fill:before {
    content: "\eb2f"
}

.ri-camera-lens-line:before {
    content: "\eb30"
}

.ri-camera-line:before {
    content: "\eb31"
}

.ri-camera-off-fill:before {
    content: "\eb32"
}

.ri-camera-off-line:before {
    content: "\eb33"
}

.ri-camera-switch-fill:before {
    content: "\eb34"
}

.ri-camera-switch-line:before {
    content: "\eb35"
}

.ri-capsule-fill:before {
    content: "\eb36"
}

.ri-capsule-line:before {
    content: "\eb37"
}

.ri-car-fill:before {
    content: "\eb38"
}

.ri-car-line:before {
    content: "\eb39"
}

.ri-car-washing-fill:before {
    content: "\eb3a"
}

.ri-car-washing-line:before {
    content: "\eb3b"
}

.ri-caravan-fill:before {
    content: "\eb3c"
}

.ri-caravan-line:before {
    content: "\eb3d"
}

.ri-cast-fill:before {
    content: "\eb3e"
}

.ri-cast-line:before {
    content: "\eb3f"
}

.ri-cellphone-fill:before {
    content: "\eb40"
}

.ri-cellphone-line:before {
    content: "\eb41"
}

.ri-celsius-fill:before {
    content: "\eb42"
}

.ri-celsius-line:before {
    content: "\eb43"
}

.ri-centos-fill:before {
    content: "\eb44"
}

.ri-centos-line:before {
    content: "\eb45"
}

.ri-character-recognition-fill:before {
    content: "\eb46"
}

.ri-character-recognition-line:before {
    content: "\eb47"
}

.ri-charging-pile-2-fill:before {
    content: "\eb48"
}

.ri-charging-pile-2-line:before {
    content: "\eb49"
}

.ri-charging-pile-fill:before {
    content: "\eb4a"
}

.ri-charging-pile-line:before {
    content: "\eb4b"
}

.ri-chat-1-fill:before {
    content: "\eb4c"
}

.ri-chat-1-line:before {
    content: "\eb4d"
}

.ri-chat-2-fill:before {
    content: "\eb4e"
}

.ri-chat-2-line:before {
    content: "\eb4f"
}

.ri-chat-3-fill:before {
    content: "\eb50"
}

.ri-chat-3-line:before {
    content: "\eb51"
}

.ri-chat-4-fill:before {
    content: "\eb52"
}

.ri-chat-4-line:before {
    content: "\eb53"
}

.ri-chat-check-fill:before {
    content: "\eb54"
}

.ri-chat-check-line:before {
    content: "\eb55"
}

.ri-chat-delete-fill:before {
    content: "\eb56"
}

.ri-chat-delete-line:before {
    content: "\eb57"
}

.ri-chat-download-fill:before {
    content: "\eb58"
}

.ri-chat-download-line:before {
    content: "\eb59"
}

.ri-chat-follow-up-fill:before {
    content: "\eb5a"
}

.ri-chat-follow-up-line:before {
    content: "\eb5b"
}

.ri-chat-forward-fill:before {
    content: "\eb5c"
}

.ri-chat-forward-line:before {
    content: "\eb5d"
}

.ri-chat-heart-fill:before {
    content: "\eb5e"
}

.ri-chat-heart-line:before {
    content: "\eb5f"
}

.ri-chat-history-fill:before {
    content: "\eb60"
}

.ri-chat-history-line:before {
    content: "\eb61"
}

.ri-chat-new-fill:before {
    content: "\eb62"
}

.ri-chat-new-line:before {
    content: "\eb63"
}

.ri-chat-off-fill:before {
    content: "\eb64"
}

.ri-chat-off-line:before {
    content: "\eb65"
}

.ri-chat-poll-fill:before {
    content: "\eb66"
}

.ri-chat-poll-line:before {
    content: "\eb67"
}

.ri-chat-private-fill:before {
    content: "\eb68"
}

.ri-chat-private-line:before {
    content: "\eb69"
}

.ri-chat-quote-fill:before {
    content: "\eb6a"
}

.ri-chat-quote-line:before {
    content: "\eb6b"
}

.ri-chat-settings-fill:before {
    content: "\eb6c"
}

.ri-chat-settings-line:before {
    content: "\eb6d"
}

.ri-chat-smile-2-fill:before {
    content: "\eb6e"
}

.ri-chat-smile-2-line:before {
    content: "\eb6f"
}

.ri-chat-smile-3-fill:before {
    content: "\eb70"
}

.ri-chat-smile-3-line:before {
    content: "\eb71"
}

.ri-chat-smile-fill:before {
    content: "\eb72"
}

.ri-chat-smile-line:before {
    content: "\eb73"
}

.ri-chat-upload-fill:before {
    content: "\eb74"
}

.ri-chat-upload-line:before {
    content: "\eb75"
}

.ri-chat-voice-fill:before {
    content: "\eb76"
}

.ri-chat-voice-line:before {
    content: "\eb77"
}

.ri-check-double-fill:before {
    content: "\eb78"
}

.ri-check-double-line:before {
    content: "\eb79"
}

.ri-check-fill:before {
    content: "\eb7a"
}

.ri-check-line:before {
    content: "\eb7b"
}

.ri-checkbox-blank-circle-fill:before {
    content: "\eb7c"
}

.ri-checkbox-blank-circle-line:before {
    content: "\eb7d"
}

.ri-checkbox-blank-fill:before {
    content: "\eb7e"
}

.ri-checkbox-blank-line:before {
    content: "\eb7f"
}

.ri-checkbox-circle-fill:before {
    content: "\eb80"
}

.ri-checkbox-circle-line:before {
    content: "\eb81"
}

.ri-checkbox-fill:before {
    content: "\eb82"
}

.ri-checkbox-indeterminate-fill:before {
    content: "\eb83"
}

.ri-checkbox-indeterminate-line:before {
    content: "\eb84"
}

.ri-checkbox-line:before {
    content: "\eb85"
}

.ri-checkbox-multiple-blank-fill:before {
    content: "\eb86"
}

.ri-checkbox-multiple-blank-line:before {
    content: "\eb87"
}

.ri-checkbox-multiple-fill:before {
    content: "\eb88"
}

.ri-checkbox-multiple-line:before {
    content: "\eb89"
}

.ri-china-railway-fill:before {
    content: "\eb8a"
}

.ri-china-railway-line:before {
    content: "\eb8b"
}

.ri-chrome-fill:before {
    content: "\eb8c"
}

.ri-chrome-line:before {
    content: "\eb8d"
}

.ri-clapperboard-fill:before {
    content: "\eb8e"
}

.ri-clapperboard-line:before {
    content: "\eb8f"
}

.ri-clipboard-fill:before {
    content: "\eb90"
}

.ri-clipboard-line:before {
    content: "\eb91"
}

.ri-clockwise-2-fill:before {
    content: "\eb92"
}

.ri-clockwise-2-line:before {
    content: "\eb93"
}

.ri-clockwise-fill:before {
    content: "\eb94"
}

.ri-clockwise-line:before {
    content: "\eb95"
}

.ri-close-circle-fill:before {
    content: "\eb96"
}

.ri-close-circle-line:before {
    content: "\eb97"
}

.ri-close-fill:before {
    content: "\eb98"
}

.ri-close-line:before {
    content: "\eb99"
}

.ri-closed-captioning-fill:before {
    content: "\eb9a"
}

.ri-closed-captioning-line:before {
    content: "\eb9b"
}

.ri-cloud-fill:before {
    content: "\eb9c"
}

.ri-cloud-line:before {
    content: "\eb9d"
}

.ri-cloud-off-fill:before {
    content: "\eb9e"
}

.ri-cloud-off-line:before {
    content: "\eb9f"
}

.ri-cloud-windy-fill:before {
    content: "\eba0"
}

.ri-cloud-windy-line:before {
    content: "\eba1"
}

.ri-cloudy-2-fill:before {
    content: "\eba2"
}

.ri-cloudy-2-line:before {
    content: "\eba3"
}

.ri-cloudy-fill:before {
    content: "\eba4"
}

.ri-cloudy-line:before {
    content: "\eba5"
}

.ri-code-box-fill:before {
    content: "\eba6"
}

.ri-code-box-line:before {
    content: "\eba7"
}

.ri-code-fill:before {
    content: "\eba8"
}

.ri-code-line:before {
    content: "\eba9"
}

.ri-code-s-fill:before {
    content: "\ebaa"
}

.ri-code-s-line:before {
    content: "\ebab"
}

.ri-code-s-slash-fill:before {
    content: "\ebac"
}

.ri-code-s-slash-line:before {
    content: "\ebad"
}

.ri-code-view:before {
    content: "\ebae"
}

.ri-codepen-fill:before {
    content: "\ebaf"
}

.ri-codepen-line:before {
    content: "\ebb0"
}

.ri-coin-fill:before {
    content: "\ebb1"
}

.ri-coin-line:before {
    content: "\ebb2"
}

.ri-coins-fill:before {
    content: "\ebb3"
}

.ri-coins-line:before {
    content: "\ebb4"
}

.ri-collage-fill:before {
    content: "\ebb5"
}

.ri-collage-line:before {
    content: "\ebb6"
}

.ri-command-fill:before {
    content: "\ebb7"
}

.ri-command-line:before {
    content: "\ebb8"
}

.ri-community-fill:before {
    content: "\ebb9"
}

.ri-community-line:before {
    content: "\ebba"
}

.ri-compass-2-fill:before {
    content: "\ebbb"
}

.ri-compass-2-line:before {
    content: "\ebbc"
}

.ri-compass-3-fill:before {
    content: "\ebbd"
}

.ri-compass-3-line:before {
    content: "\ebbe"
}

.ri-compass-4-fill:before {
    content: "\ebbf"
}

.ri-compass-4-line:before {
    content: "\ebc0"
}

.ri-compass-discover-fill:before {
    content: "\ebc1"
}

.ri-compass-discover-line:before {
    content: "\ebc2"
}

.ri-compass-fill:before {
    content: "\ebc3"
}

.ri-compass-line:before {
    content: "\ebc4"
}

.ri-compasses-2-fill:before {
    content: "\ebc5"
}

.ri-compasses-2-line:before {
    content: "\ebc6"
}

.ri-compasses-fill:before {
    content: "\ebc7"
}

.ri-compasses-line:before {
    content: "\ebc8"
}

.ri-computer-fill:before {
    content: "\ebc9"
}

.ri-computer-line:before {
    content: "\ebca"
}

.ri-contacts-book-2-fill:before {
    content: "\ebcb"
}

.ri-contacts-book-2-line:before {
    content: "\ebcc"
}

.ri-contacts-book-fill:before {
    content: "\ebcd"
}

.ri-contacts-book-line:before {
    content: "\ebce"
}

.ri-contacts-book-upload-fill:before {
    content: "\ebcf"
}

.ri-contacts-book-upload-line:before {
    content: "\ebd0"
}

.ri-contacts-fill:before {
    content: "\ebd1"
}

.ri-contacts-line:before {
    content: "\ebd2"
}

.ri-contrast-2-fill:before {
    content: "\ebd3"
}

.ri-contrast-2-line:before {
    content: "\ebd4"
}

.ri-contrast-drop-2-fill:before {
    content: "\ebd5"
}

.ri-contrast-drop-2-line:before {
    content: "\ebd6"
}

.ri-contrast-drop-fill:before {
    content: "\ebd7"
}

.ri-contrast-drop-line:before {
    content: "\ebd8"
}

.ri-contrast-fill:before {
    content: "\ebd9"
}

.ri-contrast-line:before {
    content: "\ebda"
}

.ri-copper-coin-fill:before {
    content: "\ebdb"
}

.ri-copper-coin-line:before {
    content: "\ebdc"
}

.ri-copper-diamond-fill:before {
    content: "\ebdd"
}

.ri-copper-diamond-line:before {
    content: "\ebde"
}

.ri-copyleft-fill:before {
    content: "\ebdf"
}

.ri-copyleft-line:before {
    content: "\ebe0"
}

.ri-copyright-fill:before {
    content: "\ebe1"
}

.ri-copyright-line:before {
    content: "\ebe2"
}

.ri-coreos-fill:before {
    content: "\ebe3"
}

.ri-coreos-line:before {
    content: "\ebe4"
}

.ri-coupon-2-fill:before {
    content: "\ebe5"
}

.ri-coupon-2-line:before {
    content: "\ebe6"
}

.ri-coupon-3-fill:before {
    content: "\ebe7"
}

.ri-coupon-3-line:before {
    content: "\ebe8"
}

.ri-coupon-4-fill:before {
    content: "\ebe9"
}

.ri-coupon-4-line:before {
    content: "\ebea"
}

.ri-coupon-5-fill:before {
    content: "\ebeb"
}

.ri-coupon-5-line:before {
    content: "\ebec"
}

.ri-coupon-fill:before {
    content: "\ebed"
}

.ri-coupon-line:before {
    content: "\ebee"
}

.ri-cpu-fill:before {
    content: "\ebef"
}

.ri-cpu-line:before {
    content: "\ebf0"
}

.ri-creative-commons-by-fill:before {
    content: "\ebf1"
}

.ri-creative-commons-by-line:before {
    content: "\ebf2"
}

.ri-creative-commons-fill:before {
    content: "\ebf3"
}

.ri-creative-commons-line:before {
    content: "\ebf4"
}

.ri-creative-commons-nc-fill:before {
    content: "\ebf5"
}

.ri-creative-commons-nc-line:before {
    content: "\ebf6"
}

.ri-creative-commons-nd-fill:before {
    content: "\ebf7"
}

.ri-creative-commons-nd-line:before {
    content: "\ebf8"
}

.ri-creative-commons-sa-fill:before {
    content: "\ebf9"
}

.ri-creative-commons-sa-line:before {
    content: "\ebfa"
}

.ri-creative-commons-zero-fill:before {
    content: "\ebfb"
}

.ri-creative-commons-zero-line:before {
    content: "\ebfc"
}

.ri-criminal-fill:before {
    content: "\ebfd"
}

.ri-criminal-line:before {
    content: "\ebfe"
}

.ri-crop-2-fill:before {
    content: "\ebff"
}

.ri-crop-2-line:before {
    content: "\ec00"
}

.ri-crop-fill:before {
    content: "\ec01"
}

.ri-crop-line:before {
    content: "\ec02"
}

.ri-css3-fill:before {
    content: "\ec03"
}

.ri-css3-line:before {
    content: "\ec04"
}

.ri-cup-fill:before {
    content: "\ec05"
}

.ri-cup-line:before {
    content: "\ec06"
}

.ri-currency-fill:before {
    content: "\ec07"
}

.ri-currency-line:before {
    content: "\ec08"
}

.ri-cursor-fill:before {
    content: "\ec09"
}

.ri-cursor-line:before {
    content: "\ec0a"
}

.ri-customer-service-2-fill:before {
    content: "\ec0b"
}

.ri-customer-service-2-line:before {
    content: "\ec0c"
}

.ri-customer-service-fill:before {
    content: "\ec0d"
}

.ri-customer-service-line:before {
    content: "\ec0e"
}

.ri-dashboard-2-fill:before {
    content: "\ec0f"
}

.ri-dashboard-2-line:before {
    content: "\ec10"
}

.ri-dashboard-3-fill:before {
    content: "\ec11"
}

.ri-dashboard-3-line:before {
    content: "\ec12"
}

.ri-dashboard-fill:before {
    content: "\ec13"
}

.ri-dashboard-line:before {
    content: "\ec14"
}

.ri-database-2-fill:before {
    content: "\ec15"
}

.ri-database-2-line:before {
    content: "\ec16"
}

.ri-database-fill:before {
    content: "\ec17"
}

.ri-database-line:before {
    content: "\ec18"
}

.ri-delete-back-2-fill:before {
    content: "\ec19"
}

.ri-delete-back-2-line:before {
    content: "\ec1a"
}

.ri-delete-back-fill:before {
    content: "\ec1b"
}

.ri-delete-back-line:before {
    content: "\ec1c"
}

.ri-delete-bin-2-fill:before {
    content: "\ec1d"
}

.ri-delete-bin-2-line:before {
    content: "\ec1e"
}

.ri-delete-bin-3-fill:before {
    content: "\ec1f"
}

.ri-delete-bin-3-line:before {
    content: "\ec20"
}

.ri-delete-bin-4-fill:before {
    content: "\ec21"
}

.ri-delete-bin-4-line:before {
    content: "\ec22"
}

.ri-delete-bin-5-fill:before {
    content: "\ec23"
}

.ri-delete-bin-5-line:before {
    content: "\ec24"
}

.ri-delete-bin-6-fill:before {
    content: "\ec25"
}

.ri-delete-bin-6-line:before {
    content: "\ec26"
}

.ri-delete-bin-7-fill:before {
    content: "\ec27"
}

.ri-delete-bin-7-line:before {
    content: "\ec28"
}

.ri-delete-bin-fill:before {
    content: "\ec29"
}

.ri-delete-bin-line:before {
    content: "\ec2a"
}

.ri-delete-column:before {
    content: "\ec2b"
}

.ri-delete-row:before {
    content: "\ec2c"
}

.ri-device-fill:before {
    content: "\ec2d"
}

.ri-device-line:before {
    content: "\ec2e"
}

.ri-device-recover-fill:before {
    content: "\ec2f"
}

.ri-device-recover-line:before {
    content: "\ec30"
}

.ri-dingding-fill:before {
    content: "\ec31"
}

.ri-dingding-line:before {
    content: "\ec32"
}

.ri-direction-fill:before {
    content: "\ec33"
}

.ri-direction-line:before {
    content: "\ec34"
}

.ri-disc-fill:before {
    content: "\ec35"
}

.ri-disc-line:before {
    content: "\ec36"
}

.ri-discord-fill:before {
    content: "\ec37"
}

.ri-discord-line:before {
    content: "\ec38"
}

.ri-discuss-fill:before {
    content: "\ec39"
}

.ri-discuss-line:before {
    content: "\ec3a"
}

.ri-dislike-fill:before {
    content: "\ec3b"
}

.ri-dislike-line:before {
    content: "\ec3c"
}

.ri-disqus-fill:before {
    content: "\ec3d"
}

.ri-disqus-line:before {
    content: "\ec3e"
}

.ri-divide-fill:before {
    content: "\ec3f"
}

.ri-divide-line:before {
    content: "\ec40"
}

.ri-donut-chart-fill:before {
    content: "\ec41"
}

.ri-donut-chart-line:before {
    content: "\ec42"
}

.ri-door-closed-fill:before {
    content: "\ec43"
}

.ri-door-closed-line:before {
    content: "\ec44"
}

.ri-door-fill:before {
    content: "\ec45"
}

.ri-door-line:before {
    content: "\ec46"
}

.ri-door-lock-box-fill:before {
    content: "\ec47"
}

.ri-door-lock-box-line:before {
    content: "\ec48"
}

.ri-door-lock-fill:before {
    content: "\ec49"
}

.ri-door-lock-line:before {
    content: "\ec4a"
}

.ri-door-open-fill:before {
    content: "\ec4b"
}

.ri-door-open-line:before {
    content: "\ec4c"
}

.ri-dossier-fill:before {
    content: "\ec4d"
}

.ri-dossier-line:before {
    content: "\ec4e"
}

.ri-douban-fill:before {
    content: "\ec4f"
}

.ri-douban-line:before {
    content: "\ec50"
}

.ri-double-quotes-l:before {
    content: "\ec51"
}

.ri-double-quotes-r:before {
    content: "\ec52"
}

.ri-download-2-fill:before {
    content: "\ec53"
}

.ri-download-2-line:before {
    content: "\ec54"
}

.ri-download-cloud-2-fill:before {
    content: "\ec55"
}

.ri-download-cloud-2-line:before {
    content: "\ec56"
}

.ri-download-cloud-fill:before {
    content: "\ec57"
}

.ri-download-cloud-line:before {
    content: "\ec58"
}

.ri-download-fill:before {
    content: "\ec59"
}

.ri-download-line:before {
    content: "\ec5a"
}

.ri-draft-fill:before {
    content: "\ec5b"
}

.ri-draft-line:before {
    content: "\ec5c"
}

.ri-drag-drop-fill:before {
    content: "\ec5d"
}

.ri-drag-drop-line:before {
    content: "\ec5e"
}

.ri-drag-move-2-fill:before {
    content: "\ec5f"
}

.ri-drag-move-2-line:before {
    content: "\ec60"
}

.ri-drag-move-fill:before {
    content: "\ec61"
}

.ri-drag-move-line:before {
    content: "\ec62"
}

.ri-dribbble-fill:before {
    content: "\ec63"
}

.ri-dribbble-line:before {
    content: "\ec64"
}

.ri-drive-fill:before {
    content: "\ec65"
}

.ri-drive-line:before {
    content: "\ec66"
}

.ri-drizzle-fill:before {
    content: "\ec67"
}

.ri-drizzle-line:before {
    content: "\ec68"
}

.ri-drop-fill:before {
    content: "\ec69"
}

.ri-drop-line:before {
    content: "\ec6a"
}

.ri-dropbox-fill:before {
    content: "\ec6b"
}

.ri-dropbox-line:before {
    content: "\ec6c"
}

.ri-dual-sim-1-fill:before {
    content: "\ec6d"
}

.ri-dual-sim-1-line:before {
    content: "\ec6e"
}

.ri-dual-sim-2-fill:before {
    content: "\ec6f"
}

.ri-dual-sim-2-line:before {
    content: "\ec70"
}

.ri-dv-fill:before {
    content: "\ec71"
}

.ri-dv-line:before {
    content: "\ec72"
}

.ri-dvd-fill:before {
    content: "\ec73"
}

.ri-dvd-line:before {
    content: "\ec74"
}

.ri-e-bike-2-fill:before {
    content: "\ec75"
}

.ri-e-bike-2-line:before {
    content: "\ec76"
}

.ri-e-bike-fill:before {
    content: "\ec77"
}

.ri-e-bike-line:before {
    content: "\ec78"
}

.ri-earth-fill:before {
    content: "\ec79"
}

.ri-earth-line:before {
    content: "\ec7a"
}

.ri-earthquake-fill:before {
    content: "\ec7b"
}

.ri-earthquake-line:before {
    content: "\ec7c"
}

.ri-edge-fill:before {
    content: "\ec7d"
}

.ri-edge-line:before {
    content: "\ec7e"
}

.ri-edit-2-fill:before {
    content: "\ec7f"
}

.ri-edit-2-line:before {
    content: "\ec80"
}

.ri-edit-box-fill:before {
    content: "\ec81"
}

.ri-edit-box-line:before {
    content: "\ec82"
}

.ri-edit-circle-fill:before {
    content: "\ec83"
}

.ri-edit-circle-line:before {
    content: "\ec84"
}

.ri-edit-fill:before {
    content: "\ec85"
}

.ri-edit-line:before {
    content: "\ec86"
}

.ri-eject-fill:before {
    content: "\ec87"
}

.ri-eject-line:before {
    content: "\ec88"
}

.ri-emotion-2-fill:before {
    content: "\ec89"
}

.ri-emotion-2-line:before {
    content: "\ec8a"
}

.ri-emotion-fill:before {
    content: "\ec8b"
}

.ri-emotion-happy-fill:before {
    content: "\ec8c"
}

.ri-emotion-happy-line:before {
    content: "\ec8d"
}

.ri-emotion-laugh-fill:before {
    content: "\ec8e"
}

.ri-emotion-laugh-line:before {
    content: "\ec8f"
}

.ri-emotion-line:before {
    content: "\ec90"
}

.ri-emotion-normal-fill:before {
    content: "\ec91"
}

.ri-emotion-normal-line:before {
    content: "\ec92"
}

.ri-emotion-sad-fill:before {
    content: "\ec93"
}

.ri-emotion-sad-line:before {
    content: "\ec94"
}

.ri-emotion-unhappy-fill:before {
    content: "\ec95"
}

.ri-emotion-unhappy-line:before {
    content: "\ec96"
}

.ri-empathize-fill:before {
    content: "\ec97"
}

.ri-empathize-line:before {
    content: "\ec98"
}

.ri-emphasis-cn:before {
    content: "\ec99"
}

.ri-emphasis:before {
    content: "\ec9a"
}

.ri-english-input:before {
    content: "\ec9b"
}

.ri-equalizer-fill:before {
    content: "\ec9c"
}

.ri-equalizer-line:before {
    content: "\ec9d"
}

.ri-eraser-fill:before {
    content: "\ec9e"
}

.ri-eraser-line:before {
    content: "\ec9f"
}

.ri-error-warning-fill:before {
    content: "\eca0"
}

.ri-error-warning-line:before {
    content: "\eca1"
}

.ri-evernote-fill:before {
    content: "\eca2"
}

.ri-evernote-line:before {
    content: "\eca3"
}

.ri-exchange-box-fill:before {
    content: "\eca4"
}

.ri-exchange-box-line:before {
    content: "\eca5"
}

.ri-exchange-cny-fill:before {
    content: "\eca6"
}

.ri-exchange-cny-line:before {
    content: "\eca7"
}

.ri-exchange-dollar-fill:before {
    content: "\eca8"
}

.ri-exchange-dollar-line:before {
    content: "\eca9"
}

.ri-exchange-fill:before {
    content: "\ecaa"
}

.ri-exchange-funds-fill:before {
    content: "\ecab"
}

.ri-exchange-funds-line:before {
    content: "\ecac"
}

.ri-exchange-line:before {
    content: "\ecad"
}

.ri-external-link-fill:before {
    content: "\ecae"
}

.ri-external-link-line:before {
    content: "\ecaf"
}

.ri-eye-2-fill:before {
    content: "\ecb0"
}

.ri-eye-2-line:before {
    content: "\ecb1"
}

.ri-eye-close-fill:before {
    content: "\ecb2"
}

.ri-eye-close-line:before {
    content: "\ecb3"
}

.ri-eye-fill:before {
    content: "\ecb4"
}

.ri-eye-line:before {
    content: "\ecb5"
}

.ri-eye-off-fill:before {
    content: "\ecb6"
}

.ri-eye-off-line:before {
    content: "\ecb7"
}

.ri-facebook-box-fill:before {
    content: "\ecb8"
}

.ri-facebook-box-line:before {
    content: "\ecb9"
}

.ri-facebook-circle-fill:before {
    content: "\ecba"
}

.ri-facebook-circle-line:before {
    content: "\ecbb"
}

.ri-facebook-fill:before {
    content: "\ecbc"
}

.ri-facebook-line:before {
    content: "\ecbd"
}

.ri-fahrenheit-fill:before {
    content: "\ecbe"
}

.ri-fahrenheit-line:before {
    content: "\ecbf"
}

.ri-feedback-fill:before {
    content: "\ecc0"
}

.ri-feedback-line:before {
    content: "\ecc1"
}

.ri-file-2-fill:before {
    content: "\ecc2"
}

.ri-file-2-line:before {
    content: "\ecc3"
}

.ri-file-3-fill:before {
    content: "\ecc4"
}

.ri-file-3-line:before {
    content: "\ecc5"
}

.ri-file-4-fill:before {
    content: "\ecc6"
}

.ri-file-4-line:before {
    content: "\ecc7"
}

.ri-file-add-fill:before {
    content: "\ecc8"
}

.ri-file-add-line:before {
    content: "\ecc9"
}

.ri-file-chart-2-fill:before {
    content: "\ecca"
}

.ri-file-chart-2-line:before {
    content: "\eccb"
}

.ri-file-chart-fill:before {
    content: "\eccc"
}

.ri-file-chart-line:before {
    content: "\eccd"
}

.ri-file-cloud-fill:before {
    content: "\ecce"
}

.ri-file-cloud-line:before {
    content: "\eccf"
}

.ri-file-code-fill:before {
    content: "\ecd0"
}

.ri-file-code-line:before {
    content: "\ecd1"
}

.ri-file-copy-2-fill:before {
    content: "\ecd2"
}

.ri-file-copy-2-line:before {
    content: "\ecd3"
}

.ri-file-copy-fill:before {
    content: "\ecd4"
}

.ri-file-copy-line:before {
    content: "\ecd5"
}

.ri-file-damage-fill:before {
    content: "\ecd6"
}

.ri-file-damage-line:before {
    content: "\ecd7"
}

.ri-file-download-fill:before {
    content: "\ecd8"
}

.ri-file-download-line:before {
    content: "\ecd9"
}

.ri-file-edit-fill:before {
    content: "\ecda"
}

.ri-file-edit-line:before {
    content: "\ecdb"
}

.ri-file-excel-2-fill:before {
    content: "\ecdc"
}

.ri-file-excel-2-line:before {
    content: "\ecdd"
}

.ri-file-excel-fill:before {
    content: "\ecde"
}

.ri-file-excel-line:before {
    content: "\ecdf"
}

.ri-file-fill:before {
    content: "\ece0"
}

.ri-file-forbid-fill:before {
    content: "\ece1"
}

.ri-file-forbid-line:before {
    content: "\ece2"
}

.ri-file-gif-fill:before {
    content: "\ece3"
}

.ri-file-gif-line:before {
    content: "\ece4"
}

.ri-file-history-fill:before {
    content: "\ece5"
}

.ri-file-history-line:before {
    content: "\ece6"
}

.ri-file-hwp-fill:before {
    content: "\ece7"
}

.ri-file-hwp-line:before {
    content: "\ece8"
}

.ri-file-info-fill:before {
    content: "\ece9"
}

.ri-file-info-line:before {
    content: "\ecea"
}

.ri-file-line:before {
    content: "\eceb"
}

.ri-file-list-2-fill:before {
    content: "\ecec"
}

.ri-file-list-2-line:before {
    content: "\eced"
}

.ri-file-list-3-fill:before {
    content: "\ecee"
}

.ri-file-list-3-line:before {
    content: "\ecef"
}

.ri-file-list-fill:before {
    content: "\ecf0"
}

.ri-file-list-line:before {
    content: "\ecf1"
}

.ri-file-lock-fill:before {
    content: "\ecf2"
}

.ri-file-lock-line:before {
    content: "\ecf3"
}

.ri-file-mark-fill:before {
    content: "\ecf4"
}

.ri-file-mark-line:before {
    content: "\ecf5"
}

.ri-file-music-fill:before {
    content: "\ecf6"
}

.ri-file-music-line:before {
    content: "\ecf7"
}

.ri-file-paper-2-fill:before {
    content: "\ecf8"
}

.ri-file-paper-2-line:before {
    content: "\ecf9"
}

.ri-file-paper-fill:before {
    content: "\ecfa"
}

.ri-file-paper-line:before {
    content: "\ecfb"
}

.ri-file-pdf-fill:before {
    content: "\ecfc"
}

.ri-file-pdf-line:before {
    content: "\ecfd"
}

.ri-file-ppt-2-fill:before {
    content: "\ecfe"
}

.ri-file-ppt-2-line:before {
    content: "\ecff"
}

.ri-file-ppt-fill:before {
    content: "\ed00"
}

.ri-file-ppt-line:before {
    content: "\ed01"
}

.ri-file-reduce-fill:before {
    content: "\ed02"
}

.ri-file-reduce-line:before {
    content: "\ed03"
}

.ri-file-search-fill:before {
    content: "\ed04"
}

.ri-file-search-line:before {
    content: "\ed05"
}

.ri-file-settings-fill:before {
    content: "\ed06"
}

.ri-file-settings-line:before {
    content: "\ed07"
}

.ri-file-shield-2-fill:before {
    content: "\ed08"
}

.ri-file-shield-2-line:before {
    content: "\ed09"
}

.ri-file-shield-fill:before {
    content: "\ed0a"
}

.ri-file-shield-line:before {
    content: "\ed0b"
}

.ri-file-shred-fill:before {
    content: "\ed0c"
}

.ri-file-shred-line:before {
    content: "\ed0d"
}

.ri-file-text-fill:before {
    content: "\ed0e"
}

.ri-file-text-line:before {
    content: "\ed0f"
}

.ri-file-transfer-fill:before {
    content: "\ed10"
}

.ri-file-transfer-line:before {
    content: "\ed11"
}

.ri-file-unknow-fill:before {
    content: "\ed12"
}

.ri-file-unknow-line:before {
    content: "\ed13"
}

.ri-file-upload-fill:before {
    content: "\ed14"
}

.ri-file-upload-line:before {
    content: "\ed15"
}

.ri-file-user-fill:before {
    content: "\ed16"
}

.ri-file-user-line:before {
    content: "\ed17"
}

.ri-file-warning-fill:before {
    content: "\ed18"
}

.ri-file-warning-line:before {
    content: "\ed19"
}

.ri-file-word-2-fill:before {
    content: "\ed1a"
}

.ri-file-word-2-line:before {
    content: "\ed1b"
}

.ri-file-word-fill:before {
    content: "\ed1c"
}

.ri-file-word-line:before {
    content: "\ed1d"
}

.ri-file-zip-fill:before {
    content: "\ed1e"
}

.ri-file-zip-line:before {
    content: "\ed1f"
}

.ri-film-fill:before {
    content: "\ed20"
}

.ri-film-line:before {
    content: "\ed21"
}

.ri-filter-2-fill:before {
    content: "\ed22"
}

.ri-filter-2-line:before {
    content: "\ed23"
}

.ri-filter-3-fill:before {
    content: "\ed24"
}

.ri-filter-3-line:before {
    content: "\ed25"
}

.ri-filter-fill:before {
    content: "\ed26"
}

.ri-filter-line:before {
    content: "\ed27"
}

.ri-filter-off-fill:before {
    content: "\ed28"
}

.ri-filter-off-line:before {
    content: "\ed29"
}

.ri-find-replace-fill:before {
    content: "\ed2a"
}

.ri-find-replace-line:before {
    content: "\ed2b"
}

.ri-finder-fill:before {
    content: "\ed2c"
}

.ri-finder-line:before {
    content: "\ed2d"
}

.ri-fingerprint-2-fill:before {
    content: "\ed2e"
}

.ri-fingerprint-2-line:before {
    content: "\ed2f"
}

.ri-fingerprint-fill:before {
    content: "\ed30"
}

.ri-fingerprint-line:before {
    content: "\ed31"
}

.ri-fire-fill:before {
    content: "\ed32"
}

.ri-fire-line:before {
    content: "\ed33"
}

.ri-firefox-fill:before {
    content: "\ed34"
}

.ri-firefox-line:before {
    content: "\ed35"
}

.ri-first-aid-kit-fill:before {
    content: "\ed36"
}

.ri-first-aid-kit-line:before {
    content: "\ed37"
}

.ri-flag-2-fill:before {
    content: "\ed38"
}

.ri-flag-2-line:before {
    content: "\ed39"
}

.ri-flag-fill:before {
    content: "\ed3a"
}

.ri-flag-line:before {
    content: "\ed3b"
}

.ri-flashlight-fill:before {
    content: "\ed3c"
}

.ri-flashlight-line:before {
    content: "\ed3d"
}

.ri-flask-fill:before {
    content: "\ed3e"
}

.ri-flask-line:before {
    content: "\ed3f"
}

.ri-flight-land-fill:before {
    content: "\ed40"
}

.ri-flight-land-line:before {
    content: "\ed41"
}

.ri-flight-takeoff-fill:before {
    content: "\ed42"
}

.ri-flight-takeoff-line:before {
    content: "\ed43"
}

.ri-flood-fill:before {
    content: "\ed44"
}

.ri-flood-line:before {
    content: "\ed45"
}

.ri-flow-chart:before {
    content: "\ed46"
}

.ri-flutter-fill:before {
    content: "\ed47"
}

.ri-flutter-line:before {
    content: "\ed48"
}

.ri-focus-2-fill:before {
    content: "\ed49"
}

.ri-focus-2-line:before {
    content: "\ed4a"
}

.ri-focus-3-fill:before {
    content: "\ed4b"
}

.ri-focus-3-line:before {
    content: "\ed4c"
}

.ri-focus-fill:before {
    content: "\ed4d"
}

.ri-focus-line:before {
    content: "\ed4e"
}

.ri-foggy-fill:before {
    content: "\ed4f"
}

.ri-foggy-line:before {
    content: "\ed50"
}

.ri-folder-2-fill:before {
    content: "\ed51"
}

.ri-folder-2-line:before {
    content: "\ed52"
}

.ri-folder-3-fill:before {
    content: "\ed53"
}

.ri-folder-3-line:before {
    content: "\ed54"
}

.ri-folder-4-fill:before {
    content: "\ed55"
}

.ri-folder-4-line:before {
    content: "\ed56"
}

.ri-folder-5-fill:before {
    content: "\ed57"
}

.ri-folder-5-line:before {
    content: "\ed58"
}

.ri-folder-add-fill:before {
    content: "\ed59"
}

.ri-folder-add-line:before {
    content: "\ed5a"
}

.ri-folder-chart-2-fill:before {
    content: "\ed5b"
}

.ri-folder-chart-2-line:before {
    content: "\ed5c"
}

.ri-folder-chart-fill:before {
    content: "\ed5d"
}

.ri-folder-chart-line:before {
    content: "\ed5e"
}

.ri-folder-download-fill:before {
    content: "\ed5f"
}

.ri-folder-download-line:before {
    content: "\ed60"
}

.ri-folder-fill:before {
    content: "\ed61"
}

.ri-folder-forbid-fill:before {
    content: "\ed62"
}

.ri-folder-forbid-line:before {
    content: "\ed63"
}

.ri-folder-history-fill:before {
    content: "\ed64"
}

.ri-folder-history-line:before {
    content: "\ed65"
}

.ri-folder-info-fill:before {
    content: "\ed66"
}

.ri-folder-info-line:before {
    content: "\ed67"
}

.ri-folder-keyhole-fill:before {
    content: "\ed68"
}

.ri-folder-keyhole-line:before {
    content: "\ed69"
}

.ri-folder-line:before {
    content: "\ed6a"
}

.ri-folder-lock-fill:before {
    content: "\ed6b"
}

.ri-folder-lock-line:before {
    content: "\ed6c"
}

.ri-folder-music-fill:before {
    content: "\ed6d"
}

.ri-folder-music-line:before {
    content: "\ed6e"
}

.ri-folder-open-fill:before {
    content: "\ed6f"
}

.ri-folder-open-line:before {
    content: "\ed70"
}

.ri-folder-received-fill:before {
    content: "\ed71"
}

.ri-folder-received-line:before {
    content: "\ed72"
}

.ri-folder-reduce-fill:before {
    content: "\ed73"
}

.ri-folder-reduce-line:before {
    content: "\ed74"
}

.ri-folder-settings-fill:before {
    content: "\ed75"
}

.ri-folder-settings-line:before {
    content: "\ed76"
}

.ri-folder-shared-fill:before {
    content: "\ed77"
}

.ri-folder-shared-line:before {
    content: "\ed78"
}

.ri-folder-shield-2-fill:before {
    content: "\ed79"
}

.ri-folder-shield-2-line:before {
    content: "\ed7a"
}

.ri-folder-shield-fill:before {
    content: "\ed7b"
}

.ri-folder-shield-line:before {
    content: "\ed7c"
}

.ri-folder-transfer-fill:before {
    content: "\ed7d"
}

.ri-folder-transfer-line:before {
    content: "\ed7e"
}

.ri-folder-unknow-fill:before {
    content: "\ed7f"
}

.ri-folder-unknow-line:before {
    content: "\ed80"
}

.ri-folder-upload-fill:before {
    content: "\ed81"
}

.ri-folder-upload-line:before {
    content: "\ed82"
}

.ri-folder-user-fill:before {
    content: "\ed83"
}

.ri-folder-user-line:before {
    content: "\ed84"
}

.ri-folder-warning-fill:before {
    content: "\ed85"
}

.ri-folder-warning-line:before {
    content: "\ed86"
}

.ri-folder-zip-fill:before {
    content: "\ed87"
}

.ri-folder-zip-line:before {
    content: "\ed88"
}

.ri-folders-fill:before {
    content: "\ed89"
}

.ri-folders-line:before {
    content: "\ed8a"
}

.ri-font-color:before {
    content: "\ed8b"
}

.ri-font-size-2:before {
    content: "\ed8c"
}

.ri-font-size:before {
    content: "\ed8d"
}

.ri-football-fill:before {
    content: "\ed8e"
}

.ri-football-line:before {
    content: "\ed8f"
}

.ri-footprint-fill:before {
    content: "\ed90"
}

.ri-footprint-line:before {
    content: "\ed91"
}

.ri-forbid-2-fill:before {
    content: "\ed92"
}

.ri-forbid-2-line:before {
    content: "\ed93"
}

.ri-forbid-fill:before {
    content: "\ed94"
}

.ri-forbid-line:before {
    content: "\ed95"
}

.ri-format-clear:before {
    content: "\ed96"
}

.ri-fridge-fill:before {
    content: "\ed97"
}

.ri-fridge-line:before {
    content: "\ed98"
}

.ri-fullscreen-exit-fill:before {
    content: "\ed99"
}

.ri-fullscreen-exit-line:before {
    content: "\ed9a"
}

.ri-fullscreen-fill:before {
    content: "\ed9b"
}

.ri-fullscreen-line:before {
    content: "\ed9c"
}

.ri-function-fill:before {
    content: "\ed9d"
}

.ri-function-line:before {
    content: "\ed9e"
}

.ri-functions:before {
    content: "\ed9f"
}

.ri-funds-box-fill:before {
    content: "\eda0"
}

.ri-funds-box-line:before {
    content: "\eda1"
}

.ri-funds-fill:before {
    content: "\eda2"
}

.ri-funds-line:before {
    content: "\eda3"
}

.ri-gallery-fill:before {
    content: "\eda4"
}

.ri-gallery-line:before {
    content: "\eda5"
}

.ri-gallery-upload-fill:before {
    content: "\eda6"
}

.ri-gallery-upload-line:before {
    content: "\eda7"
}

.ri-game-fill:before {
    content: "\eda8"
}

.ri-game-line:before {
    content: "\eda9"
}

.ri-gamepad-fill:before {
    content: "\edaa"
}

.ri-gamepad-line:before {
    content: "\edab"
}

.ri-gas-station-fill:before {
    content: "\edac"
}

.ri-gas-station-line:before {
    content: "\edad"
}

.ri-gatsby-fill:before {
    content: "\edae"
}

.ri-gatsby-line:before {
    content: "\edaf"
}

.ri-genderless-fill:before {
    content: "\edb0"
}

.ri-genderless-line:before {
    content: "\edb1"
}

.ri-ghost-2-fill:before {
    content: "\edb2"
}

.ri-ghost-2-line:before {
    content: "\edb3"
}

.ri-ghost-fill:before {
    content: "\edb4"
}

.ri-ghost-line:before {
    content: "\edb5"
}

.ri-ghost-smile-fill:before {
    content: "\edb6"
}

.ri-ghost-smile-line:before {
    content: "\edb7"
}

.ri-gift-2-fill:before {
    content: "\edb8"
}

.ri-gift-2-line:before {
    content: "\edb9"
}

.ri-gift-fill:before {
    content: "\edba"
}

.ri-gift-line:before {
    content: "\edbb"
}

.ri-git-branch-fill:before {
    content: "\edbc"
}

.ri-git-branch-line:before {
    content: "\edbd"
}

.ri-git-commit-fill:before {
    content: "\edbe"
}

.ri-git-commit-line:before {
    content: "\edbf"
}

.ri-git-merge-fill:before {
    content: "\edc0"
}

.ri-git-merge-line:before {
    content: "\edc1"
}

.ri-git-pull-request-fill:before {
    content: "\edc2"
}

.ri-git-pull-request-line:before {
    content: "\edc3"
}

.ri-git-repository-commits-fill:before {
    content: "\edc4"
}

.ri-git-repository-commits-line:before {
    content: "\edc5"
}

.ri-git-repository-fill:before {
    content: "\edc6"
}

.ri-git-repository-line:before {
    content: "\edc7"
}

.ri-git-repository-private-fill:before {
    content: "\edc8"
}

.ri-git-repository-private-line:before {
    content: "\edc9"
}

.ri-github-fill:before {
    content: "\edca"
}

.ri-github-line:before {
    content: "\edcb"
}

.ri-gitlab-fill:before {
    content: "\edcc"
}

.ri-gitlab-line:before {
    content: "\edcd"
}

.ri-global-fill:before {
    content: "\edce"
}

.ri-global-line:before {
    content: "\edcf"
}

.ri-globe-fill:before {
    content: "\edd0"
}

.ri-globe-line:before {
    content: "\edd1"
}

.ri-goblet-fill:before {
    content: "\edd2"
}

.ri-goblet-line:before {
    content: "\edd3"
}

.ri-google-fill:before {
    content: "\edd4"
}

.ri-google-line:before {
    content: "\edd5"
}

.ri-google-play-fill:before {
    content: "\edd6"
}

.ri-google-play-line:before {
    content: "\edd7"
}

.ri-government-fill:before {
    content: "\edd8"
}

.ri-government-line:before {
    content: "\edd9"
}

.ri-gps-fill:before {
    content: "\edda"
}

.ri-gps-line:before {
    content: "\eddb"
}

.ri-gradienter-fill:before {
    content: "\eddc"
}

.ri-gradienter-line:before {
    content: "\eddd"
}

.ri-grid-fill:before {
    content: "\edde"
}

.ri-grid-line:before {
    content: "\eddf"
}

.ri-group-2-fill:before {
    content: "\ede0"
}

.ri-group-2-line:before {
    content: "\ede1"
}

.ri-group-fill:before {
    content: "\ede2"
}

.ri-group-line:before {
    content: "\ede3"
}

.ri-guide-fill:before {
    content: "\ede4"
}

.ri-guide-line:before {
    content: "\ede5"
}

.ri-h-1:before {
    content: "\ede6"
}

.ri-h-2:before {
    content: "\ede7"
}

.ri-h-3:before {
    content: "\ede8"
}

.ri-h-4:before {
    content: "\ede9"
}

.ri-h-5:before {
    content: "\edea"
}

.ri-h-6:before {
    content: "\edeb"
}

.ri-hail-fill:before {
    content: "\edec"
}

.ri-hail-line:before {
    content: "\eded"
}

.ri-hammer-fill:before {
    content: "\edee"
}

.ri-hammer-line:before {
    content: "\edef"
}

.ri-hand-coin-fill:before {
    content: "\edf0"
}

.ri-hand-coin-line:before {
    content: "\edf1"
}

.ri-hand-heart-fill:before {
    content: "\edf2"
}

.ri-hand-heart-line:before {
    content: "\edf3"
}

.ri-hand-sanitizer-fill:before {
    content: "\edf4"
}

.ri-hand-sanitizer-line:before {
    content: "\edf5"
}

.ri-handbag-fill:before {
    content: "\edf6"
}

.ri-handbag-line:before {
    content: "\edf7"
}

.ri-hard-drive-2-fill:before {
    content: "\edf8"
}

.ri-hard-drive-2-line:before {
    content: "\edf9"
}

.ri-hard-drive-fill:before {
    content: "\edfa"
}

.ri-hard-drive-line:before {
    content: "\edfb"
}

.ri-hashtag:before {
    content: "\edfc"
}

.ri-haze-2-fill:before {
    content: "\edfd"
}

.ri-haze-2-line:before {
    content: "\edfe"
}

.ri-haze-fill:before {
    content: "\edff"
}

.ri-haze-line:before {
    content: "\ee00"
}

.ri-hd-fill:before {
    content: "\ee01"
}

.ri-hd-line:before {
    content: "\ee02"
}

.ri-heading:before {
    content: "\ee03"
}

.ri-headphone-fill:before {
    content: "\ee04"
}

.ri-headphone-line:before {
    content: "\ee05"
}

.ri-health-book-fill:before {
    content: "\ee06"
}

.ri-health-book-line:before {
    content: "\ee07"
}

.ri-heart-2-fill:before {
    content: "\ee08"
}

.ri-heart-2-line:before {
    content: "\ee09"
}

.ri-heart-3-fill:before {
    content: "\ee0a"
}

.ri-heart-3-line:before {
    content: "\ee0b"
}

.ri-heart-add-fill:before {
    content: "\ee0c"
}

.ri-heart-add-line:before {
    content: "\ee0d"
}

.ri-heart-fill:before {
    content: "\ee0e"
}

.ri-heart-line:before {
    content: "\ee0f"
}

.ri-heart-pulse-fill:before {
    content: "\ee10"
}

.ri-heart-pulse-line:before {
    content: "\ee11"
}

.ri-hearts-fill:before {
    content: "\ee12"
}

.ri-hearts-line:before {
    content: "\ee13"
}

.ri-heavy-showers-fill:before {
    content: "\ee14"
}

.ri-heavy-showers-line:before {
    content: "\ee15"
}

.ri-history-fill:before {
    content: "\ee16"
}

.ri-history-line:before {
    content: "\ee17"
}

.ri-home-2-fill:before {
    content: "\ee18"
}

.ri-home-2-line:before {
    content: "\ee19"
}

.ri-home-3-fill:before {
    content: "\ee1a"
}

.ri-home-3-line:before {
    content: "\ee1b"
}

.ri-home-4-fill:before {
    content: "\ee1c"
}

.ri-home-4-line:before {
    content: "\ee1d"
}

.ri-home-5-fill:before {
    content: "\ee1e"
}

.ri-home-5-line:before {
    content: "\ee1f"
}

.ri-home-6-fill:before {
    content: "\ee20"
}

.ri-home-6-line:before {
    content: "\ee21"
}

.ri-home-7-fill:before {
    content: "\ee22"
}

.ri-home-7-line:before {
    content: "\ee23"
}

.ri-home-8-fill:before {
    content: "\ee24"
}

.ri-home-8-line:before {
    content: "\ee25"
}

.ri-home-fill:before {
    content: "\ee26"
}

.ri-home-gear-fill:before {
    content: "\ee27"
}

.ri-home-gear-line:before {
    content: "\ee28"
}

.ri-home-heart-fill:before {
    content: "\ee29"
}

.ri-home-heart-line:before {
    content: "\ee2a"
}

.ri-home-line:before {
    content: "\ee2b"
}

.ri-home-smile-2-fill:before {
    content: "\ee2c"
}

.ri-home-smile-2-line:before {
    content: "\ee2d"
}

.ri-home-smile-fill:before {
    content: "\ee2e"
}

.ri-home-smile-line:before {
    content: "\ee2f"
}

.ri-home-wifi-fill:before {
    content: "\ee30"
}

.ri-home-wifi-line:before {
    content: "\ee31"
}

.ri-honor-of-kings-fill:before {
    content: "\ee32"
}

.ri-honor-of-kings-line:before {
    content: "\ee33"
}

.ri-honour-fill:before {
    content: "\ee34"
}

.ri-honour-line:before {
    content: "\ee35"
}

.ri-hospital-fill:before {
    content: "\ee36"
}

.ri-hospital-line:before {
    content: "\ee37"
}

.ri-hotel-bed-fill:before {
    content: "\ee38"
}

.ri-hotel-bed-line:before {
    content: "\ee39"
}

.ri-hotel-fill:before {
    content: "\ee3a"
}

.ri-hotel-line:before {
    content: "\ee3b"
}

.ri-hotspot-fill:before {
    content: "\ee3c"
}

.ri-hotspot-line:before {
    content: "\ee3d"
}

.ri-hq-fill:before {
    content: "\ee3e"
}

.ri-hq-line:before {
    content: "\ee3f"
}

.ri-html5-fill:before {
    content: "\ee40"
}

.ri-html5-line:before {
    content: "\ee41"
}

.ri-ie-fill:before {
    content: "\ee42"
}

.ri-ie-line:before {
    content: "\ee43"
}

.ri-image-2-fill:before {
    content: "\ee44"
}

.ri-image-2-line:before {
    content: "\ee45"
}

.ri-image-add-fill:before {
    content: "\ee46"
}

.ri-image-add-line:before {
    content: "\ee47"
}

.ri-image-edit-fill:before {
    content: "\ee48"
}

.ri-image-edit-line:before {
    content: "\ee49"
}

.ri-image-fill:before {
    content: "\ee4a"
}

.ri-image-line:before {
    content: "\ee4b"
}

.ri-inbox-archive-fill:before {
    content: "\ee4c"
}

.ri-inbox-archive-line:before {
    content: "\ee4d"
}

.ri-inbox-fill:before {
    content: "\ee4e"
}

.ri-inbox-line:before {
    content: "\ee4f"
}

.ri-inbox-unarchive-fill:before {
    content: "\ee50"
}

.ri-inbox-unarchive-line:before {
    content: "\ee51"
}

.ri-increase-decrease-fill:before {
    content: "\ee52"
}

.ri-increase-decrease-line:before {
    content: "\ee53"
}

.ri-indent-decrease:before {
    content: "\ee54"
}

.ri-indent-increase:before {
    content: "\ee55"
}

.ri-indeterminate-circle-fill:before {
    content: "\ee56"
}

.ri-indeterminate-circle-line:before {
    content: "\ee57"
}

.ri-information-fill:before {
    content: "\ee58"
}

.ri-information-line:before {
    content: "\ee59"
}

.ri-infrared-thermometer-fill:before {
    content: "\ee5a"
}

.ri-infrared-thermometer-line:before {
    content: "\ee5b"
}

.ri-ink-bottle-fill:before {
    content: "\ee5c"
}

.ri-ink-bottle-line:before {
    content: "\ee5d"
}

.ri-input-cursor-move:before {
    content: "\ee5e"
}

.ri-input-method-fill:before {
    content: "\ee5f"
}

.ri-input-method-line:before {
    content: "\ee60"
}

.ri-insert-column-left:before {
    content: "\ee61"
}

.ri-insert-column-right:before {
    content: "\ee62"
}

.ri-insert-row-bottom:before {
    content: "\ee63"
}

.ri-insert-row-top:before {
    content: "\ee64"
}

.ri-instagram-fill:before {
    content: "\ee65"
}

.ri-instagram-line:before {
    content: "\ee66"
}

.ri-install-fill:before {
    content: "\ee67"
}

.ri-install-line:before {
    content: "\ee68"
}

.ri-invision-fill:before {
    content: "\ee69"
}

.ri-invision-line:before {
    content: "\ee6a"
}

.ri-italic:before {
    content: "\ee6b"
}

.ri-kakao-talk-fill:before {
    content: "\ee6c"
}

.ri-kakao-talk-line:before {
    content: "\ee6d"
}

.ri-key-2-fill:before {
    content: "\ee6e"
}

.ri-key-2-line:before {
    content: "\ee6f"
}

.ri-key-fill:before {
    content: "\ee70"
}

.ri-key-line:before {
    content: "\ee71"
}

.ri-keyboard-box-fill:before {
    content: "\ee72"
}

.ri-keyboard-box-line:before {
    content: "\ee73"
}

.ri-keyboard-fill:before {
    content: "\ee74"
}

.ri-keyboard-line:before {
    content: "\ee75"
}

.ri-keynote-fill:before {
    content: "\ee76"
}

.ri-keynote-line:before {
    content: "\ee77"
}

.ri-knife-blood-fill:before {
    content: "\ee78"
}

.ri-knife-blood-line:before {
    content: "\ee79"
}

.ri-knife-fill:before {
    content: "\ee7a"
}

.ri-knife-line:before {
    content: "\ee7b"
}

.ri-landscape-fill:before {
    content: "\ee7c"
}

.ri-landscape-line:before {
    content: "\ee7d"
}

.ri-layout-2-fill:before {
    content: "\ee7e"
}

.ri-layout-2-line:before {
    content: "\ee7f"
}

.ri-layout-3-fill:before {
    content: "\ee80"
}

.ri-layout-3-line:before {
    content: "\ee81"
}

.ri-layout-4-fill:before {
    content: "\ee82"
}

.ri-layout-4-line:before {
    content: "\ee83"
}

.ri-layout-5-fill:before {
    content: "\ee84"
}

.ri-layout-5-line:before {
    content: "\ee85"
}

.ri-layout-6-fill:before {
    content: "\ee86"
}

.ri-layout-6-line:before {
    content: "\ee87"
}

.ri-layout-bottom-2-fill:before {
    content: "\ee88"
}

.ri-layout-bottom-2-line:before {
    content: "\ee89"
}

.ri-layout-bottom-fill:before {
    content: "\ee8a"
}

.ri-layout-bottom-line:before {
    content: "\ee8b"
}

.ri-layout-column-fill:before {
    content: "\ee8c"
}

.ri-layout-column-line:before {
    content: "\ee8d"
}

.ri-layout-fill:before {
    content: "\ee8e"
}

.ri-layout-grid-fill:before {
    content: "\ee8f"
}

.ri-layout-grid-line:before {
    content: "\ee90"
}

.ri-layout-left-2-fill:before {
    content: "\ee91"
}

.ri-layout-left-2-line:before {
    content: "\ee92"
}

.ri-layout-left-fill:before {
    content: "\ee93"
}

.ri-layout-left-line:before {
    content: "\ee94"
}

.ri-layout-line:before {
    content: "\ee95"
}

.ri-layout-masonry-fill:before {
    content: "\ee96"
}

.ri-layout-masonry-line:before {
    content: "\ee97"
}

.ri-layout-right-2-fill:before {
    content: "\ee98"
}

.ri-layout-right-2-line:before {
    content: "\ee99"
}

.ri-layout-right-fill:before {
    content: "\ee9a"
}

.ri-layout-right-line:before {
    content: "\ee9b"
}

.ri-layout-row-fill:before {
    content: "\ee9c"
}

.ri-layout-row-line:before {
    content: "\ee9d"
}

.ri-layout-top-2-fill:before {
    content: "\ee9e"
}

.ri-layout-top-2-line:before {
    content: "\ee9f"
}

.ri-layout-top-fill:before {
    content: "\eea0"
}

.ri-layout-top-line:before {
    content: "\eea1"
}

.ri-leaf-fill:before {
    content: "\eea2"
}

.ri-leaf-line:before {
    content: "\eea3"
}

.ri-lifebuoy-fill:before {
    content: "\eea4"
}

.ri-lifebuoy-line:before {
    content: "\eea5"
}

.ri-lightbulb-fill:before {
    content: "\eea6"
}

.ri-lightbulb-flash-fill:before {
    content: "\eea7"
}

.ri-lightbulb-flash-line:before {
    content: "\eea8"
}

.ri-lightbulb-line:before {
    content: "\eea9"
}

.ri-line-chart-fill:before {
    content: "\eeaa"
}

.ri-line-chart-line:before {
    content: "\eeab"
}

.ri-line-fill:before {
    content: "\eeac"
}

.ri-line-height:before {
    content: "\eead"
}

.ri-line-line:before {
    content: "\eeae"
}

.ri-link-m:before {
    content: "\eeaf"
}

.ri-link-unlink-m:before {
    content: "\eeb0"
}

.ri-link-unlink:before {
    content: "\eeb1"
}

.ri-link:before {
    content: "\eeb2"
}

.ri-linkedin-box-fill:before {
    content: "\eeb3"
}

.ri-linkedin-box-line:before {
    content: "\eeb4"
}

.ri-linkedin-fill:before {
    content: "\eeb5"
}

.ri-linkedin-line:before {
    content: "\eeb6"
}

.ri-links-fill:before {
    content: "\eeb7"
}

.ri-links-line:before {
    content: "\eeb8"
}

.ri-list-check-2:before {
    content: "\eeb9"
}

.ri-list-check:before {
    content: "\eeba"
}

.ri-list-ordered:before {
    content: "\eebb"
}

.ri-list-settings-fill:before {
    content: "\eebc"
}

.ri-list-settings-line:before {
    content: "\eebd"
}

.ri-list-unordered:before {
    content: "\eebe"
}

.ri-live-fill:before {
    content: "\eebf"
}

.ri-live-line:before {
    content: "\eec0"
}

.ri-loader-2-fill:before {
    content: "\eec1"
}

.ri-loader-2-line:before {
    content: "\eec2"
}

.ri-loader-3-fill:before {
    content: "\eec3"
}

.ri-loader-3-line:before {
    content: "\eec4"
}

.ri-loader-4-fill:before {
    content: "\eec5"
}

.ri-loader-4-line:before {
    content: "\eec6"
}

.ri-loader-5-fill:before {
    content: "\eec7"
}

.ri-loader-5-line:before {
    content: "\eec8"
}

.ri-loader-fill:before {
    content: "\eec9"
}

.ri-loader-line:before {
    content: "\eeca"
}

.ri-lock-2-fill:before {
    content: "\eecb"
}

.ri-lock-2-line:before {
    content: "\eecc"
}

.ri-lock-fill:before {
    content: "\eecd"
}

.ri-lock-line:before {
    content: "\eece"
}

.ri-lock-password-fill:before {
    content: "\eecf"
}

.ri-lock-password-line:before {
    content: "\eed0"
}

.ri-lock-unlock-fill:before {
    content: "\eed1"
}

.ri-lock-unlock-line:before {
    content: "\eed2"
}

.ri-login-box-fill:before {
    content: "\eed3"
}

.ri-login-box-line:before {
    content: "\eed4"
}

.ri-login-circle-fill:before {
    content: "\eed5"
}

.ri-login-circle-line:before {
    content: "\eed6"
}

.ri-logout-box-fill:before {
    content: "\eed7"
}

.ri-logout-box-line:before {
    content: "\eed8"
}

.ri-logout-box-r-fill:before {
    content: "\eed9"
}

.ri-logout-box-r-line:before {
    content: "\eeda"
}

.ri-logout-circle-fill:before {
    content: "\eedb"
}

.ri-logout-circle-line:before {
    content: "\eedc"
}

.ri-logout-circle-r-fill:before {
    content: "\eedd"
}

.ri-logout-circle-r-line:before {
    content: "\eede"
}

.ri-luggage-cart-fill:before {
    content: "\eedf"
}

.ri-luggage-cart-line:before {
    content: "\eee0"
}

.ri-luggage-deposit-fill:before {
    content: "\eee1"
}

.ri-luggage-deposit-line:before {
    content: "\eee2"
}

.ri-lungs-fill:before {
    content: "\eee3"
}

.ri-lungs-line:before {
    content: "\eee4"
}

.ri-mac-fill:before {
    content: "\eee5"
}

.ri-mac-line:before {
    content: "\eee6"
}

.ri-macbook-fill:before {
    content: "\eee7"
}

.ri-macbook-line:before {
    content: "\eee8"
}

.ri-magic-fill:before {
    content: "\eee9"
}

.ri-magic-line:before {
    content: "\eeea"
}

.ri-mail-add-fill:before {
    content: "\eeeb"
}

.ri-mail-add-line:before {
    content: "\eeec"
}

.ri-mail-check-fill:before {
    content: "\eeed"
}

.ri-mail-check-line:before {
    content: "\eeee"
}

.ri-mail-close-fill:before {
    content: "\eeef"
}

.ri-mail-close-line:before {
    content: "\eef0"
}

.ri-mail-download-fill:before {
    content: "\eef1"
}

.ri-mail-download-line:before {
    content: "\eef2"
}

.ri-mail-fill:before {
    content: "\eef3"
}

.ri-mail-forbid-fill:before {
    content: "\eef4"
}

.ri-mail-forbid-line:before {
    content: "\eef5"
}

.ri-mail-line:before {
    content: "\eef6"
}

.ri-mail-lock-fill:before {
    content: "\eef7"
}

.ri-mail-lock-line:before {
    content: "\eef8"
}

.ri-mail-open-fill:before {
    content: "\eef9"
}

.ri-mail-open-line:before {
    content: "\eefa"
}

.ri-mail-send-fill:before {
    content: "\eefb"
}

.ri-mail-send-line:before {
    content: "\eefc"
}

.ri-mail-settings-fill:before {
    content: "\eefd"
}

.ri-mail-settings-line:before {
    content: "\eefe"
}

.ri-mail-star-fill:before {
    content: "\eeff"
}

.ri-mail-star-line:before {
    content: "\ef00"
}

.ri-mail-unread-fill:before {
    content: "\ef01"
}

.ri-mail-unread-line:before {
    content: "\ef02"
}

.ri-mail-volume-fill:before {
    content: "\ef03"
}

.ri-mail-volume-line:before {
    content: "\ef04"
}

.ri-map-2-fill:before {
    content: "\ef05"
}

.ri-map-2-line:before {
    content: "\ef06"
}

.ri-map-fill:before {
    content: "\ef07"
}

.ri-map-line:before {
    content: "\ef08"
}

.ri-map-pin-2-fill:before {
    content: "\ef09"
}

.ri-map-pin-2-line:before {
    content: "\ef0a"
}

.ri-map-pin-3-fill:before {
    content: "\ef0b"
}

.ri-map-pin-3-line:before {
    content: "\ef0c"
}

.ri-map-pin-4-fill:before {
    content: "\ef0d"
}

.ri-map-pin-4-line:before {
    content: "\ef0e"
}

.ri-map-pin-5-fill:before {
    content: "\ef0f"
}

.ri-map-pin-5-line:before {
    content: "\ef10"
}

.ri-map-pin-add-fill:before {
    content: "\ef11"
}

.ri-map-pin-add-line:before {
    content: "\ef12"
}

.ri-map-pin-fill:before {
    content: "\ef13"
}

.ri-map-pin-line:before {
    content: "\ef14"
}

.ri-map-pin-range-fill:before {
    content: "\ef15"
}

.ri-map-pin-range-line:before {
    content: "\ef16"
}

.ri-map-pin-time-fill:before {
    content: "\ef17"
}

.ri-map-pin-time-line:before {
    content: "\ef18"
}

.ri-map-pin-user-fill:before {
    content: "\ef19"
}

.ri-map-pin-user-line:before {
    content: "\ef1a"
}

.ri-mark-pen-fill:before {
    content: "\ef1b"
}

.ri-mark-pen-line:before {
    content: "\ef1c"
}

.ri-markdown-fill:before {
    content: "\ef1d"
}

.ri-markdown-line:before {
    content: "\ef1e"
}

.ri-markup-fill:before {
    content: "\ef1f"
}

.ri-markup-line:before {
    content: "\ef20"
}

.ri-mastercard-fill:before {
    content: "\ef21"
}

.ri-mastercard-line:before {
    content: "\ef22"
}

.ri-mastodon-fill:before {
    content: "\ef23"
}

.ri-mastodon-line:before {
    content: "\ef24"
}

.ri-medal-2-fill:before {
    content: "\ef25"
}

.ri-medal-2-line:before {
    content: "\ef26"
}

.ri-medal-fill:before {
    content: "\ef27"
}

.ri-medal-line:before {
    content: "\ef28"
}

.ri-medicine-bottle-fill:before {
    content: "\ef29"
}

.ri-medicine-bottle-line:before {
    content: "\ef2a"
}

.ri-medium-fill:before {
    content: "\ef2b"
}

.ri-medium-line:before {
    content: "\ef2c"
}

.ri-men-fill:before {
    content: "\ef2d"
}

.ri-men-line:before {
    content: "\ef2e"
}

.ri-mental-health-fill:before {
    content: "\ef2f"
}

.ri-mental-health-line:before {
    content: "\ef30"
}

.ri-menu-2-fill:before {
    content: "\ef31"
}

.ri-menu-2-line:before {
    content: "\ef32"
}

.ri-menu-3-fill:before {
    content: "\ef33"
}

.ri-menu-3-line:before {
    content: "\ef34"
}

.ri-menu-4-fill:before {
    content: "\ef35"
}

.ri-menu-4-line:before {
    content: "\ef36"
}

.ri-menu-5-fill:before {
    content: "\ef37"
}

.ri-menu-5-line:before {
    content: "\ef38"
}

.ri-menu-add-fill:before {
    content: "\ef39"
}

.ri-menu-add-line:before {
    content: "\ef3a"
}

.ri-menu-fill:before {
    content: "\ef3b"
}

.ri-menu-fold-fill:before {
    content: "\ef3c"
}

.ri-menu-fold-line:before {
    content: "\ef3d"
}

.ri-menu-line:before {
    content: "\ef3e"
}

.ri-menu-unfold-fill:before {
    content: "\ef3f"
}

.ri-menu-unfold-line:before {
    content: "\ef40"
}

.ri-merge-cells-horizontal:before {
    content: "\ef41"
}

.ri-merge-cells-vertical:before {
    content: "\ef42"
}

.ri-message-2-fill:before {
    content: "\ef43"
}

.ri-message-2-line:before {
    content: "\ef44"
}

.ri-message-3-fill:before {
    content: "\ef45"
}

.ri-message-3-line:before {
    content: "\ef46"
}

.ri-message-fill:before {
    content: "\ef47"
}

.ri-message-line:before {
    content: "\ef48"
}

.ri-messenger-fill:before {
    content: "\ef49"
}

.ri-messenger-line:before {
    content: "\ef4a"
}

.ri-meteor-fill:before {
    content: "\ef4b"
}

.ri-meteor-line:before {
    content: "\ef4c"
}

.ri-mic-2-fill:before {
    content: "\ef4d"
}

.ri-mic-2-line:before {
    content: "\ef4e"
}

.ri-mic-fill:before {
    content: "\ef4f"
}

.ri-mic-line:before {
    content: "\ef50"
}

.ri-mic-off-fill:before {
    content: "\ef51"
}

.ri-mic-off-line:before {
    content: "\ef52"
}

.ri-mickey-fill:before {
    content: "\ef53"
}

.ri-mickey-line:before {
    content: "\ef54"
}

.ri-microscope-fill:before {
    content: "\ef55"
}

.ri-microscope-line:before {
    content: "\ef56"
}

.ri-microsoft-fill:before {
    content: "\ef57"
}

.ri-microsoft-line:before {
    content: "\ef58"
}

.ri-mind-map:before {
    content: "\ef59"
}

.ri-mini-program-fill:before {
    content: "\ef5a"
}

.ri-mini-program-line:before {
    content: "\ef5b"
}

.ri-mist-fill:before {
    content: "\ef5c"
}

.ri-mist-line:before {
    content: "\ef5d"
}

.ri-money-cny-box-fill:before {
    content: "\ef5e"
}

.ri-money-cny-box-line:before {
    content: "\ef5f"
}

.ri-money-cny-circle-fill:before {
    content: "\ef60"
}

.ri-money-cny-circle-line:before {
    content: "\ef61"
}

.ri-money-dollar-box-fill:before {
    content: "\ef62"
}

.ri-money-dollar-box-line:before {
    content: "\ef63"
}

.ri-money-dollar-circle-fill:before {
    content: "\ef64"
}

.ri-money-dollar-circle-line:before {
    content: "\ef65"
}

.ri-money-euro-box-fill:before {
    content: "\ef66"
}

.ri-money-euro-box-line:before {
    content: "\ef67"
}

.ri-money-euro-circle-fill:before {
    content: "\ef68"
}

.ri-money-euro-circle-line:before {
    content: "\ef69"
}

.ri-money-pound-box-fill:before {
    content: "\ef6a"
}

.ri-money-pound-box-line:before {
    content: "\ef6b"
}

.ri-money-pound-circle-fill:before {
    content: "\ef6c"
}

.ri-money-pound-circle-line:before {
    content: "\ef6d"
}

.ri-moon-clear-fill:before {
    content: "\ef6e"
}

.ri-moon-clear-line:before {
    content: "\ef6f"
}

.ri-moon-cloudy-fill:before {
    content: "\ef70"
}

.ri-moon-cloudy-line:before {
    content: "\ef71"
}

.ri-moon-fill:before {
    content: "\ef72"
}

.ri-moon-foggy-fill:before {
    content: "\ef73"
}

.ri-moon-foggy-line:before {
    content: "\ef74"
}

.ri-moon-line:before {
    content: "\ef75"
}

.ri-more-2-fill:before {
    content: "\ef76"
}

.ri-more-2-line:before {
    content: "\ef77"
}

.ri-more-fill:before {
    content: "\ef78"
}

.ri-more-line:before {
    content: "\ef79"
}

.ri-motorbike-fill:before {
    content: "\ef7a"
}

.ri-motorbike-line:before {
    content: "\ef7b"
}

.ri-mouse-fill:before {
    content: "\ef7c"
}

.ri-mouse-line:before {
    content: "\ef7d"
}

.ri-movie-2-fill:before {
    content: "\ef7e"
}

.ri-movie-2-line:before {
    content: "\ef7f"
}

.ri-movie-fill:before {
    content: "\ef80"
}

.ri-movie-line:before {
    content: "\ef81"
}

.ri-music-2-fill:before {
    content: "\ef82"
}

.ri-music-2-line:before {
    content: "\ef83"
}

.ri-music-fill:before {
    content: "\ef84"
}

.ri-music-line:before {
    content: "\ef85"
}

.ri-mv-fill:before {
    content: "\ef86"
}

.ri-mv-line:before {
    content: "\ef87"
}

.ri-navigation-fill:before {
    content: "\ef88"
}

.ri-navigation-line:before {
    content: "\ef89"
}

.ri-netease-cloud-music-fill:before {
    content: "\ef8a"
}

.ri-netease-cloud-music-line:before {
    content: "\ef8b"
}

.ri-netflix-fill:before {
    content: "\ef8c"
}

.ri-netflix-line:before {
    content: "\ef8d"
}

.ri-newspaper-fill:before {
    content: "\ef8e"
}

.ri-newspaper-line:before {
    content: "\ef8f"
}

.ri-node-tree:before {
    content: "\ef90"
}

.ri-notification-2-fill:before {
    content: "\ef91"
}

.ri-notification-2-line:before {
    content: "\ef92"
}

.ri-notification-3-fill:before {
    content: "\ef93"
}

.ri-notification-3-line:before {
    content: "\ef94"
}

.ri-notification-4-fill:before {
    content: "\ef95"
}

.ri-notification-4-line:before {
    content: "\ef96"
}

.ri-notification-badge-fill:before {
    content: "\ef97"
}

.ri-notification-badge-line:before {
    content: "\ef98"
}

.ri-notification-fill:before {
    content: "\ef99"
}

.ri-notification-line:before {
    content: "\ef9a"
}

.ri-notification-off-fill:before {
    content: "\ef9b"
}

.ri-notification-off-line:before {
    content: "\ef9c"
}

.ri-npmjs-fill:before {
    content: "\ef9d"
}

.ri-npmjs-line:before {
    content: "\ef9e"
}

.ri-number-0:before {
    content: "\ef9f"
}

.ri-number-1:before {
    content: "\efa0"
}

.ri-number-2:before {
    content: "\efa1"
}

.ri-number-3:before {
    content: "\efa2"
}

.ri-number-4:before {
    content: "\efa3"
}

.ri-number-5:before {
    content: "\efa4"
}

.ri-number-6:before {
    content: "\efa5"
}

.ri-number-7:before {
    content: "\efa6"
}

.ri-number-8:before {
    content: "\efa7"
}

.ri-number-9:before {
    content: "\efa8"
}

.ri-numbers-fill:before {
    content: "\efa9"
}

.ri-numbers-line:before {
    content: "\efaa"
}

.ri-nurse-fill:before {
    content: "\efab"
}

.ri-nurse-line:before {
    content: "\efac"
}

.ri-oil-fill:before {
    content: "\efad"
}

.ri-oil-line:before {
    content: "\efae"
}

.ri-omega:before {
    content: "\efaf"
}

.ri-open-arm-fill:before {
    content: "\efb0"
}

.ri-open-arm-line:before {
    content: "\efb1"
}

.ri-open-source-fill:before {
    content: "\efb2"
}

.ri-open-source-line:before {
    content: "\efb3"
}

.ri-opera-fill:before {
    content: "\efb4"
}

.ri-opera-line:before {
    content: "\efb5"
}

.ri-order-play-fill:before {
    content: "\efb6"
}

.ri-order-play-line:before {
    content: "\efb7"
}

.ri-organization-chart:before {
    content: "\efb8"
}

.ri-outlet-2-fill:before {
    content: "\efb9"
}

.ri-outlet-2-line:before {
    content: "\efba"
}

.ri-outlet-fill:before {
    content: "\efbb"
}

.ri-outlet-line:before {
    content: "\efbc"
}

.ri-page-separator:before {
    content: "\efbd"
}

.ri-pages-fill:before {
    content: "\efbe"
}

.ri-pages-line:before {
    content: "\efbf"
}

.ri-paint-brush-fill:before {
    content: "\efc0"
}

.ri-paint-brush-line:before {
    content: "\efc1"
}

.ri-paint-fill:before {
    content: "\efc2"
}

.ri-paint-line:before {
    content: "\efc3"
}

.ri-palette-fill:before {
    content: "\efc4"
}

.ri-palette-line:before {
    content: "\efc5"
}

.ri-pantone-fill:before {
    content: "\efc6"
}

.ri-pantone-line:before {
    content: "\efc7"
}

.ri-paragraph:before {
    content: "\efc8"
}

.ri-parent-fill:before {
    content: "\efc9"
}

.ri-parent-line:before {
    content: "\efca"
}

.ri-parentheses-fill:before {
    content: "\efcb"
}

.ri-parentheses-line:before {
    content: "\efcc"
}

.ri-parking-box-fill:before {
    content: "\efcd"
}

.ri-parking-box-line:before {
    content: "\efce"
}

.ri-parking-fill:before {
    content: "\efcf"
}

.ri-parking-line:before {
    content: "\efd0"
}

.ri-passport-fill:before {
    content: "\efd1"
}

.ri-passport-line:before {
    content: "\efd2"
}

.ri-patreon-fill:before {
    content: "\efd3"
}

.ri-patreon-line:before {
    content: "\efd4"
}

.ri-pause-circle-fill:before {
    content: "\efd5"
}

.ri-pause-circle-line:before {
    content: "\efd6"
}

.ri-pause-fill:before {
    content: "\efd7"
}

.ri-pause-line:before {
    content: "\efd8"
}

.ri-pause-mini-fill:before {
    content: "\efd9"
}

.ri-pause-mini-line:before {
    content: "\efda"
}

.ri-paypal-fill:before {
    content: "\efdb"
}

.ri-paypal-line:before {
    content: "\efdc"
}

.ri-pen-nib-fill:before {
    content: "\efdd"
}

.ri-pen-nib-line:before {
    content: "\efde"
}

.ri-pencil-fill:before {
    content: "\efdf"
}

.ri-pencil-line:before {
    content: "\efe0"
}

.ri-pencil-ruler-2-fill:before {
    content: "\efe1"
}

.ri-pencil-ruler-2-line:before {
    content: "\efe2"
}

.ri-pencil-ruler-fill:before {
    content: "\efe3"
}

.ri-pencil-ruler-line:before {
    content: "\efe4"
}

.ri-percent-fill:before {
    content: "\efe5"
}

.ri-percent-line:before {
    content: "\efe6"
}

.ri-phone-camera-fill:before {
    content: "\efe7"
}

.ri-phone-camera-line:before {
    content: "\efe8"
}

.ri-phone-fill:before {
    content: "\efe9"
}

.ri-phone-find-fill:before {
    content: "\efea"
}

.ri-phone-find-line:before {
    content: "\efeb"
}

.ri-phone-line:before {
    content: "\efec"
}

.ri-phone-lock-fill:before {
    content: "\efed"
}

.ri-phone-lock-line:before {
    content: "\efee"
}

.ri-picture-in-picture-2-fill:before {
    content: "\efef"
}

.ri-picture-in-picture-2-line:before {
    content: "\eff0"
}

.ri-picture-in-picture-exit-fill:before {
    content: "\eff1"
}

.ri-picture-in-picture-exit-line:before {
    content: "\eff2"
}

.ri-picture-in-picture-fill:before {
    content: "\eff3"
}

.ri-picture-in-picture-line:before {
    content: "\eff4"
}

.ri-pie-chart-2-fill:before {
    content: "\eff5"
}

.ri-pie-chart-2-line:before {
    content: "\eff6"
}

.ri-pie-chart-box-fill:before {
    content: "\eff7"
}

.ri-pie-chart-box-line:before {
    content: "\eff8"
}

.ri-pie-chart-fill:before {
    content: "\eff9"
}

.ri-pie-chart-line:before {
    content: "\effa"
}

.ri-pin-distance-fill:before {
    content: "\effb"
}

.ri-pin-distance-line:before {
    content: "\effc"
}

.ri-ping-pong-fill:before {
    content: "\effd"
}

.ri-ping-pong-line:before {
    content: "\effe"
}

.ri-pinterest-fill:before {
    content: "\efff"
}

.ri-pinterest-line:before {
    content: "\f000"
}

.ri-pinyin-input:before {
    content: "\f001"
}

.ri-pixelfed-fill:before {
    content: "\f002"
}

.ri-pixelfed-line:before {
    content: "\f003"
}

.ri-plane-fill:before {
    content: "\f004"
}

.ri-plane-line:before {
    content: "\f005"
}

.ri-plant-fill:before {
    content: "\f006"
}

.ri-plant-line:before {
    content: "\f007"
}

.ri-play-circle-fill:before {
    content: "\f008"
}

.ri-play-circle-line:before {
    content: "\f009"
}

.ri-play-fill:before {
    content: "\f00a"
}

.ri-play-line:before {
    content: "\f00b"
}

.ri-play-list-2-fill:before {
    content: "\f00c"
}

.ri-play-list-2-line:before {
    content: "\f00d"
}

.ri-play-list-add-fill:before {
    content: "\f00e"
}

.ri-play-list-add-line:before {
    content: "\f00f"
}

.ri-play-list-fill:before {
    content: "\f010"
}

.ri-play-list-line:before {
    content: "\f011"
}

.ri-play-mini-fill:before {
    content: "\f012"
}

.ri-play-mini-line:before {
    content: "\f013"
}

.ri-playstation-fill:before {
    content: "\f014"
}

.ri-playstation-line:before {
    content: "\f015"
}

.ri-plug-2-fill:before {
    content: "\f016"
}

.ri-plug-2-line:before {
    content: "\f017"
}

.ri-plug-fill:before {
    content: "\f018"
}

.ri-plug-line:before {
    content: "\f019"
}

.ri-polaroid-2-fill:before {
    content: "\f01a"
}

.ri-polaroid-2-line:before {
    content: "\f01b"
}

.ri-polaroid-fill:before {
    content: "\f01c"
}

.ri-polaroid-line:before {
    content: "\f01d"
}

.ri-police-car-fill:before {
    content: "\f01e"
}

.ri-police-car-line:before {
    content: "\f01f"
}

.ri-price-tag-2-fill:before {
    content: "\f020"
}

.ri-price-tag-2-line:before {
    content: "\f021"
}

.ri-price-tag-3-fill:before {
    content: "\f022"
}

.ri-price-tag-3-line:before {
    content: "\f023"
}

.ri-price-tag-fill:before {
    content: "\f024"
}

.ri-price-tag-line:before {
    content: "\f025"
}

.ri-printer-cloud-fill:before {
    content: "\f026"
}

.ri-printer-cloud-line:before {
    content: "\f027"
}

.ri-printer-fill:before {
    content: "\f028"
}

.ri-printer-line:before {
    content: "\f029"
}

.ri-product-hunt-fill:before {
    content: "\f02a"
}

.ri-product-hunt-line:before {
    content: "\f02b"
}

.ri-profile-fill:before {
    content: "\f02c"
}

.ri-profile-line:before {
    content: "\f02d"
}

.ri-projector-2-fill:before {
    content: "\f02e"
}

.ri-projector-2-line:before {
    content: "\f02f"
}

.ri-projector-fill:before {
    content: "\f030"
}

.ri-projector-line:before {
    content: "\f031"
}

.ri-psychotherapy-fill:before {
    content: "\f032"
}

.ri-psychotherapy-line:before {
    content: "\f033"
}

.ri-pulse-fill:before {
    content: "\f034"
}

.ri-pulse-line:before {
    content: "\f035"
}

.ri-pushpin-2-fill:before {
    content: "\f036"
}

.ri-pushpin-2-line:before {
    content: "\f037"
}

.ri-pushpin-fill:before {
    content: "\f038"
}

.ri-pushpin-line:before {
    content: "\f039"
}

.ri-qq-fill:before {
    content: "\f03a"
}

.ri-qq-line:before {
    content: "\f03b"
}

.ri-qr-code-fill:before {
    content: "\f03c"
}

.ri-qr-code-line:before {
    content: "\f03d"
}

.ri-qr-scan-2-fill:before {
    content: "\f03e"
}

.ri-qr-scan-2-line:before {
    content: "\f03f"
}

.ri-qr-scan-fill:before {
    content: "\f040"
}

.ri-qr-scan-line:before {
    content: "\f041"
}

.ri-question-answer-fill:before {
    content: "\f042"
}

.ri-question-answer-line:before {
    content: "\f043"
}

.ri-question-fill:before {
    content: "\f044"
}

.ri-question-line:before {
    content: "\f045"
}

.ri-question-mark:before {
    content: "\f046"
}

.ri-questionnaire-fill:before {
    content: "\f047"
}

.ri-questionnaire-line:before {
    content: "\f048"
}

.ri-quill-pen-fill:before {
    content: "\f049"
}

.ri-quill-pen-line:before {
    content: "\f04a"
}

.ri-radar-fill:before {
    content: "\f04b"
}

.ri-radar-line:before {
    content: "\f04c"
}

.ri-radio-2-fill:before {
    content: "\f04d"
}

.ri-radio-2-line:before {
    content: "\f04e"
}

.ri-radio-button-fill:before {
    content: "\f04f"
}

.ri-radio-button-line:before {
    content: "\f050"
}

.ri-radio-fill:before {
    content: "\f051"
}

.ri-radio-line:before {
    content: "\f052"
}

.ri-rainbow-fill:before {
    content: "\f053"
}

.ri-rainbow-line:before {
    content: "\f054"
}

.ri-rainy-fill:before {
    content: "\f055"
}

.ri-rainy-line:before {
    content: "\f056"
}

.ri-reactjs-fill:before {
    content: "\f057"
}

.ri-reactjs-line:before {
    content: "\f058"
}

.ri-record-circle-fill:before {
    content: "\f059"
}

.ri-record-circle-line:before {
    content: "\f05a"
}

.ri-record-mail-fill:before {
    content: "\f05b"
}

.ri-record-mail-line:before {
    content: "\f05c"
}

.ri-recycle-fill:before {
    content: "\f05d"
}

.ri-recycle-line:before {
    content: "\f05e"
}

.ri-red-packet-fill:before {
    content: "\f05f"
}

.ri-red-packet-line:before {
    content: "\f060"
}

.ri-reddit-fill:before {
    content: "\f061"
}

.ri-reddit-line:before {
    content: "\f062"
}

.ri-refresh-fill:before {
    content: "\f063"
}

.ri-refresh-line:before {
    content: "\f064"
}

.ri-refund-2-fill:before {
    content: "\f065"
}

.ri-refund-2-line:before {
    content: "\f066"
}

.ri-refund-fill:before {
    content: "\f067"
}

.ri-refund-line:before {
    content: "\f068"
}

.ri-registered-fill:before {
    content: "\f069"
}

.ri-registered-line:before {
    content: "\f06a"
}

.ri-remixicon-fill:before {
    content: "\f06b"
}

.ri-remixicon-line:before {
    content: "\f06c"
}

.ri-remote-control-2-fill:before {
    content: "\f06d"
}

.ri-remote-control-2-line:before {
    content: "\f06e"
}

.ri-remote-control-fill:before {
    content: "\f06f"
}

.ri-remote-control-line:before {
    content: "\f070"
}

.ri-repeat-2-fill:before {
    content: "\f071"
}

.ri-repeat-2-line:before {
    content: "\f072"
}

.ri-repeat-fill:before {
    content: "\f073"
}

.ri-repeat-line:before {
    content: "\f074"
}

.ri-repeat-one-fill:before {
    content: "\f075"
}

.ri-repeat-one-line:before {
    content: "\f076"
}

.ri-reply-all-fill:before {
    content: "\f077"
}

.ri-reply-all-line:before {
    content: "\f078"
}

.ri-reply-fill:before {
    content: "\f079"
}

.ri-reply-line:before {
    content: "\f07a"
}

.ri-reserved-fill:before {
    content: "\f07b"
}

.ri-reserved-line:before {
    content: "\f07c"
}

.ri-rest-time-fill:before {
    content: "\f07d"
}

.ri-rest-time-line:before {
    content: "\f07e"
}

.ri-restart-fill:before {
    content: "\f07f"
}

.ri-restart-line:before {
    content: "\f080"
}

.ri-restaurant-2-fill:before {
    content: "\f081"
}

.ri-restaurant-2-line:before {
    content: "\f082"
}

.ri-restaurant-fill:before {
    content: "\f083"
}

.ri-restaurant-line:before {
    content: "\f084"
}

.ri-rewind-fill:before {
    content: "\f085"
}

.ri-rewind-line:before {
    content: "\f086"
}

.ri-rewind-mini-fill:before {
    content: "\f087"
}

.ri-rewind-mini-line:before {
    content: "\f088"
}

.ri-rhythm-fill:before {
    content: "\f089"
}

.ri-rhythm-line:before {
    content: "\f08a"
}

.ri-riding-fill:before {
    content: "\f08b"
}

.ri-riding-line:before {
    content: "\f08c"
}

.ri-road-map-fill:before {
    content: "\f08d"
}

.ri-road-map-line:before {
    content: "\f08e"
}

.ri-roadster-fill:before {
    content: "\f08f"
}

.ri-roadster-line:before {
    content: "\f090"
}

.ri-robot-fill:before {
    content: "\f091"
}

.ri-robot-line:before {
    content: "\f092"
}

.ri-rocket-2-fill:before {
    content: "\f093"
}

.ri-rocket-2-line:before {
    content: "\f094"
}

.ri-rocket-fill:before {
    content: "\f095"
}

.ri-rocket-line:before {
    content: "\f096"
}

.ri-rotate-lock-fill:before {
    content: "\f097"
}

.ri-rotate-lock-line:before {
    content: "\f098"
}

.ri-rounded-corner:before {
    content: "\f099"
}

.ri-route-fill:before {
    content: "\f09a"
}

.ri-route-line:before {
    content: "\f09b"
}

.ri-router-fill:before {
    content: "\f09c"
}

.ri-router-line:before {
    content: "\f09d"
}

.ri-rss-fill:before {
    content: "\f09e"
}

.ri-rss-line:before {
    content: "\f09f"
}

.ri-ruler-2-fill:before {
    content: "\f0a0"
}

.ri-ruler-2-line:before {
    content: "\f0a1"
}

.ri-ruler-fill:before {
    content: "\f0a2"
}

.ri-ruler-line:before {
    content: "\f0a3"
}

.ri-run-fill:before {
    content: "\f0a4"
}

.ri-run-line:before {
    content: "\f0a5"
}

.ri-safari-fill:before {
    content: "\f0a6"
}

.ri-safari-line:before {
    content: "\f0a7"
}

.ri-safe-2-fill:before {
    content: "\f0a8"
}

.ri-safe-2-line:before {
    content: "\f0a9"
}

.ri-safe-fill:before {
    content: "\f0aa"
}

.ri-safe-line:before {
    content: "\f0ab"
}

.ri-sailboat-fill:before {
    content: "\f0ac"
}

.ri-sailboat-line:before {
    content: "\f0ad"
}

.ri-save-2-fill:before {
    content: "\f0ae"
}

.ri-save-2-line:before {
    content: "\f0af"
}

.ri-save-3-fill:before {
    content: "\f0b0"
}

.ri-save-3-line:before {
    content: "\f0b1"
}

.ri-save-fill:before {
    content: "\f0b2"
}

.ri-save-line:before {
    content: "\f0b3"
}

.ri-scales-2-fill:before {
    content: "\f0b4"
}

.ri-scales-2-line:before {
    content: "\f0b5"
}

.ri-scales-3-fill:before {
    content: "\f0b6"
}

.ri-scales-3-line:before {
    content: "\f0b7"
}

.ri-scales-fill:before {
    content: "\f0b8"
}

.ri-scales-line:before {
    content: "\f0b9"
}

.ri-scan-2-fill:before {
    content: "\f0ba"
}

.ri-scan-2-line:before {
    content: "\f0bb"
}

.ri-scan-fill:before {
    content: "\f0bc"
}

.ri-scan-line:before {
    content: "\f0bd"
}

.ri-scissors-2-fill:before {
    content: "\f0be"
}

.ri-scissors-2-line:before {
    content: "\f0bf"
}

.ri-scissors-cut-fill:before {
    content: "\f0c0"
}

.ri-scissors-cut-line:before {
    content: "\f0c1"
}

.ri-scissors-fill:before {
    content: "\f0c2"
}

.ri-scissors-line:before {
    content: "\f0c3"
}

.ri-screenshot-2-fill:before {
    content: "\f0c4"
}

.ri-screenshot-2-line:before {
    content: "\f0c5"
}

.ri-screenshot-fill:before {
    content: "\f0c6"
}

.ri-screenshot-line:before {
    content: "\f0c7"
}

.ri-sd-card-fill:before {
    content: "\f0c8"
}

.ri-sd-card-line:before {
    content: "\f0c9"
}

.ri-sd-card-mini-fill:before {
    content: "\f0ca"
}

.ri-sd-card-mini-line:before {
    content: "\f0cb"
}

.ri-search-2-fill:before {
    content: "\f0cc"
}

.ri-search-2-line:before {
    content: "\f0cd"
}

.ri-search-eye-fill:before {
    content: "\f0ce"
}

.ri-search-eye-line:before {
    content: "\f0cf"
}

.ri-search-fill:before {
    content: "\f0d0"
}

.ri-search-line:before {
    content: "\f0d1"
}

.ri-secure-payment-fill:before {
    content: "\f0d2"
}

.ri-secure-payment-line:before {
    content: "\f0d3"
}

.ri-seedling-fill:before {
    content: "\f0d4"
}

.ri-seedling-line:before {
    content: "\f0d5"
}

.ri-send-backward:before {
    content: "\f0d6"
}

.ri-send-plane-2-fill:before {
    content: "\f0d7"
}

.ri-send-plane-2-line:before {
    content: "\f0d8"
}

.ri-send-plane-fill:before {
    content: "\f0d9"
}

.ri-send-plane-line:before {
    content: "\f0da"
}

.ri-send-to-back:before {
    content: "\f0db"
}

.ri-sensor-fill:before {
    content: "\f0dc"
}

.ri-sensor-line:before {
    content: "\f0dd"
}

.ri-separator:before {
    content: "\f0de"
}

.ri-server-fill:before {
    content: "\f0df"
}

.ri-server-line:before {
    content: "\f0e0"
}

.ri-service-fill:before {
    content: "\f0e1"
}

.ri-service-line:before {
    content: "\f0e2"
}

.ri-settings-2-fill:before {
    content: "\f0e3"
}

.ri-settings-2-line:before {
    content: "\f0e4"
}

.ri-settings-3-fill:before {
    content: "\f0e5"
}

.ri-settings-3-line:before {
    content: "\f0e6"
}

.ri-settings-4-fill:before {
    content: "\f0e7"
}

.ri-settings-4-line:before {
    content: "\f0e8"
}

.ri-settings-5-fill:before {
    content: "\f0e9"
}

.ri-settings-5-line:before {
    content: "\f0ea"
}

.ri-settings-6-fill:before {
    content: "\f0eb"
}

.ri-settings-6-line:before {
    content: "\f0ec"
}

.ri-settings-fill:before {
    content: "\f0ed"
}

.ri-settings-line:before {
    content: "\f0ee"
}

.ri-shape-2-fill:before {
    content: "\f0ef"
}

.ri-shape-2-line:before {
    content: "\f0f0"
}

.ri-shape-fill:before {
    content: "\f0f1"
}

.ri-shape-line:before {
    content: "\f0f2"
}

.ri-share-box-fill:before {
    content: "\f0f3"
}

.ri-share-box-line:before {
    content: "\f0f4"
}

.ri-share-circle-fill:before {
    content: "\f0f5"
}

.ri-share-circle-line:before {
    content: "\f0f6"
}

.ri-share-fill:before {
    content: "\f0f7"
}

.ri-share-forward-2-fill:before {
    content: "\f0f8"
}

.ri-share-forward-2-line:before {
    content: "\f0f9"
}

.ri-share-forward-box-fill:before {
    content: "\f0fa"
}

.ri-share-forward-box-line:before {
    content: "\f0fb"
}

.ri-share-forward-fill:before {
    content: "\f0fc"
}

.ri-share-forward-line:before {
    content: "\f0fd"
}

.ri-share-line:before {
    content: "\f0fe"
}

.ri-shield-check-fill:before {
    content: "\f0ff"
}

.ri-shield-check-line:before {
    content: "\f100"
}

.ri-shield-cross-fill:before {
    content: "\f101"
}

.ri-shield-cross-line:before {
    content: "\f102"
}

.ri-shield-fill:before {
    content: "\f103"
}

.ri-shield-flash-fill:before {
    content: "\f104"
}

.ri-shield-flash-line:before {
    content: "\f105"
}

.ri-shield-keyhole-fill:before {
    content: "\f106"
}

.ri-shield-keyhole-line:before {
    content: "\f107"
}

.ri-shield-line:before {
    content: "\f108"
}

.ri-shield-star-fill:before {
    content: "\f109"
}

.ri-shield-star-line:before {
    content: "\f10a"
}

.ri-shield-user-fill:before {
    content: "\f10b"
}

.ri-shield-user-line:before {
    content: "\f10c"
}

.ri-ship-2-fill:before {
    content: "\f10d"
}

.ri-ship-2-line:before {
    content: "\f10e"
}

.ri-ship-fill:before {
    content: "\f10f"
}

.ri-ship-line:before {
    content: "\f110"
}

.ri-shirt-fill:before {
    content: "\f111"
}

.ri-shirt-line:before {
    content: "\f112"
}

.ri-shopping-bag-2-fill:before {
    content: "\f113"
}

.ri-shopping-bag-2-line:before {
    content: "\f114"
}

.ri-shopping-bag-3-fill:before {
    content: "\f115"
}

.ri-shopping-bag-3-line:before {
    content: "\f116"
}

.ri-shopping-bag-fill:before {
    content: "\f117"
}

.ri-shopping-bag-line:before {
    content: "\f118"
}

.ri-shopping-basket-2-fill:before {
    content: "\f119"
}

.ri-shopping-basket-2-line:before {
    content: "\f11a"
}

.ri-shopping-basket-fill:before {
    content: "\f11b"
}

.ri-shopping-basket-line:before {
    content: "\f11c"
}

.ri-shopping-cart-2-fill:before {
    content: "\f11d"
}

.ri-shopping-cart-2-line:before {
    content: "\f11e"
}

.ri-shopping-cart-fill:before {
    content: "\f11f"
}

.ri-shopping-cart-line:before {
    content: "\f120"
}

.ri-showers-fill:before {
    content: "\f121"
}

.ri-showers-line:before {
    content: "\f122"
}

.ri-shuffle-fill:before {
    content: "\f123"
}

.ri-shuffle-line:before {
    content: "\f124"
}

.ri-shut-down-fill:before {
    content: "\f125"
}

.ri-shut-down-line:before {
    content: "\f126"
}

.ri-side-bar-fill:before {
    content: "\f127"
}

.ri-side-bar-line:before {
    content: "\f128"
}

.ri-signal-tower-fill:before {
    content: "\f129"
}

.ri-signal-tower-line:before {
    content: "\f12a"
}

.ri-signal-wifi-1-fill:before {
    content: "\f12b"
}

.ri-signal-wifi-1-line:before {
    content: "\f12c"
}

.ri-signal-wifi-2-fill:before {
    content: "\f12d"
}

.ri-signal-wifi-2-line:before {
    content: "\f12e"
}

.ri-signal-wifi-3-fill:before {
    content: "\f12f"
}

.ri-signal-wifi-3-line:before {
    content: "\f130"
}

.ri-signal-wifi-error-fill:before {
    content: "\f131"
}

.ri-signal-wifi-error-line:before {
    content: "\f132"
}

.ri-signal-wifi-fill:before {
    content: "\f133"
}

.ri-signal-wifi-line:before {
    content: "\f134"
}

.ri-signal-wifi-off-fill:before {
    content: "\f135"
}

.ri-signal-wifi-off-line:before {
    content: "\f136"
}

.ri-sim-card-2-fill:before {
    content: "\f137"
}

.ri-sim-card-2-line:before {
    content: "\f138"
}

.ri-sim-card-fill:before {
    content: "\f139"
}

.ri-sim-card-line:before {
    content: "\f13a"
}

.ri-single-quotes-l:before {
    content: "\f13b"
}

.ri-single-quotes-r:before {
    content: "\f13c"
}

.ri-sip-fill:before {
    content: "\f13d"
}

.ri-sip-line:before {
    content: "\f13e"
}

.ri-skip-back-fill:before {
    content: "\f13f"
}

.ri-skip-back-line:before {
    content: "\f140"
}

.ri-skip-back-mini-fill:before {
    content: "\f141"
}

.ri-skip-back-mini-line:before {
    content: "\f142"
}

.ri-skip-forward-fill:before {
    content: "\f143"
}

.ri-skip-forward-line:before {
    content: "\f144"
}

.ri-skip-forward-mini-fill:before {
    content: "\f145"
}

.ri-skip-forward-mini-line:before {
    content: "\f146"
}

.ri-skull-2-fill:before {
    content: "\f147"
}

.ri-skull-2-line:before {
    content: "\f148"
}

.ri-skull-fill:before {
    content: "\f149"
}

.ri-skull-line:before {
    content: "\f14a"
}

.ri-skype-fill:before {
    content: "\f14b"
}

.ri-skype-line:before {
    content: "\f14c"
}

.ri-slack-fill:before {
    content: "\f14d"
}

.ri-slack-line:before {
    content: "\f14e"
}

.ri-slice-fill:before {
    content: "\f14f"
}

.ri-slice-line:before {
    content: "\f150"
}

.ri-slideshow-2-fill:before {
    content: "\f151"
}

.ri-slideshow-2-line:before {
    content: "\f152"
}

.ri-slideshow-3-fill:before {
    content: "\f153"
}

.ri-slideshow-3-line:before {
    content: "\f154"
}

.ri-slideshow-4-fill:before {
    content: "\f155"
}

.ri-slideshow-4-line:before {
    content: "\f156"
}

.ri-slideshow-fill:before {
    content: "\f157"
}

.ri-slideshow-line:before {
    content: "\f158"
}

.ri-smartphone-fill:before {
    content: "\f159"
}

.ri-smartphone-line:before {
    content: "\f15a"
}

.ri-snapchat-fill:before {
    content: "\f15b"
}

.ri-snapchat-line:before {
    content: "\f15c"
}

.ri-snowy-fill:before {
    content: "\f15d"
}

.ri-snowy-line:before {
    content: "\f15e"
}

.ri-sort-asc:before {
    content: "\f15f"
}

.ri-sort-desc:before {
    content: "\f160"
}

.ri-sound-module-fill:before {
    content: "\f161"
}

.ri-sound-module-line:before {
    content: "\f162"
}

.ri-soundcloud-fill:before {
    content: "\f163"
}

.ri-soundcloud-line:before {
    content: "\f164"
}

.ri-space-ship-fill:before {
    content: "\f165"
}

.ri-space-ship-line:before {
    content: "\f166"
}

.ri-space:before {
    content: "\f167"
}

.ri-spam-2-fill:before {
    content: "\f168"
}

.ri-spam-2-line:before {
    content: "\f169"
}

.ri-spam-3-fill:before {
    content: "\f16a"
}

.ri-spam-3-line:before {
    content: "\f16b"
}

.ri-spam-fill:before {
    content: "\f16c"
}

.ri-spam-line:before {
    content: "\f16d"
}

.ri-speaker-2-fill:before {
    content: "\f16e"
}

.ri-speaker-2-line:before {
    content: "\f16f"
}

.ri-speaker-3-fill:before {
    content: "\f170"
}

.ri-speaker-3-line:before {
    content: "\f171"
}

.ri-speaker-fill:before {
    content: "\f172"
}

.ri-speaker-line:before {
    content: "\f173"
}

.ri-spectrum-fill:before {
    content: "\f174"
}

.ri-spectrum-line:before {
    content: "\f175"
}

.ri-speed-fill:before {
    content: "\f176"
}

.ri-speed-line:before {
    content: "\f177"
}

.ri-speed-mini-fill:before {
    content: "\f178"
}

.ri-speed-mini-line:before {
    content: "\f179"
}

.ri-split-cells-horizontal:before {
    content: "\f17a"
}

.ri-split-cells-vertical:before {
    content: "\f17b"
}

.ri-spotify-fill:before {
    content: "\f17c"
}

.ri-spotify-line:before {
    content: "\f17d"
}

.ri-spy-fill:before {
    content: "\f17e"
}

.ri-spy-line:before {
    content: "\f17f"
}

.ri-stack-fill:before {
    content: "\f180"
}

.ri-stack-line:before {
    content: "\f181"
}

.ri-stack-overflow-fill:before {
    content: "\f182"
}

.ri-stack-overflow-line:before {
    content: "\f183"
}

.ri-stackshare-fill:before {
    content: "\f184"
}

.ri-stackshare-line:before {
    content: "\f185"
}

.ri-star-fill:before {
    content: "\f186"
}

.ri-star-half-fill:before {
    content: "\f187"
}

.ri-star-half-line:before {
    content: "\f188"
}

.ri-star-half-s-fill:before {
    content: "\f189"
}

.ri-star-half-s-line:before {
    content: "\f18a"
}

.ri-star-line:before {
    content: "\f18b"
}

.ri-star-s-fill:before {
    content: "\f18c"
}

.ri-star-s-line:before {
    content: "\f18d"
}

.ri-star-smile-fill:before {
    content: "\f18e"
}

.ri-star-smile-line:before {
    content: "\f18f"
}

.ri-steam-fill:before {
    content: "\f190"
}

.ri-steam-line:before {
    content: "\f191"
}

.ri-steering-2-fill:before {
    content: "\f192"
}

.ri-steering-2-line:before {
    content: "\f193"
}

.ri-steering-fill:before {
    content: "\f194"
}

.ri-steering-line:before {
    content: "\f195"
}

.ri-stethoscope-fill:before {
    content: "\f196"
}

.ri-stethoscope-line:before {
    content: "\f197"
}

.ri-sticky-note-2-fill:before {
    content: "\f198"
}

.ri-sticky-note-2-line:before {
    content: "\f199"
}

.ri-sticky-note-fill:before {
    content: "\f19a"
}

.ri-sticky-note-line:before {
    content: "\f19b"
}

.ri-stock-fill:before {
    content: "\f19c"
}

.ri-stock-line:before {
    content: "\f19d"
}

.ri-stop-circle-fill:before {
    content: "\f19e"
}

.ri-stop-circle-line:before {
    content: "\f19f"
}

.ri-stop-fill:before {
    content: "\f1a0"
}

.ri-stop-line:before {
    content: "\f1a1"
}

.ri-stop-mini-fill:before {
    content: "\f1a2"
}

.ri-stop-mini-line:before {
    content: "\f1a3"
}

.ri-store-2-fill:before {
    content: "\f1a4"
}

.ri-store-2-line:before {
    content: "\f1a5"
}

.ri-store-3-fill:before {
    content: "\f1a6"
}

.ri-store-3-line:before {
    content: "\f1a7"
}

.ri-store-fill:before {
    content: "\f1a8"
}

.ri-store-line:before {
    content: "\f1a9"
}

.ri-strikethrough-2:before {
    content: "\f1aa"
}

.ri-strikethrough:before {
    content: "\f1ab"
}

.ri-subscript-2:before {
    content: "\f1ac"
}

.ri-subscript:before {
    content: "\f1ad"
}

.ri-subtract-fill:before {
    content: "\f1ae"
}

.ri-subtract-line:before {
    content: "\f1af"
}

.ri-subway-fill:before {
    content: "\f1b0"
}

.ri-subway-line:before {
    content: "\f1b1"
}

.ri-subway-wifi-fill:before {
    content: "\f1b2"
}

.ri-subway-wifi-line:before {
    content: "\f1b3"
}

.ri-suitcase-2-fill:before {
    content: "\f1b4"
}

.ri-suitcase-2-line:before {
    content: "\f1b5"
}

.ri-suitcase-3-fill:before {
    content: "\f1b6"
}

.ri-suitcase-3-line:before {
    content: "\f1b7"
}

.ri-suitcase-fill:before {
    content: "\f1b8"
}

.ri-suitcase-line:before {
    content: "\f1b9"
}

.ri-sun-cloudy-fill:before {
    content: "\f1ba"
}

.ri-sun-cloudy-line:before {
    content: "\f1bb"
}

.ri-sun-fill:before {
    content: "\f1bc"
}

.ri-sun-foggy-fill:before {
    content: "\f1bd"
}

.ri-sun-foggy-line:before {
    content: "\f1be"
}

.ri-sun-line:before {
    content: "\f1bf"
}

.ri-superscript-2:before {
    content: "\f1c0"
}

.ri-superscript:before {
    content: "\f1c1"
}

.ri-surgical-mask-fill:before {
    content: "\f1c2"
}

.ri-surgical-mask-line:before {
    content: "\f1c3"
}

.ri-surround-sound-fill:before {
    content: "\f1c4"
}

.ri-surround-sound-line:before {
    content: "\f1c5"
}

.ri-survey-fill:before {
    content: "\f1c6"
}

.ri-survey-line:before {
    content: "\f1c7"
}

.ri-swap-box-fill:before {
    content: "\f1c8"
}

.ri-swap-box-line:before {
    content: "\f1c9"
}

.ri-swap-fill:before {
    content: "\f1ca"
}

.ri-swap-line:before {
    content: "\f1cb"
}

.ri-switch-fill:before {
    content: "\f1cc"
}

.ri-switch-line:before {
    content: "\f1cd"
}

.ri-sword-fill:before {
    content: "\f1ce"
}

.ri-sword-line:before {
    content: "\f1cf"
}

.ri-syringe-fill:before {
    content: "\f1d0"
}

.ri-syringe-line:before {
    content: "\f1d1"
}

.ri-t-box-fill:before {
    content: "\f1d2"
}

.ri-t-box-line:before {
    content: "\f1d3"
}

.ri-t-shirt-2-fill:before {
    content: "\f1d4"
}

.ri-t-shirt-2-line:before {
    content: "\f1d5"
}

.ri-t-shirt-air-fill:before {
    content: "\f1d6"
}

.ri-t-shirt-air-line:before {
    content: "\f1d7"
}

.ri-t-shirt-fill:before {
    content: "\f1d8"
}

.ri-t-shirt-line:before {
    content: "\f1d9"
}

.ri-table-2:before {
    content: "\f1da"
}

.ri-table-alt-fill:before {
    content: "\f1db"
}

.ri-table-alt-line:before {
    content: "\f1dc"
}

.ri-table-fill:before {
    content: "\f1dd"
}

.ri-table-line:before {
    content: "\f1de"
}

.ri-tablet-fill:before {
    content: "\f1df"
}

.ri-tablet-line:before {
    content: "\f1e0"
}

.ri-takeaway-fill:before {
    content: "\f1e1"
}

.ri-takeaway-line:before {
    content: "\f1e2"
}

.ri-taobao-fill:before {
    content: "\f1e3"
}

.ri-taobao-line:before {
    content: "\f1e4"
}

.ri-tape-fill:before {
    content: "\f1e5"
}

.ri-tape-line:before {
    content: "\f1e6"
}

.ri-task-fill:before {
    content: "\f1e7"
}

.ri-task-line:before {
    content: "\f1e8"
}

.ri-taxi-fill:before {
    content: "\f1e9"
}

.ri-taxi-line:before {
    content: "\f1ea"
}

.ri-taxi-wifi-fill:before {
    content: "\f1eb"
}

.ri-taxi-wifi-line:before {
    content: "\f1ec"
}

.ri-team-fill:before {
    content: "\f1ed"
}

.ri-team-line:before {
    content: "\f1ee"
}

.ri-telegram-fill:before {
    content: "\f1ef"
}

.ri-telegram-line:before {
    content: "\f1f0"
}

.ri-temp-cold-fill:before {
    content: "\f1f1"
}

.ri-temp-cold-line:before {
    content: "\f1f2"
}

.ri-temp-hot-fill:before {
    content: "\f1f3"
}

.ri-temp-hot-line:before {
    content: "\f1f4"
}

.ri-terminal-box-fill:before {
    content: "\f1f5"
}

.ri-terminal-box-line:before {
    content: "\f1f6"
}

.ri-terminal-fill:before {
    content: "\f1f7"
}

.ri-terminal-line:before {
    content: "\f1f8"
}

.ri-terminal-window-fill:before {
    content: "\f1f9"
}

.ri-terminal-window-line:before {
    content: "\f1fa"
}

.ri-test-tube-fill:before {
    content: "\f1fb"
}

.ri-test-tube-line:before {
    content: "\f1fc"
}

.ri-text-direction-l:before {
    content: "\f1fd"
}

.ri-text-direction-r:before {
    content: "\f1fe"
}

.ri-text-spacing:before {
    content: "\f1ff"
}

.ri-text-wrap:before {
    content: "\f200"
}

.ri-text:before {
    content: "\f201"
}

.ri-thermometer-fill:before {
    content: "\f202"
}

.ri-thermometer-line:before {
    content: "\f203"
}

.ri-thumb-down-fill:before {
    content: "\f204"
}

.ri-thumb-down-line:before {
    content: "\f205"
}

.ri-thumb-up-fill:before {
    content: "\f206"
}

.ri-thumb-up-line:before {
    content: "\f207"
}

.ri-thunderstorms-fill:before {
    content: "\f208"
}

.ri-thunderstorms-line:before {
    content: "\f209"
}

.ri-ticket-2-fill:before {
    content: "\f20a"
}

.ri-ticket-2-line:before {
    content: "\f20b"
}

.ri-ticket-fill:before {
    content: "\f20c"
}

.ri-ticket-line:before {
    content: "\f20d"
}

.ri-time-fill:before {
    content: "\f20e"
}

.ri-time-line:before {
    content: "\f20f"
}

.ri-timer-2-fill:before {
    content: "\f210"
}

.ri-timer-2-line:before {
    content: "\f211"
}

.ri-timer-fill:before {
    content: "\f212"
}

.ri-timer-flash-fill:before {
    content: "\f213"
}

.ri-timer-flash-line:before {
    content: "\f214"
}

.ri-timer-line:before {
    content: "\f215"
}

.ri-todo-fill:before {
    content: "\f216"
}

.ri-todo-line:before {
    content: "\f217"
}

.ri-toggle-fill:before {
    content: "\f218"
}

.ri-toggle-line:before {
    content: "\f219"
}

.ri-tools-fill:before {
    content: "\f21a"
}

.ri-tools-line:before {
    content: "\f21b"
}

.ri-tornado-fill:before {
    content: "\f21c"
}

.ri-tornado-line:before {
    content: "\f21d"
}

.ri-trademark-fill:before {
    content: "\f21e"
}

.ri-trademark-line:before {
    content: "\f21f"
}

.ri-traffic-light-fill:before {
    content: "\f220"
}

.ri-traffic-light-line:before {
    content: "\f221"
}

.ri-train-fill:before {
    content: "\f222"
}

.ri-train-line:before {
    content: "\f223"
}

.ri-train-wifi-fill:before {
    content: "\f224"
}

.ri-train-wifi-line:before {
    content: "\f225"
}

.ri-translate-2:before {
    content: "\f226"
}

.ri-translate:before {
    content: "\f227"
}

.ri-travesti-fill:before {
    content: "\f228"
}

.ri-travesti-line:before {
    content: "\f229"
}

.ri-treasure-map-fill:before {
    content: "\f22a"
}

.ri-treasure-map-line:before {
    content: "\f22b"
}

.ri-trello-fill:before {
    content: "\f22c"
}

.ri-trello-line:before {
    content: "\f22d"
}

.ri-trophy-fill:before {
    content: "\f22e"
}

.ri-trophy-line:before {
    content: "\f22f"
}

.ri-truck-fill:before {
    content: "\f230"
}

.ri-truck-line:before {
    content: "\f231"
}

.ri-tumblr-fill:before {
    content: "\f232"
}

.ri-tumblr-line:before {
    content: "\f233"
}

.ri-tv-2-fill:before {
    content: "\f234"
}

.ri-tv-2-line:before {
    content: "\f235"
}

.ri-tv-fill:before {
    content: "\f236"
}

.ri-tv-line:before {
    content: "\f237"
}

.ri-twitch-fill:before {
    content: "\f238"
}

.ri-twitch-line:before {
    content: "\f239"
}

.ri-twitter-fill:before {
    content: "\f23a"
}

.ri-twitter-line:before {
    content: "\f23b"
}

.ri-typhoon-fill:before {
    content: "\f23c"
}

.ri-typhoon-line:before {
    content: "\f23d"
}

.ri-u-disk-fill:before {
    content: "\f23e"
}

.ri-u-disk-line:before {
    content: "\f23f"
}

.ri-ubuntu-fill:before {
    content: "\f240"
}

.ri-ubuntu-line:before {
    content: "\f241"
}

.ri-umbrella-fill:before {
    content: "\f242"
}

.ri-umbrella-line:before {
    content: "\f243"
}

.ri-underline:before {
    content: "\f244"
}

.ri-uninstall-fill:before {
    content: "\f245"
}

.ri-uninstall-line:before {
    content: "\f246"
}

.ri-unsplash-fill:before {
    content: "\f247"
}

.ri-unsplash-line:before {
    content: "\f248"
}

.ri-upload-2-fill:before {
    content: "\f249"
}

.ri-upload-2-line:before {
    content: "\f24a"
}

.ri-upload-cloud-2-fill:before {
    content: "\f24b"
}

.ri-upload-cloud-2-line:before {
    content: "\f24c"
}

.ri-upload-cloud-fill:before {
    content: "\f24d"
}

.ri-upload-cloud-line:before {
    content: "\f24e"
}

.ri-upload-fill:before {
    content: "\f24f"
}

.ri-upload-line:before {
    content: "\f250"
}

.ri-usb-fill:before {
    content: "\f251"
}

.ri-usb-line:before {
    content: "\f252"
}

.ri-user-2-fill:before {
    content: "\f253"
}

.ri-user-2-line:before {
    content: "\f254"
}

.ri-user-3-fill:before {
    content: "\f255"
}

.ri-user-3-line:before {
    content: "\f256"
}

.ri-user-4-fill:before {
    content: "\f257"
}

.ri-user-4-line:before {
    content: "\f258"
}

.ri-user-5-fill:before {
    content: "\f259"
}

.ri-user-5-line:before {
    content: "\f25a"
}

.ri-user-6-fill:before {
    content: "\f25b"
}

.ri-user-6-line:before {
    content: "\f25c"
}

.ri-user-add-fill:before {
    content: "\f25d"
}

.ri-user-add-line:before {
    content: "\f25e"
}

.ri-user-fill:before {
    content: "\f25f"
}

.ri-user-follow-fill:before {
    content: "\f260"
}

.ri-user-follow-line:before {
    content: "\f261"
}

.ri-user-heart-fill:before {
    content: "\f262"
}

.ri-user-heart-line:before {
    content: "\f263"
}

.ri-user-line:before {
    content: "\f264"
}

.ri-user-location-fill:before {
    content: "\f265"
}

.ri-user-location-line:before {
    content: "\f266"
}

.ri-user-received-2-fill:before {
    content: "\f267"
}

.ri-user-received-2-line:before {
    content: "\f268"
}

.ri-user-received-fill:before {
    content: "\f269"
}

.ri-user-received-line:before {
    content: "\f26a"
}

.ri-user-search-fill:before {
    content: "\f26b"
}

.ri-user-search-line:before {
    content: "\f26c"
}

.ri-user-settings-fill:before {
    content: "\f26d"
}

.ri-user-settings-line:before {
    content: "\f26e"
}

.ri-user-shared-2-fill:before {
    content: "\f26f"
}

.ri-user-shared-2-line:before {
    content: "\f270"
}

.ri-user-shared-fill:before {
    content: "\f271"
}

.ri-user-shared-line:before {
    content: "\f272"
}

.ri-user-smile-fill:before {
    content: "\f273"
}

.ri-user-smile-line:before {
    content: "\f274"
}

.ri-user-star-fill:before {
    content: "\f275"
}

.ri-user-star-line:before {
    content: "\f276"
}

.ri-user-unfollow-fill:before {
    content: "\f277"
}

.ri-user-unfollow-line:before {
    content: "\f278"
}

.ri-user-voice-fill:before {
    content: "\f279"
}

.ri-user-voice-line:before {
    content: "\f27a"
}

.ri-video-add-fill:before {
    content: "\f27b"
}

.ri-video-add-line:before {
    content: "\f27c"
}

.ri-video-chat-fill:before {
    content: "\f27d"
}

.ri-video-chat-line:before {
    content: "\f27e"
}

.ri-video-download-fill:before {
    content: "\f27f"
}

.ri-video-download-line:before {
    content: "\f280"
}

.ri-video-fill:before {
    content: "\f281"
}

.ri-video-line:before {
    content: "\f282"
}

.ri-video-upload-fill:before {
    content: "\f283"
}

.ri-video-upload-line:before {
    content: "\f284"
}

.ri-vidicon-2-fill:before {
    content: "\f285"
}

.ri-vidicon-2-line:before {
    content: "\f286"
}

.ri-vidicon-fill:before {
    content: "\f287"
}

.ri-vidicon-line:before {
    content: "\f288"
}

.ri-vimeo-fill:before {
    content: "\f289"
}

.ri-vimeo-line:before {
    content: "\f28a"
}

.ri-vip-crown-2-fill:before {
    content: "\f28b"
}

.ri-vip-crown-2-line:before {
    content: "\f28c"
}

.ri-vip-crown-fill:before {
    content: "\f28d"
}

.ri-vip-crown-line:before {
    content: "\f28e"
}

.ri-vip-diamond-fill:before {
    content: "\f28f"
}

.ri-vip-diamond-line:before {
    content: "\f290"
}

.ri-vip-fill:before {
    content: "\f291"
}

.ri-vip-line:before {
    content: "\f292"
}

.ri-virus-fill:before {
    content: "\f293"
}

.ri-virus-line:before {
    content: "\f294"
}

.ri-visa-fill:before {
    content: "\f295"
}

.ri-visa-line:before {
    content: "\f296"
}

.ri-voice-recognition-fill:before {
    content: "\f297"
}

.ri-voice-recognition-line:before {
    content: "\f298"
}

.ri-voiceprint-fill:before {
    content: "\f299"
}

.ri-voiceprint-line:before {
    content: "\f29a"
}

.ri-volume-down-fill:before {
    content: "\f29b"
}

.ri-volume-down-line:before {
    content: "\f29c"
}

.ri-volume-mute-fill:before {
    content: "\f29d"
}

.ri-volume-mute-line:before {
    content: "\f29e"
}

.ri-volume-off-vibrate-fill:before {
    content: "\f29f"
}

.ri-volume-off-vibrate-line:before {
    content: "\f2a0"
}

.ri-volume-up-fill:before {
    content: "\f2a1"
}

.ri-volume-up-line:before {
    content: "\f2a2"
}

.ri-volume-vibrate-fill:before {
    content: "\f2a3"
}

.ri-volume-vibrate-line:before {
    content: "\f2a4"
}

.ri-vuejs-fill:before {
    content: "\f2a5"
}

.ri-vuejs-line:before {
    content: "\f2a6"
}

.ri-walk-fill:before {
    content: "\f2a7"
}

.ri-walk-line:before {
    content: "\f2a8"
}

.ri-wallet-2-fill:before {
    content: "\f2a9"
}

.ri-wallet-2-line:before {
    content: "\f2aa"
}

.ri-wallet-3-fill:before {
    content: "\f2ab"
}

.ri-wallet-3-line:before {
    content: "\f2ac"
}

.ri-wallet-fill:before {
    content: "\f2ad"
}

.ri-wallet-line:before {
    content: "\f2ae"
}

.ri-water-flash-fill:before {
    content: "\f2af"
}

.ri-water-flash-line:before {
    content: "\f2b0"
}

.ri-webcam-fill:before {
    content: "\f2b1"
}

.ri-webcam-line:before {
    content: "\f2b2"
}

.ri-wechat-2-fill:before {
    content: "\f2b3"
}

.ri-wechat-2-line:before {
    content: "\f2b4"
}

.ri-wechat-fill:before {
    content: "\f2b5"
}

.ri-wechat-line:before {
    content: "\f2b6"
}

.ri-wechat-pay-fill:before {
    content: "\f2b7"
}

.ri-wechat-pay-line:before {
    content: "\f2b8"
}

.ri-weibo-fill:before {
    content: "\f2b9"
}

.ri-weibo-line:before {
    content: "\f2ba"
}

.ri-whatsapp-fill:before {
    content: "\f2bb"
}

.ri-whatsapp-line:before {
    content: "\f2bc"
}

.ri-wheelchair-fill:before {
    content: "\f2bd"
}

.ri-wheelchair-line:before {
    content: "\f2be"
}

.ri-wifi-fill:before {
    content: "\f2bf"
}

.ri-wifi-line:before {
    content: "\f2c0"
}

.ri-wifi-off-fill:before {
    content: "\f2c1"
}

.ri-wifi-off-line:before {
    content: "\f2c2"
}

.ri-window-2-fill:before {
    content: "\f2c3"
}

.ri-window-2-line:before {
    content: "\f2c4"
}

.ri-window-fill:before {
    content: "\f2c5"
}

.ri-window-line:before {
    content: "\f2c6"
}

.ri-windows-fill:before {
    content: "\f2c7"
}

.ri-windows-line:before {
    content: "\f2c8"
}

.ri-windy-fill:before {
    content: "\f2c9"
}

.ri-windy-line:before {
    content: "\f2ca"
}

.ri-wireless-charging-fill:before {
    content: "\f2cb"
}

.ri-wireless-charging-line:before {
    content: "\f2cc"
}

.ri-women-fill:before {
    content: "\f2cd"
}

.ri-women-line:before {
    content: "\f2ce"
}

.ri-wubi-input:before {
    content: "\f2cf"
}

.ri-xbox-fill:before {
    content: "\f2d0"
}

.ri-xbox-line:before {
    content: "\f2d1"
}

.ri-xing-fill:before {
    content: "\f2d2"
}

.ri-xing-line:before {
    content: "\f2d3"
}

.ri-youtube-fill:before {
    content: "\f2d4"
}

.ri-youtube-line:before {
    content: "\f2d5"
}

.ri-zcool-fill:before {
    content: "\f2d6"
}

.ri-zcool-line:before {
    content: "\f2d7"
}

.ri-zhihu-fill:before {
    content: "\f2d8"
}

.ri-zhihu-line:before {
    content: "\f2d9"
}

.ri-zoom-in-fill:before {
    content: "\f2da"
}

.ri-zoom-in-line:before {
    content: "\f2db"
}

.ri-zoom-out-fill:before {
    content: "\f2dc"
}

.ri-zoom-out-line:before {
    content: "\f2dd"
}

.ri-zzz-fill:before {
    content: "\f2de"
}

.ri-zzz-line:before {
    content: "\f2df"
}
/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/

@media only screen and (max-width: 575px) {
    .xs-center {
        text-align: center;
    }
    .xs-none {
        display: none;
    }
    .smt-15 {
        margin-top: 15px !important;
    }
    .content-wrapper {
        margin-top: 215px;
    }
    .breadcrumb-wrap .breadcrumb-title .breadcrumb-menu li {
        font-size: 14px;
    }
    .breadcrumb-wrap .breadcrumb-title .breadcrumb-menu li a {
        font-size: 14px;
    }
    .breadcrumb-wrap .breadcrumb-img img {
        display: none;
    }
    .counter-card-wrap.style2 .counter-card,
    .counter-card-wrap.style3 .counter-card {
        width: calc(50% - 25px);
        padding: 25px 20px;
        text-align: center;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon,
    .counter-card-wrap.style3 .counter-card .counter-icon {
        width: 100%;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon i,
    .counter-card-wrap.style3 .counter-card .counter-icon i {
        font-size: 48px;
        margin: 0 auto;
    }
    .counter-card-wrap.style2 .counter-card .counter-text,
    .counter-card-wrap.style3 .counter-card .counter-text {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text h2,
    .counter-card-wrap.style3 .counter-card .counter-text h2 {
        font-size: 22px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text p,
    .counter-card-wrap.style3 .counter-card .counter-text p {
        font-size: 14px;
    }
    .team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-title {
        font-size: 14px;
    }
    .team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
        padding: 1px 6px;
    }
    .progess-bar-item .single-skill p {
        font-size: 14px;
    }
    .progess-bar-item .single-skill .circle-chart {
        width: 120px;
        height: 120px;
    }
   
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid {
        max-width: 540px;
        padding: 0 15px;
    }
    .counter-card-wrap.style2 .counter-card,
    .counter-card-wrap.style3 .counter-card {
        width: calc(50% - 25px);
        padding: 40px 25px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon,
    .counter-card-wrap.style3 .counter-card .counter-icon {
        width: 65px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon i,
    .counter-card-wrap.style3 .counter-card .counter-icon i {
        font-size: 62px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text,
    .counter-card-wrap.style3 .counter-card .counter-text {
        width: calc(100% - 85px);
    }
    .counter-card-wrap.style2 .counter-card .counter-text h2,
    .counter-card-wrap.style3 .counter-card .counter-text h2 {
        font-size: 28px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text p,
    .counter-card-wrap.style3 .counter-card .counter-text p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 767px) {
    p,
    input,
    textarea {
        font-size: 14px;
    }
    .ct_eko_points_bg:after {
        height: 92px !important;
       
    }
    .btn {
        font-size: 14px;
    }
    .cti_slider_btn a.btn_banner{
        padding: 12px 25px !important;
        font-size: 14px !important;
    }
    .sm-none {
        display: none !important;
    }
    .sm-center {
        text-align: center !important;
    }
    .mb-50 {
        margin-bottom: 30px !important;
    }
    .header-wrap .header-bottom {
       
        padding-block: 9px !important;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-50 {
        padding-bottom: 0px;
    }
    .pb-60 {
        padding-bottom: 15px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .mt-100 {
        margin-top: 50px !important;
    }
    .mb-100 {
        margin-bottom: 50px !important;
    }
    .mtb-100 {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .ptb-100 {
        padding-top: 50px  !important;
        padding-bottom: 50px !important;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-75 {
        padding-bottom: 25px;
    }
    .pb-50 {
        padding-bottom: 0px;
    }
    .pt-75 {
        padding-top: 25px;
    }
    .mb-40 {
        margin-bottom: 25px !important;
    }
    .section-title.mb-50,
    .content-title.mb-50 {
        margin-bottom: 30px !important;
    }
    .section-title.mb-40 {
        margin-bottom: 20px !important;
    }
    .link,
    .btn {
        font-size: 14px;
    }
    .btn {
        padding: 12px 25px 12px;
    }
    .back-to-top {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 32px;
        right: 10px;
    }
    .switch-theme-mode {
     
        top: 130px !important;
       
    }
    .switch-theme-mode.ct_mob_light_mode {
        top: 130px !important;
    }
    .back-to-top i {
        font-size: 18px;
    }
    .section-title.style2 span,
    .section-title.style3 span,
    .content-title.style2 span,
    .content-title.style3 span {
        font-size: 14px;
    }
    .section-title.style1 span,
    .content-title.style1 span {
        font-size: 14px;
    }
    .section-title h2,
    .content-title h2 {
        font-size: 24px  !important;
        line-height: 1.3;
    }
    .ct_clr_green_text{
        font-size:24px !important;
    }
    h1.ct_bigger_head.mb-0 {
    font-size: 28px;
}
    .section-title.style3 span,
    .content-title.style3 span {
        padding-bottom: 6px;
    }
    .section-title.style3 span.bl-text,
    .content-title.style3 span.bl-text {
        right: 0px;
        font-size: 40px;
    }
    .section-title.style4 span.bl-text,
    .content-title.style4 span.bl-text {
        right: 0px;
        font-size: 40px;
    }
    .content-wrapper {
        margin-top: 90px;
    }
    .content-feature-list li {
        margin: 0 0 12px;
        font-size: 14px;
    }
    .content-feature-list li i {
        top: 3px;
    }
    .breadcrumb-wrap {
        padding: 60px 0;
    }
    .breadcrumb-title h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .breadcrumb-title .breadcrumb-menu li {
        font-size: 14px;
    }
    .breadcrumb-title .breadcrumb-menu li:after {
        font-size: 15px;
    }
    .breadcrumb-title .breadcrumb-menu li a {
        font-size: 14px;
    }
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
        width: 40px;
        height: 40px;
    }
    .owl-carousel .owl-nav button.owl-prev i,
    .owl-carousel .owl-nav button.owl-next i {
        font-size: 14px;
    }
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3 {
        font-size: 20px;
    }
    .hero-wrap {
        margin-top: 242px;
    }
    .hero-wrap .hero-content {
        margin-bottom: 30px;
    }
    .hero-wrap .hero-content span {
        margin-bottom: 8px;
    }
    .hero-wrap .hero-content h1 {
        margin-bottom: 15px;
        font-weight: 700;
    }
    .hero-wrap .hero-content p {
        margin-bottom: 25px;
    }
    .hero-wrap.style1 .hero-content {
        padding: 70px 0 0px;
    }
    .hero-wrap.style1 .hero-content h1 {
        font-size: 28px;
    }
    .hero-wrap.style1 .hero-shape-six {
        max-width: 45px;
    }
    .hero-wrap.style1 .hero-shape-three {
        max-width: 80px;
    }
    .hero-wrap.style1 .hero-shape-four {
        max-width: 70px;
    }
    .hero-wrap.style1 .hero-skull-shape {
        max-width: 70px;
        right: 55%;
    }
    .hero-wrap.style1 .hero-img-wrap img {
        max-width: 280px;
        margin: 0 auto;
        display: block;
    }
    .hero-wrap.style2 .hero-slide-item {
        padding: 60px 0 220px;
    }
    .hero-wrap.style2 .hero-slide-item:after {
        background: rgba(0, 0, 0, 0.8);
    }
    .hero-wrap.style2 .hero-content h1 {
        font-size: 28px;
    }
    .hero-wrap.style3 {
        padding: 60px 0 55px;
    }
    .hero-wrap.style3 .hero-content {
        margin-bottom: 0;
    }
    .hero-wrap.style3 .hero-content h1 {
        font-size: 28px;
        color: #fff;
    }
    .hero-wrap.style3 .hero-content p {
        color: rgba(255, 255, 255, 0.8);
    }
    .hero-wrap.style3 .hero-shape-one {
        width: 100%;
    }
    .hero-wrap.style3 .hero-shape-three {
        max-width: 100px;
    }
    .hero-wrap.style3 .hero-shape-two {
        max-width: 120px;
    }
    .hero-wrap.style3 .hero-shape-four {
        opacity: 0.3;
    }
    .hero-wrap.style3 .hero-img-wrap {
        margin-left: 30px;
    }
    .hero-wrap.style3 .hero-img-wrap .hero-img-shape {
        left: -50px;
        max-width: 80px;
    }
    .hero-slider-one.owl-carousel .owl-nav {
        bottom: 195px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev,
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        width: 40px;
        height: 40px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .hero-slider-one.owl-carousel .owl-nav button.owl-next i {
        font-size: 18px;
    }
    .hero-wrap .hero-slider-thumbs {
        top: auto;
        bottom: 160px;
        right: auto;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, 0);
    }
    .hero-wrap .hero-slider-thumbs button {
        display: inline-block;
        width: 45px;
        height: 45px;
        margin: 0 10px 0 0;
    }
    .hero-wrap .hero-slider-thumbs button:last-child {
        margin: 0;
    }
    .promo-wrap.style2 {
        margin-top: -120px;
    }
    .promo-wrap.style2 .promo-card {
        width: 100%;
        padding: 30px 20px;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .about-wrap .about-content .content-title {
        margin-bottom: 20px;
    }
    .about-wrap .about-content .content-feature-list {
        margin-bottom: 25px;
    }
    .about-wrap.style1 .about-shape-one {
        max-width: 200px;
    }
    .about-wrap.style1 .about-shape-two {
        max-width: 60px;
    }
    .about-wrap.style1 .about-shape-three {
        max-width: 150px;
    }
    .about-wrap.style1 .about-img-wrap {
        height: 350px;
    }
    .about-wrap.style1 .about-img-wrap .about-quote {
        bottom: 20px;
        width: calc(100% - 40px);
        padding: 20px;
    }
    .about-wrap.style1 .about-img-wrap .about-quote i {
        top: 25px;
        left: 20px;
        font-size: 30px;
    }
    .about-wrap.style1 .about-img-wrap .about-quote p {
        font-size: 14px;
        font-weight: 400;
        padding-left: 45px;
    }
    .about-wrap.style2 .about-shape-one {
        bottom: 10px;
        right: 20px;
        max-width: 100px;
    }
    .project-card.style1 .project-info {
        padding: 20px;
    }
    .project-card.style1 .project-info h3 {
        margin: 0 0 12px;
    }
    .project-card.style1 .project-info p {
        margin: 0 0 15px;
    }
    .project-card.style1 .project-info .project-shape {
        max-width: 120px;
    }
    .project-card.style3 .project-info {
        padding: 18px 20px;
    }
    .project-card.style3 .project-info h3 {
        margin: 0 0 12px;
    }
    .project-card.style3 .project-info .progressbar-wrap {
        margin-bottom: 15px;
    }
    .project-card.style4 .project-img {
        width: 100%;
    }
    .project-card.style4 .project-info {
        width: 100%;
        margin: 0;
        padding: 20px;
    }
    .project-card.style4 .project-info h3 {
        margin-bottom: 12px;
    }
    .project-card.style4 .project-info .progressbar-wrap {
        margin-bottom: 10px;
    }
    .team-card.style1 .team-info {
        padding: 20px;
    }
    .team-card.style2 .team-info-wrap {
        padding: 20px;
    }
    .cta-wrap.style1 .cta-btn {
        text-align: center;
    }
    .cta-wrap.style1 .cta-btn .btn {
        padding: 11px 15px;
        margin-right: 0;
        display: block;
        width: 100%;
    }
    .cta-wrap.style1 .cta-btn .btn:nth-child(2) {
        margin-top: 15px;
    }
    .cta-wrap.style1::after,
    .cta-wrap.style2::after {
        z-index: -1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0.3) 100%);
    }
    .event-card.style1 .event-img,
    .event-card.style2 .event-img,
    .event-card.style3 .event-img {
        width: 100%;
        border-radius: 5px 5px 0 0;
    }
    .event-card.style1 .event-img img,
    .event-card.style2 .event-img img,
    .event-card.style3 .event-img img {
        border-radius: 5px 5px 0 0;
    }
    .event-card.style1 .event-info,
    .event-card.style2 .event-info,
    .event-card.style3 .event-info {
        padding: 18px 20px;
        width: 100%;
        margin-left: 0;
    }
    .event-card.style4 .event-info,
    .event-card.style5 .event-info {
        padding: 18px 20px;
    }
    .newsletter-form.style1 {
        padding: 22px 20px 25px;
    }
    .newsletter-form.style1 h4 {
        font-size: 20px;
    }
    .newsletter-form.style1 p {
        margin: 0 0 15px;
    }
    .newsletter-form.style1 .form-group {
        margin-bottom: 15px;
    }
    .newsletter-form.style1 .form-group input {
        width: 100%;
        height: 50px;
    }
    .event-search-form {
        padding: 20px 20px 0;
    }
    .event-search-form .form-group select,
    .event-search-form .form-group input {
        height: 52px;
        font-size: 14px;
    }
    .event-search-form .form-group button {
        margin-top: 0;
    }
    .promo-video.style1 {
        height: 350px;
    }
    .promo-video.style2 .play-now {
        margin-top: 20px;
    }
    .counter-card-wrap.style1 {
        padding: 50px 0px 75px 0px;
    }
    .counter-card-wrap.style1 .counter-card {
        padding: 30px 20px;
        max-height: 125px;
    }
    .counter-card-wrap.style1 .counter-card:nth-child(2),
    .counter-card-wrap.style1 .counter-card:nth-child(4) {
        top: 50px;
    }
    .counter-card-wrap.style1 .counter-card .counter-text h2 {
        font-size: 26px;
    }
    .counter-card-wrap.style1 .counter-card.style1 {
        border-radius: 40px 40px 0 40px;
    }
    .counter-card-wrap.style1 .counter-card.style2 {
        border-radius: 40px 40px 40px 0;
    }
    .counter-card-wrap.style1 .counter-card.style3 {
        border-radius: 40px 0 40px 40px;
    }
    .counter-card-wrap.style1 .counter-card.style4 {
        border-radius: 0 40px 40px 40px;
    }
    .donate-box {
        padding-left: 25px;
        padding-right: 25px;
    }
    .donate-box .donate-amt li {
        margin-right: 12px;
        margin-bottom: 12px;
    }
    .donate-box .donate-amt li button {
        font-size: 14px;
        padding: 10px 18px;
    }
    .blog-card .blog-info {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .blog-card .blog-info .blog-author .blog-author-info span {
        font-size: 14px;
    }
    .blog-card .blog-info .blog-metainfo li a {
        font-size: 14px;
    }
    .blog-card .blog-info .blog-metainfo li a i {
        font-size: 15px;
    }
    .blog-card.style1 .blog-info {
        padding: 20px;
    }
    .blog-card.style1 .blog-info .blog-author {
        margin: 0 0 18px;
    }
    .blog-card.style1 .blog-info h3 {
        margin-bottom: 12px;
    }
    .blog-card.style1 .blog-info p {
        margin-bottom: 12px;
    }
    .blog-card.style1 .link {
        padding: 13px 15px;
    }
    .blog-card.style2 .blog-info {
        padding: 20px;
    }
    .blog-card.style2 .blog-info .blog-author {
        margin: 0 0 18px;
    }
    .blog-card.style2 .blog-info h3 {
        margin-bottom: 12px;
    }
    .blog-card.style2 .blog-info p {
        margin-bottom: 12px;
    }
    .blog-card.style2 .link {
        padding: 13px 15px;
    }
    .blog-card.style3 {
        padding: 20px;
    }
    .blog-card.style3 .blog-info {
        padding-top: 20px;
    }
    .project-slider-one.owl-carousel .owl-nav {
        justify-content: center;
        margin: 15px 0 20px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        width: 40px;
        height: 40px;
        transform: translateY(0);
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .project-slider-one.owl-carousel .owl-nav button.owl-next i {
        font-size: 22px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev {
        margin-right: 15px;
    }
    .event-info-item-wrap .event-info-item {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .event-info-item-wrap .event-info-item p,
    .event-info-item-wrap .event-info-item span {
        font-size: 14px;
    }
    .project-info-item-wrap .project-info-item {
        margin-bottom: 20px;
    }
    .project-desc .wp-block-quote {
        padding: 20px;
    }
    .project-desc .wp-block-quote p {
        font-size: 14px;
    }
    .register-widget .form-group input,
    .register-widget .form-group select {
        height: 52px;
        font-size: 14px;
    }
    .accordion-item .accordion-header .accordion-button {
        font-size: 16px;
        padding: 15px 40px 15px 20px;
    }
    .accordion-item .accordion-header .accordion-button span {
        min-width: 30px;
        padding: 20px 10px;
    }
    .accordion-item .accordion-header .accordion-button span i {
        font-size: 20px;
        right: 15px;
    }
    .accordion-item .accordion-body {
        padding: 20px 20px 20px;
    }
    .donation-form {
        padding: 20px;
    }
    .donation-form h2 {
        font-size: 20px !important;
    }
    .donation-form h4 {
        margin: 20px 0 20px;
    }
    .donation-form .radionbtn-wrap .radionbtn label {
        font-size: 14px;
    }
    .donation-form .form-group {
        margin-bottom: 15px;
    }
    .donation-form .form-group input,
    .donation-form .form-group select {
        height: 52px;
        padding: 12px 16px;
        font-size: 14px;
    }
    .donation-form .form-check {
        margin-bottom: 15px;
    }
    .checkbox label:before {
        top: 1.5px;
    }
    .checkbox input:checked+label:after {
        content: "";
        top: 4px;
    }
    .contact-item-wrap {
        padding: 20px 20px;
    }
    .contact-item-wrap .contact-item {
        margin-bottom: 20px;
    }
    .contact-item-wrap .contact-item h3 {
        font-size: 20px;
        margin: 0 0 8px;
    }
    .contact-item-wrap .contact-item p,
    .contact-item-wrap .contact-item a {
        font-size: 14px;
    }
    #contactForm {
        padding: 20px 20px 15px;
    }
    #contactForm .form-group {
        margin: 0 0 15px;
    }
    #contactForm .form-group input {
        height: 52px;
    }
    #contactForm .form-group textarea {
        height: 120px;
    }
    .checkbox label {
        font-size: 14px;
    }
    article {
        margin-bottom: 18px;
        padding-bottom: 14px;
    }
    .event-desc .single-project-img,
    .event-desc .single-event-img,
    .project-desc .single-project-img,
    .project-desc .single-event-img {
        margin: 5px 0 20px;
    }
    article h1,
    .project-desc h1,
    .service-desc h1,
    .event-desc h1,
    .team-desc h1,
    .terms-wrap h1 {
        font-size: 26px;
    }
    article h2,
    .project-desc h2,
    .service-desc h2,
    .event-desc h2,
    .team-desc h2,
    .terms-wrap h2 {
        font-size: 24px;
    }
    article h3,
    .project-desc h3,
    .service-desc h3,
    .event-desc h3,
    .team-desc h3,
    .terms-wrap h3 {
        font-size: 22px;
    }
    article h4,
    .project-desc h4,
    .service-desc h4,
    .event-desc h4,
    .team-desc h4,
    .terms-wrap h4 {
        font-size: 20px;
    }
    article h5,
    .project-desc h5,
    .service-desc h5,
    .event-desc h5,
    .team-desc h5,
    .terms-wrap h5 {
        font-size: 18px;
    }
    article h6,
    .project-desc h6,
    .service-desc h6,
    .event-desc h6,
    .team-desc h6,
    .terms-wrap h6 {
        font-size: 16px;
    }
    article h1,
    article h2,
    article h3,
    article h4,
    article h5,
    article h6,
    .project-desc h1,
    .project-desc h2,
    .project-desc h3,
    .project-desc h4,
    .project-desc h5,
    .project-desc h6,
    .service-desc h1,
    .service-desc h2,
    .service-desc h3,
    .service-desc h4,
    .service-desc h5,
    .service-desc h6,
    .event-desc h1,
    .event-desc h2,
    .event-desc h3,
    .event-desc h4,
    .event-desc h5,
    .event-desc h6,
    .team-desc h1,
    .team-desc h2,
    .team-desc h3,
    .team-desc h4,
    .team-desc h5,
    .team-desc h6,
    .terms-wrap h1,
    .terms-wrap h2,
    .terms-wrap h3,
    .terms-wrap h4,
    .terms-wrap h5,
    .terms-wrap h6 {
        margin-bottom: 15px;
    }
    article ol,
    .project-desc ol,
    .service-desc ol,
    .event-desc ol,
    .team-desc ol,
    .terms-wrap ol {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    article ul li,
    article ol li,
    .project-desc ul li,
    .project-desc ol li,
    .service-desc ul li,
    .service-desc ol li,
    .event-desc ul li,
    .event-desc ol li,
    .team-desc ul li,
    .team-desc ol li,
    .terms-wrap ul li,
    .terms-wrap ol li {
        font-size: 14px;
        margin-bottom: 10px;
    }
    article ul li:last-child,
    article ol li:last-child,
    .project-desc ul li:last-child,
    .project-desc ol li:last-child,
    .service-desc ul li:last-child,
    .service-desc ol li:last-child,
    .event-desc ul li:last-child,
    .event-desc ol li:last-child,
    .team-desc ul li:last-child,
    .team-desc ol li:last-child,
    .terms-wrap ul li:last-child,
    .terms-wrap ol li:last-child {
        margin-bottom: 0;
    }
    article .content-feature-list,
    .project-desc .content-feature-list,
    .service-desc .content-feature-list,
    .event-desc .content-feature-list,
    .team-desc .content-feature-list,
    .terms-wrap .content-feature-list {
        margin: 15px 0 15px;
    }
    .post-author .post-author-info h4 {
        font-size: 20px;
    }
    .wp-block-quote {
        padding: 25px;
        margin: 20px 0 20px;
    }
    .post-metainfo {
        margin-bottom: 15px;
    }
    .post-metainfo li {
        margin-bottom: 0;
    }
    .post-img {
        margin: 0 0 25px;
    }
    .author-info-wrap .author-info h6 {
        font-size: 15px;
    }
    .post-tag {
        margin-top: 15px;
    }
    .post-tag ul li a {
        font-size: 13px;
    }
    .post-author {
        padding: 20px;
    }
    .post-author .post-author-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .post-author .post-author-info {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .post-author .post-author-info h4 {
        font-size: 14px;
    }
    .post-author .post-author-info h4 a {
        font-size: 20px;
    }
    .comment-box-title {
        margin-bottom: 20px;
    }
    .comment-box-wrap {
        padding: 20px;
    }
    .comment-item-wrap .comment-item {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .comment-item-wrap .comment-item.reply {
        margin-left: 25px;
    }
    .comment-item-wrap .comment-item .comment-author-img {
        width: 65px;
        height: 65px;
    }
    .comment-item-wrap .comment-item .comment-author-wrap {
        width: 100%;
        margin-left: 0;
        margin-top: 22px;
    }
    .comment-item-wrap .comment-item .comment-author-wrap .comment-author-name {
        margin: 0 0 11px;
    }
    .comment-item-wrap .comment-item .comment-author-wrap .comment-author-name h5 {
        font-size: 18px;
        display: block;
    }
    .comment-item-wrap .comment-item .comment-author-wrap .comment-text {
        margin-bottom: 8px;
    }
    #cmt-form .comment-form .form-group {
        margin-bottom: 15px;
    }
    #cmt-form .comment-form .form-group label {
        font-size: 14px;
    }
    #cmt-form .comment-form .form-group input {
        height: 54px;
    }
    #cmt-form .comment-form .form-check {
        margin-bottom: 15px;
    }
    .sidebar .sidebar-widget {
        padding: 20px;
    }
    .sidebar .sidebar-widget h4 {
        font-size: 20px;
        margin: 0 0 20px;
        padding-bottom: 15px;
    }
    .category-box ul li a {
        font-size: 14px;
        padding: 8px 10px 8px 0;
    }
    .category-box ul li a i {
        top: 18px;
    }
    .tag-list ul li {
        margin: 9px 6px 0px 0px;
    }
    .pp-post-item .pp-post-info h6 {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 10px;
    }
    .pp-post-item .pp-post-info .pp-post-date {
        font-size: 13px;
    }
    .pp-post-item .pp-post-info .pp-post-date i {
        font-size: 14px;
    }
    .page-nav {
        margin-top: 15px;
    }
    .page-nav li a {
        width: 36px;
        height: 36px;
        font-size: 14px;
        line-height: 34px;
    }
    .page-nav li a i {
        font-size: 13px;
        top: 2px;
    }
    .login-form-wrap {
        padding: 30px 20px 20px;
    }
    .login-form-wrap .login-header h3 {
        font-size: 20px;
    }
    .login-form-wrap .login-body .form-group {
        margin-bottom: 10px;
    }
    .login-form-wrap .login-body .form-group label {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .login-form-wrap .login-body .form-group input {
        height: 50px;
    }
    .login-form-wrap .login-body .checkbox label {
        font-size: 13px;
    }
    .login-form-wrap .login-body .btn {
        padding: 12px;
    }
    .login-form-wrap .login-body .link {
        font-size: 13px;
    }
    .error-content img {
        max-width: 280px;
    }
    .error-content p {
        margin: 0 auto 20px;
        max-width: 300px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container-fluid {
        max-width: 720px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .pt-100 {
        padding-top: 70px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    .mt-100 {
        margin-top: 70px !important;
    }
    .mb-100 {
        margin-bottom: 70px !important;
    }
    .mtb-100 {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }
    .pb-50 {
        padding-bottom: 20px;
    }
    .pb-60 {
        padding-bottom: 35px;
    }
    .mt-100 {
        margin-top: 70px !important;
    }
    .smb-25 {
        margin-bottom: 25px;
    }
    .smb-70 {
        margin-bottom: 70px !important;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-70 {
        padding-top: 40px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .pb-75 {
        padding-bottom: 45px;
    }
    .pb-50 {
        padding-bottom: 20px;
    }
    .pt-75 {
        padding-top: 45px;
    }
    .breadcrumb-title h2,
    .section-title h2,
    .content-title h2 {
        font-size: 32px !important;
    }
    
    .content-wrapper {
        margin-top: 95px;
    }
    .breadcrumb-wrap {
        padding: 70px 0;
    }
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3 {
        font-size: 20px;
    }
    .hero-wrap {
        margin-top: 130px;
    }
    .hero-wrap.style1 .hero-content {
        padding: 60px 0 30px;
    }
    .hero-wrap.style1 .hero-content h1 {
        font-size: 44px;
    }
    .hero-wrap.style1 .hero-img-wrap img {
        max-width: 650px;
        margin: 0 auto;
        display: block;
    }
    .hero-wrap.style2 .hero-slide-item {
        padding: 70px 0 200px;
    }
    .hero-wrap.style2 .hero-content h1 {
        font-size: 44px;
    }
    .hero-wrap.style3 {
        padding: 80px 0;
    }
    .hero-wrap.style3 .hero-content h1 {
        font-size: 30px;
    }
    .hero-wrap.style3 .hero-shape-two {
        max-width: 120px;
    }
    .hero-slider-one.owl-carousel .owl-nav {
        bottom: 145px;
    }
    .hero-wrap .hero-slider-thumbs {
        top: 50%;
        right: 30px !important;
    }
     span.ct_clr_green_text{
         font-size:32px;
     }
    .promo-wrap.style2 .promo-card {
        padding: 28px 10px;
    }
    .donate-box .donate-amt li button {
        font-size: 16px;
        padding: 13px 20px;
    }
    .event-card.style1 .event-img {
        width: 315px;
    }
    .event-card.style1 .event-info {
        width: calc(100% - 335px);
    }
    .promo-video.style1 {
        height: 450px;
    }
    .counter-card-wrap.style1 {
        padding: 70px 0px 95px 0px;
    }
    .counter-card-wrap.style2 .counter-card,
    .counter-card-wrap.style3 .counter-card {
        width: calc(50% - 25px);
        padding: 40px 65px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon,
    .counter-card-wrap.style3 .counter-card .counter-icon {
        width: 75px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon i,
    .counter-card-wrap.style3 .counter-card .counter-icon i {
        font-size: 72px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text,
    .counter-card-wrap.style3 .counter-card .counter-text {
        width: calc(100% - 95px);
    }
    .counter-card-wrap.style2 .counter-card .counter-text h2,
    .counter-card-wrap.style3 .counter-card .counter-text h2 {
        font-size: 32px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text p,
    .counter-card-wrap.style3 .counter-card .counter-text p {
        font-size: 15px;
    }
   
   
}

@media only screen and (max-width: 991px) {
    .page-wrapper {
        overflow-x: hidden !important;
    }
    .md-none {
        display: none !important;
    }
    .header-wrap .header-bottom .navbar .navbar-nav .nav-item{
        width: 100% !important;
    }
    .mt-60 {
        margin-top: 30px !important;
    }
    .md-center {
        text-align: center !important;
    }
    #contactForm {
        margin-bottom: 25px;
    }
    .about-wrap .about-img-wrap {
        margin-bottom: 25px;
    }
    .counter-wrap.style1 {
        margin-top: 25px;
    }
    .counter-card-wrap.style3 {
        margin-top: 30px;
    }
  
    .project-slider-one.owl-carousel .owl-nav,
    .project-slider-three.owl-carousel .owl-nav,
    .blog-slider-one.owl-carousel .owl-nav,
    .team-slider-one.owl-carousel .owl-nav,
    .team-slider-two.owl-carousel .owl-nav {
        justify-content: center;
        margin: 30px 0 0;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        width: 40px;
        height: 40px;
        transform: translateY(0);
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .project-slider-one.owl-carousel .owl-nav button.owl-next i,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev i,
    .project-slider-three.owl-carousel .owl-nav button.owl-next i,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next i,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .team-slider-one.owl-carousel .owl-nav button.owl-next i,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev i,
    .team-slider-two.owl-carousel .owl-nav button.owl-next i {
        font-size: 17px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev {
        margin-right: 15px;
    }
    .project-slider-one.owl-carousel .owl-dots,
    .project-slider-three.owl-carousel .owl-dots,
    .blog-slider-one.owl-carousel .owl-dots,
    .team-slider-one.owl-carousel .owl-dots,
    .team-slider-two.owl-carousel .owl-dots {
        display: none;
    }
    .promo-video.style3 .play-now {
        margin: 25px auto 0;
    }
    .team-slider-one.owl-carousel .owl-nav.disabled button {
        position: relative  !important;
        left: 0px !important;
        right: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-title h2,
    .section-title h2,
    .content-title h2 {
        font-size: 34px !important;
    }
    .breadcrumb-wrap {
        padding: 90px 0;
    }
    /* .content-wrapper {
        margin-top: 138px;
    } */
    .hero-wrap.style1 .hero-content {
        padding: 90px 0;
    }
    .hero-wrap.style1 .hero-content h1 {
        font-size: 44px;
    }
    .hero-wrap.style2 .hero-slide-item {
        padding: 100px 0 200px;
    }
    .hero-wrap.style2 .hero-content h1 {
        font-size: 44px;
    }
    .hero-wrap.style3 {
        padding: 80px 0;
    }
    .hero-wrap.style3 .hero-content h1 {
        font-size: 40px;
    }
    .hero-wrap.style3 .hero-shape-two {
        max-width: 150px;
    }
    .hero-slider-one.owl-carousel .owl-nav {
        bottom: 145px;
    }
    .hero-wrap .hero-slider-thumbs {
        top: 50%;
        right: 30px !important;
    }
    .progess-bar-item .single-skill p {
        font-size: 14px;
    }
    .progess-bar-item .single-skill .circle-chart {
        width: 120px;
        height: 120px;
    }
    .counter-wrap.style1 .container-fluid {
        padding-right: calc((100% - 960px) / 2 + 12px);
        padding-left: 0;
    }
    .counter-card-wrap.style1 {
        padding: 300px 0px 125px 30px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon,
    .counter-card-wrap.style3 .counter-card .counter-icon {
        width: 55px;
    }
    .counter-card-wrap.style2 .counter-card .counter-icon i,
    .counter-card-wrap.style3 .counter-card .counter-icon i {
        font-size: 52px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text,
    .counter-card-wrap.style3 .counter-card .counter-text {
        width: calc(100% - 75px);
    }
    .counter-card-wrap.style2 .counter-card .counter-text h2,
    .counter-card-wrap.style3 .counter-card .counter-text h2 {
        font-size: 28px;
    }
    .counter-card-wrap.style2 .counter-card .counter-text p,
    .counter-card-wrap.style3 .counter-card .counter-text p {
        font-size: 15px;
    }
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-next {
        background-color: #fff;
    }
    .team-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .team-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev:hover,
    .team-slider-two.owl-carousel .owl-nav button.owl-next:hover,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .project-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .project-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev:hover,
    .project-slider-three.owl-carousel .owl-nav button.owl-next:hover {
        background-color: #338F7A;
    }
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev {
        left: -25px !important;
    }
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-next {
        right: -25px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .sidebar {
        margin: 50px 0 0;
    }
    .sidebar.style2 {
        margin: 0 0 25px;
    }
    .lg-none {
        display: none !important;
    }
    .hero-slider-one.owl-carousel .owl-nav {
        justify-content: center;
        position: relative;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev,
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 1200px) {
    .xl-none {
        display: none !important;
    }
    .content-title p {
        padding-right: 25px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .section-img.style2 {
        right: 10px;
        max-width: 60px;
    }
    .breadcrumb-wrap .section-img {
        max-width: 140px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev,
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        width: 40px;
        height: 40px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev i,
    .hero-slider-one.owl-carousel .owl-nav button.owl-next i {
        font-size: 18px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        left: 0px !important;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        right: 0px !important;
    }
    .hero-slider-thumbs {
        right: 0px !important;
    }
    .hero-slider-thumbs button {
        width: 40px !important;
        height: 40px !important;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        background-color: #fff;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .project-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev:hover,
    .project-slider-three.owl-carousel .owl-nav button.owl-next:hover,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
    .team-slider-one.owl-carousel .owl-nav button.owl-next:hover,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev:hover,
    .team-slider-two.owl-carousel .owl-nav button.owl-next:hover {
        background-color: #338F7A;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev {
        left: -35px !important;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        right: -35px !important;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .hero-wrap.style1 .hero-content h1,
    .hero-wrap.style2 .hero-content h1,
    .hero-wrap.style3 .hero-content h1 {
        font-size: 60px;
        line-height: 1.2;
    }
    .hero-wrap.style1 .hero-content p,
    .hero-wrap.style2 .hero-content p,
    .hero-wrap.style3 .hero-content p {
        padding-right: 30px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        left: 5px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        right: 5px;
    }
    .hero-slider-thumbs {
        right: 5px;
    }
    .counter-card-wrap.style1 {
        padding: 300px 50px 125px 30px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        width: 42px;
        height: 42px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev {
        left: -48px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        right: -48px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .breadcrumb-title h2,
    .section-title h2,
    .content-title h2 {
        font-size: 40px!important;
    }
    .hero-wrap.style1 .hero-content {
        padding: 110px 0;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        left: 25px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        right: 25px;
    }
    .hero-slider-thumbs {
        right: 25px;
    }
    .counter-wrap.style1 .container-fluid {
        padding-right: calc((100% - 1140px) / 2 + 12px);
        padding-left: 0;
    }
    .counter-card-wrap.style1 {
        padding: 300px 30px 125px 30px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        width: 42px;
        height: 42px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-prev,
    .project-slider-three.owl-carousel .owl-nav button.owl-prev,
    .blog-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-one.owl-carousel .owl-nav button.owl-prev,
    .team-slider-two.owl-carousel .owl-nav button.owl-prev {
        left: -48px;
    }
    .project-slider-one.owl-carousel .owl-nav button.owl-next,
    .project-slider-three.owl-carousel .owl-nav button.owl-next,
    .blog-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-one.owl-carousel .owl-nav button.owl-next,
    .team-slider-two.owl-carousel .owl-nav button.owl-next {
        right: -48px;
    }
    .pp-post-item .pp-post-info h6 {
        font-size: 15px;
        margin-bottom: 8px;
    }
}

@media only screen and (min-width: 1400px) {
    .breadcrumb-wrap {
        padding: 125px 0;
    }
    .counter-wrap.style1 .container-fluid {
        padding-right: calc((100% - 1320px) / 2 + 12px);
        padding-left: 0;
    }
}

@media only screen and (min-width: 1600px) {
    .breadcrumb-title h2,
    .section-title h2,
    .content-title h2 {
        font-size: 35px;
    }
    /* .breadcrumb-wrap .container {
        max-width: 1520px;
        margin: 0 auto;
    } */
    /* .hero-wrap .container {
        max-width: 1520px;
    } */
    .hero-wrap.style1 .hero-content h1,
    .hero-wrap.style2 .hero-content h1,
    .hero-wrap.style3 .hero-content h1 {
        font-size: 70px;
        line-height: 1.2;
    }
    .hero-wrap.style1 .hero-content p,
    .hero-wrap.style2 .hero-content p,
    .hero-wrap.style3 .hero-content p {
        padding-right: 30px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        left: 10px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        right: 10px;
    }
    .hero-slider-thumbs {
        right: 10px;
    }
}

@media only screen and (min-width: 1920px) {
    .hero-slider-one.owl-carousel .owl-nav button.owl-prev {
        left: 60px;
    }
    .hero-slider-one.owl-carousel .owl-nav button.owl-next {
        right: 60px;
    }
    .hero-slider-thumbs {
        right: 60px;
    }
}

/*# sourceMappingURL=responsive.css.map */


$clr_white: #fff;
$clr_offwhite: rgba(255,255,255,0.8);
$clr_black: #000;
$clr_emperor: #666666;
$clr_selago: #F4F7FE;
$clr_yellow: #FFC422;
$clr_gray: #888888;
$clr_albastor: #f9f9f9;
$clr_athens: #F0F4FF;
$clr_sand: #F5F5F5;
$clr_red:#FF3838;
$clr_lochi:#338F7A;
$clr_jaffa:#F59946;
$clr_cod:#181818;

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/
@mixin transition($time) {
    transition: $time;
}

@mixin transform($tr_amt) {
    transform: $tr_amt;
}

@mixin transform-origin($trl_amt) {
    transform-origin: $trl_amt;
    -webkit-transform-origin: $trl_amt;
    -moz-transform-origin: $trl_amt;
    -ms-transform-origin: $trl_amt;
    -o-transform-origin: $trl_amt;
}

@mixin transform-style($trm_amt) {
    transform-style: $trm_amt;
    -webkit-transform-style: $trm_amt;
    -moz-transform-style: $trm_amt;
    -ms-transform-style: $trm_amt;
    -o-transform-style: $trm_amt;
}

%flex {
    display: flex;
    flex-wrap: wrap;

}

%inline_flex {
    display: inline-flex;
    flex-wrap: wrap;

}

%flex_column_center {
    @extend %flex;
    flex-direction: column;
    justify-content: center;
}

%flex_column_between {
    @extend %flex;
    flex-direction: column;
    justify-content: space-between;
}

%flex_column_end {
    @extend %flex;
    flex-direction: column;
    justify-content: flex-end;
}

%align_center {
    @extend %flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

%align_start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%align_end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
}
%flex_justify_between {
    @extend %flex;
    justify-content: space-between;
}

%flex_justify_end {
    @extend %flex;
    justify-content: flex-end;
}

%flex_justify_start {
    @extend %flex;
    justify-content: flex-start;
}

%flex_start {
    @extend %flex;
    justify-content: space-between;
}

%flex_start {
    @extend %flex;
    justify-content: space-between;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%flex_between {
    @extend %flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;

}

%flex_end {
    @extend %flex;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

@media only screen and (max-width:575px) {
    .xs-center {
        text-align: center;
    }
    .xs-none {
        display: none;
    }
    .smt-15{
        margin-top: 15px!important;
    }
    .content-wrapper{
        margin-top:215px;
    }
    .breadcrumb-wrap {
        .breadcrumb-title{
            .breadcrumb-menu{
                li{
                    font-size: 14px;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
        .breadcrumb-img{
            img{
                display: none;
            }
        }
    }
    //Counter CSS
    .counter-card-wrap{
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 25px 20px;
                text-align: center;
                .counter-icon{
                    width: 100%;
                    i{
                        font-size: 48px;
                        margin: 0 auto;
                    }
                }
                .counter-text{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 10px;
                    h2{
                        font-size: 22px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    //Team Details CSS
    .team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-title {
        font-size: 14px;
    }
    .team-desc  .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
        padding: 1px 6px;
    }
    //Team Details CSS
    .progess-bar-item {
        .single-skill{
            p{
                font-size: 14px;
            }
            .circle-chart {
                width: 120px;
                height: 120px;
            }
        }
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid{
        max-width: 540px;
        padding:0 15px;
    }
    //Counter CSS
    .counter-card-wrap{
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 40px 25px;
                .counter-icon{
                    width: 65px;
                    i{
                        font-size: 62px;
                    }
                }
                .counter-text{
                    width: calc(100% - 85px);
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    p,input,textarea {
        font-size: 14px;
    }
    .btn{
        font-size: 14px;
    }
    .sm-none {
        display: none !important;
    }
    .sm-center {
        text-align: center !important;
    }

    .mb-50 {
        margin-bottom: 30px !important;
    }

    .pt-100 {
        padding-top: 50px
    }
    .pb-50 {
        padding-bottom: 0px
    }

    .pb-60 {
        padding-bottom: 15px;
    }

    .pb-100 {
        padding-bottom: 50px;
    }

    .mt-100 {
        margin-top: 50px!important;
    }
    
    .mb-100 {
        margin-bottom: 50px!important;
    }
    .mtb-100 {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pt-70 {
        padding-top: 20px;
    }

    .pb-70 {
        padding-bottom: 20px;
    }

    .pb-75 {
        padding-bottom: 25px;
    }

    .pb-50 {
        padding-bottom: 0px
    }

    .pt-75 {
        padding-top: 25px
    }

    .mb-40 {
        margin-bottom: 25px !important;
    }
    .section-title.mb-50,
    .content-title.mb-50{
        margin-bottom: 30px!important;
    }
    .section-title.mb-40{
        margin-bottom: 20px!important;
    }
    .link,.btn{
        font-size: 14px;
    }
    .btn{
        padding:12px 25px 12px;
    }
    .back-to-top {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 32px;
        right: 10px;

        i {
            font-size: 18px;
        }
    }
    .section-title,
    .content-title{
        &.style2,
        &.style3{
            span {
                font-size: 14px;
            }
        }
        &.style1{
            span {
                font-size: 14px;
            }
        }
        h2{
            font-size: 24px;
            line-height: 1.3;
        }
        &.style3{
            span{
                padding-bottom: 6px;
                &.bl-text{
                    right: 0px;
                    font-size: 40px;
                }
            }
        }
        &.style4{
            span{
                &.bl-text{
                    right: 0px;
                    font-size: 40px;
                }
            }
        }
    }
    .content-wrapper{
        margin-top: 242px;
    }
    .content-feature-list {
        li {
            margin: 0 0 12px;
            font-size: 14px;
            i {
                top: 3px;
            }
        }
    }
    .breadcrumb-wrap {
        padding: 60px 0;
    }
    .breadcrumb-title {
        h2{
            font-size: 24px;
            margin-bottom: 10px;
        }
        .breadcrumb-menu {
            li {
                font-size: 14px;
                &:after{
                    font-size: 15px;
                }
                a{
                    font-size: 14px;
                }
            }
        }
    }
    .owl-carousel {
        .owl-nav {
            button{
                &.owl-prev,
                &.owl-next{
                    width: 40px;
                    height: 40px;
                    i{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    //Card Title CSS
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3{
        font-size:20px;
    }
    //Hero CSS
    .hero-wrap{
        margin-top: 242px;
        .hero-content{
            margin-bottom: 30px;
            span{
                margin-bottom: 8px;
            }
            h1{
                margin-bottom: 15px;
                font-weight: 700;
            }
            p{
                margin-bottom: 25px;
            }
        }
        &.style1{
            .hero-content{
                padding: 70px 0 0px;
                h1{
                    font-size: 28px;
                }
            }
            .hero-shape-six{
                max-width: 45px;
            }
            .hero-shape-three{
                max-width: 80px;
            }
            .hero-shape-four{
                max-width: 70px;
            }
            .hero-skull-shape{
                max-width: 70px;
                right: 55%;
            }
            .hero-img-wrap{
                img{
                    max-width: 280px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 60px 0 220px;
                &:after {
                    background: rgba(0, 0, 0, 0.8);
                }
            }
            .hero-content{
                h1{
                    font-size: 28px;
                }
            }
        }
        &.style3{
            padding: 60px 0 55px;
            .hero-content{
                margin-bottom: 0;
                h1{
                    font-size: 28px;
                    color: $clr_white;
                }
                p{
                    color: $clr_offwhite;
                }
            }
            .hero-shape-one {
                width: 100%;
            }
            .hero-shape-three {
                max-width: 100px;
            }
            .hero-shape-two {
                max-width: 120px;
            }
            .hero-shape-four {
                opacity: 0.3;
            }
            .hero-img-wrap{
                margin-left: 30px;
                 .hero-img-shape {
                    left: -50px;
                    max-width: 80px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 195px;
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 40px;
                        height: 40px;
                        i{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: auto;
            bottom: 160px;
            right: auto;
            left: 50%;
            width: 100%;
            text-align: center;
            @include transform(translate(-50%,0));
            button{
                display: inline-block;
                width: 45px;
                height: 45px;
                margin: 0 10px 0 0;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
    //Promo CSS
    .promo-wrap{
        &.style2 {
            margin-top: -120px;
            .promo-card {
                width: 100%;
                padding: 30px 20px;
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
    //About CSS
    .about-wrap{
        .about-content{
            .content-title{
                margin-bottom: 20px;
            }
            .content-feature-list{
                margin-bottom: 25px;
            }
        }
        &.style1{
            .about-shape-one{
                max-width: 200px;
            }
            .about-shape-two{
                max-width: 60px;
            }
            .about-shape-three{
                max-width: 150px;
            }
            .about-img-wrap{
                height: 350px;
                .about-quote{
                    bottom: 20px;
                    width: calc(100% - 40px);
                    padding: 20px;
                    i{
                        top: 25px;
                        left: 20px;
                        font-size: 30px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        padding-left: 45px;
                    }
                }
            }
        }
        &.style2{
            .about-shape-one {
                bottom: 10px;
                right: 20px;
                max-width: 100px;
            }
        }
    }
    //Project Card CSS
    .project-card{
        &.style1{
            .project-info{
                padding: 20px;
                h3{
                    margin: 0 0 12px;
                }
                p{
                    margin: 0 0 15px;
                }
                .project-shape{
                    max-width: 120px;
                }
            }
        }
        &.style3{
            .project-info{
                padding: 18px 20px;
                h3{
                    margin: 0 0 12px;
                }
                .progressbar-wrap {
                    margin-bottom: 15px;
                }
            }
        }
        &.style4{
            .project-img{
                width: 100%;
            }
            .project-info{
                width: 100%;
                margin: 0;
                padding: 20px;
                h3{
                    margin-bottom: 12px;
                }
                .progressbar-wrap {
                    margin-bottom: 10px;
                }
            }
        }
    }
    //Team Card CSS
    .team-card{
        &.style1{
            .team-info{
                padding: 20px;
            }
        }
        &.style2{
            .team-info-wrap{
                padding: 20px;
            }
        }
    }
    //CTA Section CSS
    .cta-wrap{
        &.style1{
            .cta-btn{
                text-align: center;
                .btn{
                    padding: 11px 15px;
                    margin-right: 0;
                    display: block;
                    width: 100%;
                    &:nth-child(2){
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .cta-wrap.style1::after,
    .cta-wrap.style2::after {
        z-index: -1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0.3) 100%);
    }
     //Event Card CSS
     .event-card{
        &.style1,
        &.style2,
        &.style3{
            .event-img {
                width: 100%;
                border-radius: 5px 5px 0 0;
                img{
                    border-radius: 5px 5px 0 0;
                }
            }
            .event-info {
                padding: 18px 20px;
                width: 100%;
                margin-left: 0;
            }
        }
        &.style4,
        &.style5{
            .event-info{
                padding: 18px 20px;
            }
        }
    }
    .newsletter-form{
        &.style1{
            padding: 22px 20px 25px;
            h4{
                font-size: 20px;
            }
            p{
                margin: 0 0 15px;
            }
            .form-group{
                margin-bottom: 15px;
                input{
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }
    .event-search-form{
        padding: 20px 20px 0;
        .form-group{
            select,input{
                height: 52px;
                font-size: 14px;
            }
            button{
                margin-top: 0;
            }
        }
    }
    //Counter CSS
    .promo-video{
        &.style1{
            height: 350px;
        }
        &.style2{
            .play-now {
                margin-top: 20px;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 50px 0px 75px 0px;
            .counter-card{
                padding: 30px 20px;
                max-height: 125px;
                &:nth-child(2),
                &:nth-child(4){
                    top: 50px;
                }
                .counter-text{
                    h2{
                        font-size: 26px;
                    }
                }
                &.style1{
                    border-radius: 40px 40px 0 40px;
                }
                &.style2{
                    border-radius: 40px 40px 40px 0;
                }
                &.style3{
                    border-radius: 40px 0 40px 40px;
                }
                &.style4{
                    border-radius: 0 40px 40px 40px;
                }
            }
        }
    }
    //Donate Box
    .donate-box {
        padding-left: 25px;
        padding-right: 25px;
        .donate-amt {
            li{
                margin-right: 12px;
                margin-bottom: 12px;
                button {
                    font-size: 14px;
                    padding: 10px 18px;
                }
            }
        }
    }
    //Blog CSS
    .blog-card{
        .blog-info{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .blog-author{
                .blog-author-info{
                    span{
                        font-size: 14px;
                    }
                }
            }
            .blog-metainfo{
                li{
                    a{
                        font-size: 14px;
                        i{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &.style1{
            .blog-info{
                padding: 20px;
                .blog-author{
                    margin: 0 0 18px;
                }
                h3{
                    margin-bottom: 12px;
                }
                p{
                    margin-bottom: 12px;
                }
            }
            .link{
                padding: 13px 15px;
            }
        }
        &.style2{
            .blog-info{
                padding: 20px;
                .blog-author{
                    margin: 0 0 18px;
                }
                h3{
                    margin-bottom: 12px;
                }
                p{
                    margin-bottom: 12px;
                }
            }
            .link{
                padding: 13px 15px;
            }
        }
        &.style3{
            padding: 20px;
            .blog-info{
                padding-top: 20px;
            }
        }
    }
    //Slider btn css
    .project-slider-one{
        &.owl-carousel{
            .owl-nav{
                justify-content: center;
                margin: 15px 0 20px;
                button{
                    &.owl-prev,
                    &.owl-next{
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        width: 40px;
                        height: 40px;
                        @include transform(translateY(0));
                        i{
                            font-size: 22px;
                        }
                    }
                    &.owl-prev{
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    //Event & Project Details CSS
    .event-info-item-wrap{
        .event-info-item {
            padding-bottom: 15px;
            margin-bottom: 15px;
            p,span {
                font-size: 14px;
            }
        }
    }
    .project-info-item-wrap {
        .project-info-item {
            margin-bottom: 20px;
        }
    }
    .project-desc {
        .wp-block-quote {
            padding: 20px;
            p{
                font-size: 14px;
            }
        }
    }
    .register-widget {
        .form-group {
            input,
            select{
                height: 52px;
                font-size: 14px;
            }
        }
    }
    //Accordion CSS
    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-size: 16px;
                padding: 15px 40px 15px 20px;
                span {
                    min-width: 30px;
                    padding: 20px 10px;
                    i{
                        font-size: 20px;
                        right: 15px;
                    }
                }
            }    
        }
        .accordion-body {
            padding: 20px 20px 20px;
        }
    }
    //Donation CSS
    .donation-form{
        padding: 20px;
        h2{
            font-size: 20px!important;
        }
        h4{
            margin: 20px 0 20px;
        }
        .radionbtn-wrap{
            .radionbtn{
                label{
                    font-size: 14px;
                }
            }
        }
        .form-group{
            margin-bottom: 15px;
            input,select{
                height: 52px;
                padding: 12px 16px;
                font-size: 14px;
            }
        }
        .form-check{
            margin-bottom: 15px;
        }
    }
    .checkbox {
        label {
            &:before {
                top: 1.5px;
            }
        }
    }
    .checkbox input:checked + label:after {
        content: "";
        top: 4px;
    }
    //Contact CSS
    .contact-item-wrap{
        padding: 20px 20px;
        .contact-item {
            margin-bottom: 20px;
            h3{
                font-size: 20px;
                margin: 0 0 8px;
            }
            p,a{
                font-size: 14px;
            }
        }
    }
    #contactForm {
        padding: 20px 20px 15px;
        .form-group {
            margin: 0 0 15px;
            input {
                height: 52px;
            }
            textarea {
                height: 120px;
            }
        }
    }
    .checkbox{
        label {
            font-size: 14px;
        }
    }
    //Blog Details CSS
    article {
        margin-bottom: 18px;
        padding-bottom: 14px;
    }
    .event-desc .single-project-img,
    .event-desc .single-event-img,
    .project-desc .single-project-img,
    .project-desc .single-event-img {
        margin: 5px 0 20px;
    }
    article,
    .project-desc,
    .service-desc,
    .event-desc,
    .team-desc,
    .terms-wrap{
        h1{
            font-size: 26px;
        }
        h2{
            font-size: 24px;
        }
        h3{
            font-size: 22px;
        }
        h4{
            font-size: 20px;
        }
        h5{
            font-size: 18px;
        }
        h6{
            font-size: 16px;
        }
        h1,h2,h3,h4,h5,h6{
            margin-bottom: 15px;
        }
        ol{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ul,ol{
            li{
                font-size: 14px;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                }    
            }
        .content-feature-list{
            margin: 15px 0 15px;
        }
    }
    .post-author .post-author-info h4 {
        font-size: 20px;
    }
    .wp-block-quote {
        padding: 25px;
        margin: 20px 0 20px;
    }
    .post-metainfo {
        margin-bottom: 15px;
        li {
            margin-bottom: 0;
        }
    }
    .post-img {
        margin: 0 0 25px;
    }
    .author-info-wrap {
        .author-info {
            h6 {
                font-size: 15px;
            }    
        }
    }
    .post-tag {
        margin-top: 15px;
            ul {
            li{
                a {
                    font-size: 13px;
                }
            }
        }
    }
    .post-author {
        padding: 20px;
        .post-author-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
        .post-author-info {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
            h4 {
                font-size: 14px;
               a{
                font-size: 20px;
               }
            }
        }
    }
    .comment-box-title {
        margin-bottom: 20px;
    }
    .comment-box-wrap {
        padding: 20px;
    }
    .comment-item-wrap {
        .comment-item {
            padding-bottom: 20px;
            margin-bottom: 20px;
            &.reply{
                margin-left: 25px;
            }
            .comment-author-img {
                width: 65px;
                height: 65px;
            }
            .comment-author-wrap {
                width: 100%;
                margin-left: 0;
                margin-top: 22px;
                .comment-author-name{
                    margin: 0 0 11px;
                    h5{
                        font-size: 18px;
                        display: block;
                    }
                }
                .comment-text{
                    margin-bottom: 8px;
                }
            }
        }
    }
    
    #cmt-form {
        .comment-form{
            .form-group{
                margin-bottom: 15px;
                label{
                    font-size: 14px;
                }
                input{
                    height: 54px;
                }
            }
           .form-check{
               margin-bottom: 15px;
           }
        }
    }
    .sidebar {
        .sidebar-widget {
            padding:20px;
            h4 {
                font-size: 20px;
                margin: 0 0 20px;
                padding-bottom: 15px;
            }
        }
     }
    .category-box{
         ul{
             li{
                 a{
                     font-size: 14px;
                     padding:8px 10px 8px 0;
                     i{
                         top: 18px;
                     }
                 }
             }
         }
    }
    .tag-list {
        ul {
            li {
                margin: 9px 6px 0px 0px;
            }    
        }
    }
    .pp-post-item{
        .pp-post-info {
            h6 {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 10px;
            }
            .pp-post-date {
                font-size: 13px;
                i{
                    font-size: 14px;
                }
            }
        }
    }
    .page-nav {
        margin-top: 15px;
        li{
            a{
                width: 36px;
                height: 36px;
                font-size: 14px;
                line-height: 34px;
                i{
                    font-size: 13px;
                    top: 2px;
                }
            }
        }
    }
    //Account Section CSS
    .login-form-wrap {
        padding: 30px 20px 20px;
       .login-header {
           h3 {
               font-size: 20px;
           }
       }
       .login-body{
           .form-group{
               margin-bottom: 10px;
               label{
                   font-size: 14px;
                   margin-bottom: 5px;
               }
                input {
                height: 50px;
                }
           }
           .checkbox{
               label{
                   font-size: 13px;
               }
           }
           .btn{
               padding: 12px;
           }
           .link{
               font-size: 13px;
           }
       }
    }
    //Error Page
    .error-content {
        img{
            max-width:280px;
        }
        p {
            margin: 0 auto 20px;
            max-width: 300px;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container-fluid{
        max-width: 720px;
        padding-left:15px;
        padding-right:15px;
    }
    .pt-100 {
        padding-top: 70px
    }

    .pb-100 {
        padding-bottom: 70px;
    }

    .mt-100 {
        margin-top: 70px!important;
    }

    .mb-100 {
        margin-bottom: 70px!important;
    }
    .mtb-100 {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }
    .pb-50 {
        padding-bottom: 20px
    }

    .pb-60 {
        padding-bottom: 35px;
    }

    .mt-100 {
        margin-top: 70px !important;
    }

    .smb-25 {
        margin-bottom: 25px;
    }

    .smb-70 {
        margin-bottom: 70px !important;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pt-70 {
        padding-top: 40px
    }

    .pb-70 {
        padding-bottom: 40px
    }

    .pb-75 {
        padding-bottom: 45px
    }

    .pb-50 {
        padding-bottom: 20px
    }

    .pt-75 {
        padding-top: 45px
    }
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 32px;
        }
    }
    .content-wrapper{
        margin-top:128px;
    }
    .breadcrumb-wrap {
        padding: 70px 0;
    }
    //Card Title CSS
    .team-card h3,
    .promo-wrap.style2 .promo-card h3,
    .event-card .event-info h3,
    .project-card .project-info h3,
    .blog-card .blog-info h3{
        font-size:20px;
    } 
    //Hero CSS
    .hero-wrap{
        margin-top: 130px;
        &.style1{
            .hero-content{
                padding: 60px 0 30px;
                h1{
                    font-size: 44px;
                }
            }
            .hero-img-wrap{
                img{
                    max-width: 650px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 70px 0 200px;
            }
            .hero-content{
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style3{
            padding: 80px 0;
            .hero-content{
                h1{
                    font-size: 30px;
                }
            }
            .hero-shape-two {
                max-width: 120px;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 145px;
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: 50%;
            right: 30px!important;
        }
    }
    //Promo Card CSS
    .promo-wrap.style2 .promo-card {
        padding: 28px 10px;
    }
    //Donate Box
    .donate-box {
        .donate-amt {
            li{
                button {
                    font-size: 16px;
                    padding: 13px 20px;
                }
            }
        }
    }
    //Event Card CSS
    .event-card{
        &.style1{
            .event-img {
                width: 315px;
            }
            .event-info {
                width: calc(100% - 335px);
            }
        }
    }
    //Counter CSS
    .promo-video{
        &.style1{
            height: 450px;
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 70px 0px 95px 0px;
        }
        &.style2,
        &.style3{
            .counter-card{
                width: calc(50% - 25px);
                padding: 40px 65px;
                .counter-icon{
                    width: 75px;
                    i{
                        font-size: 72px;
                    }
                }
                .counter-text{
                    width: calc(100% - 95px);
                    h2{
                        font-size: 32px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .page-wrapper{
        overflow-x: hidden!important;
    }
    .md-none {
        display: none !important;
    }
    .mt-60 {
        margin-top: 30px !important;
    }
    .md-center {
        text-align: center !important;
    }
    //Contact CSS
    #contactForm{
        margin-bottom: 25px;
    }
    //About CSS
    .about-wrap{
        .about-img-wrap{
            margin-bottom: 25px;
        }
    }
    //Counter CSS
    .counter-wrap{
        &.style1 {
            margin-top: 25px;
        }
    }
    .counter-card-wrap{
        &.style3{
            margin-top: 30px;
        }
    }
    //Slider Btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
             .owl-nav{
                 justify-content: center;
                 margin: 30px 0 0;
                 button{
                     &.owl-prev,
                     &.owl-next{
                         position: relative;
                         left: auto;
                         right: auto;
                         top: auto;
                         width: 40px;
                         height: 40px;
                         @include transform(translateY(0));
                         i{
                             font-size: 17px;
                         }
                     }
                     &.owl-prev{
                         margin-right: 15px;
                     }
                 }
             }
             .owl-dots{
                display: none;
             }
         }
     }
     //Promo Css
     .promo-video{
        &.style3{
            .play-now{
                margin: 25px auto 0;
            }
        }
     }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 34px;
        }
    }
    .breadcrumb-wrap {
        padding: 90px 0;
    }
    .content-wrapper{
        margin-top: 138px;
    }
    //Hero CSS
    .hero-wrap{
        &.style1{
            .hero-content{
                padding: 90px 0;
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style2{
            .hero-slide-item{
                padding: 100px 0 200px;
            }
            .hero-content{
                h1{
                    font-size: 44px;
                }
            }
        }
        &.style3{
            padding: 80px 0;
           
            .hero-content{
                h1{
                    font-size: 40px;
                }
            }
            .hero-shape-two {
                max-width: 150px;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                bottom: 145px;
            }
        }
    }
    .hero-wrap{
        .hero-slider-thumbs{
            top: 50%;
            right: 30px!important;
        }
    }
    //Team Details CSS
    .progess-bar-item {
        .single-skill{
            p{
                font-size: 14px;
            }
            .circle-chart {
                width: 120px;
                height: 120px;
            }
        }
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 960px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 300px 0px 125px 30px;
        }
        &.style2,
        &.style3{
            .counter-card{
                .counter-icon{
                    width: 55px;
                    i{
                        font-size: 52px;
                    }
                }
                .counter-text{
                    width: calc(100% - 75px);
                    h2{
                        font-size: 28px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }
    //Slider btn css
    .team-slider-one,
    .team-slider-two,
    .blog-slider-one,
    .project-slider-one,
    .project-slider-three{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        background-color: $clr_white;
                        &:hover{
                            background-color: $clr_lochi;
                        }
                    }
                    &.owl-prev{
                        left: -25px!important;
                    }
                    &.owl-next{
                        right: -25px!important;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1199px) {
    .sidebar {
        margin: 50px 0 0;
        &.style2{
            margin: 0 0 25px;
        }
    }
    .lg-none{
        display: none!important;
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                justify-content: center;
                position: relative;
                button{
                    &.owl-prev,
                    &.owl-next{
                       position: relative;
                       top: auto;
                       left: auto;
                       right: auto;
                    }
                    &.owl-prev{
                       margin-right: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:1200px) {
    .xl-none {
        display: none !important;
    }
    .content-title{
        p{
            padding-right: 25px;
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .section-img.style2 {
        right: 10px;
        max-width: 60px;
    }
    .breadcrumb-wrap{
        .section-img {
            max-width: 140px;
        }
    }
    //Hero CSS
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 40px;
                        height: 40px;
                        i{
                            font-size: 18px;
                        }
                    }
                    &.owl-prev{
                       left: 0px!important;
                    }
                    &.owl-next{
                        right: 0px!important;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 0px!important;
        button{
            width: 40px!important;
            height: 40px!important;
        }
    }
    //Slider Btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        background-color: $clr_white;
                        &:hover{
                            background-color: $clr_lochi;
                        }
                    }
                    &.owl-prev{
                        left: -35px!important;
                    }
                    &.owl-next{
                        right: -35px!important;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    //hero CSS
    .hero-wrap{
        &.style1,
        &.style2,
        &.style3{
            .hero-content{
                h1{
                    font-size: 60px;
                    line-height: 1.2;
                }
                p{
                    padding-right: 30px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 5px;
                    }
                    &.owl-next{
                        right: 5px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 5px;
    }
    //Counter CSS
    .counter-card-wrap{
        &.style1 {
            padding: 300px 50px 125px 30px;
        }
    }
    //Slider btn CSS
    .project-slider-one,
    .project-slider-three,
    .blog-slider-one,
    .team-slider-one,
    .team-slider-two{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev,
                    &.owl-next{
                        width: 42px;
                        height: 42px;
                    }
                    &.owl-prev{
                        left: -48px;
                    }
                    &.owl-next{
                        right: -48px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 40px;
        }
    }
    //Hero CSS
    .hero-wrap{
        &.style1{
            .hero-content{
                padding: 110px 0;
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 25px;
                    }
                    &.owl-next{
                        right: 25px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 25px;
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 1140px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
    .counter-card-wrap{
        &.style1 {
            padding: 300px 30px 125px 30px;
        }
    }
     //Slider btn css
     .project-slider-one,
     .project-slider-three,
     .blog-slider-one,
     .team-slider-one,
     .team-slider-two{
         &.owl-carousel{
             .owl-nav{
                 button{
                     &.owl-prev,
                     &.owl-next{
                         width: 42px;
                         height: 42px;
                     }
                     &.owl-prev{
                         left: -48px;
                     }
                     &.owl-next{
                         right: -48px;
                     }
                 }
             }
         }
     }
     //Popular Post Widget
     .pp-post-item {
        .pp-post-info {
            h6 {
                font-size: 15px;
                margin-bottom: 8px;
            }
        }
    }
    
}
@media only screen and (min-width: 1400px) {
    .breadcrumb-wrap {
        padding: 125px 0;
    }
    //Counter CSS
    .counter-wrap{
        &.style1{
            .container-fluid{
                padding-right: calc((100% - 1320px) / 2 + 12px);
                padding-left: 0;
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    .breadcrumb-title,
    .section-title,
    .content-title{
        h2{
            font-size: 48px;
        }
    }
    .breadcrumb-wrap{
       .container{
            max-width: 1520px;
            margin: 0 auto;
       }
    }
    //hero CSS
    .hero-wrap{
        .container{
            max-width: 1520px;
        }
        &.style1,
        &.style2,
        &.style3{
            .hero-content{
                h1{
                    font-size: 70px;
                    line-height: 1.2;
                }
                p{
                    padding-right: 30px;
                }
            }
        }
    }
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 10px;
                    }
                    &.owl-next{
                        right: 10px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 10px;
    }
    
}
@media only screen and (min-width:1920px) {
    .hero-slider-one{
        &.owl-carousel{
            .owl-nav{
                button{
                    &.owl-prev{
                       left: 60px;
                    }
                    &.owl-next{
                        right: 60px;
                     }
                }
            }
        }
    }
    .hero-slider-thumbs{
        right: 60px;
    }
}


/*parallax*/

.background_cover {
    background-size: cover;
}


.bg_image,
.bg_image:after {
    /* background-image: url(../../../assets/DashboardAssets/assets/background_section.png); */
}



.to_animate {
    visibility: hidden;
}



.animated {
    /*IE7 fix*/
    visibility: visible !important;
}

/*
**boxed layout
*/

#box_wrapper,
#canvas {
    overflow: visible;
    position: relative;
}

#canvas.boxed {
    padding: 1px 0;
}

#box_wrapper.container {
    overflow: hidden;
}









/* .container {
    max-width: 100%;
} */

/* @media (max-width: 767px) {
    .container {
        max-width: 500px;
    }
} */

@media (min-width: 1600px) {
    #box_wrapper.container {
        width: 1560px;
    }
}

.boxed #box_wrapper.container {
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 59px;
}

.boxed #box_wrapper.container.top-bottom-margins {
    margin-top: 80px;
    margin-bottom: 80px;
}


/**common dark and dark muted sections - .ds and .ds.ms*/

/**darkgrey section - .ds*/

.ds {
    background-color: #333333;
    color: #a4a4a4;
}

/**dark section - .ds.ms*/

.ds.ms {
    background-color: #1e1e1e;
}



.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
    color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
    color: #ffffff;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
    color: #89b149;
}

.ds .ls {
    background-color: #ffffff;
    color: #a4a4a4;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
    color: #333333;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
    color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
    color: #89b149;
}

.ds .ls a {
    color: #89b149;
}

.ds .ls a:hover {
    color: #333333;
}

.ds a {
    color: #89b149;
}

.ds a:hover {
    color: #ffffff;
}

.ds hr {
    background-color: #ffffff;
}

.ds hr.light-divider {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds blockquote,
.ds .blockquote {
    color: #ffffff;
}

.ds blockquote .blockquote-meta,
.ds .blockquote .blockquote-meta {
    color: #a4a4a4;
}

.ds .blockquote-big:before,
.ds .blockquote-big:after {
    color: #2c2c2c;
}

.ds .theme_buttons a,
.ds .theme_button {
    color: #ffffff;
}

.ds .theme_buttons a:after,
.ds .theme_button:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(255, 255, 255, 0.02); */
}

.ds .theme_buttons a:hover,
.ds .theme_buttons a:focus,
.ds .theme_button:hover,
.ds .theme_button:focus {
    color: #333333;
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
    background-color: #f5f5f5;
    color: #333333;
}

.ds .theme_buttons.inverse a:hover,
.ds .theme_buttons.inverse a:focus,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus {
    color: #ffffff;
    background-color: #333333;
}

.ds .theme_button[class*="color"] {
    color: #fff;
}

.ds .theme_button.color1:hover {
    color: #89b149;
}

.ds .theme_button.color2:hover {
    color: #e23751;
}

.ds .theme_button[class*="color"]:hover,
.ds .theme_button[class*="color"]:focus {
    background-color: #ffffff;
}

.ds .border_button.color1:hover {
    border-color: #89b149;
    background-color: transparent;
}

.ds .social-icon.color-icon:hover {
    color: #ffffff;
}

.ds .muted_background,
.ds .with_background,
.ds .well {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds.muted_section:before {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .with_border {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .highlight {
    color: #89b149;
}

.ds .highlight2 {
    color: #e23751;
}

.ds .grey,
.ds .black {
    color: #ffffff;
    border-color: #ffffff;
}

.ds .highlightlinks a {
    color: #89b149;
}

.ds .highlightlinks a:hover {
    opacity: 0.5;
}

.ds .greylinks a {
    color: #a4a4a4;
}

.ds .greylinks a:hover {
    color: #89b149;
}

.ds .darklinks a {
    color: #ffffff;
}

.ds .darklinks a:hover {
    color: #89b149;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds .section_header+p {
    color: inherit;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds textarea,
.ds select,
.ds .form-control {
    background-color: #333333;
    border-color: rgba(255, 255, 255, 0.2);
}

.ds form label+[class*="icon-"] {
    color: #ffffff;
}

.ds .form-group-select:before {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .form-control:-moz-placeholder {
    color: #a4a4a4;
    opacity: 1;
}

.ds .form-control::-moz-placeholder {
    color: #a4a4a4;
    opacity: 1;
}

.ds .form-control:-ms-input-placeholder {
    color: #a4a4a4;
}

.ds .form-control::-webkit-input-placeholder {
    color: #a4a4a4;
}

.ds .form-control:focus:-moz-placeholder {
    color: transparent;
}

.ds .form-control:focus::-moz-placeholder {
    color: transparent;
}

.ds .form-control:focus:-ms-input-placeholder {
    color: transparent;
}

.ds .form-control:focus::-webkit-input-placeholder {
    color: transparent;
}

.ds .contact-form.transparent-background .form-control {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .border-paragraphs p {
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .table-striped>tbody>tr:nth-child(odd),
.ds .table-striped>tbody>tr:nth-child(odd)>td,
.ds .table-striped>tbody>tr:nth-child(odd)>th {
    border-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .color-icon.bg-icon {
    background-color: #ffffff;
}

.ds .color-icon.bg-icon:hover {
    color: #ffffff;
}

.ds .color-bg-icon:hover {
    background-color: #89b149;
}

 .tab-content,
 .nav-tabs>li>a {
    background-color: rgba(255, 255, 255, 0.06);
}

 .nav-tabs>li.active>a,
 .nav-tabs>li.active>a:hover,
 .nav-tabs>li.active>a:focus {
    border-color: #89b149;
    border-bottom-color: transparent;
    background-color: #89b149;
    color: #ffffff;
}

.ds .price-table.style2 {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .plan-name {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .plan-price p {
    color: #ffffff;
}

.ds .features-list .disabled:before {
    color: #ffffff;
}

.ds ul.list1 {
    color: #ffffff;
}

.ds ul.list1 li {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .testimonials-carousel .media+p {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .side-item {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .entry-excerpt {
    color: #ffffff;
}

.ds .comment-meta {
    color: #ffffff;
}

.ds .comment-meta .author_url {
    color: #ffffff;
}

.ds .comment-list article:after {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds .comment-respond {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .entry-tags {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_nav_menu ul ul {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_popular_entries .media-heading {
    color: #ffffff;
}

.ds .tweet_list li+li .tweet_right {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_tag_cloud a {
    color: #ffffff;
}

.ds #calendar_wrap {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar table {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar tfoot td a {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar caption,
.ds .widget_calendar thead,
.ds .widget_calendar th {
    color: #ffffff;
}

.ds .widget_calendar tbody td a:hover {
    color: #ffffff;
}

.ds .grid-view h3:before,
.ds .grid-view h3:after {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel.product-thumbnails .owl-nav>div:after {
    color: #ffffff;
}

.ds .ui-slider {
    background-color: rgba(255, 255, 255, 0.06);
}

.ds .ui-slider .ui-slider-handle {
    background-color: #ffffff;
}

.ds #sort_view {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after,
.ds #toggle_shop_view:before {
    background-color: rgba(247, 247, 247, 0.06);
}

.ds .widget_layered_nav .color-filters a:before {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .thumbnail {
    background-color: transparent;
}

.ds .thumbnail h3 a {
    color: #ffffff;
}

.ds .thumbnail .caption {
    border-color: transparent;
    background-color: rgba(252, 252, 252, 0.06);
}

.ds .progress {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds .blog-accordion .panel-heading {
    background-color: rgba(255, 255, 255, 0.2);
}

.ds .panel-group .panel-heading+.panel-collapse .panel-body {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .vertical-tabs .nav>li>a {
    background-color: rgba(255, 255, 255, 0.06);
}

 .vertical-tabs .nav>li.active>a,
 .vertical-tabs .nav>li>a:hover {
    background-color: #89b149;
}

.ds .entry-meta .comments-link a {
    color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .with_corners:before,
.ds .with_corners:after {
    border-color: #ffffff;
}

.ds .with_corners.color_corners:before,
.ds .with_corners.color_corners:after {
    border-color: #89b149;
}

.ds .with_shadow,
.ds .features-teaser.teaser:hover .teaser_icon {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
}

.ds .countdown-period,
.ds .darken-font-color {
    color: #ffffff;
}

.ds .bg_teaser.light_teaser .bg_overlay {
    background-color: #333333;
}

.ds .bg_teaser.light_teaser:hover .bg_overlay,
.ds .bg_teaser.light_teaser.hover .bg_overlay {
    background-color: rgba(30, 30, 30, 0.9);
}

.ds .features-teaser.teaser:hover .teaser_icon {
    background-color: #333333;
}

.ds .info-teaser+.info-teaser {
    border-color: rgba(255, 255, 255, 0.06);
}

.ds .contact-form-container {
    /* background-image: url("../../../assets/DashboardAssets/assets/contact-form-dark.png"); */
}

.ds p.item-meta .date,
.ds p.item-meta .item-comments,
.ds .widget_search .form-inline input[type="text"],
.ds .widget_mailchimp .form-inline input[type="email"] {
    border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_search .form-inline .theme_button:after,
.ds .widget_mailchimp .form-inline .theme_button:after {
    display: none;
}

.ds .flex-control-paging li a:after,
.ds .flex-control-paging li a.flex-active,
.ds .flex-control-paging li a:hover {
    background-color: #ffffff;
}


section .intro_section .slide_description_wrapper {
    padding-left: 60px;
    padding-right: 60px;
    right: 30px;
}

@media (max-width: 600px) {
    section .intro_section .slide_description_wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }
}


/** 
* intro_section styles
*/

/* flexslider */

.intro_section {
    position: relative;
    overflow: hidden;
    clear: both;
}

.intro_section>img {
    width: 100%;
}

.intro_section img {
    opacity: 0.4;
}

.intro_section .flexslider:before,
.intro_section .flexslider:after {
    content: '';
    display: block;
    clear: both;
}

/* div#canvas {
    margin-top: 115px;
} */
.intro_section .container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.intro_section .container.nav-container {
    width: 100%;
}

.intro_section [class*="col-"] {
    position: static;
}

.intro_section .slide_description_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    font-size: 0;
    z-index: 5;
    padding: 0 80px;
}

.intro_section .slide_description_wrapper .slide_description {
    font-size: 14px;
}

.intro_section .slide_description_wrapper:before,
.intro_section .slide_description {
    display: inline-block;
    vertical-align: middle;
}

.intro_section .slide_description_wrapper:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

.intro_section .big {
    font-family:'Inter', sans-serif !important;
    line-height: 77px;
    font-weight: 700;
}

.intro_section .fontsize_16 {
    line-height: 30px;
}

.intro_section .top-corner {
    max-width: 120px;
}

.intro_section .top-corner div {
    margin-top: -3px;
    text-transform: uppercase;
    padding: 20px;
}


@media (min-width: 1200px) {
    .intro_section .big {
        font-size: 46px;
    }
}

@media (max-width: 992px) {
    .intro_section {
        text-align: center;
    }
    .intro_section .nav-container {
        padding: 0;
    }
    .intro_section .flex-control-nav,
    .intro_section .flex-custom-nav {
        bottom: 40px;
        text-align: center;
    }
    .intro_section .container {
        position: static;
        width: auto;
        max-width: 100%;
    }
    .intro_section .slide_description_wrapper:before {
        display: none;
    }
    .intro_section .slide_description {
        padding: 30px 0;
        display: block;
    }
    .intro_section li .slide_description {
        padding: 30px 0 90px;
    }
    .intro_section .slide_description_wrapper {
        padding: 0;
        position: absolute;
        top: 18%;
        left: 50%;
        width: 87%;
        height: 100vh;
        transform: translateX(-50%);
    }
    .intro_section .flex-direction-nav .flex-prev {
        top: 14%;
    }
    .intro_section .flex-direction-nav .flex-next {
        top: 14%;
    }
}




.cornered-block {
    position: relative;
    padding: 45px 50px;
    max-width: 890px;
    margin: auto;
}

@media (max-width: 500px) {
    .cornered-block {
        padding: 20px;
    }
}

.cornered-block .bottom-corners:before,
.cornered-block .bottom-corners:after,
.cornered-block:before,
.cornered-block:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border: 2px solid #ffffff;
    border-bottom: none;
}

.cornered-block .bottom-corners:before,
.cornered-block .bottom-corners:after {
    top: auto;
    bottom: 0;
    border: 2px solid #ffffff;
    border-top: none;
}

.cornered-block:before,
.cornered-block .bottom-corners:before {
    border-right: none;
}

.cornered-block:after,
.cornered-block .bottom-corners:after {
    left: auto;
    right: 0;
    border-left: none;
}



.big {
    font-size: 42px;
    line-height: 0.9;
}




.grey {
    color: #333333;
    border-color: #333333;
}

.fontsize_16 {
    font-size: 18px;
}



.fontsize_20 {
    font-size: 20px;
}












/* owl images fix */




.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/

.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider .slides>li {
    display: none;
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flexslider .slides img {
    width: 100%;
    display: block;
    height: 760px;
    -o-object-fit: cover;
       object-fit: cover;
    filter: brightness(0.5);
    transition: 5s ease;
    
}
.flexslider .slides li{
    transition:1s ease;
}
.flexslider .slides li:hover img{
    transform: scale(1.3);
    
}
/* @keyframes zoom_in {
    0%,50%,100%{
        transform: scale(1);
    }
    25%,75%{
        transform: scale(1.2);
    }
} */
.flex-pauseplay span {
    text-transform: capitalize;
}

/* Clearfix for the .slides element */

.slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}



/* FlexSlider Default Theme
*********************************/

.flexslider {
    margin: 0 0;
    position: relative;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

/*.carousel li { margin-right: 5px; }*/

/* Direction Nav */

/* .flex-direction-nav {
    *height: 0;
} */

.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
    color: transparent;
    text-align: center;
}

.flex-direction-nav .flex-prev {
    left: 40px;
}

.flex-direction-nav .flex-next {
    right: 40px;
    text-align: right;
}

.flexslider:hover .flex-prev {
    opacity: 0.7;
    left: 40px;
}

.flexslider:hover .flex-next {
    opacity: 0.7;
    right: 40px;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
}

.flex-direction-nav .flex-disabled {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: default;
}

.flex-direction-nav a:before {
    font-family: "FontAwesome";
    font-size: 40px;
    content: '\f104';
    color: #ffffff;
    position: absolute;
    top: 5px;
    left: 12px;
}

.flex-direction-nav a.flex-next:before {
    content: '\f105';
    left: 14px;
}

/* Pause/Play */

.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
}

.flex-pauseplay a:before {
    font-family: 'rt-icons-2';
    font-size: 20px;
    display: inline-block;
    content: "\e6a2";
}

.flex-pauseplay a:hover {
    opacity: 1;
}

.flex-pauseplay a.flex-play:before {
    content: "\e711";
}

/* Control Nav */

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: 42px;
    text-align: center;
    z-index: 10;
}

.flex-control-nav li {
    display: inline-block;
    padding: 0 10px;
    zoom: 1;
    /* *display: inline; */
}

.flex-control-paging li a {
    width: 10px;
    height: 10px;
    display: inline-block;
    line-height: 0;
    color: transparent;
    border: 1px solid #333333;
    cursor: pointer;
    font-size: 0;
    border-radius: 50%;
    transition: margin 0.3s ease-in-out 0s;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
    border-color: #ffffff;
}

.flex-control-paging li a:after {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    text-indent: 0;
    height: 1px;
    width: 0;
    margin-left: 29px;
    background-color: #333333;
    transition: all 0.3s ease-in-out 0s;
}

.flex-control-paging li:last-child a.flex-active:after {
    width: 0;
}

.flex-control-paging li a:hover {
    background-color: #333333;
}

.flex-control-paging li a.flex-active {
    background-color: #333333;
    margin-right: 120px;
}

.flex-control-paging li a.flex-active:after {
    width: 100px;
}

.flex-control-paging li:last-child a.flex-active {
    margin-right: 0;
}

@media (min-width: 992px) {
    .flex-control-paging {
        width: 10px;
        bottom: 50%;
        right: 40px;
        transform: translateY(50%);
    }
    .flex-control-paging li,
    .flex-control-paging li a,
    .flex-control-paging li a:after {
        display: block;
    }
    .flex-control-paging li {
        padding: 10px 0;
    }
    .flex-control-paging li a:after {
        top: 0;
        left: 3px;
        width: 1px;
        height: 0;
        margin-left: 0;
        margin-top: 29px;
    }
    .flex-control-paging li a.flex-active {
        margin-left: 0;
        margin-bottom: 120px;
    }
    .flex-control-paging li:last-child a.flex-active {
        margin-bottom: 0;
    }
    .flex-control-paging li a.flex-active:after {
        width: 1px;
        height: 100px;
    }
    .flex-control-paging li:last-child a.flex-active:after {
        height: 0;
    }
}


@media screen and (max-width:575px){
   .intro_section .slide_description_wrapper {
    top: 10%;
}
.flexslider .slides img {
  
    height: 670px;
  
}
}



/* @import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;500;600;700&family=Spline+Sans:wght@300;400;500;600;700&display=swap"); */
/*---------------------------------
            General Styles 
-----------------------------------*/

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: "Inter", sans-serif !important;
}

:focus,
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
}

a,
button,
input[type="submit"] {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  font-family: "Inter", sans-serif;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

a {
  color: #373435;
  text-transform: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover,
a :focus {
  text-decoration: none;
  box-shadow: none;
  color: #373435;
}

a:focus {
  outline: 0 solid;
}

img {
  max-width: 100%;
  height: auto;
}

a#toTop {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  line-height: 1.4;
  color: #373437;
  font-family: "Inter", sans-serif;
}

body {
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  color: #666666;
}

p {
  font-size: 16px;
  line-height: 1.8;
  color: #666666;
  text-align: center;
  /* font-weight: 500; */
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-image: url(../img/down-arrow.png); */
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 18px) 50%;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-130 {
  padding-bottom: 130px;
}

.ptb-10 {
  padding-bottom: 10px;
  padding-bottom: 10px;
}

.ptb-20 {
  padding-bottom: 20px;
  padding-bottom: 20px;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ptb-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ptb-100 {
  padding-top: 80px ;
  padding-bottom: 80px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mmt-10 {
  margin-top: -10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mtb-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.z-0 {
  z-index: 0;
  position: relative;
}

#message {
  padding: 0;
  margin: 0;
}

.help-block {
  color: #e31c25;
}

.list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-style li {
  list-style: none;
}

.page-wrapper {
  overflow-x: hidden;
}

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: flex;
  flex-wrap: wrap;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn {
  flex-direction: column;
  justify-content: center;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.team-card.style2 .team-info-wrap,
.progess-bar-item {
  justify-content: space-between;
}

.donate-box .donate-amt {
  justify-content: center;
}

.header-wrap .header-top .header-top-right {
  justify-content: flex-end;
}

.fund-collection {
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.ct_hero_banner {
  background-image: url(/static/media/hero_banner.0d345582.png);
  background-size: cover;
  height: 650px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.ct_hero_banner:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #373435;
  opacity: 0.3;
  z-index: -1;
}

.ct_request_demo_btn {
  padding: 10px 15px !important;
  border-radius: 100px !important;
  color: #fff !important;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 85px;
  width: 85px;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  border-radius: 50%;
  -ms-box-shadow: 0 0 0 0 #fff;
  -o-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
  animation: ripple 3s infinite;
}

.play-now {
  position: relative;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 92px;
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
  z-index: 1;
  font-size: 28px;
  padding-left: 5px;
  display: block;
  transform-origin: center center;
  border-radius: 50%;
  margin: 0 auto;
}

.play-now .ripple:before {
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@keyframes ripple {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*---------------------------------
     Back To Top Button CSS
-----------------------------------*/
.back-to-top:hover {
  background-color: #86af48 !important;
}

.back-to-top {
  font-size: 24px;
  width: 45px;
  height: 45px;
  line-height: 52px !important;
  text-align: center;
  opacity: 1;
  visibility: visible !important;
  display: block !important;
  position: fixed;
  bottom: 30px !important;
  right: 20px;
  z-index: 999;
  border-radius: 5px;
  background: #86af48;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
}

.back-to-top.open {
  bottom: 30px;
}

.back-to-top i {
  color: #fff;
}

/*---------------------------------
     Preloader CSS
-----------------------------------*/

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 20000;
  overflow-x: hidden !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loader div {
  /* width: 12px;
    height: 12px; */
  margin: 0 10px 0 0;
  border-radius: 50px;
  transform-origin: 50% 0;
  display: inline-block;
  animation: bouncing 1.4s linear infinite;
  font-size: 48px;
  color: #86af48;
  font-weight: 700;
}

.loader div:last-child {
  margin: 0;
}

.loader div:nth-child(2) {
  /* background: rgba(1, 148, 70, 0.7); */
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  /* background: rgba(1, 148, 70, 0.5); */
  animation-delay: 0.4s;
}

.loader div:nth-child(4) {
  /* background: rgba(1, 148, 70, 0.5); */
  animation-delay: 0.8s;
}

.loader div:nth-child(5) {
  /* background: rgba(1, 148, 70, 0.5); */
  animation-delay: 1s;
}

.loader div:nth-child(6) {
  /* background: rgba(1, 148, 70, 0.5); */
  animation-delay: 1.2s;
}

@keyframes bouncing {

  0%,
  100% {
    transform: translateY(0) scale(1, 1);
    animation-timing-function: ease-in;
  }

  45% {
    transform: translateY(80px) scale(1, 1);
    animation-timing-function: linear;
  }

  50% {
    transform: translateY(80px) scale(1.5, 0.5);
    animation-timing-function: linear;
  }

  55% {
    transform: translateY(80px) scale(1, 1);
    animation-timing-function: ease-out;
  }
}

/*---------------------------------
       Button & Link CSS
----------------------------------*/

.btn {
  display: inline-block;
  line-height: 26px;
  font-weight: 500 !important;
  border-radius: 5px;
  text-align: center;
  transition: all 0.7s ease-in-out;
  position: relative;
  z-index: 1;
  border: none;
  padding: 15px 30px 15px;
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
}

.btn i {
  position: relative;
  top: 3px;
  font-size: 16px;
  margin-left: 7px;
  transition: all 0.4s ease-in-out;
}

.btn:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.btn:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  color: #fff;
}

.btn:hover:after,
.btn:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn.style1 {
  background-color: #86af48;
  border-radius: 15px 0 15px 0;
}

.btn.style1:after,
.btn.style1:before {
  background: #373435;
}

.btn.style2 {
  background-color: #86af48;
  border-radius: 15px 0 15px 0;
  color: #fff;
  border-radius: 10px 0 10px 0;
}

.btn.style2:after,
.btn.style2:before {
  background: #373435;
}

.btn.style2:hover {
  color: #fff;
}

.btn.style3 {
  background-color: #373435;
  color: #fff;
}

.btn.style3:after,
.btn.style3:before {
  background: #86af48;
}

.btn.style3:hover {
  color: #fff;
}

.btn.style4 {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #373435;
}

.btn.style4:after,
.btn.style4:before {
  background: #86af48;
}

.btn.style4:hover {
  color: #fff;
}

.btn.style5 {
  background-color: transparent;
  border: 1px solid #86af48;
  color: #86af48;
}

.btn.style5:after,
.btn.style5:before {
  background: #86af48;
}

.btn.style5:hover {
  color: #fff;
}

.btn.style6 {
  background-color: #fff;
  color: #86af48;
}

.btn.style6:after,
.btn.style6:before {
  background: #86af48;
}

.btn.style6:hover {
  color: #fff;
}

.link {
  font-weight: 500;
  line-height: 26px;
  display: inline-block;
  position: relative;
  color: #86af48;
  transition: all 0.4s ease-in-out;
}

.link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #86af48;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.link i {
  position: relative;
  top: 3px;
  margin-left: 5px;
  font-size: 14px;
  transition: all 0.4s ease-in-out;
}

.link.style1 {
  color: #86af48;
}

.link:hover {
  color: #86af48;
}

.link:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.link:hover i {
  margin-left: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev i,
.owl-carousel .owl-nav button.owl-next i {
  font-size: 18px;
  line-height: 0.8;
  display: inline-block;
  position: relative;
  top: 1px;
  color: #86af48;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #86af48;
  border-color: transparent;
}

.owl-carousel .owl-nav button.owl-prev:hover i,
.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.owl-carousel .owl-dots button.owl-dot {
  display: inline-block;
  margin: 0 6px;
  position: relative;
  bottom: -5px;
}

.owl-carousel .owl-dots button.owl-dot span {
  display: inline-block;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% 0% 50% 50%;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.4s ease-in-out;
  margin: 0px !important;
}

.owl-carousel .owl-dots button.owl-dot.active span {
  background: #86af48;
  width: 18px;
  height: 18px;
  top: 1px;
}

.ant-message {
  z-index: 999999999999 !important;
}

.ct_sub_menu_1 {
  display: block;
  height: 0px;
  transition: 0.4s ease all;
  overflow: hidden;
}

.ct_sub_menu_hover {
  display: block;
  height: 100%;
  transition: 0.4s ease all;
}

/*--------------------------------------
    Section Title,Overlay & Shape CSS
----------------------------------------*/

.section-title,
.content-title {
  position: relative;
}

.section-title h2,
.content-title h2 {
  font-size: 35px;
  margin: 0;
}

.section-title p,
.content-title p {
  margin: 12px 0 0;
}

.section-title span,
.content-title span {
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 1;
  line-height: 1;
  color: #86af48;
  padding-bottom: 18px;
  font-size: 16px;
}

.section-title span img,
.content-title span img {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 150px;
  transform: translateX(-50%);
}

.section-title.style2 span,
.content-title.style2 span {
  color: rgba(255, 255, 255, 0.8);
}

.section-title.style2 h2,
.content-title.style2 h2 {
  color: #fff;
}

.section-title.style3 span,
.content-title.style3 span {
  display: block;
  color: #86af48;
  padding-bottom: 10px;
}

.section-title.style3 span.bl-text,
.content-title.style3 span.bl-text {
  position: absolute;
  top: 42%;
  right: 20px;
  font-size: 65px;
  font-weight: 800;
  width: 100%;
  color: rgba(0, 0, 0, 0.08);
  line-height: 1;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.09);
  -webkit-text-fill-color: transparent;
  transform: translateY(-50%);
}

.section-title.style4 span,
.content-title.style4 span {
  display: block;
  color: #86af48;
}

.section-title.style4 span.bl-text,
.content-title.style4 span.bl-text {
  position: absolute;
  top: 42%;
  right: 20px;
  font-size: 65px;
  font-weight: 800;
  width: 100%;
  color: rgba(255, 255, 255, 0.095);
  line-height: 1;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.08);
  -webkit-text-fill-color: transparent;
  transform: translateY(-50%);
}

.section-title.style4 h2,
.content-title.style4 h2 {
  color: #fff;
}

.section-title.style4 p,
.content-title.style4 p {
  color: rgba(255, 255, 255, 0.8);
}

.section-title.style5 span,
.content-title.style5 span {
  display: block;
  color: #86af48;
  padding-bottom: 0;
}

.section-title.style5 h2,
.content-title.style5 h2 {
  color: #fff;
}

.section-title.style5 p,
.content-title.style5 p {
  color: rgba(255, 255, 255, 0.8);
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: #373435;
}

.bg-white {
  background-color: #fff;
}

.bg-athens {
  background-color: #f0f4ff;
}

.bg-albastor {
  background-color: #f9f9f9;
}

.bg-sand {
  background-color: #f5f5f5;
  /* background-color: rgb(228 233 99 / 23%); */
  /*background-color: rgb(228 233 99 / 5%);*/
}

.bg-lochi {
  background-color: #86af48;
}

.body_overlay {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.body_overlay.open {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-75 {
  opacity: 0.75;
}

.op-8 {
  opacity: 0.8;
}

.op-85 {
  opacity: 0.85;
}

.op-9 {
  opacity: 0.9;
}

.op-95 {
  opacity: 0.95;
}

.op-10 {
  opacity: 1;
}

.bg-f {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.pos-rel {
  position: relative;
  z-index: 1;
}

.bounce {
  animation-name: bounce !important;
  animation: float 1500ms infinite ease-in-out;
}

.moveHorizontal {
  animation: moveHorizontal 3000ms infinite ease-in-out;
}

.moveVertical {
  animation: moveVertical 3000ms infinite ease-in-out;
}

.moveHorizontal_reverse {
  animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
}

.animationFramesTwo {
  animation: animationFramesTwo 20000ms infinite ease-in-out;
}

.flyLeft {
  animation: flyLeft 30000ms infinite linear;
}

.flyRight {
  animation: flyRight 30000ms infinite linear;
}

.flyUp {
  animation: flyUp 15000ms infinite linear;
}

.moveLeft {
  animation: moveLeft 30000ms infinite linear;
}

.moveRight {
  animation: moveRight 20000ms infinite linear;
}

.waving_left {
  animation: waving_left 5s infinite linear;
  transition: all 0.3s ease-in-out;
}

.waving_right {
  animation: waving_right 7s infinite linear;
  transition: all 0.3s ease-in-out;
}

.rotate {
  animation: rotation 20s infinite linear;
}

.rotate {
  animation: rotation 20s infinite linear;
}

@keyframes waving_left {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(5deg);
  }

  50% {
    -webkit-transform: rotate(7deg);
  }

  75% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes waving_right {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-3deg);
  }

  50% {
    -webkit-transform: rotate(-6deg);
  }

  75% {
    -webkit-transform: rotate(-3deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes moveHorizontal_reverse {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes flyLeft {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(-50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(-65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(-80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(-100vw, 0px) rotate(0deg);
  }
}

@keyframes flyRight {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(100vw, 0px) rotate(0deg);
  }
}

@keyframes flyUp {
  0% {
    transform: translate(0px, 0px);
  }

  25% {
    transform: translate(50px, -50px);
  }

  50% {
    transform: translate(100px, 60px);
  }

  75% {
    transform: translate(-50px, -25px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

/*-------------------------------
     Social Link  CSS
-------------------------------*/

.social-profile li {
  display: inline-block;
  margin: 0 4px;
}

.social-profile li:first-child {
  margin-left: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li a {
  display: block;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.social-profile li a i {
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  line-height: 0.8;
}

.social-profile li a:hover {
  border-color: transparent;
  color: #86af48;
}

.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a {
  width: 35px;
  height: 35px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.social-profile.style1 li a {
  background: #fff;
  border-radius: 50% 0% 50% 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.social-profile.style1 li a i {
  color: #86af48;
}

.social-profile.style1 li a:hover {
  background-color: #86af48;
  transform: translateY(-3px);
}

.social-profile.style1 li a:hover i {
  color: #fff;
}

.social-profile.style2 li a {
  background: #f9f9f9;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.social-profile.style2 li a i {
  color: rgba(0, 0, 0, 0.3);
}

.theme-dark .social-profile.style2 li a i {
  color: #fff;
}

.theme-dark .social-profile.style2 li a {
  background: #333;
}

.social-profile.style2 li a:hover {
  background-color: #86af48;
  transform: translateY(-3px);
}

.social-profile.style2 li a:hover i {
  color: #fff;
}

.social-profile.style3 li a {
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.social-profile.style3 li a i {
  color: rgba(0, 0, 0, 0.3);
}

.social-profile.style3 li a:hover {
  background-color: #86af48;
  transform: translateY(-3px);
}

.social-profile.style3 li a:hover i {
  color: #fff;
}

/*-------------------------------
        Pagination  CSS
-------------------------------*/

.page-nav {
  text-align: center;
  margin-top: 25px;
}

.page-nav li {
  margin: 0 5px;
  display: inline-block;
}

.page-nav li a {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 54px;
  font-weight: 600;
  opacity: 0.7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.page-nav li a i {
  font-size: 18px;
  line-height: 1;
  margin: 0 auto;
  position: relative;
  top: 2px;
  transition: all 0.4s ease-in-out;
}

.page-nav li a.active,
.page-nav li a:hover {
  color: #fff;
  opacity: 1;
  background-color: #86af48;
}

.page-nav li a.active i,
.page-nav li a:hover i {
  color: #fff;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: flex;
  flex-wrap: wrap;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn {
  flex-direction: column;
  justify-content: center;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.header-wrap .header-top .header-top-right {
  justify-content: flex-end;
}

/*---------------------------------
        Header CSS 
-----------------------------------*/

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes toBottomFromTop10 {
  49% {
    transform: translateY(-10%);
  }

  50% {
    opacity: 0;
    transform: translateY(10%);
  }

  51% {
    opacity: 1;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

.header-wrap {
  /* position: absolute; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.4s;
}

.header-wrap.sticky {
  position: fixed;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
  background: #fff;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: 100%;
  top: 0px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}

.header-wrap .header-top {
  padding: 12px 0 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-wrap .header-top .header-top-left .contact-info li {
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.header-wrap .header-top .header-top-left .contact-info li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.header-wrap .header-top .header-top-left .contact-info li i {
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  transition: 0.4s;
}

.header-wrap .header-top .header-top-left .contact-info li a,
.header-wrap .header-top .header-top-left .contact-info li p {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
  transition: 0.4s;
}

.header-wrap .header-top .header-top-left .contact-info li a:hover,
.header-wrap .header-top .header-top-left .contact-info li p:hover {
  color: #86af48;
}

.header-wrap .header-top .header-top-right .social-profile {
  text-align: right;
  margin-left: 15px;
  position: relative;
  padding-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.19);
}

.header-wrap .header-top .header-top-right .social-profile:before {
  position: absolute;
  top: 50%;
  left: -2px;
  content: "";
  width: 1px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.35);
  transform: translateY(-50%);
}

.header-wrap .header-top .header-top-right .social-profile li a {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
}

.header-wrap .header-top .header-top-right .social-profile li a i {
  font-size: 14px;
  color: #fff;
}

.header-wrap .header-top .header-top-right .social-profile li a:hover {
  background-color: #86af48;
}

.header-wrap .header-bottom {
  background-color: #fff;
  transition: 0.4s;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
  padding-block: 15px;
}

.header-wrap .header-bottom .container {
  position: relative;
}

.searchbox {
  position: relative;
}

.searchbox input {
  width: 100%;
  height: 54px;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 12px 30px 12px 18px;
  border: none;
  border-radius: 15px 0 15px 0;
}

.searchbox button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 5px 20px 5px 5px;
  height: 100%;
}

.searchbox button i {
  color: #373435;
  position: relative;
  top: 4px;
}

.searchbtn {
  background-color: transparent;
  border: none;
  padding: 0;
  position: relative;
  top: 2px;
}

.searchbtn i {
  font-size: 20px;
  line-height: 1;
}

.search-area {
  position: absolute;
  top: 85px;
  right: 12px;
  background-color: #fff;
  padding: 15px 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 320px;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 5px 5px;
  transition: 0.4s;
}

.search-area.open {
  visibility: visible;
  opacity: 1;
  top: 78px;
}

.search-area input {
  width: 100%;
  height: 58px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 15px;
  background-color: transparent;
  color: #373435;
}

.search-area button {
  position: absolute;
  top: 35px;
  right: 22px;
  border: none;
  background-color: transparent;
}

.search-area button i {
  font-size: 20px;
  opacity: 0.5;
  line-height: 0.8;
}

.select-lang {
  padding-left: 18px;
  position: relative;
}

.select-lang i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 15px;
  line-height: 1;
  color: #86af48;
}

.select-lang .navbar-language .dropdown-toggle {
  background-color: transparent;
  border: none;
  font-size: 15px;
  padding-right: 16px;
  color: #666666;
  transition: 0.3s;
}

.ct_drop_icon img {
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(35);
}

.select-lang .navbar-language .dropdown-toggle:after {
  display: none;
}

.select-lang .navbar-language .dropdown-toggle:before {
  position: absolute;
  top: 0px;
  right: -2px;
  content: "\EA4E";
  font-family: "remixicon";
  font-weight: 400;
  border: 0;
  margin-left: 8px;
  font-size: 16px;
}

.select-lang .navbar-language .dropdown-menu {
  margin: 0;
  padding: 5px 0;
  box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
  left: 0;
  right: auto;
  border: 0;
  border-radius: 0;
  transform: translateY(75px) !important;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s linear;
}

.select-lang .navbar-language .dropdown-menu a {
  padding: 5px 10px;
  transition: all 0.3s linear;
  font-size: 15px;
}

.select-lang .navbar-language .dropdown-menu a img {
  width: 20px;
  margin-right: 10px;
}

.select-lang .navbar-language .dropdown-menu.show {
  transform: translateY(40px) !important;
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 767px) {

  .header-wrap .header-top .header-top-left .contact-item p,
  .header-wrap .header-top .header-top-left .contact-item a,
  .header-wrap .header-top .header-top-right .select-lang .navbar-language .dropdown-toggle {
    font-size: 14px !important;
  }

  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .navbar-brand img {
    max-width: 120px;
  }

  .header-wrap .header-top {
    padding: 15px 0;
    background-color: rgba(51, 143, 122, 0.09);
    border: none;
  }

  .header-wrap .header-top .header-top-left {
    margin-bottom: 10px;
  }

  .header-wrap .header-top .header-top-left .contact-info {
    width: 100%;
  }

  .header-wrap .header-top .header-top-left .contact-info li {
    text-align: center;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
    border: none;
  }

  .header-wrap .header-top .header-top-left .contact-info li span {
    top: 0px;
    width: 25px;
    height: 25px;
  }

  .header-wrap .header-top .header-top-left .contact-info li span i {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-right {
    justify-content: center;
  }

  .header-wrap .header-top .header-top-right .select-lang i {
    top: 4px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a {
    width: 34px;
    height: 34px;
  }

  .search-area {
    width: 100%;
    right: 0;
  }

  .search-area.open {
    top: 76px;
  }

  .search-area input {
    height: 52px;
    font-size: 14px;
    padding: 10px 15px;
  }

  .search-area button {
    top: 32px;
  }

  .search-area button i {
    font-size: 18px;
  }

  .breadcrumb-wrap {
    padding: 60px 0;
    height: auto !important;
  }

  .cti_support_text {
    max-width: 100% !important;
  }

  .ct_remove_br br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .header-top .header-top-left .contact-info li i {
    font-size: 15px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-right .select-lang span {
    font-size: 14px;
  }

  .header-wrap .header-bottom .other-options .btn {
    font-size: 15px;
    padding: 12px 20px;
  }

  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 9px !important;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .header-top {
    padding: 12px 0;
  }

  .header-wrap .header-top .header-top-left .contact-info li {
    margin-right: 8px;
    padding-right: 8px;
    border-right: none;
  }

  .header-wrap .header-top .header-top-left .contact-info li i {
    font-size: 12px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 13px;
    margin-left: 5px;
  }

  .header-wrap .header-top .header-top-right .select-lang .dropdown-toggle span {
    font-size: 13px;
  }

  .header-wrap .header-top .header-top-right .social-profile {
    margin-left: 10px;
    padding-left: 10px;
  }

  .header-wrap .header-top .header-top-right .social-profile li {
    margin: 0 3px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a {
    width: 25px;
    height: 25px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a i {
    font-size: 13px;
  }

  .header-wrap .header-top .header-top-left,
  .header-wrap .header-top .header-top-right {
    justify-content: center;
  }

  .header-wrap .navbar-brand img {
    max-width: 140px;
  }
}

@media only screen and (max-width: 991px) {
  .header-wrap .header-top .header-top-left .contact-item {
    width: 100%;
    margin: 0 0 15px;
  }

  .header-wrap .header-top .header-top-left .contact-item p,
  .header-wrap .header-top .header-top-left .contact-item a {
    color: #373435;
    font-size: 14px;
    font-weight: 400;
  }

  .header-wrap .header-bottom .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .header-wrap .header-bottom .container .search-area.open {
    top: 76px;
  }

  .header-wrap .header-bottom .navbar .navbar-collapse {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }

  .header-wrap .header-bottom .navbar .navbar-nav {
    width: 100%;
    flex-direction: column;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item {
    position: relative;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .menu-expand {
    position: absolute;
    right: 0;
    top: 14px;
    cursor: pointer;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .menu-expand i {
    color: #373435;
    font-size: 18px;
    line-height: 1;
    position: relative;
    top: 7px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .dropdown-menu .nav-item .menu-expand i {
    color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .dropdown-menu .nav-item a {
    border-color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a i {
    display: none;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.active {
    color: #86af48;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.btn {
    display: inline-block;
    padding: 10px 30px 10px;
    color: #fff;
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.btn i {
    display: inline-block;
    top: 6px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu {
    width: 100%;
    background-color: #86af48;
    border: none;
    padding: 0;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 10px;
    color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #86af48;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    padding-left: 20px;
  }

  .header-wrap .header-bottom .user-login span {
    position: relative;
    top: -3px;
  }

  .header-wrap .header-bottom .user-login span i {
    font-size: 20px;
  }

  .mobile-bar-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    top: 4px;
  }

  .menu-close {
    position: absolute;
    top: 13px;
    right: 16px;
  }

  .menu-close i {
    color: #373435;
    font-size: 25px;
  }

  .mobile-menu {
    text-align: right;
    margin-left: 20px;
  }

  .mobile-menu a {
    width: 38px;
    height: 38px;
    border-radius: 62% 38% 46% 54% / 60% 63% 37% 40%;
    text-align: center;
    background-color: #86af48;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-menu a i {
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
  }

  .mobile-menu {
    display: inline-block;
    position: relative;
    top: -5px;
  }

  .mobile-top-bar {
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    position: relative;
    top: 0px;
  }

  .mobile-top-bar span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #373435;
    border-radius: 20px;
    margin: 5.9px 0;
  }

  .mobile-top-bar span:nth-child(1) {
    margin-top: 0;
  }

  .mobile-top-bar span:nth-child(2) {
    width: 12px;
    margin-left: 8px;
  }

  .main-menu-wrap {
    width: 100%;
    position: fixed;
    background: #f5f5f5;
    z-index: 999;
    top: 0;
    height: 100vh;
    transition: 0.5s;
    left: -100%;
    padding: 55px 20px 30px;
    overflow-y: auto;
    transition: 0.4s;
  }

  .main-menu-wrap.open {
    left: 0;
  }

  .main-menu>li.has-children.menu-open>span.menu-expand {
    transform: rotate(180deg);
  }

  .main-menu>li.has-children.menu-open>span.menu-expand i {
    color: #373435;
  }

  .main-menu>li>ul>li.has-children.menu-open>span.menu-expand {
    transform: rotate(180deg);
  }

  .main-menu li {
    position: relative;
  }

  .main-menu li:last-child {
    margin: 0;
  }

  .main-menu li>span.menu-expand {
    position: absolute;
    right: 0;
    top: 13px;
    cursor: pointer;
  }

  .main-menu li>span.menu-expand i {
    color: #373435;
    font-size: 18px;
  }

  .main-menu li>span.menu-expand:hover {
    color: #373435;
  }

  .main-menu li a.active {
    color: #86af48;
  }

  .main-menu li>a {
    font-size: 14px;
    font-weight: 400;
    color: #373435;
    text-transform: capitalize;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .main-menu li>a:hover {
    color: #373435;
  }

  .main-menu li>ul.sub-menu {
    padding-left: 20px;
  }

  .header-wrap .header-bottom .main-menu-wrap #menu>ul>li>a::after {
    display: none;
  }

  .main-menu li a.active {
    font-weight: 600;
    color: #373435;
    border-color: rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (min-width: 992px) {
  .menu-expand i {
    display: none;
  }

  .header-wrap .navbar {
    padding: 0;
  }

  .header-wrap .navbar .navbar-brand {
    padding: 0 20px 0 0;
  }

  .header-wrap .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }

  .header-wrap .navbar .navbar-nav {
    margin-left: auto;
  }

  .header-wrap .navbar .navbar-nav .nav-item {
    position: relative;
    margin: 0 10px;
  }

  .header-wrap .navbar .navbar-nav .nav-item.first-child {
    margin-left: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    color: #373435;
    font-weight: 500;
    padding: 30px 0;
    transition: 0.4s;
    font-size: 16px;
    position: relative;
  }

  .header-wrap .header-bottom .container {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-wrap .navbar .navbar-nav .nav-item a:hover,
  .header-wrap .navbar .navbar-nav .nav-item a:focus,
  .header-wrap .navbar .navbar-nav .nav-item a.active {
    color: #86af48;
  }

  .header-wrap .navbar .navbar-nav .nav-item a i {
    font-size: 16px;
    line-height: 1;
    position: relative;
    top: 0px;
    display: inline-block;
    margin-left: 0px;
    transition: 0.4s;
  }

  .header-wrap .navbar .navbar-nav .nav-item a:hover i {
    transform: rotate(180deg);
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu {
    display: block !important;
    border: none;
    top: 83px;
    left: -23px;
    z-index: 99;
    opacity: 0;
    width: 300px;
    display: block;
    border-radius: 0;
    padding: 12px 0;
    position: absolute;
    visibility: hidden;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transform: rotateX(-75deg);
    transition: 0.5s;
    transform-style: preserve-3d;
    transform-origin: 0% 0%;
    background-color: #8bb249;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li {
    margin: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 10px 20px;
    position: relative;
    display: block;
    font-weight: 500;
    transition: 0.3s;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #fff !important;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li+li {
    border-top: 1px solid #fff;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:before {
    position: absolute;
    top: 50%;
    left: 0px;
    content: "";
    width: 0;
    height: 2px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-50%);
    transition: 0.4s;
  }

  .header-wrap .navbar .navbar-nav .nav-item ul.dropdown-menu ul.dropdown-menu li.nav-item a {
    display: grid;
    grid-template-columns: 35px auto;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover {
    color: #86af48;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active:before,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover:before {
    width: 20px;
    visibility: visible;
    opacity: 1;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover {
    padding-left: 30px;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    width: 100%;
    right: auto;
    left: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a i {
    margin: 0;
    position: absolute;
    top: 55%;
    font-size: 16px;
    line-height: 1;
    transform: translateY(-50%);
    right: 15px;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    right: -300px;

    left: auto;
    visibility: hidden;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .header-wrap .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: rotatex(0deg);
  }

  .header-wrap .navbar .other-options {
    display: flex;
    align-items: center;
    position: relative;
  }

  .header-wrap .navbar .other-options .option-item {
    margin: 0 0 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .navbar-brand img {
    max-width: 140px;
  }
}

@media only screen and (min-width: 1400px) {
  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 25px;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 17px;
  }
}

/* @media only screen and (min-width: 1600px) {
    .header-wrap .container {
        max-width: 1520px;
    }
} */

/*---------------------------------
       Breadcrumb CSS 
-----------------------------------*/

.content-wrapper {
  margin-top: 0px !important;
}

.ct_about_bg {
  background-image: url(/static/media/about_bg_new.3a60ae71.jpg);
  /* background-position: 0px -108px; */
}

.breadcrumb-wrap {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
  z-index: 1;
  height: 60vh;
  display: flex;
  align-items: center;
}

.breadcrumb-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.breadcrumb-title {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.breadcrumb-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 15px;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.breadcrumb-title .breadcrumb-menu li:after {
  position: absolute;
  top: 1px;
  right: -13px;
  content: "/";
  font-size: 16px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.breadcrumb-title .breadcrumb-menu li:first-child {
  margin-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:first-child a {
  padding-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:last-child {
  margin-right: 0;
  color: #86af48;
}

.breadcrumb-title .breadcrumb-menu li:last-child:after {
  display: none;
}

.breadcrumb-title .breadcrumb-menu li a {
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  padding: 0px 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  position: relative;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li a:hover {
  color: #86af48;
}

.content-feature-list {
  margin: 0 0 28px 0;
}

.content-feature-list li {
  position: relative;
  padding-left: 25px;
  margin: 0 0 18px;
}

.content-feature-list li:last-child {
  margin-bottom: 0;
}

.content-feature-list li i {
  position: absolute;
  top: 6px;
  left: 0;
  color: #86af48;
  font-size: 17px;
  line-height: 0.8;
}

/*---------------------------------------
        Sign In CSS
 ----------------------------------------*/

.login-header {
  margin: 0 0 30px;
}

.login-header h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
}

.login-header span {
  color: #fff;
}

.login-form-wrap {
  padding: 40px 40px 30px;
  margin-bottom: 25px;
  background: #f9f9f9;
}

.login-form-wrap .login-form .form-group {
  margin: 0 0 20px;
}

.login-form-wrap .login-form .form-group label {
  font-size: 16px;
  color: #666666;
  margin: 0 0 15px;
  display: block;
}

.login-form-wrap .login-form .form-group input {
  border-radius: 5px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60px;
  padding: 10px 15px;
  border-radius: 5px;
}

.login-form-wrap .login-form .btn {
  padding: 15px 35px;
}

.login-form-wrap .link.style1 {
  color: #86af48;
}

/*---------------------------
        Sidebar CSS
 ---------------------------*/

.sidebar .sidebar-widget {
  margin: 0 0 30px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding: 25px;
  border-radius: 5px;
}

.sidebar .sidebar-widget.style2 {
  background-color: transparent;
  padding: 0;
}

.sidebar .sidebar-widget h4 {
  margin: 0 0 25px;
  font-size: 22px;
  position: relative;
  line-height: 1;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #86af48;
}

.sidebar .sidebar-widget:last-child {
  margin: 0;
}

/*-------------------------------
       Search Box Widget CSS
-------------------------------*/

.search-box {
  position: relative;
}

.search-box .form-group {
  position: relative;
}

.search-box .form-group input {
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px solid #f0f4ff;
  color: #373435;
  font-size: 15px;
  line-height: 21px;
  padding: 10px 60px 10px 20px;
  border-radius: 5px;
}

.search-box .form-group input:focus {
  border-color: #888888;
}

.search-box .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 18px;
  height: 100%;
  border: none;
  border-radius: 0 5px 5px 0;
  text-align: center;
  background: #86af48;
}

.search-box .form-group button i {
  color: #fff;
  top: 4px;
  left: 1px;
  font-size: 20px;
  position: relative;
}

/*-------------------------------
     Category Widget CSS
-------------------------------*/

.category-box li {
  position: relative;
}

.category-box li:first-child a {
  padding-top: 0;
}

.category-box li:first-child a i {
  top: 5px;
}

.category-box li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}

.category-box li:last-child a i {
  top: 13px;
}

.category-box li a {
  color: #666666;
  font-weight: 500;
  padding: 8px 10px 8px 25px;
  display: block;
  transition: 0.4s;
}

.category-box li a i {
  position: absolute;
  top: 14px;
  left: 0;
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  transition: 0.4s;
}

.category-box li a:hover {
  color: #86af48;
  padding-left: 28px;
}

.category-box li a:hover i {
  color: #86af48;
}

/*-------------------------------
     Contact Widget CSS
-------------------------------*/

.contact-widget {
  /* background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg.jpg); */
  position: relative;
  padding: 60px 30px;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
}

.contact-widget:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.contact-widget h3 {
  color: #fff;
  margin: 0 0 15px;
  border: none;
  font-size: 22px;
}

.contact-widget p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 20px;
}

/*-------------------------------
     Profile Widget CSS
-------------------------------*/

.profile-widget {
  position: relative;
  border-radius: 5px;
}

.profile-widget img {
  border-radius: 5px;
}

.profile-widget .btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

/*-------------------------------
     Taglist Widget CSS
-------------------------------*/

.tag-list {
  margin-top: -12px;
}

.tag-list li {
  display: inline-block;
  margin: 12px 8px 0px 0px;
}

.tag-list li a {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 12px;
  color: #666666;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;
}

.tag-list li a:hover {
  background-color: #86af48;
  color: #fff !important;
  border-color: transparent;
}

/*-------------------------------
     Register Widget CSS
-------------------------------*/

.register-widget .form-group {
  margin-bottom: 20px;
}

.register-widget .form-group input,
.register-widget .form-group select,
.register-widget .form-group textarea {
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 12px 15px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.5);
}

.register-widget .form-group input:-ms-input-placeholder,
.register-widget .form-group select:-ms-input-placeholder,
.register-widget .form-group textarea:-ms-input-placeholder {
  opacity: 1;
}

.register-widget .form-group input::-moz-placeholder, .register-widget .form-group select::-moz-placeholder, .register-widget .form-group textarea::-moz-placeholder {
  opacity: 1;
}

.register-widget .form-group input:-ms-input-placeholder, .register-widget .form-group select:-ms-input-placeholder, .register-widget .form-group textarea:-ms-input-placeholder {
  opacity: 1;
}

.register-widget .form-group input::placeholder,
.register-widget .form-group select::placeholder,
.register-widget .form-group textarea::placeholder {
  opacity: 1;
}

.register-widget .form-group input,
.register-widget .form-group select {
  height: 58px;
}

.register-widget .form-group textarea {
  height: 150px;
  resize: none;
}

/*-------------------------------
     Project Info Widget CSS
-------------------------------*/

.project-info-item-wrap .project-info-item {
  margin-bottom: 24px;
}

.project-info-item-wrap .project-info-item:last-child {
  margin-bottom: 0;
}

.project-info-item-wrap .project-info-item h6 {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}

/*-------------------------------
     Event Info Widget CSS
-------------------------------*/

.event-info-item-wrap {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.event-info-item-wrap .event-info-item {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 15px;
  padding-bottom: 20px;
  color: #666666;
}

.event-info-item-wrap .event-info-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.event-info-item-wrap .event-info-item span {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  width: calc(100% - 105px);
  margin-left: 5px;
  text-align: right;
}

.event-info-item-wrap .event-info-item span a {
  color: #86af48;
}

.event-info-item-wrap .event-info-item p {
  font-size: 16px;
  margin: 0;
  color: #373435;
  font-weight: 500;
  width: 100px;
}

.event-info-item-wrap .event-info-item p i {
  font-size: 16px;
  position: relative;
  color: #86af48;
  top: 2px;
  margin-right: 7px;
}

/*-------------------------------
    Popular post Widget CSS
-------------------------------*/

.pp-post-item {
  margin: 0 0 20px;
}

.pp-post-item .pp-post-img {
  width: 85px;
  border-radius: 4px;
}

.pp-post-item .pp-post-img img {
  border-radius: 4px;
}

.pp-post-item .pp-post-info {
  width: calc(100% - 100px);
  margin-left: 15px;
  margin-top: -3px;
}

.pp-post-item .pp-post-info h6 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 8px;
}

.pp-post-item .pp-post-info h6 a {
  color: #373435;
}

.pp-post-item .pp-post-info .pp-post-date {
  font-size: 14px;
  line-height: 1;
  display: block;
  position: relative;
  padding-left: 22px;
  color: #666666;
}

.pp-post-item .pp-post-info .pp-post-date:hover {
  color: #86af48;
}

.pp-post-item .pp-post-info .pp-post-date i {
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 15px;
  line-height: 0.8;
  color: #86af48;
}

.pp-post-item:last-child {
  margin: 0;
}

.pp-post-item:hover .pp-post-info h6 a {
  color: #86af48;
}

/*-------------------------------
    Hero  Section CSS
-------------------------------*/

.hero-slide-one {
  /* background-image: url(../../../assets/DashboardAssets/assets/hero-slider-1.jpg); */
}

.hero-slide-two {
  /* background-image: url(../img/hero/hero-slider-2.jpg); */
}

.hero-slide-three {
  /* background-image: url(../img/hero/hero-slider-3.jpg); */
}

.hero-wrap {
  position: relative;
  z-index: 1;
  margin-top: 114px;
}

.hero-wrap .hero-content span {
  color: #86af48;
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.hero-content {
  transform: matrix(1, 0, 0, 1, 0, -7.86);
}

.hero-wrap .hero-content h1 {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 15px;
  color: #fff;
}

.hero-wrap .hero-content p {
  margin-bottom: 30px;
  color: #fff;
}

.hero-wrap.style1 {
  background-color: #f5f5f5;
  padding: 50px 0 0;
  overflow: hidden;
}

.hero-wrap.style1 .hero-shape-one,
.hero-wrap.style1 .hero-shape-two,
.hero-wrap.style1 .hero-shape-three,
.hero-wrap.style1 .hero-shape-four,
.hero-wrap.style1 .hero-shape-five,
.hero-wrap.style1 .hero-shape-six,
.hero-wrap.style1 .hero-skull-shape {
  position: absolute;
  z-index: -1;
}

.hero-wrap.style1 .hero-shape-one {
  bottom: 0;
  right: 0;
}

.hero-wrap.style1 .hero-shape-two {
  bottom: 0;
  left: 10px;
}

.hero-wrap.style1 .hero-shape-three {
  top: 40px;
  left: 40px;
}

.hero-wrap.style1 .hero-shape-four {
  top: 30px;
  right: 50px;
}

.hero-wrap.style1 .hero-shape-five {
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero-wrap.style1 .hero-shape-six {
  bottom: 0;
  right: -10px;
}

.hero-wrap.style1 .hero-skull-shape {
  bottom: -8px;
  right: 40%;
}

.hero-wrap.style1 .hero-content {
  padding: 140px 0;
}

.hero-wrap.style1 .hero-img-wrap {
  position: relative;
  bottom: -15px;
}

.hero-wrap.style2 {
  position: relative;
}

.hero-wrap.style2 .hero-slide-item {
  padding: 120px 0 260px;
  position: relative;
  z-index: 1;
}

.hero-wrap.style2 .hero-slide-item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.9) 41.6%,
      rgba(0, 0, 0, 0.03) 85%);
  z-index: -1;
}

.hero-wrap.style2 .hero-slide-item .hero-content span {
  color: #86af48;
}

.hero-wrap.style2 .hero-slide-item .hero-content h1 {
  color: #fff;
}

.hero-wrap.style2 .hero-slide-item .hero-content p {
  color: rgba(255, 255, 255, 0.8);
}

.hero-wrap.style3 {
  position: relative;
  z-index: 1;
  padding: 90px 0;
  overflow: hidden;
  background-color: #f5f5f5;
}

.hero-wrap.style3 .hero-shape-one,
.hero-wrap.style3 .hero-shape-two,
.hero-wrap.style3 .hero-shape-three,
.hero-wrap.style3 .hero-shape-four {
  position: absolute;
  z-index: -1;
}

.hero-wrap.style3 .hero-shape-one {
  top: 0;
  left: 0;
  height: 100%;
}

.hero-wrap.style3 .hero-shape-two {
  bottom: 0;
  right: 15%;
}

.hero-wrap.style3 .hero-shape-three {
  bottom: 0;
  right: 0;
}

.hero-wrap.style3 .hero-shape-four {
  top: 20px;
  right: 0;
}

.hero-wrap.style3 .hero-img-wrap {
  position: relative;
  z-index: 1;
}

.hero-wrap.style3 .hero-img-wrap .hero-img-shape {
  position: absolute;
  bottom: 0;
  left: -100px;
  z-index: -1;
}

.hero-wrap .hero-slider-thumbs {
  position: absolute;
  top: calc(50% + 140px);
  transform: translateY(-50%);
  z-index: 1;
}

.hero-wrap .hero-slider-thumbs button {
  display: block;
  margin: 0 0 10px;
  border: none;
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.hero-wrap .hero-slider-thumbs button:last-child {
  margin-bottom: 0;
}

.hero-wrap .hero-slider-thumbs button img {
  border-radius: 50%;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev,
.hero-slider-one.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  width: 55px;
  height: 55px;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev i,
.hero-slider-one.owl-carousel .owl-nav button.owl-next i {
  color: #86af48;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
.hero-slider-one.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #86af48;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover i,
.hero-slider-one.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content span {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.5s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content h1 {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content p {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.9s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content .btn {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 1.1s;
}

/*-------------------------------
    Promo  Section CSS
-------------------------------*/

.promo-wrap.style2 {
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-top: -150px;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.09);
}

.promo-wrap.style2:after {
  position: absolute;
  bottom: -10px;
  left: -10px;
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 5px;
  z-index: -1;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.promo-wrap.style2 .promo-shape-one {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.promo-wrap.style2 .promo-shape-two {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.promo-wrap.style2 .promo-card {
  width: 33.33%;
  padding: 70px 25px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.promo-wrap.style2 .promo-card:first-child {
  border-radius: 5px 0 0 5px;
}

.promo-wrap.style2 .promo-card:last-child {
  border-right: none;
  border-radius: 0 5px 5px 0;
}

.promo-wrap.style2 .promo-card .promo-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #86af48;
  margin: 0 auto 20px;
}

.promo-wrap.style2 .promo-card .promo-icon i {
  font-size: 44px;
  line-height: 0.8;
  color: #86af48;
}

.promo-wrap.style2 .promo-card h3 {
  font-size: 22px;
}

/*-------------------------------
    Project  Section CSS
-------------------------------*/

.project-card {
  margin-bottom: 25px;
}

.project-card .project-info h3 {
  font-size: 22px;
}

.project-card .project-img {
  overflow: hidden;
}

.project-card .project-img img {
  transition: 0.7s;
}

.project-card:hover .project-img img {
  transform: scale(1.05);
}

.project-card.style1 .project-info {
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;
  padding: 25px;
  transition: 0.4s;
}

.project-card.style1 .project-info h3 {
  margin: 0 0 15px;
  transition: 0.4s;
}

.project-card.style1 .project-info p {
  margin: 0 0 18px;
  transition: 0.4s;
}

.project-card.style1 .project-info .project-shape {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: -1;
  max-width: 200px;
  filter: brightness(0.1) invert(0);
  opacity: 0.06;
  transition: 0.4s;
}

.project-card.style1:hover .project-info {
  background-color: #86af48;
}

.project-card.style1:hover .project-info h3 a {
  color: #fff;
}

.project-card.style1:hover .project-info p {
  color: rgba(255, 255, 255, 0.8);
}

.project-card.style1:hover .project-info .link.style1 {
  color: #373435;
}

.project-card.style1:hover .project-info .project-shape {
  right: 0;
  filter: brightness(1) invert(0);
  opacity: 1;
}

.project-card.style2 {
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.project-card.style2 img {
  border-radius: 5px;
}

.project-card.style2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 5px;
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.241317) 58%);
}

.project-card.style2 .project-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 25px 25px;
  z-index: 1;
  width: 100%;
}

.project-card.style2 .project-info h3 {
  font-weight: 600;
  margin-bottom: 18px;
}

.project-card.style2 .project-info h3 a {
  color: #fff;
}

.project-card.style2 .project-info h3 a:hover {
  color: #86af48;
}

.project-card.style2 .project-info .fund-collection .fund-raised,
.project-card.style2 .project-info .fund-collection .fund-goal {
  color: #fff;
}

.project-card.style2 .project-img,
.project-card.style3 .project-img {
  position: relative;
}

.project-card.style2 .like-btn,
.project-card.style3 .like-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  z-index: 2;
  background-color: #fff;
  transition: 0.4s;
}

.project-card.style2 .like-btn:hover,
.project-card.style3 .like-btn:hover {
  background-color: #86af48;
}

.project-card.style2 .like-btn:hover i,
.project-card.style3 .like-btn:hover i {
  color: #fff;
}

.project-card.style2 .like-btn i,
.project-card.style3 .like-btn i {
  font-size: 26px;
  line-height: 0.8;
  margin: 0 auto;
  color: #86af48;
  transition: 0.4s;
}

.project-card.style3 {
  background-color: #fff;
  border-radius: 5px;
}

.project-card.style3 .project-img {
  border-radius: 5px 5px 0 0;
}

.project-card.style3 .project-img img {
  border-radius: 5px 5px 0 0;
}

.project-card.style3 .project-info {
  position: relative;
  z-index: 1;
  padding: 25px;
  transition: 0.4s;
}

.project-card.style3 .project-info h3 {
  margin: 0 0 15px;
  transition: 0.4s;
}

.project-card.style3 .project-info p {
  margin: 0 0 7px;
  transition: 0.4s;
}

.project-card.style3 .project-info .fund-collection {
  margin-bottom: 0;
}

.project-card.style3 .project-info .fund-collection .fund-raised,
.project-card.style3 .project-info .fund-collection .fund-goal {
  color: #666666;
}

.project-card.style4 {
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.project-card.style4 .project-img {
  position: relative;
  width: 270px;
  border-radius: 5px 0 0 5px;
}

.project-card.style4 .project-img img {
  border-radius: 5px 0 0 5px;
}

.project-card.style4 .project-img .like-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  z-index: 2;
  background-color: #fff;
}

.project-card.style4 .project-img .like-btn i {
  font-size: 26px;
  line-height: 0.8;
  margin: 0 auto;
  color: #86af48;
}

.project-card.style4 .project-info {
  position: relative;
  z-index: 1;
  padding: 25px 25px 25px 5px;
  width: calc(100% - 290px);
  margin-left: 20px;
  transition: 0.4s;
}

.project-card.style4 .project-info h3 {
  font-size: 22px;
  margin: 0 0 15px;
  transition: 0.4s;
}

.project-card.style4 .project-info p {
  margin: 0 0 5px;
  transition: 0.4s;
}

.project-card.style4 .project-info .fund-collection .fund-raised,
.project-card.style4 .project-info .fund-collection .fund-goal {
  color: #666666;
}

.skills .skillbar {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  height: 10px;
  border-radius: 15px;
}

.skills .skillbar .skillbar-bar {
  height: 10px;
  width: 0px;
  background: #86af48;
  border-radius: 50px 0 0 50px;
}

.fund-collection {
  margin: 0 0 10px;
}

.fund-collection .fund-raised,
.fund-collection .fund-goal {
  font-size: 14px;
}

.progressbar-wrap {
  margin-bottom: 20px;
}

.progressbar-wrap .progress-bar {
  margin: 0 0 25px;
  overflow: hidden;
  background: transparent;
}

.progressbar-wrap .progress-bar:last-child {
  margin-bottom: 0;
}

.progressbar-wrap .progress-bar .progress-content-outter {
  height: 12px;
  background-color: #f0f4ff;
  border-radius: 5px;
}

.progressbar-wrap .progress-bar .progress-content-outter .progress-content {
  height: 12px;
  background-color: #86af48;
  border-radius: 5px;
  width: 0%;
}

.progressbar-wrap .progress-bar .progress-title-holder {
  position: relative;
  margin: 0 0 45px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-title {
  z-index: 100;
  font-weight: 600;
  margin: 0;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper {
  width: 100%;
  z-index: 10;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
  margin-bottom: 0;
  border-radius: 3px;
  border: 1px solid #86af48;
  position: absolute;
  bottom: -35px;
  color: #86af48;
  padding: 3px 6px;
  border-radius: 5px;
  transform: translateX(-100%);
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark:after {
  position: absolute;
  bottom: -8px;
  left: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #86af48;
  border-left: 0px solid transparent;
  border-right: 8px solid transparent;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark .percent {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  color: #373435;
}

.project-slider-one .project-card,
.project-slider-two .project-card,
.project-slider-three .project-card {
  margin: 0;
}

.team-slider-one.owl-carousel .owl-nav button.owl-prev,
.team-slider-one.owl-carousel .owl-nav button.owl-next,
.team-slider-two.owl-carousel .owl-nav button.owl-prev,
.team-slider-two.owl-carousel .owl-nav button.owl-next,
.blog-slider-one.owl-carousel .owl-nav button.owl-prev,
.blog-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-three.owl-carousel .owl-nav button.owl-prev,
.project-slider-three.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.team-slider-one.owl-carousel .owl-nav button.owl-prev,
.team-slider-two.owl-carousel .owl-nav button.owl-prev,
.blog-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-three.owl-carousel .owl-nav button.owl-prev {
  left: -65px;
}

.team-slider-one.owl-carousel .owl-nav button.owl-next,
.team-slider-two.owl-carousel .owl-nav button.owl-next,
.blog-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-three.owl-carousel .owl-nav button.owl-next {
  right: -65px;
}

.team-slider-one.owl-carousel .owl-dots,
.team-slider-two.owl-carousel .owl-dots,
.blog-slider-one.owl-carousel .owl-dots,
.project-slider-one.owl-carousel .owl-dots,
.project-slider-two.owl-carousel .owl-dots,
.project-slider-three.owl-carousel .owl-dots {
  margin: 30px 0 0;
  text-align: center;
}

.project-search-form .form-group {
  margin-bottom: 25px;
  position: relative;
}

.project-search-form .form-group select,
.project-search-form .form-group input {
  width: 100%;
  height: 58px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 12px 18px;
  color: #373435;
  border: none;
}

.project-search-form .form-group select:-ms-input-placeholder,
.project-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select::-moz-placeholder, .project-search-form .form-group input::-moz-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select:-ms-input-placeholder, .project-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select::placeholder,
.project-search-form .form-group input::placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select select,
.project-search-form .form-group input select {
  opacity: 0.7;
}

.project-search-form .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
  height: 100%;
  border: none;
  background-color: transparent;
}

/*-------------------------------
    About  Section CSS
-------------------------------*/

.about-wrap {
  position: relative;
  z-index: 1;
}

.about-wrap .about-content .content-title {
  margin-bottom: 25px;
}

.about-wrap .about-content .content-feature-list {
  margin-bottom: 30px;
}

.about-wrap.style1 .about-shape-one,
.about-wrap.style1 .about-shape-two,
.about-wrap.style1 .about-shape-three {
  position: absolute;
  z-index: -1;
}

.about-wrap.style1 .about-shape-one {
  bottom: 0;
  right: 50px;
}

.about-wrap.style1 .about-shape-two {
  bottom: 180px;
  right: 20%;
}

.about-wrap.style1 .about-shape-three {
  bottom: 0;
  right: 0;
}

.about-wrap.style1 .about-img-wrap {
  position: relative;
  /* background-image: url(../img/about/about-img-1.jpg); */
  height: 630px;
  border-radius: 10px;
}

.about-img-wrap img {
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.ct_about_main {
  position: relative;
}

.ct_about_main:before {
  content: "";
  position: absolute;
  right: -30px;
  top: 0;
  height: 50%;
  width: 15px;
  background: #86af48;
}

.ct_about_main:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -30px;
  height: 50%;
  width: 15px;
  background: #86af48;
}

.about-wrap.style1 .about-img-wrap .about-quote {
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: calc(100% - 60px);
  transform: translateX(-50%);
  background-color: #86af48;
  border-radius: 10px;
  padding: 30px;
}

.about-wrap.style1 .about-img-wrap .about-quote i {
  color: #86af48;
  position: absolute;
  top: 35px;
  left: 25px;
  font-size: 38px;
  line-height: 0.8;
}

.about-wrap.style1 .about-img-wrap .about-quote p {
  color: #fff;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  padding-left: 44px;
}

.about-wrap.style2 .about-shape-one {
  position: absolute;
  bottom: 60px;
  right: 50px;
  z-index: -1;
}

.about-wrap.style3 .about-img-wrap {
  position: relative;
  z-index: 1;
}

.about-wrap.style3 .about-img-wrap:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: "";
  width: calc(100% - 80px);
  height: 250px;
  background-color: #f5f5f5;
  border-radius: 5px;
  z-index: -1;
  transform: translateX(-50%) rotate(2deg);
}

.about-wrap.style3 .about-img-wrap .about-shape-one {
  position: absolute;
  bottom: -20px;
  right: 20px;
  z-index: 1;
}

.ct_ct_climate_crisis_bg {
  background-image: url(/static/media/about-us-1img.39b8ba98.jpg);
  padding: 60px 0px;
  background-size: 65% 100%;
  background-position: left;
  background-repeat: no-repeat;
}

.ct_climate_crisis_cnt {
  border-left: 4px solid #86af48;
}

.ct_ct_climate_crisis_bg_2 .ct_climate_crisis_cnt {
  border-left: 0px solid #86af48;
  border-right: 4px solid #86af48;
}

.theme-dark .ct_climate_crisis_cnt {
  background-color: #333;
}

.ct_ct_climate_crisis_bg_2 {
  background-image: url(/static/media/about-us-2img.f8a223f5.jpg);
  padding: 60px 0px;
  background-size: 65% 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.ct_climate_crisis_cnt {
  background-color: #ffff;
  padding: 60px 50px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}

/* energy section S */

.ct_energy_bg {
  position: relative;
}

.ct_energy_bg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  /* background-image: url('../../../assets/DashboardAssets/assets/shape_top_left.png'); */
  background-repeat: no-repeat;
  background-position: left;
  width: 250px;
  height: 250px;
  background-size: contain;
}

.ct_energy_bg:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-image: url(/static/media/white_bg_shape.284b4e6f.png);
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  background-position: bottom;
  background-size: contain;
  z-index: -1;
}

/*-------------------------------
    Donate  Section CSS
-------------------------------*/

.donate-box {
  background-color: #86af48;
  position: relative;
  z-index: 10;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  border-radius: 10px;
}

.donate-box .donate-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.donate-box .donate-amt {
  margin-bottom: 20px;
}

.donate-box .donate-amt li {
  margin-right: 20px;
  margin-bottom: 15px;
}

.donate-box .donate-amt li:last-child {
  margin-right: 0;
}

.donate-box .donate-amt li button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.9);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  padding: 18px 35px;
}

.donation-form {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 5px;
}

.donation-form h2 {
  font-size: 26px !important;
  margin: 0 0 10px !important;
}

.donation-form h4 {
  margin: 25px 0 25px;
}

.donation-form .radionbtn-wrap .radionbtn {
  margin-right: 25px;
  margin-bottom: 15px;
}

.donation-form .radionbtn-wrap .radionbtn:last-child {
  margin-right: 0;
}

.donation-form .form-group {
  margin-bottom: 25px;
}

.donation-form .form-group input,
.donation-form .form-group select {
  width: 100%;
  height: 58px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 12px 16px;
  color: #373435;
}

.donation-form .form-group input:-ms-input-placeholder,
.donation-form .form-group select:-ms-input-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input::-moz-placeholder, .donation-form .form-group select::-moz-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input:-ms-input-placeholder, .donation-form .form-group select:-ms-input-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input::placeholder,
.donation-form .form-group select::placeholder {
  opacity: 0.7;
}

.donation-form .form-group select {
  opacity: 0.7;
}

.donation-form .form-check {
  margin-bottom: 20px;
}

/*-------------------------------
    Counter  Section CSS
-------------------------------*/

.counter-wrap {
  position: relative;
  z-index: 1;
}

.counter-wrap .counter-shape-one,
.counter-wrap .counter-shape-two,
.counter-wrap .counter-shape-three {
  position: absolute;
  z-index: -1;
}

.counter-wrap.style1 {
  position: relative;
  background-color: #f5f5f5;
  margin-top: -200px;
  overflow: hidden;
}

.counter-wrap.style1 .counter-shape-one {
  bottom: 0;
  right: 0;
}

.counter-wrap.style1 .counter-shape-two {
  bottom: 0;
  right: 220px;
}

.counter-wrap.style1 .counter-shape-three {
  bottom: 0;
  right: -10px;
}

.promo-video {
  position: relative;
  z-index: 1;
}

.promo-video:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.promo-video .play-now {
  width: 90px;
  height: 90px;
  top: 50%;
  font-size: 34px;
  line-height: 88px;
  transform: translateY(-50%);
  background-color: #fff;
}

.promo-video .play-now i {
  color: #86af48;
  position: relative;
  left: -2px;
}

.promo-video .play-now .ripple:after,
.promo-video .play-now .ripple:before,
.promo-video .play-now .ripple {
  width: 90px;
  height: 90x;
}

.promo-video.style1 {
  background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
  height: 100%;
}

.promo-video.style2 {
  /* background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg-2.jpg); */
}

.promo-video.style2 .play-now {
  position: relative;
  top: auto;
  margin-top: 50px;
  transform: translateY(0%);
}

.promo-video.style3 {
  background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
  height: 100%;
}

.promo-video.style3 .play-now {
  position: relative;
  top: auto;
  left: auto;
  transform: translateY(0);
  margin: 30px 0 0;
}

.counter-card-wrap {
  margin: 0 -12.5px;
}

.counter-card-wrap.style1 {
  padding: 300px 90px 125px 60px;
}

.counter-card-wrap.style1 .counter-card {
  position: relative;
  margin-bottom: 25px;
  background-color: #fff;
  width: calc(50% - 25px);
  margin: 0 12.5px 25px;
  padding: 50px 20px;
  max-height: 185px;
  text-align: center;
}

.counter-card-wrap.style1 .counter-card:nth-child(2),
.counter-card-wrap.style1 .counter-card:nth-child(4) {
  top: 50px;
}

.counter-card-wrap.style1 .counter-card .counter-text h2 {
  font-size: 46px;
  line-height: 1;
  margin: 0 0 10px;
}

.counter-card-wrap.style1 .counter-card .counter-text p {
  margin-bottom: 0;
  line-height: 1;
}

.counter-card-wrap.style1 .counter-card.style1 .counter-text h2,
.counter-card-wrap.style1 .counter-card.style4 .counter-text h2 {
  color: #86af48;
}

.counter-card-wrap.style1 .counter-card.style2 .counter-text h2,
.counter-card-wrap.style1 .counter-card.style3 .counter-text h2 {
  color: #86af48;
}

.counter-card-wrap.style1 .counter-card.style1 {
  border-radius: 50px 50px 0 50px;
}

.counter-card-wrap.style1 .counter-card.style2 {
  border-radius: 50px 50px 50px 0;
}

.counter-card-wrap.style1 .counter-card.style3 {
  border-radius: 50px 0 50px 50px;
}

.counter-card-wrap.style1 .counter-card.style4 {
  border-radius: 0 50px 50px 50px;
}

.counter-card-wrap.style2 {
  margin: 0 -12.5px;
  padding: 0;
}

.counter-card-wrap.style2 .counter-card {
  padding: 40px 25px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.06);
  width: calc(25% - 25px);
  margin: 0 12.5px 25px;
  position: relative;
}

.counter-card-wrap.style2 .counter-card:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.counter-card-wrap.style2 .counter-card.style1 {
  border-radius: 50px 50px 0 50px;
}

.counter-card-wrap.style2 .counter-card.style1:after {
  bottom: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style2 {
  border-radius: 50px 50px 50px 0;
}

.counter-card-wrap.style2 .counter-card.style2:after {
  top: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style3 {
  border-radius: 50px 0 50px 50px;
}

.counter-card-wrap.style2 .counter-card.style3:after {
  bottom: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style4 {
  border-radius: 0 50px 50px 50px;
}

.counter-card-wrap.style2 .counter-card.style4:after {
  top: 50%;
  right: -5px;
}

.counter-card-wrap.style2 .counter-card .counter-icon {
  width: 70px;
}

.counter-card-wrap.style2 .counter-card .counter-icon i {
  font-size: 68px;
  line-height: 0.8;
  color: #86af48;
}

.counter-card-wrap.style2 .counter-card .counter-text {
  width: calc(100% - 90px);
  margin-left: 20px;
}

.counter-card-wrap.style2 .counter-card .counter-text h2 {
  color: #fff;
  font-size: 40px;
  margin: 0 0 5px;
}

.counter-card-wrap.style2 .counter-card .counter-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.counter-card-wrap.style3 {
  padding-bottom: 25px;
}

.counter-card-wrap.style3 .counter-card {
  position: relative;
  background-color: #fff;
  width: calc(50% - 25px);
  margin: 0 12.5px 25px;
  padding: 50px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.counter-card-wrap.style3 .counter-card:nth-child(2),
.counter-card-wrap.style3 .counter-card:nth-child(4) {
  top: 50px;
}

.counter-card-wrap.style3 .counter-card .counter-icon {
  width: 80px;
}

.counter-card-wrap.style3 .counter-card .counter-icon i {
  font-size: 78px;
  line-height: 0.8;
  color: #86af48;
}

.counter-card-wrap.style3 .counter-card .counter-text {
  width: calc(100% - 100px);
  margin-left: 20px;
}

.counter-card-wrap.style3 .counter-card .counter-text h2 {
  font-size: 46px;
  line-height: 1;
  margin: 0 0 10px;
  color: #fff;
}

.counter-card-wrap.style3 .counter-card .counter-text p {
  margin-bottom: 0;
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
}

.counter-card-wrap.style3 .counter-card:nth-child(1),
.counter-card-wrap.style3 .counter-card:nth-child(4) {
  background-color: #86af48;
  border-color: transparent;
}

.counter-card-wrap.style3 .counter-card:nth-child(1) i.flaticon-coin,
.counter-card-wrap.style3 .counter-card:nth-child(4) .flaticon-volunteer {
  color: #fff;
}

.counter-card-wrap.style3 .counter-card:nth-child(2),
.counter-card-wrap.style3 .counter-card:nth-child(3) {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  padding: 0 1px;
}

/*-------------------------------
    Event  Section CSS
-------------------------------*/

.event-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.event-wrap .event-sape-one {
  position: absolute;
  bottom: -10px;
  left: 20px;
  z-index: -1;
  opacity: 0.5;
}

.event-wrap .event-sape-two {
  position: absolute;
  top: 70px;
  left: 120px;
  z-index: -1;
}

.event-wrap .event-sape-three {
  position: absolute;
  bottom: -10px;
  right: 30px;
  z-index: -1;
  opacity: 0.5;
}

.event-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 25px;
  transition: 0.4s;
}

.event-card ul.event-metainfo.list-style li {
  color: #86af48;
}

.event-card:hover ul.event-metainfo.list-style li {
  color: #fff;
}

.event-card:hover .btn {
  background-color: #fff;
  color: #333;
}

.event-card .btn:hover {
  color: #fff;
}

.theme-dark .event-card .btn:hover {
  color: #fff !important;
}

.theme-dark .event-card .btn {
  z-index: 1;
}

.theme-dark .event-card:hover .btn {
  color: #333 !important;
}

.theme-dark .event-card .btn:hover {
  color: #fff !important;
}

.theme-dark .event-info a:hover {
  color: #fff !important;
}

.theme-dark .event-info:hover .btn {
  color: #333;
}

.event-card .event-img {
  position: relative;
  border-radius: 10px 0 0 10px;
}

.event-card .event-img img {
  border-radius: 10px 0 0 10px;
}

.event-card .event-img .event-date {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #86af48;
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 15px;
  padding: 7px 15px;
}

.event-card .event-info {
  transition: 0.4s;
}

.event-card .event-info h3 {
  font-size: 22px;
  margin: 0 0 15px;
  transition: 0.4s;
}

.event-card .event-info p {
  transition: 0.4s;
}

.event-card .event-info .event-metainfo {
  margin-bottom: 10px;
}

.event-card .event-info .event-metainfo li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
  color: #666666;
  font-size: 15px;
  transition: 0.4s;
}

.event-card .event-info .event-metainfo li a {
  color: #666666;
  transition: 0.4s;
}

.event-card .event-info .event-metainfo li:last-child {
  margin-right: 0;
}

.event-card .event-info .event-metainfo li i {
  color: #86af48;
  position: relative;
  top: 1px;
  margin-right: 5px;
  transition: 0.4s;
}

.event-card .event-info p {
  margin: 0;
}

.event-card:hover {
  background-color: #86af48;
  border-color: transparent;
}

.event-card:hover .event-info h3 a {
  color: #fff;
}

.event-card:hover .event-info p {
  color: rgba(255, 255, 255, 0.8);
}

.event-card:hover .event-info .event-metainfo li i {
  color: #fff;
}

.event-card:hover .event-info .event-metainfo li a,
.event-card:hover .event-info .event-metainfo li p {
  color: rgba(255, 255, 255, 0.8);
}

.event-card.style1 .event-img {
  width: 250px;
}

.event-card.style1 .event-info {
  width: calc(100% - 270px);
  padding: 20px 20px 20px 25px;
}

.event-card.style2,
.event-card.style3 {
  background-color: #fff;
}

.event-card.style2:hover,
.event-card.style3:hover {
  background-color: #86af48;
  border-color: transparent;
}

.event-card.style2 .event-info,
.event-card.style3 .event-info {
  margin-left: 20px;
  padding: 20px 20px 20px 5px;
}

.event-card.style2 .event-info .event-metainfo li a,
.event-card.style2 .event-info .event-metainfo li p,
.event-card.style3 .event-info .event-metainfo li a,
.event-card.style3 .event-info .event-metainfo li p {
  font-size: 14px;
}

.event-card.style2 .event-img {
  width: 210px;
}

.event-card.style2 .event-info {
  width: calc(100% - 230px);
}

.event-card.style3 .event-img {
  width: 185px;
}

.event-card.style3 .event-info {
  width: calc(100% - 205px);
}

.event-card.style4 .event-img {
  border-radius: 5px 5px 0 0;
}

.event-card.style4 .event-img img {
  border-radius: 5px 5px 0 0;
}

.event-card.style4 .event-info {
  padding: 24px 25px;
}

.event-card.style5 {
  background-color: #f5f5f5;
  border: none;
}

.event-card.style5 .event-img {
  border-radius: 5px 5px 0 0;
}

.event-card.style5 .event-img img {
  border-radius: 5px 5px 0 0;
}

.event-card.style5 .event-info {
  padding: 24px 25px;
}

.event-card.style5 .event-info p {
  margin-bottom: 15px;
}

.event-card.style5:hover {
  background-color: #86af48;
}

.event-card.style5:hover .link {
  color: #fff;
}

.newsletter-form.style1 {
  margin-bottom: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 50px 30px 58px;
  border-radius: 10px;
  background-color: #fff;
}

.newsletter-form.style1 h4 {
  font-size: 22px;
  margin: 0 0 10x;
  text-align: center;
}

.newsletter-form.style1 p {
  margin: 0 0 20px;
  text-align: center;
}

.newsletter-form.style1 .form-group {
  margin-bottom: 20px;
}

.newsletter-form.style1 .form-group input {
  width: 100%;
  background-color: #f9f9f9;
  height: 54px;
  padding: 12px 18px;
  border: none;
}

.newsletter-form.style1 button {
  width: 100%;
  display: block;
}

.event-search-form {
  background-color: #f5f5f5;
  padding: 25px 25px 5px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.event-search-form .form-group {
  margin: 0 0 20px;
}

.event-search-form .form-group label {
  font-weight: 600;
  color: #373435;
  margin: 0 0 10px;
  display: block;
}

.event-search-form .form-group select,
.event-search-form .form-group input {
  width: 100%;
  height: 58px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 18px;
  color: #373435;
  border: none;
}

.event-search-form .form-group select:-ms-input-placeholder,
.event-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select::-moz-placeholder, .event-search-form .form-group input::-moz-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select:-ms-input-placeholder, .event-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select::placeholder,
.event-search-form .form-group input::placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select select,
.event-search-form .form-group input select {
  opacity: 0.7;
}

.event-search-form .form-group button {
  margin-top: 35px;
}

/*-------------------------------------
    Project & Event Details  CSS
----------------------------------------*/

.event-desc .single-project-img,
.event-desc .single-event-img,
.project-desc .single-project-img,
.project-desc .single-event-img {
  border-radius: 10px;
  margin: 10px 0 30px;
  display: block;
}

.event-desc .single-project-img img,
.event-desc .single-event-img img,
.project-desc .single-project-img img,
.project-desc .single-event-img img {
  border-radius: 10px;
}

.event-desc .progressbar-wrap,
.project-desc .progressbar-wrap {
  margin-top: -10px;
}

.project-desc .fund-collection li span {
  font-size: 20px;
  font-weight: 600;
  margin-right: 4px;
  color: #373435;
}

.project-desc .wp-block-quote {
  box-shadow: none;
  background-color: #f5f5f5;
  padding: 30px;
  border-left: 4px solid #86af48;
}

.project-desc .wp-block-quote p {
  text-align: left;
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  margin: 0;
}

/*-------------------------------
    Team  Section CSS
-------------------------------*/

.team-wrap {
  position: relative;
  z-index: 1;
}

.team-wrap .team-shape-one {
  position: absolute;
  bottom: 0;
  left: 30px;
  z-index: -1;
}

.team-card {
  margin-bottom: 25px;
  overflow: hidden;
}

.team-card h3 {
  font-size: 22px;
}

.team-card.style1 {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.team-card.style1 img {
  border-radius: 5px 5px 0 0;
}

.team-card.style1 .team-info {
  background-color: #fff;
  text-align: center;
  padding: 25px;
  position: absolute;
  z-index: 1;
  bottom: -60px;
  left: 0;
  width: 100%;
  transition: 0.4s;

}

.team-card.style1 .team-info .team-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.team-card.style1 .team-info h3 {
  margin: 0 0 7px;
}

.team-card.style1 .team-info span {
  font-size: 14px;
  color: #666666;
}

.team-card.style1 .team-info .social-profile {
  margin-top: 20px;
}

.team-card.style1 .team-info .social-profile li a {
  background-color: #86af48;
}

.team-card.style1 .team-info .social-profile li a i {
  color: #fff;
}

.team-card.style1:hover .team-info {
  bottom: 0;
}

.team-card.style2 {
  border-radius: 5px;
}

.team-card.style2 img {
  border-radius: 5px 5px 0 0;
}

.btn-close {
  background-image: unset !important;
}

.team-card.style2 .team-info-wrap {
  background-color: #86af48;
  padding: 25px;
  transition: 0.4s;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 1;
}

.team-card.style2 .team-info-wrap .team-card-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100px;
  z-index: -1;
}

.team-card.style2 .team-info-wrap .team-info {
  width: calc(100% - 60px);
  margin-right: 8px;
}

.team-card.style2 .team-info-wrap .team-info h3 {
  margin: 0 0 6px;
}

.team-card.style2 .team-info-wrap .team-info h3 a {
  color: #fff;
}

.team-card.style2 .team-info-wrap .team-info span {
  color: rgba(255, 255, 255, 0.8);
}

.team-card.style2 .team-info-wrap .social-link {
  cursor: pointer;
  position: relative;
}

.team-card.style2 .team-info-wrap .social-link span {
  display: inline-block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
}

.team-card.style2 .team-info-wrap .social-link span i {
  color: #86af48;
  font-size: 22px;
  line-height: 54px;
  margin: 0 auto;
}

.team-card.style2 .team-info-wrap .social-link .social-profile {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 0;
  transition: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li {
  visibility: hidden;
  opacity: 0;
  display: block;
  margin: 10px 0;
  transform: translateX(-10px);
  transition: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li a {
  width: 52px;
  height: 52px;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li a i {
  font-size: 20px;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:last-child {
  margin: 0;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(4) {
  transition-delay: 0.2s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(3) {
  transition-delay: 0.3s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(2) {
  transition-delay: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(1) {
  transition-delay: 0.5s;
}

.team-card.style2 .team-info-wrap .social-link:hover .social-profile li {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.team-card.style2:hover .team-info-wrap {
  background-color: #86af48;
}

.team-card.style3 {
  position: relative;
  background-color: #f5f5f5;
  transition: 0.4s;
}

.team-card.style3 img {
  border-radius: 5px;
  transition: 0.4s;
}

.team-card.style3 .team-info {
  background-color: #f5f5f5;
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: -75px;
  padding-top: 25px;
  padding-bottom: 85px;
  width: calc(100% + 5px);
  transition: 0.4s;
}

.team-card.style3:hover .team-info {
  padding-bottom: 25px !important;
}

.team-card.style3 .team-info h3 {
  margin: 0 0 7px;
}

.team-card.style3 .team-info span {
  font-size: 14px;
  color: #666666;
}

.team-card.style3 .team-info .social-profile {
  margin-top: 20px;
}

.team-card.style3 .team-info .social-profile li a {
  background-color: #86af48;
}

.team-card.style3 .team-info .social-profile li a i {
  color: #fff;
}

.team-card.style3:hover {
  padding: 25px;
  background-color: #fff;
}

.team-card.style3:hover img {
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info h3 {
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info span {
  transform: rotate(0deg);
}

.team-slider-one .team-card,
.team-slider-two .team-card {
  margin: 0;
}

.team-slider-one .team-card.style3,
.team-slider-two .team-card.style3 {
  margin: 10px 10px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 img {
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info {
  transform: rotate(2deg);
  bottom: -75px;
  left: -7px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info h3 {
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info span {
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover {
  padding: 20px;
  background-color: #fff;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover img {
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info h3 {
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info span {
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 img {
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info {
  transform: rotate(-2deg);
  bottom: -75px;
  right: -7px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info h3 {
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info span {
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover {
  padding: 20px;
  background-color: #fff;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover img {
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info h3 {
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info span {
  transform: rotate(0deg);
}

/*-------------------------------
    Team Details  Section CSS
-------------------------------*/
.ct_director_img img {
  width: 100%;
  height: 550px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  transition: 0.4s ease all;
}

.ct_ddirector_info {
  padding: 30px;
}

.ct_director_img_box {
  background: #fff;
  box-shadow: 0 0 25px #00000017;
  padding: 30px 30px 50px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}

.ct_white_bg {
  background-color: #f5f5f5;
  /* box-shadow: 0px 0px 25px rgba(0,0,0,0.09); */
  margin-bottom: 30px;
  padding: 15px;
}

.ct_director_img {
  border: 2px solid #86af48;
  border-radius: 10px;
}

.team-desc p:last-child {
  margin-bottom: 0;
}

.team-desc .progressbar-wrap {
  margin-bottom: 25px;
}

.team-desc .progressbar-wrap .progress-bar {
  margin-bottom: 20px;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder {
  margin-bottom: 20px;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-title {
  position: relative;
  top: 5px;
  font-size: 18px;
  font-weight: 500;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
  bottom: -7px;
  border-color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark .percent {
  color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark:after {
  border-top-color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-content-outter {
  border-radius: 0;
}

.team-desc .progressbar-wrap .progress-bar .progress-content-outter .progress-content {
  border-radius: 0;
}

.ct_director_img {
  position: relative;
  overflow: hidden;
}

.ct_ddirector_info h2 {
  color: #86af48;
}

.ct_director_img::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.ct_director_img:hover::before {
  animation: shine 0.75s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.progess-bar-item {
  margin: 25px 0 0;
}

.progess-bar-item .single-skill {
  margin-bottom: 25px;
  text-align: center;
}

.progess-bar-item .single-skill p {
  margin: 15px auto 0;
}

.progess-bar-item .single-skill .circlechart {
  text-align: center;
}

.progressbar {
  display: inline-block;
  width: 100px;
  margin: 25px;
}

.progressbar .circle {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  text-align: center;
}

.progressbar .circle canvas {
  vertical-align: middle;
}

.progressbar .circle div {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}

.progressbar .circle strong i {
  font-style: normal;
  font-size: 0.6em;
  font-weight: normal;
}

.progressbar .circle strong span {
  display: block;
  color: #aaa;
  margin-top: 12px;
}

.circle-chart {
  width: 150px;
  height: 150px;
}

.circle-chart__circle {
  stroke: #00acc1;
  stroke-width: 1;
  stroke-linecap: square;
  fill: none;
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform: rotate(-90deg);
  /* 2, 3 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__subline {
  display: none;
}

.single-skill .circle-chart__circle {
  stroke: #86af48;
  stroke-width: 2;
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1, -1);
}

.circle-chart__background {
  stroke: #f5f5f5;
  stroke-width: 2;
  fill: none;
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(20px);
}

.circle-chart__percent {
  text-anchor: middle;
  font-size: 6px;
  color: #86af48;
  font-family: "Inter", sans-serif;
}

.circle-chart__subline {
  text-anchor: middle;
  font-size: 3px;
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*-------------------------------
    CTA  Section CSS
-------------------------------*/

.cta-wrap {
  overflow: hidden;
}

.cta-wrap.style1,
.cta-wrap.style2 {
  position: relative;
  background-image: url(/static/media/cta-bg-1.734645dd.jpg);
  z-index: 1;
}

.cta-wrap.style1:after,
.cta-wrap.style2:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.95) 45%,
      rgba(255, 255, 255, 0.3) 94%);
}

.cta-wrap.style1 .content-title,
.cta-wrap.style2 .content-title {
  position: relative;
  z-index: 1;
}

.cta-wrap.style1 .cta-btn,
.cta-wrap.style2 .cta-btn {
  text-align: center;
}

.cta-wrap.style1 .cta-btn .btn:nth-child(1),
.cta-wrap.style2 .cta-btn .btn:nth-child(1) {
  margin-right: 15px;
}

.cta-wrap.style1 .cta-shape-one,
.cta-wrap.style2 .cta-shape-one {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 0;
}

.cta-wrap.style1 {
  position: relative;
  z-index: 1;
}

.cta-wrap.style1 .cta-shape-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*-------------------------------
    Blog  Section CSS
-------------------------------*/

.blog-wrap .container {
  position: relative;
  z-index: 1;
}

.blog-wrap .container .blog-section-shape {
  position: absolute;
  bottom: -20px;
  left: -100px;
  z-index: -1;
}

.blog-card {
  border-radius: 5px;
  margin-bottom: 25px;
  transition: 0.4s;
}

.blog-card .blog-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-card .blog-info .blog-author {
  margin: 0 0 20px;
}

.blog-card .blog-info .blog-author .blog-author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.blog-card .blog-info .blog-author .blog-author-img img {
  border-radius: 50%;
}

.blog-card .blog-info .blog-author .blog-author-info {
  width: calc(100% - 65px);
  margin-left: 15px;
}

.blog-card .blog-info .blog-author .blog-author-info h6 {
  font-size: 16px;
  margin: 5px 0 0;
  transition: 0.4s;
}

.blog-card .blog-info .blog-author .blog-author-info span {
  font-size: 15px;
  transition: 0.4s;
}

.blog-card .blog-info h3 {
  font-size: 22px;
  font-weight: 600;
  transition: 0.4s;
}

.blog-card .blog-info p {
  transition: 0.4s;
}

.blog-card .blog-info .blog-metainfo li {
  display: inline-block;
  margin-right: 15px;
  color: #666666;
}

.blog-card .blog-info .blog-metainfo li:last-child {
  margin-right: 0;
}

.blog-card .blog-info .blog-metainfo li a {
  font-size: 15px;
  position: relative;
  padding-left: 22px;
  color: #666666;
  transition: 0.4s;
}

.blog-card .blog-info .blog-metainfo li a i {
  color: #86af48;
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 16px;
  transition: 0.4s;
}

.blog-card .blog-info .link {
  transition: 0.4s;
}

.blog-card .blog-info .link:after {
  display: none;
}

.blog-card.style1 {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.blog-card.style1 .blog-info {
  padding: 25px;
}

.blog-card.style1 .blog-info .blog-author {
  margin: 0 0 20px;
}

.blog-card.style1 .blog-info h3 {
  margin-bottom: 15px;
}

.blog-card.style1 .blog-info p {
  margin-bottom: 15px;
}

.blog-card.style1 .link {
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 18px 15px;
  border-radius: 0 0 5px 5px;
}

.blog-card.style1:hover {
  background-color: #86af48;
}

.blog-card.style1:hover .blog-info .blog-author .blog-author-info h6 a {
  color: #fff;
}

.blog-card.style1:hover .blog-info .blog-author .blog-author-info span {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info h3 a {
  color: #fff;
}

.blog-card.style1:hover .blog-info p {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info .blog-metainfo li {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info .blog-metainfo li i {
  color: #fff;
}

.blog-card.style1:hover .blog-info .blog-metainfo li a {
  color: #fff;
}

.blog-card.style1:hover .link {
  background-color: #86af48;
  color: #fff;
}

.blog-card.style1:hover .link:after {
  display: none;
}

.blog-card.style2 {
  background-color: #fff;
}

.blog-card.style2 .blog-info {
  padding: 25px;
}

.blog-card.style2 .blog-info .blog-metainfo {
  margin-bottom: 0;
}

.blog-card.style2 .blog-info h3 {
  margin-bottom: 15px;
}

.blog-card.style2 .link {
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 18px 15px;
  border-radius: 0 0 5px 5px;
}

.blog-card.style2:hover .link {
  background-color: #86af48;
  color: #fff;
}

.blog-card.style3 {
  background-color: #fff;
  padding: 25px;
}

.blog-card.style3 .blog-img {
  position: relative;
}

.blog-card.style3 .blog-img .blog-date {
  position: absolute;
  top: -25px;
  left: -25px;
  background-color: #86af48;
  padding: 15px 18px;
  border-radius: 5px 0 5px 0;
  color: #fff;
  text-align: center;
}

.blog-card.style3 .blog-img .blog-date span {
  display: block;
  font-weight: 600;
}

.blog-card.style3 .blog-info {
  border-bottom: 0;
  padding: 25px 0 0;
}

.blog-card.style3 .blog-info .blog-metainfo {
  margin-bottom: 0;
}

.blog-card.style3 .link {
  display: inline-block;
  text-align: left;
  padding: 0;
}

.blog-card.style3 .link:after {
  display: none;
}

.blog-slider-one .blog-card {
  margin: 0;
}

/*-------------------------------
         Contact CSS
-------------------------------*/

.contact-item-wrap {
  background-color: #f5f5f5;
  padding: 25px 25px 30px;
  border-radius: 5px;
}

.contact-item-wrap .contact-item {
  transition: 0.4s;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.contact-item-wrap .contact-item:last-child {
  margin-bottom: 0;
}

.contact-item-wrap .contact-item h3 {
  font-size: 22px;
  margin: 0 0 12px;
}

.contact-item-wrap .contact-item p,
.contact-item-wrap .contact-item a {
  display: block;
  color: #666666;
  margin-bottom: 0;
  transition: 0.3s;
}

.contact-item-wrap .contact-item p:hover,
.contact-item-wrap .contact-item a:hover {
  color: #86af48;
}

#contactForm {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 25px 20px;
  border-radius: 5px;
}

#contactForm .form-group {
  margin: 0 0 20px;
}

#contactForm .form-group input,
#contactForm .form-group textarea {
  width: 100%;
  background: #f5f5f5;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}

#contactForm .form-group input:focus,
#contactForm .form-group textarea:focus {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

#contactForm .form-group input {
  height: 60px;
}

#contactForm .form-group textarea {
  height: 150px;
  resize: none;
  padding-top: 18px;
}

.btn.disabled {
  opacity: 1;
}

#msgSubmit {
  font-size: 16px;
  margin-top: 10px;
}

.checkbox {
  display: block;
}

.checkbox.form-check {
  padding-left: 0;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
}

.checkbox label:before {
  content: "";
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 1px solid #86af48;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 4px;
}

.checkbox a {
  color: #86af48;
}

.checkbox.style2 label:before {
  border-radius: 50%;
}

.checkbox input:checked+label:before {
  border-color: #86af48;
}

.checkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 3px;
  width: 9px;
  height: 9px;
  border: none;
  background: #86af48;
}

.checkbox.style2 input:checked+label:after {
  border-radius: 50%;
}

/* 
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #86af48;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */

/*-------------------------------
   FAQ & Error Section  CSS
-------------------------------*/

.faq-wrap {
  position: relative;
}

.faq-wrap .accordion {
  margin-bottom: 25px;
}

.accordion-item {
  border: none;
  margin: 0 0 25px;
  background: transparent;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-item .accordion-header {
  margin-bottom: 0;
  position: relative;
  z-index: 3;
}

.accordion-item .accordion-header .faq-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.accordion-item .accordion-header .accordion-button {
  border-radius: 5px 5px 0 0;
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 5px;
  color: #373435;
  text-align: left;
  align-items: center;
  border-bottom: none;
  padding: 20px 45px 22px 20px;
  transition: 0.4s;
  background-color: #f5f5f5;
  box-shadow: none;
}

.accordion-item .accordion-header .accordion-button.collapsed {
  color: #373435;
}

.accordion-item .accordion-header .accordion-button.collapsed span {
  background-color: #86af48 !important;
}

.accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-item .accordion-header .accordion-button.collapsed {
  color: #373435;
  background-color: #f5f5f5;
}

.accordion-item .accordion-header .accordion-button.collapsed span {
  background-color: transparent !important;
}

.accordion-item .accordion-header .accordion-button.collapsed span i {
  color: #666666;
}

.accordion-item .accordion-header .accordion-button span {
  display: inline-block;
  min-width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 20px;
  text-align: center;
}

.accordion-item .accordion-header .accordion-button span i {
  position: absolute;
  top: 28px;
  right: 20px;
  font-size: 24px;
  line-height: 0.8;
  color: #fff;
  display: block;
  margin: 0 auto;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-body {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 22px 20px 22px;
}

.accordion-item .accordion-body p {
  margin: 0 0 10px;
}

.accordion-item .accordion-body p:last-child {
  margin: 0;
}

.accordion-collapse {
  border: none;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: #86af48 !important;
  border-color: transparent;
  color: #fff;
}

.accordion-button:not(.collapsed) span i.plus {
  visibility: hidden;
  opacity: 0;
}

.accordion-button:not(.collapsed) span i.minus {
  visibility: visible !important;
  opacity: 1 !important;
}

.error-wrap {
  margin-top: 84px;
}

.error-content {
  text-align: center;
}

.error-content img {
  display: block;
  margin: 0 auto 30px;
  max-width: 600px;
}

.error-content p {
  margin: 0 auto 20px;
  max-width: 520px;
}

/*-------------------------------
    Blog Details CSS
--------------------------------*/

article p,
.project-desc p,
.event-desc p,
.team-desc p,
.terms-wrap p {
  margin-bottom: 20px;
}

article p:last-child,
.project-desc p:last-child,
.event-desc p:last-child,
.team-desc p:last-child,
.terms-wrap p:last-child {
  margin-bottom: 0;
}

article p strong,
.project-desc p strong,
.event-desc p strong,
.team-desc p strong,
.terms-wrap p strong {
  color: #373435;
}

article p a,
.project-desc p a,
.event-desc p a,
.team-desc p a,
.terms-wrap p a {
  color: #86af48;
}

article p a:hover,
.project-desc p a:hover,
.event-desc p a:hover,
.team-desc p a:hover,
.terms-wrap p a:hover {
  text-decoration: underline;
  color: #86af48;
}

article h1,
.project-desc h1,
.event-desc h1,
.team-desc h1,
.terms-wrap h1 {
  font-size: 32px;
  margin-bottom: 15px;
}

article h2,
.project-desc h2,
.event-desc h2,
.team-desc h2,
.terms-wrap h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

article h3,
.project-desc h3,
.event-desc h3,
.team-desc h3,
.terms-wrap h3 {
  font-size: 28px;
  margin-bottom: 15px;
}

article h4,
.project-desc h4,
.event-desc h4,
.team-desc h4,
.terms-wrap h4 {
  font-size: 26px;
  margin-bottom: 15px;
}

article h5,
.project-desc h5,
.event-desc h5,
.team-desc h5,
.terms-wrap h5 {
  font-size: 24px;
  margin-bottom: 15px;
}

article h6,
.project-desc h6,
.event-desc h6,
.team-desc h6,
.terms-wrap h6 {
  font-size: 22px;
  margin-bottom: 15px;
}

article ol,
.project-desc ol,
.event-desc ol,
.team-desc ol,
.terms-wrap ol {
  margin-top: 20px;
  margin-bottom: 25px;
}

article ol li,
.project-desc ol li,
.event-desc ol li,
.team-desc ol li,
.terms-wrap ol li {
  margin-bottom: 15px;
  color: #666666;
  padding-left: 3px;
}

article .content-feature-list,
.project-desc .content-feature-list,
.event-desc .content-feature-list,
.team-desc .content-feature-list,
.terms-wrap .content-feature-list {
  margin: 25px 0 25px;
}

article {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.post-metainfo {
  margin-bottom: 13px;
}

.post-metainfo li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  transition: 0.3s;
}

.post-metainfo li:after {
  position: absolute;
  top: -1px;
  right: -15px;
  content: "/";
  font-size: 16px;
  color: #666666;
}

.post-metainfo li:last-child {
  margin-right: 0;
}

.post-metainfo li:last-child:after {
  display: none;
}

.post-metainfo li a {
  font-size: 13px;
  color: #666666;
  transition: 0.3s;
}

.post-metainfo li a:hover {
  color: #86af48;
}

.post-metainfo li i {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  transition: 0.3s;
}

.post-img {
  position: relative;
  margin: 0 0 25px;
  display: block;
  border-radius: 10px;
}

.post-img img {
  border-radius: 10px;
}

.wp-block-quote {
  position: relative;
  padding: 35px 35px 35px;
  background-color: #f5f5f5;
  border-left: 5px solid #86af48;
  margin: 30px 0 30px;
  z-index: 1;
}

.wp-block-quote p {
  text-align: center;
  margin: 0;
}

.post-meta-option {
  margin-bottom: 25px;
}

.post-tag ul {
  display: inline-block;
}

.post-tag ul li {
  display: inline-block;
}

.post-tag ul li a {
  color: #555;
  font-size: 16px;
  border-radius: 3px;
}

.post-tag ul li a:hover {
  color: #373435;
}

.post-tag span {
  margin-right: 10px;
  font-weight: 500;
  color: #373435;
}

.post-tag span i {
  position: relative;
  top: 3px;
  color: #86af48;
  margin-right: 3px;
}

.post-share {
  display: inline-block;
}

.post-share span {
  font-weight: 500;
  color: #373435;
}

.post-share ul {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.post-share ul li a {
  border: 1px solid #86af48;
  box-shadow: none;
}

.post-share ul li a:hover {
  border-color: transparent;
}

.post-author {
  padding: 25px;
  margin: 15px 0 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.post-author .post-author-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.post-author .post-author-img img {
  border-radius: 50%;
}

.post-author .post-author-info {
  width: calc(100% - 175px);
  margin-left: 25px;
}

.post-author .post-author-info h4 {
  font-size: 16px;
  color: #373435;
  margin: 0 0 15px;
  font-weight: 400;
}

.post-author .post-author-info h4 a {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
  color: #86af48;
}

.post-author .post-author-info p {
  margin: 0 0 15px;
}

.comment-item-wrap .comment-item {
  padding: 0 0 25px 0;
  margin-bottom: 25px;
  border-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.comment-item-wrap .comment-item.reply {
  margin-left: 40px;
}

.comment-item-wrap .comment-item:last-child {
  margin-bottom: 0;
}

.comment-item-wrap .comment-item .comment-author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  top: 4px;
}

.comment-item-wrap .comment-item .comment-author-img img {
  border-radius: 50%;
}

.comment-item-wrap .comment-item .comment-author-wrap {
  width: calc(100% - 120px);
  margin-left: 20px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name {
  margin: 0 0 9px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name h5 {
  line-height: 1;
  margin: 0 20px 0 0;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name .comment-date {
  display: inline-block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #666666;
  margin-left: 10px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-text {
  margin: 0 0 10px;
}

.comment-item-wrap .reply-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  color: #86af48;
  font-size: 14px;
  transition: 0.3s;
}

.comment-item-wrap .reply-btn:hover {
  color: #86af48;
}

.comment-box-title {
  margin-bottom: 30px;
}

.comment-box-title h4 {
  margin-bottom: 0;
  line-height: 1;
}

.comment-box-title p {
  margin-top: 10px;
  margin-bottom: 0;
}

#cmt-form {
  margin-top: 35px;
}

#cmt-form .comment-form .form-group {
  margin: 0 0 20px;
  position: relative;
}

#cmt-form .comment-form .form-group>label {
  font-weight: 500;
  display: block;
  margin: 0 0 5px;
  padding-left: 0;
}

#cmt-form .comment-form .form-group input,
#cmt-form .comment-form .form-group textarea {
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  padding: 15px;
  color: #373435;
}

#cmt-form .comment-form .form-group input:focus,
#cmt-form .comment-form .form-group textarea:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#cmt-form .comment-form .form-group input {
  height: 60px;
}

#cmt-form .comment-form .form-group textarea {
  height: 145px;
  resize: none;
}

#cmt-form .comment-form .form-check {
  margin-bottom: 25px;
}

.post-cat-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #86af48;
}

.post-cat-title {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #86af48;
}

/*-------------------------------
    Event  Section CSS
-------------------------------*/

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: flex;
  flex-wrap: wrap;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

/*---------------------------------
        Footer CSS 
-----------------------------------*/

.footer-wrap {
  position: relative;
  background-color: #86af48;
}

.footer-wrap .footer-top {
  background-color: #181818;
  position: relative;
  z-index: 1;
}

.footer-wrap .footer-top .footer-shape-one,
.footer-wrap .footer-top .footer-shape-two,
.footer-wrap .footer-top .footer-shape-three,
.footer-wrap .footer-top .footer-shape-four {
  position: absolute;
  z-index: -1;
}

.footer-wrap .footer-top .footer-shape-one {
  bottom: 0;
  left: 15px;
}

.footer-wrap .footer-top .footer-shape-two {
  top: 20px;
  right: 45px;
}

.footer-wrap .footer-top .footer-shape-three {
  top: 120px;
  right: 0;
}

.footer-wrap .footer-top .footer-shape-four {
  top: 60px;
  right: 0;
}

.footer-wrap .footer-bottom {
  background-color: #373435;
}

.footer-wrap .footer-widget {
  margin-bottom: 25px;
}

.footer-wrap .footer-widget .footer-widget-title {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 36px;
  padding-bottom: 20px;
}

.footer-wrap .footer-widget .footer-widget-title:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  content: "";
  width: 100px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .footer-widget-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 35px;
  height: 2px;
  background-color: #86af48;
}

.footer-wrap .footer-widget .footer-logo {
  display: block;
}

.footer-wrap .footer-widget .comp-desc {
  color: rgba(255, 255, 255, 0.8);
  margin: 25px 0 30px 0;
  padding-bottom: 25px;
  position: relative;
}

.footer-wrap .footer-widget .comp-desc:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .comp-desc:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 80px;
  height: 2px;
  background-color: #86af48;
}

.footer-wrap .footer-widget .newsletter-form {
  position: relative;
}

.footer-wrap .footer-widget .newsletter-form input {
  width: 100%;
  height: 60px;
  border-radius: 10px 0 10px 0;
  padding: 13px 105px 12px 18px;
  color: #fff;
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .newsletter-form button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #86af48;
  color: #fff;
  border: none;
  height: 100%;
  border-radius: 0 0 10px 0;
  padding: 12px 20px;
}

.footer-wrap .footer-widget .footer-menu li {
  margin-bottom: 20px;
}

.footer-wrap .footer-widget .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-wrap .footer-widget .footer-menu li a {
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  padding-left: 25px;
}

.footer-wrap .footer-widget .footer-menu li a:after {
  position: absolute;
  top: 8px;
  left: 0;
  content: "";
  width: 16px;
  height: 1px;
  background-color: #86af48;
  transition: 0.4s;
}

.footer-wrap .footer-widget .footer-menu li a:hover {
  color: #86af48;
  padding-left: 28px;
}

.footer-wrap .footer-widget .contact-info li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}

.footer-wrap .footer-widget .contact-info li:last-child {
  margin-bottom: 0;
}

.footer-wrap .footer-widget .contact-info li h6 {
  color: #fff;
  font-weight: 500;
  margin: 0 0 5px;
}

.footer-wrap .footer-widget .contact-info li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 16px;
  line-height: 1;
  color: #86af48;
}

.ct_leave_reply_main {
  padding-inline: 0px !important;
}

.footer-wrap .footer-widget .contact-info li p,
.footer-wrap .footer-widget .contact-info li a {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  transition: 0.3s;
}

.footer-wrap .footer-widget .contact-info li:hover p,
.footer-wrap .footer-widget .contact-info li:hover a {
  color: #86af48;
}

.footer-bottom {
  padding: 18px 10px;
}

.footer-bottom .copyright-text {
  margin: 0;
  color: #fff;
}

.footer-bottom .copyright-text i {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.footer-bottom .copyright-text span {
  color: #86af48;
}

.footer-bottom .copyright-text a {
  color: #86af48;
  font-weight: 500;
}

.footer-bottom .copyright-text a:hover {
  text-decoration: underline;
}

.footer-bottom .social-profile {
  text-align: right;
}

.footer-bottom .social-profile li a {
  background-color: #86af48;
  color: #fff;
  font-size: 18px;
}

.footer-bottom .social-profile li a i {
  color: #373435;
}

.footer-bottom .social-profile li a:hover {
  background-color: #86af48;
  transform: translateY(-2px);
}

.footer-bottom .social-profile li a:hover i {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .footer-wrap .footer-bottom .copyright-text {
    margin-bottom: 15px;
  }

  .footer-wrap .footer-bottom .copyright-text,
  .footer-wrap .footer-bottom .social-profile {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap .footer-top .footer-shape-one {
    left: auto;
    right: 0px;
  }

  .footer-wrap .footer-top .footer-shape-two {
    right: 25px;
    top: 10px;
  }

  .footer-wrap .footer-top .footer-shape-four {
    right: 0px;
    top: 20px;
    max-width: 75px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  .footer-wrap .footer-widget .comp-desc {
    font-size: 14px;
    margin: 15px 0 22px;
    padding-bottom: 15px;
  }

  .footer-wrap .footer-widget .contact-info li {
    margin-bottom: 10px;
  }

  .footer-wrap .footer-widget .contact-info p,
  .footer-wrap .footer-widget .contact-info a {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .footer-menu li {
    margin-bottom: 8px;
  }

  .footer-wrap .footer-widget .footer-menu li a {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .footer-menu li a i {
    font-size: 14px;
    top: -3px;
  }

  .footer-wrap .footer-bottom .social-profile li a {
    width: 30px;
    height: 30px;
  }

  .footer-wrap .footer-bottom .social-profile li a i {
    font-size: 14px;
  }

  .ct_get_quote_form {
    padding: 60px 15px 50px !important;
  }

  .ct_mob_reverse_row {
    flex-direction: column-reverse;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-block: 30px;
  }

  .ct_director_img img {
    height: 250px;
  }

  .ct_ddirector_info {
    padding: 30px 0px;
  }

  .ct_mob_d_block {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .footer-wrap .footer-top .footer-shape-one {
    max-width: 140px;
  }

  .footer-wrap .footer-top .footer-shape-two {
    max-width: 70px;
  }

  .footer-wrap .footer-top .footer-shape-three {
    max-width: 70px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    margin-bottom: 22px;
    padding-bottom: 15px;
  }

  .footer-wrap .footer-widget .newsletter-form input,
  .footer-wrap .footer-widget .newsletter-form button {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .newsletter-form input {
    height: 55px;
    padding: 12px 130px 12px 15px;
  }

  .footer-wrap .footer-widget .newsletter-form button {
    padding: 12px 16px;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    background-size: cover;
  }

  .ct_drop_icon img {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-wrap .footer-top .footer-shape-four {
    top: 40px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form input,
  .footer-wrap .footer-top .footer-widget .newsletter-form button {
    font-size: 14px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form input {
    height: 55px;
    padding: 12px 130px 12px 15px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form button {
    padding: 12px 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

  .footer-menu li a,
  .comp-desc,
  .footer-wrap .footer-widget .contact-info li p,
  .footer-wrap .footer-widget .contact-info li a {
    font-size: 15px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
  }
}

/*# sourceMappingURL=style.css.map */

.ct_card_box {
  text-align: center;
  padding: 40px 20px 20px;
  width: 250px;
  height: 250px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s ease all;
  z-index: 1;
}

.ct_card_box:before {
  content: "";
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(right top,
      #86af48,
      #6ac610) repeat scroll 0 0;
  border-radius: 100% 0px 100% 100%;
  z-index: -1;
  transition: 0.4s ease all;
}

.ct_card_box:hover:before {
  transform: rotate(-45deg);
}

/* .ct_card_box figure{
    transition: 0.4s ease all;
}
.ct_card_box:hover figure {
    transform: rotate(45deg);
} */
.ct_card_icon {
  margin-bottom: 25px;
}

.ct_card_icon img {
  transition: 0.4s ease all;
}

.ct_card_box:hover .ct_card_icon img {
  transform: scale(1.2);
}

.ct_card_box figcaption {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

/* 
.ct_srvc_icon img {
    max-width: 210px;
    width: 210px;
    height: 241px;
    object-fit: cover;
} */
/* .ct_srvc_card {
    display: flex;
    align-items: center;
    gap: 25px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #FFF;
    transition: 0.4s ease all;
    overflow: hidden;
} */

/* .ct_srvc_card:hover{
    background-color: #86af48;
    border-color: #86af48;
}
.ct_srvc_card:hover .ct_srvc_cnt h3,.ct_srvc_card:hover .ct_srvc_cnt p{
    color: #fff;
}
.ct_srvc_card .ct_srvc_cnt h3 {
    font-size: 22px;
    margin-bottom: 15px;
}
.ct_srvc_card .ct_srvc_cnt p {
    margin-bottom: 0px;
}
.ct_srvc_cnt {
    padding: 20px 20px  20px 0px ;
} */

.project-wrap {
  position: relative;
  z-index: 1;
}

.ct_font_600 {
  font-weight: 500 !important;
}

.ct_bordr_left {
  font-size: 20px;
  border-left: 3px solid #8bc34a;
  padding-left: 15px;
  color: #373437;
}

/* .project-wrap:before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url(../../assets/img/shape_top_left.png);
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    z-index: -1;

} */
/* .project-wrap:after{
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(../../assets/img/shape_top_right.png);
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    z-index: -1;

} */
.ct_srvc_card_box {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  overflow: hidden;
  transition: 0.4s ease all;
  position: relative;
  height: 100%;
  margin-right: 25px;
  display: grid;
}

.ct_srvc_cnt {
  padding: 20px;
  text-align: center;
}

.ct_srvc_card_box .ct_srvc_cnt h3 {
  font-size: 22px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
}

.ct_srvc_card_box .ct_srvc_cnt p {
  margin-bottom: 0px;
  font-size: 16px;
  overflow: hidden;
  /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}

.ct_srvc_body {
  padding: 25px 30px 5px;
}

.ct_srvc_icon {
  text-align: center;
  background-color: #f8f7f0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: auto;
  transition: 0.4s ease all;
  position: relative;
}

.ct_srvc_icon img {
  transition: 0.4s ease all;
  position: relative;
  width: 40px !important;
}

.ct_srvc_card_box .ct_srvc_btm_img {
  overflow: hidden;
  position: relative;
  display: flex;
  align-self: flex-end;
}

.ct_srvc_card_box:hover .ct_srvc_icon img {
  transform: rotate(360deg);
}

.ct_srvc_card_box .ct_srvc_btm_img img {
  width: 100%;
  transition: 0.4s ease all;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.ct_srvc_card_box:hover .ct_srvc_btm_img img {
  transform: scale(1.2);
}

.ct_overlay_btn {
  position: absolute;
  z-index: 99;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s ease all;
}

.ct_srvc_card_box:hover .ct_srvc_btm_img .ct_overlay_btn {
  bottom: -0%;
}

.ct_srvc_btm_img:before {
  content: "";
  content: "";
  background-color: #373435;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  z-index: 1;
}

.ct_srvc_btm_img:hover:before {
  opacity: 0.3;
}

.theme-dark .ct_srvc_card_box {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  background-color: #333;
}

.ct_client_brand_img img {
  max-width: 200px;
  margin: auto;
}

.ct_remove_animtion {
  animation: none !important;
}

.theme-dark .ct_client_brand_img {
  background-color: #fff;
  height: 100px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding: 10px;
}

.ct_client_brand .owl-nav,
.ct_client_brand .owl-dots {
  display: none;
}

.ct_new_letter_bg {
  background-image: url(/static/media/newletr_bg.742867e0.jpg);
  /* background-image: linear-gradient(to right, rgb(141 180 74 / 80%) , rgb(228 233 99 / 60%)); */
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_new_letter_bg:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #000;
  opacity: 0.6;
  z-index: -1;
}

.ct_news_lettr_text h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 15px;
}

.ct_news_lettr_text p {
  color: #fff;
}

.ct_white_bg_btn .btn {
  background-color: #fff;
  color: #333 !important;
  width: 180px;
}

.ct_white_bg_btn .btn:hover {
  color: #fff !important;
}

.ct_news_lettr_text p {
  margin-bottom: 0px;
}

.ct_small_form_news input {
  width: 100%;
  height: 56px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: 1px dashed #9e9eab;
}

.ct_newsltr_btn {
  width: 100% !important;
}

.ct_white_bg_btn .btn.ct_newsltr_btn {
  background-color: #333;
  color: #fff !important;
  width: 180px;
}

.ct_news_letr_head svg {
  font-size: 60px;
  fill: #fff;
}

.ct_dashboard_right_cnt {
  padding: 30px 30px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  border-top: 4px solid #86af48;
}

.ct_dashboard_icon {
  background-color: #f8f7f0;
  border-radius: 10px;
  padding: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 15px;
  margin-inline: auto;
}

.ct_dashboard_cnt p {
  margin-bottom: 0px;
}

.ct_dashboard_cnt h4 {
  font-size: 22px;
}

.ct_dashboard_img {
  position: relative;
  animation: move 2s ease infinite;
  /* border: 2px solid #86af48;
    border-radius: 10px; */
  overflow: hidden;
}

.ct_dashboard_img img {
  width: 100%;
  height: auto;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.theme-dark .ct_dashboard_cnt h4,
.theme-dark .ct_dashboard_cnt p {
  color: #333 !important;
}

.ct_tabs_main .nav {
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

button.btn-close {
  position: absolute !important;
  right: 16px !important;
  background-color: #86af48;
  opacity: 1 !important;
  top: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1 !important;
}

.ct_padd_top_0 {
  padding-top: 0px !important;
}

.ct_padd_inline_0 {
  padding-inline: 0px !important;
}

button.btn-close i.fa.fa-times {
  color: #fff;
  font-size: 18px;
}

.opacity-animate3 {
  animation: opt-animation3 1s;
  animation-fill-mode: forwards;
}

.ct_modal_bg .modal-body {
  padding: 25px;
  text-align: center;
  background: rgb(134 175 72 / 30%);
}

@keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ct_tabs_main .tab-pane.active {
  display: block;
  opacity: 1;
}

.ct_tabs_main .nav a.nav-link {
  width: 120px;
  text-align: center;
  color: #333;
}

/* .ct_tabs_main .nav-pills .nav-link.active, .ct_tabs_main .nav-pills .show>.nav-link {
    background-color: #86af48;
    color: #fff;
} */
.ct_tabs_main .nav-pills .nav-link.active {
  background-color: #86af48;
  color: #fff;
}

.ct_project_main_div button:hover {
  background-color: unset !important;
  border: 0px !important;
}

.ct_project_main_div button {
  border: 0px !important;
}

.ct_project_main_div button.owl-prev:after {
  content: "Prev";
  margin-left: 5px;
  font-weight: 500;
}

.ct_project_main_div button.owl-next:before {
  content: "Next";
  margin-right: 5px;
  font-weight: 500;
}

.ct_project_main_div button span {
  font-size: 34px;
  position: relative;
  top: -4px;
}

.ct_project_main_div button span {
  font-size: 34px;
}

.ct_project_main_div .owl-nav {
  grid-gap: 15px;
  gap: 15px;
}

.ct_project_main_div .owl-nav button:hover,
.ct_project_main_div button:hover span {
  color: #86af48 !important;
}

.item-content .item-meta h4,
.item-content .item-meta p {
  color: #fff;
}

.item-content .item-meta {
  opacity: 0;
  transform: translateX(-10px);
}

.item-content .item-meta {
  opacity: 0;
  transform: translateX(-10px);
  transition: 0.4s ease all;
}

.ct_project_main_div .vertical-item:hover .item-content .item-meta {
  opacity: 1;
  transform: translateX(0px);
}

/* 

@keyframes move{
    0%,50%,100%{
        transform: translateY(0px);
    }
    25%,50%{
        transform: translateY(-10px);
    }
} */

/* our project css S */

.ds {
  background-color: #333333;
  color: #a4a4a4;
}

.cornered-heading {
  margin-top: -8px;
  display: inline-block;
  max-width: 95%;
  margin-right: 22px;
  margin-left: 22px;
}

.bottommargin_55 {
  margin-bottom: 55px;
}

.topmargin_40 {
  margin-top: 40px;
}


/* ouer mission S */
/* .ct_icon svg {
    width: 25px;
    color: #333;
} */
.ct_icon i {
  font-size: 28px;
  color: #fff;
}

.ct_our_mission_bg {
  background-image: url(/static/media/NaureBasedSolutions.9f95b027.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  height: 100%;
  position: relative;
  width: 100%;
  transition: 0.4s ease all;
  overflow: hidden;
  z-index: 1;
}

.ct_our_mission_bg2 {
  background-image: url(/static/media/CarbonRemoval.49e73256.jpg);
}

.ct_our_mission_bg:hover .ct_overlay_mission_cnt {
  top: 50%;
}

.ct_overlay_mission_cnt {
  width: 100%;
  position: absolute;
  top: -50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding: 30px;
  transition: 0.4s ease all;
  background: #86af4866;
  display: block;
  height: 100%;
  z-index: -1;
}

.ct_overlay_mission_cnt {
  text-align: center;
}

.ct_overlay_mission_cnt .ct_icon svg {
  fill: #fff;
  margin-bottom: 15px;
}

.ct_mission_info h4,
.ct_mission_info p {
  color: #fff;
}

.ct_our_mission_main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ct_background_clr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #86af48;
}

.ct_our_mission_left_bg ul.content-feature-list li i {
  color: #fff;
}

.ct_our_mission_left_bg {
  max-width: 650px;
  float: right;
  padding-inline: 15px;
  padding-block: 100px;
}

.ct_overlay_center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  padding: 30px;
}

.ct_our_mission_bg:hover .ct_mission_title {
  display: none;
}

.ct_mission_title h4 svg {
  width: 28px;
  fill: #fff;
}

.ct_mission_title h4 {
  color: #fff;
}

.ct_mission_title {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  height: 60px;
  transition: 0.4s ease all;
}

.ct_our_mission_bg:after {
  content: "";
  position: absolute;
  bottom: 0px;
  background: -webkit-linear-gradient(bottom,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0));
  width: 100%;
  height: 122px;
  left: 0px;
  z-index: -1;
}

.team-card.style3 {
  width: 320px;
  margin: auto;
  display: block;
}

.ct_white_bg_overlay {
  position: relative;
  z-index: 1;
}

.ct_white_bg_overlay:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(/static/media/white_background_bg.1aa17d1f.png);
  /*background-position: center;*/
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-size: cover;
  z-index: -1;
}

.theme-dark .ct_white_bg_overlay:before {
  opacity: 0.2;
}

/* service Page css S */

.ct_service_bg {
  background-image: url(/static/media/service_bg.bffb7bf2.jpg);
}

.ct_life_cycle_bg {
  background-image: url(/static/media/pseudo.2938ae6f.png);
}

.ct_chain_connection {
  position: relative;
}

.ct_chain_connection:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 10px;
  border: 2px solid #86af48;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  -webkit-box-reflect: right -3px;
  z-index: -1999;
}

.ct_service_card_bg {
  text-align: center;
  /* background-image: url(../../assets/img/Project_1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
  padding: 50px 30px;
  position: relative;
  box-shadow: 0 4px 16px 0 rgba(51, 51, 51, 0.1);
  z-index: 1;
  height: 100%;
}

.ct_inner_srvc_info h4 {
  font-size: 22px;
  margin: 25px 0px 18px;
}

.ct_inner_srvc_info hr {
  height: 2px;
  width: 40px;
  background: #333;
  opacity: 1;
  margin: 20px auto 25px;
}

.ct_service_card_main {
  position: relative;
  height: 100%;
}

.ct_srvc_overlay_bg {
  position: absolute;
  inset: 0px;
  background-color: #fff;
  z-index: -1;
  transition: 0.4s ease all;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}

.ct_show_more_div {
  display: none;
}

.theme-dark .ct_srvc_overlay_bg {
  background-color: #333;
}

.theme-dark .ct_service_card_bg:hover .ct_srvc_overlay_bg {
  background-color: rgb(51 51 51 / 90%);
}

.theme-dark .ct_inner_srvc_info hr {
  background-color: #fff;
}

.ct_service_card_bg:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: rgb(134 175 72 / 21%);
  left: 0px;
  bottom: 0;
  transition: 0.4s ease all;
  z-index: -1;
}

.ct_service_card_bg:hover:after {
  height: 100%;
}

.ct_service_card_bg:hover .ct_srvc_card_icon img {
  animation: scaleDisappear 0.5s;
}

@keyframes scaleDisappear {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  60% {
    opacity: 0;
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* .ct_sub_heading {
    position: relative;
    width: fit-content;
    margin-inline: auto;
  
} */
.ct_sub_heading {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  margin-top: -56px;
  background-color: #86af48;
  padding: 10px 30px;
  color: #fff;
  border-radius: 30px 0px 30px 30px;
  text-transform: uppercase;
}

/*   
  @keyframes water {
    0% {
      background-position: 0px -150px;
    }
    50% {
      background-position: -300px -50px; 
    }
    100% {
      background-position: 0px -150px;
    }
  } */
/* .ct_sub_heading:before {
    content: "";
    position: absolute;
    top: -6px;
    left: -12px;
    width: 25px;
    height: 25px;
    border: 3px solid #86af48;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.ct_sub_heading:after {
    content: "";
    position: absolute;
    bottom: -4px;
    right: -12px;
    width: 25px;
    height: 25px;
    border: 3px solid #86af48;
    border-left-width: 0px;
    border-top-width: 0px;
} */

.vertical-item .item-media img {
  height: 169px;
  -o-object-fit: cover;
     object-fit: cover;
}

.vertical-item.gallery-item .item-media img {
  height: 636px;
  -o-object-fit: cover;
     object-fit: cover;
}

/* get a quote section S */

.ct_get_quote_bg {
  background-image: url(/static/media/get_quote_bg.7fc91042.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
}

.ct_get_quote_bg:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #373435;
  opacity: 0.5;
  z-index: -1;
}

.ct_get_quote_form {
  background-color: #fff;
  padding: 60px 60px 50px;
}

.theme-dark .ct_get_quote_form {
  background-color: #333;
}

.theme-dark .ct_get_quote_form input,
.theme-dark .ct_get_quote_form textarea {
  border-width: 0px;
  border-bottom-width: 1px;
}

.theme-dark .ct_get_quote_form input:focus~label,
.theme-dark .ct_get_quote_form input:valid~label,
.theme-dark .ct_get_quote_form textarea:focus~label,
.theme-dark .ct_get_quote_form textarea:valid~label {
  color: #fff;
}

.ct_get_quote_form .form-group {
  position: relative;
  margin-bottom: 35px;
}

.ct_get_quote_form input,
.ct_get_quote_form textarea {
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: unset !important;
  color: #000000 !important;
}

.ct_get_quote_form input:focus,
.ct_get_quote_form textarea:focus {
  outline: none;
}

.ct_get_quote_form label {
  position: absolute;
  top: 10px;
  left: 5px;
  color: #999;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  transition: all 0.2s ease;
}

.ct_get_quote_form input:focus~label,
.ct_get_quote_form input:valid~label,
.ct_get_quote_form textarea:focus~label,
.ct_get_quote_form textarea:valid~label {
  top: -18px;
  font-size: 16px;
  color: #333;
}

.ct_get_quote_form .bar {
  display: block;
  position: relative;
  width: 100%;
  top: 2px;
}

.ct_get_quote_form .bar:before,
.ct_get_quote_form .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #86af48;
  transition: all 0.2s ease;
}

.ct_get_quote_form .bar:before {
  left: 50%;
}

.ct_get_quote_form .bar:after {
  right: 50%;
}

.ct_get_quote_form input:focus~.bar:before,
.ct_get_quote_form input:focus~.bar:after,
.ct_get_quote_form textarea:focus~.bar:before,
.ct_get_quote_form textarea:focus~.bar:after {
  width: 50%;
}

/* contact page css S */
.ct_contact_bg {
  background-image: url(/static/media/contact_bg_new.11eb8a20.jpg);
  background-position: center;
  background-size: cover;
}

.ct_form_wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 25px 20px;
  border-radius: 5px;
}

/* service detail css S */
.ct_srvc_sidebar ul li a {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(82, 195, 6, 0.2);
  padding: 15px 20px;
  color: #333;
  font-size: 16px;
  text-transform: capitalize;
  z-index: 1;
  transition: all 300ms ease;
}

.ct_srvc_sidebar ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  border-left: 3px solid #86af48;
  background: #444444;
  transition: all 500ms ease;
}

.ct_srvc_sidebar ul li:hover:before,
.ct_srvc_sidebar ul li.active:before {
  width: 100%;
}

.ct_hover_img img {
  width: 100%;
}

.ct_srvc_sidebar li:hover a,
.ct_srvc_sidebar li.active a {
  color: #fff;
}

.ct_srvc_sidebar ul li {
  position: relative;
  display: block;
  background: url(/static/media/bg-pattern-1.671b1861.png) center center repeat;
  margin-bottom: 15px;
}

.ct_widget-inner {
  background: #eaecf3b8;
  padding: 50px 50px 30px;
}

.theme-dark .ct_widget-inner {
  background-color: #333;
}

.theme-dark .ct_srvc_sidebar ul li {
  background-image: unset;
}

.ct_widget-inner h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.ct_widget-inner ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ct_widget-inner ul li a {
  position: relative;
  display: block;
  padding: 15px 0px;
  line-height: 30px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
}

.ct_bottom_card_img img {
  width: 100%;
  border-radius: 10px;
}

.ct_bottom_card {
  position: relative;
}

.ct_bottom_card_info {
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 93%;
  text-align: center;
  border-bottom: 3px solid #86af48;
}

.ct_bottom_card_info ul.social-profile a {
  background: #86af48;
}

.ct_bottom_card_info ul.social-profile a i {
  color: #fff;
}

/* product tab section S */
.ct_prdct_tab_1 div {
  box-sizing: border-box;
  transition: all ease-in-out 0.3s;
}

.ct_prdct_icetab {
  display: inline-block;
  border-bottom: 0px;
  margin: 0px;
  color: #333;
  cursor: pointer;
  border-right: 0px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.team-info a {
  color: #373435 !important;
}

.theme-dark .ct_prdct_icetab {
  color: #fff;
}

#ct_icetab-content {
  overflow: hidden;
  position: relative;
}

.team-slider-one.owl-carousel .owl-nav.disabled {
  display: flex !important;
  align-items: center;
}

.team-slider-one.owl-carousel .owl-nav.disabled button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #86af48;
}

.team-slider-one.owl-carousel .owl-nav.disabled button:hover {
  background-color: #86af48;
  color: #fff;
}

.team-slider-one.owl-carousel .owl-nav.disabled button span {
  display: none !important;
}

.team-slider-one.owl-carousel .owl-nav.disabled button.owl-prev:before {
  content: "\f134";
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.team-slider-one.owl-carousel .owl-nav.disabled button.owl-next:before {
  content: "\f133";
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.ct_tabcontent1 {
  position: absolute;
  left: 0px;
  top: 0px;
  /* background: #fff; */
  width: 100%;
  border-top: 0px;
  border-top: 0px;
  transform: scale(0);
}

.ct_tabcontent1:first-child {
  position: relative;
}

.ct_tabcontent1.ct_tab-active {
  border-top: 0px;
  display: block;
  transform: scale(1);
}

.ct_box_shadow {
  background-color: rgb(255 255 255 / 41%);
  padding: 30px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.ct_tabcontent1 {
  padding: 40px 0px;
}

.ct_prdct_icetab {
  padding: 8px 16px;
  width: 140px;
  border-radius: 5px;
}

.ct_current-tab {
  background-color: #86af48;
  color: #fff;
}

div#ct_prdct_icetab-container {
  text-align: center;
}

/* about page css S */

.ct_mob_d_block {
  display: none;
}

.ct_ceo_img img {
  width: 250px;
  height: 250px;
  border-radius: 100%;
  border: 5px solid #f5f5f5;
  margin: auto;
  display: block;
}

.ct_ceo_img {
  margin-bottom: 20px;
}

.ct_ceo-card_main {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
  position: relative;
  transition: 0.4s ease all;
  z-index: 1;
  border-radius: 10px;
  height: 100%;
}

.ct_ceo-card_main:before {
  content: "";
  position: absolute;
  top: 0px;
  background-color: #86af4866;
  width: 100%;
  left: 0px;
  height: 300px;
  z-index: -1;
  border-radius: 10px 10px 0px 0px;
}

.ct_inner_ceo_card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  z-index: 1;
  height: 100%;
}

.theme-dark .ct_ceo-card_main {
  background-color: #333;
}

.theme-dark .ct_inner_ceo_card {
  background-color: #333;
}

.ct_ceo_info .ct_bottom_card_info {
  position: unset;
  left: 0px;
  margin: 0px;
  width: 100%;
  padding: 10px 0px 0px;
  transform: translateX(0);
}

.ct_inner_ceo_card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed #86af48;
  bottom: 0%;
  left: 0%;
  border-radius: 10px;
  z-index: -1;
}

.ct_nav_owl_none button {
  display: none !important;
}

.ct_z_index_1 {
  z-index: -1 !important;
}

.ct_more_link {
  display: none;
}

/* Esg Reporting service S */
.ct_dash_esg_cnt h2 {
  font-size: 40px;
  line-height: 50px;
}

.ct_srvc_point h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

ul.ct_srvc_point {
  margin-bottom: 0px;
  padding-left: 0px;
}

ul.ct_srvc_point li {
  list-style: none;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 25px;
}

ul.ct_srvc_point li img {
  width: 50px;
  height: 50px;
  border: 1px solid #9dbe4f;
  padding: 10px;
  border-radius: 45px;
}

.ct_dash_esgimg img {
  width: 100%;
  animation: moveimg 3s ease infinite;
}

@keyframes moveimg {

  0%,
  50%,
  100% {
    transform: translateY(-10px);
  }

  25%,
  75% {
    transform: translateY(0px);
  }
}

ul.ct_srvc_point li p {
  margin-bottom: 0px;
}

/* on scrool section S */
#div01 {
  /* height: 100vh; */
  width: 100vw;
  /* background-color: blue; */
  top: 800px;
  left: 0px;
}

#logoDove,
#logoDog,
#logoFish {
  display: none;
}

.ct_scroll_section {
  /* height: 100vh; */
  width: 100vw;
  top: 800px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* #div02 {
    background-color: green;
}
#div03 {
    background-color: orange;
}
#div04 {
    background-color: purple;
} */

.ct_scrool_cnt {
  position: relative;
  top: 0px;
  /* left: 30px; */
}

.ct_scroll_fixed_img img {
  background-repeat: no-repeat;
  background-size: cover;
}

.ct_scroll_fixed_cnt {
  padding-inline: 30px;
}

.ct_round_num p {
  background-color: #f5f5f5;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 100px;
  color: #86af48;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ct_scroll_fixed_cnt .ct_round_num p:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #86af48;
  margin: auto;
  display: block;
  width: 0%;
  height: 0%;
  z-index: -1;
  transition: 0.4s ease all;
  border-radius: 100px;
}

.ct_scroll_fixed_cnt:hover .ct_round_num p {
  color: #fff;
}

.ct_scroll_fixed_cnt:hover .ct_round_num p:before {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ct_green_background {
  background-color: #86af48;
  text-align: center;
  padding-bottom: 166px !important;
}

.ct_green_background:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-image: url(/static/media/bg_shape_dot.33a48662.png);
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  animation: ct_move_X 6s ease infinite;
}

.en_faq_main_div .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.en_faq_main_div .accordion-item .accordion-header .accordion-button.collapsed:after {
  content: "";
  width: 2px;
  height: 15px;
  background-color: red;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.en_faq_main_div .accordion-item .accordion-header button.accordion-button.collapsed:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  background: #000;
  width: 15px;
  height: 2px;
  transform: translateY(-50%);
}

.en_faq_main_div .accordion-item .accordion-header button.accordion-button.collapsed:after {
  content: "" !important;
  position: absolute;
  top: 50%;
  display: block !important;
  right: 22px;
  background: #000;
  width: 2px;
  height: 15px;
  transform: translateY(-50%);
}

@keyframes ct_move_X {

  0%,
  50%,
  100% {
    transform: translateX(10px);
  }

  25%,
  75% {
    transform: translateX(0px);
  }
}

.ct_globel_logo_img {
  background-color: #f8f6f2;
  padding: 15px;
  /* width: 33.33%; */
}

.ct_globel_logo_img img {
  max-width: 100%;
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
  display: block;
}

.ct_globel_logo_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 25px;
  gap: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  position: relative;
  margin-top: 0px;
  padding: 80px;
  border-radius: 10px;
  margin-top: -154px;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.ct_globel_partner_bg {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  position: relative;
  margin-top: -85px;
  margin-bottom: 100px;
  border-radius: 10px;
}

span.ct_clr_text {
  color: #86af48;
  font-size: 32px;
}

.ct_nwsletr_bg1 {
  position: relative;
  padding-block: 100px;
  z-index: 1;
  background: #f8f6f2;
}

.ct_nwsletr_bg1:before {
  content: "";
  position: absolute;
  inset: 0px;
  opacity: 0.3;
  width: 100%;
  height: 100%;

  background-image: url(/static/media/newsletter-background12.16150501.webp);
  z-index: -1;
}

.ct_newletter_bg {
  text-align: center;
}

.ct_shake_btn {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.ct_min_img img {
  width: auto;
  height: 400px;
  -o-object-fit: contain;
     object-fit: contain;
}

.ct_accelerate_bg {
  background-image: url(/static/media/Accelerate_bg.9347465b.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_small_title h4 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 14px;
}

.ct_bg_grey_1 {
  background-color: #f5f5f5;
  /* padding: 30px; */
  border: 1px solid #aaaa;
  padding: 15px;
  border-radius: 10px;
}

.ct_how_works_bg {
  /* background-image: url(../../assets/img/how_it_works2.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
}

/* .ct_how_works_bg p {
    font-weight: 500;
} */
.ct_sm_btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  outline: none;
  border-radius: 5px;
  background-color: #86af48;
  color: #fff;
  font-size: 14px;
  border: 1px solid #86af48;
}

/* .ct_light_green_bg {
    background-color: #86af4836;
} */
.ct_light_yeloow {
  background: #dfe66124;
}

h4.ct_md_btn {
  /* background-color: #86af48; */
  color: #fff;
  padding: 6px 40px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 18px;
  margin: auto;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 2px solid;
  border-radius: 0px;
}

.ct_f_10 {
  font-size: 14px;
  padding-inline: 6px;
}

.ct_table tr th,
.ct_table tr td {
  font-size: 10px;
  padding-inline: 5px;
}

.ct_bg_dark {
  background-color: rgb(228 233 99 / 26%) !important;
}

table.table.ct_table {
  border: 1px solid rgb(170 170 170 / 42%);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Api Page css S */

.ct_how_box_1 {
  max-width: 100%;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(134 175 72 / 27%);
  /* clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%); */
  /* clip-path: polygon(75% 0, 0 0, 0 100%, 75% 100%, 100% 50%); */
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  height: 180px;
  color: #333;
}

.ct_how_box_1:before {
  content: "";
  position: absolute;
  top: 60px;
  left: -30px;
  z-index: 1;
  border: solid 15px transparent;
  border-right-color: #fff;
}

.ct_small_title_box {
  max-width: 206px;
  height: 84px;
  border: 4px solid #86af48;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;
  /* clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); */
  /* background: rgb(139 195 74 / 35%); */
  margin: auto;
  /* clip-path: polygon(0% 0%, 90% 0, 100% 50%, 89% 100%, 0% 100%); */
  border-right: 0px;
  position: relative;
}

.ct_small_title_box:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 57px;
  border: 4px solid #86af48;
  border-left: 0px;
  /* border-top: 0px; */
  border-bottom: 0px;
  right: -30px;
  background-color: transparent;
  transform: rotate(47deg);
}

.ct_bank_bg {
  /* margin-bottom: 100px; */
  padding-block: 20px;
  border-radius: 0px;
  margin-top: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to right, rgb(134 175 72) , rgb(228 233 99 / 65%)); */
  /* background-color: rgb(228 233 99 / 17%); */
  background-image: url(/static/media/api_head_bg.009f8d27.png);
  padding: 70px 15px 120px;
  position: relative;
  background-color: #eaed98;
}

span.ct_clr_green_text {
  font-size: 35px;
}

.ct_bigger_head {
  position: relative;
  display: inline-block;
  font-size: 43px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #333;
  /* background-color: #86af48; */
  padding-bottom: 15px;
  /* width: 250px; */
  /* filter: drop-shadow(3px 3px 3px #fff); */
  margin-bottom: 60px;
  text-transform: uppercase;
  /* text-shadow: 1px 3px 7px #aaa; */
}

.ct_svg_head:after {
  content: "";
  position: absolute;
  width: 100%;
  background: url(/static/media/wavesNegative.d6f185c1.svg);
  height: 50px;
  left: 0px;
  bottom: 0;
}

/* .ct_bigger_head::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 10px solid #86af48;
    transform: translateX(-50%);
} */

.ct_bigger_head:before {
  content: "";
  position: absolute;
  border-bottom: 2px solid #86af48;
  width: 100%;
  bottom: 0px;
  left: 0px;
  transition: 0.4s ease all;
}

.ct_bigger_head:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #86af48;
  border-radius: 100px;
  bottom: -5px;
  left: 50%;
  transform: translateX(0%);
  animation: move_dot 10s linear infinite;
}

@keyframes move_dot {

  0%,
  50%,
  100% {
    left: 0%;
    transform: translateX(0%);
  }

  25%,
  75% {
    left: 100%;

    transform: translateX(0%);
  }
}

/* Ecommerce section css S */
.UpperPart {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 3;
  background-color: white;
}

.UpperPart-Img {
  padding: 50px;
}

.UpperPart-Img img {
  height: 100%;
  width: 100%;
}

.UpperPart-text {
  height: auto;
  padding: 50px 50px 50px 0px;
  text-align: left;
  line-height: 2;
}

.UpperPart-Title {
  color: #292929;
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
}

.UpperPart-SubTitle {
  color: #2f2f2f;
  padding: 20px;
  font-weight: 500;
}

.UpperPart-Description {
  font-size: 20px;
  color: #7d7d7d;
  padding: 20px;
}

/******* this is the upper horizontal and vertical lines *************************************************/
.connection-line {
  height: 0vh;
  width: 50%;
  background-color: white;
  margin-top: -10vh;
  margin-left: 25%;
  margin-right: 25%;
  border-right: 1.2px solid #0e3b85;
  z-index: 3;
}

.horizontal-lines {
  display: block;
  width: 0px;
  height: 1.2px;
  margin-top: 30vh;
  position: absolute;
  background-color: #0e3b85;
  animation-name: animated-line;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

#line-left {
  left: 50%;
}

#line-right {
  right: 25%;
}

#line-center {
  margin: auto;
}

/************************************************************************************/

/********************************************************/

.timeline {
  list-style: none;
  padding-top: 0px;
  position: relative;
  z-index: -3;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: fixed;
  content: " ";
  width: 1.5px;
  background-color: unset;
  border: none;
  left: 130px;
  height: 100%;
  z-index: -5;
}

.timeline:after {
  top: 0;
  bottom: 0;
  position: fixed;
  content: " ";
  width: 1.5px;
  background-color: uns;
  left: 130px;
  border: none;
  height: 50vh;
  z-index: -5;
}

.ct_fixed:before,
.ct_fixed:after {
  position: fixed !important;
}

.ct_fixed:before {
  border: 1.25px solid #86af48 !important;
  background-color: #86af48;
}

.ct_fixed:after {
  border: 1.25px solid #f5f5f5 !important;
  background-color: #86af48 !important;
}

/************************************************************************************/

.timeline>li {
  position: relative;
  display: flex;
  align-items: center;
}

/************************************************************************************/

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

/************************************************************************************/
.timeline-panel {
  width: 50%;
  height: 500px;
  padding: 50px;
  position: relative;
  /* text-align: justify; */
  color: #000000;
  display: flex;
  align-items: center;
}

/************************************************************************************/

.timeline-panel:before {
  position: absolute;
  top: 29%;
  right: -6vw;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid lightblue;
  content: " ";
  background-color: white;
  z-index: 99;
  display: none;
}

/************************************************************************************/

.timeline-panel-img img {
  width: 100%;
  height: auto;
  max-width: 40vw;
  /* max-height: 35vh; */
  left: auto;
  right: auto;
}

/************************************************************************************/

.timeline-panel-img:before {
  position: absolute;
  top: 29%;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightblue;
  content: " ";
  display: none;
}

/************************************************************************************/

.timeline-panel:after {
  position: absolute;
  top: 31%;
  right: -14.25vw;
  display: inline-block;
  width: 8.3vw;
  height: 1px;
  background-color: lightblue;
  content: " ";
  z-index: -3;
  display: none;
}

/************************************************************************************/

.timeline-badge.active {
  color: #86af48;
  animation-name: lines;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  border-color: #86af48 !important;
}

/************************************************************************************/

.timeline-panel-body {
  opacity: 1;
}

/************************************************************************************/

.timeline-panel.active:after {
  animation-name: lines2;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/

.timeline-panel.active:before {
  animation-name: lines3;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/

.timeline-panel-img.active:before {
  animation-name: ani-img;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/
.timeline-badge {
  color: #333;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 30%;
  left: -13%;
  margin-left: -69px;
  background-color: white;
  border: 1px solid #333;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

/************************************************************************************/

.timeline-body p {
  font-size: 1.2em;
  line-height: 2;
  /* text-align: justify; */
}

/************************************************************************************/

/* .timeline-panel.timeline-inverted, .timeline-inverted>.timeline-panel {
    float: right;
  }
   */
.timeline-panel.timeline-inverted:before,
.timeline-inverted>.timeline-panel:before {
  left: -6vw;
  right: auto;
}

.timeline-panel.timeline-inverted:after,
.timeline-inverted>.timeline-panel:after {
  background-color: lightblue;
  left: -15.2vw;
  right: auto;
  width: 10vw;
}

.timeline-title {
  font-size: 32px;
  line-height: 1.5;
  /* text-align: center; */
  font-weight: 600;
}

/************************************************************************************/

.endpart {
  position: relative;
  height: 50vh;
  background-color: white;
  z-index: 3;
  margin-top: 100px;
  text-align: center;
}

.endpart p {
  padding-top: 200px;
}

/************************************************************************************/
@keyframes animated-line {
  0% {
    width: 0px;
  }

  100% {
    width: 25%;
  }
}

@keyframes ani-img {
  0% {
    background-color: lightblue;
    transform: scale(1, 1);
  }

  50% {
    background-color: #7390bf;
    transform: scale(2, 2);
  }

  100% {
    background-color: #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes ani-panel {
  0% {
    color: #7390bf;
    opacity: 0;
  }

  50% {
    color: #7390bf;
    opacity: 0.5;
  }

  100% {
    color: #7390bf;
    opacity: 1;
  }
}

@keyframes ani-body {
  0% {
    color: lightblue;
  }

  50% {
    color: #7390bf;
  }

  100% {
    color: #0e3b85;
  }
}

@keyframes lines3 {
  0% {
    border: 1px solid lightblue;
    transform: scale(1, 1);
  }

  50% {
    border: 1.25px solid #7390bf;
    transform: scale(2, 2);
  }

  100% {
    border: 1.5px solid #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes lines {
  0% {
    border: 1px solid lightblue;
    transform: scale(1, 1);
  }

  50% {
    border: 1.25px solid #7390bf;
    transform: scale(1.5, 1.5);
  }

  100% {
    border: 1.5px solid #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes lines2 {
  0% {
    height: 1px;
  }

  50% {
    height: 1.25px;
    background-color: #7390bf;
  }

  100% {
    height: 1.5px;
    background-color: #0e3b85;
  }
}

@media (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
  .connection-line {
    display: none;
  }

  .timeline {
    padding-top: 50px;
    margin-left: 0;
  }

  .timeline:before {
    left: 50%;
    display: none;
  }

  .timeline:after {
    left: 50%;
    display: none;
  }

  .timeline>li {
    padding-bottom: 10%;
  }

  .timeline>li>.timeline-badge {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    line-height: 125px;
    font-size: 2em;
    left: 50%;
    top: 0;
    /* margin-bottom: 50px; */
    position: relative;
  }

  .timeline-badge.active {
    color: #86af48;
    animation-name: lines-mob;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .timeline>li>.timeline-panel {
    width: 100%;
    height: 35vh;
    float: none;
    left: 0%;
    background-color: white;
    z-index: 5;
    display: block;
    padding: 0px 0px;
    height: auto;
    text-align: justify;
  }

  .timeline>li>.timeline-panel.timeline-inverted,
  .timeline>li.timeline-inverted>.timeline-panel {
    float: none;
  }

  .timeline>li>.timeline-panel::before {
    display: none;
  }

  .timeline>li>.timeline-panel::after {
    display: none;
  }

  .timeline>li>.timeline-panel-img::before {
    display: none;
  }

  .timeline>li>.timeline-panel-img::after {
    display: none;
  }

  .timeline-body p {
    font-size: 90%;
    line-height: 1.25;
  }

  .timeline-title {
    text-align: center;
    font-size: 32px;
  }

  .timeline-panel-img img {
    width: 100%;
    height: auto;
    max-width: 90vw;

    left: auto;
    right: auto;
  }

  @keyframes lines-mob {
    0% {
      border: 1px solid lightblue;
      transform: scale(1, 1);
    }

    50% {
      border: 1.25px solid #7390bf;
      transform: scale(1.2, 1.2);
    }

    100% {
      border: 1.5px solid #0e3b85;
      transform: scale(1, 1);
    }
  }

  .timeline-body p {
    font-size: 1em;
    line-height: 1.4;
    text-align: center;
  }
}

/* scroll image change css S */

.ct_scrollsection {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.ct_scrollsection__img {
  position: relative;
  display: flex;
}

/* .ct_scrollsection__img__inner {
        height: 100vh;
        position: fixed;
        left: 10%;
        width: 40%;
        top:30%;
        z-index: -1;
      } */

.ct_scrollsection__body {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  /* top: 32%;
        transform: translateY(-50%); */
}

.ct_scrollsection__text {
  padding: 0vw;
}

/* .ct_scrollsection__img__inner img {
    transform: translateX(-100px);
    opacity: 0;

    transition: 0.6s ease all;
    
  } */

/* .ct_scrollsection.active .ct_scrollsection__img__inner img {
    opacity: 1;

    transform: translateX(0px);
  } */

.ct_overflow_hidden {
  overflow: hidden;
}

.ct_right_width_img {
  width: auto;
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
  display: block;
}

.ct_scroll_fixed_cnt ul li {
  position: relative;
  list-style: none;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

.ct_scroll_fixed_cnt p {
  font-weight: 500;
  color: #666;
}

.ct_scroll_fixed_cnt ul li:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -20px;
  background-color: #86af48;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
  border-radius: 10px 0px 10px 10px;
}

.ct_custome_close {
  position: absolute !important;
  top: -8px !important;
  right: -8px !important;
  color: #fff !important;
}

.ct_book_a_demo_main {
  padding-top: 0px !important;
}

select.form-control.ct_select_option1 {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0px;
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
  padding-left: 0px;
}

#ct_book_demo {
  /* z-index: 9999999 !important; */
  margin-top: 50px;
}

.ct_small_title_box h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.ct_box_light_bg {
  /* background-color: rgb(228 233 99 / 19%); */
  /* background-color: #2196f314; */
  padding: 15px;
  text-align: center;
  position: relative;
  margin-top: 30px;
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  overflow: hidden;
}

.ct_box_light_bg:before {
  content: "";
  position: absolute;
  background-image: url(/static/media/card_btm_wave.1e12a52b.svg);
  width: 104%;
  height: 40px;
  left: 0px;
  bottom: 0px;
  transform: rotate(180deg);
}

.ct_box_light_bg p {
  word-wrap: break-word;
  font-size: 16px;
  color: #fff;
}

.ct_right_arrow {
  background-color: #fff;
  width: 10px;
  padding: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
  border: 1px solid #aaa;
}

.ct_light_blue_head,
.ct_light_blue_head:after {
  border-color: #83bfe1;
}

.ct_light_blue_bg {
  background-color: #83bfe1;
}

.ct_light_green_head,
.ct_light_green_head:after {
  border-color: #86af48a8;
}

.ct_light_green_bg {
  background-color: #86af48a8;
}

.ct_light_pink_head,
.ct_light_pink_head:after {
  border-color: #f29381d1;
}

.ct_light_pink_bg {
  background-color: #f29381d1;
}

/* Carbon Neutral events Page S */

.ct_how_work_box {
  background-color: rgb(134 175 72 / 14%);
  padding: 30px;
  height: 100%;
  border-radius: 30px 0px 30px 30px;
  transition: 0.4s ease all;
  position: relative;
}

.ct_how_work_box:hover {
  transform: translateY(-15px);
}

.ct_how_work_icon {
  width: 60px;
  height: 60px;
  border: 2px dashed #8bc34a;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease all;
  position: relative;
  margin: 0px auto 30px;
  background-color: #fff;
  margin-top: -60px;
  z-index: 1;
  overflow: hidden;
}

.ct_how_work_icon:before {
  content: "";
  position: absolute;
  inset: 0px;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  transition: 0.4s ease all;
  background-color: #86af48;
  z-index: -1;
  border-radius: 100px;
}

.ct_how_work_box:hover .ct_how_work_icon h2 {
  color: #fff;
}

.ct_how_work_box:hover .ct_how_work_icon:before {
  width: 100%;
  height: 100%;
}

.ct_how_work_icon h2 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 900;
}

.ct_how_work_box .ct_how_work_cnt {
  text-align: center;
}

.ct_how_work_cnt h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

.ct_neutral_left_img img {
  border-radius: 28% 72% 69% 31% / 33% 31% 69% 67%;
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}

.ct_neutral_left_img {
  border-radius: 28% 72% 69% 31% / 33% 31% 69% 67%;
  max-width: 500px;
  height: 500px;
  margin: auto;
}

.ct_light_yellow_bg {
  background-color: #f8f6f2;
}

.ct_faq_q_box {
  background-color: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
  padding-bottom: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
  height: 100%;
}

.ct_faq_question_title {
  background-color: #86af48;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.ct_faq_question_title h4 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}

.ct_faq_answer {
  padding: 0px 15px 0px;
}

.ct_faq_answer p {
  margin-bottom: 0px;
}

.ct_carbon_neutral_nwsltr {
  display: grid;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f8f6f2;
}

.ct_nwsltr_img img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 100% 27%;
     object-position: 100% 27%;
  filter: brightness(0.9);
}

.ct_carbon_neutral_nwsltr_cnt {
  padding-left: 50px;
}

/* Eko Points page css S */
.ct_eko_point_work_box {
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  /* padding: 30px; */
  transition: 0.4s ease all;
  position: relative;
  height: 100%;
  border: 2px dashed #8bc34a;
  border-radius: 10px;
  overflow: hidden;
}

.ct_eko_point_work_cnt h4 {
  margin-bottom: 15px;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}

.ct_eko_point_work_cnt p {
  margin-bottom: 0px;
  color: #fff;
}

.ct_eko_icon {
  padding-top: 40px;
  z-index: 111;
  position: relative;
}

.ct_eko_icon img {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 25px;
}

.ct_eko_point_work_cnt {
  padding: 40px 30px 30px;
  background-color: #8bc34a;
  height: 100%;
  position: relative;
}

.ct_eko_point_work_cnt::before {
  position: absolute;
  left: -30px;
  right: -30px;
  top: -45px;
  content: "";
  height: 60px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffffff;
}

.ct_eko_point_work_box:hover .ct_eko_icon img {
  animation: ct_eko_shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.ct_eko_points_bg {
  background-image: url(/static/media/Eko_points_bg_img.61a06b0b.jpg);
  background-color: #86af48;
  /* overflow: auto; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_eko_points_bg:after {
  width: 100%;
  height: 150px !important;
  z-index: -1;
  background-image: url(/static/media/Eko_points_waves.d6f185c1.svg);
  bottom: 0px !important;
  background-color: transparent !important;
  top: auto !important;
}

.ct_eko_points_bg:before {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;

  bottom: 0px;
  background-color: #000;
  opacity: 0.5;
  top: auto;
}

.ct_eko_spark_mob img {
  position: relative;
  top: 25px;
  width: 240px;
}

.ct_eko_spark_bg_title h2 {
  color: #fff;
  font-size: 35px;
  margin-bottom: 0px;
  letter-spacing: 1.6px;
}

.ct_eko_spark_bg_title p {
  color: rgb(255 255 255 / 79%);
}

h4.ct_coming_soon_text {
  color: #fff;
  letter-spacing: 2.4px;
  margin-top: 50px;
}

.ct_eko_spark_bg_title {
  margin-top: -150px;
}

/* .ct_ecom_titl_bg {
    background-color: rgb(134 175 72 / 25%);
    padding-block: 60px;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 68%);
} */
@keyframes ct_eko_shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Home New Slider css S */
.ct_slider_inner_cnt img {
  width: 100%;
  height: 760px;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.4;
  filter: brightness(0.5);
  transition: 5s ease;
}

.ct_slider_inner_cnt img:hover {
  transform: scale(1.3);
}

.ct_slider_inner_cnt figure {
  margin: 0px;
}

.ct_slider_inner_cnt figcaption {
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%, 0%);
  text-align: center;
  /* padding: 2rem; */
  padding: 45px 50px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-width: 890px;
}

.cti_custom_class .owl-item.active .ct_slider_inner_cnt figcaption {
  visibility: visible;
  top: 18%;

  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible;
  opacity: 1;
  margin-top: 16px;
}

@keyframes slideExpandUp {
  0% {
    transform: translate(-50%, 140%) scaleX(0.5);
  }

  30% {
    transform: translate(-50%, -8%) scaleX(0.5);
  }

  40% {
    transform: translate(-50%, 2%) scaleX(0.5);
  }

  50% {
    transform: translate(-50%, 0%) scaleX(1.1);
  }

  60% {
    transform: translate(-50%, 0%) scaleX(0.9);
  }

  70% {
    transform: translate(-50%, 0%) scaleX(1.05);
  }

  80% {
    transform: translate(-50%, 0%) scaleX(0.95);
  }

  90% {
    transform: translate(-50%, 0%) scaleX(1.02);
  }

  100% {
    transform: translate(-50%, 0%) scaleX(1);
  }
}

.ct_slider_inner_cnt h4 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400 !important;
  line-height: 35px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Inter", sans-serif !important;
}

.ct_slider_inner_cnt h2 {
  font-family: "Inter", sans-serif !important;
  line-height: 77px;
  font-weight: 700 !important;
  font-size: 46px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.ct_slider_inner_cnt p {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Inter", sans-serif !important;
}

.ct_home_slider_bg {
  height: 700px;
}

.ct_home_slider_bg .owl-dots .owl-dot span {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px !important;
  margin: 0px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span {
  background-color: transparent !important;
  border: 1px solid #fff;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  margin-bottom: 2px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active {
  margin-bottom: 30px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active {
  margin-bottom: 0px;
}

.ct_slider_inner_cnt:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #333;
}

.ct_nav_flex .owl-dots {
  display: none;
}

.ct_nav_flex .owl-nav {
  display: flex !important;
}

.cti_slider_btn {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cti_slider_btn a.btn_banner {
  color: #fff;
}

.btn_banner {
  background-color: #86af48;
  border-radius: 10px 0 10px 0;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  transition: all 0.7s ease-in-out;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: sans-serif;
  border: none;
  padding: 15px 30px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: #ffffffe6;
  font-size: 16px;
  font-weight: 400;
}

.btn_banner:hover:after,
.btn_banner:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn_banner:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.btn_banner:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.btn_banner:after,
.btn_banner:before {
  background: #373435;
}

.ct_slider_inner_cnt {
  position: relative;
  z-index: 2;
}

.content_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-right: none;
  border-bottom: none;
}

.content_border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-right: none;
  border-top: none;
}

.box_cut::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-left: none;
  border-top: none;
}

.box_cut::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-left: none;
  border-bottom: none;
}

.ct_home_slider_bg .cti_custom_class .owl-nav {
  display: none;
}

.cti_custom_class {
  position: unset !important;
}

.ct_home_slider_bg .cti_custom_class .owl-dots {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 1.5rem;
  transform: translateY(-50%) !important;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  display: block;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  margin-bottom: 10px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span {
  background: #7d7d7d;
  position: relative;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 25px;
  text-indent: 0;
  height: 0px;
  width: 1px;
  margin-left: 0;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span:before {
  height: 100px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active span:before {
  height: 0px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span,
.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:hover span {
  background: #fff !important;
}

@media screen and (max-width: 1023px) and (min-width: 991px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width: 990px) and (min-width: 768px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width: 1400px) {
  .timeline-badge {
    left: 0%;
  }

  .timeline:before,
  .timeline:after {
    left: 54px;
  }
}

@media screen and (max-width: 1326px) {
  .timeline-badge {
    left: -52px;
  }

  .timeline:before,
  .timeline:after {
    left: 54px;
  }
}

@media screen and (max-width: 1280px) {
  .timeline:after {
    display: none;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .timeline-badge {
    left: 0px;
    top: 29%;
  }

  .ct_fixed:before,
  .ct_fixed:after {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .ct_card_box {
    width: 220px;
    height: 220px;
  }

  .ct_slider_inner_cnt h2 {
    line-height: 58px;
  }

  .ct_card_box figcaption {
    font-size: 14px;
  }

  .intro_section .big {
    line-height: 58px !important;
  }
}

@media screen and (max-width: 991px) {
  .ct_about_main {
    position: relative;
    max-width: 470px;
    margin: auto;
  }

  .hero-wrap {
    margin-top: 90px !important;
  }

  .ct_card_box {
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
  }

  .ct_card_box figcaption {
    font-size: 16px;
  }

  .ct_our_mission_bg {
    height: 250px;

    margin-bottom: 24px;
  }

  .ct_our_mission_main {
    display: block;
    margin-inline: 15px;
  }

  .ct_our_mission_left_bg {
    max-width: 100%;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots {
    top: auto;

    right: auto;
    bottom: 30px;

    transform: translatex(-50%) !important;
    display: flex;
    left: 50%;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
    top: auto;
    bottom: 10px;
    height: 1px;
    width: 0px;
    left: 25px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span:before {
    width: 100px;
    height: 1px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active {
    margin-bottom: 0px;
    margin-right: 120px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active {
    margin-right: 0px;
  }

  /* .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
        bottom: 25px;
        height: 1px;
        width: 100px;
        top: auto;
    } */
  /* .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active{
        margin-bottom: 0px;
        margin-right: 30px;
    } */
  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
    margin-bottom: 0px;
  }

  .ct_srvc_card_box {
    height: 100%;
  }

  .ct_srvc_card_box .ct_srvc_btm_img img {
    height: 160px;
  }

  .ct_srvc_body {
    padding: 25px 0px 25px;
  }

  .ct_right_arrow {
    display: none;
  }

  .ct_slider_inner_cnt figcaption {
    width: 87%;
  }
}

@media screen and (max-width: 767px) {

  .project-wrap:before,
  .project-wrap:after,
  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_slider_inner_cnt h2 {
    font-size: 28px;
    line-height: 46px;
  }

  .ct_slider_inner_cnt p {
    font-size: 14px;
    line-height: 30px;
  }

  .about-img-wrap img {
    height: 250px;
  }

  .ct_srvc_card_box {
    height: auto;
  }

  br {
    display: none;
  }

  .timeline-badge {
    left: 50%;
    margin-left: 0;

    transform: translateX(-50%);
  }

  .timeline-badge.active {
    transform: translateX(-50%) !important;
    left: 50% !important;
  }

  .ct_carbon_neutral_nwsltr {
    grid-template-columns: repeat(1, 1fr);
  }

  .ct_carbon_neutral_nwsltr_cnt {
    padding-block: 40px;
    padding-top: 20px;
  }

  .timeline {
    padding-left: 0px;
  }

  .ct_srvc_body {
    padding-bottom: 5px;
  }

  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_scroll_fixed_img {
    margin-bottom: 30px;
  }

  .ct_scroll_fixed_cnt {
    padding-inline: 0px;
    margin-bottom: 30px;
  }

  .ct_globel_partner_bg {
    margin-top: -174px;
  }

  .ct_green_background {
    padding-bottom: 220px !important;
  }

  .ct_globel_logo_img img {
    height: 64px;
    -o-object-fit: contain;
       object-fit: contain;
    margin: auto;
    display: block;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-inline: 15px;
  }

  .ct_globel_logo_img {
    margin-bottom: 24px;
  }

  .vertical-item.gallery-item .item-media img {
    height: 200px;
    width: 100%;
    max-width: 100%;
    margin-inline: 0px;
  }

  .team-card.style3 img {
    height: 288px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
    margin: auto !important;
    display: block;
  }

  .ct_ddirector_info blockquote {
    font-size: 14px;
  }

  .ct_climate_crisis_cnt {
    padding: 30px;
  }

  .ct_our_mission_left_bg {
    padding-block: 30px;
  }

  .intro_section .big {
    line-height: 46px !important;
  }

  .intro_section .big {
    font-size: 28px;
  }

  .fontsize_20 {
    font-size: 18px;
  }

  .switch-theme-mode.ct_mob_light_mode {
    top: 130px !important;
  }

  .ct_ceo_img img {
    width: 150px;
    height: 150px;
  }

  .ct_mob_none {
    display: none !important;
  }

  .ct_ceo-card_main:before {
    height: 200px;
  }

  .ct_ceo-card_main {
    margin-bottom: 30px;
  }

  .ct_srvc_icon {
    width: 50px;
    height: 50px;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
  }

  .owl-carousel.projects-carousel {
    margin-top: 15px;
  }

  .ct_nav_flex .owl-dots {
    display: none !important;
  }

  .ct_nav_flex .owl-nav {
    display: flex;
  }

  .ct_project_main_div .owl-carousel .owl-nav button.owl-prev,
  .ct_project_main_div .owl-carousel .owl-nav button.owl-next {
    width: 60px !important;
    height: 40px;
  }

  .ct_text_short {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_show_full {
    overflow: auto;
    white-space: normal;
  }

  .ct_more_link {
    color: #86af48;
    font-weight: 500;
    font-size: 16px;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_sub_heading {
    font-size: 18px;
  }

  .ct_srvc_icon img {
    width: 40px;
  }

  .ct_dashboard_icon {
    width: 70px;
    height: 70px;
  }

  .ct_dashboard_icon svg {
    width: 50px;
    height: 50px;
  }

  .intro_section .fontsize_16 {
    line-height: 30px;
    font-size: 14px;
  }

  .fontsize_20 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .timeline>li {
    display: block;
  }

  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
  }

  .timeline-title {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
    line-height: 36px;
  }

  .ct_scrollsection {
    min-height: auto;
  }

  .ct_scrollsection__img__inner {
    height: auto;
    position: unset;
    width: 100%;
  }

  .ct_scrollsection__body {
    position: unset;
    transform: unset;
    margin-top: 30px;
  }

  .ct_neutral_left_img {
    max-width: 360px;
    height: 360px;
  }

  .ct_eko_spark_mob img {
    width: 200px;
  }

  /* .ct_eko_points_bg:after{
    display: none;
} */
  .ct_eko_spark_mob img {
    position: unset;
    margin: auto;
    display: block;
  }

  .ct_eko_points_bg {
    height: 90vh !important;
    padding-bottom: 220px !important;
  }

  .ct_eko_spark_bg_title {
    margin-top: 0px;
    text-align: center;
  }

  .ct_eko_points_bg:after {
    height: 60px !important;
  }
}

@media screen and (max-width: 575px) {
  #ct_book_demo .modal-dialog {
    margin-inline: 25px;
  }

  .ct_slider_inner_cnt figcaption {
    top: 10% !important;
  }
}

@media screen and (max-width: 500px) {
  .ct_slider_inner_cnt .content_border {
    padding: 20px !important;
  }
}

/* OUR APPROACH */

.cti_contact_bg {
  background-image: url(/static/media/approach-banner.4131a079.jpg);
  background-position: center;
  background-size: cover;
  padding: 192px 0 156px;
}

.cti-banner-text h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 1.3;
  color: #363636;
}

.cti-banner-text span {
  color: #86af48;
}

.cti-banner-text p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
  padding-top: 0.7rem;
}

.cti-breadcrumb-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

.cti-carbon-section {
  padding: 56px 0;
}

.cti_carbon_subSection h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  color: #373435;
  font-family: "Inter", sans-serif;
}

.cti_carbon_subSection span {
  color: #86af48;
  font-weight: 600;
}

.cti_offsetting_content p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.cti_description {
  color: #a0a0a0;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 455px;
  margin-top: 1rem;
}

.cti_offsetting_subSection h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  color: #373435;
  font-family: "Inter", sans-serif;
}

.cti_offsetting_subSection .bold_class {
  font-weight: 700;
  color: #86af48;
}

.carbon_offsetting_section {
  padding: 80px 0;
}

.cti_offsetting_content-1 .cti_para {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.cti_offsetting_content-1 .cti_para_2 {
  font-weight: 500;
  font-size: 15px;
  color: #999;
}

.arrow_image i {
  font-size: 4rem;
  color: #86af48;
}

.cti_offset_work {
  padding: 22px;
  display: flex;
  align-items: flex-start;
  grid-gap: 18px;
  gap: 18px;
}

.cti_offset_work img {
  background: #86af48;
  width: 40px;
  padding: 6px;
  border-radius: 4px;
  margin-top: 10px;
}

.cti_offset_work h5 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.9;
}

.cti_arrow_down_blue i {
  font-size: 4rem;
  color: #0d6efdc2;
}

.cti_arrow_only i {
  font-size: 4rem;
  color: #86af48;
}

.cti_cards_content {
  color: #999;
}

.cti_contact_bg_Sub_footer {
  background-image: url(/static/media/approch_climate_change.5a1bbcf0.jpg);
  background-position: center;
  background-size: cover;
  padding: 192px 0 156px;
}

.cti_sub_footer_text {
  background-color: #fff;
  padding: 1.6rem 3rem;
  border-radius: 10px;
  max-width: 100%;
  filter: opacity(0.9);
}

.cti_sub_footer_text p {
  font-size: 24px;
  color: #566a7f;
}

.sub-footer-textP {
  font-size: 15px !important;
  color: #a0a0a0 !important;
  font-weight: 600;
}

.cti_subFooter {
  color: #86af48;
  font-weight: 700;
}

/* FAQS CSS */
.faq_section {
  padding-block: 4rem;
}

.cti_faq_heading {
  position: relative;
}

.cti_faq_heading:before {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: #86af48;
  left: 37%;
  top: 50%;
  position: absolute;
}

.cti_faq_heading:after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: #86af48;
  right: 37%;
  top: 50%;
  position: absolute;
}

.main_child_accor {
  margin-bottom: 1rem;
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.12);
}

.main_child_accor a:hover {
  color: #fff !important;
}

.cti_custome {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 10px;
}

div.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.collapse_arrow {
  width: 24px !important;
}

.ant-collapse {
  border: none !important;
}

.ant-collapse-item {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  padding-block: 10px;
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.12);
}

/* GIFT CSS */
.cti_gift_section {
  padding-block: 3rem;
}

.cti_main_heading p {
  font-size: 32px;
  font-weight: 500;
  color: #373435;
  text-transform: uppercase;
}

.cti_main_heading span {
  color: #86af48;
  font-weight: 700;
}

/* .cti_main_heading p::before {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: #86af48;
    position: absolute;
    top: 50%;
    left: 37%;
  }
  .cti_main_heading p::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: #86af48;
    position: absolute;
    top: 50%;
    right: 37%;
  } */
.cti_gift_para {
  font-size: 1.475rem;
  color: #697a8d;
  font-weight: 600;
  margin: 0;
}

.cti_gift_para1 {
  color: #88ae47;
  font-weight: 600;
  font-size: 1.475rem;
  line-height: 1.4;
}

.cti_gift_para2 {
  color: #9e9e9e;
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 73%;
}

.cti_right_image {
  background: #dee2e68c;
  padding: 2rem 0rem;
  border-radius: 10px;
}

.cti_right_image img {
  max-width: 80%;
}

/* PROJECT CSS */
.ct_project_bg1 {
  background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
}

.ct_how_redeem_gift_bg_1 {
  background-image: url(/static/media/thread_image.64696000.jpeg);
}

.ct_faq_bg_1 {
  background-image: url(/static/media/man-with-tablet-world-drawn-background.5f806789.jpg);
}

.cti_standard__img {
  border: 1px solid #9999995c;
  padding: 1rem;
  border-radius: 8px;
}

.cti_support_text {
  max-width: 60%;
  margin: 0 auto !important;
}

.cti_cutome_card {
  position: relative;
  border: none;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.cti_card_title {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 0px;
  padding-top: 8px;
}

.cti_card_title_1 p {
  font-size: 13px;
}

.cti_cutome_card img {
  border-radius: 10px;
  aspect-ratio: 4/3;
  -o-object-fit: cover;
     object-fit: cover;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cti_cutome_card:hover img {
  opacity: 0.3;
  transition: all ease 1s;
}

.cti_cutome_card:hover .middle {
  opacity: 1;
}

.text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.cti_card_title_1 i {
  color: #a0a0a0;
  font-size: 20px;
}

/* MEDAI QUERY */
@media (max-width: 576px) {
  .cti-banner-text h1 {
    font-size: 28px;
  }

  .cti-banner-text p {
    font-size: 13px;
  }

  .cti_offset_work h5 {
    line-height: 1.6;
  }

  .cti_carbon_subSection h3 {
    font-size: 18px;
  }

  .cti_main_heading p {
    font-size: 23px;
  }

  .carbon_offsetting_section {
    padding: 40px 0;
  }

  .cti_offsetting_subSection h2 {
    font-size: 18px;
  }
}

.ct_life_cycle_img img {
  border-radius: 70px 180px 345px 327.5px;
}

.ct_assessment_img img {
  width: 360px;
  display: block;
  margin-inline: auto;
  margin-top: 100px;
}

@media screen and (max-width:1023px) and (min-width:991px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width:990px) and (min-width:768px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}


@media screen and (max-width:1400px) {
  .timeline-badge {

    left: 0%;

  }

  .timeline:before,
  .timeline:after {

    left: 54px;

  }
}

@media screen and (max-width:1326px) {
  .timeline-badge {

    left: -52px;

  }

  .timeline:before,
  .timeline:after {

    left: 54px;

  }
}

@media screen and (max-width:1280px) {

  .timeline:after {

    display: none;

  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .timeline-badge {
    left: 0px;
    top: 29%;
  }

  .ct_fixed:before,
  .ct_fixed:after {
    display: none;
  }

}

@media screen and (max-width:1199px) {
  .ct_card_box {
    width: 220px;
    height: 220px;
  }

  .ct_card_box figcaption {
    font-size: 14px;
  }

  .intro_section .big {

    line-height: 58px !important;

  }
}

@media screen and (max-width:991px) {
  .ct_about_main {
    position: relative;
    max-width: 470px;
    margin: auto;
  }

  .hero-wrap {
    margin-top: 90px !important;
  }

  .ct_card_box {
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
  }

  .ct_card_box figcaption {
    font-size: 16px;
  }

  .ct_our_mission_bg {
    height: 250px;

    margin-bottom: 24px;
  }

  .ct_our_mission_main {
    display: block;
    margin-inline: 15px;
  }

  .ct_our_mission_left_bg {
    max-width: 100%;
  }

  /*   div#canvas {*/
  /*    margin-top: 95px !important;*/
  /*}*/
  .ct_srvc_card_box {
    height: 100%;
  }

  .ct_srvc_card_box .ct_srvc_btm_img img {
    height: 160px;

  }

  .ct_srvc_body {
    padding: 25px 0px 25px;
  }

  .ct_right_arrow {
    display: none;
  }
}

@media screen and (max-width:767px) {
  .ct_chain_connection:after {
    width: 12px;
    height: 11px;
    top: auto;
    transform: translateY(-50%);
    left: 50%;
    -webkit-box-reflect: right -3px;
    z-index: -1999;
    transform: translateX(-50%) rotate(90deg);
    right: auto;
  }

  .project-wrap:before,
  .project-wrap:after,
  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .about-img-wrap img {
    height: 250px;

  }

  .ct_srvc_card_box {
    height: auto;
  }

  br {
    display: none;
  }

  .timeline-badge {

    left: 50%;
    margin-left: 0;

    transform: translateX(-50%);
  }

  .timeline-badge.active {
    transform: translateX(-50%) !important;
    left: 50% !important;
  }

  .ct_carbon_neutral_nwsltr {
    grid-template-columns: repeat(1, 1fr);

  }

  .ct_carbon_neutral_nwsltr_cnt {
    padding-block: 40px;
    padding-top: 20px;
  }

  .timeline {

    padding-left: 0px;
  }

  .ct_srvc_body {
    padding-bottom: 5px;
  }

  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_scroll_fixed_img {
    margin-bottom: 30px;
  }

  .ct_scroll_fixed_cnt {
    padding-inline: 0px;
    margin-bottom: 30px;
  }

  .ct_globel_partner_bg {

    margin-top: -174px;

  }

  .ct_green_background {

    padding-bottom: 220px !important;
  }

  .ct_globel_logo_img img {

    height: 64px;
    -o-object-fit: contain;
       object-fit: contain;
    margin: auto;
    display: block;
  }


  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-inline: 15px;
  }

  .ct_globel_logo_img {
    margin-bottom: 24px;
  }

  .vertical-item.gallery-item .item-media img {
    height: 200px;
    width: 100%;
    max-width: 100%;
    margin-inline: 0px;
  }

  .team-card.style3 img {
    height: 288px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
    margin: auto !important;
    display: block;
  }

  .ct_ddirector_info blockquote {
    font-size: 14px;
  }

  .ct_climate_crisis_cnt {
    padding: 30px;
  }

  .ct_our_mission_left_bg {

    padding-block: 30px;
  }

  .intro_section .big {
    line-height: 46px !important;

  }

  .intro_section .big {
    font-size: 28px;
  }

  .fontsize_20 {
    font-size: 18px;
  }

  .ct_ceo_img img {
    width: 150px;
    height: 150px;
  }

  .ct_mob_none {
    display: none !important;
  }

  .ct_ceo-card_main:before {
    height: 200px;
  }

  .ct_ceo-card_main {

    margin-bottom: 30px;
  }

  .ct_srvc_icon {
    width: 50px;
    height: 50px;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
  }

  .owl-carousel.projects-carousel {
    margin-top: 15px;
  }

  .ct_project_main_div .owl-carousel .owl-nav button.owl-prev,
  .ct_project_main_div .owl-carousel .owl-nav button.owl-next {
    width: 60px !important;
    height: 40px;
  }

  .ct_text_short {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_show_full {
    overflow: auto;
    white-space: normal;
  }

  .ct_more_link {
    color: #86af48;
    font-weight: 500;
    font-size: 16px;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_sub_heading {
    font-size: 18px;
  }

  .ct_srvc_icon img {

    width: 40px !important;
  }

  .ct_dashboard_icon {

    width: 70px;
    height: 70px;
  }

  .ct_dashboard_icon svg {
    width: 50px;
    height: 50px;
  }

  .intro_section .fontsize_16 {
    line-height: 30px;
    font-size: 14px;
  }

  .fontsize_20 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .timeline>li {
    display: block;
  }

  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    margin-bottom: 30px
  }

  .timeline-title {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
    line-height: 36px;
  }

  .ct_scrollsection {
    min-height: auto;
  }

  .ct_scrollsection__img__inner {
    height: auto;
    position: unset;
    width: 100%;
  }

  .ct_scrollsection__body {
    position: unset;
    transform: unset;
    margin-top: 30px;
  }

  .ct_neutral_left_img {

    max-width: 360px;
    height: 360px;

  }

  .ct_eko_spark_mob img {
    width: 200px;
  }

  /* .ct_eko_points_bg:after{
  display: none;
} */
  .ct_eko_spark_mob img {
    position: unset;
    margin: auto;
    display: block;
  }

  .ct_eko_points_bg {
    height: 90vh !important;
    padding-bottom: 220px !important;
  }

  .ct_eko_spark_bg_title {
    margin-top: 0px;
    text-align: center;
  }
}


@media screen and (max-width:575px) {
  #ct_book_demo .modal-dialog {
    margin-inline: 25px;
  }
}
$primary_font : 'Spline Sans',
sans-serif;
$secondary_font: 'Readex Pro', sans-serif;

$clr_white: #fff;
$clr_offwhite: rgba(255, 255, 255, 0.8);
$clr_black: #000;
$clr_emperor: #666666;
$clr_selago: #F4F7FE;
$clr_yellow: #FFC422;
$clr_gray: #888888;
$clr_albastor: #f9f9f9;
$clr_athens: #F0F4FF;
$clr_sand: #F5F5F5;
$clr_red: #FF3838;
$clr_lochi: #338F7A;
$clr_jaffa: #F59946;
$clr_cod: #181818;

/*---------------------------------
            General Styles 
-----------------------------------*/
* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

:focus,
button:focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
}

a,
button,
input[type="submit"] {
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

a {
    color: $clr_black;
    text-transform: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
}

a:hover,
a :focus {
    text-decoration: none;
    box-shadow: none;
    color: $clr_black;
}

a:focus {
    outline: 0 solid;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: 1.4;
    color: $clr_black;
    font-family: $secondary_font;

}

body {
    font-size: 16px;
    font-family: $primary_font;
    color: $clr_emperor;
}

p {
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
    color: $clr_emperor;
}

select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-repeat: no-repeat;
    background-size: 15px;
    background-position: calc(100% - 18px) 50%;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-200 {
    padding-top: 200px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-130 {
    padding-bottom: 130px;
}

.ptb-10 {
    padding-bottom: 10px;
    padding-bottom: 10px;
}

.ptb-20 {
    padding-bottom: 20px;
    padding-bottom: 20px;
}

.ptb-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.ptb-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.ptb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.ptb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.ptb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.ptb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.ptb-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mmt-10 {
    margin-top: -10px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mtb-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.z-0 {
    z-index: 0;
    position: relative;
}

#message {
    padding: 0;
    margin: 0;
}

.help-block {
    color: #E31C25;
}

.list-style {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }
}

.page-wrapper {
    overflow-x: hidden;
}

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/
@mixin transition($time) {
    transition: all $time ease-in-out;
}

@mixin transform($tr_amt) {
    transform: $tr_amt;
}

@mixin transform-origin($trl_amt) {
    transform-origin: $trl_amt;
    -webkit-transform-origin: $trl_amt;
    -moz-transform-origin: $trl_amt;
    -ms-transform-origin: $trl_amt;
    -o-transform-origin: $trl_amt;
}

@mixin transform-style($trm_amt) {
    transform-style: $trm_amt;
    -webkit-transform-style: $trm_amt;
    -moz-transform-style: $trm_amt;
    -ms-transform-style: $trm_amt;
    -o-transform-style: $trm_amt;
}

%flex {
    display: flex;
    flex-wrap: wrap;
}

%inline_flex {
    display: inline-flex;
    flex-wrap: wrap;
}

%flex_column_center {
    @extend %flex;
    flex-direction: column;
    justify-content: center;
}

%inline_column_center {
    @extend %inline_flex;
    flex-direction: column;
    justify-content: center;
}

%flex_column_between {
    @extend %flex;
    flex-direction: column;
    justify-content: space-between;
}

%flex_column_end {
    @extend %flex;
    flex-direction: column;
    justify-content: flex-end;
}

%align_center {
    @extend %flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

%align_start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%align_end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
}

%flex_justify_between {
    @extend %flex;
    justify-content: space-between;
}

%flex_justify_center {
    @extend %flex;
    justify-content: center;
}

%flex_justify_end {
    @extend %flex;
    justify-content: flex-end;
}

%flex_justify_start {
    @extend %flex;
    justify-content: flex-start;
}

%flex_start {
    @extend %flex;
    justify-content: space-between;
}

%flex_start {
    @extend %flex;
    justify-content: space-between;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}

%flex_between {
    @extend %flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

%flex_end {
    @extend %flex;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 85px;
    width: 85px;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
    border-radius: 50%;
    -ms-box-shadow: 0 0 0 0 #fff;
    -o-box-shadow: 0 0 0 0 #fff;
    box-shadow: 0 0 0 0 #fff;
    animation: ripple 3s infinite;
}

.play-now {
    position: relative;
    height: 85px;
    width: 85px;
    text-align: center;
    line-height: 92px;
    background-color: transparent;
    border: 3px solid $clr_white;
    color: #fff;
    z-index: 1;
    font-size: 28px;
    padding-left: 5px;
    display: block;
    transform-origin: center center;
    border-radius: 50%;
    margin: 0 auto;

    .ripple {
        &:before {
            animation-delay: 0.9s;
            content: "";
            position: absolute;
        }

        &:after {
            animation-delay: 0.6s;
            content: "";
            position: absolute;
        }
    }
}

@keyframes ripple {
    70% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}


.back-to-top {
    font-size: 24px;
    width: 45px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    display: none;
    position: fixed;
    bottom: 230px;
    right: 20px;
    z-index: 999;
    border-radius: 5px;
    background: $clr_lochi;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    @include transition(0.4s);

    &.open {
        bottom: 30px;
    }

    i {
        color: $clr_white;
    }
}


.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $clr_white;
    z-index: 20000;
    overflow-x: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader div {
    height: 12px;
    margin: 0 10px 0 0;
    border-radius: 50px;
    transform-origin: 50% 0;
    display: inline-block;
    animation: bouncing 1.4s linear infinite;
}

.loader div:nth-child(2) {
    animation-delay: 0.2s;
}

.loader div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bouncing {

    0%,
    100% {
        transform: translateY(0) scale(1, 1);
        animation-timing-function: ease-in;
    }

    45% {
        transform: translateY(80px) scale(1, 1);
        animation-timing-function: linear;
    }

    50% {
        transform: translateY(80px) scale(1.5, 0.5);
        animation-timing-function: linear;
    }

    55% {
        transform: translateY(80px) scale(1, 1);
        animation-timing-function: ease-out;
    }
}

/*---------------------------------
       Button & Link CSS
----------------------------------*/
.btn {
    display: inline-block;
    line-height: 26px;
    font-weight: 400;
    border-radius: 5px;
    text-align: center;
    @include transition(0.7s);
    position: relative;
    z-index: 1;
    border: none;
    padding: 15px 30px 15px;
    border-radius: 0;
    color: $clr_white;
    overflow: hidden;
    white-space: nowrap;

    i {
        position: relative;
        top: 3px;
        font-size: 16px;
        margin-left: 7px;
        @include transition(0.4s);
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 50%;
        height: 0;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        @include transition(0.4s);
    }

    &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        content: "";
        width: 50%;
        height: 0;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        @include transition(0.4s);
    }

    &:hover {
        color: $clr_white;

        &:after,
        &:before {
            width: 100%;
            height: 100%;
            visibility: visible;
            opacity: 1;
        }
    }

    &.style1 {
        background-color: $clr_lochi;
        border-radius: 15px 0 15px 0;

        &:after,
        &:before {
            background: $clr_black;
        }
    }

    &.style2 {
        background-color: $clr_jaffa;
        border-radius: 15px 0 15px 0;
        color: $clr_white;
        border-radius: 10px 0 10px 0;

        &:after,
        &:before {
            background: $clr_black;
        }

        &:hover {
            color: $clr_white;
        }
    }

    &.style3 {
        background-color: $clr_black;
        color: $clr_white;

        &:after,
        &:before {
            background: $clr_lochi;
        }

        &:hover {
            color: $clr_white;
        }
    }

    &.style4 {
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: $clr_black;

        &:after,
        &:before {
            background: $clr_lochi;
        }

        &:hover {
            color: $clr_white;
        }
    }

    &.style5 {
        background-color: transparent;
        border: 1px solid $clr_lochi;
        color: $clr_lochi;

        &:after,
        &:before {
            background: $clr_lochi;
        }

        &:hover {
            color: $clr_white;
        }
    }

    &.style6 {
        background-color: $clr_white;
        color: $clr_lochi;

        &:after,
        &:before {
            background: $clr_lochi;
        }

        &:hover {
            color: $clr_white;
        }
    }

}

.link {
    font-weight: 500;
    line-height: 26px;
    display: inline-block;
    position: relative;
    color: $clr_lochi;
    @include transition(0.4s);

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 0;
        height: 1px;
        background: $clr_lochi;
        visibility: hidden;
        opacity: 0;
        @include transition(0.4s);
    }

    i {
        position: relative;
        top: 3px;
        margin-left: 5px;
        font-size: 14px;
        @include transition(0.4s);
    }

    &.style1 {
        color: $clr_lochi;
    }

    &:hover {
        color: $clr_lochi;

        &:after {
            visibility: visible;
            opacity: 1;
            width: 100%;
        }

        i {
            margin-left: 10px
        }
    }
}

.owl-carousel {
    .owl-nav {
        @extend %flex;

        button {

            &.owl-prev,
            &.owl-next {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                text-align: center;
                background-color: transparent;
                border: 1px solid rgba(0, 0, 0, 0.1);
                @extend %flex_column_center;
                position: relative;
                overflow: hidden;
                z-index: 1;
                @include transition(0.4s);

                i {
                    font-size: 18px;
                    line-height: 0.8;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    color: $clr_lochi;
                    margin: 0 auto;
                    @include transition(0.4s);
                }

                &:hover {
                    background-color: $clr_lochi;
                    border-color: transparent;

                    i {
                        color: $clr_white;
                    }
                }
            }
        }
    }

    .owl-dots {
        button {
            &.owl-dot {
                display: inline-block;
                margin: 0 6px;
                position: relative;
                bottom: -5px;

                span {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.1);
                    position: relative;
                    @include transition(0.4s);
                }

                &.active {
                    span {
                        background: $clr_lochi;
                        width: 18px;
                        height: 18px;
                        top: 1px;
                    }
                }
            }
        }
    }
}

/*--------------------------------------
    Section Title,Overlay & Shape CSS
----------------------------------------*/
.section-title,
.content-title {
    position: relative;

    h2 {
        font-size: 40px;
        margin: 0;
    }

    p {
        margin: 12px 0 0;
    }

    span {
        display: inline-block;
        font-weight: 700;
        margin: 0 0 10px;
        position: relative;
        z-index: 1;
        line-height: 1;
        color: $clr_lochi;
        padding-bottom: 18px;

        img {
            position: absolute;
            bottom: 0;
            left: 50%;
            min-width: 150px;
            @include transform(translateX(-50%));
        }
    }

    &.style2 {
        span {
            color: $clr_offwhite;
        }

        h2 {
            color: $clr_white;
        }
    }

    &.style3 {
        span {
            display: block;
            color: $clr_jaffa;
            padding-bottom: 10px;

            &.bl-text {
                position: absolute;
                top: 42%;
                right: 20px;
                font-size: 65px;
                font-weight: 800;
                width: 100%;
                color: rgba(0, 0, 0, 0.08);
                line-height: 1;
                -webkit-text-stroke: 1px rgba(0, 0, 0, 0.09);
                -webkit-text-fill-color: transparent;
                @include transform(translateY(-50%));
            }
        }
    }

    &.style4 {
        span {
            display: block;
            color: $clr_jaffa;

            &.bl-text {
                position: absolute;
                top: 42%;
                right: 20px;
                font-size: 65px;
                font-weight: 800;
                width: 100%;
                color: rgba(255, 255, 255, 0.095);
                line-height: 1;
                -webkit-text-stroke: 1px rgba(255, 255, 255, 0.08);
                -webkit-text-fill-color: transparent;
                @include transform(translateY(-50%));
            }
        }

        h2 {
            color: $clr_white;
        }

        p {
            color: $clr_offwhite;
        }
    }

    &.style5 {
        span {
            display: block;
            color: $clr_jaffa;
            padding-bottom: 0;
        }

        h2 {
            color: $clr_white;
        }

        p {
            color: $clr_offwhite;
        }
    }
}

.bg-transparent {
    background-color: transparent;
}

.bg-black {
    background-color: $clr_black;
}

.bg-white {
    background-color: $clr_white;
}

.bg-athens {
    background-color: $clr_athens;
}

.bg-albastor {
    background-color: $clr_albastor;
}

.bg-sand {
    background-color: $clr_sand;
}

.bg-lochi {
    background-color: $clr_lochi;
}

.body_overlay {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    @include transition(0.4s);

    &.open {
        visibility: visible;
        opacity: 1;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.op-1 {
    opacity: 0.1;
}

.op-2 {
    opacity: 0.2;
}

.op-3 {
    opacity: 0.3;
}

.op-4 {
    opacity: 0.4;
}

.op-5 {
    opacity: 0.5;
}

.op-6 {
    opacity: 0.6;
}

.op-7 {
    opacity: 0.7;
}

.op-75 {
    opacity: 0.75;
}

.op-8 {
    opacity: 0.8;
}

.op-85 {
    opacity: 0.85;
}

.op-9 {
    opacity: 0.9;
}

.op-95 {
    opacity: 0.95;
}

.op-10 {
    opacity: 1;
}

.bg-f {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ddd;
}

.pos-rel {
    position: relative;
    z-index: 1;
}

.bounce {
    animation: float 1500ms infinite ease-in-out
}

.moveHorizontal {
    animation: moveHorizontal 3000ms infinite ease-in-out;
}

.moveVertical {
    animation: moveVertical 3000ms infinite ease-in-out;
}

.moveHorizontal_reverse {
    animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
}

.animationFramesTwo {
    animation: animationFramesTwo 20000ms infinite ease-in-out;
}

.flyLeft {
    animation: flyLeft 30000ms infinite linear;
}

.flyRight {
    animation: flyRight 30000ms infinite linear;
}

.flyUp {
    animation: flyUp 15000ms infinite linear;
}

.moveLeft {
    animation: moveLeft 30000ms infinite linear;
}

.moveRight {
    animation: moveRight 20000ms infinite linear;
}

.waving_left {
    animation: waving_left 5s infinite linear;
    @include transition(0.3s);
}

.waving_right {
    animation: waving_right 7s infinite linear;
    @include transition(0.3s);
}

.rotate {
    animation: rotation 20s infinite linear;
}

.rotate {
    animation: rotation 20s infinite linear;
}

@keyframes waving_left {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(5deg);
    }

    50% {
        -webkit-transform: rotate(7deg);
    }

    75% {
        -webkit-transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes waving_right {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(-3deg);
    }

    50% {
        -webkit-transform: rotate(-6deg);
    }

    75% {
        -webkit-transform: rotate(-3deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes moveLeft {
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes float {
    0% {
        @include transform(translateY(0px));

    }

    50% {
        @include transform(translateY(-5px));
    }

    100% {
        @include transform(translateY(0px));
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes moveHorizontal {
    0% {
        transform: translateX(0px)
    }

    50% {
        transform: translateX(-20px)
    }

    100% {
        transform: translateX(0px)
    }
}

@keyframes moveHorizontal_reverse {
    0% {
        transform: translateX(0px)
    }

    50% {
        transform: translateX(20px)
    }

    100% {
        transform: translateX(0px)
    }
}

@keyframes moveVertical {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(-20px)
    }

    100% {
        transform: translateY(0px)
    }
}

@keyframes flyLeft {
    0% {
        -webkit-transform: translate(0, 0) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(-25vw, 50px) rotate(25deg);
    }

    40% {
        -webkit-transform: translate(-50vw, -52px) rotate(40deg);
    }

    60% {
        -webkit-transform: translate(-65vw, 82px) rotate(30deg);
    }

    80% {
        -webkit-transform: translate(-80vw, -72px) rotate(15deg);
    }

    100% {
        -webkit-transform: translate(-100vw, 0px) rotate(0deg);
    }
}

@keyframes flyRight {
    0% {
        -webkit-transform: translate(0, 0) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(25vw, 50px) rotate(25deg);
    }

    40% {
        -webkit-transform: translate(50vw, -52px) rotate(40deg);
    }

    60% {
        -webkit-transform: translate(65vw, 82px) rotate(30deg);
    }

    80% {
        -webkit-transform: translate(80vw, -72px) rotate(15deg);
    }

    100% {
        -webkit-transform: translate(100vw, 0px) rotate(0deg);
    }
}

@keyframes flyUp {
    0% {
        transform: translate(0px, 0px)
    }

    25% {
        transform: translate(50px, -50px)
    }

    50% {
        transform: translate(100px, 60px)
    }

    75% {
        transform: translate(-50px, -25px)
    }

    100% {
        transform: translate(0px, 0px)
    }
}

/*-------------------------------
     Social Link  CSS
-------------------------------*/
.social-profile {
    li {
        display: inline-block;
        margin: 0 4px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
            text-align: center;
            border: 1px solid transparent;
            @include transition(0.3s);

            i {
                @include transition(0.3s);
                font-size: 16px;
                line-height: 0.8;
            }

            &:hover {
                border-color: transparent;
                color: $clr_lochi;
            }
        }
    }

    &.style1,
    &.style2,
    &.style3 {
        li {
            a {
                width: 35px;
                height: 35px;
                text-align: center;
                @extend %flex_column_center;
                @include transition(0.3s);
            }
        }
    }

    &.style1 {
        li {
            a {
                background: $clr_white;
                border-radius: 50%;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);

                i {
                    color: $clr_lochi;
                }

                &:hover {
                    background-color: $clr_lochi;
                    @include transform(translateY(-3px));

                    i {
                        color: $clr_white;
                    }
                }
            }
        }
    }

    &.style2 {
        li {
            a {
                background: $clr_albastor;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.25);

                i {
                    color: rgba(0, 0, 0, 0.3);
                }

                &:hover {
                    background-color: $clr_lochi;
                    @include transform(translateY(-3px));

                    i {
                        color: $clr_white;
                    }
                }
            }
        }
    }

    &.style3 {
        li {
            a {
                background: transparent;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.25);

                i {
                    color: rgba(0, 0, 0, 0.3);
                }

                &:hover {
                    background-color: $clr_lochi;
                    @include transform(translateY(-3px));

                    i {
                        color: $clr_white;
                    }
                }
            }
        }
    }
}

/*-------------------------------
        Pagination  CSS
-------------------------------*/
.page-nav {
    text-align: center;
    margin-top: 25px;

    li {
        margin: 0 5px;
        display: inline-block;

        a {
            display: block;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            font-size: 16px;
            line-height: 54px;
            font-weight: 600;
            opacity: 0.7;
            border: 1px solid rgba(0, 0, 0, 0.2);
            text-align: center;
            @include transition(0.4s);

            i {
                font-size: 18px;
                line-height: 1;
                margin: 0 auto;
                position: relative;
                top: 2px;
                @include transition(0.4s);
            }

            &.active,
            &:hover {
                color: $clr_white;
                opacity: 1;
                background-color: $clr_lochi;

                i {
                    color: $clr_white;
                }
            }
        }
    }
}



.content-wrapper {
    margin-top: 139px;
}

.breadcrumb-wrap {
    position: relative;
    padding: 100px 0;
    overflow: hidden;
    z-index: 1;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.7);
    }
}

.breadcrumb-title {
    position: relative;
    z-index: 1;
    width: 100%;

    h2 {
        font-size: 40px;
        font-weight: 600;
        margin: 0 0 15px;
        color: $clr_white;
    }

    .breadcrumb-menu {
        li {
            display: inline-block;
            margin: 0 10px;
            position: relative;
            color: $clr_white;
            font-weight: 500;

            &:after {
                position: absolute;
                top: 1px;
                right: -13px;
                content: "/";
                font-size: 16px;
                background-color: transparent;
                color: rgba(255, 255, 255, 0.9);
            }

            &:first-child {
                margin-left: 0;

                a {
                    padding-left: 0;
                }
            }

            &:last-child {
                margin-right: 0;
                color: $clr_lochi;

                &:after {
                    display: none;
                }
            }

            a {
                font-size: 16px;
                line-height: 22px;
                display: inline-block;
                padding: 0px 5px;
                transition: 0.3s;
                -webkit-transition: 0.3s;
                position: relative;
                color: $clr_white;

                &:hover {
                    color: $clr_lochi;
                }
            }
        }
    }
}

.content-feature-list {
    margin: 0 0 28px 0;

    li {
        position: relative;
        padding-left: 25px;
        margin: 0 0 18px;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            position: absolute;
            top: 6px;
            left: 0;
            color: $clr_lochi;
            font-size: 17px;
            line-height: 0.8;
        }
    }

}

/*---------------------------------------
        Sign In CSS
 ----------------------------------------*/
.login-header {
    margin: 0 0 30px;

    h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 10px;
    }

    span {
        color: $clr_white;
    }
}

.login-form-wrap {
    padding: 40px 40px 30px;
    margin-bottom: 25px;
    background: $clr_albastor;

    .login-form {
        .form-group {
            margin: 0 0 20px;

            label {
                font-size: 16px;
                color: $clr_emperor;
                margin: 0 0 15px;
                display: block;
            }

            input {
                border-radius: 5px;
                background: transparent;
                border: 1px solid rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 60px;
                padding: 10px 15px;
                border-radius: 5px;
            }
        }

        .btn {
            padding: 15px 35px;
        }
    }

    .link {
        &.style1 {
            color: $clr_lochi;
        }
    }
}

/*---------------------------
        Sidebar CSS
 ---------------------------*/
.sidebar {
    .sidebar-widget {
        margin: 0 0 30px;
        background-color: $clr_sand;
        position: relative;
        z-index: 1;
        padding: 25px;
        border-radius: 5px;

        &.style2 {
            background-color: transparent;
            padding: 0;
        }

        h4 {
            margin: 0 0 25px;
            font-size: 22px;
            position: relative;
            line-height: 1;
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid $clr_lochi;
        }

        &:last-child {
            margin: 0;
        }
    }
}

/*-------------------------------
       Search Box Widget CSS
-------------------------------*/
.search-box {
    position: relative;

    .form-group {
        position: relative;

        input {
            width: 100%;
            height: 54px;
            background: $clr_white;
            border: 1px solid $clr_athens;
            color: $clr_black;
            font-size: 15px;
            line-height: 21px;
            padding: 10px 60px 10px 20px;
            border-radius: 5px;

            &:focus {
                border-color: $clr_gray;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 18px;
            height: 100%;
            border: none;
            border-radius: 0 5px 5px 0;
            text-align: center;
            background: $clr_lochi;

            i {
                color: $clr_white;
                top: 4px;
                left: 1px;
                font-size: 20px;
                position: relative;
            }
        }
    }
}

/*-------------------------------
     Category Widget CSS
-------------------------------*/
.category-box {
    li {
        position: relative;

        &:first-child {
            a {
                padding-top: 0;

                i {
                    top: 5px;
                }
            }
        }

        &:last-child {
            a {
                border-bottom: none;
                padding-bottom: 0;

                i {
                    top: 13px;
                }
            }
        }

        a {
            color: $clr_emperor;
            font-weight: 500;
            padding: 8px 10px 8px 25px;
            display: block;
            @include transition(0.4s);

            i {
                position: absolute;
                top: 14px;
                left: 0;
                font-size: 16px;
                line-height: 0.8;
                color: $clr_lochi;
                @include transition(0.4s);
            }

            &:hover {
                color: $clr_lochi;
                padding-left: 28px;

                i {
                    color: $clr_lochi;
                }
            }
        }
    }
}

/*-------------------------------
     Contact Widget CSS
-------------------------------*/
.contact-widget {
    background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
    position: relative;
    padding: 60px 30px;
    z-index: 1;
    text-align: center;
    border-radius: 5px;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
    }

    h3 {
        color: $clr_white;
        margin: 0 0 15px;
        border: none;
        font-size: 22px;
    }

    p {
        color: $clr_offwhite;
        margin: 0 0 20px;
    }
}

/*-------------------------------
     Profile Widget CSS
-------------------------------*/
.profile-widget {
    position: relative;
    border-radius: 5px;

    img {
        border-radius: 5px;
    }

    .btn {
        position: absolute;
        bottom: 30px;
        left: 50%;
        @include transform(translateX(-50%));
    }
}

/*-------------------------------
     Taglist Widget CSS
-------------------------------*/
.tag-list {
    margin-top: -12px;

    li {
        display: inline-block;
        margin: 12px 8px 0px 0px;

        a {
            display: inline-block;
            font-size: 14px;
            line-height: 16px;
            padding: 9px 12px;
            color: $clr_emperor;
            background-color: transparent;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            @include transition(0.3s);

            &:hover {
                background-color: $clr_lochi;
                color: $clr_white !important;
                border-color: transparent;
            }
        }
    }
}

/*-------------------------------
     Register Widget CSS
-------------------------------*/
.register-widget {
    .form-group {
        margin-bottom: 20px;

        input,
        select,
        textarea {
            width: 100%;
            background-color: $clr_white;
            border: none;
            padding: 12px 15px;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.5);

            &::-moz-placeholder {
                opacity: 1;
            }

            &:-ms-input-placeholder {
                opacity: 1;
            }

            &::placeholder {
                opacity: 1;
            }
        }

        input,
        select {
            height: 58px;
        }

        textarea {
            height: 150px;
            resize: none;
        }
    }
}

/*-------------------------------
     Project Info Widget CSS
-------------------------------*/
.project-info-item-wrap {
    .project-info-item {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        h6 {
            margin: 0 0 8px;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

/*-------------------------------
     Event Info Widget CSS
-------------------------------*/
.event-info-item-wrap {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    .event-info-item {
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        font-size: 15px;
        padding-bottom: 20px;
        color: $clr_emperor;
        @extend %align_center;

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        span {
            color: $clr_emperor;
            font-size: 16px;
            font-weight: 400;
            width: calc(100% - 105px);
            margin-left: 5px;
            text-align: right;

            a {
                color: $clr_lochi;
            }
        }

        p {
            font-size: 16px;
            margin: 0;
            color: $clr_black;
            font-weight: 500;
            width: 100px;

            i {
                font-size: 16px;
                position: relative;
                color: $clr_lochi;
                top: 2px;
                margin-right: 7px;
            }
        }
    }
}

/*-------------------------------
    Popular post Widget CSS
-------------------------------*/
.pp-post-item {
    @extend %flex;
    margin: 0 0 20px;

    .pp-post-img {
        width: 85px;
        border-radius: 4px;

        img {
            border-radius: 4px;
        }
    }

    .pp-post-info {
        width: calc(100% - 100px);
        margin-left: 15px;
        margin-top: -3px;

        h6 {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 600;
            margin-bottom: 8px;

            a {
                color: $clr_black;
            }
        }

        .pp-post-date {
            font-size: 14px;
            line-height: 1;
            display: block;
            position: relative;
            padding-left: 22px;
            color: $clr_emperor;

            &:hover {
                color: $clr_lochi;
            }

            i {
                position: absolute;
                top: 1px;
                left: 0;
                font-size: 15px;
                line-height: 0.8;
                color: $clr_lochi;
            }
        }
    }

    &:last-child {
        margin: 0;
    }

    &:hover {
        .pp-post-info {
            h6 {
                a {
                    color: $clr_lochi;
                }
            }
        }
    }
}


.hero-wrap {
    position: relative;
    z-index: 1;
    margin-top: 139px;

    .hero-content {
        span {
            color: $clr_lochi;
            display: block;
            font-weight: 500;
            margin-bottom: 5px;
        }

        h1 {
            font-size: 48px;
            font-weight: 800;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 30px;
        }
    }

    &.style1 {
        background-color: $clr_sand;
        padding: 50px 0 0;
        overflow: hidden;

        .hero-shape-one,
        .hero-shape-two,
        .hero-shape-three,
        .hero-shape-four,
        .hero-shape-five,
        .hero-shape-six,
        .hero-skull-shape {
            position: absolute;
            z-index: -1;
        }

        .hero-shape-one {
            bottom: 0;
            right: 0;
        }

        .hero-shape-two {
            bottom: 0;
            left: 10px;
        }

        .hero-shape-three {
            top: 40px;
            left: 40px;
        }

        .hero-shape-four {
            top: 30px;
            right: 50px;
        }

        .hero-shape-five {
            top: 50px;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .hero-shape-six {
            bottom: 0;
            right: -10px;
        }

        .hero-skull-shape {
            bottom: -8px;
            right: 40%;
        }

        .hero-content {
            padding: 140px 0;
        }

        .hero-img-wrap {
            position: relative;
            bottom: -15px;
        }
    }

    &.style2 {
        position: relative;

        .hero-slide-item {
            padding: 120px 0 260px;
            position: relative;
            z-index: 1;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "";
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 41.6%, rgba(0, 0, 0, 0.03) 85%);
                z-index: -1;
            }

            .hero-content {
                span {
                    color: $clr_jaffa;
                }

                h1 {
                    color: $clr_white;
                }

                p {
                    color: $clr_offwhite;
                }
            }
        }
    }

    &.style3 {
        position: relative;
        z-index: 1;
        padding: 90px 0;
        overflow: hidden;
        background-color: $clr_sand;

        .hero-shape-one,
        .hero-shape-two,
        .hero-shape-three,
        .hero-shape-four {
            position: absolute;
            z-index: -1;
        }

        .hero-shape-one {
            top: 0;
            left: 0;
            height: 100%;
        }

        .hero-shape-two {
            bottom: 0;
            right: 15%;
        }

        .hero-shape-three {
            bottom: 0;
            right: 0;
        }

        .hero-shape-four {
            top: 20px;
            right: 0;
        }

        .hero-img-wrap {
            position: relative;
            z-index: 1;

            .hero-img-shape {
                position: absolute;
                bottom: 0;
                left: -100px;
                z-index: -1;
            }
        }
    }

    .hero-slider-thumbs {
        position: absolute;
        top: calc(50% + 140px);
        @include transform(translateY(-50%));
        z-index: 1;

        button {
            display: block;
            margin: 0 0 10px;
            border: none;
            background-color: transparent;
            width: 60px;
            height: 60px;
            border-radius: 50%;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                border-radius: 50%;
            }
        }
    }
}

.hero-slider-one {
    &.owl-carousel {
        .owl-nav {
            button {

                &.owl-prev,
                &.owl-next {
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                    background-color: $clr_white;
                    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
                    width: 55px;
                    height: 55px;

                    i {
                        color: $clr_lochi;
                    }

                    &:hover {
                        background-color: $clr_lochi;

                        i {
                            color: $clr_white;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 50%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

.hero-slider-one {
    &.owl-carousel {
        .owl-item {
            &.active {
                .hero-slide-item {
                    .hero-content {
                        span {
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            animation-name: fadeInUp;
                            animation-delay: .5s
                        }

                        h1 {
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            animation-name: fadeInUp;
                            animation-delay: .7s
                        }

                        p {
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            animation-name: fadeInUp;
                            animation-delay: .9s
                        }

                        .btn {
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            animation-name: fadeInUp;
                            animation-delay: 1.1s
                        }
                    }
                }
            }
        }
    }
}

/*-------------------------------
    Promo  Section CSS
-------------------------------*/
.promo-wrap {
    &.style2 {
        background-color: $clr_white;
        @extend %flex;
        position: relative;
        z-index: 1;
        margin-top: -150px;
        border-radius: 5px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.09);

        &:after {
            position: absolute;
            bottom: -10px;
            left: -10px;
            content: "";
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            border-radius: 5px;
            z-index: -1;
            border: 1px solid rgba(0, 0, 0, 0.06);
        }

        .promo-shape-one {
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }

        .promo-shape-two {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }

        .promo-card {
            width: 33.33%;
            padding: 70px 25px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            text-align: center;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-right: none;
                border-radius: 0 5px 5px 0;
            }

            .promo-icon {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                @extend %flex_column_center;
                border: 1px solid $clr_jaffa;
                margin: 0 auto 20px;

                i {
                    font-size: 44px;
                    line-height: 0.8;
                    color: $clr_jaffa;
                }
            }

            h3 {
                font-size: 22px;
            }
        }
    }
}

/*-------------------------------
    Project  Section CSS
-------------------------------*/
.project-card {
    margin-bottom: 25px;

    .project-info {
        h3 {
            font-size: 22px;
        }
    }

    .project-img {
        overflow: hidden;

        img {
            @include transition(0.7s);
        }
    }

    &:hover {
        .project-img {
            img {
                @include transform(scale(1.05));
            }
        }
    }

    &.style1 {
        .project-info {
            position: relative;
            z-index: 1;
            background-color: $clr_sand;
            padding: 25px;
            @include transition(0.4s);

            h3 {
                margin: 0 0 15px;
                @include transition(0.4s);
            }

            p {
                margin: 0 0 18px;
                @include transition(0.4s);

            }

            .project-shape {
                position: absolute;
                bottom: 0;
                right: 10px;
                z-index: -1;
                max-width: 200px;
                filter: brightness(0.1) invert(0);
                opacity: 0.06;
                @include transition(0.4s);
            }
        }

        &:hover {
            .project-info {
                background-color: $clr_lochi;

                h3 {
                    a {
                        color: $clr_white;
                    }
                }

                p {
                    color: $clr_offwhite;
                }

                .link {
                    &.style1 {
                        color: $clr_black;
                    }
                }

                .project-shape {
                    right: 0;
                    filter: brightness(1) invert(0);
                    opacity: 1;
                }
            }
        }
    }

    &.style2 {
        border-radius: 5px;
        position: relative;
        z-index: 1;
        background-color: transparent;

        img {
            border-radius: 5px;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 0;
            border-radius: 5px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2413165949973739) 58%);
        }

        .project-info {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 25px 25px;
            z-index: 1;
            width: 100%;

            h3 {
                font-weight: 600;
                margin-bottom: 18px;

                a {
                    color: $clr_white;

                    &:hover {
                        color: $clr_lochi;
                    }
                }
            }

            .fund-collection {

                .fund-raised,
                .fund-goal {
                    color: $clr_white;
                }
            }
        }
    }

    &.style2,
    &.style3 {
        .project-img {
            position: relative;
        }

        .like-btn {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 50px;
            height: 50px;
            border: none;
            border-radius: 50%;
            z-index: 2;
            background-color: $clr-white;
            @extend %flex_column_center;
            @include transition(0.4s);

            &:hover {
                background-color: $clr_lochi;

                i {
                    color: $clr_white;
                }
            }

            i {
                font-size: 26px;
                line-height: 0.8;
                margin: 0 auto;
                color: $clr_lochi;
                @include transition(0.4s);
            }
        }
    }

    &.style3 {
        background-color: $clr_white;
        border-radius: 5px;

        .project-img {
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
            }
        }

        .project-info {
            position: relative;
            z-index: 1;
            padding: 25px;
            @include transition(0.4s);

            h3 {
                margin: 0 0 15px;
                @include transition(0.4s);
            }

            p {
                margin: 0 0 7px;
                @include transition(0.4s);

            }

            .fund-collection {
                margin-bottom: 0;

                .fund-raised,
                .fund-goal {
                    color: $clr_emperor;
                }
            }
        }
    }

    &.style4 {
        background-color: $clr_white;
        @extend %align_center;
        background-color: $clr_white;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);

        .project-img {
            position: relative;
            width: 270px;
            border-radius: 5px 0 0 5px;

            img {
                border-radius: 5px 0 0 5px;
            }

            .like-btn {
                position: absolute;
                top: 25px;
                left: 25px;
                width: 50px;
                height: 50px;
                border: none;
                border-radius: 50%;
                z-index: 2;
                background-color: $clr-white;
                @extend %flex_column_center;

                i {
                    font-size: 26px;
                    line-height: 0.8;
                    margin: 0 auto;
                    color: $clr_lochi;
                }
            }
        }

        .project-info {
            position: relative;
            z-index: 1;
            padding: 25px 25px 25px 5px;
            width: calc(100% - 290px);
            margin-left: 20px;
            @include transition(0.4s);

            h3 {
                font-size: 22px;
                margin: 0 0 15px;
                @include transition(0.4s);
            }

            p {
                margin: 0 0 5px;
                @include transition(0.4s);

            }

            .fund-collection {

                .fund-raised,
                .fund-goal {
                    color: $clr_emperor;
                }
            }
        }
    }
}

.skills {
    .skillbar {
        position: relative;
        display: block;
        width: 100%;
        background: $clr_white;
        height: 10px;
        border-radius: 15px;

        .skillbar-bar {
            height: 10px;
            width: 0px;
            background: $clr_lochi;
            border-radius: 50px 0 0 50px;
        }
    }
}

.fund-collection {
    @extend %flex_between;
    margin: 0 0 10px;

    .fund-raised,
    .fund-goal {
        font-size: 14px;
    }
}

.progressbar-wrap {
    margin-bottom: 20px;

    .progress-bar {
        margin: 0 0 25px;
        overflow: hidden;
        background: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        .progress-content-outter {
            height: 12px;
            background-color: $clr_athens;
            border-radius: 5px;

            .progress-content {
                height: 12px;
                background-color: $clr_lochi;
                border-radius: 5px;
                width: 0%;
            }
        }

        .progress-title-holder {
            position: relative;
            margin: 0 0 45px;
            font-size: 14px;
            font-weight: 400;
            text-align: left;

            .progress-title {
                z-index: 100;
                font-weight: 600;
                margin: 0;
            }

            .progress-number-wrapper {
                width: 100%;
                z-index: 10;

                .progress-number-mark {
                    margin-bottom: 0;
                    border-radius: 3px;
                    border: 1px solid $clr_lochi;
                    position: absolute;
                    bottom: -35px;
                    color: $clr_lochi;
                    padding: 3px 6px;
                    border-radius: 5px;
                    @include transform(translateX(-100%));

                    &:after {
                        position: absolute;
                        bottom: -8px;
                        left: 15px;
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 8px solid $clr_lochi;
                        border-left: 0px solid transparent;
                        border-right: 8px solid transparent;
                    }

                    .percent {
                        font-weight: 500;
                        font-size: 14px;
                        position: relative;
                        color: $clr_black;
                    }
                }
            }
        }
    }
}

.project-slider-one,
.project-slider-two,
.project-slider-three {
    .project-card {
        margin: 0;
    }
}

.team-slider-one,
.team-slider-two,
.blog-slider-one,
.project-slider-one,
.project-slider-three {
    &.owl-carousel {
        .owl-nav {
            button {

                &.owl-prev,
                &.owl-next {
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                }

                &.owl-prev {
                    left: -65px;
                }

                &.owl-next {
                    right: -65px;
                }
            }
        }

    }
}

.team-slider-one,
.team-slider-two,
.blog-slider-one,
.project-slider-one,
.project-slider-two,
.project-slider-three {
    &.owl-carousel {
        .owl-dots {
            margin: 30px 0 0;
            text-align: center;
        }
    }
}

.project-search-form {
    .form-group {
        margin-bottom: 25px;
        position: relative;

        select,
        input {
            width: 100%;
            height: 58px;
            background-color: $clr_sand;
            border-radius: 5px;
            padding: 12px 18px;
            color: $clr_black;
            border: none;

            &::-moz-placeholder {
                opacity: 0.7;
            }

            &:-ms-input-placeholder {
                opacity: 0.7;
            }

            &::placeholder {
                opacity: 0.7;
            }

            select {
                opacity: 0.7;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 15px;
            height: 100%;
            border: none;
            background-color: transparent;
        }
    }
}

/*-------------------------------
    About  Section CSS
-------------------------------*/
.about-wrap {
    position: relative;
    z-index: 1;

    .about-content {
        .content-title {
            margin-bottom: 25px;
        }

        .content-feature-list {
            margin-bottom: 30px;
        }
    }

    &.style1 {

        .about-shape-one,
        .about-shape-two,
        .about-shape-three {
            position: absolute;
            z-index: -1;
        }

        .about-shape-one {
            bottom: 0;
            right: 50px;
        }

        .about-shape-two {
            bottom: 180px;
            right: 20%;
        }

        .about-shape-three {
            bottom: 0;
            right: 0;
        }

        .about-img-wrap {
            position: relative;

            height: 630px;
            border-radius: 10px;

            .about-quote {
                position: absolute;
                bottom: 30px;
                left: 50%;
                width: calc(100% - 60px);
                @include transform(translateX(-50%));
                background-color: $clr_lochi;
                border-radius: 10px;
                padding: 30px;

                i {
                    color: $clr_jaffa;
                    position: absolute;
                    top: 35px;
                    left: 25px;
                    font-size: 38px;
                    line-height: 0.8;
                }

                p {
                    color: $clr_white;
                    margin: 0;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 44px;
                }
            }
        }
    }

    &.style2 {
        .about-shape-one {
            position: absolute;
            bottom: 60px;
            right: 50px;
            z-index: -1;
        }
    }

    &.style3 {
        .about-img-wrap {
            position: relative;
            z-index: 1;

            &:after {
                position: absolute;
                bottom: 0;
                left: 50%;
                content: "";
                width: calc(100% - 80px);
                height: 250px;
                background-color: $clr_sand;
                border-radius: 5px;
                z-index: -1;
                @include transform(translateX(-50%) rotate(2deg));
            }

            .about-shape-one {
                position: absolute;
                bottom: -20px;
                right: 20px;
                z-index: 1;
            }
        }
    }
}

/*-------------------------------
    Donate  Section CSS
-------------------------------*/
.donate-box {
    background-color: $clr_lochi;
    position: relative;
    z-index: 10;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    border-radius: 10px;

    .donate-shape-one {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .donate-amt {
        @extend %flex_justify_center;
        margin-bottom: 20px;

        li {
            margin-right: 20px;
            margin-bottom: 15px;

            &:last-child {
                margin-right: 0;
            }

            button {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.9);
                color: $clr_white;
                font-size: 18px;
                font-weight: 600;
                border-radius: 5px;
                padding: 18px 35px;
            }
        }
    }
}

.donation-form {
    background-color: $clr_sand;
    padding: 30px;
    border-radius: 5px;

    h2 {
        font-size: 26px !important;
        margin: 0 0 10px !important;
    }

    h4 {
        margin: 25px 0 25px;
    }

    .radionbtn-wrap {
        @extend %flex;

        .radionbtn {
            margin-right: 25px;
            margin-bottom: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .form-group {
        margin-bottom: 25px;

        input,
        select {
            width: 100%;
            height: 58px;
            background-color: $clr_white;
            border-radius: 5px;
            border: none;
            padding: 12px 16px;
            color: $clr_black;

            &::-moz-placeholder {
                opacity: 0.7;
            }

            &:-ms-input-placeholder {
                opacity: 0.7;
            }

            &::placeholder {
                opacity: 0.7;
            }
        }

        select {
            opacity: 0.7;
        }
    }

    .form-check {
        margin-bottom: 20px;
    }
}

/*-------------------------------
    Counter  Section CSS
-------------------------------*/
.counter-wrap {
    position: relative;
    z-index: 1;

    .counter-shape-one,
    .counter-shape-two,
    .counter-shape-three {
        position: absolute;
        z-index: -1;
    }

    &.style1 {
        position: relative;
        background-color: $clr_sand;
        margin-top: -200px;
        overflow: hidden;

        .counter-shape-one {
            bottom: 0;
            right: 0;
        }

        .counter-shape-two {
            bottom: 0;
            right: 220px;
        }

        .counter-shape-three {
            bottom: 0;
            right: -10px;
        }
    }
}

.promo-video {
    position: relative;
    z-index: 1;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: -1;
    }

    .play-now {
        width: 90px;
        height: 90px;
        top: 50%;
        font-size: 34px;
        line-height: 88px;
        @include transform(translateY(-50%));
        background-color: $clr_white;

        i {
            color: $clr_lochi;
            position: relative;
            left: -2px;
        }

        .ripple:after,
        .ripple:before,
        .ripple {
            width: 90px;
            height: 90x;
        }
    }

    &.style1 {
        background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
        height: 100%;
    }

    &.style2 {

        .play-now {
            position: relative;
            top: auto;
            margin-top: 50px;
            @include transform(translateY(0%));
        }
    }

    &.style3 {
        background-image: url(/static/media/promo-video-bg.ab87b504.jpg);
        height: 100%;

        .play-now {
            position: relative;
            top: auto;
            left: auto;
            @include transform(translateY(0));
            margin: 30px 0 0;
        }
    }
}

.counter-card-wrap {
    @extend %flex;
    margin: 0 -12.5px;

    &.style1 {
        padding: 300px 90px 125px 60px;

        .counter-card {
            position: relative;
            margin-bottom: 25px;
            background-color: $clr_white;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;
            padding: 50px 20px;
            max-height: 185px;
            text-align: center;

            &:nth-child(2),
            &:nth-child(4) {
                top: 50px;
            }

            .counter-text {
                h2 {
                    font-size: 46px;
                    line-height: 1;
                    margin: 0 0 10px;
                }

                p {
                    margin-bottom: 0;
                    line-height: 1;
                }
            }

            &.style1,
            &.style4 {
                .counter-text {
                    h2 {
                        color: $clr_jaffa;
                    }
                }
            }

            &.style2,
            &.style3 {
                .counter-text {
                    h2 {
                        color: $clr_lochi;
                    }
                }
            }

            &.style1 {
                border-radius: 50px 50px 0 50px;
            }

            &.style2 {
                border-radius: 50px 50px 50px 0;
            }

            &.style3 {
                border-radius: 50px 0 50px 50px;
            }

            &.style4 {
                border-radius: 0 50px 50px 50px;
            }
        }
    }

    &.style2 {
        margin: 0 -12.5px;
        padding: 0;

        .counter-card {
            @extend %align_center;
            padding: 40px 25px;
            background-color: rgba(255, 255, 255, 0.08);
            border: 1px solid rgba(255, 255, 255, 0.06);
            width: calc(25% - 25px);
            margin: 0 12.5px 25px;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $clr_white;
            }

            &.style1 {
                border-radius: 50px 50px 0 50px;

                &:after {
                    bottom: -5px;
                    left: 40%;
                }
            }

            &.style2 {
                border-radius: 50px 50px 50px 0;

                &:after {
                    top: -5px;
                    left: 40%;
                }
            }

            &.style3 {
                border-radius: 50px 0 50px 50px;

                &:after {
                    bottom: -5px;
                    left: 40%;
                }
            }

            &.style4 {
                border-radius: 0 50px 50px 50px;

                &:after {
                    top: 50%;
                    right: -5px;
                }
            }

            .counter-icon {
                width: 70px;

                i {
                    font-size: 68px;
                    line-height: 0.8;
                    color: $clr_jaffa;
                }
            }

            .counter-text {
                width: calc(100% - 90px);
                margin-left: 20px;

                h2 {
                    color: $clr_white;
                    font-size: 40px;
                    margin: 0 0 5px;
                }

                p {
                    color: $clr_offwhite;
                    margin: 0;
                }
            }
        }
    }

    &.style3 {
        padding-bottom: 25px;

        .counter-card {
            @extend %align_center;
            position: relative;
            background-color: $clr_white;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;
            padding: 50px 20px;
            border: 1px solid transparent;
            border-radius: 5px;

            &:nth-child(2),
            &:nth-child(4) {
                top: 50px;
            }

            .counter-icon {
                width: 80px;

                i {
                    font-size: 78px;
                    line-height: 0.8;
                    color: $clr_jaffa;
                }
            }

            .counter-text {
                width: calc(100% - 100px);
                margin-left: 20px;

                h2 {
                    font-size: 46px;
                    line-height: 1;
                    margin: 0 0 10px;
                    color: $clr_white;
                }

                p {
                    margin-bottom: 0;
                    line-height: 1;
                    color: $clr_offwhite;
                }
            }

            &:nth-child(1),
            &:nth-child(4) {
                background-color: $clr_lochi;
                border-color: transparent;
            }

            &:nth-child(2),
            &:nth-child(3) {
                background-color: transparent;
                border-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
    padding: 0 1px;
}

/*-------------------------------
    Event  Section CSS
-------------------------------*/
.event-wrap {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .event-sape-one {
        position: absolute;
        bottom: -10px;
        left: 20px;
        z-index: -1;
        opacity: 0.5;
    }

    .event-sape-two {
        position: absolute;
        top: 70px;
        left: 120px;
        z-index: -1;
    }

    .event-sape-three {
        position: absolute;
        bottom: -10px;
        right: 30px;
        z-index: -1;
        opacity: 0.5;
    }
}

.event-card {
    @extend %align_center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 25px;
    @include transition(0.4s);

    .event-img {
        position: relative;
        border-radius: 10px 0 0 10px;

        img {
            border-radius: 10px 0 0 10px;
        }

        .event-date {
            position: absolute;
            top: 20px;
            left: 0;
            background-color: $clr_jaffa;
            border-radius: 0 5px 5px 0;
            color: $clr_white;
            font-size: 15px;
            padding: 7px 15px;
        }
    }

    .event-info {
        @include transition(0.4s);

        h3 {
            font-size: 22px;
            margin: 0 0 15px;
            @include transition(0.4s);
        }

        p {
            @include transition(0.4s);
        }

        .event-metainfo {
            margin-bottom: 10px;

            li {
                display: inline-block;
                margin-right: 15px;
                margin-bottom: 5px;
                color: $clr_emperor;
                font-size: 15px;
                @include transition(0.4s);

                a {
                    color: $clr_emperor;
                    @include transition(0.4s);
                }

                &:last-child {
                    margin-right: 0;
                }

                i {
                    color: $clr_lochi;
                    position: relative;
                    top: 1px;
                    margin-right: 5px;
                    @include transition(0.4s);
                }
            }
        }

        p {
            margin: 0;
        }
    }

    &:hover {
        background-color: $clr_lochi;
        border-color: transparent;

        .event-info {
            h3 {
                a {
                    color: $clr_white;
                }
            }

            p {
                color: $clr_offwhite;
            }

            .event-metainfo {
                li {
                    i {
                        color: $clr_white;
                    }

                    a,
                    p {
                        color: $clr_offwhite;
                    }
                }
            }
        }
    }

    &.style1 {
        .event-img {
            width: 250px;
        }

        .event-info {
            width: calc(100% - 270px);
            padding: 20px 20px 20px 25px;
        }
    }

    &.style2,
    &.style3 {
        background-color: $clr_white;

        &:hover {
            background-color: $clr_lochi;
            border-color: transparent;
        }

        .event-info {
            margin-left: 20px;
            padding: 20px 20px 20px 5px;

            .event-metainfo {
                li {

                    a,
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &.style2 {
        .event-img {
            width: 210px;
        }

        .event-info {
            width: calc(100% - 230px);
        }
    }

    &.style3 {
        .event-img {
            width: 185px;
        }

        .event-info {
            width: calc(100% - 205px);
        }
    }

    &.style4 {
        .event-img {
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
            }
        }

        .event-info {
            padding: 24px 25px;
        }
    }

    &.style5 {
        background-color: $clr_sand;
        border: none;

        .event-img {
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
            }
        }

        .event-info {
            padding: 24px 25px;

            p {
                margin-bottom: 15px;
            }
        }

        &:hover {
            background-color: $clr_lochi;

            .link {
                color: $clr_white;
            }
        }
    }
}

.newsletter-form {
    &.style1 {
        margin-bottom: 25px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        padding: 50px 30px 58px;
        border-radius: 10px;
        background-color: $clr_white;

        h4 {
            font-size: 22px;
            margin: 0 0 10x;
            text-align: center;
        }

        p {
            margin: 0 0 20px;
            text-align: center;
        }

        .form-group {
            margin-bottom: 20px;

            input {
                width: 100%;
                background-color: $clr_albastor;
                height: 54px;
                padding: 12px 18px;
                border: none;
            }
        }

        button {
            width: 100%;
            display: block;
        }
    }
}

.event-search-form {
    background-color: $clr_sand;
    padding: 25px 25px 5px;
    border-radius: 5px;
    margin-bottom: 25px;

    .form-group {
        margin: 0 0 20px;

        label {
            font-weight: 600;
            color: $clr_black;
            margin: 0 0 10px;
            display: block;
        }

        select,
        input {
            width: 100%;
            height: 58px;
            background-color: $clr_white;
            border-radius: 5px;
            padding: 12px 18px;
            color: $clr_black;
            border: none;

            &::-moz-placeholder {
                opacity: 0.7;
            }

            &:-ms-input-placeholder {
                opacity: 0.7;
            }

            &::placeholder {
                opacity: 0.7;
            }

            select {
                opacity: 0.7;
            }
        }

        button {
            margin-top: 35px;
        }
    }
}

/*-------------------------------------
    Project & Event Details  CSS
----------------------------------------*/
.event-desc,
.project-desc {

    .single-project-img,
    .single-event-img {
        border-radius: 10px;
        margin: 10px 0 30px;
        display: block;

        img {
            border-radius: 10px;
        }
    }

    .progressbar-wrap {
        margin-top: -10px;
    }
}

.project-desc {
    .fund-collection {
        li {
            span {
                font-size: 20px;
                font-weight: 600;
                margin-right: 4px;
                color: $clr_black;
            }
        }
    }

    .wp-block-quote {
        box-shadow: none;
        background-color: $clr_sand;
        padding: 30px;
        border-left: 4px solid $clr_lochi;

        p {
            text-align: left;
            font-size: 16px;
            color: $clr_emperor;
            font-weight: 400;
            margin: 0;
        }
    }
}

/*-------------------------------
    Team  Section CSS
-------------------------------*/
.team-wrap {
    position: relative;
    z-index: 1;

    .team-shape-one {
        position: absolute;
        bottom: 0;
        left: 30px;
        z-index: -1;
    }
}

.team-card {
    margin-bottom: 25px;

    h3 {
        font-size: 22px;
    }

    &.style1 {
        position: relative;
        overflow: hidden;
        border-radius: 5px;

        img {
            border-radius: 5px 5px 0 0;
        }

        .team-info {
            background-color: $clr-white;
            text-align: center;
            padding: 25px;
            position: absolute;
            z-index: 1;
            bottom: -60px;
            left: 0;
            width: 100%;
            @include transition(0.4s);

            .team-shape {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            h3 {
                margin: 0 0 7px;
            }

            span {
                font-size: 14px;
                color: $clr_emperor;
            }

            .social-profile {
                margin-top: 20px;

                li {
                    a {
                        background-color: $clr_lochi;

                        i {
                            color: $clr_white;
                        }
                    }
                }
            }
        }

        &:hover {
            .team-info {
                bottom: 0;
            }
        }
    }

    &.style2 {
        border-radius: 5px;

        img {
            border-radius: 5px 5px 0 0;
        }

        .team-info-wrap {
            background-color: $clr_lochi;
            padding: 25px;
            @extend %align_center;
            @extend %flex_justify_between;
            @include transition(0.4s);
            border-radius: 0 0 5px 5px;
            position: relative;
            z-index: 1;

            .team-card-shape {
                position: absolute;
                bottom: 0;
                right: 0;
                max-width: 100px;
                z-index: -1;
            }

            .team-info {
                width: calc(100% - 60px);
                margin-right: 8px;

                h3 {
                    margin: 0 0 6px;

                    a {
                        color: $clr_white;
                    }
                }

                span {
                    color: $clr_offwhite;
                }
            }

            .social-link {
                cursor: pointer;
                position: relative;

                span {
                    display: inline-block;
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                    background-color: $clr_white;
                    text-align: center;

                    i {
                        color: $clr_lochi;
                        font-size: 22px;
                        line-height: 54px;
                        margin: 0 auto;
                    }
                }

                .social-profile {
                    position: absolute;
                    bottom: calc(100% + 15px);
                    left: 0;
                    @include transition(0.4s);

                    li {
                        visibility: hidden;
                        opacity: 0;
                        display: block;
                        margin: 10px 0;
                        @include transform(translateX(-10px));

                        a {
                            width: 52px;
                            height: 52px;

                            i {
                                font-size: 20px;
                            }
                        }

                        &:last-child {
                            margin: 0;
                        }

                        @include transition(0.4s);

                        &:nth-child(4) {
                            transition-delay: 0.2s;
                        }

                        &:nth-child(3) {
                            transition-delay: 0.3s;
                        }

                        &:nth-child(2) {
                            transition-delay: 0.4s;
                        }

                        &:nth-child(1) {
                            transition-delay: 0.5s;
                        }
                    }
                }

                &:hover {
                    .social-profile {
                        li {
                            visibility: visible;
                            opacity: 1;
                            @include transform(translateX(0));
                        }
                    }
                }
            }
        }

        &:hover {
            .team-info-wrap {
                background-color: $clr_jaffa;
            }
        }
    }

    &.style3 {
        position: relative;
        background-color: $clr_sand;
        @include transition(0.4s);

        img {
            border-radius: 5px;
            @include transition(0.4s);
        }

        .team-info {
            background-color: $clr_sand;
            text-align: center;
            position: absolute;
            z-index: 1;
            bottom: -75px;
            padding-top: 25px;
            padding-bottom: 25px;
            width: calc(100% + 5px);
            @include transition(0.4s);

            h3 {
                margin: 0 0 7px;
            }

            span {
                font-size: 14px;
                color: $clr_emperor;
            }

            .social-profile {
                margin-top: 20px;

                li {
                    a {
                        background-color: $clr_lochi;

                        i {
                            color: $clr_white;
                        }
                    }
                }
            }
        }

        &:hover {
            padding: 25px;
            background-color: $clr_white;

            img {
                @include transform(rotate(0deg));
            }

            .team-info {
                background-color: $clr_white;
                bottom: 0;
                left: 0;
                @include transform(rotate(0deg));

                h3 {
                    @include transform(rotate(0deg));
                }

                span {
                    @include transform(rotate(0deg));
                }
            }
        }
    }
}

.team-slider-one,
.team-slider-two {
    .team-card {
        margin: 0;

        &.style3 {
            margin: 10px 10px;
        }
    }
}

.team-slider-one {
    .owl-stage-outer {
        .owl-stage {
            .owl-item {
                &:nth-child(odd) {
                    .team-card {
                        &.style3 {
                            img {
                                @include transform(rotate(2deg));
                            }

                            .team-info {
                                @include transform(rotate(2deg));
                                bottom: -75px;
                                left: -7px;

                                h3 {
                                    @include transform(rotate(-2deg));
                                }

                                span {
                                    @include transform(rotate(-2deg));
                                }
                            }

                            &:hover {
                                padding: 20px;
                                background-color: $clr_white;

                                img {
                                    @include transform(rotate(0deg));
                                }

                                .team-info {
                                    background-color: $clr_white;
                                    bottom: 0;
                                    left: 0;
                                    @include transform(rotate(0deg));

                                    h3 {
                                        @include transform(rotate(0deg));
                                    }

                                    span {
                                        @include transform(rotate(0deg));
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(even) {
                    .team-card {
                        &.style3 {
                            img {
                                @include transform(rotate(-2deg));
                            }

                            .team-info {
                                @include transform(rotate(-2deg));
                                bottom: -75px;
                                right: -7px;

                                h3 {
                                    @include transform(rotate(2deg));
                                }

                                span {
                                    @include transform(rotate(2deg));
                                }
                            }

                            &:hover {
                                padding: 20px;
                                background-color: $clr_white;

                                img {
                                    @include transform(rotate(0deg));
                                }

                                .team-info {
                                    background-color: $clr_white;
                                    bottom: 0;
                                    left: 0;
                                    @include transform(rotate(0deg));

                                    h3 {
                                        @include transform(rotate(0deg));
                                    }

                                    span {
                                        @include transform(rotate(0deg));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*-------------------------------
    Team Details  Section CSS
-------------------------------*/
.team-desc {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .progressbar-wrap {
        margin-bottom: 25px;

        .progress-bar {
            margin-bottom: 20px;

            .progress-title-holder {
                margin-bottom: 20px;

                .progress-title {
                    position: relative;
                    top: 5px;
                    font-size: 18px;
                    font-weight: 500;
                }

                .progress-number-wrapper {
                    .progress-number-mark {
                        bottom: -7px;
                        border-color: $clr_jaffa;

                        .percent {
                            color: $clr_jaffa;
                        }

                        &:after {
                            border-top-color: $clr_jaffa;
                        }
                    }
                }
            }

            .progress-content-outter {
                border-radius: 0;

                .progress-content {
                    border-radius: 0;
                }
            }
        }
    }
}

.progess-bar-item {
    @extend %flex;
    @extend %flex_justify_between;
    @extend %align_center;
    margin: 25px 0 0;

    .single-skill {
        margin-bottom: 25px;
        text-align: center;

        p {
            margin: 15px auto 0;
        }

        .circlechart {
            text-align: center;
        }
    }
}

.progressbar {
    display: inline-block;
    width: 100px;
    margin: 25px;

    .circle {
        width: 100%;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        text-align: center;

        canvas {
            vertical-align: middle;
        }

        div {
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
        }

        strong {
            i {
                font-style: normal;
                font-size: 0.6em;
                font-weight: normal;
            }

            span {
                display: block;
                color: #aaa;
                margin-top: 12px;
            }
        }
    }
}

.circle-chart {
    width: 150px;
    height: 150px;
}

.circle-chart__circle {
    stroke: #00acc1;
    stroke-width: 1;
    stroke-linecap: square;
    fill: none;
    animation: circle-chart-fill 2s reverse;
    /* 1 */
    transform: rotate(-90deg);
    /* 2, 3 */
    transform-origin: center;
    /* 4 */
}

.circle-chart__subline {
    display: none;
}

.single-skill {
    .circle-chart__circle {
        stroke: $clr_jaffa;
        stroke-width: 2;
    }
}

.circle-chart__circle--negative {
    transform: rotate(-90deg) scale(1, -1);
}

.circle-chart__background {
    stroke: $clr_sand;
    stroke-width: 2;
    fill: none;
}

.circle-chart__info {
    animation: circle-chart-appear 2s forwards;
    opacity: 0;
    transform: translateY(20px);
}

.circle-chart__percent {
    text-anchor: middle;
    font-size: 6px;
    color: $clr_lochi;
    font-family: $secondary_font;
}

.circle-chart__subline {
    text-anchor: middle;
    font-size: 3px;
}

@keyframes circle-chart-fill {
    to {
        stroke-dasharray: 0 100;
    }
}

@keyframes circle-chart-appear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/*-------------------------------
    CTA  Section CSS
-------------------------------*/
.cta-wrap {
    overflow: hidden;

    &.style1,
    &.style2 {
        position: relative;
        background-image: url(/static/media/cta-bg-1.734645dd.jpg);
        z-index: 1;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 45%, rgba(255, 255, 255, 0.3) 94%);
        }

        .content-title {
            position: relative;
            z-index: 1;
        }

        .cta-btn {
            text-align: center;

            .btn {
                &:nth-child(1) {
                    margin-right: 15px;
                }
            }
        }

        .cta-shape-one {
            position: absolute;
            top: 50px;
            left: 50px;
            z-index: 0;
        }
    }

    &.style1 {
        position: relative;
        z-index: 1;

        .cta-shape-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

/*-------------------------------
    Blog  Section CSS
-------------------------------*/
.blog-wrap {
    .container {
        position: relative;
        z-index: 1;

        .blog-section-shape {
            position: absolute;
            bottom: -20px;
            left: -100px;
            z-index: -1;
        }
    }
}

.blog-card {
    border-radius: 5px;
    margin-bottom: 25px;
    @include transition(0.4s);

    .blog-info {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .blog-author {
            @extend %align_center;
            margin: 0 0 20px;

            .blog-author-img {
                width: 50px;
                height: 50px;
                border-radius: 50%;

                img {
                    border-radius: 50%;
                }
            }

            .blog-author-info {
                width: calc(100% - 65px);
                margin-left: 15px;

                h6 {
                    font-size: 16px;
                    margin: 5px 0 0;
                    @include transition(0.4s);

                }

                span {
                    font-size: 15px;
                    @include transition(0.4s);
                }
            }
        }

        h3 {
            font-size: 22px;
            font-weight: 600;
            @include transition(0.4s);
        }

        p {
            @include transition(0.4s);
        }

        .blog-metainfo {
            li {
                display: inline-block;
                margin-right: 15px;
                color: $clr_emperor;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-size: 15px;
                    position: relative;
                    padding-left: 22px;
                    color: $clr_emperor;
                    @include transition(0.4s);

                    i {
                        color: $clr_lochi;
                        position: absolute;
                        top: -4px;
                        left: 0;
                        font-size: 16px;
                        @include transition(0.4s);
                    }
                }
            }
        }

        .link {
            @include transition(0.4s);

            &:after {
                display: none;
            }
        }
    }

    &.style1 {
        background-color: $clr_sand;
        border-radius: 5px;

        .blog-info {
            padding: 25px;

            .blog-author {
                margin: 0 0 20px;
            }

            h3 {
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 15px;
            }
        }

        .link {
            margin: 0 auto;
            text-align: center;
            display: block;
            padding: 18px 15px;
            border-radius: 0 0 5px 5px;
        }

        &:hover {
            background-color: $clr_lochi;

            .blog-info {
                .blog-author {
                    .blog-author-info {
                        h6 {
                            a {
                                color: $clr_white;
                            }
                        }

                        span {
                            color: $clr_offwhite;
                        }
                    }
                }

                h3 {
                    a {
                        color: $clr_white;
                    }
                }

                p {
                    color: $clr_offwhite;
                }

                .blog-metainfo {
                    li {
                        color: $clr_offwhite;

                        i {
                            color: $clr_white;
                        }

                        a {
                            color: $clr_white;
                        }
                    }
                }
            }

            .link {
                background-color: $clr_jaffa;
                color: $clr_white;

                &:after {
                    display: none;
                }
            }
        }
    }

    &.style2 {
        background-color: $clr_white;

        .blog-info {
            padding: 25px;

            .blog-metainfo {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: 15px;
            }
        }

        .link {
            margin: 0 auto;
            text-align: center;
            display: block;
            padding: 18px 15px;
            border-radius: 0 0 5px 5px;
        }

        &:hover {
            .link {
                background-color: $clr_jaffa;
                color: $clr_white;
            }
        }
    }

    &.style3 {
        background-color: $clr_white;
        padding: 25px;

        .blog-img {
            position: relative;

            .blog-date {
                position: absolute;
                top: -25px;
                left: -25px;
                background-color: $clr_jaffa;
                padding: 15px 18px;
                border-radius: 5px 0 5px 0;
                color: $clr_white;
                text-align: center;

                span {
                    display: block;
                    font-weight: 600;
                }
            }
        }

        .blog-info {
            border-bottom: 0;
            padding: 25px 0 0;

            .blog-metainfo {
                margin-bottom: 0;
            }
        }

        .link {
            display: inline-block;
            text-align: left;
            padding: 0;

            &:after {
                display: none;
            }
        }
    }
}

.blog-slider-one {
    .blog-card {
        margin: 0;
    }
}

/*-------------------------------
         Contact CSS
-------------------------------*/
.contact-item-wrap {
    background-color: $clr_sand;
    padding: 25px 25px 30px;
    border-radius: 5px;

    .contact-item {
        @include transition(0.4s);
        margin-bottom: 25px;
        position: relative;
        z-index: 1;

        &:last-child {
            margin-bottom: 0;
        }

        h3 {
            font-size: 22px;
            margin: 0 0 12px;
        }

        p,
        a {
            display: block;
            color: $clr_emperor;
            margin-bottom: 0;
            @include transition(0.3s);

            &:hover {
                color: $clr_lochi;
            }
        }
    }
}

#contactForm {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px 25px 20px;
    border-radius: 5px;

    .form-group {
        margin: 0 0 20px;

        input,
        textarea {
            width: 100%;
            background: $clr_sand;
            border: none;
            padding: 10px 15px;
            border-radius: 5px;

            &:focus {
                border: 1px solid rgba(0, 0, 0, 0.09);
            }
        }

        input {
            height: 60px;
        }

        textarea {
            height: 150px;
            resize: none;
            padding-top: 18px;
        }
    }
}

.btn.disabled {
    opacity: 1;
}

#msgSubmit {
    font-size: 16px;
    margin-top: 10px;
}

.checkbox {
    display: block;

    &.form-check {
        padding-left: 0;
    }

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;
        padding-left: 20px;

        &:before {
            content: "";
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid $clr_lochi;
            display: inline-block;
            width: 15px;
            height: 15px;
            position: absolute;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            position: absolute;
            left: 0;
            top: 4px;
        }
    }

    a {
        color: $clr_lochi;
    }

    &.style2 {
        label {
            &:before {
                border-radius: 50%;
            }
        }
    }
}

.checkbox input:checked+label:before {
    border-color: $clr_lochi;
}

.checkbox input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 3px;
    width: 9px;
    height: 9px;
    border: none;
    background: $clr_lochi;
}

.checkbox.style2 input:checked+label:after {
    border-radius: 50%;
}

/*-------------------------------
   FAQ & Error Section  CSS
-------------------------------*/
.faq-wrap {
    position: relative;

    .accordion {
        margin-bottom: 25px;
    }
}

.accordion-item {
    border: none;
    margin: 0 0 25px;
    background: transparent;

    &:last-child {
        margin-bottom: 0;
    }

    .accordion-header {
        margin-bottom: 0;
        position: relative;
        z-index: 3;

        .faq-shape {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .accordion-button {
            border-radius: 5px 5px 0 0;
            margin: 0;
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
            border-radius: 5px;
            color: $clr_black;
            text-align: left;
            align-items: center;
            border-bottom: none;
            padding: 20px 45px 22px 20px;
            @include transition(0.4s);
            background-color: $clr_sand;
            box-shadow: none;

            &.collapsed {
                color: $clr_black;

                span {
                    background-color: $clr_lochi !important;
                }
            }

            &:after {
                display: none;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.collapsed {
                color: $clr_black;
                background-color: $clr_sand;

                span {
                    background-color: transparent !important;

                    i {
                        color: $clr_emperor;
                    }
                }
            }

            span {
                display: inline-block;
                min-width: 40px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                padding: 20px 20px;
                text-align: center;

                i {
                    position: absolute;
                    top: 28px;
                    right: 20px;
                    font-size: 24px;
                    line-height: 0.8;
                    color: $clr_white;
                    display: block;
                    margin: 0 auto;

                    &.minus {
                        visibility: hidden;
                        opacity: 0;
                    }

                    &.minus {
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .accordion-body {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-top: none;
        border-radius: 0 0 5px 5px;
        padding: 22px 20px 22px;

        p {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.accordion-collapse {
    border: none;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background: $clr_lochi !important;
    border-color: transparent;
    color: $clr_white;
}

.accordion-button:not(.collapsed) span i.plus {
    visibility: hidden;
    opacity: 0;
}

.accordion-button:not(.collapsed) span i.minus {
    visibility: visible !important;
    opacity: 1 !important;
}

.error-wrap {
    margin-top: 84px;
}

.error-content {
    text-align: center;

    img {
        display: block;
        margin: 0 auto 30px;
        max-width: 600px;
    }

    p {
        margin: 0 auto 20px;
        max-width: 520px;
    }
}

/*-------------------------------
    Blog Details CSS
--------------------------------*/
article,
.project-desc,
.event-desc,
.team-desc,
.terms-wrap {
    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            color: $clr_black;
        }

        a {
            color: $clr_lochi;

            &:hover {
                text-decoration: underline;
                color: $clr_lochi;
            }
        }
    }

    h1 {
        font-size: 32px;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }

    h4 {
        font-size: 26px;
        margin-bottom: 15px;
    }

    h5 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    h6 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    ol {
        margin-top: 20px;
        margin-bottom: 25px;

        li {
            margin-bottom: 15px;
            color: $clr_emperor;
            padding-left: 3px;
        }
    }

    .content-feature-list {
        margin: 25px 0 25px;
    }
}

article {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.post-metainfo {
    margin-bottom: 13px;

    li {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        margin-right: 20px;
        font-size: 15px;
        @include transition(0.3s);

        &:after {
            position: absolute;
            top: -1px;
            right: -15px;
            content: "/";
            font-size: 16px;
            color: $clr_emperor;
        }

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        a {
            font-size: 13px;
            color: $clr_emperor;
            @include transition(0.3s);

            &:hover {
                color: $clr_lochi;
            }
        }

        i {
            position: absolute;
            top: 5px;
            left: 0;
            font-size: 16px;
            line-height: 0.8;
            color: $clr_lochi;
            @include transition(0.3s);
        }
    }
}

.post-img {
    position: relative;
    margin: 0 0 25px;
    display: block;
    border-radius: 10px;

    img {
        border-radius: 10px;
    }
}

.wp-block-quote {
    position: relative;
    padding: 35px 35px 35px;
    background-color: $clr_sand;
    border-left: 5px solid $clr_lochi;
    margin: 30px 0 30px;
    z-index: 1;

    p {
        text-align: center;
        margin: 0;
    }
}

.post-meta-option {
    margin-bottom: 25px;
}

.post-tag {
    ul {
        display: inline-block;

        li {
            display: inline-block;

            a {
                color: #555;
                font-size: 16px;
                border-radius: 3px;

                &:hover {
                    color: $clr_black;
                }
            }
        }
    }

    span {
        margin-right: 10px;
        font-weight: 500;
        color: $clr_black;

        i {
            position: relative;
            top: 3px;
            color: $clr_lochi;
            margin-right: 3px;
        }
    }
}

.post-share {
    display: inline-block;

    span {
        font-weight: 500;
        color: $clr_black;
    }

    ul {
        display: inline-block;
        margin-left: 5px;
        position: relative;
        top: 2px;

        li {
            a {
                border: 1px solid $clr_lochi;
                box-shadow: none;

                &:hover {
                    border-color: transparent;
                }
            }
        }
    }
}

.post-author {
    @extend %flex;
    padding: 25px;
    margin: 15px 0 30px;
    border-radius: 5px;
    background-color: $clr_sand;

    .post-author-img {
        width: 150px;
        height: 150px;
        border-radius: 50%;

        img {
            border-radius: 50%;
        }
    }

    .post-author-info {
        width: calc(100% - 175px);
        margin-left: 25px;

        h4 {
            font-size: 16px;
            color: $clr_black;
            margin: 0 0 15px;
            font-weight: 400;

            a {
                font-size: 22px;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 15px;
                color: $clr_lochi;
            }
        }

        p {
            margin: 0 0 15px;
        }
    }
}

.comment-item-wrap {
    .comment-item {
        @extend %flex;
        padding: 0 0 25px 0;
        margin-bottom: 25px;
        border-radius: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        &.reply {
            margin-left: 40px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .comment-author-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            position: relative;
            top: 4px;

            img {
                border-radius: 50%;
            }
        }

        .comment-author-wrap {
            width: calc(100% - 120px);
            margin-left: 20px;

            .comment-author-name {
                margin: 0 0 9px;

                h5 {
                    line-height: 1;
                    margin: 0 20px 0 0;
                    font-size: 16px;
                    font-weight: 600;
                    display: inline-block;
                }

                .comment-date {
                    display: inline-block;
                    font-size: 14px;
                    font-family: $primary_font;
                    font-weight: 400;
                    line-height: 1;
                    color: $clr_emperor;
                    margin-left: 10px;
                }
            }

            .comment-text {
                margin: 0 0 10px;
            }
        }
    }

    .reply-btn {
        display: inline-block;
        line-height: 1;
        font-weight: 500;
        color: $clr_lochi;
        font-size: 14px;
        @include transition(0.3s);

        &:hover {
            color: $clr_lochi;
        }
    }
}

.comment-box-title {
    margin-bottom: 30px;

    h4 {
        margin-bottom: 0;
        line-height: 1;
    }

    p {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

#cmt-form {
    margin-top: 35px;

    .comment-form {
        .form-group {
            margin: 0 0 20px;
            position: relative;

            &>label {
                font-weight: 500;
                display: block;
                margin: 0 0 5px;
                padding-left: 0;
            }

            input,
            textarea {
                width: 100%;
                background-color: $clr_sand;
                border: none;
                border-radius: 5px;
                padding: 15px;
                color: $clr_black;

                &:focus {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
            }

            input {
                height: 60px;
            }

            textarea {
                height: 145px;
                resize: none;
            }
        }

        .form-check {
            margin-bottom: 25px;
        }
    }
}

.post-cat-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 25px;

    span {
        font-weight: 600;
        color: $clr_lochi;
    }
}

.post-cat-title {
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 25px;

    span {
        font-weight: 600;
        color: $clr_lochi;
    }
}

/*-------------------------------
    Buy Now Btn
-------------------------------*/
.buy-now-btn {
    img {
        top: 50%;
        left: 20px;
        width: 15px;
        position: absolute;
        transform: translateY(-50%);
    }

    right: 20px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: $clr_white;
    background-color: #82b440;
    padding: 10px 20px 10px 42px;
    box-shadow: 0 1px 20px 1px #82b440;
    font-size: 15px;
    font-weight: 600;

    &:hover {
        color: $clr_white;
        background-color: #94be5d;
    }
}
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Signup.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Core.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Demo.css');` */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/theme.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Flight.css'); */
/* @import url('../../blocks/dashboard/src/EkobonDashboard/Style/aos.css'); */

:root {
  --color-primary: #3f7a4f;
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;
  --color-light-gray-hover: #7c81884f;
  --color-individual: #88ae47;
  --color-individual-firstsquareT: #7f9065;
  --color-individual-secondsquareT: #96aeb4;
  --color-individual-firstsquare: #e8f0db;
  --color-individual-secondsquare: #e1f4fa;
  --color-individual-white: #f4f8ea;
  --color-individual-gray: #cbcbcb;
  --color-individual-gray1: #c3c3c3;
  --color-green-individual-main: #88ae47;
  --color-green-individual-backgroud: #f8f6f7;
  --color-tabgreen-individual-backgroud: #bae481;
  --color-individual-Inner-box: #e6f1cf;
  --color-individual-i-b-amount: #a4b0b2;
  --color-individual-i-b-amount1: #94bcbf;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* background-color: var(--color-individual-gray) !important; */
  -webkit-user-select: none;
          user-select: none;
}

iframe {
  pointer-events: none !important;
  /* overflow: hidden !important; */
}

#iframe2 {
  pointer-events: unset !important;
  z-index: 99 !important;
  border-radius: 15px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* overflow: hidden; */
}

.switch-theme-mode {
  position: fixed !important;
  top: 200px !important;
  right: 15px !important;
  z-index: 99 !important;
}

li {
  list-style: none;
}

.modal-content {
  border-radius: 10px;
}

.ct_tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

span.ct_tooltiptext.ct_tooltip_hiddien {
  display: none;
  visibility: unset !important;
  right: 4px;
  position: absolute !important;
  padding: 8px 12px;
  font-size: 12px;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.ct_tooltip a {
  line-height: 45px;
}

#g-recaptcha1 iframe {
  pointer-events: all !important;
}

.rc-anchor-logo-portrait {
  margin-left: 0px;
}

.rc-anchor-normal {
  height: 74px;
  width: 258px !important;
}

.en_position_reset_12 {
  position: unset !important;
  top: 0px !important;
  -webkit-transform: unset !important;
          transform: unset !important;
  left: 0px !important;
}

.ct_tooltip .ct_tooltiptext {
  visibility: hidden;
  /* width: 100%; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 6px;
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 10px;
  line-height: 23px;
  right: auto;
  margin-left: 20px;
}

.ct_tooltip:hover .ct_tooltiptext.ct_tooltip_hiddien {
  display: block;
}

.ct_tooltip:hover .ct_tooltiptext {
  display: block;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  /* .ct_login_width_bg {
    height: auto;
  } */

  .ct_login_tab a.nav-link {
    padding-block: 15px !important;
  }
}



.ant-input {
  border-radius: 10px !important;
}

/* .ant-select-selector {
  border-color: rgba(204, 204, 204, 0.5) !important;
  box-shadow: none !important;
} */
.ct_fs_18 {
  font-size: 18px;
}
