/* @import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;500;600;700&family=Spline+Sans:wght@300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
/*---------------------------------
            General Styles 
-----------------------------------*/

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: "Inter", sans-serif !important;
}

:focus,
button:focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

a,
button,
input[type="submit"] {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  font-family: "Inter", sans-serif;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  appearance: textfield;
}

a {
  color: #373435;
  text-transform: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover,
a :focus {
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #373435;
}

a:focus {
  outline: 0 solid;
}

img {
  max-width: 100%;
  height: auto;
}

a#toTop {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  line-height: 1.4;
  color: #373437;
  font-family: "Inter", sans-serif;
}

body {
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  color: #666666;
}

p {
  font-size: 16px;
  line-height: 1.8;
  color: #666666;
  text-align: center;
  /* font-weight: 500; */
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-image: url(../img/down-arrow.png); */
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 18px) 50%;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-130 {
  padding-bottom: 130px;
}

.ptb-10 {
  padding-bottom: 10px;
  padding-bottom: 10px;
}

.ptb-20 {
  padding-bottom: 20px;
  padding-bottom: 20px;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ptb-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ptb-100 {
  padding-top: 80px ;
  padding-bottom: 80px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mmt-10 {
  margin-top: -10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mtb-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.z-0 {
  z-index: 0;
  position: relative;
}

#message {
  padding: 0;
  margin: 0;
}

.help-block {
  color: #e31c25;
}

.list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-style li {
  list-style: none;
}

.page-wrapper {
  overflow-x: hidden;
}

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.team-card.style2 .team-info-wrap,
.progess-bar-item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.donate-box .donate-amt {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-wrap .header-top .header-top-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.fund-collection {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.ct_hero_banner {
  background-image: url(../../../assets/DashboardAssets/assets/hero_banner.png);
  background-size: cover;
  height: 650px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.ct_hero_banner:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #373435;
  opacity: 0.3;
  z-index: -1;
}

.ct_request_demo_btn {
  padding: 10px 15px !important;
  border-radius: 100px !important;
  color: #fff !important;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 85px;
  width: 85px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 #fff;
  -ms-box-shadow: 0 0 0 0 #fff;
  -o-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.play-now {
  position: relative;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 92px;
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
  z-index: 1;
  font-size: 28px;
  padding-left: 5px;
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center center;
  border-radius: 50%;
  margin: 0 auto;
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*---------------------------------
     Back To Top Button CSS
-----------------------------------*/
.back-to-top:hover {
  background-color: #86af48 !important;
}

.back-to-top {
  font-size: 24px;
  width: 45px;
  height: 45px;
  line-height: 52px !important;
  text-align: center;
  opacity: 1;
  visibility: visible !important;
  display: block !important;
  position: fixed;
  bottom: 30px !important;
  right: 20px;
  z-index: 999;
  border-radius: 5px;
  background: #86af48;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.back-to-top.open {
  bottom: 30px;
}

.back-to-top i {
  color: #fff;
}

/*---------------------------------
     Preloader CSS
-----------------------------------*/

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 20000;
  overflow-x: hidden !important;

  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loader div {
  /* width: 12px;
    height: 12px; */
  margin: 0 10px 0 0;
  border-radius: 50px;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  display: inline-block;
  -webkit-animation: bouncing 1.4s linear infinite;
  animation: bouncing 1.4s linear infinite;
  font-size: 48px;
  color: #86af48;
  font-weight: 700;
}

.loader div:last-child {
  margin: 0;
}

.loader div:nth-child(2) {
  /* background: rgba(1, 148, 70, 0.7); */
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  /* background: rgba(1, 148, 70, 0.5); */
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loader div:nth-child(4) {
  /* background: rgba(1, 148, 70, 0.5); */
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.loader div:nth-child(5) {
  /* background: rgba(1, 148, 70, 0.5); */
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader div:nth-child(6) {
  /* background: rgba(1, 148, 70, 0.5); */
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@-webkit-keyframes bouncing {

  0%,
  100% {
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: translateY(80px) scale(1, 1);
    transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  50% {
    -webkit-transform: translateY(80px) scale(1.5, 0.5);
    transform: translateY(80px) scale(1.5, 0.5);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  55% {
    -webkit-transform: translateY(80px) scale(1, 1);
    transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bouncing {

  0%,
  100% {
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: translateY(80px) scale(1, 1);
    transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  50% {
    -webkit-transform: translateY(80px) scale(1.5, 0.5);
    transform: translateY(80px) scale(1.5, 0.5);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  55% {
    -webkit-transform: translateY(80px) scale(1, 1);
    transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/*---------------------------------
       Button & Link CSS
----------------------------------*/

.btn {
  display: inline-block;
  line-height: 26px;
  font-weight: 500 !important;
  border-radius: 5px;
  text-align: center;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  position: relative;
  z-index: 1;
  border: none;
  padding: 15px 30px 15px;
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
}

.btn i {
  position: relative;
  top: 3px;
  font-size: 16px;
  margin-left: 7px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  color: #fff;
}

.btn:hover:after,
.btn:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn.style1 {
  background-color: #86af48;
  border-radius: 15px 0 15px 0;
}

.btn.style1:after,
.btn.style1:before {
  background: #373435;
}

.btn.style2 {
  background-color: #86af48;
  border-radius: 15px 0 15px 0;
  color: #fff;
  border-radius: 10px 0 10px 0;
}

.btn.style2:after,
.btn.style2:before {
  background: #373435;
}

.btn.style2:hover {
  color: #fff;
}

.btn.style3 {
  background-color: #373435;
  color: #fff;
}

.btn.style3:after,
.btn.style3:before {
  background: #86af48;
}

.btn.style3:hover {
  color: #fff;
}

.btn.style4 {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #373435;
}

.btn.style4:after,
.btn.style4:before {
  background: #86af48;
}

.btn.style4:hover {
  color: #fff;
}

.btn.style5 {
  background-color: transparent;
  border: 1px solid #86af48;
  color: #86af48;
}

.btn.style5:after,
.btn.style5:before {
  background: #86af48;
}

.btn.style5:hover {
  color: #fff;
}

.btn.style6 {
  background-color: #fff;
  color: #86af48;
}

.btn.style6:after,
.btn.style6:before {
  background: #86af48;
}

.btn.style6:hover {
  color: #fff;
}

.link {
  font-weight: 500;
  line-height: 26px;
  display: inline-block;
  position: relative;
  color: #86af48;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #86af48;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link i {
  position: relative;
  top: 3px;
  margin-left: 5px;
  font-size: 14px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link.style1 {
  color: #86af48;
}

.link:hover {
  color: #86af48;
}

.link:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.link:hover i {
  margin-left: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev i,
.owl-carousel .owl-nav button.owl-next i {
  font-size: 18px;
  line-height: 0.8;
  display: inline-block;
  position: relative;
  top: 1px;
  color: #86af48;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #86af48;
  border-color: transparent;
}

.owl-carousel .owl-nav button.owl-prev:hover i,
.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.owl-carousel .owl-dots button.owl-dot {
  display: inline-block;
  margin: 0 6px;
  position: relative;
  bottom: -5px;
}

.owl-carousel .owl-dots button.owl-dot span {
  display: inline-block;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% 0% 50% 50%;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin: 0px !important;
}

.owl-carousel .owl-dots button.owl-dot.active span {
  background: #86af48;
  width: 18px;
  height: 18px;
  top: 1px;
}

.ant-message {
  z-index: 999999999999 !important;
}

.ct_sub_menu_1 {
  display: block;
  height: 0px;
  transition: 0.4s ease all;
  overflow: hidden;
}

.ct_sub_menu_hover {
  display: block;
  height: 100%;
  transition: 0.4s ease all;
}

/*--------------------------------------
    Section Title,Overlay & Shape CSS
----------------------------------------*/

.section-title,
.content-title {
  position: relative;
}

.section-title h2,
.content-title h2 {
  font-size: 35px;
  margin: 0;
}

.section-title p,
.content-title p {
  margin: 12px 0 0;
}

.section-title span,
.content-title span {
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 1;
  line-height: 1;
  color: #86af48;
  padding-bottom: 18px;
  font-size: 16px;
}

.section-title span img,
.content-title span img {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 150px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-title.style2 span,
.content-title.style2 span {
  color: rgba(255, 255, 255, 0.8);
}

.section-title.style2 h2,
.content-title.style2 h2 {
  color: #fff;
}

.section-title.style3 span,
.content-title.style3 span {
  display: block;
  color: #86af48;
  padding-bottom: 10px;
}

.section-title.style3 span.bl-text,
.content-title.style3 span.bl-text {
  position: absolute;
  top: 42%;
  right: 20px;
  font-size: 65px;
  font-weight: 800;
  width: 100%;
  color: rgba(0, 0, 0, 0.08);
  line-height: 1;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.09);
  -webkit-text-fill-color: transparent;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-title.style4 span,
.content-title.style4 span {
  display: block;
  color: #86af48;
}

.section-title.style4 span.bl-text,
.content-title.style4 span.bl-text {
  position: absolute;
  top: 42%;
  right: 20px;
  font-size: 65px;
  font-weight: 800;
  width: 100%;
  color: rgba(255, 255, 255, 0.095);
  line-height: 1;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.08);
  -webkit-text-fill-color: transparent;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-title.style4 h2,
.content-title.style4 h2 {
  color: #fff;
}

.section-title.style4 p,
.content-title.style4 p {
  color: rgba(255, 255, 255, 0.8);
}

.section-title.style5 span,
.content-title.style5 span {
  display: block;
  color: #86af48;
  padding-bottom: 0;
}

.section-title.style5 h2,
.content-title.style5 h2 {
  color: #fff;
}

.section-title.style5 p,
.content-title.style5 p {
  color: rgba(255, 255, 255, 0.8);
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: #373435;
}

.bg-white {
  background-color: #fff;
}

.bg-athens {
  background-color: #f0f4ff;
}

.bg-albastor {
  background-color: #f9f9f9;
}

.bg-sand {
  background-color: #f5f5f5;
  /* background-color: rgb(228 233 99 / 23%); */
  /*background-color: rgb(228 233 99 / 5%);*/
}

.bg-lochi {
  background-color: #86af48;
}

.body_overlay {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.body_overlay.open {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-75 {
  opacity: 0.75;
}

.op-8 {
  opacity: 0.8;
}

.op-85 {
  opacity: 0.85;
}

.op-9 {
  opacity: 0.9;
}

.op-95 {
  opacity: 0.95;
}

.op-10 {
  opacity: 1;
}

.bg-f {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.pos-rel {
  position: relative;
  z-index: 1;
}

.bounce {
  animation-name: bounce !important;
  -webkit-animation: float 1500ms infinite ease-in-out;
  animation: float 1500ms infinite ease-in-out;
}

.moveHorizontal {
  -webkit-animation: moveHorizontal 3000ms infinite ease-in-out;
  animation: moveHorizontal 3000ms infinite ease-in-out;
}

.moveVertical {
  -webkit-animation: moveVertical 3000ms infinite ease-in-out;
  animation: moveVertical 3000ms infinite ease-in-out;
}

.moveHorizontal_reverse {
  -webkit-animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
  animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
}

.animationFramesTwo {
  -webkit-animation: animationFramesTwo 20000ms infinite ease-in-out;
  animation: animationFramesTwo 20000ms infinite ease-in-out;
}

.flyLeft {
  -webkit-animation: flyLeft 30000ms infinite linear;
  animation: flyLeft 30000ms infinite linear;
}

.flyRight {
  -webkit-animation: flyRight 30000ms infinite linear;
  animation: flyRight 30000ms infinite linear;
}

.flyUp {
  -webkit-animation: flyUp 15000ms infinite linear;
  animation: flyUp 15000ms infinite linear;
}

.moveLeft {
  -webkit-animation: moveLeft 30000ms infinite linear;
  animation: moveLeft 30000ms infinite linear;
}

.moveRight {
  -webkit-animation: moveRight 20000ms infinite linear;
  animation: moveRight 20000ms infinite linear;
}

.waving_left {
  -webkit-animation: waving_left 5s infinite linear;
  animation: waving_left 5s infinite linear;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.waving_right {
  -webkit-animation: waving_right 7s infinite linear;
  animation: waving_right 7s infinite linear;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rotate {
  -webkit-animation: rotation 20s infinite linear;
  animation: rotation 20s infinite linear;
}

.rotate {
  -webkit-animation: rotation 20s infinite linear;
  animation: rotation 20s infinite linear;
}

@-webkit-keyframes waving_left {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(5deg);
  }

  50% {
    -webkit-transform: rotate(7deg);
  }

  75% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes waving_left {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(5deg);
  }

  50% {
    -webkit-transform: rotate(7deg);
  }

  75% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes waving_right {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-3deg);
  }

  50% {
    -webkit-transform: rotate(-6deg);
  }

  75% {
    -webkit-transform: rotate(-3deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes waving_right {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(-3deg);
  }

  50% {
    -webkit-transform: rotate(-6deg);
  }

  75% {
    -webkit-transform: rotate(-3deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes moveLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes moveHorizontal {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveHorizontal {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveHorizontal_reverse {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveHorizontal_reverse {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveVertical {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes moveVertical {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes flyLeft {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(-50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(-65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(-80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(-100vw, 0px) rotate(0deg);
  }
}

@keyframes flyLeft {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(-50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(-65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(-80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(-100vw, 0px) rotate(0deg);
  }
}

@-webkit-keyframes flyRight {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(100vw, 0px) rotate(0deg);
  }
}

@keyframes flyRight {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(25vw, 50px) rotate(25deg);
  }

  40% {
    -webkit-transform: translate(50vw, -52px) rotate(40deg);
  }

  60% {
    -webkit-transform: translate(65vw, 82px) rotate(30deg);
  }

  80% {
    -webkit-transform: translate(80vw, -72px) rotate(15deg);
  }

  100% {
    -webkit-transform: translate(100vw, 0px) rotate(0deg);
  }
}

@-webkit-keyframes flyUp {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25% {
    -webkit-transform: translate(50px, -50px);
    transform: translate(50px, -50px);
  }

  50% {
    -webkit-transform: translate(100px, 60px);
    transform: translate(100px, 60px);
  }

  75% {
    -webkit-transform: translate(-50px, -25px);
    transform: translate(-50px, -25px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes flyUp {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25% {
    -webkit-transform: translate(50px, -50px);
    transform: translate(50px, -50px);
  }

  50% {
    -webkit-transform: translate(100px, 60px);
    transform: translate(100px, 60px);
  }

  75% {
    -webkit-transform: translate(-50px, -25px);
    transform: translate(-50px, -25px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

/*-------------------------------
     Social Link  CSS
-------------------------------*/

.social-profile li {
  display: inline-block;
  margin: 0 4px;
}

.social-profile li:first-child {
  margin-left: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li a {
  display: block;
  text-align: center;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-profile li a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  line-height: 0.8;
}

.social-profile li a:hover {
  border-color: transparent;
  color: #86af48;
}

.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a {
  width: 35px;
  height: 35px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-profile.style1 li a {
  background: #fff;
  border-radius: 50% 0% 50% 50%;
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.social-profile.style1 li a i {
  color: #86af48;
}

.social-profile.style1 li a:hover {
  background-color: #86af48;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style1 li a:hover i {
  color: #fff;
}

.social-profile.style2 li a {
  background: #f9f9f9;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.social-profile.style2 li a i {
  color: rgba(0, 0, 0, 0.3);
}

.theme-dark .social-profile.style2 li a i {
  color: #fff;
}

.theme-dark .social-profile.style2 li a {
  background: #333;
}

.social-profile.style2 li a:hover {
  background-color: #86af48;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style2 li a:hover i {
  color: #fff;
}

.social-profile.style3 li a {
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.social-profile.style3 li a i {
  color: rgba(0, 0, 0, 0.3);
}

.social-profile.style3 li a:hover {
  background-color: #86af48;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style3 li a:hover i {
  color: #fff;
}

/*-------------------------------
        Pagination  CSS
-------------------------------*/

.page-nav {
  text-align: center;
  margin-top: 25px;
}

.page-nav li {
  margin: 0 5px;
  display: inline-block;
}

.page-nav li a {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 54px;
  font-weight: 600;
  opacity: 0.7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-nav li a i {
  font-size: 18px;
  line-height: 1;
  margin: 0 auto;
  position: relative;
  top: 2px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-nav li a.active,
.page-nav li a:hover {
  color: #fff;
  opacity: 1;
  background-color: #86af48;
}

.page-nav li a.active i,
.page-nav li a:hover i {
  color: #fff;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.header-wrap .header-top .header-top-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/*---------------------------------
        Header CSS 
-----------------------------------*/

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes toBottomFromTop10 {
  49% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop10 {
  49% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  51% {
    opacity: 1;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.header-wrap {
  /* position: absolute; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-wrap.sticky {
  position: fixed;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #fff;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: 100%;
  top: 0px;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}

.header-wrap .header-top {
  padding: 12px 0 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-wrap .header-top .header-top-left .contact-info li {
  margin-right: 20px;
  padding-right: 20px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.header-wrap .header-top .header-top-left .contact-info li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.header-wrap .header-top .header-top-left .contact-info li i {
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-wrap .header-top .header-top-left .contact-info li a,
.header-wrap .header-top .header-top-left .contact-info li p {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 15px;
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-wrap .header-top .header-top-left .contact-info li a:hover,
.header-wrap .header-top .header-top-left .contact-info li p:hover {
  color: #86af48;
}

.header-wrap .header-top .header-top-right .social-profile {
  text-align: right;
  margin-left: 15px;
  position: relative;
  padding-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.19);
}

.header-wrap .header-top .header-top-right .social-profile:before {
  position: absolute;
  top: 50%;
  left: -2px;
  content: "";
  width: 1px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.35);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-wrap .header-top .header-top-right .social-profile li a {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
}

.header-wrap .header-top .header-top-right .social-profile li a i {
  font-size: 14px;
  color: #fff;
}

.header-wrap .header-top .header-top-right .social-profile li a:hover {
  background-color: #86af48;
}

.header-wrap .header-bottom {
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
  padding-block: 15px;
}

.header-wrap .header-bottom .container {
  position: relative;
}

.searchbox {
  position: relative;
}

.searchbox input {
  width: 100%;
  height: 54px;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 12px 30px 12px 18px;
  border: none;
  border-radius: 15px 0 15px 0;
}

.searchbox button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 5px 20px 5px 5px;
  height: 100%;
}

.searchbox button i {
  color: #373435;
  position: relative;
  top: 4px;
}

.searchbtn {
  background-color: transparent;
  border: none;
  padding: 0;
  position: relative;
  top: 2px;
}

.searchbtn i {
  font-size: 20px;
  line-height: 1;
}

.search-area {
  position: absolute;
  top: 85px;
  right: 12px;
  background-color: #fff;
  padding: 15px 15px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 320px;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 5px 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.search-area.open {
  visibility: visible;
  opacity: 1;
  top: 78px;
}

.search-area input {
  width: 100%;
  height: 58px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 15px;
  background-color: transparent;
  color: #373435;
}

.search-area button {
  position: absolute;
  top: 35px;
  right: 22px;
  border: none;
  background-color: transparent;
}

.search-area button i {
  font-size: 20px;
  opacity: 0.5;
  line-height: 0.8;
}

.select-lang {
  padding-left: 18px;
  position: relative;
}

.select-lang i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 15px;
  line-height: 1;
  color: #86af48;
}

.select-lang .navbar-language .dropdown-toggle {
  background-color: transparent;
  border: none;
  font-size: 15px;
  padding-right: 16px;
  color: #666666;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.ct_drop_icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  filter: brightness(35);
}

.select-lang .navbar-language .dropdown-toggle:after {
  display: none;
}

.select-lang .navbar-language .dropdown-toggle:before {
  position: absolute;
  top: 0px;
  right: -2px;
  content: "\EA4E";
  font-family: "remixicon";
  font-weight: 400;
  border: 0;
  margin-left: 8px;
  font-size: 16px;
}

.select-lang .navbar-language .dropdown-menu {
  margin: 0;
  padding: 5px 0;
  -webkit-box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
  box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
  left: 0;
  right: auto;
  border: 0;
  border-radius: 0;
  -webkit-transform: translateY(75px) !important;
  transform: translateY(75px) !important;
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.select-lang .navbar-language .dropdown-menu a {
  padding: 5px 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 15px;
}

.select-lang .navbar-language .dropdown-menu a img {
  width: 20px;
  margin-right: 10px;
}

.select-lang .navbar-language .dropdown-menu.show {
  -webkit-transform: translateY(40px) !important;
  transform: translateY(40px) !important;
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 767px) {

  .header-wrap .header-top .header-top-left .contact-item p,
  .header-wrap .header-top .header-top-left .contact-item a,
  .header-wrap .header-top .header-top-right .select-lang .navbar-language .dropdown-toggle {
    font-size: 14px !important;
  }

  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .navbar-brand img {
    max-width: 120px;
  }

  .header-wrap .header-top {
    padding: 15px 0;
    background-color: rgba(51, 143, 122, 0.09);
    border: none;
  }

  .header-wrap .header-top .header-top-left {
    margin-bottom: 10px;
  }

  .header-wrap .header-top .header-top-left .contact-info {
    width: 100%;
  }

  .header-wrap .header-top .header-top-left .contact-info li {
    text-align: center;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    border: none;
  }

  .header-wrap .header-top .header-top-left .contact-info li span {
    top: 0px;
    width: 25px;
    height: 25px;
  }

  .header-wrap .header-top .header-top-left .contact-info li span i {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header-wrap .header-top .header-top-right .select-lang i {
    top: 4px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a {
    width: 34px;
    height: 34px;
  }

  .search-area {
    width: 100%;
    right: 0;
  }

  .search-area.open {
    top: 76px;
  }

  .search-area input {
    height: 52px;
    font-size: 14px;
    padding: 10px 15px;
  }

  .search-area button {
    top: 32px;
  }

  .search-area button i {
    font-size: 18px;
  }

  .breadcrumb-wrap {
    padding: 60px 0;
    height: auto !important;
  }

  .cti_support_text {
    max-width: 100% !important;
  }

  .ct_remove_br br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .header-top .header-top-left .contact-info li i {
    font-size: 15px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 14px;
  }

  .header-wrap .header-top .header-top-right .select-lang span {
    font-size: 14px;
  }

  .header-wrap .header-bottom .other-options .btn {
    font-size: 15px;
    padding: 12px 20px;
  }

  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 9px !important;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrap.sticky {
    top: 0px;
  }

  .header-wrap .header-top {
    padding: 12px 0;
  }

  .header-wrap .header-top .header-top-left .contact-info li {
    margin-right: 8px;
    padding-right: 8px;
    border-right: none;
  }

  .header-wrap .header-top .header-top-left .contact-info li i {
    font-size: 12px;
  }

  .header-wrap .header-top .header-top-left .contact-info li a,
  .header-wrap .header-top .header-top-left .contact-info li p {
    font-size: 13px;
    margin-left: 5px;
  }

  .header-wrap .header-top .header-top-right .select-lang .dropdown-toggle span {
    font-size: 13px;
  }

  .header-wrap .header-top .header-top-right .social-profile {
    margin-left: 10px;
    padding-left: 10px;
  }

  .header-wrap .header-top .header-top-right .social-profile li {
    margin: 0 3px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a {
    width: 25px;
    height: 25px;
  }

  .header-wrap .header-top .header-top-right .social-profile li a i {
    font-size: 13px;
  }

  .header-wrap .header-top .header-top-left,
  .header-wrap .header-top .header-top-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header-wrap .navbar-brand img {
    max-width: 140px;
  }
}

@media only screen and (max-width: 991px) {
  .header-wrap .header-top .header-top-left .contact-item {
    width: 100%;
    margin: 0 0 15px;
  }

  .header-wrap .header-top .header-top-left .contact-item p,
  .header-wrap .header-top .header-top-left .contact-item a {
    color: #373435;
    font-size: 14px;
    font-weight: 400;
  }

  .header-wrap .header-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-wrap .header-bottom .container .search-area.open {
    top: 76px;
  }

  .header-wrap .header-bottom .navbar .navbar-collapse {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }

  .header-wrap .header-bottom .navbar .navbar-nav {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item {
    position: relative;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .menu-expand {
    position: absolute;
    right: 0;
    top: 14px;
    cursor: pointer;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .menu-expand i {
    color: #373435;
    font-size: 18px;
    line-height: 1;
    position: relative;
    top: 7px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .dropdown-menu .nav-item .menu-expand i {
    color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .dropdown-menu .nav-item a {
    border-color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a i {
    display: none;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.active {
    color: #86af48;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.btn {
    display: inline-block;
    padding: 10px 30px 10px;
    color: #fff;
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item a.btn i {
    display: inline-block;
    top: 6px;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu {
    width: 100%;
    background-color: #86af48;
    border: none;
    padding: 0;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 10px;
    color: #fff;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #86af48;
  }

  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    padding-left: 20px;
  }

  .header-wrap .header-bottom .user-login span {
    position: relative;
    top: -3px;
  }

  .header-wrap .header-bottom .user-login span i {
    font-size: 20px;
  }

  .mobile-bar-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    top: 4px;
  }

  .menu-close {
    position: absolute;
    top: 13px;
    right: 16px;
  }

  .menu-close i {
    color: #373435;
    font-size: 25px;
  }

  .mobile-menu {
    text-align: right;
    margin-left: 20px;
  }

  .mobile-menu a {
    width: 38px;
    height: 38px;
    border-radius: 62% 38% 46% 54% / 60% 63% 37% 40%;
    text-align: center;
    background-color: #86af48;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile-menu a i {
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
  }

  .mobile-menu {
    display: inline-block;
    position: relative;
    top: -5px;
  }

  .mobile-top-bar {
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    position: relative;
    top: 0px;
  }

  .mobile-top-bar span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #373435;
    border-radius: 20px;
    margin: 5.9px 0;
  }

  .mobile-top-bar span:nth-child(1) {
    margin-top: 0;
  }

  .mobile-top-bar span:nth-child(2) {
    width: 12px;
    margin-left: 8px;
  }

  .main-menu-wrap {
    width: 100%;
    position: fixed;
    background: #f5f5f5;
    z-index: 999;
    top: 0;
    height: 100vh;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    left: -100%;
    padding: 55px 20px 30px;
    overflow-y: auto;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .main-menu-wrap.open {
    left: 0;
  }

  .main-menu>li.has-children.menu-open>span.menu-expand {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .main-menu>li.has-children.menu-open>span.menu-expand i {
    color: #373435;
  }

  .main-menu>li>ul>li.has-children.menu-open>span.menu-expand {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .main-menu li {
    position: relative;
  }

  .main-menu li:last-child {
    margin: 0;
  }

  .main-menu li>span.menu-expand {
    position: absolute;
    right: 0;
    top: 13px;
    cursor: pointer;
  }

  .main-menu li>span.menu-expand i {
    color: #373435;
    font-size: 18px;
  }

  .main-menu li>span.menu-expand:hover {
    color: #373435;
  }

  .main-menu li a.active {
    color: #86af48;
  }

  .main-menu li>a {
    font-size: 14px;
    font-weight: 400;
    color: #373435;
    text-transform: capitalize;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .main-menu li>a:hover {
    color: #373435;
  }

  .main-menu li>ul.sub-menu {
    padding-left: 20px;
  }

  .header-wrap .header-bottom .main-menu-wrap #menu>ul>li>a::after {
    display: none;
  }

  .main-menu li a.active {
    font-weight: 600;
    color: #373435;
    border-color: rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (min-width: 992px) {
  .menu-expand i {
    display: none;
  }

  .header-wrap .navbar {
    padding: 0;
  }

  .header-wrap .navbar .navbar-brand {
    padding: 0 20px 0 0;
  }

  .header-wrap .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }

  .header-wrap .navbar .navbar-nav {
    margin-left: auto;
  }

  .header-wrap .navbar .navbar-nav .nav-item {
    position: relative;
    margin: 0 10px;
  }

  .header-wrap .navbar .navbar-nav .nav-item.first-child {
    margin-left: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    color: #373435;
    font-weight: 500;
    padding: 30px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 16px;
    position: relative;
  }

  .header-wrap .header-bottom .container {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-wrap .navbar .navbar-nav .nav-item a:hover,
  .header-wrap .navbar .navbar-nav .nav-item a:focus,
  .header-wrap .navbar .navbar-nav .nav-item a.active {
    color: #86af48;
  }

  .header-wrap .navbar .navbar-nav .nav-item a i {
    font-size: 16px;
    line-height: 1;
    position: relative;
    top: 0px;
    display: inline-block;
    margin-left: 0px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .header-wrap .navbar .navbar-nav .nav-item a:hover i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu {
    display: block !important;
    border: none;
    top: 83px;
    left: -23px;
    z-index: 99;
    opacity: 0;
    width: 300px;
    display: block;
    border-radius: 0;
    padding: 12px 0;
    position: absolute;
    visibility: hidden;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: rotateX(-75deg);
    transform: rotateX(-75deg);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    background-color: #8bb249;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li {
    margin: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 10px 20px;
    position: relative;
    display: block;
    font-weight: 500;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff !important;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li+li {
    border-top: 1px solid #fff;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:before {
    position: absolute;
    top: 50%;
    left: 0px;
    content: "";
    width: 0;
    height: 2px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .header-wrap .navbar .navbar-nav .nav-item ul.dropdown-menu ul.dropdown-menu li.nav-item a {
    display: grid;
    grid-template-columns: 35px auto;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover {
    color: #86af48;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active:before,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover:before {
    width: 20px;
    visibility: visible;
    opacity: 1;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover {
    padding-left: 30px;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    width: 100%;
    right: auto;
    left: 0;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a i {
    margin: 0;
    position: absolute;
    top: 55%;
    font-size: 16px;
    line-height: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    right: -300px;

    left: auto;
    visibility: hidden;
  }

  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .header-wrap .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotatex(0deg);
    transform: rotatex(0deg);
  }

  .header-wrap .navbar .other-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .header-wrap .navbar .other-options .option-item {
    margin: 0 0 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .navbar-brand img {
    max-width: 140px;
  }
}

@media only screen and (min-width: 1400px) {
  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 25px;
  }

  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 17px;
  }
}

/* @media only screen and (min-width: 1600px) {
    .header-wrap .container {
        max-width: 1520px;
    }
} */

/*---------------------------------
       Breadcrumb CSS 
-----------------------------------*/

.content-wrapper {
  margin-top: 0px !important;
}

.ct_about_bg {
  background-image: url(../../../assets/DashboardAssets/assets/about_bg_new.jpg);
  /* background-position: 0px -108px; */
}

.breadcrumb-wrap {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
  z-index: 1;
  height: 60vh;
  display: flex;
  align-items: center;
}

.breadcrumb-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.breadcrumb-title {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.breadcrumb-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 15px;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.breadcrumb-title .breadcrumb-menu li:after {
  position: absolute;
  top: 1px;
  right: -13px;
  content: "/";
  font-size: 16px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.breadcrumb-title .breadcrumb-menu li:first-child {
  margin-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:first-child a {
  padding-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:last-child {
  margin-right: 0;
  color: #86af48;
}

.breadcrumb-title .breadcrumb-menu li:last-child:after {
  display: none;
}

.breadcrumb-title .breadcrumb-menu li a {
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  padding: 0px 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  position: relative;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li a:hover {
  color: #86af48;
}

.content-feature-list {
  margin: 0 0 28px 0;
}

.content-feature-list li {
  position: relative;
  padding-left: 25px;
  margin: 0 0 18px;
}

.content-feature-list li:last-child {
  margin-bottom: 0;
}

.content-feature-list li i {
  position: absolute;
  top: 6px;
  left: 0;
  color: #86af48;
  font-size: 17px;
  line-height: 0.8;
}

/*---------------------------------------
        Sign In CSS
 ----------------------------------------*/

.login-header {
  margin: 0 0 30px;
}

.login-header h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
}

.login-header span {
  color: #fff;
}

.login-form-wrap {
  padding: 40px 40px 30px;
  margin-bottom: 25px;
  background: #f9f9f9;
}

.login-form-wrap .login-form .form-group {
  margin: 0 0 20px;
}

.login-form-wrap .login-form .form-group label {
  font-size: 16px;
  color: #666666;
  margin: 0 0 15px;
  display: block;
}

.login-form-wrap .login-form .form-group input {
  border-radius: 5px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60px;
  padding: 10px 15px;
  border-radius: 5px;
}

.login-form-wrap .login-form .btn {
  padding: 15px 35px;
}

.login-form-wrap .link.style1 {
  color: #86af48;
}

/*---------------------------
        Sidebar CSS
 ---------------------------*/

.sidebar .sidebar-widget {
  margin: 0 0 30px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding: 25px;
  border-radius: 5px;
}

.sidebar .sidebar-widget.style2 {
  background-color: transparent;
  padding: 0;
}

.sidebar .sidebar-widget h4 {
  margin: 0 0 25px;
  font-size: 22px;
  position: relative;
  line-height: 1;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #86af48;
}

.sidebar .sidebar-widget:last-child {
  margin: 0;
}

/*-------------------------------
       Search Box Widget CSS
-------------------------------*/

.search-box {
  position: relative;
}

.search-box .form-group {
  position: relative;
}

.search-box .form-group input {
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px solid #f0f4ff;
  color: #373435;
  font-size: 15px;
  line-height: 21px;
  padding: 10px 60px 10px 20px;
  border-radius: 5px;
}

.search-box .form-group input:focus {
  border-color: #888888;
}

.search-box .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 18px;
  height: 100%;
  border: none;
  border-radius: 0 5px 5px 0;
  text-align: center;
  background: #86af48;
}

.search-box .form-group button i {
  color: #fff;
  top: 4px;
  left: 1px;
  font-size: 20px;
  position: relative;
}

/*-------------------------------
     Category Widget CSS
-------------------------------*/

.category-box li {
  position: relative;
}

.category-box li:first-child a {
  padding-top: 0;
}

.category-box li:first-child a i {
  top: 5px;
}

.category-box li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}

.category-box li:last-child a i {
  top: 13px;
}

.category-box li a {
  color: #666666;
  font-weight: 500;
  padding: 8px 10px 8px 25px;
  display: block;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.category-box li a i {
  position: absolute;
  top: 14px;
  left: 0;
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.category-box li a:hover {
  color: #86af48;
  padding-left: 28px;
}

.category-box li a:hover i {
  color: #86af48;
}

/*-------------------------------
     Contact Widget CSS
-------------------------------*/

.contact-widget {
  /* background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg.jpg); */
  position: relative;
  padding: 60px 30px;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
}

.contact-widget:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.contact-widget h3 {
  color: #fff;
  margin: 0 0 15px;
  border: none;
  font-size: 22px;
}

.contact-widget p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 20px;
}

/*-------------------------------
     Profile Widget CSS
-------------------------------*/

.profile-widget {
  position: relative;
  border-radius: 5px;
}

.profile-widget img {
  border-radius: 5px;
}

.profile-widget .btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/*-------------------------------
     Taglist Widget CSS
-------------------------------*/

.tag-list {
  margin-top: -12px;
}

.tag-list li {
  display: inline-block;
  margin: 12px 8px 0px 0px;
}

.tag-list li a {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 12px;
  color: #666666;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tag-list li a:hover {
  background-color: #86af48;
  color: #fff !important;
  border-color: transparent;
}

/*-------------------------------
     Register Widget CSS
-------------------------------*/

.register-widget .form-group {
  margin-bottom: 20px;
}

.register-widget .form-group input,
.register-widget .form-group select,
.register-widget .form-group textarea {
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 12px 15px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.5);
}

.register-widget .form-group input::-webkit-input-placeholder,
.register-widget .form-group select::-webkit-input-placeholder,
.register-widget .form-group textarea::-webkit-input-placeholder {
  opacity: 1;
}

.register-widget .form-group input:-ms-input-placeholder,
.register-widget .form-group select:-ms-input-placeholder,
.register-widget .form-group textarea:-ms-input-placeholder {
  opacity: 1;
}

.register-widget .form-group input::-ms-input-placeholder,
.register-widget .form-group select::-ms-input-placeholder,
.register-widget .form-group textarea::-ms-input-placeholder {
  opacity: 1;
}

.register-widget .form-group input::placeholder,
.register-widget .form-group select::placeholder,
.register-widget .form-group textarea::placeholder {
  opacity: 1;
}

.register-widget .form-group input,
.register-widget .form-group select {
  height: 58px;
}

.register-widget .form-group textarea {
  height: 150px;
  resize: none;
}

/*-------------------------------
     Project Info Widget CSS
-------------------------------*/

.project-info-item-wrap .project-info-item {
  margin-bottom: 24px;
}

.project-info-item-wrap .project-info-item:last-child {
  margin-bottom: 0;
}

.project-info-item-wrap .project-info-item h6 {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}

/*-------------------------------
     Event Info Widget CSS
-------------------------------*/

.event-info-item-wrap {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.event-info-item-wrap .event-info-item {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 15px;
  padding-bottom: 20px;
  color: #666666;
}

.event-info-item-wrap .event-info-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.event-info-item-wrap .event-info-item span {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  width: calc(100% - 105px);
  margin-left: 5px;
  text-align: right;
}

.event-info-item-wrap .event-info-item span a {
  color: #86af48;
}

.event-info-item-wrap .event-info-item p {
  font-size: 16px;
  margin: 0;
  color: #373435;
  font-weight: 500;
  width: 100px;
}

.event-info-item-wrap .event-info-item p i {
  font-size: 16px;
  position: relative;
  color: #86af48;
  top: 2px;
  margin-right: 7px;
}

/*-------------------------------
    Popular post Widget CSS
-------------------------------*/

.pp-post-item {
  margin: 0 0 20px;
}

.pp-post-item .pp-post-img {
  width: 85px;
  border-radius: 4px;
}

.pp-post-item .pp-post-img img {
  border-radius: 4px;
}

.pp-post-item .pp-post-info {
  width: calc(100% - 100px);
  margin-left: 15px;
  margin-top: -3px;
}

.pp-post-item .pp-post-info h6 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 8px;
}

.pp-post-item .pp-post-info h6 a {
  color: #373435;
}

.pp-post-item .pp-post-info .pp-post-date {
  font-size: 14px;
  line-height: 1;
  display: block;
  position: relative;
  padding-left: 22px;
  color: #666666;
}

.pp-post-item .pp-post-info .pp-post-date:hover {
  color: #86af48;
}

.pp-post-item .pp-post-info .pp-post-date i {
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 15px;
  line-height: 0.8;
  color: #86af48;
}

.pp-post-item:last-child {
  margin: 0;
}

.pp-post-item:hover .pp-post-info h6 a {
  color: #86af48;
}

/*-------------------------------
    Hero  Section CSS
-------------------------------*/

.hero-slide-one {
  /* background-image: url(../../../assets/DashboardAssets/assets/hero-slider-1.jpg); */
}

.hero-slide-two {
  /* background-image: url(../img/hero/hero-slider-2.jpg); */
}

.hero-slide-three {
  /* background-image: url(../img/hero/hero-slider-3.jpg); */
}

.hero-wrap {
  position: relative;
  z-index: 1;
  margin-top: 114px;
}

.hero-wrap .hero-content span {
  color: #86af48;
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.hero-content {
  transform: matrix(1, 0, 0, 1, 0, -7.86);
}

.hero-wrap .hero-content h1 {
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 15px;
  color: #fff;
}

.hero-wrap .hero-content p {
  margin-bottom: 30px;
  color: #fff;
}

.hero-wrap.style1 {
  background-color: #f5f5f5;
  padding: 50px 0 0;
  overflow: hidden;
}

.hero-wrap.style1 .hero-shape-one,
.hero-wrap.style1 .hero-shape-two,
.hero-wrap.style1 .hero-shape-three,
.hero-wrap.style1 .hero-shape-four,
.hero-wrap.style1 .hero-shape-five,
.hero-wrap.style1 .hero-shape-six,
.hero-wrap.style1 .hero-skull-shape {
  position: absolute;
  z-index: -1;
}

.hero-wrap.style1 .hero-shape-one {
  bottom: 0;
  right: 0;
}

.hero-wrap.style1 .hero-shape-two {
  bottom: 0;
  left: 10px;
}

.hero-wrap.style1 .hero-shape-three {
  top: 40px;
  left: 40px;
}

.hero-wrap.style1 .hero-shape-four {
  top: 30px;
  right: 50px;
}

.hero-wrap.style1 .hero-shape-five {
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero-wrap.style1 .hero-shape-six {
  bottom: 0;
  right: -10px;
}

.hero-wrap.style1 .hero-skull-shape {
  bottom: -8px;
  right: 40%;
}

.hero-wrap.style1 .hero-content {
  padding: 140px 0;
}

.hero-wrap.style1 .hero-img-wrap {
  position: relative;
  bottom: -15px;
}

.hero-wrap.style2 {
  position: relative;
}

.hero-wrap.style2 .hero-slide-item {
  padding: 120px 0 260px;
  position: relative;
  z-index: 1;
}

.hero-wrap.style2 .hero-slide-item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(41.6%, rgba(0, 0, 0, 0.9)),
      color-stop(85%, rgba(0, 0, 0, 0.03)));
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.9) 41.6%,
      rgba(0, 0, 0, 0.03) 85%);
  z-index: -1;
}

.hero-wrap.style2 .hero-slide-item .hero-content span {
  color: #86af48;
}

.hero-wrap.style2 .hero-slide-item .hero-content h1 {
  color: #fff;
}

.hero-wrap.style2 .hero-slide-item .hero-content p {
  color: rgba(255, 255, 255, 0.8);
}

.hero-wrap.style3 {
  position: relative;
  z-index: 1;
  padding: 90px 0;
  overflow: hidden;
  background-color: #f5f5f5;
}

.hero-wrap.style3 .hero-shape-one,
.hero-wrap.style3 .hero-shape-two,
.hero-wrap.style3 .hero-shape-three,
.hero-wrap.style3 .hero-shape-four {
  position: absolute;
  z-index: -1;
}

.hero-wrap.style3 .hero-shape-one {
  top: 0;
  left: 0;
  height: 100%;
}

.hero-wrap.style3 .hero-shape-two {
  bottom: 0;
  right: 15%;
}

.hero-wrap.style3 .hero-shape-three {
  bottom: 0;
  right: 0;
}

.hero-wrap.style3 .hero-shape-four {
  top: 20px;
  right: 0;
}

.hero-wrap.style3 .hero-img-wrap {
  position: relative;
  z-index: 1;
}

.hero-wrap.style3 .hero-img-wrap .hero-img-shape {
  position: absolute;
  bottom: 0;
  left: -100px;
  z-index: -1;
}

.hero-wrap .hero-slider-thumbs {
  position: absolute;
  top: calc(50% + 140px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.hero-wrap .hero-slider-thumbs button {
  display: block;
  margin: 0 0 10px;
  border: none;
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.hero-wrap .hero-slider-thumbs button:last-child {
  margin-bottom: 0;
}

.hero-wrap .hero-slider-thumbs button img {
  border-radius: 50%;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev,
.hero-slider-one.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  width: 55px;
  height: 55px;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev i,
.hero-slider-one.owl-carousel .owl-nav button.owl-next i {
  color: #86af48;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover,
.hero-slider-one.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #86af48;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover i,
.hero-slider-one.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content span {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content p {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content .btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

/*-------------------------------
    Promo  Section CSS
-------------------------------*/

.promo-wrap.style2 {
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-top: -150px;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.09);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.09);
}

.promo-wrap.style2:after {
  position: absolute;
  bottom: -10px;
  left: -10px;
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 5px;
  z-index: -1;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.promo-wrap.style2 .promo-shape-one {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.promo-wrap.style2 .promo-shape-two {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.promo-wrap.style2 .promo-card {
  width: 33.33%;
  padding: 70px 25px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.promo-wrap.style2 .promo-card:first-child {
  border-radius: 5px 0 0 5px;
}

.promo-wrap.style2 .promo-card:last-child {
  border-right: none;
  border-radius: 0 5px 5px 0;
}

.promo-wrap.style2 .promo-card .promo-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #86af48;
  margin: 0 auto 20px;
}

.promo-wrap.style2 .promo-card .promo-icon i {
  font-size: 44px;
  line-height: 0.8;
  color: #86af48;
}

.promo-wrap.style2 .promo-card h3 {
  font-size: 22px;
}

/*-------------------------------
    Project  Section CSS
-------------------------------*/

.project-card {
  margin-bottom: 25px;
}

.project-card .project-info h3 {
  font-size: 22px;
}

.project-card .project-img {
  overflow: hidden;
}

.project-card .project-img img {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.project-card:hover .project-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.project-card.style1 .project-info {
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;
  padding: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style1 .project-info h3 {
  margin: 0 0 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style1 .project-info p {
  margin: 0 0 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style1 .project-info .project-shape {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: -1;
  max-width: 200px;
  -webkit-filter: brightness(0.1) invert(0);
  filter: brightness(0.1) invert(0);
  opacity: 0.06;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style1:hover .project-info {
  background-color: #86af48;
}

.project-card.style1:hover .project-info h3 a {
  color: #fff;
}

.project-card.style1:hover .project-info p {
  color: rgba(255, 255, 255, 0.8);
}

.project-card.style1:hover .project-info .link.style1 {
  color: #373435;
}

.project-card.style1:hover .project-info .project-shape {
  right: 0;
  -webkit-filter: brightness(1) invert(0);
  filter: brightness(1) invert(0);
  opacity: 1;
}

.project-card.style2 {
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.project-card.style2 img {
  border-radius: 5px;
}

.project-card.style2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 5px;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(black),
      color-stop(58%, rgba(0, 0, 0, 0.241317)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.241317) 58%);
}

.project-card.style2 .project-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 25px 25px;
  z-index: 1;
  width: 100%;
}

.project-card.style2 .project-info h3 {
  font-weight: 600;
  margin-bottom: 18px;
}

.project-card.style2 .project-info h3 a {
  color: #fff;
}

.project-card.style2 .project-info h3 a:hover {
  color: #86af48;
}

.project-card.style2 .project-info .fund-collection .fund-raised,
.project-card.style2 .project-info .fund-collection .fund-goal {
  color: #fff;
}

.project-card.style2 .project-img,
.project-card.style3 .project-img {
  position: relative;
}

.project-card.style2 .like-btn,
.project-card.style3 .like-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  z-index: 2;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style2 .like-btn:hover,
.project-card.style3 .like-btn:hover {
  background-color: #86af48;
}

.project-card.style2 .like-btn:hover i,
.project-card.style3 .like-btn:hover i {
  color: #fff;
}

.project-card.style2 .like-btn i,
.project-card.style3 .like-btn i {
  font-size: 26px;
  line-height: 0.8;
  margin: 0 auto;
  color: #86af48;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style3 {
  background-color: #fff;
  border-radius: 5px;
}

.project-card.style3 .project-img {
  border-radius: 5px 5px 0 0;
}

.project-card.style3 .project-img img {
  border-radius: 5px 5px 0 0;
}

.project-card.style3 .project-info {
  position: relative;
  z-index: 1;
  padding: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style3 .project-info h3 {
  margin: 0 0 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style3 .project-info p {
  margin: 0 0 7px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style3 .project-info .fund-collection {
  margin-bottom: 0;
}

.project-card.style3 .project-info .fund-collection .fund-raised,
.project-card.style3 .project-info .fund-collection .fund-goal {
  color: #666666;
}

.project-card.style4 {
  background-color: #fff;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.project-card.style4 .project-img {
  position: relative;
  width: 270px;
  border-radius: 5px 0 0 5px;
}

.project-card.style4 .project-img img {
  border-radius: 5px 0 0 5px;
}

.project-card.style4 .project-img .like-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  z-index: 2;
  background-color: #fff;
}

.project-card.style4 .project-img .like-btn i {
  font-size: 26px;
  line-height: 0.8;
  margin: 0 auto;
  color: #86af48;
}

.project-card.style4 .project-info {
  position: relative;
  z-index: 1;
  padding: 25px 25px 25px 5px;
  width: calc(100% - 290px);
  margin-left: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style4 .project-info h3 {
  font-size: 22px;
  margin: 0 0 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style4 .project-info p {
  margin: 0 0 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.project-card.style4 .project-info .fund-collection .fund-raised,
.project-card.style4 .project-info .fund-collection .fund-goal {
  color: #666666;
}

.skills .skillbar {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  height: 10px;
  border-radius: 15px;
}

.skills .skillbar .skillbar-bar {
  height: 10px;
  width: 0px;
  background: #86af48;
  border-radius: 50px 0 0 50px;
}

.fund-collection {
  margin: 0 0 10px;
}

.fund-collection .fund-raised,
.fund-collection .fund-goal {
  font-size: 14px;
}

.progressbar-wrap {
  margin-bottom: 20px;
}

.progressbar-wrap .progress-bar {
  margin: 0 0 25px;
  overflow: hidden;
  background: transparent;
}

.progressbar-wrap .progress-bar:last-child {
  margin-bottom: 0;
}

.progressbar-wrap .progress-bar .progress-content-outter {
  height: 12px;
  background-color: #f0f4ff;
  border-radius: 5px;
}

.progressbar-wrap .progress-bar .progress-content-outter .progress-content {
  height: 12px;
  background-color: #86af48;
  border-radius: 5px;
  width: 0%;
}

.progressbar-wrap .progress-bar .progress-title-holder {
  position: relative;
  margin: 0 0 45px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-title {
  z-index: 100;
  font-weight: 600;
  margin: 0;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper {
  width: 100%;
  z-index: 10;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
  margin-bottom: 0;
  border-radius: 3px;
  border: 1px solid #86af48;
  position: absolute;
  bottom: -35px;
  color: #86af48;
  padding: 3px 6px;
  border-radius: 5px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark:after {
  position: absolute;
  bottom: -8px;
  left: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #86af48;
  border-left: 0px solid transparent;
  border-right: 8px solid transparent;
}

.progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark .percent {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  color: #373435;
}

.project-slider-one .project-card,
.project-slider-two .project-card,
.project-slider-three .project-card {
  margin: 0;
}

.team-slider-one.owl-carousel .owl-nav button.owl-prev,
.team-slider-one.owl-carousel .owl-nav button.owl-next,
.team-slider-two.owl-carousel .owl-nav button.owl-prev,
.team-slider-two.owl-carousel .owl-nav button.owl-next,
.blog-slider-one.owl-carousel .owl-nav button.owl-prev,
.blog-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-three.owl-carousel .owl-nav button.owl-prev,
.project-slider-three.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.team-slider-one.owl-carousel .owl-nav button.owl-prev,
.team-slider-two.owl-carousel .owl-nav button.owl-prev,
.blog-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-one.owl-carousel .owl-nav button.owl-prev,
.project-slider-three.owl-carousel .owl-nav button.owl-prev {
  left: -65px;
}

.team-slider-one.owl-carousel .owl-nav button.owl-next,
.team-slider-two.owl-carousel .owl-nav button.owl-next,
.blog-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-one.owl-carousel .owl-nav button.owl-next,
.project-slider-three.owl-carousel .owl-nav button.owl-next {
  right: -65px;
}

.team-slider-one.owl-carousel .owl-dots,
.team-slider-two.owl-carousel .owl-dots,
.blog-slider-one.owl-carousel .owl-dots,
.project-slider-one.owl-carousel .owl-dots,
.project-slider-two.owl-carousel .owl-dots,
.project-slider-three.owl-carousel .owl-dots {
  margin: 30px 0 0;
  text-align: center;
}

.project-search-form .form-group {
  margin-bottom: 25px;
  position: relative;
}

.project-search-form .form-group select,
.project-search-form .form-group input {
  width: 100%;
  height: 58px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 12px 18px;
  color: #373435;
  border: none;
}

.project-search-form .form-group select::-webkit-input-placeholder,
.project-search-form .form-group input::-webkit-input-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select:-ms-input-placeholder,
.project-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select::-ms-input-placeholder,
.project-search-form .form-group input::-ms-input-placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select::placeholder,
.project-search-form .form-group input::placeholder {
  opacity: 0.7;
}

.project-search-form .form-group select select,
.project-search-form .form-group input select {
  opacity: 0.7;
}

.project-search-form .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
  height: 100%;
  border: none;
  background-color: transparent;
}

/*-------------------------------
    About  Section CSS
-------------------------------*/

.about-wrap {
  position: relative;
  z-index: 1;
}

.about-wrap .about-content .content-title {
  margin-bottom: 25px;
}

.about-wrap .about-content .content-feature-list {
  margin-bottom: 30px;
}

.about-wrap.style1 .about-shape-one,
.about-wrap.style1 .about-shape-two,
.about-wrap.style1 .about-shape-three {
  position: absolute;
  z-index: -1;
}

.about-wrap.style1 .about-shape-one {
  bottom: 0;
  right: 50px;
}

.about-wrap.style1 .about-shape-two {
  bottom: 180px;
  right: 20%;
}

.about-wrap.style1 .about-shape-three {
  bottom: 0;
  right: 0;
}

.about-wrap.style1 .about-img-wrap {
  position: relative;
  /* background-image: url(../img/about/about-img-1.jpg); */
  height: 630px;
  border-radius: 10px;
}

.about-img-wrap img {
  height: 500px;
  object-fit: cover;
  width: 100%;
}

.ct_about_main {
  position: relative;
}

.ct_about_main:before {
  content: "";
  position: absolute;
  right: -30px;
  top: 0;
  height: 50%;
  width: 15px;
  background: #86af48;
}

.ct_about_main:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -30px;
  height: 50%;
  width: 15px;
  background: #86af48;
}

.about-wrap.style1 .about-img-wrap .about-quote {
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: calc(100% - 60px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #86af48;
  border-radius: 10px;
  padding: 30px;
}

.about-wrap.style1 .about-img-wrap .about-quote i {
  color: #86af48;
  position: absolute;
  top: 35px;
  left: 25px;
  font-size: 38px;
  line-height: 0.8;
}

.about-wrap.style1 .about-img-wrap .about-quote p {
  color: #fff;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  padding-left: 44px;
}

.about-wrap.style2 .about-shape-one {
  position: absolute;
  bottom: 60px;
  right: 50px;
  z-index: -1;
}

.about-wrap.style3 .about-img-wrap {
  position: relative;
  z-index: 1;
}

.about-wrap.style3 .about-img-wrap:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: "";
  width: calc(100% - 80px);
  height: 250px;
  background-color: #f5f5f5;
  border-radius: 5px;
  z-index: -1;
  -webkit-transform: translateX(-50%) rotate(2deg);
  transform: translateX(-50%) rotate(2deg);
}

.about-wrap.style3 .about-img-wrap .about-shape-one {
  position: absolute;
  bottom: -20px;
  right: 20px;
  z-index: 1;
}

.ct_ct_climate_crisis_bg {
  background-image: url(../../../assets/DashboardAssets/assets/about-us-1img.jpg);
  padding: 60px 0px;
  background-size: 65% 100%;
  background-position: left;
  background-repeat: no-repeat;
}

.ct_climate_crisis_cnt {
  border-left: 4px solid #86af48;
}

.ct_ct_climate_crisis_bg_2 .ct_climate_crisis_cnt {
  border-left: 0px solid #86af48;
  border-right: 4px solid #86af48;
}

.theme-dark .ct_climate_crisis_cnt {
  background-color: #333;
}

.ct_ct_climate_crisis_bg_2 {
  background-image: url(../../../assets/DashboardAssets/assets/about-us-2img.jpg);
  padding: 60px 0px;
  background-size: 65% 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.ct_climate_crisis_cnt {
  background-color: #ffff;
  padding: 60px 50px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}

/* energy section S */

.ct_energy_bg {
  position: relative;
}

.ct_energy_bg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  /* background-image: url('../../../assets/DashboardAssets/assets/shape_top_left.png'); */
  background-repeat: no-repeat;
  background-position: left;
  width: 250px;
  height: 250px;
  background-size: contain;
}

.ct_energy_bg:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-image: url(../../../assets/DashboardAssets/assets/white_bg_shape.png);
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  background-position: bottom;
  background-size: contain;
  z-index: -1;
}

/*-------------------------------
    Donate  Section CSS
-------------------------------*/

.donate-box {
  background-color: #86af48;
  position: relative;
  z-index: 10;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  border-radius: 10px;
}

.donate-box .donate-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.donate-box .donate-amt {
  margin-bottom: 20px;
}

.donate-box .donate-amt li {
  margin-right: 20px;
  margin-bottom: 15px;
}

.donate-box .donate-amt li:last-child {
  margin-right: 0;
}

.donate-box .donate-amt li button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.9);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  padding: 18px 35px;
}

.donation-form {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 5px;
}

.donation-form h2 {
  font-size: 26px !important;
  margin: 0 0 10px !important;
}

.donation-form h4 {
  margin: 25px 0 25px;
}

.donation-form .radionbtn-wrap .radionbtn {
  margin-right: 25px;
  margin-bottom: 15px;
}

.donation-form .radionbtn-wrap .radionbtn:last-child {
  margin-right: 0;
}

.donation-form .form-group {
  margin-bottom: 25px;
}

.donation-form .form-group input,
.donation-form .form-group select {
  width: 100%;
  height: 58px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  padding: 12px 16px;
  color: #373435;
}

.donation-form .form-group input::-webkit-input-placeholder,
.donation-form .form-group select::-webkit-input-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input:-ms-input-placeholder,
.donation-form .form-group select:-ms-input-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input::-ms-input-placeholder,
.donation-form .form-group select::-ms-input-placeholder {
  opacity: 0.7;
}

.donation-form .form-group input::placeholder,
.donation-form .form-group select::placeholder {
  opacity: 0.7;
}

.donation-form .form-group select {
  opacity: 0.7;
}

.donation-form .form-check {
  margin-bottom: 20px;
}

/*-------------------------------
    Counter  Section CSS
-------------------------------*/

.counter-wrap {
  position: relative;
  z-index: 1;
}

.counter-wrap .counter-shape-one,
.counter-wrap .counter-shape-two,
.counter-wrap .counter-shape-three {
  position: absolute;
  z-index: -1;
}

.counter-wrap.style1 {
  position: relative;
  background-color: #f5f5f5;
  margin-top: -200px;
  overflow: hidden;
}

.counter-wrap.style1 .counter-shape-one {
  bottom: 0;
  right: 0;
}

.counter-wrap.style1 .counter-shape-two {
  bottom: 0;
  right: 220px;
}

.counter-wrap.style1 .counter-shape-three {
  bottom: 0;
  right: -10px;
}

.promo-video {
  position: relative;
  z-index: 1;
}

.promo-video:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.promo-video .play-now {
  width: 90px;
  height: 90px;
  top: 50%;
  font-size: 34px;
  line-height: 88px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
}

.promo-video .play-now i {
  color: #86af48;
  position: relative;
  left: -2px;
}

.promo-video .play-now .ripple:after,
.promo-video .play-now .ripple:before,
.promo-video .play-now .ripple {
  width: 90px;
  height: 90x;
}

.promo-video.style1 {
  background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg.jpg);
  height: 100%;
}

.promo-video.style2 {
  /* background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg-2.jpg); */
}

.promo-video.style2 .play-now {
  position: relative;
  top: auto;
  margin-top: 50px;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.promo-video.style3 {
  background-image: url(../../../assets/DashboardAssets/assets/promo-video-bg.jpg);
  height: 100%;
}

.promo-video.style3 .play-now {
  position: relative;
  top: auto;
  left: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  margin: 30px 0 0;
}

.counter-card-wrap {
  margin: 0 -12.5px;
}

.counter-card-wrap.style1 {
  padding: 300px 90px 125px 60px;
}

.counter-card-wrap.style1 .counter-card {
  position: relative;
  margin-bottom: 25px;
  background-color: #fff;
  width: calc(50% - 25px);
  margin: 0 12.5px 25px;
  padding: 50px 20px;
  max-height: 185px;
  text-align: center;
}

.counter-card-wrap.style1 .counter-card:nth-child(2),
.counter-card-wrap.style1 .counter-card:nth-child(4) {
  top: 50px;
}

.counter-card-wrap.style1 .counter-card .counter-text h2 {
  font-size: 46px;
  line-height: 1;
  margin: 0 0 10px;
}

.counter-card-wrap.style1 .counter-card .counter-text p {
  margin-bottom: 0;
  line-height: 1;
}

.counter-card-wrap.style1 .counter-card.style1 .counter-text h2,
.counter-card-wrap.style1 .counter-card.style4 .counter-text h2 {
  color: #86af48;
}

.counter-card-wrap.style1 .counter-card.style2 .counter-text h2,
.counter-card-wrap.style1 .counter-card.style3 .counter-text h2 {
  color: #86af48;
}

.counter-card-wrap.style1 .counter-card.style1 {
  border-radius: 50px 50px 0 50px;
}

.counter-card-wrap.style1 .counter-card.style2 {
  border-radius: 50px 50px 50px 0;
}

.counter-card-wrap.style1 .counter-card.style3 {
  border-radius: 50px 0 50px 50px;
}

.counter-card-wrap.style1 .counter-card.style4 {
  border-radius: 0 50px 50px 50px;
}

.counter-card-wrap.style2 {
  margin: 0 -12.5px;
  padding: 0;
}

.counter-card-wrap.style2 .counter-card {
  padding: 40px 25px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.06);
  width: calc(25% - 25px);
  margin: 0 12.5px 25px;
  position: relative;
}

.counter-card-wrap.style2 .counter-card:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.counter-card-wrap.style2 .counter-card.style1 {
  border-radius: 50px 50px 0 50px;
}

.counter-card-wrap.style2 .counter-card.style1:after {
  bottom: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style2 {
  border-radius: 50px 50px 50px 0;
}

.counter-card-wrap.style2 .counter-card.style2:after {
  top: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style3 {
  border-radius: 50px 0 50px 50px;
}

.counter-card-wrap.style2 .counter-card.style3:after {
  bottom: -5px;
  left: 40%;
}

.counter-card-wrap.style2 .counter-card.style4 {
  border-radius: 0 50px 50px 50px;
}

.counter-card-wrap.style2 .counter-card.style4:after {
  top: 50%;
  right: -5px;
}

.counter-card-wrap.style2 .counter-card .counter-icon {
  width: 70px;
}

.counter-card-wrap.style2 .counter-card .counter-icon i {
  font-size: 68px;
  line-height: 0.8;
  color: #86af48;
}

.counter-card-wrap.style2 .counter-card .counter-text {
  width: calc(100% - 90px);
  margin-left: 20px;
}

.counter-card-wrap.style2 .counter-card .counter-text h2 {
  color: #fff;
  font-size: 40px;
  margin: 0 0 5px;
}

.counter-card-wrap.style2 .counter-card .counter-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.counter-card-wrap.style3 {
  padding-bottom: 25px;
}

.counter-card-wrap.style3 .counter-card {
  position: relative;
  background-color: #fff;
  width: calc(50% - 25px);
  margin: 0 12.5px 25px;
  padding: 50px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.counter-card-wrap.style3 .counter-card:nth-child(2),
.counter-card-wrap.style3 .counter-card:nth-child(4) {
  top: 50px;
}

.counter-card-wrap.style3 .counter-card .counter-icon {
  width: 80px;
}

.counter-card-wrap.style3 .counter-card .counter-icon i {
  font-size: 78px;
  line-height: 0.8;
  color: #86af48;
}

.counter-card-wrap.style3 .counter-card .counter-text {
  width: calc(100% - 100px);
  margin-left: 20px;
}

.counter-card-wrap.style3 .counter-card .counter-text h2 {
  font-size: 46px;
  line-height: 1;
  margin: 0 0 10px;
  color: #fff;
}

.counter-card-wrap.style3 .counter-card .counter-text p {
  margin-bottom: 0;
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
}

.counter-card-wrap.style3 .counter-card:nth-child(1),
.counter-card-wrap.style3 .counter-card:nth-child(4) {
  background-color: #86af48;
  border-color: transparent;
}

.counter-card-wrap.style3 .counter-card:nth-child(1) i.flaticon-coin,
.counter-card-wrap.style3 .counter-card:nth-child(4) .flaticon-volunteer {
  color: #fff;
}

.counter-card-wrap.style3 .counter-card:nth-child(2),
.counter-card-wrap.style3 .counter-card:nth-child(3) {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  padding: 0 1px;
}

/*-------------------------------
    Event  Section CSS
-------------------------------*/

.event-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.event-wrap .event-sape-one {
  position: absolute;
  bottom: -10px;
  left: 20px;
  z-index: -1;
  opacity: 0.5;
}

.event-wrap .event-sape-two {
  position: absolute;
  top: 70px;
  left: 120px;
  z-index: -1;
}

.event-wrap .event-sape-three {
  position: absolute;
  bottom: -10px;
  right: 30px;
  z-index: -1;
  opacity: 0.5;
}

.event-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card ul.event-metainfo.list-style li {
  color: #86af48;
}

.event-card:hover ul.event-metainfo.list-style li {
  color: #fff;
}

.event-card:hover .btn {
  background-color: #fff;
  color: #333;
}

.event-card .btn:hover {
  color: #fff;
}

.theme-dark .event-card .btn:hover {
  color: #fff !important;
}

.theme-dark .event-card .btn {
  z-index: 1;
}

.theme-dark .event-card:hover .btn {
  color: #333 !important;
}

.theme-dark .event-card .btn:hover {
  color: #fff !important;
}

.theme-dark .event-info a:hover {
  color: #fff !important;
}

.theme-dark .event-info:hover .btn {
  color: #333;
}

.event-card .event-img {
  position: relative;
  border-radius: 10px 0 0 10px;
}

.event-card .event-img img {
  border-radius: 10px 0 0 10px;
}

.event-card .event-img .event-date {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #86af48;
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 15px;
  padding: 7px 15px;
}

.event-card .event-info {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info h3 {
  font-size: 22px;
  margin: 0 0 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info .event-metainfo {
  margin-bottom: 10px;
}

.event-card .event-info .event-metainfo li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
  color: #666666;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info .event-metainfo li a {
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info .event-metainfo li:last-child {
  margin-right: 0;
}

.event-card .event-info .event-metainfo li i {
  color: #86af48;
  position: relative;
  top: 1px;
  margin-right: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.event-card .event-info p {
  margin: 0;
}

.event-card:hover {
  background-color: #86af48;
  border-color: transparent;
}

.event-card:hover .event-info h3 a {
  color: #fff;
}

.event-card:hover .event-info p {
  color: rgba(255, 255, 255, 0.8);
}

.event-card:hover .event-info .event-metainfo li i {
  color: #fff;
}

.event-card:hover .event-info .event-metainfo li a,
.event-card:hover .event-info .event-metainfo li p {
  color: rgba(255, 255, 255, 0.8);
}

.event-card.style1 .event-img {
  width: 250px;
}

.event-card.style1 .event-info {
  width: calc(100% - 270px);
  padding: 20px 20px 20px 25px;
}

.event-card.style2,
.event-card.style3 {
  background-color: #fff;
}

.event-card.style2:hover,
.event-card.style3:hover {
  background-color: #86af48;
  border-color: transparent;
}

.event-card.style2 .event-info,
.event-card.style3 .event-info {
  margin-left: 20px;
  padding: 20px 20px 20px 5px;
}

.event-card.style2 .event-info .event-metainfo li a,
.event-card.style2 .event-info .event-metainfo li p,
.event-card.style3 .event-info .event-metainfo li a,
.event-card.style3 .event-info .event-metainfo li p {
  font-size: 14px;
}

.event-card.style2 .event-img {
  width: 210px;
}

.event-card.style2 .event-info {
  width: calc(100% - 230px);
}

.event-card.style3 .event-img {
  width: 185px;
}

.event-card.style3 .event-info {
  width: calc(100% - 205px);
}

.event-card.style4 .event-img {
  border-radius: 5px 5px 0 0;
}

.event-card.style4 .event-img img {
  border-radius: 5px 5px 0 0;
}

.event-card.style4 .event-info {
  padding: 24px 25px;
}

.event-card.style5 {
  background-color: #f5f5f5;
  border: none;
}

.event-card.style5 .event-img {
  border-radius: 5px 5px 0 0;
}

.event-card.style5 .event-img img {
  border-radius: 5px 5px 0 0;
}

.event-card.style5 .event-info {
  padding: 24px 25px;
}

.event-card.style5 .event-info p {
  margin-bottom: 15px;
}

.event-card.style5:hover {
  background-color: #86af48;
}

.event-card.style5:hover .link {
  color: #fff;
}

.newsletter-form.style1 {
  margin-bottom: 25px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 50px 30px 58px;
  border-radius: 10px;
  background-color: #fff;
}

.newsletter-form.style1 h4 {
  font-size: 22px;
  margin: 0 0 10x;
  text-align: center;
}

.newsletter-form.style1 p {
  margin: 0 0 20px;
  text-align: center;
}

.newsletter-form.style1 .form-group {
  margin-bottom: 20px;
}

.newsletter-form.style1 .form-group input {
  width: 100%;
  background-color: #f9f9f9;
  height: 54px;
  padding: 12px 18px;
  border: none;
}

.newsletter-form.style1 button {
  width: 100%;
  display: block;
}

.event-search-form {
  background-color: #f5f5f5;
  padding: 25px 25px 5px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.event-search-form .form-group {
  margin: 0 0 20px;
}

.event-search-form .form-group label {
  font-weight: 600;
  color: #373435;
  margin: 0 0 10px;
  display: block;
}

.event-search-form .form-group select,
.event-search-form .form-group input {
  width: 100%;
  height: 58px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 18px;
  color: #373435;
  border: none;
}

.event-search-form .form-group select::-webkit-input-placeholder,
.event-search-form .form-group input::-webkit-input-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select:-ms-input-placeholder,
.event-search-form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select::-ms-input-placeholder,
.event-search-form .form-group input::-ms-input-placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select::placeholder,
.event-search-form .form-group input::placeholder {
  opacity: 0.7;
}

.event-search-form .form-group select select,
.event-search-form .form-group input select {
  opacity: 0.7;
}

.event-search-form .form-group button {
  margin-top: 35px;
}

/*-------------------------------------
    Project & Event Details  CSS
----------------------------------------*/

.event-desc .single-project-img,
.event-desc .single-event-img,
.project-desc .single-project-img,
.project-desc .single-event-img {
  border-radius: 10px;
  margin: 10px 0 30px;
  display: block;
}

.event-desc .single-project-img img,
.event-desc .single-event-img img,
.project-desc .single-project-img img,
.project-desc .single-event-img img {
  border-radius: 10px;
}

.event-desc .progressbar-wrap,
.project-desc .progressbar-wrap {
  margin-top: -10px;
}

.project-desc .fund-collection li span {
  font-size: 20px;
  font-weight: 600;
  margin-right: 4px;
  color: #373435;
}

.project-desc .wp-block-quote {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f5f5f5;
  padding: 30px;
  border-left: 4px solid #86af48;
}

.project-desc .wp-block-quote p {
  text-align: left;
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  margin: 0;
}

/*-------------------------------
    Team  Section CSS
-------------------------------*/

.team-wrap {
  position: relative;
  z-index: 1;
}

.team-wrap .team-shape-one {
  position: absolute;
  bottom: 0;
  left: 30px;
  z-index: -1;
}

.team-card {
  margin-bottom: 25px;
  overflow: hidden;
}

.team-card h3 {
  font-size: 22px;
}

.team-card.style1 {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.team-card.style1 img {
  border-radius: 5px 5px 0 0;
}

.team-card.style1 .team-info {
  background-color: #fff;
  text-align: center;
  padding: 25px;
  position: absolute;
  z-index: 1;
  bottom: -60px;
  left: 0;
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;

}

.team-card.style1 .team-info .team-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.team-card.style1 .team-info h3 {
  margin: 0 0 7px;
}

.team-card.style1 .team-info span {
  font-size: 14px;
  color: #666666;
}

.team-card.style1 .team-info .social-profile {
  margin-top: 20px;
}

.team-card.style1 .team-info .social-profile li a {
  background-color: #86af48;
}

.team-card.style1 .team-info .social-profile li a i {
  color: #fff;
}

.team-card.style1:hover .team-info {
  bottom: 0;
}

.team-card.style2 {
  border-radius: 5px;
}

.team-card.style2 img {
  border-radius: 5px 5px 0 0;
}

.btn-close {
  background-image: unset !important;
}

.team-card.style2 .team-info-wrap {
  background-color: #86af48;
  padding: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 1;
}

.team-card.style2 .team-info-wrap .team-card-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100px;
  z-index: -1;
}

.team-card.style2 .team-info-wrap .team-info {
  width: calc(100% - 60px);
  margin-right: 8px;
}

.team-card.style2 .team-info-wrap .team-info h3 {
  margin: 0 0 6px;
}

.team-card.style2 .team-info-wrap .team-info h3 a {
  color: #fff;
}

.team-card.style2 .team-info-wrap .team-info span {
  color: rgba(255, 255, 255, 0.8);
}

.team-card.style2 .team-info-wrap .social-link {
  cursor: pointer;
  position: relative;
}

.team-card.style2 .team-info-wrap .social-link span {
  display: inline-block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
}

.team-card.style2 .team-info-wrap .social-link span i {
  color: #86af48;
  font-size: 22px;
  line-height: 54px;
  margin: 0 auto;
}

.team-card.style2 .team-info-wrap .social-link .social-profile {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li {
  visibility: hidden;
  opacity: 0;
  display: block;
  margin: 10px 0;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li a {
  width: 52px;
  height: 52px;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li a i {
  font-size: 20px;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:last-child {
  margin: 0;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(2) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.team-card.style2 .team-info-wrap .social-link .social-profile li:nth-child(1) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.team-card.style2 .team-info-wrap .social-link:hover .social-profile li {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.team-card.style2:hover .team-info-wrap {
  background-color: #86af48;
}

.team-card.style3 {
  position: relative;
  background-color: #f5f5f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-card.style3 img {
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-card.style3 .team-info {
  background-color: #f5f5f5;
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: -75px;
  padding-top: 25px;
  padding-bottom: 85px;
  width: calc(100% + 5px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.team-card.style3:hover .team-info {
  padding-bottom: 25px !important;
}

.team-card.style3 .team-info h3 {
  margin: 0 0 7px;
}

.team-card.style3 .team-info span {
  font-size: 14px;
  color: #666666;
}

.team-card.style3 .team-info .social-profile {
  margin-top: 20px;
}

.team-card.style3 .team-info .social-profile li a {
  background-color: #86af48;
}

.team-card.style3 .team-info .social-profile li a i {
  color: #fff;
}

.team-card.style3:hover {
  padding: 25px;
  background-color: #fff;
}

.team-card.style3:hover img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info h3 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-card.style3:hover .team-info span {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .team-card,
.team-slider-two .team-card {
  margin: 0;
}

.team-slider-one .team-card.style3,
.team-slider-two .team-card.style3 {
  margin: 10px 10px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 img {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
  bottom: -75px;
  left: -7px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info h3 {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3 .team-info span {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover {
  padding: 20px;
  background-color: #fff;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info h3 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(odd) .team-card.style3:hover .team-info span {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 img {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info {
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
  bottom: -75px;
  right: -7px;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info h3 {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3 .team-info span {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover {
  padding: 20px;
  background-color: #fff;
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info {
  background-color: #fff;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info h3 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.team-slider-one .owl-stage-outer .owl-stage .owl-item:nth-child(even) .team-card.style3:hover .team-info span {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*-------------------------------
    Team Details  Section CSS
-------------------------------*/
.ct_director_img img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  border-radius: 10px;
  transition: 0.4s ease all;
}

.ct_ddirector_info {
  padding: 30px;
}

.ct_director_img_box {
  background: #fff;
  box-shadow: 0 0 25px #00000017;
  padding: 30px 30px 50px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}

.ct_white_bg {
  background-color: #f5f5f5;
  /* box-shadow: 0px 0px 25px rgba(0,0,0,0.09); */
  margin-bottom: 30px;
  padding: 15px;
}

.ct_director_img {
  border: 2px solid #86af48;
  border-radius: 10px;
}

.team-desc p:last-child {
  margin-bottom: 0;
}

.team-desc .progressbar-wrap {
  margin-bottom: 25px;
}

.team-desc .progressbar-wrap .progress-bar {
  margin-bottom: 20px;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder {
  margin-bottom: 20px;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-title {
  position: relative;
  top: 5px;
  font-size: 18px;
  font-weight: 500;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark {
  bottom: -7px;
  border-color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark .percent {
  color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-title-holder .progress-number-wrapper .progress-number-mark:after {
  border-top-color: #86af48;
}

.team-desc .progressbar-wrap .progress-bar .progress-content-outter {
  border-radius: 0;
}

.team-desc .progressbar-wrap .progress-bar .progress-content-outter .progress-content {
  border-radius: 0;
}

.ct_director_img {
  position: relative;
  overflow: hidden;
}

.ct_ddirector_info h2 {
  color: #86af48;
}

.ct_director_img::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.ct_director_img:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.progess-bar-item {
  margin: 25px 0 0;
}

.progess-bar-item .single-skill {
  margin-bottom: 25px;
  text-align: center;
}

.progess-bar-item .single-skill p {
  margin: 15px auto 0;
}

.progess-bar-item .single-skill .circlechart {
  text-align: center;
}

.progressbar {
  display: inline-block;
  width: 100px;
  margin: 25px;
}

.progressbar .circle {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  text-align: center;
}

.progressbar .circle canvas {
  vertical-align: middle;
}

.progressbar .circle div {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}

.progressbar .circle strong i {
  font-style: normal;
  font-size: 0.6em;
  font-weight: normal;
}

.progressbar .circle strong span {
  display: block;
  color: #aaa;
  margin-top: 12px;
}

.circle-chart {
  width: 150px;
  height: 150px;
}

.circle-chart__circle {
  stroke: #00acc1;
  stroke-width: 1;
  stroke-linecap: square;
  fill: none;
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  /* 2, 3 */
  -webkit-transform-origin: center;
  transform-origin: center;
  /* 4 */
}

.circle-chart__subline {
  display: none;
}

.single-skill .circle-chart__circle {
  stroke: #86af48;
  stroke-width: 2;
}

.circle-chart__circle--negative {
  -webkit-transform: rotate(-90deg) scale(1, -1);
  transform: rotate(-90deg) scale(1, -1);
}

.circle-chart__background {
  stroke: #f5f5f5;
  stroke-width: 2;
  fill: none;
}

.circle-chart__info {
  -webkit-animation: circle-chart-appear 2s forwards;
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.circle-chart__percent {
  text-anchor: middle;
  font-size: 6px;
  color: #86af48;
  font-family: "Inter", sans-serif;
}

.circle-chart__subline {
  text-anchor: middle;
  font-size: 3px;
}

@-webkit-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@-webkit-keyframes circle-chart-appear {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*-------------------------------
    CTA  Section CSS
-------------------------------*/

.cta-wrap {
  overflow: hidden;
}

.cta-wrap.style1,
.cta-wrap.style2 {
  position: relative;
  background-image: url(../../../assets/DashboardAssets/assets/cta-bg-1.jpg);
  z-index: 1;
}

.cta-wrap.style1:after,
.cta-wrap.style2:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(45%, rgba(255, 255, 255, 0.95)),
      color-stop(94%, rgba(255, 255, 255, 0.3)));
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.95) 45%,
      rgba(255, 255, 255, 0.3) 94%);
}

.cta-wrap.style1 .content-title,
.cta-wrap.style2 .content-title {
  position: relative;
  z-index: 1;
}

.cta-wrap.style1 .cta-btn,
.cta-wrap.style2 .cta-btn {
  text-align: center;
}

.cta-wrap.style1 .cta-btn .btn:nth-child(1),
.cta-wrap.style2 .cta-btn .btn:nth-child(1) {
  margin-right: 15px;
}

.cta-wrap.style1 .cta-shape-one,
.cta-wrap.style2 .cta-shape-one {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 0;
}

.cta-wrap.style1 {
  position: relative;
  z-index: 1;
}

.cta-wrap.style1 .cta-shape-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*-------------------------------
    Blog  Section CSS
-------------------------------*/

.blog-wrap .container {
  position: relative;
  z-index: 1;
}

.blog-wrap .container .blog-section-shape {
  position: absolute;
  bottom: -20px;
  left: -100px;
  z-index: -1;
}

.blog-card {
  border-radius: 5px;
  margin-bottom: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-card .blog-info .blog-author {
  margin: 0 0 20px;
}

.blog-card .blog-info .blog-author .blog-author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.blog-card .blog-info .blog-author .blog-author-img img {
  border-radius: 50%;
}

.blog-card .blog-info .blog-author .blog-author-info {
  width: calc(100% - 65px);
  margin-left: 15px;
}

.blog-card .blog-info .blog-author .blog-author-info h6 {
  font-size: 16px;
  margin: 5px 0 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info .blog-author .blog-author-info span {
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info h3 {
  font-size: 22px;
  font-weight: 600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info .blog-metainfo li {
  display: inline-block;
  margin-right: 15px;
  color: #666666;
}

.blog-card .blog-info .blog-metainfo li:last-child {
  margin-right: 0;
}

.blog-card .blog-info .blog-metainfo li a {
  font-size: 15px;
  position: relative;
  padding-left: 22px;
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info .blog-metainfo li a i {
  color: #86af48;
  position: absolute;
  top: -4px;
  left: 0;
  font-size: 16px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info .link {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-info .link:after {
  display: none;
}

.blog-card.style1 {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.blog-card.style1 .blog-info {
  padding: 25px;
}

.blog-card.style1 .blog-info .blog-author {
  margin: 0 0 20px;
}

.blog-card.style1 .blog-info h3 {
  margin-bottom: 15px;
}

.blog-card.style1 .blog-info p {
  margin-bottom: 15px;
}

.blog-card.style1 .link {
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 18px 15px;
  border-radius: 0 0 5px 5px;
}

.blog-card.style1:hover {
  background-color: #86af48;
}

.blog-card.style1:hover .blog-info .blog-author .blog-author-info h6 a {
  color: #fff;
}

.blog-card.style1:hover .blog-info .blog-author .blog-author-info span {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info h3 a {
  color: #fff;
}

.blog-card.style1:hover .blog-info p {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info .blog-metainfo li {
  color: rgba(255, 255, 255, 0.8);
}

.blog-card.style1:hover .blog-info .blog-metainfo li i {
  color: #fff;
}

.blog-card.style1:hover .blog-info .blog-metainfo li a {
  color: #fff;
}

.blog-card.style1:hover .link {
  background-color: #86af48;
  color: #fff;
}

.blog-card.style1:hover .link:after {
  display: none;
}

.blog-card.style2 {
  background-color: #fff;
}

.blog-card.style2 .blog-info {
  padding: 25px;
}

.blog-card.style2 .blog-info .blog-metainfo {
  margin-bottom: 0;
}

.blog-card.style2 .blog-info h3 {
  margin-bottom: 15px;
}

.blog-card.style2 .link {
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 18px 15px;
  border-radius: 0 0 5px 5px;
}

.blog-card.style2:hover .link {
  background-color: #86af48;
  color: #fff;
}

.blog-card.style3 {
  background-color: #fff;
  padding: 25px;
}

.blog-card.style3 .blog-img {
  position: relative;
}

.blog-card.style3 .blog-img .blog-date {
  position: absolute;
  top: -25px;
  left: -25px;
  background-color: #86af48;
  padding: 15px 18px;
  border-radius: 5px 0 5px 0;
  color: #fff;
  text-align: center;
}

.blog-card.style3 .blog-img .blog-date span {
  display: block;
  font-weight: 600;
}

.blog-card.style3 .blog-info {
  border-bottom: 0;
  padding: 25px 0 0;
}

.blog-card.style3 .blog-info .blog-metainfo {
  margin-bottom: 0;
}

.blog-card.style3 .link {
  display: inline-block;
  text-align: left;
  padding: 0;
}

.blog-card.style3 .link:after {
  display: none;
}

.blog-slider-one .blog-card {
  margin: 0;
}

/*-------------------------------
         Contact CSS
-------------------------------*/

.contact-item-wrap {
  background-color: #f5f5f5;
  padding: 25px 25px 30px;
  border-radius: 5px;
}

.contact-item-wrap .contact-item {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
}

.contact-item-wrap .contact-item:last-child {
  margin-bottom: 0;
}

.contact-item-wrap .contact-item h3 {
  font-size: 22px;
  margin: 0 0 12px;
}

.contact-item-wrap .contact-item p,
.contact-item-wrap .contact-item a {
  display: block;
  color: #666666;
  margin-bottom: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-item-wrap .contact-item p:hover,
.contact-item-wrap .contact-item a:hover {
  color: #86af48;
}

#contactForm {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 25px 20px;
  border-radius: 5px;
}

#contactForm .form-group {
  margin: 0 0 20px;
}

#contactForm .form-group input,
#contactForm .form-group textarea {
  width: 100%;
  background: #f5f5f5;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}

#contactForm .form-group input:focus,
#contactForm .form-group textarea:focus {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

#contactForm .form-group input {
  height: 60px;
}

#contactForm .form-group textarea {
  height: 150px;
  resize: none;
  padding-top: 18px;
}

.btn.disabled {
  opacity: 1;
}

#msgSubmit {
  font-size: 16px;
  margin-top: 10px;
}

.checkbox {
  display: block;
}

.checkbox.form-check {
  padding-left: 0;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
}

.checkbox label:before {
  content: "";
  appearance: none;
  background-color: transparent;
  border: 1px solid #86af48;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 4px;
}

.checkbox a {
  color: #86af48;
}

.checkbox.style2 label:before {
  border-radius: 50%;
}

.checkbox input:checked+label:before {
  border-color: #86af48;
}

.checkbox input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 3px;
  width: 9px;
  height: 9px;
  border: none;
  background: #86af48;
}

.checkbox.style2 input:checked+label:after {
  border-radius: 50%;
}

/* 
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #86af48;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */

/*-------------------------------
   FAQ & Error Section  CSS
-------------------------------*/

.faq-wrap {
  position: relative;
}

.faq-wrap .accordion {
  margin-bottom: 25px;
}

.accordion-item {
  border: none;
  margin: 0 0 25px;
  background: transparent;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-item .accordion-header {
  margin-bottom: 0;
  position: relative;
  z-index: 3;
}

.accordion-item .accordion-header .faq-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.accordion-item .accordion-header .accordion-button {
  border-radius: 5px 5px 0 0;
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  border-radius: 5px;
  color: #373435;
  text-align: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: none;
  padding: 20px 45px 22px 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #f5f5f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item .accordion-header .accordion-button.collapsed {
  color: #373435;
}

.accordion-item .accordion-header .accordion-button.collapsed span {
  background-color: #86af48 !important;
}

.accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item .accordion-header .accordion-button.collapsed {
  color: #373435;
  background-color: #f5f5f5;
}

.accordion-item .accordion-header .accordion-button.collapsed span {
  background-color: transparent !important;
}

.accordion-item .accordion-header .accordion-button.collapsed span i {
  color: #666666;
}

.accordion-item .accordion-header .accordion-button span {
  display: inline-block;
  min-width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 20px;
  text-align: center;
}

.accordion-item .accordion-header .accordion-button span i {
  position: absolute;
  top: 28px;
  right: 20px;
  font-size: 24px;
  line-height: 0.8;
  color: #fff;
  display: block;
  margin: 0 auto;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-body {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 22px 20px 22px;
}

.accordion-item .accordion-body p {
  margin: 0 0 10px;
}

.accordion-item .accordion-body p:last-child {
  margin: 0;
}

.accordion-collapse {
  border: none;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: #86af48 !important;
  border-color: transparent;
  color: #fff;
}

.accordion-button:not(.collapsed) span i.plus {
  visibility: hidden;
  opacity: 0;
}

.accordion-button:not(.collapsed) span i.minus {
  visibility: visible !important;
  opacity: 1 !important;
}

.error-wrap {
  margin-top: 84px;
}

.error-content {
  text-align: center;
}

.error-content img {
  display: block;
  margin: 0 auto 30px;
  max-width: 600px;
}

.error-content p {
  margin: 0 auto 20px;
  max-width: 520px;
}

/*-------------------------------
    Blog Details CSS
--------------------------------*/

article p,
.project-desc p,
.event-desc p,
.team-desc p,
.terms-wrap p {
  margin-bottom: 20px;
}

article p:last-child,
.project-desc p:last-child,
.event-desc p:last-child,
.team-desc p:last-child,
.terms-wrap p:last-child {
  margin-bottom: 0;
}

article p strong,
.project-desc p strong,
.event-desc p strong,
.team-desc p strong,
.terms-wrap p strong {
  color: #373435;
}

article p a,
.project-desc p a,
.event-desc p a,
.team-desc p a,
.terms-wrap p a {
  color: #86af48;
}

article p a:hover,
.project-desc p a:hover,
.event-desc p a:hover,
.team-desc p a:hover,
.terms-wrap p a:hover {
  text-decoration: underline;
  color: #86af48;
}

article h1,
.project-desc h1,
.event-desc h1,
.team-desc h1,
.terms-wrap h1 {
  font-size: 32px;
  margin-bottom: 15px;
}

article h2,
.project-desc h2,
.event-desc h2,
.team-desc h2,
.terms-wrap h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

article h3,
.project-desc h3,
.event-desc h3,
.team-desc h3,
.terms-wrap h3 {
  font-size: 28px;
  margin-bottom: 15px;
}

article h4,
.project-desc h4,
.event-desc h4,
.team-desc h4,
.terms-wrap h4 {
  font-size: 26px;
  margin-bottom: 15px;
}

article h5,
.project-desc h5,
.event-desc h5,
.team-desc h5,
.terms-wrap h5 {
  font-size: 24px;
  margin-bottom: 15px;
}

article h6,
.project-desc h6,
.event-desc h6,
.team-desc h6,
.terms-wrap h6 {
  font-size: 22px;
  margin-bottom: 15px;
}

article ol,
.project-desc ol,
.event-desc ol,
.team-desc ol,
.terms-wrap ol {
  margin-top: 20px;
  margin-bottom: 25px;
}

article ol li,
.project-desc ol li,
.event-desc ol li,
.team-desc ol li,
.terms-wrap ol li {
  margin-bottom: 15px;
  color: #666666;
  padding-left: 3px;
}

article .content-feature-list,
.project-desc .content-feature-list,
.event-desc .content-feature-list,
.team-desc .content-feature-list,
.terms-wrap .content-feature-list {
  margin: 25px 0 25px;
}

article {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.post-metainfo {
  margin-bottom: 13px;
}

.post-metainfo li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-metainfo li:after {
  position: absolute;
  top: -1px;
  right: -15px;
  content: "/";
  font-size: 16px;
  color: #666666;
}

.post-metainfo li:last-child {
  margin-right: 0;
}

.post-metainfo li:last-child:after {
  display: none;
}

.post-metainfo li a {
  font-size: 13px;
  color: #666666;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-metainfo li a:hover {
  color: #86af48;
}

.post-metainfo li i {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 16px;
  line-height: 0.8;
  color: #86af48;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-img {
  position: relative;
  margin: 0 0 25px;
  display: block;
  border-radius: 10px;
}

.post-img img {
  border-radius: 10px;
}

.wp-block-quote {
  position: relative;
  padding: 35px 35px 35px;
  background-color: #f5f5f5;
  border-left: 5px solid #86af48;
  margin: 30px 0 30px;
  z-index: 1;
}

.wp-block-quote p {
  text-align: center;
  margin: 0;
}

.post-meta-option {
  margin-bottom: 25px;
}

.post-tag ul {
  display: inline-block;
}

.post-tag ul li {
  display: inline-block;
}

.post-tag ul li a {
  color: #555;
  font-size: 16px;
  border-radius: 3px;
}

.post-tag ul li a:hover {
  color: #373435;
}

.post-tag span {
  margin-right: 10px;
  font-weight: 500;
  color: #373435;
}

.post-tag span i {
  position: relative;
  top: 3px;
  color: #86af48;
  margin-right: 3px;
}

.post-share {
  display: inline-block;
}

.post-share span {
  font-weight: 500;
  color: #373435;
}

.post-share ul {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.post-share ul li a {
  border: 1px solid #86af48;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.post-share ul li a:hover {
  border-color: transparent;
}

.post-author {
  padding: 25px;
  margin: 15px 0 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.post-author .post-author-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.post-author .post-author-img img {
  border-radius: 50%;
}

.post-author .post-author-info {
  width: calc(100% - 175px);
  margin-left: 25px;
}

.post-author .post-author-info h4 {
  font-size: 16px;
  color: #373435;
  margin: 0 0 15px;
  font-weight: 400;
}

.post-author .post-author-info h4 a {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
  color: #86af48;
}

.post-author .post-author-info p {
  margin: 0 0 15px;
}

.comment-item-wrap .comment-item {
  padding: 0 0 25px 0;
  margin-bottom: 25px;
  border-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.comment-item-wrap .comment-item.reply {
  margin-left: 40px;
}

.comment-item-wrap .comment-item:last-child {
  margin-bottom: 0;
}

.comment-item-wrap .comment-item .comment-author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  top: 4px;
}

.comment-item-wrap .comment-item .comment-author-img img {
  border-radius: 50%;
}

.comment-item-wrap .comment-item .comment-author-wrap {
  width: calc(100% - 120px);
  margin-left: 20px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name {
  margin: 0 0 9px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name h5 {
  line-height: 1;
  margin: 0 20px 0 0;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name .comment-date {
  display: inline-block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #666666;
  margin-left: 10px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-text {
  margin: 0 0 10px;
}

.comment-item-wrap .reply-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  color: #86af48;
  font-size: 14px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.comment-item-wrap .reply-btn:hover {
  color: #86af48;
}

.comment-box-title {
  margin-bottom: 30px;
}

.comment-box-title h4 {
  margin-bottom: 0;
  line-height: 1;
}

.comment-box-title p {
  margin-top: 10px;
  margin-bottom: 0;
}

#cmt-form {
  margin-top: 35px;
}

#cmt-form .comment-form .form-group {
  margin: 0 0 20px;
  position: relative;
}

#cmt-form .comment-form .form-group>label {
  font-weight: 500;
  display: block;
  margin: 0 0 5px;
  padding-left: 0;
}

#cmt-form .comment-form .form-group input,
#cmt-form .comment-form .form-group textarea {
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  padding: 15px;
  color: #373435;
}

#cmt-form .comment-form .form-group input:focus,
#cmt-form .comment-form .form-group textarea:focus {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#cmt-form .comment-form .form-group input {
  height: 60px;
}

#cmt-form .comment-form .form-group textarea {
  height: 145px;
  resize: none;
}

#cmt-form .comment-form .form-check {
  margin-bottom: 25px;
}

.post-cat-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #86af48;
}

.post-cat-title {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #86af48;
}

/*-------------------------------
    Event  Section CSS
-------------------------------*/

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.social-profile.style1 li a,
.social-profile.style2 li a,
.social-profile.style3 li a,
.promo-wrap.style2 .promo-card .promo-icon,
.project-card.style2 .like-btn,
.project-card.style3 .like-btn,
.project-card.style4 .project-img .like-btn,
.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author,
.donate-box .donate-amt,
.fund-collection,
.owl-carousel .owl-nav,
.pp-post-item,
.promo-wrap.style2,
.donation-form .radionbtn-wrap,
.counter-card-wrap,
.post-author,
.comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.header-wrap .header-top .header-top-left,
.header-wrap .header-top .header-top-left .contact-info,
.header-wrap .header-top .header-top-left .contact-info li,
.header-wrap .header-top .header-top-right,
.select-lang .navbar-language .dropdown-toggle,
.event-info-item-wrap .event-info-item,
.project-card.style4,
.counter-card-wrap.style2 .counter-card,
.counter-card-wrap.style3 .counter-card,
.event-card,
.team-card.style2 .team-info-wrap,
.progess-bar-item,
.blog-card .blog-info .blog-author {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

/*---------------------------------
        Footer CSS 
-----------------------------------*/

.footer-wrap {
  position: relative;
  background-color: #86af48;
}

.footer-wrap .footer-top {
  background-color: #181818;
  position: relative;
  z-index: 1;
}

.footer-wrap .footer-top .footer-shape-one,
.footer-wrap .footer-top .footer-shape-two,
.footer-wrap .footer-top .footer-shape-three,
.footer-wrap .footer-top .footer-shape-four {
  position: absolute;
  z-index: -1;
}

.footer-wrap .footer-top .footer-shape-one {
  bottom: 0;
  left: 15px;
}

.footer-wrap .footer-top .footer-shape-two {
  top: 20px;
  right: 45px;
}

.footer-wrap .footer-top .footer-shape-three {
  top: 120px;
  right: 0;
}

.footer-wrap .footer-top .footer-shape-four {
  top: 60px;
  right: 0;
}

.footer-wrap .footer-bottom {
  background-color: #373435;
}

.footer-wrap .footer-widget {
  margin-bottom: 25px;
}

.footer-wrap .footer-widget .footer-widget-title {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 36px;
  padding-bottom: 20px;
}

.footer-wrap .footer-widget .footer-widget-title:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  content: "";
  width: 100px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .footer-widget-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 35px;
  height: 2px;
  background-color: #86af48;
}

.footer-wrap .footer-widget .footer-logo {
  display: block;
}

.footer-wrap .footer-widget .comp-desc {
  color: rgba(255, 255, 255, 0.8);
  margin: 25px 0 30px 0;
  padding-bottom: 25px;
  position: relative;
}

.footer-wrap .footer-widget .comp-desc:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .comp-desc:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 80px;
  height: 2px;
  background-color: #86af48;
}

.footer-wrap .footer-widget .newsletter-form {
  position: relative;
}

.footer-wrap .footer-widget .newsletter-form input {
  width: 100%;
  height: 60px;
  border-radius: 10px 0 10px 0;
  padding: 13px 105px 12px 18px;
  color: #fff;
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.footer-wrap .footer-widget .newsletter-form button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #86af48;
  color: #fff;
  border: none;
  height: 100%;
  border-radius: 0 0 10px 0;
  padding: 12px 20px;
}

.footer-wrap .footer-widget .footer-menu li {
  margin-bottom: 20px;
}

.footer-wrap .footer-widget .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-wrap .footer-widget .footer-menu li a {
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  padding-left: 25px;
}

.footer-wrap .footer-widget .footer-menu li a:after {
  position: absolute;
  top: 8px;
  left: 0;
  content: "";
  width: 16px;
  height: 1px;
  background-color: #86af48;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.footer-wrap .footer-widget .footer-menu li a:hover {
  color: #86af48;
  padding-left: 28px;
}

.footer-wrap .footer-widget .contact-info li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}

.footer-wrap .footer-widget .contact-info li:last-child {
  margin-bottom: 0;
}

.footer-wrap .footer-widget .contact-info li h6 {
  color: #fff;
  font-weight: 500;
  margin: 0 0 5px;
}

.footer-wrap .footer-widget .contact-info li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 16px;
  line-height: 1;
  color: #86af48;
}

.ct_leave_reply_main {
  padding-inline: 0px !important;
}

.footer-wrap .footer-widget .contact-info li p,
.footer-wrap .footer-widget .contact-info li a {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer-wrap .footer-widget .contact-info li:hover p,
.footer-wrap .footer-widget .contact-info li:hover a {
  color: #86af48;
}

.footer-bottom {
  padding: 18px 10px;
}

.footer-bottom .copyright-text {
  margin: 0;
  color: #fff;
}

.footer-bottom .copyright-text i {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.footer-bottom .copyright-text span {
  color: #86af48;
}

.footer-bottom .copyright-text a {
  color: #86af48;
  font-weight: 500;
}

.footer-bottom .copyright-text a:hover {
  text-decoration: underline;
}

.footer-bottom .social-profile {
  text-align: right;
}

.footer-bottom .social-profile li a {
  background-color: #86af48;
  color: #fff;
  font-size: 18px;
}

.footer-bottom .social-profile li a i {
  color: #373435;
}

.footer-bottom .social-profile li a:hover {
  background-color: #86af48;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.footer-bottom .social-profile li a:hover i {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .footer-wrap .footer-bottom .copyright-text {
    margin-bottom: 15px;
  }

  .footer-wrap .footer-bottom .copyright-text,
  .footer-wrap .footer-bottom .social-profile {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap .footer-top .footer-shape-one {
    left: auto;
    right: 0px;
  }

  .footer-wrap .footer-top .footer-shape-two {
    right: 25px;
    top: 10px;
  }

  .footer-wrap .footer-top .footer-shape-four {
    right: 0px;
    top: 20px;
    max-width: 75px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  .footer-wrap .footer-widget .comp-desc {
    font-size: 14px;
    margin: 15px 0 22px;
    padding-bottom: 15px;
  }

  .footer-wrap .footer-widget .contact-info li {
    margin-bottom: 10px;
  }

  .footer-wrap .footer-widget .contact-info p,
  .footer-wrap .footer-widget .contact-info a {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .footer-menu li {
    margin-bottom: 8px;
  }

  .footer-wrap .footer-widget .footer-menu li a {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .footer-menu li a i {
    font-size: 14px;
    top: -3px;
  }

  .footer-wrap .footer-bottom .social-profile li a {
    width: 30px;
    height: 30px;
  }

  .footer-wrap .footer-bottom .social-profile li a i {
    font-size: 14px;
  }

  .ct_get_quote_form {
    padding: 60px 15px 50px !important;
  }

  .ct_mob_reverse_row {
    flex-direction: column-reverse;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-block: 30px;
  }

  .ct_director_img img {
    height: 250px;
  }

  .ct_ddirector_info {
    padding: 30px 0px;
  }

  .ct_mob_d_block {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .footer-wrap .footer-top .footer-shape-one {
    max-width: 140px;
  }

  .footer-wrap .footer-top .footer-shape-two {
    max-width: 70px;
  }

  .footer-wrap .footer-top .footer-shape-three {
    max-width: 70px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    margin-bottom: 22px;
    padding-bottom: 15px;
  }

  .footer-wrap .footer-widget .newsletter-form input,
  .footer-wrap .footer-widget .newsletter-form button {
    font-size: 14px;
  }

  .footer-wrap .footer-widget .newsletter-form input {
    height: 55px;
    padding: 12px 130px 12px 15px;
  }

  .footer-wrap .footer-widget .newsletter-form button {
    padding: 12px 16px;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    background-size: cover;
  }

  .ct_drop_icon img {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-wrap .footer-top .footer-shape-four {
    top: 40px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form input,
  .footer-wrap .footer-top .footer-widget .newsletter-form button {
    font-size: 14px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form input {
    height: 55px;
    padding: 12px 130px 12px 15px;
  }

  .footer-wrap .footer-top .footer-widget .newsletter-form button {
    padding: 12px 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

  .footer-menu li a,
  .comp-desc,
  .footer-wrap .footer-widget .contact-info li p,
  .footer-wrap .footer-widget .contact-info li a {
    font-size: 15px;
  }

  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
  }
}

/*# sourceMappingURL=style.css.map */

.ct_card_box {
  text-align: center;
  padding: 40px 20px 20px;
  width: 250px;
  height: 250px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s ease all;
  z-index: 1;
}

.ct_card_box:before {
  content: "";
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(right top,
      #86af48,
      #6ac610) repeat scroll 0 0;
  border-radius: 100% 0px 100% 100%;
  z-index: -1;
  transition: 0.4s ease all;
}

.ct_card_box:hover:before {
  transform: rotate(-45deg);
}

/* .ct_card_box figure{
    transition: 0.4s ease all;
}
.ct_card_box:hover figure {
    transform: rotate(45deg);
} */
.ct_card_icon {
  margin-bottom: 25px;
}

.ct_card_icon img {
  transition: 0.4s ease all;
}

.ct_card_box:hover .ct_card_icon img {
  transform: scale(1.2);
}

.ct_card_box figcaption {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

/* 
.ct_srvc_icon img {
    max-width: 210px;
    width: 210px;
    height: 241px;
    object-fit: cover;
} */
/* .ct_srvc_card {
    display: flex;
    align-items: center;
    gap: 25px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #FFF;
    transition: 0.4s ease all;
    overflow: hidden;
} */

/* .ct_srvc_card:hover{
    background-color: #86af48;
    border-color: #86af48;
}
.ct_srvc_card:hover .ct_srvc_cnt h3,.ct_srvc_card:hover .ct_srvc_cnt p{
    color: #fff;
}
.ct_srvc_card .ct_srvc_cnt h3 {
    font-size: 22px;
    margin-bottom: 15px;
}
.ct_srvc_card .ct_srvc_cnt p {
    margin-bottom: 0px;
}
.ct_srvc_cnt {
    padding: 20px 20px  20px 0px ;
} */

.project-wrap {
  position: relative;
  z-index: 1;
}

.ct_font_600 {
  font-weight: 500 !important;
}

.ct_bordr_left {
  font-size: 20px;
  border-left: 3px solid #8bc34a;
  padding-left: 15px;
  color: #373437;
}

/* .project-wrap:before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url(../../assets/img/shape_top_left.png);
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    z-index: -1;

} */
/* .project-wrap:after{
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(../../assets/img/shape_top_right.png);
    width: 250px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    z-index: -1;

} */
.ct_srvc_card_box {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  overflow: hidden;
  transition: 0.4s ease all;
  position: relative;
  height: 100%;
  margin-right: 25px;
  display: grid;
}

.ct_srvc_cnt {
  padding: 20px;
  text-align: center;
}

.ct_srvc_card_box .ct_srvc_cnt h3 {
  font-size: 22px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
}

.ct_srvc_card_box .ct_srvc_cnt p {
  margin-bottom: 0px;
  font-size: 16px;
  overflow: hidden;
  /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}

.ct_srvc_body {
  padding: 25px 30px 5px;
}

.ct_srvc_icon {
  text-align: center;
  background-color: #f8f7f0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: auto;
  transition: 0.4s ease all;
  position: relative;
}

.ct_srvc_icon img {
  transition: 0.4s ease all;
  position: relative;
  width: 40px !important;
}

.ct_srvc_card_box .ct_srvc_btm_img {
  overflow: hidden;
  position: relative;
  display: flex;
  align-self: flex-end;
}

.ct_srvc_card_box:hover .ct_srvc_icon img {
  transform: rotate(360deg);
}

.ct_srvc_card_box .ct_srvc_btm_img img {
  width: 100%;
  transition: 0.4s ease all;
  height: 200px;
  object-fit: cover;
}

.ct_srvc_card_box:hover .ct_srvc_btm_img img {
  transform: scale(1.2);
}

.ct_overlay_btn {
  position: absolute;
  z-index: 99;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s ease all;
}

.ct_srvc_card_box:hover .ct_srvc_btm_img .ct_overlay_btn {
  bottom: -0%;
}

.ct_srvc_btm_img:before {
  content: "";
  content: "";
  background-color: #373435;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  z-index: 1;
}

.ct_srvc_btm_img:hover:before {
  opacity: 0.3;
}

.theme-dark .ct_srvc_card_box {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  background-color: #333;
}

.ct_client_brand_img img {
  max-width: 200px;
  margin: auto;
}

.ct_remove_animtion {
  animation: none !important;
}

.theme-dark .ct_client_brand_img {
  background-color: #fff;
  height: 100px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding: 10px;
}

.ct_client_brand .owl-nav,
.ct_client_brand .owl-dots {
  display: none;
}

.ct_new_letter_bg {
  background-image: url(../../../assets/DashboardAssets/assets/newletr_bg.jpg);
  /* background-image: linear-gradient(to right, rgb(141 180 74 / 80%) , rgb(228 233 99 / 60%)); */
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_new_letter_bg:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #000;
  opacity: 0.6;
  z-index: -1;
}

.ct_news_lettr_text h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 15px;
}

.ct_news_lettr_text p {
  color: #fff;
}

.ct_white_bg_btn .btn {
  background-color: #fff;
  color: #333 !important;
  width: 180px;
}

.ct_white_bg_btn .btn:hover {
  color: #fff !important;
}

.ct_news_lettr_text p {
  margin-bottom: 0px;
}

.ct_small_form_news input {
  width: 100%;
  height: 56px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: 1px dashed #9e9eab;
}

.ct_newsltr_btn {
  width: 100% !important;
}

.ct_white_bg_btn .btn.ct_newsltr_btn {
  background-color: #333;
  color: #fff !important;
  width: 180px;
}

.ct_news_letr_head svg {
  font-size: 60px;
  fill: #fff;
}

.ct_dashboard_right_cnt {
  padding: 30px 30px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  border-top: 4px solid #86af48;
}

.ct_dashboard_icon {
  background-color: #f8f7f0;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  margin-bottom: 15px;
  margin-inline: auto;
}

.ct_dashboard_cnt p {
  margin-bottom: 0px;
}

.ct_dashboard_cnt h4 {
  font-size: 22px;
}

.ct_dashboard_img {
  position: relative;
  animation: move 2s ease infinite;
  /* border: 2px solid #86af48;
    border-radius: 10px; */
  overflow: hidden;
}

.ct_dashboard_img img {
  width: 100%;
  height: auto;
  margin: auto;
  object-fit: contain;
}

.theme-dark .ct_dashboard_cnt h4,
.theme-dark .ct_dashboard_cnt p {
  color: #333 !important;
}

.ct_tabs_main .nav {
  justify-content: center;
  gap: 15px;
}

button.btn-close {
  position: absolute !important;
  right: 16px !important;
  background-color: #86af48;
  opacity: 1 !important;
  top: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1 !important;
}

.ct_padd_top_0 {
  padding-top: 0px !important;
}

.ct_padd_inline_0 {
  padding-inline: 0px !important;
}

button.btn-close i.fa.fa-times {
  color: #fff;
  font-size: 18px;
}

.opacity-animate3 {
  animation: opt-animation3 1s;
  animation-fill-mode: forwards;
}

.ct_modal_bg .modal-body {
  padding: 25px;
  text-align: center;
  background: rgb(134 175 72 / 30%);
}

@-webkit-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ct_tabs_main .tab-pane.active {
  display: block;
  opacity: 1;
}

.ct_tabs_main .nav a.nav-link {
  width: 120px;
  text-align: center;
  color: #333;
}

/* .ct_tabs_main .nav-pills .nav-link.active, .ct_tabs_main .nav-pills .show>.nav-link {
    background-color: #86af48;
    color: #fff;
} */
.ct_tabs_main .nav-pills .nav-link.active {
  background-color: #86af48;
  color: #fff;
}

.ct_project_main_div button:hover {
  background-color: unset !important;
  border: 0px !important;
}

.ct_project_main_div button {
  border: 0px !important;
}

.ct_project_main_div button.owl-prev:after {
  content: "Prev";
  margin-left: 5px;
  font-weight: 500;
}

.ct_project_main_div button.owl-next:before {
  content: "Next";
  margin-right: 5px;
  font-weight: 500;
}

.ct_project_main_div button span {
  font-size: 34px;
  position: relative;
  top: -4px;
}

.ct_project_main_div button span {
  font-size: 34px;
}

.ct_project_main_div .owl-nav {
  gap: 15px;
}

.ct_project_main_div .owl-nav button:hover,
.ct_project_main_div button:hover span {
  color: #86af48 !important;
}

.item-content .item-meta h4,
.item-content .item-meta p {
  color: #fff;
}

.item-content .item-meta {
  opacity: 0;
  transform: translateX(-10px);
}

.item-content .item-meta {
  opacity: 0;
  transform: translateX(-10px);
  transition: 0.4s ease all;
}

.ct_project_main_div .vertical-item:hover .item-content .item-meta {
  opacity: 1;
  transform: translateX(0px);
}

/* 

@keyframes move{
    0%,50%,100%{
        transform: translateY(0px);
    }
    25%,50%{
        transform: translateY(-10px);
    }
} */

/* our project css S */

.ds {
  background-color: #333333;
  color: #a4a4a4;
}

.cornered-heading {
  margin-top: -8px;
  display: inline-block;
  max-width: 95%;
  margin-right: 22px;
  margin-left: 22px;
}

.bottommargin_55 {
  margin-bottom: 55px;
}

.topmargin_40 {
  margin-top: 40px;
}


/* ouer mission S */
/* .ct_icon svg {
    width: 25px;
    color: #333;
} */
.ct_icon i {
  font-size: 28px;
  color: #fff;
}

.ct_our_mission_bg {
  background-image: url(../../../assets/DashboardAssets/assets/NaureBasedSolutions.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  height: 100%;
  position: relative;
  width: 100%;
  transition: 0.4s ease all;
  overflow: hidden;
  z-index: 1;
}

.ct_our_mission_bg2 {
  background-image: url(../../../assets/DashboardAssets/assets/CarbonRemoval.jpg);
}

.ct_our_mission_bg:hover .ct_overlay_mission_cnt {
  top: 50%;
}

.ct_overlay_mission_cnt {
  width: 100%;
  position: absolute;
  top: -50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding: 30px;
  transition: 0.4s ease all;
  background: #86af4866;
  display: block;
  height: 100%;
  z-index: -1;
}

.ct_overlay_mission_cnt {
  text-align: center;
}

.ct_overlay_mission_cnt .ct_icon svg {
  fill: #fff;
  margin-bottom: 15px;
}

.ct_mission_info h4,
.ct_mission_info p {
  color: #fff;
}

.ct_our_mission_main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ct_background_clr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #86af48;
}

.ct_our_mission_left_bg ul.content-feature-list li i {
  color: #fff;
}

.ct_our_mission_left_bg {
  max-width: 650px;
  float: right;
  padding-inline: 15px;
  padding-block: 100px;
}

.ct_overlay_center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  padding: 30px;
}

.ct_our_mission_bg:hover .ct_mission_title {
  display: none;
}

.ct_mission_title h4 svg {
  width: 28px;
  fill: #fff;
}

.ct_mission_title h4 {
  color: #fff;
}

.ct_mission_title {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  height: 60px;
  transition: 0.4s ease all;
}

.ct_our_mission_bg:after {
  content: "";
  position: absolute;
  bottom: 0px;
  background: -webkit-linear-gradient(bottom,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0));
  width: 100%;
  height: 122px;
  left: 0px;
  z-index: -1;
}

.team-card.style3 {
  width: 320px;
  margin: auto;
  display: block;
}

.ct_white_bg_overlay {
  position: relative;
  z-index: 1;
}

.ct_white_bg_overlay:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../../../assets/DashboardAssets/assets/white_background_bg.png");
  /*background-position: center;*/
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-size: cover;
  z-index: -1;
}

.theme-dark .ct_white_bg_overlay:before {
  opacity: 0.2;
}

/* service Page css S */

.ct_service_bg {
  background-image: url(../../../assets/DashboardAssets/assets/service_bg.jpg);
}

.ct_life_cycle_bg {
  background-image: url(../../../assets/pseudo.png);
}

.ct_chain_connection {
  position: relative;
}

.ct_chain_connection:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 10px;
  border: 2px solid #86af48;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  -webkit-box-reflect: right -3px;
  z-index: -1999;
}

.ct_service_card_bg {
  text-align: center;
  /* background-image: url(../../assets/img/Project_1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
  padding: 50px 30px;
  position: relative;
  box-shadow: 0 4px 16px 0 rgba(51, 51, 51, 0.1);
  z-index: 1;
  height: 100%;
}

.ct_inner_srvc_info h4 {
  font-size: 22px;
  margin: 25px 0px 18px;
}

.ct_inner_srvc_info hr {
  height: 2px;
  width: 40px;
  background: #333;
  opacity: 1;
  margin: 20px auto 25px;
}

.ct_service_card_main {
  position: relative;
  height: 100%;
}

.ct_srvc_overlay_bg {
  position: absolute;
  inset: 0px;
  background-color: #fff;
  z-index: -1;
  transition: 0.4s ease all;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}

.ct_show_more_div {
  display: none;
}

.theme-dark .ct_srvc_overlay_bg {
  background-color: #333;
}

.theme-dark .ct_service_card_bg:hover .ct_srvc_overlay_bg {
  background-color: rgb(51 51 51 / 90%);
}

.theme-dark .ct_inner_srvc_info hr {
  background-color: #fff;
}

.ct_service_card_bg:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: rgb(134 175 72 / 21%);
  left: 0px;
  bottom: 0;
  transition: 0.4s ease all;
  z-index: -1;
}

.ct_service_card_bg:hover:after {
  height: 100%;
}

.ct_service_card_bg:hover .ct_srvc_card_icon img {
  animation: scaleDisappear 0.5s;
}

@keyframes scaleDisappear {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  60% {
    opacity: 0;
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* .ct_sub_heading {
    position: relative;
    width: fit-content;
    margin-inline: auto;
  
} */
.ct_sub_heading {
  position: relative;
  width: fit-content;
  margin-inline: auto;
  margin-top: -56px;
  background-color: #86af48;
  padding: 10px 30px;
  color: #fff;
  border-radius: 30px 0px 30px 30px;
  text-transform: uppercase;
}

/*   
  @keyframes water {
    0% {
      background-position: 0px -150px;
    }
    50% {
      background-position: -300px -50px; 
    }
    100% {
      background-position: 0px -150px;
    }
  } */
/* .ct_sub_heading:before {
    content: "";
    position: absolute;
    top: -6px;
    left: -12px;
    width: 25px;
    height: 25px;
    border: 3px solid #86af48;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.ct_sub_heading:after {
    content: "";
    position: absolute;
    bottom: -4px;
    right: -12px;
    width: 25px;
    height: 25px;
    border: 3px solid #86af48;
    border-left-width: 0px;
    border-top-width: 0px;
} */

.vertical-item .item-media img {
  height: 169px;
  object-fit: cover;
}

.vertical-item.gallery-item .item-media img {
  height: 636px;
  object-fit: cover;
}

/* get a quote section S */

.ct_get_quote_bg {
  background-image: url(../../../assets/DashboardAssets/assets/get_quote_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
}

.ct_get_quote_bg:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #373435;
  opacity: 0.5;
  z-index: -1;
}

.ct_get_quote_form {
  background-color: #fff;
  padding: 60px 60px 50px;
}

.theme-dark .ct_get_quote_form {
  background-color: #333;
}

.theme-dark .ct_get_quote_form input,
.theme-dark .ct_get_quote_form textarea {
  border-width: 0px;
  border-bottom-width: 1px;
}

.theme-dark .ct_get_quote_form input:focus~label,
.theme-dark .ct_get_quote_form input:valid~label,
.theme-dark .ct_get_quote_form textarea:focus~label,
.theme-dark .ct_get_quote_form textarea:valid~label {
  color: #fff;
}

.ct_get_quote_form .form-group {
  position: relative;
  margin-bottom: 35px;
}

.ct_get_quote_form input,
.ct_get_quote_form textarea {
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: unset !important;
  color: #000000 !important;
}

.ct_get_quote_form input:focus,
.ct_get_quote_form textarea:focus {
  outline: none;
}

.ct_get_quote_form label {
  position: absolute;
  top: 10px;
  left: 5px;
  color: #999;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  transition: all 0.2s ease;
}

.ct_get_quote_form input:focus~label,
.ct_get_quote_form input:valid~label,
.ct_get_quote_form textarea:focus~label,
.ct_get_quote_form textarea:valid~label {
  top: -18px;
  font-size: 16px;
  color: #333;
}

.ct_get_quote_form .bar {
  display: block;
  position: relative;
  width: 100%;
  top: 2px;
}

.ct_get_quote_form .bar:before,
.ct_get_quote_form .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #86af48;
  transition: all 0.2s ease;
}

.ct_get_quote_form .bar:before {
  left: 50%;
}

.ct_get_quote_form .bar:after {
  right: 50%;
}

.ct_get_quote_form input:focus~.bar:before,
.ct_get_quote_form input:focus~.bar:after,
.ct_get_quote_form textarea:focus~.bar:before,
.ct_get_quote_form textarea:focus~.bar:after {
  width: 50%;
}

/* contact page css S */
.ct_contact_bg {
  background-image: url(../../../assets/DashboardAssets/assets/contact_bg_new.jpg);
  background-position: center;
  background-size: cover;
}

.ct_form_wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 25px 20px;
  border-radius: 5px;
}

/* service detail css S */
.ct_srvc_sidebar ul li a {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(82, 195, 6, 0.2);
  padding: 15px 20px;
  color: #333;
  font-size: 16px;
  text-transform: capitalize;
  z-index: 1;
  transition: all 300ms ease;
}

.ct_srvc_sidebar ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  border-left: 3px solid #86af48;
  background: #444444;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ct_srvc_sidebar ul li:hover:before,
.ct_srvc_sidebar ul li.active:before {
  width: 100%;
}

.ct_hover_img img {
  width: 100%;
}

.ct_srvc_sidebar li:hover a,
.ct_srvc_sidebar li.active a {
  color: #fff;
}

.ct_srvc_sidebar ul li {
  position: relative;
  display: block;
  background: url(../../../assets/DashboardAssets/assets/bg-pattern-1.png) center center repeat;
  margin-bottom: 15px;
}

.ct_widget-inner {
  background: #eaecf3b8;
  padding: 50px 50px 30px;
}

.theme-dark .ct_widget-inner {
  background-color: #333;
}

.theme-dark .ct_srvc_sidebar ul li {
  background-image: unset;
}

.ct_widget-inner h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.ct_widget-inner ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ct_widget-inner ul li a {
  position: relative;
  display: block;
  padding: 15px 0px;
  line-height: 30px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
}

.ct_bottom_card_img img {
  width: 100%;
  border-radius: 10px;
}

.ct_bottom_card {
  position: relative;
}

.ct_bottom_card_info {
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 93%;
  text-align: center;
  border-bottom: 3px solid #86af48;
}

.ct_bottom_card_info ul.social-profile a {
  background: #86af48;
}

.ct_bottom_card_info ul.social-profile a i {
  color: #fff;
}

/* product tab section S */
.ct_prdct_tab_1 div {
  box-sizing: border-box;
  transition: all ease-in-out 0.3s;
}

.ct_prdct_icetab {
  display: inline-block;
  border-bottom: 0px;
  margin: 0px;
  color: #333;
  cursor: pointer;
  border-right: 0px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.team-info a {
  color: #373435 !important;
}

.theme-dark .ct_prdct_icetab {
  color: #fff;
}

#ct_icetab-content {
  overflow: hidden;
  position: relative;
}

.team-slider-one.owl-carousel .owl-nav.disabled {
  display: flex !important;
  align-items: center;
}

.team-slider-one.owl-carousel .owl-nav.disabled button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #86af48;
}

.team-slider-one.owl-carousel .owl-nav.disabled button:hover {
  background-color: #86af48;
  color: #fff;
}

.team-slider-one.owl-carousel .owl-nav.disabled button span {
  display: none !important;
}

.team-slider-one.owl-carousel .owl-nav.disabled button.owl-prev:before {
  content: "\f134";
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.team-slider-one.owl-carousel .owl-nav.disabled button.owl-next:before {
  content: "\f133";
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.ct_tabcontent1 {
  position: absolute;
  left: 0px;
  top: 0px;
  /* background: #fff; */
  width: 100%;
  border-top: 0px;
  border-top: 0px;
  transform: scale(0);
}

.ct_tabcontent1:first-child {
  position: relative;
}

.ct_tabcontent1.ct_tab-active {
  border-top: 0px;
  display: block;
  transform: scale(1);
}

.ct_box_shadow {
  background-color: rgb(255 255 255 / 41%);
  padding: 30px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.ct_tabcontent1 {
  padding: 40px 0px;
}

.ct_prdct_icetab {
  padding: 8px 16px;
  width: 140px;
  border-radius: 5px;
}

.ct_current-tab {
  background-color: #86af48;
  color: #fff;
}

div#ct_prdct_icetab-container {
  text-align: center;
}

/* about page css S */

.ct_mob_d_block {
  display: none;
}

.ct_ceo_img img {
  width: 250px;
  height: 250px;
  border-radius: 100%;
  border: 5px solid #f5f5f5;
  margin: auto;
  display: block;
}

.ct_ceo_img {
  margin-bottom: 20px;
}

.ct_ceo-card_main {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
  position: relative;
  transition: 0.4s ease all;
  z-index: 1;
  border-radius: 10px;
  height: 100%;
}

.ct_ceo-card_main:before {
  content: "";
  position: absolute;
  top: 0px;
  background-color: #86af4866;
  width: 100%;
  left: 0px;
  height: 300px;
  z-index: -1;
  border-radius: 10px 10px 0px 0px;
}

.ct_inner_ceo_card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  z-index: 1;
  height: 100%;
}

.theme-dark .ct_ceo-card_main {
  background-color: #333;
}

.theme-dark .ct_inner_ceo_card {
  background-color: #333;
}

.ct_ceo_info .ct_bottom_card_info {
  position: unset;
  left: 0px;
  margin: 0px;
  width: 100%;
  padding: 10px 0px 0px;
  transform: translateX(0);
}

.ct_inner_ceo_card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed #86af48;
  bottom: 0%;
  left: 0%;
  border-radius: 10px;
  z-index: -1;
}

.ct_nav_owl_none button {
  display: none !important;
}

.ct_z_index_1 {
  z-index: -1 !important;
}

.ct_more_link {
  display: none;
}

/* Esg Reporting service S */
.ct_dash_esg_cnt h2 {
  font-size: 40px;
  line-height: 50px;
}

.ct_srvc_point h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

ul.ct_srvc_point {
  margin-bottom: 0px;
  padding-left: 0px;
}

ul.ct_srvc_point li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

ul.ct_srvc_point li img {
  width: 50px;
  height: 50px;
  border: 1px solid #9dbe4f;
  padding: 10px;
  border-radius: 45px;
}

.ct_dash_esgimg img {
  width: 100%;
  animation: moveimg 3s ease infinite;
}

@keyframes moveimg {

  0%,
  50%,
  100% {
    transform: translateY(-10px);
  }

  25%,
  75% {
    transform: translateY(0px);
  }
}

ul.ct_srvc_point li p {
  margin-bottom: 0px;
}

/* on scrool section S */
#div01 {
  /* height: 100vh; */
  width: 100vw;
  /* background-color: blue; */
  top: 800px;
  left: 0px;
}

#logoDove,
#logoDog,
#logoFish {
  display: none;
}

.ct_scroll_section {
  /* height: 100vh; */
  width: 100vw;
  top: 800px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* #div02 {
    background-color: green;
}
#div03 {
    background-color: orange;
}
#div04 {
    background-color: purple;
} */

.ct_scrool_cnt {
  position: relative;
  top: 0px;
  /* left: 30px; */
}

.ct_scroll_fixed_img img {
  background-repeat: no-repeat;
  background-size: cover;
}

.ct_scroll_fixed_cnt {
  padding-inline: 30px;
}

.ct_round_num p {
  background-color: #f5f5f5;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 100px;
  color: #86af48;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ct_scroll_fixed_cnt .ct_round_num p:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #86af48;
  margin: auto;
  display: block;
  width: 0%;
  height: 0%;
  z-index: -1;
  transition: 0.4s ease all;
  border-radius: 100px;
}

.ct_scroll_fixed_cnt:hover .ct_round_num p {
  color: #fff;
}

.ct_scroll_fixed_cnt:hover .ct_round_num p:before {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ct_green_background {
  background-color: #86af48;
  text-align: center;
  padding-bottom: 166px !important;
}

.ct_green_background:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-image: url(../../../assets/DashboardAssets/assets/bg_shape_dot.png);
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  animation: ct_move_X 6s ease infinite;
}

.en_faq_main_div .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.en_faq_main_div .accordion-item .accordion-header .accordion-button.collapsed:after {
  content: "";
  width: 2px;
  height: 15px;
  background-color: red;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.en_faq_main_div .accordion-item .accordion-header button.accordion-button.collapsed:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  background: #000;
  width: 15px;
  height: 2px;
  transform: translateY(-50%);
}

.en_faq_main_div .accordion-item .accordion-header button.accordion-button.collapsed:after {
  content: "" !important;
  position: absolute;
  top: 50%;
  display: block !important;
  right: 22px;
  background: #000;
  width: 2px;
  height: 15px;
  transform: translateY(-50%);
}

@keyframes ct_move_X {

  0%,
  50%,
  100% {
    transform: translateX(10px);
  }

  25%,
  75% {
    transform: translateX(0px);
  }
}

.ct_globel_logo_img {
  background-color: #f8f6f2;
  padding: 15px;
  /* width: 33.33%; */
}

.ct_globel_logo_img img {
  max-width: 100%;
  height: 80px;
  object-fit: contain;
  margin: auto;
  display: block;
}

.ct_globel_logo_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  position: relative;
  margin-top: 0px;
  padding: 80px;
  border-radius: 10px;
  margin-top: -154px;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.ct_globel_partner_bg {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  position: relative;
  margin-top: -85px;
  margin-bottom: 100px;
  border-radius: 10px;
}

span.ct_clr_text {
  color: #86af48;
  font-size: 32px;
}

.ct_nwsletr_bg1 {
  position: relative;
  padding-block: 100px;
  z-index: 1;
  background: #f8f6f2;
}

.ct_nwsletr_bg1:before {
  content: "";
  position: absolute;
  inset: 0px;
  opacity: 0.3;
  width: 100%;
  height: 100%;

  background-image: url("../../../assets/DashboardAssets/assets/newsletter-background12.webp");
  z-index: -1;
}

.ct_newletter_bg {
  text-align: center;
}

.ct_shake_btn {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.ct_min_img img {
  width: auto;
  height: 400px;
  object-fit: contain;
}

.ct_accelerate_bg {
  background-image: url(../../../assets/DashboardAssets/assets/Accelerate_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_small_title h4 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 14px;
}

.ct_bg_grey_1 {
  background-color: #f5f5f5;
  /* padding: 30px; */
  border: 1px solid #aaaa;
  padding: 15px;
  border-radius: 10px;
}

.ct_how_works_bg {
  /* background-image: url(../../assets/img/how_it_works2.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
}

/* .ct_how_works_bg p {
    font-weight: 500;
} */
.ct_sm_btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  outline: none;
  border-radius: 5px;
  background-color: #86af48;
  color: #fff;
  font-size: 14px;
  border: 1px solid #86af48;
}

/* .ct_light_green_bg {
    background-color: #86af4836;
} */
.ct_light_yeloow {
  background: #dfe66124;
}

h4.ct_md_btn {
  /* background-color: #86af48; */
  color: #fff;
  padding: 6px 40px;
  border-radius: 5px;
  width: fit-content;
  font-size: 18px;
  margin: auto;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 2px solid;
  border-radius: 0px;
}

.ct_f_10 {
  font-size: 14px;
  padding-inline: 6px;
}

.ct_table tr th,
.ct_table tr td {
  font-size: 10px;
  padding-inline: 5px;
}

.ct_bg_dark {
  background-color: rgb(228 233 99 / 26%) !important;
}

table.table.ct_table {
  border: 1px solid rgb(170 170 170 / 42%);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Api Page css S */

.ct_how_box_1 {
  max-width: 100%;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(134 175 72 / 27%);
  /* clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%); */
  /* clip-path: polygon(75% 0, 0 0, 0 100%, 75% 100%, 100% 50%); */
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  height: 180px;
  color: #333;
}

.ct_how_box_1:before {
  content: "";
  position: absolute;
  top: 60px;
  left: -30px;
  z-index: 1;
  border: solid 15px transparent;
  border-right-color: #fff;
}

.ct_small_title_box {
  max-width: 206px;
  height: 84px;
  border: 4px solid #86af48;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;
  /* clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); */
  /* background: rgb(139 195 74 / 35%); */
  margin: auto;
  /* clip-path: polygon(0% 0%, 90% 0, 100% 50%, 89% 100%, 0% 100%); */
  border-right: 0px;
  position: relative;
}

.ct_small_title_box:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 57px;
  border: 4px solid #86af48;
  border-left: 0px;
  /* border-top: 0px; */
  border-bottom: 0px;
  right: -30px;
  background-color: transparent;
  transform: rotate(47deg);
}

.ct_bank_bg {
  /* margin-bottom: 100px; */
  padding-block: 20px;
  border-radius: 0px;
  margin-top: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to right, rgb(134 175 72) , rgb(228 233 99 / 65%)); */
  /* background-color: rgb(228 233 99 / 17%); */
  background-image: url(../../../assets/DashboardAssets/assets/api_head_bg.png);
  padding: 70px 15px 120px;
  position: relative;
  background-color: #eaed98;
}

span.ct_clr_green_text {
  font-size: 35px;
}

.ct_bigger_head {
  position: relative;
  display: inline-block;
  font-size: 43px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #333;
  /* background-color: #86af48; */
  padding-bottom: 15px;
  /* width: 250px; */
  /* filter: drop-shadow(3px 3px 3px #fff); */
  margin-bottom: 60px;
  text-transform: uppercase;
  /* text-shadow: 1px 3px 7px #aaa; */
}

.ct_svg_head:after {
  content: "";
  position: absolute;
  width: 100%;
  background: url(../../../assets/DashboardAssets/assets/wavesNegative.svg);
  height: 50px;
  left: 0px;
  bottom: 0;
}

/* .ct_bigger_head::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 10px solid #86af48;
    transform: translateX(-50%);
} */

.ct_bigger_head:before {
  content: "";
  position: absolute;
  border-bottom: 2px solid #86af48;
  width: 100%;
  bottom: 0px;
  left: 0px;
  transition: 0.4s ease all;
}

.ct_bigger_head:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #86af48;
  border-radius: 100px;
  bottom: -5px;
  left: 50%;
  transform: translateX(0%);
  animation: move_dot 10s linear infinite;
}

@keyframes move_dot {

  0%,
  50%,
  100% {
    left: 0%;
    transform: translateX(0%);
  }

  25%,
  75% {
    left: 100%;

    transform: translateX(0%);
  }
}

/* Ecommerce section css S */
.UpperPart {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 3;
  background-color: white;
}

.UpperPart-Img {
  padding: 50px;
}

.UpperPart-Img img {
  height: 100%;
  width: 100%;
}

.UpperPart-text {
  height: auto;
  padding: 50px 50px 50px 0px;
  text-align: left;
  line-height: 2;
}

.UpperPart-Title {
  color: #292929;
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
}

.UpperPart-SubTitle {
  color: #2f2f2f;
  padding: 20px;
  font-weight: 500;
}

.UpperPart-Description {
  font-size: 20px;
  color: #7d7d7d;
  padding: 20px;
}

/******* this is the upper horizontal and vertical lines *************************************************/
.connection-line {
  height: 0vh;
  width: 50%;
  background-color: white;
  margin-top: -10vh;
  margin-left: 25%;
  margin-right: 25%;
  border-right: 1.2px solid #0e3b85;
  z-index: 3;
}

.horizontal-lines {
  display: block;
  width: 0px;
  height: 1.2px;
  margin-top: 30vh;
  position: absolute;
  background-color: #0e3b85;
  animation-name: animated-line;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

#line-left {
  left: 50%;
}

#line-right {
  right: 25%;
}

#line-center {
  margin: auto;
}

/************************************************************************************/

/********************************************************/

.timeline {
  list-style: none;
  padding-top: 0px;
  position: relative;
  z-index: -3;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: fixed;
  content: " ";
  width: 1.5px;
  background-color: unset;
  border: none;
  left: 130px;
  height: 100%;
  z-index: -5;
}

.timeline:after {
  top: 0;
  bottom: 0;
  position: fixed;
  content: " ";
  width: 1.5px;
  background-color: uns;
  left: 130px;
  border: none;
  height: 50vh;
  z-index: -5;
}

.ct_fixed:before,
.ct_fixed:after {
  position: fixed !important;
}

.ct_fixed:before {
  border: 1.25px solid #86af48 !important;
  background-color: #86af48;
}

.ct_fixed:after {
  border: 1.25px solid #f5f5f5 !important;
  background-color: #86af48 !important;
}

/************************************************************************************/

.timeline>li {
  position: relative;
  display: flex;
  align-items: center;
}

/************************************************************************************/

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

/************************************************************************************/
.timeline-panel {
  width: 50%;
  height: 500px;
  padding: 50px;
  position: relative;
  /* text-align: justify; */
  color: #000000;
  display: flex;
  align-items: center;
}

/************************************************************************************/

.timeline-panel:before {
  position: absolute;
  top: 29%;
  right: -6vw;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid lightblue;
  content: " ";
  background-color: white;
  z-index: 99;
  display: none;
}

/************************************************************************************/

.timeline-panel-img img {
  width: 100%;
  height: auto;
  max-width: 40vw;
  /* max-height: 35vh; */
  left: auto;
  right: auto;
}

/************************************************************************************/

.timeline-panel-img:before {
  position: absolute;
  top: 29%;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightblue;
  content: " ";
  display: none;
}

/************************************************************************************/

.timeline-panel:after {
  position: absolute;
  top: 31%;
  right: -14.25vw;
  display: inline-block;
  width: 8.3vw;
  height: 1px;
  background-color: lightblue;
  content: " ";
  z-index: -3;
  display: none;
}

/************************************************************************************/

.timeline-badge.active {
  color: #86af48;
  animation-name: lines;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  border-color: #86af48 !important;
}

/************************************************************************************/

.timeline-panel-body {
  opacity: 1;
}

/************************************************************************************/

.timeline-panel.active:after {
  animation-name: lines2;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/

.timeline-panel.active:before {
  animation-name: lines3;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/

.timeline-panel-img.active:before {
  animation-name: ani-img;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

/************************************************************************************/
.timeline-badge {
  color: #333;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 30%;
  left: -13%;
  margin-left: -69px;
  background-color: white;
  border: 1px solid #333;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

/************************************************************************************/

.timeline-body p {
  font-size: 1.2em;
  line-height: 2;
  /* text-align: justify; */
}

/************************************************************************************/

/* .timeline-panel.timeline-inverted, .timeline-inverted>.timeline-panel {
    float: right;
  }
   */
.timeline-panel.timeline-inverted:before,
.timeline-inverted>.timeline-panel:before {
  left: -6vw;
  right: auto;
}

.timeline-panel.timeline-inverted:after,
.timeline-inverted>.timeline-panel:after {
  background-color: lightblue;
  left: -15.2vw;
  right: auto;
  width: 10vw;
}

.timeline-title {
  font-size: 32px;
  line-height: 1.5;
  /* text-align: center; */
  font-weight: 600;
}

/************************************************************************************/

.endpart {
  position: relative;
  height: 50vh;
  background-color: white;
  z-index: 3;
  margin-top: 100px;
  text-align: center;
}

.endpart p {
  padding-top: 200px;
}

/************************************************************************************/
@keyframes animated-line {
  0% {
    width: 0px;
  }

  100% {
    width: 25%;
  }
}

@keyframes ani-img {
  0% {
    background-color: lightblue;
    transform: scale(1, 1);
  }

  50% {
    background-color: #7390bf;
    transform: scale(2, 2);
  }

  100% {
    background-color: #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes ani-panel {
  0% {
    color: #7390bf;
    opacity: 0;
  }

  50% {
    color: #7390bf;
    opacity: 0.5;
  }

  100% {
    color: #7390bf;
    opacity: 1;
  }
}

@keyframes ani-body {
  0% {
    color: lightblue;
  }

  50% {
    color: #7390bf;
  }

  100% {
    color: #0e3b85;
  }
}

@keyframes lines3 {
  0% {
    border: 1px solid lightblue;
    transform: scale(1, 1);
  }

  50% {
    border: 1.25px solid #7390bf;
    transform: scale(2, 2);
  }

  100% {
    border: 1.5px solid #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes lines {
  0% {
    border: 1px solid lightblue;
    transform: scale(1, 1);
  }

  50% {
    border: 1.25px solid #7390bf;
    transform: scale(1.5, 1.5);
  }

  100% {
    border: 1.5px solid #0e3b85;
    transform: scale(1, 1);
  }
}

@keyframes lines2 {
  0% {
    height: 1px;
  }

  50% {
    height: 1.25px;
    background-color: #7390bf;
  }

  100% {
    height: 1.5px;
    background-color: #0e3b85;
  }
}

@media (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
  .connection-line {
    display: none;
  }

  .timeline {
    padding-top: 50px;
    margin-left: 0;
  }

  .timeline:before {
    left: 50%;
    display: none;
  }

  .timeline:after {
    left: 50%;
    display: none;
  }

  .timeline>li {
    padding-bottom: 10%;
  }

  .timeline>li>.timeline-badge {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    line-height: 125px;
    font-size: 2em;
    left: 50%;
    top: 0;
    /* margin-bottom: 50px; */
    position: relative;
  }

  .timeline-badge.active {
    color: #86af48;
    animation-name: lines-mob;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .timeline>li>.timeline-panel {
    width: 100%;
    height: 35vh;
    float: none;
    left: 0%;
    background-color: white;
    z-index: 5;
    display: block;
    padding: 0px 0px;
    height: auto;
    text-align: justify;
  }

  .timeline>li>.timeline-panel.timeline-inverted,
  .timeline>li.timeline-inverted>.timeline-panel {
    float: none;
  }

  .timeline>li>.timeline-panel::before {
    display: none;
  }

  .timeline>li>.timeline-panel::after {
    display: none;
  }

  .timeline>li>.timeline-panel-img::before {
    display: none;
  }

  .timeline>li>.timeline-panel-img::after {
    display: none;
  }

  .timeline-body p {
    font-size: 90%;
    line-height: 1.25;
  }

  .timeline-title {
    text-align: center;
    font-size: 32px;
  }

  .timeline-panel-img img {
    width: 100%;
    height: auto;
    max-width: 90vw;

    left: auto;
    right: auto;
  }

  @keyframes lines-mob {
    0% {
      border: 1px solid lightblue;
      transform: scale(1, 1);
    }

    50% {
      border: 1.25px solid #7390bf;
      transform: scale(1.2, 1.2);
    }

    100% {
      border: 1.5px solid #0e3b85;
      transform: scale(1, 1);
    }
  }

  .timeline-body p {
    font-size: 1em;
    line-height: 1.4;
    text-align: center;
  }
}

/* scroll image change css S */

.ct_scrollsection {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.ct_scrollsection__img {
  position: relative;
  display: flex;
}

/* .ct_scrollsection__img__inner {
        height: 100vh;
        position: fixed;
        left: 10%;
        width: 40%;
        top:30%;
        z-index: -1;
      } */

.ct_scrollsection__body {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  /* top: 32%;
        transform: translateY(-50%); */
}

.ct_scrollsection__text {
  padding: 0vw;
}

/* .ct_scrollsection__img__inner img {
    transform: translateX(-100px);
    opacity: 0;

    transition: 0.6s ease all;
    
  } */

/* .ct_scrollsection.active .ct_scrollsection__img__inner img {
    opacity: 1;

    transform: translateX(0px);
  } */

.ct_overflow_hidden {
  overflow: hidden;
}

.ct_right_width_img {
  width: auto;
  height: 500px;
  object-fit: contain;
  margin: auto;
  display: block;
}

.ct_scroll_fixed_cnt ul li {
  position: relative;
  list-style: none;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

.ct_scroll_fixed_cnt p {
  font-weight: 500;
  color: #666;
}

.ct_scroll_fixed_cnt ul li:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -20px;
  background-color: #86af48;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
  border-radius: 10px 0px 10px 10px;
}

.ct_custome_close {
  position: absolute !important;
  top: -8px !important;
  right: -8px !important;
  color: #fff !important;
}

.ct_book_a_demo_main {
  padding-top: 0px !important;
}

select.form-control.ct_select_option1 {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0px;
  appearance: auto;
  padding-left: 0px;
}

#ct_book_demo {
  /* z-index: 9999999 !important; */
  margin-top: 50px;
}

.ct_small_title_box h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.ct_box_light_bg {
  /* background-color: rgb(228 233 99 / 19%); */
  /* background-color: #2196f314; */
  padding: 15px;
  text-align: center;
  position: relative;
  margin-top: 30px;
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  overflow: hidden;
}

.ct_box_light_bg:before {
  content: "";
  position: absolute;
  background-image: url("../../../assets/DashboardAssets/assets/card_btm_wave.svg");
  width: 104%;
  height: 40px;
  left: 0px;
  bottom: 0px;
  transform: rotate(180deg);
}

.ct_box_light_bg p {
  word-wrap: break-word;
  font-size: 16px;
  color: #fff;
}

.ct_right_arrow {
  background-color: #fff;
  width: 10px;
  padding: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
  border: 1px solid #aaa;
}

.ct_light_blue_head,
.ct_light_blue_head:after {
  border-color: #83bfe1;
}

.ct_light_blue_bg {
  background-color: #83bfe1;
}

.ct_light_green_head,
.ct_light_green_head:after {
  border-color: #86af48a8;
}

.ct_light_green_bg {
  background-color: #86af48a8;
}

.ct_light_pink_head,
.ct_light_pink_head:after {
  border-color: #f29381d1;
}

.ct_light_pink_bg {
  background-color: #f29381d1;
}

/* Carbon Neutral events Page S */

.ct_how_work_box {
  background-color: rgb(134 175 72 / 14%);
  padding: 30px;
  height: 100%;
  border-radius: 30px 0px 30px 30px;
  transition: 0.4s ease all;
  position: relative;
}

.ct_how_work_box:hover {
  transform: translateY(-15px);
}

.ct_how_work_icon {
  width: 60px;
  height: 60px;
  border: 2px dashed #8bc34a;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease all;
  position: relative;
  margin: 0px auto 30px;
  background-color: #fff;
  margin-top: -60px;
  z-index: 1;
  overflow: hidden;
}

.ct_how_work_icon:before {
  content: "";
  position: absolute;
  inset: 0px;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  transition: 0.4s ease all;
  background-color: #86af48;
  z-index: -1;
  border-radius: 100px;
}

.ct_how_work_box:hover .ct_how_work_icon h2 {
  color: #fff;
}

.ct_how_work_box:hover .ct_how_work_icon:before {
  width: 100%;
  height: 100%;
}

.ct_how_work_icon h2 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 900;
}

.ct_how_work_box .ct_how_work_cnt {
  text-align: center;
}

.ct_how_work_cnt h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

.ct_neutral_left_img img {
  border-radius: 28% 72% 69% 31% / 33% 31% 69% 67%;
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  object-fit: cover;
}

.ct_neutral_left_img {
  border-radius: 28% 72% 69% 31% / 33% 31% 69% 67%;
  max-width: 500px;
  height: 500px;
  margin: auto;
}

.ct_light_yellow_bg {
  background-color: #f8f6f2;
}

.ct_faq_q_box {
  background-color: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
  padding-bottom: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
  height: 100%;
}

.ct_faq_question_title {
  background-color: #86af48;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.ct_faq_question_title h4 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}

.ct_faq_answer {
  padding: 0px 15px 0px;
}

.ct_faq_answer p {
  margin-bottom: 0px;
}

.ct_carbon_neutral_nwsltr {
  display: grid;
  align-items: center;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f8f6f2;
}

.ct_nwsltr_img img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: 100% 27%;
  filter: brightness(0.9);
}

.ct_carbon_neutral_nwsltr_cnt {
  padding-left: 50px;
}

/* Eko Points page css S */
.ct_eko_point_work_box {
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  /* padding: 30px; */
  transition: 0.4s ease all;
  position: relative;
  height: 100%;
  border: 2px dashed #8bc34a;
  border-radius: 10px;
  overflow: hidden;
}

.ct_eko_point_work_cnt h4 {
  margin-bottom: 15px;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}

.ct_eko_point_work_cnt p {
  margin-bottom: 0px;
  color: #fff;
}

.ct_eko_icon {
  padding-top: 40px;
  z-index: 111;
  position: relative;
}

.ct_eko_icon img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 25px;
}

.ct_eko_point_work_cnt {
  padding: 40px 30px 30px;
  background-color: #8bc34a;
  height: 100%;
  position: relative;
}

.ct_eko_point_work_cnt::before {
  position: absolute;
  left: -30px;
  right: -30px;
  top: -45px;
  content: "";
  height: 60px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffffff;
}

.ct_eko_point_work_box:hover .ct_eko_icon img {
  animation: ct_eko_shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.ct_eko_points_bg {
  background-image: url("../../../assets/DashboardAssets/assets/Eko_points_bg_img.jpg");
  background-color: #86af48;
  /* overflow: auto; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ct_eko_points_bg:after {
  width: 100%;
  height: 150px !important;
  z-index: -1;
  background-image: url(../../../assets/DashboardAssets/assets/Eko_points_waves.svg);
  bottom: 0px !important;
  background-color: transparent !important;
  top: auto !important;
}

.ct_eko_points_bg:before {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;

  bottom: 0px;
  background-color: #000;
  opacity: 0.5;
  top: auto;
}

.ct_eko_spark_mob img {
  position: relative;
  top: 25px;
  width: 240px;
}

.ct_eko_spark_bg_title h2 {
  color: #fff;
  font-size: 35px;
  margin-bottom: 0px;
  letter-spacing: 1.6px;
}

.ct_eko_spark_bg_title p {
  color: rgb(255 255 255 / 79%);
}

h4.ct_coming_soon_text {
  color: #fff;
  letter-spacing: 2.4px;
  margin-top: 50px;
}

.ct_eko_spark_bg_title {
  margin-top: -150px;
}

/* .ct_ecom_titl_bg {
    background-color: rgb(134 175 72 / 25%);
    padding-block: 60px;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 68%);
} */
@keyframes ct_eko_shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Home New Slider css S */
.ct_slider_inner_cnt img {
  width: 100%;
  height: 760px;
  object-fit: cover;
  opacity: 0.4;
  filter: brightness(0.5);
  transition: 5s ease;
}

.ct_slider_inner_cnt img:hover {
  transform: scale(1.3);
}

.ct_slider_inner_cnt figure {
  margin: 0px;
}

.ct_slider_inner_cnt figcaption {
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%, 0%);
  text-align: center;
  /* padding: 2rem; */
  padding: 45px 50px;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-width: 890px;
}

.cti_custom_class .owl-item.active .ct_slider_inner_cnt figcaption {
  visibility: visible;
  top: 18%;

  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible;
  opacity: 1;
  margin-top: 16px;
}

@keyframes slideExpandUp {
  0% {
    transform: translate(-50%, 140%) scaleX(0.5);
  }

  30% {
    transform: translate(-50%, -8%) scaleX(0.5);
  }

  40% {
    transform: translate(-50%, 2%) scaleX(0.5);
  }

  50% {
    transform: translate(-50%, 0%) scaleX(1.1);
  }

  60% {
    transform: translate(-50%, 0%) scaleX(0.9);
  }

  70% {
    transform: translate(-50%, 0%) scaleX(1.05);
  }

  80% {
    transform: translate(-50%, 0%) scaleX(0.95);
  }

  90% {
    transform: translate(-50%, 0%) scaleX(1.02);
  }

  100% {
    transform: translate(-50%, 0%) scaleX(1);
  }
}

.ct_slider_inner_cnt h4 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400 !important;
  line-height: 35px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Inter", sans-serif !important;
}

.ct_slider_inner_cnt h2 {
  font-family: "Inter", sans-serif !important;
  line-height: 77px;
  font-weight: 700 !important;
  font-size: 46px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.ct_slider_inner_cnt p {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Inter", sans-serif !important;
}

.ct_home_slider_bg {
  height: 700px;
}

.ct_home_slider_bg .owl-dots .owl-dot span {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px !important;
  margin: 0px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span {
  background-color: transparent !important;
  border: 1px solid #fff;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  margin-bottom: 2px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active {
  margin-bottom: 30px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active {
  margin-bottom: 0px;
}

.ct_slider_inner_cnt:before {
  content: "";
  position: absolute;
  inset: 0px;
  background-color: #333;
}

.ct_nav_flex .owl-dots {
  display: none;
}

.ct_nav_flex .owl-nav {
  display: flex !important;
}

.cti_slider_btn {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cti_slider_btn a.btn_banner {
  color: #fff;
}

.btn_banner {
  background-color: #86af48;
  border-radius: 10px 0 10px 0;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: sans-serif;
  border: none;
  padding: 15px 30px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: #ffffffe6;
  font-size: 16px;
  font-weight: 400;
}

.btn_banner:hover:after,
.btn_banner:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn_banner:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn_banner:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn_banner:after,
.btn_banner:before {
  background: #373435;
}

.ct_slider_inner_cnt {
  position: relative;
  z-index: 2;
}

.content_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-right: none;
  border-bottom: none;
}

.content_border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-right: none;
  border-top: none;
}

.box_cut::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-left: none;
  border-top: none;
}

.box_cut::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-left: none;
  border-bottom: none;
}

.ct_home_slider_bg .cti_custom_class .owl-nav {
  display: none;
}

.cti_custom_class {
  position: unset !important;
}

.ct_home_slider_bg .cti_custom_class .owl-dots {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 1.5rem;
  transform: translateY(-50%) !important;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  display: block;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
  margin-bottom: 10px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span {
  background: #7d7d7d;
  position: relative;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 25px;
  text-indent: 0;
  height: 0px;
  width: 1px;
  margin-left: 0;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span:before {
  height: 100px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active span:before {
  height: 0px;
}

.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span,
.ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:hover span {
  background: #fff !important;
}

@media screen and (max-width: 1023px) and (min-width: 991px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width: 990px) and (min-width: 768px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width: 1400px) {
  .timeline-badge {
    left: 0%;
  }

  .timeline:before,
  .timeline:after {
    left: 54px;
  }
}

@media screen and (max-width: 1326px) {
  .timeline-badge {
    left: -52px;
  }

  .timeline:before,
  .timeline:after {
    left: 54px;
  }
}

@media screen and (max-width: 1280px) {
  .timeline:after {
    display: none;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .timeline-badge {
    left: 0px;
    top: 29%;
  }

  .ct_fixed:before,
  .ct_fixed:after {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .ct_card_box {
    width: 220px;
    height: 220px;
  }

  .ct_slider_inner_cnt h2 {
    line-height: 58px;
  }

  .ct_card_box figcaption {
    font-size: 14px;
  }

  .intro_section .big {
    line-height: 58px !important;
  }
}

@media screen and (max-width: 991px) {
  .ct_about_main {
    position: relative;
    max-width: 470px;
    margin: auto;
  }

  .hero-wrap {
    margin-top: 90px !important;
  }

  .ct_card_box {
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
  }

  .ct_card_box figcaption {
    font-size: 16px;
  }

  .ct_our_mission_bg {
    height: 250px;

    margin-bottom: 24px;
  }

  .ct_our_mission_main {
    display: block;
    margin-inline: 15px;
  }

  .ct_our_mission_left_bg {
    max-width: 100%;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots {
    top: auto;

    right: auto;
    bottom: 30px;

    transform: translatex(-50%) !important;
    display: flex;
    left: 50%;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
    top: auto;
    bottom: 10px;
    height: 1px;
    width: 0px;
    left: 25px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active span:before {
    width: 100px;
    height: 1px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active {
    margin-bottom: 0px;
    margin-right: 120px;
  }

  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot:last-child.active {
    margin-right: 0px;
  }

  /* .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot span:before {
        bottom: 25px;
        height: 1px;
        width: 100px;
        top: auto;
    } */
  /* .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot.active{
        margin-bottom: 0px;
        margin-right: 30px;
    } */
  .ct_home_slider_bg .cti_custom_class .owl-dots .owl-dot {
    margin-bottom: 0px;
  }

  .ct_srvc_card_box {
    height: 100%;
  }

  .ct_srvc_card_box .ct_srvc_btm_img img {
    height: 160px;
  }

  .ct_srvc_body {
    padding: 25px 0px 25px;
  }

  .ct_right_arrow {
    display: none;
  }

  .ct_slider_inner_cnt figcaption {
    width: 87%;
  }
}

@media screen and (max-width: 767px) {

  .project-wrap:before,
  .project-wrap:after,
  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_slider_inner_cnt h2 {
    font-size: 28px;
    line-height: 46px;
  }

  .ct_slider_inner_cnt p {
    font-size: 14px;
    line-height: 30px;
  }

  .about-img-wrap img {
    height: 250px;
  }

  .ct_srvc_card_box {
    height: auto;
  }

  br {
    display: none;
  }

  .timeline-badge {
    left: 50%;
    margin-left: 0;

    transform: translateX(-50%);
  }

  .timeline-badge.active {
    transform: translateX(-50%) !important;
    left: 50% !important;
  }

  .ct_carbon_neutral_nwsltr {
    grid-template-columns: repeat(1, 1fr);
  }

  .ct_carbon_neutral_nwsltr_cnt {
    padding-block: 40px;
    padding-top: 20px;
  }

  .timeline {
    padding-left: 0px;
  }

  .ct_srvc_body {
    padding-bottom: 5px;
  }

  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_scroll_fixed_img {
    margin-bottom: 30px;
  }

  .ct_scroll_fixed_cnt {
    padding-inline: 0px;
    margin-bottom: 30px;
  }

  .ct_globel_partner_bg {
    margin-top: -174px;
  }

  .ct_green_background {
    padding-bottom: 220px !important;
  }

  .ct_globel_logo_img img {
    height: 64px;
    object-fit: contain;
    margin: auto;
    display: block;
  }

  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-inline: 15px;
  }

  .ct_globel_logo_img {
    margin-bottom: 24px;
  }

  .vertical-item.gallery-item .item-media img {
    height: 200px;
    width: 100%;
    max-width: 100%;
    margin-inline: 0px;
  }

  .team-card.style3 img {
    height: 288px;
    object-fit: contain;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
    margin: auto !important;
    display: block;
  }

  .ct_ddirector_info blockquote {
    font-size: 14px;
  }

  .ct_climate_crisis_cnt {
    padding: 30px;
  }

  .ct_our_mission_left_bg {
    padding-block: 30px;
  }

  .intro_section .big {
    line-height: 46px !important;
  }

  .intro_section .big {
    font-size: 28px;
  }

  .fontsize_20 {
    font-size: 18px;
  }

  .switch-theme-mode.ct_mob_light_mode {
    top: 130px !important;
  }

  .ct_ceo_img img {
    width: 150px;
    height: 150px;
  }

  .ct_mob_none {
    display: none !important;
  }

  .ct_ceo-card_main:before {
    height: 200px;
  }

  .ct_ceo-card_main {
    margin-bottom: 30px;
  }

  .ct_srvc_icon {
    width: 50px;
    height: 50px;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
  }

  .owl-carousel.projects-carousel {
    margin-top: 15px;
  }

  .ct_nav_flex .owl-dots {
    display: none !important;
  }

  .ct_nav_flex .owl-nav {
    display: flex;
  }

  .ct_project_main_div .owl-carousel .owl-nav button.owl-prev,
  .ct_project_main_div .owl-carousel .owl-nav button.owl-next {
    width: 60px !important;
    height: 40px;
  }

  .ct_text_short {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_show_full {
    overflow: auto;
    white-space: normal;
  }

  .ct_more_link {
    color: #86af48;
    font-weight: 500;
    font-size: 16px;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_sub_heading {
    font-size: 18px;
  }

  .ct_srvc_icon img {
    width: 40px;
  }

  .ct_dashboard_icon {
    width: 70px;
    height: 70px;
  }

  .ct_dashboard_icon svg {
    width: 50px;
    height: 50px;
  }

  .intro_section .fontsize_16 {
    line-height: 30px;
    font-size: 14px;
  }

  .fontsize_20 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .timeline>li {
    display: block;
  }

  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
  }

  .timeline-title {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
    line-height: 36px;
  }

  .ct_scrollsection {
    min-height: auto;
  }

  .ct_scrollsection__img__inner {
    height: auto;
    position: unset;
    width: 100%;
  }

  .ct_scrollsection__body {
    position: unset;
    transform: unset;
    margin-top: 30px;
  }

  .ct_neutral_left_img {
    max-width: 360px;
    height: 360px;
  }

  .ct_eko_spark_mob img {
    width: 200px;
  }

  /* .ct_eko_points_bg:after{
    display: none;
} */
  .ct_eko_spark_mob img {
    position: unset;
    margin: auto;
    display: block;
  }

  .ct_eko_points_bg {
    height: 90vh !important;
    padding-bottom: 220px !important;
  }

  .ct_eko_spark_bg_title {
    margin-top: 0px;
    text-align: center;
  }

  .ct_eko_points_bg:after {
    height: 60px !important;
  }
}

@media screen and (max-width: 575px) {
  #ct_book_demo .modal-dialog {
    margin-inline: 25px;
  }

  .ct_slider_inner_cnt figcaption {
    top: 10% !important;
  }
}

@media screen and (max-width: 500px) {
  .ct_slider_inner_cnt .content_border {
    padding: 20px !important;
  }
}

/* OUR APPROACH */

.cti_contact_bg {
  background-image: url(../../../assets/DashboardAssets/assets/approach-banner.jpg);
  background-position: center;
  background-size: cover;
  padding: 192px 0 156px;
}

.cti-banner-text h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 1.3;
  color: #363636;
}

.cti-banner-text span {
  color: #86af48;
}

.cti-banner-text p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
  padding-top: 0.7rem;
}

.cti-breadcrumb-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

.cti-carbon-section {
  padding: 56px 0;
}

.cti_carbon_subSection h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  color: #373435;
  font-family: "Inter", sans-serif;
}

.cti_carbon_subSection span {
  color: #86af48;
  font-weight: 600;
}

.cti_offsetting_content p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.cti_description {
  color: #a0a0a0;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 455px;
  margin-top: 1rem;
}

.cti_offsetting_subSection h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  color: #373435;
  font-family: "Inter", sans-serif;
}

.cti_offsetting_subSection .bold_class {
  font-weight: 700;
  color: #86af48;
}

.carbon_offsetting_section {
  padding: 80px 0;
}

.cti_offsetting_content-1 .cti_para {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.cti_offsetting_content-1 .cti_para_2 {
  font-weight: 500;
  font-size: 15px;
  color: #999;
}

.arrow_image i {
  font-size: 4rem;
  color: #86af48;
}

.cti_offset_work {
  padding: 22px;
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

.cti_offset_work img {
  background: #86af48;
  width: 40px;
  padding: 6px;
  border-radius: 4px;
  margin-top: 10px;
}

.cti_offset_work h5 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.9;
}

.cti_arrow_down_blue i {
  font-size: 4rem;
  color: #0d6efdc2;
}

.cti_arrow_only i {
  font-size: 4rem;
  color: #86af48;
}

.cti_cards_content {
  color: #999;
}

.cti_contact_bg_Sub_footer {
  background-image: url(../../../assets/DashboardAssets/assets/approch_climate_change.jpg);
  background-position: center;
  background-size: cover;
  padding: 192px 0 156px;
}

.cti_sub_footer_text {
  background-color: #fff;
  padding: 1.6rem 3rem;
  border-radius: 10px;
  max-width: 100%;
  filter: opacity(0.9);
}

.cti_sub_footer_text p {
  font-size: 24px;
  color: #566a7f;
}

.sub-footer-textP {
  font-size: 15px !important;
  color: #a0a0a0 !important;
  font-weight: 600;
}

.cti_subFooter {
  color: #86af48;
  font-weight: 700;
}

/* FAQS CSS */
.faq_section {
  padding-block: 4rem;
}

.cti_faq_heading {
  position: relative;
}

.cti_faq_heading:before {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: #86af48;
  left: 37%;
  top: 50%;
  position: absolute;
}

.cti_faq_heading:after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: #86af48;
  right: 37%;
  top: 50%;
  position: absolute;
}

.main_child_accor {
  margin-bottom: 1rem;
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.12);
}

.main_child_accor a:hover {
  color: #fff !important;
}

.cti_custome {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 10px;
}

div.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.collapse_arrow {
  width: 24px !important;
}

.ant-collapse {
  border: none !important;
}

.ant-collapse-item {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  padding-block: 10px;
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.12);
}

/* GIFT CSS */
.cti_gift_section {
  padding-block: 3rem;
}

.cti_main_heading p {
  font-size: 32px;
  font-weight: 500;
  color: #373435;
  text-transform: uppercase;
}

.cti_main_heading span {
  color: #86af48;
  font-weight: 700;
}

/* .cti_main_heading p::before {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: #86af48;
    position: absolute;
    top: 50%;
    left: 37%;
  }
  .cti_main_heading p::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: #86af48;
    position: absolute;
    top: 50%;
    right: 37%;
  } */
.cti_gift_para {
  font-size: 1.475rem;
  color: #697a8d;
  font-weight: 600;
  margin: 0;
}

.cti_gift_para1 {
  color: #88ae47;
  font-weight: 600;
  font-size: 1.475rem;
  line-height: 1.4;
}

.cti_gift_para2 {
  color: #9e9e9e;
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 73%;
}

.cti_right_image {
  background: #dee2e68c;
  padding: 2rem 0rem;
  border-radius: 10px;
}

.cti_right_image img {
  max-width: 80%;
}

/* PROJECT CSS */
.ct_project_bg1 {
  background-image: url("../../../assets/DashboardAssets/assets/promo-video-bg.jpg");
}

.ct_how_redeem_gift_bg_1 {
  background-image: url("../../../assets/DashboardAssets/assets/thread_image.jpeg");
}

.ct_faq_bg_1 {
  background-image: url("../../../assets/DashboardAssets/assets/man-with-tablet-world-drawn-background.jpg");
}

.cti_standard__img {
  border: 1px solid #9999995c;
  padding: 1rem;
  border-radius: 8px;
}

.cti_support_text {
  max-width: 60%;
  margin: 0 auto !important;
}

.cti_cutome_card {
  position: relative;
  border: none;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.cti_card_title {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 0px;
  padding-top: 8px;
}

.cti_card_title_1 p {
  font-size: 13px;
}

.cti_cutome_card img {
  border-radius: 10px;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cti_cutome_card:hover img {
  opacity: 0.3;
  transition: all ease 1s;
}

.cti_cutome_card:hover .middle {
  opacity: 1;
}

.text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.cti_card_title_1 i {
  color: #a0a0a0;
  font-size: 20px;
}

/* MEDAI QUERY */
@media (max-width: 576px) {
  .cti-banner-text h1 {
    font-size: 28px;
  }

  .cti-banner-text p {
    font-size: 13px;
  }

  .cti_offset_work h5 {
    line-height: 1.6;
  }

  .cti_carbon_subSection h3 {
    font-size: 18px;
  }

  .cti_main_heading p {
    font-size: 23px;
  }

  .carbon_offsetting_section {
    padding: 40px 0;
  }

  .cti_offsetting_subSection h2 {
    font-size: 18px;
  }
}

.ct_life_cycle_img img {
  border-radius: 70px 180px 345px 327.5px;
}

.ct_assessment_img img {
  width: 360px;
  display: block;
  margin-inline: auto;
  margin-top: 100px;
}

@media screen and (max-width:1023px) and (min-width:991px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}

@media screen and (max-width:990px) and (min-width:768px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    line-height: 60px;
    font-size: 20px;
    left: 0%;
    top: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 50px; */
    position: absolute;
  }
}


@media screen and (max-width:1400px) {
  .timeline-badge {

    left: 0%;

  }

  .timeline:before,
  .timeline:after {

    left: 54px;

  }
}

@media screen and (max-width:1326px) {
  .timeline-badge {

    left: -52px;

  }

  .timeline:before,
  .timeline:after {

    left: 54px;

  }
}

@media screen and (max-width:1280px) {

  .timeline:after {

    display: none;

  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .timeline-badge {
    left: 0px;
    top: 29%;
  }

  .ct_fixed:before,
  .ct_fixed:after {
    display: none;
  }

}

@media screen and (max-width:1199px) {
  .ct_card_box {
    width: 220px;
    height: 220px;
  }

  .ct_card_box figcaption {
    font-size: 14px;
  }

  .intro_section .big {

    line-height: 58px !important;

  }
}

@media screen and (max-width:991px) {
  .ct_about_main {
    position: relative;
    max-width: 470px;
    margin: auto;
  }

  .hero-wrap {
    margin-top: 90px !important;
  }

  .ct_card_box {
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
  }

  .ct_card_box figcaption {
    font-size: 16px;
  }

  .ct_our_mission_bg {
    height: 250px;

    margin-bottom: 24px;
  }

  .ct_our_mission_main {
    display: block;
    margin-inline: 15px;
  }

  .ct_our_mission_left_bg {
    max-width: 100%;
  }

  /*   div#canvas {*/
  /*    margin-top: 95px !important;*/
  /*}*/
  .ct_srvc_card_box {
    height: 100%;
  }

  .ct_srvc_card_box .ct_srvc_btm_img img {
    height: 160px;

  }

  .ct_srvc_body {
    padding: 25px 0px 25px;
  }

  .ct_right_arrow {
    display: none;
  }
}

@media screen and (max-width:767px) {
  .ct_chain_connection:after {
    width: 12px;
    height: 11px;
    top: auto;
    transform: translateY(-50%);
    left: 50%;
    -webkit-box-reflect: right -3px;
    z-index: -1999;
    transform: translateX(-50%) rotate(90deg);
    right: auto;
  }

  .project-wrap:before,
  .project-wrap:after,
  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .about-img-wrap img {
    height: 250px;

  }

  .ct_srvc_card_box {
    height: auto;
  }

  br {
    display: none;
  }

  .timeline-badge {

    left: 50%;
    margin-left: 0;

    transform: translateX(-50%);
  }

  .timeline-badge.active {
    transform: translateX(-50%) !important;
    left: 50% !important;
  }

  .ct_carbon_neutral_nwsltr {
    grid-template-columns: repeat(1, 1fr);

  }

  .ct_carbon_neutral_nwsltr_cnt {
    padding-block: 40px;
    padding-top: 20px;
  }

  .timeline {

    padding-left: 0px;
  }

  .ct_srvc_body {
    padding-bottom: 5px;
  }

  .ct_energy_bg:before,
  .ct_energy_bg:after {
    display: none;
  }

  .ct_scroll_fixed_img {
    margin-bottom: 30px;
  }

  .ct_scroll_fixed_cnt {
    padding-inline: 0px;
    margin-bottom: 30px;
  }

  .ct_globel_partner_bg {

    margin-top: -174px;

  }

  .ct_green_background {

    padding-bottom: 220px !important;
  }

  .ct_globel_logo_img img {

    height: 64px;
    object-fit: contain;
    margin: auto;
    display: block;
  }


  .ct_ct_climate_crisis_bg,
  .ct_ct_climate_crisis_bg_2 {
    padding-inline: 15px;
  }

  .ct_globel_logo_img {
    margin-bottom: 24px;
  }

  .vertical-item.gallery-item .item-media img {
    height: 200px;
    width: 100%;
    max-width: 100%;
    margin-inline: 0px;
  }

  .team-card.style3 img {
    height: 288px;
    object-fit: contain;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
    margin: auto !important;
    display: block;
  }

  .ct_ddirector_info blockquote {
    font-size: 14px;
  }

  .ct_climate_crisis_cnt {
    padding: 30px;
  }

  .ct_our_mission_left_bg {

    padding-block: 30px;
  }

  .intro_section .big {
    line-height: 46px !important;

  }

  .intro_section .big {
    font-size: 28px;
  }

  .fontsize_20 {
    font-size: 18px;
  }

  .ct_ceo_img img {
    width: 150px;
    height: 150px;
  }

  .ct_mob_none {
    display: none !important;
  }

  .ct_ceo-card_main:before {
    height: 200px;
  }

  .ct_ceo-card_main {

    margin-bottom: 30px;
  }

  .ct_srvc_icon {
    width: 50px;
    height: 50px;
  }

  .team-card {
    max-width: 360px;
    height: 360px;
  }

  .owl-carousel.projects-carousel {
    margin-top: 15px;
  }

  .ct_project_main_div .owl-carousel .owl-nav button.owl-prev,
  .ct_project_main_div .owl-carousel .owl-nav button.owl-next {
    width: 60px !important;
    height: 40px;
  }

  .ct_text_short {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_show_full {
    overflow: auto;
    white-space: normal;
  }

  .ct_more_link {
    color: #86af48;
    font-weight: 500;
    font-size: 16px;
    display: block;
    transition: 0.4s ease all;
  }

  .ct_sub_heading {
    font-size: 18px;
  }

  .ct_srvc_icon img {

    width: 40px !important;
  }

  .ct_dashboard_icon {

    width: 70px;
    height: 70px;
  }

  .ct_dashboard_icon svg {
    width: 50px;
    height: 50px;
  }

  .intro_section .fontsize_16 {
    line-height: 30px;
    font-size: 14px;
  }

  .fontsize_20 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .timeline>li {
    display: block;
  }

  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    margin-bottom: 30px
  }

  .timeline-title {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
    line-height: 36px;
  }

  .ct_scrollsection {
    min-height: auto;
  }

  .ct_scrollsection__img__inner {
    height: auto;
    position: unset;
    width: 100%;
  }

  .ct_scrollsection__body {
    position: unset;
    transform: unset;
    margin-top: 30px;
  }

  .ct_neutral_left_img {

    max-width: 360px;
    height: 360px;

  }

  .ct_eko_spark_mob img {
    width: 200px;
  }

  /* .ct_eko_points_bg:after{
  display: none;
} */
  .ct_eko_spark_mob img {
    position: unset;
    margin: auto;
    display: block;
  }

  .ct_eko_points_bg {
    height: 90vh !important;
    padding-bottom: 220px !important;
  }

  .ct_eko_spark_bg_title {
    margin-top: 0px;
    text-align: center;
  }
}


@media screen and (max-width:575px) {
  #ct_book_demo .modal-dialog {
    margin-inline: 25px;
  }
}