@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600,700,900);



.vertical-tabs .tab-content.no-border {
    padding-top: 0;
}

.vertical-tabs .nav>li>a {
    background-color: #333333;
    color: #ffffff;
    padding: 18px 60px 18px 40px;
    margin-bottom: 10px;
    position: relative;
}

.vertical-tabs .nav>li>a i {
    position: relative;
    top: 1px;
    padding-right: 12px;
}

.vertical-tabs .nav>li>a:after {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'rt-icons-2';
    content: "\e7c5";
    position: absolute;
    font-size: 12px;
    line-height: 58px;
    text-align: center;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    letter-spacing: 0;
}

.vertical-tabs .nav>li.active a,
.vertical-tabs .nav>li>a:hover {
    background-color: #4baf47;
}

@media (min-width: 768px) {
    .vertical-tabs .nav>li.active {
        position: relative;
        z-index: 2;
    }
}

.owl-carousel.projects-carousel {
    z-index: 6;
}

@media (min-width: 992px) {
    .owl-carousel.projects-carousel {
        width: 130%;
    }
}

.projects-content {
    padding: 0;
    border: none;
    background-color: transparent;
}

.projects-content .item-media img {
    margin: 0 -16.6666666666%;
    max-width: 150%;
}

@media (max-width: 991px) {
    .projects-content {
        margin-top: 40px;
    }
}

@media (min-width: 768px) {
    .owl-carousel.hover-scale .owl-stage-outer {
        overflow: visible;
    }
    .owl-carousel.hover-scale .vertical-item {
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }
    .owl-carousel.hover-scale .owl-item:hover .vertical-item,
    .owl-carousel.hover-scale .owl-item.center .vertical-item {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        z-index: 4;
        overflow: hidden;
    }
}


.vertical-item.full-padding {
    padding: 30px 30px 30px 30px;
}

.vertical-item.content-padding .item-content {
    padding: 25px 30px;
}

.vertical-item.content-with-meta .item-content {
    padding: 25px 30px 10px;
}

.vertical-item.content-absolute {
    position: relative;
}

.vertical-item.content-absolute .item-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px;
}

@media (max-width: 400px) {
    .vertical-item.content-absolute .item-content {
        padding: 30px 20px;
    }
}

.vertical-item.content-absolute.vertical-center .item-content {
    bottom: 0;
    line-height: 1.1;
}

.vertical-item.content-absolute.bottom-content .item-content {
    top: auto;
    bottom: 0;
    padding: 0 30px 22px;
}

@media (max-width: 400px) {
    .vertical-item.content-absolute.bottom-content .item-content {
        padding: 0 20px 12px;
    }
}

@media (max-width: 400px) {
    .vertical-item .item-content {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

/* item media links */

.item-media {
    position: relative;
    overflow: hidden;
}

.media-links {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.media-links div {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 4;
}

.media-links:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

[class*="item"]:hover .media-links:before {
    opacity: 0.75;
}

.media-links.p-link:before {
    background-color: #4baf47;
}

.media-links.p-link .links-wrap {
    line-height: 1;
    text-align: center;
    color: #333333;
    bottom: 50%;
    margin-bottom: -15px;
    z-index: 2;
}

.media-links.p-link .links-wrap i {
    position: relative;
    left: -100px;
    font-size: 30px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    color: #fff;
}

[class*="item"]:hover .media-links.p-link:before {
    opacity: 0.8;
}

[class*="item"]:hover .media-links.p-link .links-wrap i {
    left: 0;
    opacity: 1;
}

.media-links a.abs-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 3;
}

[class*="item"]:hover .media-links a.abs-link {
    opacity: 1;
}

.links-wrap {
    line-height: 0;
    font-size: 0;
    text-align: right;
}

.links-wrap a {
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 60px;
    right: -60px;
    display: inline-block;
    z-index: 5;
    opacity: 0;
    -webkit-transition: all 0.6s ease-in-out 0s;
    transition: all 0.6s ease-in-out 0s;
    background-color: #4baf47;
}

.links-wrap a:before,
.links-wrap a:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #333333;
}

.links-wrap a:before {
    width: 2px;
    height: 30px;
}

.links-wrap a:after {
    width: 30px;
    height: 2px;
}

.links-wrap a:hover:before,
.links-wrap a:hover:after {
    opacity: 0.6;
}

.links-wrap a.p-link:before {
    content: "\f10c";
}

.media-links.type2 .links-wrap {
    text-align: center;
    top: auto;
    bottom: 50%;
    margin-bottom: -18px;
}

.media-links.type2 .links-wrap a {
    position: relative;
    top: -10px;
    right: 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    background-color: transparent;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.media-links.type2 .links-wrap a:after {
    display: none;
}

.media-links.type2 .links-wrap a:before {
    width: auto;
    height: auto;
    position: static;
    font-family: Flaticon;
    font-size: 20px;
    background-color: transparent;
}

.media-links.type2 .links-wrap a.p-link:before {
    content: "\f10a";
}

.media-links.type2 .links-wrap a.p-link {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.media-links.type2 .links-wrap a.p-like {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.media-links.type2 .links-wrap a.p-view:before {
    content: "\f117";
}

.media-links.type2 .links-wrap a.p-like:before {
    content: "\f10c";
}

[class*="item"]:hover .media-links.type2 .links-wrap a {
    top: 0;
}

.media-links.type2 .links-wrap a:hover {
    color: #4baf47;
}

.media-links.type2 .links-wrap a:hover:before {
    opacity: 1;
}

.bottom-content .links-wrap {
    top: 0;
    bottom: auto;
}

.bottom-content .links-wrap a {
    height: 70px;
    font-size: 26px;
    line-height: 70px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
}

.bottom-content .links-wrap a:before {
    content: "\f114";
    position: static;
    font-family: Flaticon;
    font-size: 26px;
    background-color: transparent;
    -webkit-transform: none;
    transform: none;
}

.bottom-content .links-wrap a:after {
    display: none;
}

.bottom-content .links-wrap a.p-link:before {
    content: "\f110";
}

.bottom-content .links-wrap a:hover:before,
.bottom-content .links-wrap a:hover:after {
    opacity: 1;
}

[class*="item"]:hover .links-wrap a {
    right: 0;
    opacity: 1;
}

[class*="item"]:hover .media-links div {
    opacity: 1;
}

/* item icons */

.item-icons {
    display: table;
    width: 100%;
    border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
    display: block;
    font-size: 16px;
}

.item-icons>div {
    padding: 22px 10px 18px;
    display: table-cell;
}

.item-icons>div+div {
    border-left: 1px solid rgba(128, 128, 128, 0.1);
}

.read-more {
    font-size: 16px;
    font-weight: 700;
}

.read-more i {
    padding-right: 2px;
    font-size: 400;
    -webkit-transition: padding 0.2s ease-in-out 0s;
    transition: padding 0.2s ease-in-out 0s;
}

.read-more:hover i {
    padding: 0 5px;
}

.bottom-border {
    position: relative;
}

.bottom-border:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}



.block-header .highlight {
    position: relative;
}

.block-header .highlight:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -11px;
    height: 3px;
    z-index: 2;
    background-color: #4baf47;
}



.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    backface-visibility: hidden;
   
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    display: none;
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.owl-carousel .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

/* .owl-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 6px;
    border: 1px solid #a4a4a4;
    display: block;
    backface-visibility: visible;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    border-radius: 50%;
} */

/* .owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
    background-color: #a4a4a4;
} */

/* .owl-carousel .owl-nav {
    margin-top: 37px;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
} */

.owl-carousel .owl-nav>div {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
}

.owl-carousel .owl-nav>div+div {
    margin-left: 65px;
}

.owl-carousel .owl-nav>div:hover {
    color: #4baf47;
}

.owl-carousel .owl-nav>div:first-child:before,
.owl-carousel .owl-nav>div:last-child:after {
    font: normal normal normal 14px/1 FontAwesome;
    color: #4baf47;
    line-height: 16px;
    width: 16px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.owl-carousel .owl-nav>div:first-child:before {
    content: "\f104";
    padding-right: 10px;
}

.owl-carousel .owl-nav>div:last-child:after {
    content: "\f105";
    padding-left: 10px;
}

/* No Js */





/*
 *  Owl Carousel - Auto Height Plugin
 */

.owl-height {
    -webkit-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

/*
 *  Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}




/* owl theme */

.owl-theme .owl-nav>div {
    text-align: center;
}

.owl-theme .owl-nav .disabled {
    cursor: default;
    pointer-events: none;
}

.owl-theme .owl-dots {
    margin-top: 40px;
}

.owl-center .owl-nav {
    top: 0;
    bottom: 0;
    position: static;
    margin-top: 0;
    opacity: 1;
}

.owl-center .owl-nav>div {
    right: -15px;
    width: 50px;
    top: 0;
    bottom: 0;
    margin: 0;
    height: auto;
    background-color: rgba(51, 51, 51, 0.6);
}

.owl-center .owl-nav>div:first-child {
    left: -15px;
    right: auto;
}

.owl-center .owl-nav>div:first-child:after {
    left: 13px;
}

.owl-center .owl-nav>div:after {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    color: #ffffff;
    font-size: 20px;
    left: 17px;
}

