@import url('../src/fonts/stylesheet.css');
@import url('../../blocks/email-account-login/assets/Login.css');
@import url('../../blocks/forgot-password/assets/ForgotPassword.css');
@import url('../../blocks/email-account-registration/assets/IndvSignup.css');
@import url('../../components/src/Footer/Footer.css');
@import url('../../components/src/Header/Header.css');
@import url('../../blocks/dashboard/src/IndividualNav.web.css');
@import url('../../blocks/dashboard/src/IndvOffset/IndividualOffset.css');
@import url('../../blocks/dashboard/src/Dashboard.css');
@import url('../../blocks/dashboard/src/IndividaualScreen.web.css');
@import url('../../blocks/dashboard/src/IndvLayout/indvlayout.css');
@import url('../../blocks/dashboard/src/IndvFaq/indvfaq.css');
@import url('../../blocks/dashboard/src/IndvAddToCart/indvAddtoCart.css');
@import url('../../blocks/dashboard/src/IndvPrivacy/indvPrivacy.css');
@import url('../../blocks/CustomisableUserProfiles/src/reset-password/IndvidualProfileResetPassword.css');
@import url('../../blocks/dashboard/src/IndvClimateCertificate/indvClimate.css');
@import url('../../blocks/dashboard/src/IndvPaymentSuccess/IndvPaymentSuccess.css');
@import url('../../blocks/dashboard/src/LandingPages/IndiviLandingCommon.css');
@import url('../../blocks/dashboard/src/IndvTour/IndividualTour.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessTakeTour/BusinessTour.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessOffset/BusinessOffset.css');
@import url('../../blocks/dashboard/src/IndvOffset/IndividualOffsetDetails.web.css');
@import url('../../blocks/dashboard/src/IndvProject/IndividualProject.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessScreen.web.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Signup.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Core.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Demo.css');` */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/theme.css'); */
/* @import url('../../blocks/dashboard/src/Dashboard_Api/Style/Flight.css'); */
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/animations.css');
/* @import url('../../blocks/dashboard/src/EkobonDashboard/Style/aos.css'); */
@import url('https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/bootstrap.min.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/bootstrap_2.min.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/css2.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/dark-theme.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/dark-theme.scss');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/fancybox.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/flaticon.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/footer.scss');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/header.scss');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/odometer.min.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/owl-theme-default-min.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/owl.carousel.min.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/remixicon.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/responsive.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/responsive.scss');
@import url('../../blocks/dashboard/src/EkobonDashboard/DashboardCss/slider.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/DashboardCss/style.css');
@import url('../../blocks/dashboard/src/EkobonDashboard/Style/style.scss');

:root {
  --color-primary: #3f7a4f;
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;
  --color-light-gray-hover: #7c81884f;
  --color-individual: #88ae47;
  --color-individual-firstsquareT: #7f9065;
  --color-individual-secondsquareT: #96aeb4;
  --color-individual-firstsquare: #e8f0db;
  --color-individual-secondsquare: #e1f4fa;
  --color-individual-white: #f4f8ea;
  --color-individual-gray: #cbcbcb;
  --color-individual-gray1: #c3c3c3;
  --color-green-individual-main: #88ae47;
  --color-green-individual-backgroud: #f8f6f7;
  --color-tabgreen-individual-backgroud: #bae481;
  --color-individual-Inner-box: #e6f1cf;
  --color-individual-i-b-amount: #a4b0b2;
  --color-individual-i-b-amount1: #94bcbf;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* background-color: var(--color-individual-gray) !important; */
  user-select: none;
}

iframe {
  pointer-events: none !important;
  /* overflow: hidden !important; */
}

#iframe2 {
  pointer-events: unset !important;
  z-index: 99 !important;
  border-radius: 15px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* overflow: hidden; */
}

.switch-theme-mode {
  position: fixed !important;
  top: 200px !important;
  right: 15px !important;
  z-index: 99 !important;
}

li {
  list-style: none;
}

.modal-content {
  border-radius: 10px;
}

.ct_tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

span.ct_tooltiptext.ct_tooltip_hiddien {
  display: none;
  visibility: unset !important;
  right: 4px;
  position: absolute !important;
  padding: 8px 12px;
  font-size: 12px;
  width: fit-content !important;
}

.ct_tooltip a {
  line-height: 45px;
}

#g-recaptcha1 iframe {
  pointer-events: all !important;
}

.rc-anchor-logo-portrait {
  margin-left: 0px;
}

.rc-anchor-normal {
  height: 74px;
  width: 258px !important;
}

.en_position_reset_12 {
  position: unset !important;
  top: 0px !important;
  transform: unset !important;
  left: 0px !important;
}

.ct_tooltip .ct_tooltiptext {
  visibility: hidden;
  /* width: 100%; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 6px;
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  line-height: 23px;
  right: auto;
  margin-left: 20px;
}

.ct_tooltip:hover .ct_tooltiptext.ct_tooltip_hiddien {
  display: block;
}

.ct_tooltip:hover .ct_tooltiptext {
  display: block;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  /* .ct_login_width_bg {
    height: auto;
  } */

  .ct_login_tab a.nav-link {
    padding-block: 15px !important;
  }
}



.ant-input {
  border-radius: 10px !important;
}

/* .ant-select-selector {
  border-color: rgba(204, 204, 204, 0.5) !important;
  box-shadow: none !important;
} */
.ct_fs_18 {
  font-size: 18px;
}